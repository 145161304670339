import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let DocumentsCircle = props => (
  <SvgIcon {...props}>
    <path
      fill="#393939"
      d="M17,2C8.7,2,2,8.7,2,17s6.7,15,15,15s15-6.7,15-15S25.3,2,17,2z M17,30C9.8,30,4,24.2,4,17S9.8,4,17,4
		s13,5.8,13,13S24.2,30,17,30z"
    />
    <path
      fill="#393939"
      d="M18.6,8.8h-6.6c-0.9,0-1.6,0.7-1.6,1.6l0,13.1c0,0.9,0.7,1.6,1.6,1.6h9.9c0.9,0,1.6-0.7,1.6-1.6v-9.8L18.6,8.8
		z M20.3,21.9h-6.6v-1.6h6.6V21.9z M20.3,18.6h-6.6V17h6.6V18.6z M17.8,14.5V10l4.5,4.5H17.8z"
    />
  </SvgIcon>
);

DocumentsCircle = pure(DocumentsCircle);
DocumentsCircle.displaName = 'DocumentsCircle';
DocumentsCircle.muiName = 'SvgIcon';

export default DocumentsCircle;

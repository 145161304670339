import { addDays, parse } from 'date-fns';
import moment from 'moment';

// Actions
const ACTIONS = {
  SET_ORDERS: 'schedule/SET_ORDERS',
  SET_PENDING_ORDERS: 'schedule/SET_PENDING_ORDERS',
  SET_INSPECTORS: 'schedule/SET_INSPECTORS',
  SET_SELECTED_ORDER: 'schedule/SET_SELECTED_ORDER',
  ADD_OR_UPDATE_ORDER: 'schedule/ADD_OR_UPDATE_ORDER',
  DELETE_ORDER: 'schedule/DELETE_ORDER',
  PREPEND_FILTERED_PENDING_ORDER: 'schedule/PREPEND_FILTERED_PENDING_ORDER',
  APPEND_FILTERED_PENDING_ORDERS: 'schedule/APPEND_FILTERED_PENDING_ORDERS',
  DELETE_FILTERED_PENDING_ORDER: 'schedule/DELETE_FILTERED_PENDING_ORDER',
  SET_DATE: 'schedule/SET_DATE',
  SET_UNSAVED: 'schedule/SET_UNSAVED',
  SET_INITIAL_FILTERED_INSPECTORS: 'schedule/SET_INITIAL_FILTERED_INSPECTORS',
  SET_INITIAL_FILTERED_PENDING_ORDERS: 'schedule/SET_INITIAL_FILTERED_PENDING_ORDERS',
  SET_FILTERED_INSPECTORS: 'schedule/SET_FILTERED_INSPECTORS',
  SET_FILTERED_PENDING_ORDERS: 'schedule/SET_FILTERED_PENDING_ORDERS',
  NOTIFY_FILTERED_INSPECTORS: 'schedule/NOTIFY_FILTERED_INSPECTORS',
  CLEAR_FILTERED_INSPECTORS_NOTIFICATIONS: 'schedule/CLEAR_FILTERED_INSPECTORS_NOTIFICATIONS',
  SET_INSPECTORS_AVAILABILITIES: 'schedule/SET_INSPECTORS_AVAILABILITIES',
  SET_INSPECTORS_UNAVAILABILITIES: 'schedule/SET_INSPECTORS_UNAVAILABILITIES',
  APPEND_ORDER_BEING_EDITED: 'schedule/APPEND_ORDER_BEING_EDITED',
  DELETE_ORDER_BEING_EDITED: 'schedule/DELETE_ORDER_BEING_EDITED',
  SORT_PENDING_ORDERS: 'schedule/SORT_PENDING_ORDERS',
};

// Reducer
const initialState = {
  date: moment().add(1, 'days'),
  dateAsDateObject: addDays(new Date(), 1),
  orders: [],
  pendingOrders: [],
  inspectors: [],
  filteredInspectors: [],
  filteredPendingOrders: [],
  ordersThatAreBeingEdited: [],
  todayUnavailabilitiesGroupedByInspector: new Map(),
  selectedOrder: null,
  unsaved: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.SET_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };

    case ACTIONS.ADD_OR_UPDATE_ORDER: {
      const orders = [...state.orders];
      const index = orders.findIndex(el => el.order.public_id === action.payload?.order?.public_id);

      if (index > -1) {
        // update order
        orders[index] = action.payload;
      } else {
        // new order
        orders.push(action.payload);
      }

      return {
        ...state,
        orders,
      };
    }

    case ACTIONS.DELETE_ORDER: {
      const orders = [...state.orders];
      const index = orders.findIndex(order => order?.order?.public_id === action.payload);

      if (index === -1) return state;

      orders.splice(index, 1);

      return {
        ...state,
        orders,
      };
    }

    case ACTIONS.SET_PENDING_ORDERS: {
      const pendingOrdersThatAreNotOnTimeline = action.payload.filter(pendingOrder => {
        return !state.orders.some(timelineOrder => timelineOrder.order.public_id === pendingOrder.id);
      });

      return {
        ...state,
        pendingOrders: pendingOrdersThatAreNotOnTimeline,
      };
    }

    case ACTIONS.APPEND_FILTERED_PENDING_ORDERS: {
      const filteredPendingOrders = [...state.filteredPendingOrders];
      const pendingOrders = [...state.pendingOrders];

      action.payload.forEach(order => filteredPendingOrders.push(order));
      action.payload.forEach(order => pendingOrders.push(order));

      return {
        ...state,
        filteredPendingOrders,
        pendingOrders,
      };
    }

    case ACTIONS.PREPEND_FILTERED_PENDING_ORDER: {
      const filteredPendingOrders = [...state.filteredPendingOrders];
      const pendingOrders = [...state.pendingOrders];

      const isAlreadyInFilteredPendingOrders = filteredPendingOrders.some(order => order?.id === action?.payload?.id);
      const isAlreadyInPendingOrders = pendingOrders.some(order => order?.id === action?.payload?.id);

      if (!isAlreadyInFilteredPendingOrders) filteredPendingOrders.unshift(action?.payload);

      if (!isAlreadyInPendingOrders) pendingOrders.unshift(action?.payload);

      return {
        ...state,
        filteredPendingOrders,
        pendingOrders,
      };
    }

    case ACTIONS.DELETE_FILTERED_PENDING_ORDER: {
      const filteredPendingOrders = [...state.filteredPendingOrders];
      const pendingOrders = [...state.pendingOrders];

      const filteredPendingOrderIndex = filteredPendingOrders.findIndex(order => order?.id === action.payload);
      const pendingOrderIndex = pendingOrders.findIndex(order => order?.id === action.payload);

      if (filteredPendingOrderIndex === -1 || pendingOrderIndex === -1) {
        return state;
      }

      filteredPendingOrders.splice(filteredPendingOrderIndex, 1);
      pendingOrders.splice(pendingOrderIndex, 1);

      return {
        ...state,
        filteredPendingOrders,
        pendingOrders,
      };
    }

    case ACTIONS.SET_SELECTED_ORDER:
      return {
        ...state,
        selectedOrder: action.payload,
      };

    case ACTIONS.SET_DATE:
      return {
        ...state,
        date: action.payload,
        dateAsDateObject: action.payload.toDate(),
      };

    case ACTIONS.SET_INSPECTORS: {
      return {
        ...state,
        inspectors: action.payload,
      };
    }

    case ACTIONS.SET_INSPECTORS_UNAVAILABILITIES: {
      const unavailabilitiesGroupedByInspector = action.payload
        .map(unavailability => ({
          ...unavailability,
          start: parse(unavailability.start.slice(0, -2) + '00', 'yyyy-MM-dd HH:mm:ss', new Date()),
          end: parse(unavailability.end.slice(0, -2) + '00', 'yyyy-MM-dd HH:mm:ss', new Date()),
        }))
        .reduce((map, unavailability) => {
          const { inspector_id } = unavailability;

          if (!map.has(inspector_id)) map.set(inspector_id, []);

          map.get(inspector_id).push(unavailability);

          return map;
        }, new Map());

      const inspectorsWithTodayUnvailabilities = state.inspectors.map(inspector => {
        const todayUnavailabilities = unavailabilitiesGroupedByInspector.get(inspector.public_id) || [];

        return { ...inspector, todayUnavailabilities };
      });

      const filteredInspectorsWithTodayUnvailabilities = state.filteredInspectors.map(filteredInspector => {
        const todayUnavailabilities = unavailabilitiesGroupedByInspector.get(filteredInspector.public_id) || [];

        return { ...filteredInspector, todayUnavailabilities };
      });

      return {
        ...state,
        inspectors: inspectorsWithTodayUnvailabilities,
        filteredInspectors: filteredInspectorsWithTodayUnvailabilities,
        todayUnavailabilitiesGroupedByInspector: unavailabilitiesGroupedByInspector,
      };
    }

    case ACTIONS.SET_INSPECTORS_AVAILABILITIES: {
      const today = state.date.locale('en').format('dddd').toLowerCase();

      const inspectorsWithTodayAvailabilities = state.inspectors.map(inspector => {
        let todayAvailabilities = [];

        if (inspector.availability && inspector.availability[today]) {
          todayAvailabilities = inspector.availability[today].map(availability => ({
            start_time: new Date(`1970-01-01 ${availability.start_time}`),
            end_time: new Date(`1970-01-01 ${availability.end_time}`),
          }));
        }

        return {
          ...inspector,
          todayAvailabilities,
        };
      });

      const filteredInspectorsInspectorsWithTodayAvailabilities = state.filteredInspectors.map(filteredInspector => {
        let todayAvailabilities = [];

        if (filteredInspector.availability && filteredInspector.availability[today]) {
          todayAvailabilities = filteredInspector.availability[today].map(availability => ({
            start_time: new Date(`1970-01-01 ${availability.start_time}`),
            end_time: new Date(`1970-01-01 ${availability.end_time}`),
          }));
        }

        return {
          ...filteredInspector,
          todayAvailabilities,
        };
      });

      return {
        ...state,
        inspectors: inspectorsWithTodayAvailabilities,
        filteredInspectors: filteredInspectorsInspectorsWithTodayAvailabilities,
      };
    }

    case ACTIONS.SET_UNSAVED:
      return {
        ...state,
        unsaved: action.payload,
      };

    case ACTIONS.SET_INITIAL_FILTERED_INSPECTORS:
      return {
        ...state,
        filteredInspectors: action.payload,
      };

    case ACTIONS.SET_INITIAL_FILTERED_PENDING_ORDERS: {
      const filteredPendingOrdersThatAreNotOnTimeline = action.payload.filter(filteredPendingOrder => {
        return !state.orders.some(timelineOrder => timelineOrder.order.public_id === filteredPendingOrder.id);
      });

      return {
        ...state,
        filteredPendingOrders: filteredPendingOrdersThatAreNotOnTimeline,
      };
    }

    case ACTIONS.SET_FILTERED_INSPECTORS: {
      const { addresses = [], orderTypes = [] } = action.payload;

      let filteredInspectors = state.inspectors || [];
      const notFilteredInspectors = [];

      if (addresses.length || orderTypes.length) {
        if (addresses.length && !orderTypes.length) {
          filteredInspectors = state.inspectors.filter(inspector => {
            const hasAddress = addresses.some(address => inspector?.filters?.addresses.includes(address?.id));

            if (!hasAddress) {
              notFilteredInspectors.push({
                ...inspector,
                wasNotFiltered: true,
              });
            }

            return hasAddress;
          });
        } else if (!addresses.length && orderTypes.length) {
          filteredInspectors = state.inspectors.filter(inspector => {
            const hasOrderType = orderTypes.some(orderType => inspector?.filters?.orderTypes.includes(orderType?.id));

            if (!hasOrderType) {
              notFilteredInspectors.push({
                ...inspector,
                wasNotFiltered: true,
              });
            }

            return hasOrderType;
          });
        } else if (addresses.length && orderTypes.length) {
          filteredInspectors = state.inspectors.filter(inspector => {
            const hasAddress = addresses.some(address => inspector?.filters?.addresses.includes(address?.id));

            const hasOrderType = orderTypes.some(orderType => inspector?.filters?.orderTypes.includes(orderType?.id));

            if (!hasOrderType || !hasAddress) {
              notFilteredInspectors.push({
                ...inspector,
                wasNotFiltered: true,
              });
            }

            return hasAddress && hasOrderType;
          });
        }
      }

      return {
        ...state,
        filteredInspectors: filteredInspectors.concat(notFilteredInspectors),
      };
    }

    case ACTIONS.SET_FILTERED_PENDING_ORDERS: {
      const { addresses = [], orderTypes = [], text } = action.payload;

      let filteredPendingOrders = state.pendingOrders;

      if (text) {
        const splitedText = String(text).trim().split(' ');

        filteredPendingOrders = filteredPendingOrders.filter(order => {
          const hasTerm = splitedText.every(term => order.search_tokens?.includes(term.toUpperCase()));

          return hasTerm;
        });
      }

      if (addresses.length || orderTypes.length) {
        if (addresses.length && !orderTypes.length) {
          filteredPendingOrders = state.pendingOrders.filter(pendingOrder => {
            const hasAddress = addresses.some(address => address?.id === pendingOrder?.address_id);

            return hasAddress;
          });
        } else if (!addresses.length && orderTypes.length) {
          filteredPendingOrders = state.pendingOrders.filter(pendingOrder => {
            const hasOrderType = orderTypes.some(orderType => orderType?.id === pendingOrder?.order_type_id);

            return hasOrderType;
          });
        } else if (addresses.length && orderTypes.length) {
          filteredPendingOrders = state.pendingOrders.filter(pendingOrder => {
            const hasAddress = addresses.some(address => address?.id === pendingOrder?.address_id);

            const hasOrderType = orderTypes.some(orderType => orderType?.id === pendingOrder?.order_type_id);

            return hasAddress && hasOrderType;
          });
        }
      }

      filteredPendingOrders = filteredPendingOrders.filter(filteredPendingOrder => {
        const itsOnTimeline = state.orders.some(order => order?.order?.public_id === filteredPendingOrder?.id);

        return !itsOnTimeline;
      });

      return {
        ...state,
        filteredPendingOrders,
      };
    }

    case ACTIONS.NOTIFY_FILTERED_INSPECTORS: {
      const { order_type_id = null, address_id = null } = action.payload;

      const notifiedFilteredInspectors = state.filteredInspectors.map(inspector => {
        const hasOrderType = inspector?.filters?.orderTypes.includes(order_type_id);

        if (!hasOrderType && order_type_id) {
          return {
            ...inspector,
            pendingOrderBasedMessage: 'ORDER_TYPE',
          };
        }

        const hasAddress = inspector?.filters?.addresses.includes(address_id);

        if (!hasAddress && address_id) {
          return {
            ...inspector,
            pendingOrderBasedMessage: 'ADDRESS',
          };
        }

        return inspector;
      });

      return {
        ...state,
        filteredInspectors: notifiedFilteredInspectors,
      };
    }

    case ACTIONS.CLEAR_FILTERED_INSPECTORS_NOTIFICATIONS: {
      const clearedFilteredInspectors = state.filteredInspectors.map(inspector => ({
        ...inspector,
        pendingOrderBasedMessage: null,
      }));

      return {
        ...state,
        filteredInspectors: clearedFilteredInspectors,
      };
    }

    case ACTIONS.APPEND_ORDER_BEING_EDITED: {
      if (!action.payload?.order || !action.payload?.sender) {
        return state;
      }

      const ordersThatAreBeingEdited = [...state.ordersThatAreBeingEdited, action.payload];

      return {
        ...state,
        ordersThatAreBeingEdited,
      };
    }

    case ACTIONS.DELETE_ORDER_BEING_EDITED: {
      const { public_id } = action?.payload || {};

      if (!public_id) {
        return state;
      }

      const ordersThatAreBeingEdited = [...state.ordersThatAreBeingEdited];
      const indexToDelete = ordersThatAreBeingEdited.findIndex(orderThatAreBeingEdited => {
        return orderThatAreBeingEdited?.order?.order?.public_id === public_id;
      });

      if (indexToDelete === -1) {
        return state;
      }

      ordersThatAreBeingEdited.splice(indexToDelete, 1);

      return {
        ...state,
        ordersThatAreBeingEdited,
      };
    }

    case ACTIONS.SORT_PENDING_ORDERS: {
      const { older } = action.payload;

      const sortingTypes = {
        newest: (order, nextOrder) => new Date(order?.created_at) - new Date(nextOrder?.created_at),

        oldest: (order, nextOrder) => new Date(nextOrder?.created_at) - new Date(order?.created_at),
      };

      const sortingType = older ? 'oldest' : 'newest';
      const sortedPendingOrders = state.filteredPendingOrders?.slice().sort(sortingTypes[sortingType]);

      return {
        ...state,
        filteredPendingOrders: sortedPendingOrders,
      };
    }

    default: {
      return state;
    }
  }
}

// Actions
export const setOrders = payload => ({
  type: ACTIONS.SET_ORDERS,
  payload,
});

export const setPendingOrders = payload => ({
  type: ACTIONS.SET_PENDING_ORDERS,
  payload,
});

export const setInspectors = payload => ({
  type: ACTIONS.SET_INSPECTORS,
  payload,
});

export const setInspectorsAvailabilities = () => ({
  type: ACTIONS.SET_INSPECTORS_AVAILABILITIES,
});

export const setInspectorsUnavailabilities = payload => ({
  type: ACTIONS.SET_INSPECTORS_UNAVAILABILITIES,
  payload,
});

export const setSelectedOrder = payload => ({
  type: ACTIONS.SET_SELECTED_ORDER,
  payload,
});

export const addOrUpdateOrder = payload => ({
  type: ACTIONS.ADD_OR_UPDATE_ORDER,
  payload,
});

export const deleteOrder = payload => ({
  type: ACTIONS.DELETE_ORDER,
  payload,
});

export const appendPendingOrders = payload => ({
  type: ACTIONS.APPEND_FILTERED_PENDING_ORDERS,
  payload,
});

export const prependPendingOrder = payload => ({
  type: ACTIONS.PREPEND_FILTERED_PENDING_ORDER,
  payload,
});

export const deleteFilteredPendingOrder = payload => ({
  type: ACTIONS.DELETE_FILTERED_PENDING_ORDER,
  payload,
});

export const setDate = payload => ({
  type: ACTIONS.SET_DATE,
  payload,
});

export const setUnsaved = payload => ({
  type: ACTIONS.SET_UNSAVED,
  payload,
});

export const setInitialFilteredInspectors = payload => ({
  type: ACTIONS.SET_INITIAL_FILTERED_INSPECTORS,
  payload,
});

export const setInitialFilteredPendingOrders = payload => ({
  type: ACTIONS.SET_INITIAL_FILTERED_PENDING_ORDERS,
  payload,
});

export const setFilteredInspectors = payload => ({
  type: ACTIONS.SET_FILTERED_INSPECTORS,
  payload,
});

export const setFilteredPendingOrders = payload => ({
  type: ACTIONS.SET_FILTERED_PENDING_ORDERS,
  payload,
});

export const notifyFilteredInspectors = payload => ({
  type: ACTIONS.NOTIFY_FILTERED_INSPECTORS,
  payload,
});

export const clearFilteredInspectorsNotifications = () => ({
  type: ACTIONS.CLEAR_FILTERED_INSPECTORS_NOTIFICATIONS,
});

export const addOrderBeingEdited = payload => ({
  type: ACTIONS.APPEND_ORDER_BEING_EDITED,
  payload,
});

export const deleteOrderBeingEdited = payload => ({
  type: ACTIONS.DELETE_ORDER_BEING_EDITED,
  payload,
});

export const sortPendingOrders = payload => ({
  type: ACTIONS.SORT_PENDING_ORDERS,
  payload,
});

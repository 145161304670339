import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let Declined = props => (
  <SvgIcon {...props}>
    <path
      fill="#E1E0E0"
      d="M26,0C11.6,0,0,11.6,0,26s11.6,26,26,26s26-11.6,26-26S40.4,0,26,0z M26,50C12.7,50,2,39.3,2,26
	C2,12.7,12.7,2,26,2c13.3,0,24,10.7,24,24C50,39.3,39.3,50,26,50z"
    />
    <polygon
      fill="#E1E0E0"
      points="36.7,16.7 35.3,15.3 26,24.6 16.7,15.3 15.3,16.7 24.6,26 15.3,35.3 16.7,36.7 26,27.4 35.3,36.7 
	36.7,35.3 27.4,26 "
    />
  </SvgIcon>
);

Declined = pure(Declined);
Declined.displaName = 'Declined';
Declined.muiName = 'SvgIcon';

export default Declined;

import React, { useEffect, useState } from 'react';

import ReportProblem from '@material-ui/icons/ReportProblem';
import { PowerBIEmbed } from 'powerbi-client-react';

import API from 'src/apiRequest';
import Spinner from 'src/components/common/Spinner';
import { DASHBOARD_BASE_URL } from 'src/config';
import 'src/components/UserDashboardsWrapper/style.css';

const UserDashboardsWrapper = () => {
  const [iframeDashboards, setIframeDashboards] = useState([]);
  const [powerBiDashboards, setPowerbiDashboards] = useState([]);
  const [arcGisDashboards, setArcGisDashboards] = useState([]);
  const [locked, setLocked] = useState(true);
  const [loading, setloading] = useState(false);

  const handleFormatDashboardTitle = (title = '') => title.replaceAll(' ', '-').toLowerCase();

  useEffect(() => {
    const me = JSON.parse(window.sessionStorage.getItem('me'));
    const arcgisUserKey = me?.dashboards?.arcgis?.base;

    if(!arcgisUserKey){
      setLocked(false);
      return;
    }

    async function handleUnlockDashboard() {
      try {
        setloading(true);

        await API.post(`${DASHBOARD_BASE_URL}/api/arcgis/${arcgisUserKey}/unlock`);

        setLocked(false);
      } catch {
        setLocked(true);
      } finally {
        setTimeout(() => {
          setloading(false);
        }, 3000);
      }
    }

    handleUnlockDashboard();
  }, []);

  useEffect(() => {
    const me = JSON.parse(window.sessionStorage.getItem('me'));

    if (!locked) {
      setIframeDashboards(me?.dashboards?.iframes?.items || []);
      setPowerbiDashboards(me?.dashboards?.powerbi?.items || []);
      setArcGisDashboards(me?.dashboards?.arcgis?.items || []);
    }
  }, [locked]);

  return (
    <div id="dashboards">
      {loading ? (
        <div id="loading">
          <div className="spinner">
            <Spinner />
          </div>
        </div>
      ) : !locked ? (
        <>
          <header className="dashboard-header">
            {!!iframeDashboards.length &&
              iframeDashboards.map(dashboard => (
                <a href={`#${handleFormatDashboardTitle(dashboard.title)}`} key={dashboard.title}>
                  {dashboard.title}
                </a>
              ))}
            {!!powerBiDashboards.length &&
              powerBiDashboards.map(dashboard => (
                <a href={`#${handleFormatDashboardTitle(dashboard.title)}`} key={dashboard.title}>
                  {dashboard.title}
                </a>
              ))}
            {!!arcGisDashboards.length &&
              arcGisDashboards.map(dashboard => (
                <a href={`#${handleFormatDashboardTitle(dashboard.title)}`} key={dashboard.title}>
                  {dashboard.title}
                </a>
              ))}
          </header>
          <main>
            {!!iframeDashboards.length &&
              iframeDashboards.map(dashboard => (
                <section id={handleFormatDashboardTitle(dashboard.title)} key={dashboard.title}>
                  <iframe
                    src={dashboard.uri}
                    title={dashboard.title}
                    className="iframe-wrapper"
                  />
                </section>
              ))}
            {!!powerBiDashboards.length &&
              powerBiDashboards.map(dashboard => (
                <section id={handleFormatDashboardTitle(dashboard.title)} key={dashboard.title}>
                  <PowerBIEmbed
                    embedConfig={{ type: 'visual', embedUrl: dashboard.uri }}
                    cssClassName="powerbi-wrapper"
                  />
                </section>
              ))}
            {!!arcGisDashboards.length &&
              arcGisDashboards.map(dashboard => (
                <section id={handleFormatDashboardTitle(dashboard.title)} key={dashboard.title}>
                  <iframe
                    src={`https://gruporv.maps.arcgis.com/apps/dashboards/${dashboard.id}`}
                    title={dashboard.title}
                    className="arcgis-wrapper"
                  />
                </section>
              ))}
          </main>
        </>
      ) : (
        <div id="error">
          <ReportProblem color="error" style={{ fontSize: 50 }} />
          <p>Erro ao carregar os dashboards, tente novamente em breve</p>
        </div>
      )}
    </div>
  );
};

export default UserDashboardsWrapper;

import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';

import ColorButton from 'src/components/common/elements/ColorButton';
import AttachDetails from 'src/inspectionAttach/AttachDetails';
import DeviceDetails from 'src/inspectionAttach/DeviceDetails';
import DevicesFilter from 'src/inspectionAttach/DevicesFilter';
import classes from 'src/inspectionAttach/InspectionAttach.module.css';
import InspectionFile from 'src/inspectionAttach/InspectionFile';
import InspectionSearch from 'src/inspectionAttach/InspectionSearch';

class InspectionAttach extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null, // Object with the selected option data
    };
  }

  /*
   * Function to handle the selection of an inspection from the search bar
   */
  handleSelect = option => {
    this.setState({
      selectedOption: option,
    });
  };

  /*
   * Function to handle the cancel of th selected inspection fron the search bar
   */
  handleCancel = () => {
    this.setState({
      selectedOption: null,
    });
  };

  /*
   * Function to handle the confirmation of th selected inspection
   */
  handleConfirm = () => {
    const { selectedOption } = this.state;
    const { handleSearchConfirm } = this.props;
    handleSearchConfirm(selectedOption);
  };

  render() {
    const { selectedOption } = this.state;
    const {
      apiUrl,
      clientPanel,
      device,
      handleFileConfirm,
      handleContinueWithouAttachment,
      header,
      onlySearch,
      previousType,
      searchPlaceholder,
      subHeader,
      newOrderType,
    } = this.props;
    let search;

    if (selectedOption !== null) {
      if (device) {
        search = (
          <Grid item xs={10}>
            <DeviceDetails data={selectedOption} handleCancel={this.handleCancel} handleConfirm={this.handleConfirm} />
          </Grid>
        );
      } else {
        search = (
          <Grid item xs={10}>
            <AttachDetails data={selectedOption} handleCancel={this.handleCancel} handleConfirm={this.handleConfirm} />
          </Grid>
        );
      }
    } else if (device) {
      search = (
        <Grid item xs={10}>
          <DevicesFilter handleSelect={this.handleSelect} searchPlaceholder={searchPlaceholder} apiUrl={apiUrl} />
        </Grid>
      );
    } else {
      search = (
        <Grid item xs={10}>
          <InspectionSearch handleSelect={this.handleSelect} searchPlaceholder={searchPlaceholder} apiUrl={apiUrl} />
        </Grid>
      );
    }

    if (onlySearch) {
      return (
        <div className={clientPanel ? classes.center : classes.centerHeight}>
          <Paper className={classes.Paper} elevation={0}>
            <div className={classes.Header}>
              <span>{header}</span>
            </div>

            <div className={classes.SubHeader}>
              <span>{subHeader}</span>
            </div>

            <div className={classes.Body}>
              <Grid container justify="center" spacing={16}>
                {search}
              </Grid>
            </div>
          </Paper>
        </div>
      );
    }

    return (
      <div className={clientPanel ? classes.center : classes.centerHeight}>
        <Paper className={clientPanel ? null : classes.Paper} elevation={0}>
          <div className={classes.Header}>
            <span>{header}</span>
          </div>

          <div className={classes.SubHeader}>
            <span>{subHeader}</span>
          </div>

          <div className={classes.Body}>
            <Grid container justify="center" alignItems="center" spacing={16}>
              {search}

              {!['aditivo', 'conferenciaFinalDeObra', 'intermediariaBHomy', 'revistoriaBrookfield'].includes(newOrderType) && (
                <>
                  <Grid item xs={10}>
                    <FormattedMessage id="or" />
                  </Grid>

                  {newOrderType !== 'acompanhamentoObraMM' ? (
                    <Grid item xs={10}>
                      <InspectionFile orderAttachFile={handleFileConfirm} previousType={previousType} />
                    </Grid>
                  ) : (
                    <ColorButton color="blue" onClick={handleContinueWithouAttachment}>
                      Crie o primeiro pedido
                    </ColorButton>
                  )}
                </>
              )}
            </Grid>
          </div>
        </Paper>
      </div>
    );
  }
}

InspectionAttach.propTypes = {
  /**
   * FormattedMessage of the header
   */
  header: PropTypes.object.isRequired,
  /**
   * FormattedMessage of the subHeader
   */
  subHeader: PropTypes.object.isRequired,
  /**
   * Function to make the correspondent action after
   * confirm the selected search value
   */
  handleSearchConfirm: PropTypes.func.isRequired,
  /**
   * Function to handle order creation without previous
   * inspection.
   */
  handleContinueWithouAttachment: PropTypes.func.isRequired,
  /**
   * Function to make the correspondent action after
   * the file upload
   */
  handleFileConfirm: PropTypes.func.isRequired,
  /**
   * URL of the api to make the search
   */
  apiUrl: PropTypes.string.isRequired,
  /**
   * Boolean to know if only will show the search bar
   */
  onlySearch: PropTypes.bool,
  /**
   * String for the placeholder of the search bar
   */
  searchPlaceholder: PropTypes.string,
  /**
   * Boolean to know if is a device search
   */
  device: PropTypes.bool,
  /**
   * Boolean to know if is the client panel
   */
  clientPanel: PropTypes.bool,
  previousType: PropTypes.string.isRequired,
  newOrderType: PropTypes.string,
};

InspectionAttach.defaultProps = {
  onlySearch: false,
  searchPlaceholder: null,
  device: false,
  clientPanel: false,
  newOrderType: null,
};

export default InspectionAttach;

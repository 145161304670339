import React from 'react';

const ClientPlus = props => (
  <svg {...props} width="24" height="24" viewBox="0 0 34.016 34.016" fill="none">
    <path
      d="M14.4286 14.5472C16.2038 14.5472 17.6429 13.1081 17.6429 11.3329C17.6429 9.55774 16.2038 8.11865 14.4286 8.11865C12.6534 8.11865 11.2143 9.55774 11.2143 11.3329C11.2143 13.1081 12.6534 14.5472 14.4286 14.5472Z"
      fill="#393939"
    />
    <path
      d="M14.4286 16.6901C17.979 16.6901 20.8571 19.5683 20.8571 23.1187H8C8 19.5683 10.8782 16.6901 14.4286 16.6901Z"
      fill="#393939"
    />
    <path
      d="M23 12.4044C23 11.8126 22.5203 11.3329 21.9286 11.3329C21.3368 11.3329 20.8571 11.8126 20.8571 12.4044V13.4758H19.7857C19.194 13.4758 18.7143 13.9555 18.7143 14.5472C18.7143 15.139 19.194 15.6187 19.7857 15.6187H20.8571V16.6901C20.8571 17.2818 21.3368 17.7615 21.9286 17.7615C22.5203 17.7615 23 17.2818 23 16.6901V15.6187H24.0714C24.6632 15.6187 25.1429 15.139 25.1429 14.5472C25.1429 13.9555 24.6632 13.4758 24.0714 13.4758H23V12.4044Z"
      fill="#393939"
    />
    <circle cx="15" cy="15.1187" r="14" stroke="#393939" strokeWidth="2" />
  </svg>
);

export default ClientPlus;

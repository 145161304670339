import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const BudgetDuringInspection = props => (
  <SvgIcon viewBox="0 0 34.016 34.016" {...props} xmlns="http://www.w3.org/2000/svg">
    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0177 8.15571C13.0177 7.5991 13.463 7.15381 14.0196 7.15381H16.0133C16.5699 7.15381 17.0152 7.5991 17.0152 8.15571C17.0152 8.71232 16.5699 9.1576 16.0133 9.1576H14.0196C13.463 9.14748 13.0177 8.7022 13.0177 8.15571Z"
        fill="#393939"
      />
      <path
        d="M21.0127 10.1494V10.4631C20.9823 10.4631 20.9519 10.4631 20.9216 10.4631C20.3751 10.4631 19.8589 10.6756 19.4643 11.0703L13.1796 17.3549L13.129 17.5472L12.6129 19.5713C12.5319 19.885 12.6028 20.2088 12.7951 20.4618C12.9975 20.7148 13.291 20.8565 13.6047 20.8565C13.6856 20.8565 13.7767 20.8464 13.8577 20.8262L15.8817 20.31L16.074 20.2594L20.9924 15.341V21.15C20.9924 22.2531 20.1018 23.1538 18.9987 23.1538H11.0038C9.9007 23.1538 9 22.2632 9 21.15V10.1494C9 9.04628 9.89058 8.14559 11.0038 8.14559C11.0038 9.8053 12.3498 11.1412 13.9994 11.1412H16.0032C17.6629 11.1412 18.9987 9.79518 18.9987 8.14559C20.112 8.15571 21.0127 9.04628 21.0127 10.1494Z"
        fill="#393939"
      />
      <path
        d="M21.8729 13.4688L21.5693 13.7724L19.6667 11.8698L19.9703 11.5662C20.2334 11.3031 20.5775 11.1715 20.9216 11.1715C20.9519 11.1715 20.9823 11.1715 21.0127 11.1715C21.3264 11.1918 21.63 11.3233 21.8729 11.5662C22.3991 12.0823 22.3991 12.9426 21.8729 13.4688Z"
        fill="#393939"
      />
      <path d="M21.0127 14.2076V14.329L21.0734 14.2683L21.0127 14.2076Z" fill="#393939" />
      <path
        d="M21.0127 14.2076V14.329L15.74 19.6016L13.716 20.1178C13.6856 20.1279 13.6654 20.1279 13.635 20.1279C13.6148 20.1279 13.5946 20.1279 13.5743 20.1178C13.5541 20.1178 13.5338 20.1076 13.5136 20.0975C13.4934 20.0874 13.4731 20.0773 13.463 20.0672C13.4529 20.057 13.4326 20.0469 13.4124 20.0267C13.3314 19.9457 13.3011 19.8344 13.3314 19.7231L13.8476 17.699L19.1809 12.3657L21.0127 14.2076Z"
        fill="#393939"
      />
      <path d="M21.0734 14.2683L21.0127 14.329V14.2076L21.0734 14.2683Z" fill="#393939" />
      <circle cx="15" cy="15.1538" r="14" stroke="#393939" strokeWidth="2" />
    </svg>
  </SvgIcon>
);

BudgetDuringInspection.displayName = 'BudgetDuringInspection';
BudgetDuringInspection.muiName = 'SvgIcon';

export default BudgetDuringInspection;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let TypeCurtaTemporada = props => (
  <SvgIcon {...props} fill="none" viewBox="0 0 19 21">
    <path
      d="M9.61165 2.50485V1.01942C9.61165 0.174757 9.43689 0 8.56311 0H7.74757C6.87379 0 6.69903 0.174757 6.69903 1.01942V2.53398L4.07767 3.52427V5.53398H12.233V3.52427L9.61165 2.50485Z"
      fill="#9B202F"
    />
    <path
      d="M16.0194 2.94175V9.32039H15.7573C15.699 8.82524 15.4951 8.38835 15.1456 8.03884C14.767 7.66019 14.2136 7.42719 13.6602 7.42719H11.1262C10.5728 7.42719 10.0485 7.63107 9.64078 8.03884C9.29126 8.38835 9.08738 8.85437 9.02913 9.32039H7.31068C6.17476 9.32039 5.21359 10.2524 5.21359 11.4175V17.7379C5.21359 18.2913 5.41748 18.8155 5.82524 19.2233C6.20388 19.6311 6.72816 19.835 7.31068 19.835H15.932C15.7864 20.5049 15.1748 21 14.4757 21H1.51456C0.699029 20.9709 0 20.3301 0 19.4854V3.23301C0 2.38835 0.699029 1.74757 1.51456 1.74757H5.47573L2.91262 2.73786V6.69903H13.1068V2.4466L10.6893 1.45631H14.5631C15.3495 1.45631 16.0194 2.12621 16.0194 2.94175Z"
      fill="#9B202F"
    />
    <path
      d="M18.7573 11.4175V17.7379C18.7573 18.0874 18.6117 18.3786 18.3786 18.6408C18.1456 18.8738 17.8252 19.0194 17.4757 19.0194H16.835V10.1359H17.4757C17.7961 10.1359 18.1165 10.2816 18.3786 10.5146C18.6117 10.7476 18.7573 11.068 18.7573 11.4175Z"
      fill="#9B202F"
    />
    <path
      d="M8.00971 10.1359V18.9903H7.36893C7.01942 18.9903 6.72816 18.8447 6.46602 18.6117C6.23301 18.3786 6.11651 18.0583 6.11651 17.7379V11.4175C6.11651 10.7184 6.69903 10.165 7.36893 10.165H8.00971V10.1359Z"
      fill="#9B202F"
    />
    <path
      d="M14.9417 10.1359V9.49515C14.9417 9.17476 14.7961 8.85437 14.5631 8.59223C14.3301 8.35922 14.0097 8.21359 13.6602 8.21359H11.1262C10.8058 8.21359 10.4854 8.35922 10.2233 8.59223C9.99029 8.82524 9.84466 9.14563 9.84466 9.49515V10.1359H8.59223V18.9903H16.165V10.1359H14.9417ZM13.6893 10.1359H11.1553V9.49515H13.6893V10.1359Z"
      fill="#9B202F"
    />
  </SvgIcon>
);

TypeCurtaTemporada = pure(TypeCurtaTemporada);
TypeCurtaTemporada.displayName = 'TypeCurtaTemporada';
TypeCurtaTemporada.muiName = 'SvgIcon';

export default TypeCurtaTemporada;

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const styles = {
  bootstrapInputCost: {
    borderRadius: 4,
    fontSize: 'calc(6px + 0.4vw)',
    padding: '8px 12px',
    width: 'calc(100% - 24px)',
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    fontFamily: 'Open Sans',
    backgroundColor: '#F4F3F4',
  },
  cost: {
    fontFamily: 'Open Sans',
    color: '#858585',
    fontSize: 'calc(6px + 0.3vw)',
    textAlign: 'left',
  },
  size: {
    height: 20,
  },
};

const NoAvailablePackages = ({ classes, data, handleSelectPackage, selectedPackage, intl }) => {
  const items = data.candidatePackages.map((item, index) => (
    <Grid key={index} item xs={12}>
      <Grid container spacing={16}>
        <Grid item xs={6}>
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <Radio
                checked={selectedPackage === item.id.toString()}
                onChange={handleSelectPackage}
                value={item.id.toString()}
                color="primary"
                disableRipple
                className={classes.size}
              />
            </Grid>
            <Grid item xs={10}>
              <div style={styles.cost}>
                <TextField
                  type="text"
                  value={item.name}
                  disabled
                  fullWidth
                  data-cy={item.name}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.bootstrapInputCost,
                    },
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <div style={styles.cost}>
                <TextField
                  type="text"
                  value={`${item.standardMeters.formattedValue} créditos`}
                  disabled
                  fullWidth
                  data-cy={item.standardMeters.formattedValue}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.bootstrapInputCost,
                    },
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ));
  return (
    <Grid container alignItems="center">
      <Grid container spacing={16}>
        <Grid item xs={6}>
          <div style={styles.cost}>
            <FormattedMessage id="package" />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div style={styles.cost}>
            <FormattedMessage id="area" />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        {items}
        <Grid item xs={12}>
          <Grid container spacing={16}>
            <Grid item xs={6}>
              <Grid container alignItems="center">
                <Grid item xs={2}>
                  <Radio
                    checked={selectedPackage === ''}
                    onChange={handleSelectPackage}
                    value=""
                    color="primary"
                    disableRipple
                    className={classes.size}
                    data-cy="select-package"
                  />
                </Grid>
                <Grid item xs={10}>
                  <div style={styles.cost}>
                    <TextField
                      type="text"
                      value={intl.formatMessage({ id: 'INDIVIDUAL_INSPECTION' })}
                      disabled
                      data-cy="individual-inspection"
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          input: classes.bootstrapInputCost,
                        },
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {data.monetaryPrice?.standardMeters?.formattedValue && (
              <Grid item xs={6}>
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    <div style={styles.cost}>
                      <TextField
                        type="text"
                        value={`${data.monetaryPrice.standardMeters.formattedValue} créditos`}
                        disabled
                        fullWidth
                        data-cy={data.monetaryPrice.standardMeters.formattedValue}
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            input: classes.bootstrapInputCost,
                          },
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

NoAvailablePackages.propTypes = {
  /**
   * Function to handle the select of a package
   */
  handleSelectPackage: PropTypes.func.isRequired,
  /**
   * Value to know the current select package
   */
  selectedPackage: PropTypes.number,
  /**
   * Data of the package consume and candidatePackages
   */
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default withStyles(styles)(injectIntl(NoAvailablePackages));

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import AddIcon from '@material-ui/icons/Add';
import RmIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';

import styles from 'src/orderForm/fields/DocuSignSigners/styles.module.css';
import Client from 'src/svg-icons/client';

/*
    Data example:
      [
        {name: 'Duyle Campos', email: 'duyle.campos@redevistorias.com.br', status: 'created'},
        {name: 'Mateus Silva', email: 'mateus.silva@redevistorias.com.br', status: 'created'}
      ]
*/
class DocuSignSigners extends React.Component {
  //  create signers atribute in state to manage formData information
  constructor(props) {
    super(props);
    const signers = props.formData ? [...props.formData] : [];
    this.state = {
      signers,
      errors: [
        {
          name: false,
          email: false,
        },
      ],
      emailErrorMessage: 'requiredEmail',
      nameErrorMessage: 'requiredField',
      isEditing: this.isEditing(),
    };
    this.onChange(signers);
  }
  /*
      onChange method returns an onChange event and send changin info to parent
      signers out of event for resources maintenance
  */

  onChange(propertyName, index) {
    const { signers } = this.state;
    const { onChange } = this.props;
    return event => {
      signers[index][propertyName] = event.target.value;
      if (signers[index].id) {
        signers[index].status = 'updated';
      } else if (signers[index].status === undefined) {
        signers[index].status = 'created';
      }
      this.setState({ signers });
      onChange(signers);
    };
  }

  // hanClick triggered when button add is clicked

  handleClick = () => {
    const { signers, errors } = this.state;
    const { onChange } = this.props;
    signers.push({ name: '', email: '', status: 'created' });
    errors.push({
      name: false,
      email: false,
    });
    this.setState({
      signers,
      errors,
    });
    onChange(signers);
  };

  /*
      removeItem triggered when RM button is clicked
      send `splice` information to this.props.onChange
      update state
  */
  removeItem = index => () => {
    const { signers } = this.state;
    const { onChange } = this.props;
    if (signers.length === 1 && signers[index].id === undefined) {
      signers[0].name = '';
      signers[0].email = '';
      signers[0].status = undefined;
    } else if (signers[index] !== undefined && signers[index].id !== undefined) {
      signers[index].status = 'deleted';
    } else {
      signers.splice(index, 1);
    }
    this.setState({ signers });
    onChange(signers);
  };

  /*
      isError method check input data
      return bool value to handle TextField error parameter
  */
  isErrorName = index => {
    const { errorSchema, formData } = this.props;
    if (errorSchema[index]) {
      return formData[index].name === '';
    }
    return false;
  };

  isErrorEmail = index => {
    const { errorSchema, formData } = this.props;
    const check = /^[^\s@]+@[^\s@]+\.[^\s@]+$/g;
    if (errorSchema[index]) {
      return !check.test(String(formData[index].email));
    }
    return false;
  };

  /*
      errorMassage control error flow
      add error message to TextFiled error parameter
  */
  errorMessage = (propertieName, index) => {
    const { errorSchema } = this.props;
    if (this.isErrorName(index) || this.isErrorEmail(index)) {
      if (
        errorSchema[index] &&
        errorSchema[index][propertieName].__errors // eslint-disable-line no-underscore-dangle
      ) {
        return errorSchema[index][propertieName].__errors[0]; // eslint-disable-line no-underscore-dangle
      }
    }
    return '';
  };

  disableDelete = () => {
    const { signers } = this.state;
    return (
      (signers[0].name === '' && signers[0].email === '' && signers.length === 1) ||
      (signers[0].name === undefined && signers[0].email === undefined && signers.length === 1)
    );
  };

  isEditing = () => {
    const { formData } = this.props;
    return !!formData;
  };

  render() {
    const { emailErrorMessage, isEditing, nameErrorMessage, signers } = this.state;
    const { name } = this.props;

    if (signers.length < 1) {
      this.setState({
        signers: [
          {
            name: '',
            email: '',
            status: undefined,
          },
        ],
      });
      this.onChange(signers);
    }

    return (
      <Grid container direction="row" className={styles.rootContainer}>
        <Client viewBox="0 0 34.016 34.016" />
        <Grid item xs={11}>
          {signers &&
            signers
              .filter(signer => signer.status !== 'deleted')
              .map((signer, index) => (
                <div className={styles.signersSpacing} key={index}>
                  <div className={styles.flexHeader}>
                    <p className={styles.title}>
                      <FormattedMessage id={name} /> {index + 1}
                    </p>
                    <Button
                      disabled={this.disableDelete() || isEditing}
                      variant="fab"
                      mini
                      classes={{
                        mini: styles.removeButton,
                        disabled: styles.removeButtonDisabled,
                      }}
                      onClick={this.removeItem(index)}
                      data-cy={`${index}-signer-remove`}
                    >
                      <RmIcon />
                    </Button>
                  </div>
                  <div className={styles.signerPadding}>
                    <div>
                      <span className={styles.label}>
                        <FormattedMessage id="recipients.Signer.name" />*
                      </span>
                      <FormControl error={this.isErrorName(index)} fullWidth>
                        <Input
                          classes={{
                            input: this.isErrorName(index) ? styles.bootstrapInputError : styles.bootstrapInput,
                          }}
                          fullWidth
                          type="text"
                          value={signer.name}
                          onChange={this.onChange('name', index)}
                          disabled={
                            signer.status &&
                            signer.status !== 'pending' &&
                            signer.status !== 'created' &&
                            signer.status !== 'updated'
                          }
                          disableUnderline
                          data-cy={`${index}-signer-name`}
                        />
                        <FormHelperText
                          classes={{
                            error: styles.error,
                          }}
                        >
                          {this.isErrorName(index) ? (
                            <ul>
                              <li>
                                <FormattedMessage id={nameErrorMessage} />
                              </li>
                            </ul>
                          ) : null}
                        </FormHelperText>
                      </FormControl>
                    </div>
                    <div>
                      <span className={styles.label}>
                        <FormattedMessage id="recipients.email" />*
                      </span>
                      <FormControl error={this.isErrorEmail(index)} fullWidth>
                        <Input
                          classes={{
                            input: this.isErrorEmail(index) ? styles.bootstrapInputError : styles.bootstrapInput,
                          }}
                          fullWidth
                          type="email"
                          value={signer.email}
                          onChange={this.onChange('email', index)}
                          disabled={
                            signer.status &&
                            signer.status !== 'pending' &&
                            signer.status !== 'created' &&
                            signer.status !== 'updated'
                          }
                          disableUnderline
                          data-cy={`${index}-signer-email`}
                        />
                        <FormHelperText
                          classes={{
                            error: styles.error,
                          }}
                        >
                          {this.isErrorEmail(index) ? (
                            <ul>
                              <li>
                                <FormattedMessage id={emailErrorMessage} />
                              </li>
                            </ul>
                          ) : null}
                        </FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                </div>
              ))}
        </Grid>
        {!isEditing ? (
          <div className={styles.grayLines}>
            <Button
              variant="fab"
              mini
              onClick={this.handleClick}
              classes={{ mini: styles.plusButton }}
              data-cy="add-signer"
            >
              <AddIcon />
            </Button>
          </div>
        ) : null}
      </Grid>
    );
  }
}

DocuSignSigners.propTypes = {
  formData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      id: PropTypes.number,
      status: PropTypes.string,
    })
  ),
};

DocuSignSigners.defaultProps = {
  formData: null,
};

export default injectIntl(DocuSignSigners);

import { DASHBOARD_BASE_URL } from 'src/config';

function getDashboardLink() {
  const accessToken = sessionStorage.getItem('accessToken');
  const tokenType = sessionStorage.getItem('tokenType');

  window.open(`${DASHBOARD_BASE_URL}#access_token=${accessToken}&token_type=${tokenType}&expires_in=`, '_blank');
}

export default getDashboardLink;

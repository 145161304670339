import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import Checkbox from 'src/components/common/elements/Checkbox';
import classes from 'src/orderForm/widgets/MultipleCheckboxes/style.module.scss';

const MultipleCheckboxes = ({ schema, options, onChange, value }) => {
  const isMounted = useRef(false);
  const [selectedRooms, setSelectedRooms] = useState(value);
  const { enumOptions: rooms } = options;

  const handleChange = evt => {
    const { checked, value: targetValue } = evt.target;
    const tempSelectedRooms = [...selectedRooms];

    if (checked) {
      tempSelectedRooms.push(targetValue);
    } else {
      tempSelectedRooms.splice(tempSelectedRooms.indexOf(targetValue), 1);
    }

    setSelectedRooms(tempSelectedRooms);
  };

  useEffect(() => {
    isMounted.current = true;
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      onChange(selectedRooms);
    }
  }, [onChange, selectedRooms]);

  return (
    <div className={classes.multipleCheckboxes}>
      <div className={classes.checkboxList}>
        {rooms.map(room => (
          <div className={classes.checkbox} key={room.value}>
            <label>
              <Checkbox
                className={classes.inputCheckbox}
                id={`checkbox-${room.value}`}
                data-cy={`checkbox-${room.value}`}
                name={schema.title}
                value={room.value}
                checked={selectedRooms.includes(room.value)}
                onChange={handleChange}
              />
              {room.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

MultipleCheckboxes.propTypes = {
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  schema: PropTypes.object.isRequired,
  value: PropTypes.array,
};

MultipleCheckboxes.defaultProps = {
  value: [],
};

export default MultipleCheckboxes;

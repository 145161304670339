import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import classes from 'src/components/SchedulePage/Timeline/PendingOrders/SortSwitcher/style.module.scss';
import { sortPendingOrders } from 'src/store/ducks/schedule';

const SortSwitcher = () => {
  const [isOlderDateSorted, setIsOlderDateSorted] = useState(false);

  const dispatch = useDispatch();

  const handleSortPendingOrders = () => {
    dispatch(sortPendingOrders({ older: !isOlderDateSorted }));

    setIsOlderDateSorted(state => !state);
  };

  const sortTypeText = isOlderDateSorted ? 'Mais novas' : 'Mais antigas';

  return (
    <div className={classes.sortSwitcher}>
      <h4>Vistorias disponíveis</h4>

      <button type="button" onClick={handleSortPendingOrders}>
        {sortTypeText} <div className={`${classes.arrowDown} ${isOlderDateSorted ? classes.rotateArrow : ''}`} />
      </button>
    </div>
  );
};

export default SortSwitcher;

import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { format } from 'date-fns';

import API from 'src/apiRequest';
import ExportGeneralStatisticsComponent from 'src/components/InspectorsPage/InspectorList/ExportGeneralStatistics/ExportGeneralStatisticsComponent';
import { showToast } from 'src/store/ducks/toasts';

const ExportGeneralStatisticsContainer = () => {
  const [isBusy, setIsBusy] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const dispatch = useDispatch();
  const intl = useIntl();

  const handleStartDateChange = useCallback(date => {
    setStartDate(date);
  }, []);

  const handleEndDateChange = useCallback(date => {
    setEndDate(date);
  }, []);

  const handleClick = useCallback(async () => {
    if (!startDate || !endDate) {
      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'EXPORT_STATISTICS_ERROR_MESSAGE' }),
          duration: 5000,
        })
      );

      return;
    }

    setIsBusy(true);

    try {
      const {data} = await API.get('/inspectors/inspections', {
        params: {
          start_date: format(new Date(startDate), 'yyyy-MM-dd'),
          end_date: format(new Date(endDate), 'yyyy-MM-dd'),
        },
      });
      dispatch(
        showToast({
          type: 'success',
          title: intl.formatMessage({ id: 'inspectionsTitle' }),
          text: intl.formatMessage({ id: 'inspectionsSummary' }),
          link: data.file,
        })
      );
    } catch (err) {
      console.log(err);
    } finally {
      setIsBusy(false);
    }
  }, [dispatch, endDate, intl, startDate]);

  useEffect(() => {
    const today = new Date();
    const oneMonthAgo = today.setDate(today.getDate() - 30);

    setStartDate(oneMonthAgo);
    setEndDate(new Date());
  }, []);

  return (
    <ExportGeneralStatisticsComponent
      isBusy={isBusy}
      onClick={handleClick}
      startDate={startDate}
      endDate={endDate}
      handleStartDateChange={handleStartDateChange}
      handleEndDateChange={handleEndDateChange}
      intl={intl}
    />
  );
};

export default ExportGeneralStatisticsContainer;

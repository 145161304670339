import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { format, getDay, isEqual } from 'date-fns';

import API from 'src/apiRequest';
import InspectorFiltersDetailsComponent from 'src/components/InspectorsPage/InspectorFiltersDetails/InspectorFiltersDetailsComponent';
import { useConfirmAlert } from 'src/contexts/ConfirmAlertContext';
import { useUser } from 'src/contexts/UserContext';
import AddressService from 'src/services/addressService';
import { showToast } from 'src/store/ducks/toasts';

const language = 'pt-BR';

const placeholderDateMapper = {
  sunday: '1969-12-28',
  monday: '1969-12-29',
  tuesday: '1969-12-30',
  wednesday: '1969-12-31',
  thursday: '1970-01-01',
  friday: '1970-01-02',
  saturday: '1970-01-03',
};

const InspectorFiltersDetailsContainer = () => {
  const [isSubmitingData, setIsSubmitingData] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const [orderTypes, setOrderTypes] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);

  const [loadedCities, setLoadedCities] = useState([]);
  const [loadedCitiesPage, setLoadedCitiesPage] = useState(0);

  const [loadedNeighborhoods, setLoadedNeighborhoods] = useState([]);
  const [loadedNeighborhoodsPage, setLoadedNeighborhoodsPage] = useState(0);

  const [selectedNeighborhoods, setSelectedNeighborhoods] = useState([]);
  const [selectedOrderTypes, setSelectedOrderTypes] = useState([]);

  const [isCityLoading, setIsCityLoading] = useState(false);
  const [isCitySelectLoading, setIsCitySelectLoading] = useState(false);

  const [isNeighborhoodLoading, setIsNeighborhoodLoading] = useState(false);
  const [isNeighborhoodSelectLoading, setIsNeighborhoodSelectLoading] = useState(false);

  const [currentCity, setCurrentCity] = useState('');

  const [schedules, setSchedules] = useState({
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
  });

  const { id } = useParams();
  const { push } = useHistory();

  const dispatch = useDispatch();
  const intl = useIntl();

  const { openConfirmAlert } = useConfirmAlert();

  const me = useUser();
  const addressService = new AddressService(me?.profile?.country);

  const handleFetchCities = useCallback(
    async state => {
      try {
        if (!state) {
          setCities([]);
          setNeighborhoods([]);
          setLoadedCities([]);
          setLoadedNeighborhoods([]);

          return;
        }

        setIsCityLoading(true);

        const citiesResponse = await addressService.getCitiesByState(state?.value?.id);

        setCities(
          citiesResponse.map(city => ({
            value: city,
            label: city?.name,
            state: state?.label,
          }))
        );
        setLoadedCities(
          citiesResponse.slice(0, 30).map(city => ({
            value: city,
            label: city?.name,
            state: state?.label,
          }))
        );
        setLoadedCitiesPage(1);
        setNeighborhoods([]);
        setLoadedNeighborhoods([]);
      } catch {
        dispatch(
          showToast({
            type: 'error',
            title: intl.formatMessage({ id: 'ERROR' }),
            text: intl.formatMessage({ id: 'CITIES_LOAD_LIST_ERROR' }),
            duration: 5000,
          })
        );
      } finally {
        setIsCityLoading(false);
      }
    },
    [dispatch, intl]
  );

  const handleFetchNeighborhoods = useCallback(
    async city => {
      try {
        if (!city) {
          setNeighborhoods([]);
          setLoadedNeighborhoods([]);
          setCurrentCity('');

          return;
        }

        setIsNeighborhoodLoading(true);

        const neighborhoodsResponse = await addressService.getNeighborhoodsByCity(city?.value?.id);
        setNeighborhoods(
          neighborhoodsResponse.map((neighborhood, index) => ({
            value: {
              id: neighborhood?.id,
              name: neighborhood?.name,
              tokenized: neighborhood?.tokenized,
              index,
              city: city?.label,
              state: city?.state,
            },
            label: neighborhood?.name,
          }))
        );
        setLoadedNeighborhoods(
          neighborhoodsResponse.slice(0, 30).map((neighborhood, index) => ({
            value: {
              id: neighborhood?.id,
              name: neighborhood?.name,
              tokenized: neighborhood?.tokenized,
              index,
              city: city?.label,
              state: city?.state,
            },
            label: neighborhood?.name,
          }))
        );
        setLoadedNeighborhoodsPage(1);
        setCurrentCity(city?.label);
      } catch {
        dispatch(
          showToast({
            type: 'error',
            title: intl.formatMessage({ id: 'ERROR' }),
            text: intl.formatMessage({ id: 'NEIGHBORHOOD_LOAD_LIST_ERROR' }),
            duration: 5000,
          })
        );
      } finally {
        setIsNeighborhoodLoading(false);
      }
    },
    [dispatch, intl]
  );

  const handleFilterOptions = useCallback(
    (inputValue, itemType) => {
      const normalizeString = str => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      };

      const normalizedInput = normalizeString(inputValue.toLowerCase());

      const handleFilterOptionsStrategy = {
        cities: () => {
          if (cities.length) {
            const citiesFounded = cities.filter(city =>
              normalizeString(city?.label.toLowerCase()).includes(normalizedInput)
            );

            return citiesFounded;
          }

          return [];
        },
        neighborhoods: () => {
          if (neighborhoods.length) {
            const neighborhoodsFounded = neighborhoods.filter(neigborhood =>
              normalizeString(neigborhood?.label.toLowerCase()).includes(normalizedInput)
            );

            return neighborhoodsFounded;
          }

          return [];
        },
      };

      return handleFilterOptionsStrategy[itemType]();
    },
    [cities, neighborhoods]
  );

  const handleLoadOptions = useCallback(
    (inputValue, callback, itemType) => {
      try {
        return callback(handleFilterOptions(inputValue, itemType));
      } catch {
        return [];
      }
    },
    [handleFilterOptions]
  );

  const select = useCallback((item, index, filterType) => {
    const selectFiltersStrategy = {
      'order-types': () => {
        setOrderTypes(previousOrderTypes => {
          previousOrderTypes.splice(index, 1);

          return previousOrderTypes;
        });

        setSelectedOrderTypes(previousSelectedOrderTypes => {
          return [...previousSelectedOrderTypes, item].sort((a, b) => {
            return String(a?.description).localeCompare(b?.description, language);
          });
        });
      },
      neighborhoods: () => {
        setLoadedNeighborhoods(previousNeighborhoods => {
          const filteredNeighborhoods = previousNeighborhoods.filter(
            previousNeighborhood => previousNeighborhood?.value?.id !== item?.value?.id
          );

          return filteredNeighborhoods;
        });

        setSelectedNeighborhoods(previousSelectedNeighborhoods => {
          return [...previousSelectedNeighborhoods, item].sort((a, b) => {
            return String(a?.value?.tokenized).localeCompare(b?.value?.tokenized, language);
          });
        });
      },
    };

    selectFiltersStrategy[filterType]();
  }, []);

  const unselect = useCallback(
    (item, index, filterType) => {
      const unselectFiltersStrategy = {
        'order-types': () => {
          setSelectedOrderTypes(previousOrderTypes => {
            previousOrderTypes.splice(index, 1);

            return previousOrderTypes;
          });

          setOrderTypes(previousOrderTypes => {
            return [...previousOrderTypes, item].sort((a, b) => {
              return String(a?.description).localeCompare(b?.description, language);
            });
          });
        },
        neighborhoods: () => {
          setSelectedNeighborhoods(
            selectedNeighborhoods.filter(selectedNeighborhood => {
              return selectedNeighborhood?.value?.id !== item?.value?.id;
            })
          );

          if (currentCity && String(item?.value?.city).localeCompare(currentCity, language) === 0) {
            setLoadedNeighborhoods(previousNeighborhoods => {
              return [...previousNeighborhoods, item].sort((a, b) => {
                return String(a?.value?.tokenized).localeCompare(b?.value?.tokenized, language);
              });
            });
          }
        },
      };

      unselectFiltersStrategy[filterType]();
    },
    [currentCity, selectedNeighborhoods]
  );

  const handleSelectAllNeighborhoods = useCallback(() => {
    if (loadedNeighborhoods?.length) {
      setSelectedNeighborhoods(oldState => {
        const neighborhoodsToAdd = loadedNeighborhoods.filter(loadedNeighborhood => {
          return !oldState.some(selectedNeighborhood => {
            return Number(selectedNeighborhood?.value?.id) === Number(loadedNeighborhood?.value?.id);
          });
        });

        return [...oldState, ...neighborhoodsToAdd];
      });

      setLoadedNeighborhoods([]);
    }
  }, [loadedNeighborhoods]);

  const handleFiltersSettingsFormSubmit = useCallback(
    async event => {
      event.preventDefault();

      setIsSubmitingData(true);

      const uniqueLocations = selectedNeighborhoods.reduce((acc, selectedNeighborhood) => {
        return acc.concat(
          acc.find(neighborhood => neighborhood?.value?.id === selectedNeighborhood?.value?.id)
            ? []
            : [selectedNeighborhood]
        );
      }, []);

      const uniqueOrderTypes = selectedOrderTypes.reduce((acc, selectedOrderType) => {
        return acc.concat(acc.find(orderType => orderType?.id === selectedOrderType?.id) ? [] : [selectedOrderType]);
      }, []);

      const filterSettings = {
        orderTypes: uniqueOrderTypes,
        locations: uniqueLocations.map(neighborhood => ({
          id: neighborhood?.value?.id,
          description: `${neighborhood?.value?.name}, ${neighborhood?.value?.city} - ${neighborhood?.value?.state}`,
        })),
      };

      const updatedSchedules = {};

      Object.entries(schedules).forEach(([day, schdls]) => {
        updatedSchedules[day] = schdls.map(schedule => ({
          start_time: format(schedule.start, 'HH:mm'),
          end_time: format(schedule.end, 'HH:mm'),
        }));
      });

      try {
        await API.post(`/inspectors/${id}/filters-settings`, filterSettings);
        await API.post(`/inspectors/${id}/availability`, updatedSchedules);

        dispatch(
          showToast({
            type: 'success',
            title: intl.formatMessage({ id: 'SUCCESS' }),
            text: intl.formatMessage({ id: 'FILTER_SETTINGS_SAVE_SUCCESS' }),
            duration: 5000,
          })
        );

        push(`/inspectors/active/${id}`);
      } catch {
        dispatch(
          showToast({
            type: 'error',
            title: intl.formatMessage({ id: 'ERROR' }),
            text: intl.formatMessage({ id: 'FILTER_SETTINGS_SAVE_ERROR' }),
            duration: 5000,
          })
        );
      } finally {
        setIsSubmitingData(false);
      }
    },
    [dispatch, id, intl, push, schedules, selectedNeighborhoods, selectedOrderTypes]
  );

  const handleLoadMoreCities = useCallback(() => {
    try {
      if (cities.length === loadedCities.length) {
        return;
      }

      setIsCitySelectLoading(true);

      const moreThirtyCityItems = cities.slice(loadedCitiesPage * 30, loadedCitiesPage * 30 + 30);

      setLoadedCities(prevLoadedCities => [...prevLoadedCities, ...moreThirtyCityItems]);
      setLoadedCitiesPage(prevLoadedCitiesPage => prevLoadedCitiesPage + 1);
    } catch {
      setLoadedCities([]);
    } finally {
      setTimeout(() => {
        setIsCitySelectLoading(false);
      }, 500);
    }
  }, [cities, loadedCities.length, loadedCitiesPage]);

  const handleLoadMoreNeighborhoods = useCallback(() => {
    try {
      if (neighborhoods.length === loadedNeighborhoods.length) {
        return;
      }

      setIsNeighborhoodSelectLoading(true);

      const moreThirtyNeighborhoodsItems = neighborhoods.slice(
        loadedNeighborhoodsPage * 30,
        loadedNeighborhoodsPage * 30 + 30
      );

      setLoadedNeighborhoods(prevLoadedNeighborhoods => [...prevLoadedNeighborhoods, ...moreThirtyNeighborhoodsItems]);
      setLoadedNeighborhoodsPage(prevLoadedNeighborhoodsPage => prevLoadedNeighborhoodsPage + 1);
    } catch {
      setLoadedNeighborhoods([]);
    } finally {
      setTimeout(() => {
        setIsNeighborhoodSelectLoading(false);
      }, 500);
    }
  }, [loadedNeighborhoods.length, loadedNeighborhoodsPage, neighborhoods]);

  const handleSelectCalendarDateRange = useCallback(
    ({ start, end }) => {
      const dayOfThatSchedule = Object.keys(schedules)[getDay(start)];

      const withNewSchedule = [...schedules[dayOfThatSchedule], { start, end }];
      const sortedSchedules = withNewSchedule.slice().sort((a, b) => {
        return a.start > b.start ? 1 : -1;
      });

      let mergedSchedules = [sortedSchedules[0]];

      for (let i = 1; i < sortedSchedules.length; i++) {
        let currentSchedule = sortedSchedules[i];
        let lastMergedSchedule = mergedSchedules[mergedSchedules.length - 1];

        if (currentSchedule.start <= lastMergedSchedule.end) {
          if (lastMergedSchedule.end <= currentSchedule.end) {
            lastMergedSchedule.end = currentSchedule.end;
          }
        } else {
          mergedSchedules.push(currentSchedule);
        }
      }

      setSchedules(oldState => ({
        ...oldState,
        [dayOfThatSchedule]: mergedSchedules,
      }));
    },
    [schedules]
  );

  const handleDeleteCalendarDateRange = useCallback(
    ({ start, end }) => {
      const dayOfThatSchedule = Object.keys(schedules)[getDay(start)];

      openConfirmAlert({
        message: intl.formatMessage({
          id: 'WANT_TO_REMOVE_SELECTED_TIME_MESSAGE',
        }),
        onConfirm: () => {
          setSchedules(oldState => {
            const indexToDelete = oldState[dayOfThatSchedule].findIndex(
              schedule => isEqual(schedule.start, start) && isEqual(schedule.end, end)
            );

            if (indexToDelete !== -1) {
              oldState[dayOfThatSchedule].splice(indexToDelete, 1);
            }

            return oldState;
          });
        },
      });
    },
    [intl, openConfirmAlert, schedules]
  );

  useEffect(() => {
    setIsLoadingData(true);

    Promise.all([
      API.get('/filters/schedule'),
      addressService.getStates(),
      API.get(`/inspectors/${id}/filters-settings`),
      API.get(`/inspectors/${id}/availability`),
    ])
      .then(([availableFiltersResponse, statesResponse, selectedFiltersResponse, selectedSchedulesResponse]) => {
        if (statesResponse?.length) {
          setStates(
            statesResponse.map(state => ({
              value: state,
              label: state?.name,
            }))
          );
        }

        if (availableFiltersResponse.data) {
          const previouslySelectedLocations = selectedFiltersResponse.data?.locations
            .map((location, index) => {
              const [cityAndNeighborhood, stateUf] = location.description.split('-').map(str => str.trim());
              const [neighborhoodName, cityName] = cityAndNeighborhood.split(',').map(str => str.trim());

              const tokenized = neighborhoodName
                ?.normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toUpperCase();

              return {
                value: {
                  id: location?.id,
                  name: neighborhoodName,
                  tokenized,
                  index,
                  city: cityName,
                  state: stateUf,
                },
                label: neighborhoodName,
              };
            })
            .sort((a, b) => String(a?.value?.tokenized).localeCompare(b?.value?.tokenized, language));

          setSelectedNeighborhoods(previouslySelectedLocations);

          const unselectedOrderTypes = availableFiltersResponse.data?.orderTypes.filter(orderType => {
            return !selectedFiltersResponse.data?.orderTypes.some(selectedOrderType => {
              return String(selectedOrderType?.id) === String(orderType?.id);
            });
          });

          setOrderTypes(unselectedOrderTypes);
          setSelectedOrderTypes(selectedFiltersResponse.data?.orderTypes);

          const fetchedSchedules = {};

          Object.entries(selectedSchedulesResponse.data).forEach(([day, schdls]) => {
            fetchedSchedules[day] = schdls.map(schedule => ({
              start: new Date(`${placeholderDateMapper[day]} ${schedule.start_time}`),
              end: new Date(`${placeholderDateMapper[day]} ${schedule.end_time}`),
            }));
          });

          setSchedules(oldState => ({ ...oldState, ...fetchedSchedules }));
        }
      })
      .catch(() => {
        dispatch(
          showToast({
            type: 'error',
            title: intl.formatMessage({ id: 'ERROR' }),
            text: intl.formatMessage({ id: 'FILTER_SETTINGS_LOAD_LIST_ERROR' }),
            duration: 5000,
          })
        );
      })
      .finally(() => {
        setIsLoadingData(false);
      });
  }, [dispatch, id, intl]);

  return (
    <InspectorFiltersDetailsComponent
      isSubmitingData={isSubmitingData}
      isLoadingData={isLoadingData}
      inspectorId={id}
      select={select}
      handleSelectAllNeighborhoods={handleSelectAllNeighborhoods}
      unselect={unselect}
      orderTypes={orderTypes}
      states={states}
      cities={cities}
      neighborhoods={neighborhoods}
      loadedCities={loadedCities}
      loadedNeighborhoods={loadedNeighborhoods}
      selectedNeighborhoods={selectedNeighborhoods}
      selectedOrderTypes={selectedOrderTypes}
      isCityLoading={isCityLoading}
      isCitySelectLoading={isCitySelectLoading}
      isNeighborhoodLoading={isNeighborhoodLoading}
      isNeighborhoodSelectLoading={isNeighborhoodSelectLoading}
      handleFetchCities={handleFetchCities}
      handleFetchNeighborhoods={handleFetchNeighborhoods}
      handleLoadOptions={handleLoadOptions}
      handleSubmit={handleFiltersSettingsFormSubmit}
      handleLoadMoreCities={handleLoadMoreCities}
      handleLoadMoreNeighborhoods={handleLoadMoreNeighborhoods}
      handleSelectCalendarDateRange={handleSelectCalendarDateRange}
      handleDeleteCalendarDateRange={handleDeleteCalendarDateRange}
      schedules={schedules}
      intl={intl}
    />
  );
};

export default InspectorFiltersDetailsContainer;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const edit = props => (
  <SvgIcon {...props} viewBox="0 0 11 11">
    <path
      d="M8.3174 0.460261C8.93108 -0.15342 9.92606 -0.15342 10.5397 0.460261C11.1534 1.07394 11.1534 2.06891 10.5397 2.6826L9.91675 3.30558L7.69442 1.08325L8.3174 0.460261Z"
      fill="#393939"
    />
    <path d="M6.58325 2.19442L0 8.77766V11H2.22233L8.80558 4.41675L6.58325 2.19442Z" fill="#393939" />
  </SvgIcon>
);

edit.displayName = 'edit';
edit.muiName = 'SvgIcon';

export default edit;

import React from 'react';

import { withStyles } from '@material-ui/core/styles';

const styles = {
  error: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    textAlign: 'center',
    color: '#dc3545',
    fontWeight: 'normal',
  },
};

function FormError(props) {
  return <div style={styles.error}>{props.children}</div>;
}

export default withStyles(styles)(FormError);

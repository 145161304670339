import React from 'react';

import PropTypes from 'prop-types';

import classes from 'src/components/common/Spinner/style.module.scss';

const Spinner = ({ color }) => (
  <div className={classes.spinner} style={{ color }}>
    <svg viewBox="22 22 44 44">
      <circle cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6" />
    </svg>
  </div>
);

Spinner.propTypes = {
  color: PropTypes.string,
};

Spinner.defaultProps = {
  color: '#4c8fca',
};

export default Spinner;

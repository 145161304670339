import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const Recommendation = props => (
  <SvgIcon viewBox="0 0 34.016 34.016" {...props}>
    <path
      fill="#393939"
      d="M17,2A15,15,0,1,0,32,17,15,15,0,0,0,17,2Zm0,28A13,13,0,1,1,30,17,13,13,0,0,1,17,30Z"
      transform="translate(-2.01 -2.01)"
    />
    <circle fill="#393939" cx="12.64" cy="13.04" r="3.14" />
    <path
      fill="#393939"
      d="M14.65,19.76c-2.09,0-6.28,1-6.28,3.14v1.57H20.93V22.9C20.93,20.81,16.75,19.76,14.65,19.76Zm6.1-7.57-1.32,1.32a2.61,2.61,0,0,1,0,3.06l1.32,1.33a4,4,0,0,0,0-5.69Zm2.6-2.64-1.28,1.28a6.29,6.29,0,0,1,0,8.43l1.28,1.28a7.71,7.71,0,0,0,.07-10.91Z"
      transform="translate(-2.01 -2.01)"
    />
  </SvgIcon>
);
Recommendation.displayName = 'Recommendation';
Recommendation.muiName = 'SvgIcon';

export default Recommendation;

import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR'; // the locale you want
import PropTypes from 'prop-types';

import classes from 'src/components/SchedulePage/DateSwitch/style.module.scss';

registerLocale('pt-BR', ptBR);

const DateSwitcherComponent = ({ date, handleChange }) => (
  <div className={classes.dateSwitch}>
    <div className={classes.datePicker}>
      <DatePicker
        id="dateSwitch"
        autoComplete="off"
        locale="pt-BR"
        dateFormat="dd MMMM yyy"
        selected={date.toDate()}
        onChange={handleChange}
        selectsStart
      />
      <div className={classes.arrowDown} />
    </div>
  </div>
);

DateSwitcherComponent.propTypes = {
  date: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default DateSwitcherComponent;

import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/ClientSection.module.css';
import Client from 'src/svg-icons/client';

const ClientSection = ({ clients, creator }) => (
  <Grid item xs={12}>
    <div className={classes.Header}>
      <Client className={classes.Icon} />

      <FormattedMessage id="client" />
    </div>

    <Grid container spacing={8} className={classes.Information}>
      <Grid item xs={6}>
        <div className={classes.SubHeader}>
          <FormattedMessage id="name" />
        </div>

        <span data-cy="order-details-client">{clients.client}</span>
      </Grid>

      <Grid item xs={6}>
        <div className={classes.SubHeader}>
          <FormattedMessage id="username" />
        </div>

        <span data-cy="order-details-creator">{creator}</span>
      </Grid>

      {clients.client_origin_franchisee && (
        <Grid item xs={6}>
          <div className={classes.SubHeader}>
            <FormattedMessage id="CLIENT_ORIGIN_FRANCHISEE" />
          </div>

          <span data-cy="order-details-client-origin-franchisee">{clients.client_origin_franchisee}</span>
        </Grid>
      )}
    </Grid>
  </Grid>
);

ClientSection.propTypes = {
  clients: PropTypes.object.isRequired,
  creator: PropTypes.string,
};

ClientSection.detaultProps = {
  creator: null,
};

export default ClientSection;

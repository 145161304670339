import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import AsyncSelect from 'react-select/async';

import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import classes from 'src/components/OrdersReportsModal/style.module.scss';
import debounce from 'src/helpers/debounce';
import { showToast } from 'src/store/ducks/toasts';

const ClientsSelect = ({ handleSelectClient }) => {
  const [mappedClients, setMappedClients] = useState([]);
  const [clients, setClients] = useState([]);

  const [value, setValue] = useState('');

  const [currentPage, setCurrentPage] = useState(0);
  const [loadMore, setLoadMore] = useState(true);

  const [defaultMenuIsOpen, setDefaultMenuIsOpen] = useState(false);
  const [inputSearchValue, setInputSearchValue] = useState('');
  const [inputKey, setInputKey] = useState(1);

  const dispatch = useDispatch();
  const intl = useIntl();

  const handleSelectChange = selectValue => {
    setValue(selectValue);
    handleSelectClient(selectValue);
    setDefaultMenuIsOpen(false);
  };

  const handleInputChange = inputValue => {
    if (inputValue === inputSearchValue) return;

    setInputSearchValue(inputValue);
    handleFetchSuggestions(inputValue);
  };

  const handleMapClientsData = data => data?.map(client => ({ label: client.name, value: client.id })) || [];

  const handleFetchClients = async (search = '', page = 0) => {
    try {
      const { data } = await API.get('/commercial/clients', {
        params: {
          limit: 15,
          offset: page * 15,
          orderBy: 'created_at',
          orderByDir: 'desc',
          search: search || undefined,
        },
      });

      const fetchedClients = handleMapClientsData(data);

      if (fetchedClients.length < 15) setLoadMore(false);

      return fetchedClients;
    } catch (err) {
      console.debug('[DEBUG] ClientsSelect handleFetchClients error ', err);

      dispatch(
        showToast({
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'FETCH_EXTRACT_CLIENTS_DATA_ERROR' }),
          type: 'error',
          duration: 4000,
        })
      );
    }
  };

  const handleFetchSuggestions = async inputValue => {
    setDefaultMenuIsOpen(true);
    setCurrentPage(0);

    setClients(await handleFetchClients(inputValue));

    setInputKey(state => state + 1);
  };

  const handleLoadMore = async () => {
    if (loadMore) {
      setDefaultMenuIsOpen(true);
      setCurrentPage(state => state + 1);

      const moreClients = await handleFetchClients(inputSearchValue, currentPage + 1);

      setClients(state => [...state, ...moreClients]);
    }
  };

  useEffect(() => {
    const fetchInitialClients = async () => setClients(await handleFetchClients('', 0));

    fetchInitialClients();
  }, []);

  useEffect(() => {
    const mapped = clients.map(client => ({
      value: client.id,
      label: client.name,
      ...client,
    }));

    setMappedClients(mapped);
  }, [clients]);

  return (
    <AsyncSelect
      className={classes.select}
      classNamePrefix="select"
      name="clients"
      key={inputKey}
      defaultInputValue={inputSearchValue}
      onInputChange={debounce(handleInputChange, 500)}
      value={value}
      placeholder={intl.formatMessage({ id: 'CUSTOMERS' })}
      onMenuScrollToBottom={handleLoadMore}
      defaultOptions={mappedClients}
      noOptionsMessage={() => intl.formatMessage({ id: 'NO_SELECT_OPTIONS' })}
      onChange={handleSelectChange}
      defaultMenuIsOpen={defaultMenuIsOpen}
      escapeClearsValue
      isMulti
    />
  );
};

ClientsSelect.propTypes = {
  handleSelectClient: PropTypes.func.isRequired,
};

export default ClientsSelect;

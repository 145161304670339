import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/ViewResourcesSection/style.module.scss';
import DocumentsCircle from 'src/svg-icons/documents-circle';

const ViewResourcesSection = ({ resources }) => {
  const croqui = resources[0] || null;

  return (
    <Grid item xs={12}>
      <div className={classes.Header}>
        <DocumentsCircle viewBox="0 0 34.016 34.016" className={classes.Icon} />
        <FormattedMessage id="resources" />
      </div>
      <Grid container spacing={8} className={classes.Information}>
        <Grid item xs={12}>
          <div className={classes.SubHeader}>Croqui</div>
          <div className={classes.ImagesContainer}>
            {!!croqui && <img key={croqui.uri} src={croqui.uri} alt="croqui" className={classes.Image} />}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

ViewResourcesSection.propTypes = {
  resources: PropTypes.array,
};

ViewResourcesSection.detaultProps = {
  resources: [],
};

export default ViewResourcesSection;

import React, { useCallback, useEffect, useRef } from 'react';

import classes from 'src/components/OctadeskChatButton/style.module.scss';
import chatIcon from 'src/images/icons/chat.svg';

import 'src/components/OctadeskChatButton/resets.scss';

const OctadeskChatButton = () => {
  const buttonWrapperRef = useRef(null);

  const handleToggleChat = () => {
    window.octadesk?.chat?.showApp();
  };

  const handleHideChat = () => {
    window.octadesk?.chat?.hideApp();
  };

  const handleClickOutside = useCallback(event => {
    const isOctadeskElement = event?.target?.querySelector('[class^="octadesk-"]');

    if (buttonWrapperRef?.current && !buttonWrapperRef?.current?.contains(event?.target) && !isOctadeskElement) {
      handleHideChat();
    }
  }, []);

  useEffect(() => {
    const loginTries = setInterval(() => {
      const me = JSON.parse(sessionStorage.getItem('me'));

      const tries = 0;
      const maxTries = 50;
      const loginResult = window.octadesk?.chat?.login({ name: me?.name, email: me?.profile?.email });

      if (loginResult || (!loginResult && tries >= maxTries)) {
        clearInterval(loginTries);
      }
    }, 100);
  }, []);

  useEffect(() => {
    handleHideChat();
    document.addEventListener('click', handleClickOutside);

    return () => {
      handleHideChat();
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className={classes.octadeskChatButton} onClick={handleToggleChat} ref={buttonWrapperRef} title="Octadesk">
      <img src={chatIcon} alt="Octadesk Chat" />
    </div>
  );
};

export default OctadeskChatButton;

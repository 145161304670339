import React, { useState, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import apiPublicRequest from 'src/apiPublicRequest';
import ClientFormLinkComponent from 'src/components/ClientsPublicPage/ClientFormLink/ClientFormLinkComponent';
import { parseInputName } from 'src/helpers';
import { masksByCountry } from 'src/helpers/mask';
import { decodeToken } from 'src/helpers/tokenGenerator';
import AddressService from 'src/services/addressService';
import { showToast } from 'src/store/ducks/toasts';

const classifications = [
  'IMOBILIARIA',
  'CORRETOR_DE_IMOVEIS',
  'PROPRIETARIO',
  'CONDOMINIO',
  'ADMINISTRADORA_DE_CONDOMINIOS',
  'SINDICO_PROFISSIONAL',
  'CONSTRUTORA_INCORPORADORA',
  'EMPREITEIRA',
  'INSTITUICAO_FINANCEIRA',
  'REDE_DE_VAREJO',
  'PESSOA_FISICA',
  'OUTROS',
];
const billingModalities = ['SEPARATE', 'PRE_PAID', 'POST_PAID'];
const billingModalitiesPanel = ['PAY_PER_USE', 'BASIC_PLAN', 'BASIC_LEGACY_PLAN', 'ADVANCED_PLAN', 'PROFESSIONAL_PLAN'];

const ClientFormLinkContainer = () => {
  const [busy, setBusy] = useState(false);
  const [type, setType] = useState('pj');
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [receiveInformation, setReceiveInformation] = useState(false);
  const [client, setClient] = useState({ client_type: 'pj' });

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const dispatch = useDispatch();
  const { hash } = useParams();
  const history = useHistory();
  const intl = useIntl();

  const addressService = new AddressService('BR');

  const zipcodeMask = masksByCountry['BR'].ZIPCODE_MASK;
  const cnpjMask = masksByCountry['BR'].CNPJ_MASK;
  const cpfOnlyMask = masksByCountry['BR'].CPF_ONLY_MASK;
  const phoneMask = masksByCountry['BR'].PHONE_MASK;
  const cellphoneMask = masksByCountry['BR'].CELLPHONE_MASK;
  const cellphoneMaskLength = masksByCountry['BR'].CELLPHONE_MASK_LENGTH;

  const franchise = useMemo(() => decodeToken(hash), [hash]);

  useEffect(() => {
    setStates(addressService.getStates());
  }, []);

  const fillCities = async (parent, stateId) => {
    try {
      const data = await addressService.getCitiesByState(stateId);
      setClient(oldState => ({
        ...oldState,
        [parent]: {
          ...oldState[parent],
          city: null,
          city_id: null,
        },
      }));
      if (parent === 'address') setCities(data);
    } catch (err) {
      console.debug('[DEBUG] fillCities error ', err);
    }
  };

  const fillAddress = async (parent, zipcode) => {
    try {
      const address = await addressService.getLocationByZipcode(zipcode);
      await fillCities(parent, address?.state_id);

      setClient({
        ...client,
        [parent]: {
          ...client[parent],
          street: address?.street,
          neighborhood: address?.neighborhood,
          neighborhood_id: address?.neighborhood_id,
          city: address?.city,
          city_id: address?.city_id,
          state: address?.state,
          state_id: address?.state_id,
          zipcode,
        },
      });
    } catch (err) {
      console.debug('[DEBUG] fillAddress error ', err);
    }
  };

  const handleChange = async evt => {
    const { name, value } = evt.target;
    const parsed = parseInputName(name, value);
    const parent = Object.keys(parsed)[0];

    if (name === 'privacy_policy') {
      setPrivacyPolicy(!privacyPolicy);
    }

    if (name === 'receive_information') {
      setReceiveInformation(!receiveInformation);
    }

    if (name === 'client_type') {
      setType(value);
    }

    if (name.includes('zipcode') && addressService.validateZipcode(value)) {
      await fillAddress(parent, value);
      return;
    }

    if (name.includes('state_id')) {
      await fillCities(parent, value);
      parsed[parent].state_id = +value;
      parsed[parent].state = states.find(state => state.id === +value).name;
    }

    if (name.includes('city_id')) {
      parsed[parent].city_id = +value;
      parsed[parent].city = cities.find(city => city.id === +value).name;
    }

    const parsedValue = typeof parsed[parent] !== 'string' ? { ...client[parent], ...parsed[parent] } : parsed[parent];
    setClient({ ...client, [parent]: parsedValue });
  };

  const handleSubmit = async evt => {
    evt.preventDefault();

    try {
      setBusy(true);
      const payload = {
        ...client,
        privacy_policy: privacyPolicy,
        receive_information: receiveInformation,
      };

      await apiPublicRequest.post(`/public/franchisees/${franchise.franchisee_id}/clients`, payload);

      history.replace(`/new-client/${hash}/success`);
    } catch (err) {
      console.debug('[DEBUG] handleSubmit error ', err);

      if (err?.response?.data?.message) {
        dispatch(
          showToast({
            type: 'error',
            title: 'Erro',
            text: intl.formatMessage({ id: err.response.data.message }),
            duration: 6000,
          })
        );
      } else {
        dispatch(
          showToast({
            type: 'error',
            title: 'Erro',
            text: 'Não foi possível salvar as informações, tente novamente em breve',
            duration: 6000,
          })
        );
      }
    } finally {
      setBusy(false);
    }
  };

  return (
    <ClientFormLinkComponent
      busy={busy}
      client={client}
      classifications={classifications}
      billingModalities={billingModalities}
      billingModalitiesPanel={billingModalitiesPanel}
      states={states}
      cities={cities}
      privacyPolicy={privacyPolicy}
      receiveInformation={receiveInformation}
      type={type}
      onChange={handleChange}
      onSubmit={handleSubmit}
      zipcodeMask={zipcodeMask}
      cnpjMask={cnpjMask}
      cpfOnlyMask={cpfOnlyMask}
      phoneMask={phoneMask}
      cellphoneMask={cellphoneMask}
      cellphoneMaskLength={cellphoneMaskLength}
    />
  );
};

export default ClientFormLinkContainer;

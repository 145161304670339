import React from 'react';

import PropTypes from 'prop-types';

import classes from 'src/components/ClientsPublicPage/ClientSuccess/style.module.scss';
import access from 'src/components/common/icons/access.svg';
import chat from 'src/components/common/icons/chat.svg';
import email from 'src/components/common/icons/email.svg';
import phone from 'src/components/common/icons/phone.svg';
import loginImage from 'src/png-icons/login.png';

const ClientSuccessComponent = ({ franchise }) => (
  <div className={classes.successPage}>
    <div className={classes.container}>
      <h2 className={classes.title}>CADASTRO REALIZADO</h2>
      <h3>Bem-vindo à nossa plataforma!</h3>
      <p>Enviamos um e-mail de confirmação para o endereço fornecido durante o cadastro. </p>
      <p>
        Por favor, verifique a sua caixa de entrada e, se não encontrar o e-mail de confirmação, verifique a pasta de
        spam ou lixo eletrônico.
      </p>

      <div className={classes.content}>
        <h4>
          <img className={classes.icon} src={chat} alt="" />
          Atendimento
        </h4>
        <p>
          A partir de agora você será atendido pela <span className={classes.uppercase}>{franchise.name}</span> .
        </p>
        <p>Em caso de dúvidas, entre em contato com nossa equipe de consultores:</p>
        <p>
          <img className={classes.icon} src={phone} alt="" />
          {franchise.phone}
        </p>
        <p>
          <img className={classes.icon} src={email} alt="" />
          {franchise.email}
        </p>
        <h4>
          <img className={classes.icon} src={access} alt="" />
          Primeiro acesso
        </h4>
        <p>
          Acessando o link em seu e-mail, você irá finalizar a criação da sua conta ao definir sua senha de acesso.{' '}
        </p>
        <img className={classes.image} src={loginImage} alt=""></img>
      </div>

      <p>
        Após a definição da senha, você poderá acessar o Painel por meio do{' '}
        <a className={classes.link} href="https://painel.redevistorias.com.br/" target="_blank" rel="noreferrer">
          www.painel.redevistorias.com.br
        </a>{' '}
        ou acessando o Painel do Cliente em nosso site.
      </p>
      <p>
        Nesse mesmo e-mail estará disponível o tutorial do painel{' '}
        <a className={classes.link} href="https://s.vistori.as/criando_um_pedido_tutorial.pdf" target="_blank" rel="noreferrer">
          clicando aqui.
        </a>
      </p>
      <img className={classes.image} src="/assets/img/logo_rede2.svg" alt="Logo Rede Vistorias" />
    </div>
  </div>
);

ClientSuccessComponent.propTypes = {
  franchise: PropTypes.object,
};

ClientSuccessComponent.defaultProps = {
  franchise: null,
};

export default ClientSuccessComponent;

import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import PropTypes from 'prop-types';

import Spinner from 'src/components/common/Spinner';
import OrderIcon from 'src/components/OrderIcon/OrderIcon';
import { generateGoogleMapsUrl } from 'src/components/SchedulePage/helpers/generateGoogleMapsUrl';
import OrderForm from 'src/components/SchedulePage/OrderModal/OrderForm';
import ActivityLogComponent from 'src/components/SchedulePage/OrderModal/OrderHistoric/ActivityLogComponent';
import OrderKeysLocation from 'src/components/SchedulePage/OrderModal/OrderKeysLocation';
import classes from 'src/components/SchedulePage/OrderModal/style.module.scss';
import rightCurvedArrow from 'src/images/icons/right-curved-arrow.svg';
import trashImage from 'src/images/icons/trash.svg';
import AnnotationSection from 'src/orderDetails/AnnotationSection';
import Whatsapp from 'src/svg-icons/whatsapp';

const OrderModalComponent = ({
  isBusy,
  isInTimeline,
  hasErrors,
  confirmRemove,
  order,
  historic,
  schedule,
  intl,
  setConfirmRemove,
  unassign,
  closeModal,
  openAttachment,
  handleEmitOrderEditingHasBeenFinished,
  orderAlreadyBeingEditedByAnotherUser,
  canShowAnnotation,
  refreshHandler,
}) => {
  const [currentTab, setCurrentTab] = useState('details');
  const [initialFormValues, setInitialFormValues] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!hasErrors && !isBusy && schedule && order) {
      const values = {
        order: {
          code: order?.code,
          public_id: order?.public_id,
          identifier: order?.building_id,
          credits: (Math.round(order?.price * 100) * 100) / 100,
          type: order?.type,
          client: order?.client,
          area: order?.area ? String(order?.area) : '',
          address_id: order?.address_id,
          order_type_id: order?.order_type_id,
          building_type: order?.building_type,
          search_tokens: order?.search_tokens,
          allowed_at_date: order?.allowed_at_date,
          accompanied_inspection: order?.accompanied_inspection,
          furnished: order?.furnished,
          modality: order?.modality,
          urgency: order?.urgency,
          created_at: order?.created_at,
          access_information: order?.access_information,
          details: order?.details,
          status: order?.status,
          city: order?.city,
          neighborhood: order?.neighborhood,
          street: order?.street,
          number: order?.number,
          complement: order?.complement,
          state: order?.state,
          zipcode: order?.zipcode,
        },
        schedule,
      };
      setInitialFormValues(values);
      setIsLoaded(true);
    }
  }, [schedule, order, hasErrors, isBusy]);

  const changeTab = async tab => {
    setCurrentTab(tab);
  };

  const openWhatsapp = () => {
    const phone = order?.accompanied_inspection?.companionContact.replace(/\D/g, '');
    window.open(`https://wa.me/+55${phone}`, '_blank');
  };

  const openOrder = () => {
    const accessToken = sessionStorage.getItem('accessToken');
    const tokenType = sessionStorage.getItem('tokenType');

    window.open(
      `/orders/${order?.public_id}#access_token=${accessToken}&token_type=${tokenType}&expires_in=`,
      '_blank'
    );
  };

  const openAddress = address => {
    const mapsUrl = generateGoogleMapsUrl(address);

    window.open(mapsUrl, '_blank');
  };

  const fullOrderAddress = `${order?.street}, ${order?.number}, ${order?.neighborhood}${
    order?.complement ? ` - ${order.complement}` : ''
  }, ${order?.city}/${order?.state}`;

  return (
    <div className={classes.orderModal} onClick={closeModal}>
      <div
        className={`
        ${classes.content}
        ${orderAlreadyBeingEditedByAnotherUser?.isEditing && classes.isEditingOrderBorder}
      `}
        onClick={evt => evt.stopPropagation()}
      >
        {isLoaded ? (
          <>
            {orderAlreadyBeingEditedByAnotherUser?.isEditing && (
              <p className={classes.isEditingOrderBadge}>
                {intl.formatMessage({ id: 'ORDER_BEING_CHANGED_BY' })} {orderAlreadyBeingEditedByAnotherUser?.who}
              </p>
            )}

            <div className={classes.header}>
              <div className={classes.text}>{order?.building_id}</div>
            </div>

            <div className={classes.body}>
              <div className={classes.info}>
                <div className={classes.tab}>
                  <span
                    className={`${classes.tabMenu} ${currentTab === 'details' ? classes.active : ''}`}
                    onClick={() => changeTab('details')}
                  >
                    {intl.formatMessage({ id: 'DETAILS' })}
                  </span>

                  <span
                    className={`${classes.tabMenu} ${currentTab === 'historic' ? classes.active : ''}`}
                    onClick={() => changeTab('historic')}
                  >
                    {intl.formatMessage({ id: 'orderHistoric' })}
                  </span>
                </div>

                {currentTab === 'details' && (
                  <div>
                    <div className={classes.line}>
                      <strong>{intl.formatMessage({ id: 'CODE' })}</strong>
                      {': '}
                      <button className={classes.orderLinkButton} type="button" onClick={openOrder} title={order?.code}>
                        {order?.code}
                      </button>
                    </div>

                    <div className={classes.line}>
                      <strong>{intl.formatMessage({ id: 'CUSTOMER' })}</strong>
                      {': '}
                      {order?.client}
                    </div>

                    <div className={classes.line}>
                      <strong>{intl.formatMessage({ id: 'PROPERTY_TYPE' })}</strong>
                      {': '}
                      {order?.building_type}
                    </div>

                    {order?.area && (
                      <div className={classes.line}>
                        <strong>{intl.formatMessage({ id: 'AREA' })}</strong>
                        {': '}
                        {`${order?.area}m`}
                        <sup>2</sup>
                      </div>
                    )}

                    {order?.furnished && (
                      <div className={classes.line}>
                        <strong>{intl.formatMessage({ id: 'FURNITURE' })}</strong>
                        {': '}
                        <FormattedMessage id={order?.furnished} />
                      </div>
                    )}

                    {order?.keys_location && (
                      <div className={classes.line}>
                        <strong>{intl.formatMessage({ id: 'KEYS' })}</strong>
                        {': '}
                        {order?.access_information || order?.property_access ? (
                          <OrderKeysLocation
                            keysLocationInformation={order?.access_information || order?.property_access}
                            handleOpenAddress={openAddress}
                          />
                        ) : (
                          <span>{order?.keys_location}</span>
                        )}
                      </div>
                    )}

                    <div className={classes.line}>
                      <strong>{intl.formatMessage({ id: 'extraInstructions' })}</strong>
                      {': '}
                      {order?.access_information?.extraInstructions ? (
                        <FormattedMessage id="Yes" />
                      ) : (
                        <FormattedMessage id="No" />
                      )}
                    </div>

                    {order?.access_information?.extraInstructions && (
                      <div className={classes.line}>
                        <strong>{intl.formatMessage({ id: 'instructions' })}</strong>
                        {': '}
                        <span>{order?.access_information?.instructions}</span>
                      </div>
                    )}

                    {order?.urgency && (
                      <div className={classes.line}>
                        <strong>{intl.formatMessage({ id: 'EXPRESS_INSPECTION' })}</strong>
                        {': '}
                        <FormattedMessage id={order?.urgency} />
                      </div>
                    )}

                    {order?.modality && (
                      <div className={classes.line}>
                        <strong>{intl.formatMessage({ id: 'MODALITY' })}</strong>
                        {': '}
                        <FormattedMessage id={order?.modality} />
                      </div>
                    )}

                    <div className={classes.line}>
                      <strong>{intl.formatMessage({ id: 'ADDRESS' })}</strong>
                      {': '}
                      <button
                        className={classes.orderLinkButton}
                        type="button"
                        onClick={() => openAddress(order)}
                        title={fullOrderAddress}
                      >
                        {fullOrderAddress}
                      </button>
                    </div>

                    {order?.allowed_at_date && (
                      <div className={classes.line}>
                        <strong>{intl.formatMessage({ id: 'allowed_at_date_schedule' })}</strong>
                        {': '}
                        <span>{order?.allowed_at_date}</span>
                      </div>
                    )}

                    {order?.details && (
                      <div className={classes.line}>
                        <strong>{intl.formatMessage({ id: 'DETAILS' })}:</strong>

                        <div className={classes.details}>{order?.details}</div>

                        <button name="copy-details-to-inspector" className={classes.detailsCopy} type="button">
                          <span>{intl.formatMessage({ id: 'COPY_TO_INSPECTOR' })}</span>

                          <img src={rightCurvedArrow} alt="Ícone flecha" />
                        </button>
                      </div>
                    )}

                    {order?.accompanied_inspection?.isAccompanied && (
                      <>
                        <div className={classes.line}>
                          <strong>{intl.formatMessage({ id: 'ACCOMPANIED_INSPECTION' })}</strong>
                        </div>

                        <div className={classes.accompaniedData}>
                          {order?.accompanied_inspection?.companionName && (
                            <p>
                              {intl.formatMessage({ id: 'COMPANION_NAME' })}:{' '}
                              {order?.accompanied_inspection?.companionName}
                            </p>
                          )}

                          {order?.accompanied_inspection?.companionContact && (
                            <p>
                              {intl.formatMessage({ id: 'phoneContact' })}:{' '}
                              {order?.accompanied_inspection?.companionContact}
                              <span className={classes.whatsapp} onClick={openWhatsapp}>
                                <Whatsapp style={{ verticalAlign: 'middle', marginRight: '5px', fontSize: '18px' }} />
                                Abrir no WhatsApp Web
                              </span>
                            </p>
                          )}

                          {order?.accompanied_inspection?.scheduleDate && (
                            <p>
                              {intl.formatMessage({ id: 'SCHEDULE_DATE' })}:{' '}
                              {order?.accompanied_inspection?.scheduleDate}
                            </p>
                          )}
                        </div>
                      </>
                    )}

                    {!order?.previous_inspection?.file && order?.previous_inspection?.inspection && (
                      <div className={classes.line}>
                        <strong>
                          {intl.formatMessage({
                            id: 'BASED_ON_ANOTHER_INSPECTION_MESSAGE',
                          })}
                        </strong>
                      </div>
                    )}

                    {(order?.previous_inspection?.file || !!order?.attachments.length) && (
                      <>
                        <div className={classes.line}>
                          <strong>{intl.formatMessage({ id: 'ATTACHMENTS' })}</strong>
                          {': '}
                        </div>

                        <div className={classes.attachments}>
                          <>
                            {order?.previous_inspection?.file && (
                              <button
                                type="button"
                                onClick={() =>
                                  openAttachment(
                                    order?.previous_inspection?.file?.remote_name,
                                    order?.previous_inspection?.file?.original_name,
                                    order?.previous_inspection?.file?.extension
                                  )
                                }
                              >
                                {order?.previous_inspection?.file.original_name}
                              </button>
                            )}

                            {!!order?.attachments.length &&
                              order?.attachments.map(attachment => (
                                <button
                                  key={attachment?.original_name}
                                  type="button"
                                  onClick={() =>
                                    openAttachment(
                                      attachment?.remote_name,
                                      attachment?.original_name,
                                      attachment?.extension
                                    )
                                  }
                                >
                                  {attachment?.original_name}
                                </button>
                              ))}
                          </>
                        </div>
                      </>
                    )}

                    {canShowAnnotation && order && (
                      <div className="w-100 mb-3">
                        <hr />
                        <AnnotationSection
                          annotation={order.annotation || {}}
                          orderId={order.public_id}
                          orderCode={order.code}
                          refreshHandler={refreshHandler}
                          inspectionIcon={
                            order.type && <OrderIcon orderType={order.type} fontSize="calc(16px + 0.8vw)" />
                          }
                        />
                      </div>
                    )}
                  </div>
                )}

                {currentTab === 'historic' && (
                  <div className={classes.historic}>
                    <ActivityLogComponent intl={intl} historic={historic} />
                  </div>
                )}

                {isInTimeline && (
                  <div className={classes.delete}>
                    {!confirmRemove ? (
                      <button type="button" className={classes.link} onClick={() => setConfirmRemove(true)}>
                        <div className={classes.icon}>
                          <img src={trashImage} alt={intl.formatMessage({ id: 'TRASH_CAN' })} />
                        </div>

                        {intl.formatMessage({ id: 'REMOVE_ASSIGNMENT' })}
                      </button>
                    ) : (
                      <div className={classes.confirmQuestion}>
                        <div className={classes.question}>{intl.formatMessage({ id: 'CONFIRM_REMOVAL' })}</div>

                        <div className={classes.actions}>
                          <button type="button" onClick={() => unassign({ order, schedule })}>
                            {intl.formatMessage({ id: 'YES' })}
                          </button>

                          <button type="button" onClick={() => setConfirmRemove(false)}>
                            {intl.formatMessage({ id: 'NO' })}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className={classes.form}>
                <OrderForm
                  initialValues={initialFormValues}
                  order={{ order, schedule }}
                  closeModal={closeModal}
                  handleEmitOrderEditingHasBeenFinished={handleEmitOrderEditingHasBeenFinished}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            {isBusy && (
              <div className={classes.spinnerWrapper}>
                <Spinner />
              </div>
            )}

            {hasErrors && <p>{intl.formatMessage({ id: 'ORDER_LOAD_ERROR' })}</p>}
          </>
        )}
      </div>
    </div>
  );
};

OrderModalComponent.propTypes = {
  canShowAnnotation: PropTypes.bool.isRequired,
  refreshHandler: PropTypes.func.isRequired,
  isBusy: PropTypes.bool.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  isInTimeline: PropTypes.bool.isRequired,
  confirmRemove: PropTypes.bool.isRequired,
  order: PropTypes.object,
  historic: PropTypes.object,
  schedule: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  openAttachment: PropTypes.func.isRequired,
  unassign: PropTypes.func.isRequired,
  setConfirmRemove: PropTypes.func.isRequired,
  handleEmitOrderEditingHasBeenFinished: PropTypes.func.isRequired,
  orderAlreadyBeingEditedByAnotherUser: PropTypes.object,
  intl: PropTypes.object.isRequired,
};

OrderModalComponent.defaultProps = {
  order: null,
  historic: null,
  schedule: null,
  orderAlreadyBeingEditedByAnotherUser: null,
};

export default injectIntl(OrderModalComponent);

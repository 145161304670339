import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import moment from 'moment';
import PropTypes from 'prop-types';

import classes from 'src/components/ClientsPage/ClientList/ClientCard/style.module.scss';
import Card from 'src/components/common/Card';
import clientImage from 'src/components/common/icons/client-details.svg';
import exclamationCircleImage from 'src/images/icons/exclamation-circle.svg';

const ClientCard = ({ data }) => {
  const params = useParams();
  const intl = useIntl();
  const numberFormat = new Intl.NumberFormat('pt-BR', {
    maximumSignificantDigits: 5,
  });
  const status = params.status || 'enabled';

  return (
    <Card className="isInspector" url={`/clients/${status}/${data.id}`}>
      <div className={classes.name}>
        <div className={classes.icon}>
          <img src={clientImage} alt={intl.formatMessage({ id: 'CUSTOMER' })} />
        </div>
        <div className={classes.text}>{data.name}</div>
        {data?.invalid_address && (
          <div className={classes.invalidAddress}>
            <img src={exclamationCircleImage} alt="Exclamação" />
            Endereço inválido
          </div>
        )}
      </div>
      <div className={classes.line}>
        {data.phone && <div className={classes.phone}>{data.phone}</div>}
        {data.email && <div className={classes.email}>{data.email}</div>}
      </div>
      <div className={classes.line}>
        {data.last_order
          ? `${intl.formatMessage({ id: 'LAST_MOVE_ON_MESSAGE' })} ${moment(data.last_order).format()}`
          : intl.formatMessage({ id: 'NO_REGISTERED_MOVEMENTS_MESSAGE' })}
      </div>
      <div className={classes.totalBalance}>
        <div className={classes.title}>
          {intl.formatMessage({ id: 'BALANCE' })}
        </div>
        <div className={classes.number}>{numberFormat.format((data.balance / 100).toFixed(0))}</div>
      </div>
    </Card>
  );
};

ClientCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ClientCard;

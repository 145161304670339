import React from 'react';

import PropTypes from 'prop-types';

const Element = ({ data, className, children, handleClick }) =>
  data.link ? (
    <a
      href={data.link}
      className={className}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClick}
    >
      {children}
    </a>
  ) : (
    <div className={className}>{children}</div>
  );

Element.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default Element;

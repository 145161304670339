import React from 'react';

import PropTypes from 'prop-types';

import Form from 'src/components/common/Form';
import Spinner from 'src/components/common/Spinner';
import classes from 'src/components/MarketplacePage/Service/style.module.scss';
import checkImage from 'src/images/icons/check.svg';

const ServiceComponent = ({
  busy,
  formBusy,
  formData,
  schema,
  service,
  type,
  onChange,
  onDisableClick,
  onSubmit,
  onError,
  intl,
}) => (
  <div className={classes.service}>
    {busy ? (
      <div className={classes.spinnerWrapper}>
        <Spinner />
      </div>
    ) : (
      <>
        <div className={classes.info}>
          <div className={classes.image}>
            {service.logo_url ? <img src={service.logo_url} alt={service.name} /> : service.name}
            {(service.enabled || service.status === 'ENABLED') && (
              <div className={classes.flag}>
                <img src={checkImage} alt={intl.formatMessage({ id: 'ACTIVATED' })} />
              </div>
            )}
          </div>
          <div className={classes.data}>
            <h1 className={classes.name}>{service.name}</h1>
            <p className={classes.slogan}>{service.slogan}</p>
            {(service.enabled || service.status === 'ENABLED') && (
              <div className={classes.status}>
                <div className={classes.isEnabled}>{intl.formatMessage({ id: 'ACTIVATED' })}</div>
                {type === 'erp' && (
                  <button type="button" className={classes.disable} onClick={onDisableClick}>
                    {intl.formatMessage({ id: 'DEACTIVATE' })}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        <hr />
        <p className={classes.description}>{service.description}</p>
        {!service.enabled && (
          <div className={classes.formWrapper}>
            <Form formData={formData} schema={schema} onSubmit={onSubmit} onChange={onChange} onError={onError}>
              <button type="submit" className={classes.button} disabled={formBusy}>
                {intl.formatMessage({ id: 'SAVE' })}
              </button>
            </Form>
          </div>
        )}
      </>
    )}
  </div>
);

ServiceComponent.propTypes = {
  busy: PropTypes.bool.isRequired,
  formBusy: PropTypes.bool.isRequired,
  formData: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  service: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onDisableClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

ServiceComponent.defaultProps = {
  service: null,
};

export default ServiceComponent;

import Furnished from 'src/images/icons/scheduleCard/furnished.svg';
import KeyDisabled from 'src/images/icons/scheduleCard/key-disabled.svg';
import Key from 'src/images/icons/scheduleCard/key.svg';
import LightningDisabled from 'src/images/icons/scheduleCard/lightning-disabled.svg';
import Lightning from 'src/images/icons/scheduleCard/lightning.svg';
import Locked from 'src/images/icons/scheduleCard/locked.svg';
import PeopleDisabled from 'src/images/icons/scheduleCard/people-disabled.svg';
import People from 'src/images/icons/scheduleCard/people.svg';
import SemiFurnished from 'src/images/icons/scheduleCard/semi-furnished.svg';
import StarDisabled from 'src/images/icons/scheduleCard/star-disabled.svg';
import Star from 'src/images/icons/scheduleCard/star.svg';
import Unfurnished from 'src/images/icons/scheduleCard/unfurnished.svg';
import Unlocked from 'src/images/icons/scheduleCard/unlocked.svg';
import Warningdisabled from 'src/images/icons/scheduleCard/warning-disabled.svg';
import Warning from 'src/images/icons/scheduleCard/warning.svg';

const iconByName = {
  unlocked: Unlocked,
  locked: Locked,
  unfurnished: Unfurnished,
  furnished: Furnished,
  'semi-furnished': SemiFurnished,
  people: People,
  'people-disabled': PeopleDisabled,
  'key-disabled': KeyDisabled,
  key: Key,
  'lightning-disabled': LightningDisabled,
  lightning: Lightning,
  'star-disabled': StarDisabled,
  star: Star,
  'warning-disabled': Warningdisabled,
  warning: Warning,
};

function orderCardIcon(iconName) {
  return iconByName[iconName] || '';
}

export default orderCardIcon;

import { getAccessToken } from 'src/apiRequest';
import { REDIRECT_URL, AUTH_BASE_URL } from 'src/config';

function isTokenExpired(token) {
  if (!token) return true;

  const splitted = token.split('.');
  const parsed = JSON.parse(atob(splitted[1]));

  return Math.floor(new Date().getTime() / 1000) >= parsed?.exp;
}

export function checkAccessTokenIsExpired() {
  const accessToken = getAccessToken();

  if (isTokenExpired(accessToken)) {
    const redirectUrl = window.location.href || REDIRECT_URL;

    window.location =
      `${AUTH_BASE_URL}/login?response_type=token` +
      `&redirect_uri=${redirectUrl.replace('#_=_', '')}` +
      '&client_id=redevistorias' +
      '&scope=users%20order:*%20client:*%20device:*%20financial:*%20package:*%20entity:*%20integration:*';
    return true;
  }
  return false;
}

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let Inspections = props => (
  <SvgIcon {...props} viewBox="0 0 34.016 34.016">
    <path
      fill="#393939"
      d="M17.008,2.008c-8.271,0-15,6.729-15,15c0,8.271,6.729,15,15,15c8.271,0,15-6.729,15-15
	C32.008,8.737,25.279,2.008,17.008,2.008z M17.008,30.008c-7.168,0-13-5.832-13-13s5.832-13,13-13s13,5.832,13,13
	S24.176,30.008,17.008,30.008z"
    />
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#393939"
        d="M23.352,23.788c0,0-0.232,1.595-1.189,1.595H11.854
            c-0.657,0-1.189-0.536-1.189-1.196V11.026c0-0.661,0.532-1.196,1.189-1.196h3.172l-1.982,0.797v3.19h7.929v-3.19L18.99,9.83h3.172
            c0.656,0,1.189,0.535,1.189,1.196v4.785V23.788z M20.18,13.02h-6.343v-1.595l1.982-0.797V9.431c0-0.661,0.136-0.798,0.792-0.798
            h0.793c0.657,0,0.793,0.137,0.793,0.798v1.196l1.982,0.797V13.02z"
      />
    </g>
  </SvgIcon>
);
Inspections = pure(Inspections);
Inspections.displayName = 'Inspections';
Inspections.muiName = 'SvgIcon';

export default Inspections;

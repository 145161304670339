export default function secondsToHms(d) {
  const seconds = Number(d);

  if (seconds === 0) return `${seconds}s`;

  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);

  const hDisplay = h > 0 ? `${h}h` : '';
  const mDisplay = m > 0 ? `${m}m` : '';
  const sDisplay = s > 0 ? `${s}s` : '';

  if (h > 0) return `${hDisplay} ${mDisplay}`;
  if (m > 0) return `${mDisplay} ${sDisplay}`;

  return `${sDisplay}`;
}

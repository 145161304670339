import React from 'react';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import AttachDetailsFooter from 'src/inspectionAttach/AttachDetailsFooter';
import classes from 'src/inspectionAttach/DeviceDetails.module.css';

function DeviceDetails(props) {
  return (
    <Grid container alignItems={'center'} className={classes.Grid}>
      <Grid item xs={12}>
        <span>{props.data.name}</span>
      </Grid>
      <Grid item xs={12}>
        <AttachDetailsFooter handleCancel={props.handleCancel} handleConfirm={props.handleConfirm} />
      </Grid>
    </Grid>
  );
}

DeviceDetails.propTypes = {
  /**
   * Object with the selected data
   */
  data: PropTypes.object.isRequired,
  /**
   * Function to confirm the selection
   */
  handleConfirm: PropTypes.func.isRequired,
  /**
   * Function to cancel the selection
   */
  handleCancel: PropTypes.func.isRequired,
};

export default DeviceDetails;

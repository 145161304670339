import React from 'react';
import { FormattedMessage } from 'react-intl';

import PropTypes from 'prop-types';

import Radio from 'src/components/common/elements/Radio';
import classes from 'src/orderForm/widgets/RadioWidget/style.module.scss';

const RadioWidget = ({ disabled, id, label, options, onChange, value }) => (
  <div className={classes.radioWidget}>
    <div className={classes.field}>
      <div className={classes.title}>
        <FormattedMessage id={label} />
      </div>

      <div className={classes.optionList}>
        {options.enumOptions.map(option => (
          <div className={classes.option} key={`${id}-${option.value}`}>
            <label data-cy={`${id}-${option.label}`}>
              <Radio
                name={id}
                className={classes.radio}
                id={`${id}-${option.value}`}
                data-cy={`${id}-${option.value}`}
                value={option.value.toString()}
                checked={value === option.value}
                onChange={evt => onChange(evt.target.value === 'true')}
                disabled={disabled}
              />

              <FormattedMessage id={option.label} />
            </label>
          </div>
        ))}
      </div>
    </div>
  </div>
);

RadioWidget.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
};

RadioWidget.defaultProps = {
  disabled: false,
  value: null,
};

export default RadioWidget;

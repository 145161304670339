import React from 'react';

const ObjectFieldTemplate = ({ description, title, properties, uiSchema }) =>
  properties.map((element, index) => (
    <div className="object-field-template" key={index}>
      {element.content}
    </div>
  ));

export default ObjectFieldTemplate;

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/InterestedPersonSection/style.module.scss';
import InterestedPerson from 'src/svg-icons/InterestedPerson';

const InterestedPersonSection = ({ data }) => (
  <Grid item xs={12}>
    <div className={classes.header}>
      <InterestedPerson className={classes.icon} />
      <FormattedMessage id="interestedPerson" />
    </div>
    <Grid container spacing={8} className={classes.information}>
      <Grid item xs={6} data-cy="order-details-interested-person-full-name">
        <div className={classes.subHeader}>
          <FormattedMessage id="fullName" />
        </div>
        {data.fullName}
      </Grid>
      <Grid item xs={6} data-cy="order-details-interested-person-whatsapp">
        <div className={classes.subHeader}>
          <FormattedMessage id="whatsapp" />
        </div>
        {data.whatsapp}
      </Grid>
    </Grid>
  </Grid>
);

InterestedPersonSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default InterestedPersonSection;

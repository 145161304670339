import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const ReportPrint = props => (
  <SvgIcon {...props}>
    <path
      fill="#1D487A"
      d="M19,8H5c-1.7,0-3,1.3-3,3v6h4v4h12v-4h4v-6C22,9.3,20.7,8,19,8z M16,19H8v-5h8V19z M19,12c-0.5,0-1-0.4-1-1
      s0.5-1,1-1s1,0.4,1,1S19.5,12,19,12z M18,3H6v4h12V3z"
    />
    <path fill="none" d="M0,0h24v24H0V0z" />
  </SvgIcon>
);

ReportPrint.displayName = 'ReportPrint';
ReportPrint.muiName = 'SvgIcon';

export default ReportPrint;

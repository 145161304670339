import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import { useUser } from 'src/contexts/UserContext';
import { masksByCountry } from 'src/helpers/mask';
import urlPrefix from 'src/helpers/url-prefix';
import { reloadCards } from 'src/store/ducks/cards';
import { showToast } from 'src/store/ducks/toasts';

import UserFormComponent from './UserFormComponent';

const UserFormContainer = ({ action }) => {
  const [isFeaturesBusy, setIsFeaturesBusy] = useState(true);
  const [isUserBusy, setIsUserBusy] = useState(false);
  const [user, setUser] = useState({ name: '', cpf: '', phone: '', username: '', email: '', features: [] });
  const [features, setFeatures] = useState([]);

  const { id } = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const me = useUser();
  const cpfMask = masksByCountry[me?.profile?.country || 'BR'].CPF_MASK;
  const phoneMask = masksByCountry[me?.profile?.country || 'BR'].PHONE_MASK;
  const cellphoneMask = masksByCountry[me?.profile?.country || 'BR'].CELLPHONE_MASK;
  const cellphoneMaskLength = masksByCountry[me?.profile?.country || 'BR'].CELLPHONE_MASK_LENGTH;

  const handleSubmit = async ({ name, cpf, phone, email, features: permissions, username }) => {
    if (!permissions.length) {
      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'SELECT_AT_LEAST_ONE_PERMISSION_MESSAGE' }),
          duration: 5000,
        })
      );

      return;
    }

    try {
      let message;

      if (action === 'edit') {
        await API.put(`/users/${id}`, { name, cpf, phone, features: permissions });

        history.push(`${urlPrefix('users')}/${id}`);
        message = intl.formatMessage({ id: 'USER_UPDATE_SUCCESS' });
      } else {
        await API.post('/users', { name, cpf, phone, email, features: permissions, username });

        history.push(urlPrefix('users'));
        message = intl.formatMessage({ id: 'USER_REGISTRATION_FINISHED_SUCCESS' });

        dispatch(reloadCards());
      }
      dispatch(
        showToast({
          type: 'success',
          title: intl.formatMessage({ id: 'SUCCESS' }),
          text: message,
          duration: 5000,
        })
      );
    } catch (err) {
      let message;
      if (err.response) {
        const firstKey = Object.keys(err.response.data)[0];
        message = intl.formatMessage({ id: `${firstKey}.${err.response.data[firstKey]}` });
      } else {
        message = intl.formatMessage({ id: 'DATA_SAVE_ERROR' });
      }

      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: message,
          duration: 5000,
        })
      );
    }
  };

  useEffect(() => {
    if (action === 'edit') {
      setIsUserBusy(true);

      API.get(`/users/${id}`)
        .then(({ data }) => {
          setUser({
            ...data,
            features: data?.features.filter(feature => feature?.selected).map(feature => `${feature?.id}`),
          });
          setFeatures(data?.features);
        })
        .finally(() => {
          setIsUserBusy(false);
          setIsFeaturesBusy(false);
        });
    } else {
      API.get('/features')
        .then(({ data }) => {
          setUser({ features: data.map(feature => `${feature?.id}`) });
          setFeatures(data);
        })
        .finally(() => {
          setIsFeaturesBusy(false);
        });
    }
  }, [action, id]);

  return (
    <UserFormComponent
      userId={id}
      features={features}
      initialValues={user}
      isFeaturesBusy={isFeaturesBusy}
      isUserBusy={isUserBusy}
      action={action}
      intl={intl}
      handleSubmit={handleSubmit}
      cpfMask={cpfMask}
      phoneMask={phoneMask}
      cellphoneMask={cellphoneMask}
      cellphoneMaskLength={cellphoneMaskLength}
    />
  );
};

UserFormContainer.propTypes = {
  action: PropTypes.string.isRequired,
};

export default UserFormContainer;

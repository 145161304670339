import React from 'react';

const Property = props => (
  <svg {...props} width="24" height="24" viewBox="0 0 34.016 34.016" fill="none">
    <path
      d="M15.3769 6.81542C14.9563 6.39486 14.2744 6.39486 13.8539 6.81542L6.31542 14.3539C5.89486 14.7744 5.89486 15.4563 6.31542 15.8769C6.73599 16.2974 7.41786 16.2974 7.83842 15.8769L8.15385 15.5615V22.6538C8.15385 23.2486 8.636 23.7308 9.23077 23.7308H11.3846C11.9794 23.7308 12.4615 23.2486 12.4615 22.6538V20.5C12.4615 19.9052 12.9437 19.4231 13.5385 19.4231H15.6923C16.2871 19.4231 16.7692 19.9052 16.7692 20.5V22.6538C16.7692 23.2486 17.2514 23.7308 17.8462 23.7308H20C20.5948 23.7308 21.0769 23.2486 21.0769 22.6538V15.5615L21.3923 15.8769C21.8129 16.2974 22.4948 16.2974 22.9153 15.8769C23.3359 15.4563 23.3359 14.7744 22.9153 14.3539L15.3769 6.81542Z"
      fill="#393939"
    />
    <circle cx="15" cy="15.5" r="14" stroke="#393939" strokeWidth="2" />
  </svg>
);

export default Property;

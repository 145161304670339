import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const AccessInformation = props => (
  <SvgIcon viewBox="0 0 34 34" {...props}>
    <path
      fill="#393939"
      d="M17,2C8.7,2,2,8.7,2,17s6.7,15,15,15s15-6.7,15-15S25.3,2,17,2z M17,30C9.8,30,4,24.2,4,17S9.8,4,17,4
        s13,5.8,13,13S24.2,30,17,30z"
    />
    <path
      fill="#393939"
      d="M8,18h14.2l-3.6,3.6L20,23l6-6l-6-6l-1.4,1.4l3.6,3.6H8V18z"
    />
  </SvgIcon>
);

AccessInformation.displayName = 'AccessInformation';
AccessInformation.muiName = 'SvgIcon';

export default AccessInformation;

import React from 'react';

import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = {
  badge: {
    backgroundColor: '#5882C1',
    color: '#ffffff',
    top: 9,
    left: 160,
  },
  root: {
    width: '100%',
    paddingBottom: 14,
  },
};

function DrawerSubMenuItem({ content, classes, children }) {
  if (content > 0) {
    return (
      <div>
        <Badge badgeContent={content} classes={{ badge: classes.badge, root: classes.root }}>
          {children}
        </Badge>
      </div>
    );
  }

  return <div style={styles.root}>{children}</div>;
}

DrawerSubMenuItem.propTypes = {
  /**
   * Badge content
   */
  content: PropTypes.number.isRequired,
  /**
   * Element children to be render
   */
  children: PropTypes.element.isRequired,
  /**
   * Style classes
   */
  classes: PropTypes.object,
};

DrawerSubMenuItem.defaultProps = {
  classes: {},
};

export default withStyles(styles)(DrawerSubMenuItem);

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ToastComponent from 'src/components/common/Toast/ToastComponent';
import { hideToast } from 'src/store/ducks/toasts';

const ToastContainer = () => {
  const toasts = useSelector(state => state.toasts);
  const dispatch = useDispatch();

  const handleToastClick = (toastId, toastOnClick) => {
    if (toastOnClick) toastOnClick();
    dispatch(hideToast(toastId));
  };

  return (
    <ToastComponent
      toasts={toasts}
      hideToast={toastId => dispatch(hideToast(toastId))}
      handleToastClick={handleToastClick}
    />
  );
};

export default ToastContainer;

import React from 'react';
import { Link } from 'react-router-dom';

import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';
import PropTypes from 'prop-types';

import classes from 'src/components/ClientLayout/style.module.scss';
import HeaderMenu from 'src/HeaderMenu';

const ClientLayoutComponent = ({ user, intl, children }) => (
  <div className={classes.clientLayout}>
    <div className={classes.header}>
      <div className={classes.container}>
        <div className={classes.logo}>
          <Link to="/orders">
            <img src="/assets/img/logo_rede.svg" alt="Logo Rede Vistorias" />
          </Link>
        </div>
        {user.authRole === 'ROLE_CLIENT' && (
          <div className={classes.credit}>
            <div className={classes.creditIcon}>
              <AccountBalanceWallet />
            </div>
            <div className={classes.creditText}>
              {`${intl.formatMessage({ id: 'BALANCE' })}: ${user.accountBalance}`}
            </div>
          </div>
        )}
        <div className={classes.right}>
          <HeaderMenu />
        </div>
      </div>
    </div>
    {children}
  </div>
);

ClientLayoutComponent.propTypes = {
  user: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default ClientLayoutComponent;

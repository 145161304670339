import React from 'react';

import PropTypes from 'prop-types';

import Spinner from 'src/components/common/Spinner';
import classes from 'src/orderForm/fields/FileUpload/style.module.scss';

const FileUploadComponent = ({ formData, id, name, options, file, remoteUri, isBusy, handleChange }) => (
  <div className={classes.fileUpload}>
    {isBusy && (
      <div className={classes.isBusy}>
        <div className={classes.spinnerWrapper}>
          <Spinner />
        </div>
      </div>
    )}
    <label htmlFor={id}>{name}</label>
    <input
      type="file"
      id={id}
      onChange={handleChange}
      accept={options.accept}
      data-cy={`${String(name).toLowerCase()}-file`}
    />
    <label htmlFor={id}>Selecionar imagem...</label>
    {file && (
      <div className={classes.fileInfo}>
        <span className={classes.fileName}>{file.name}</span> (
        {(file.size / 1024).toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
        KB)
      </div>
    )}
    {(formData || remoteUri) && (
      <div className={classes.imageWrapper}>
        <img src={remoteUri || formData} alt="Imagem da fachada" />
      </div>
    )}
  </div>
);

FileUploadComponent.propTypes = {
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isBusy: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  required: PropTypes.bool.isRequired,
  formData: PropTypes.string,
  file: PropTypes.instanceOf(File),
  remoteUri: PropTypes.string,
};

FileUploadComponent.defaultProps = {
  formData: null,
  file: null,
  remoteUri: null,
};

export default FileUploadComponent;

import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/LocationReferencesSection/style.module.scss';
import Address from 'src/svg-icons/address';

function LocationReferences({ locationReferences }) {
  return (
    <Grid item xs={12}>
      <div className={classes.Header}>
        <Address className={classes.Icon} />
        <FormattedMessage id="location_references" />
      </div>
      <div className={classes.Information}>
        <div className={classes.SubHeader}>
          <FormattedMessage id="geo_link" />
        </div>
        <span className={classes.limit}>
          <a
            href={locationReferences.geo_link}
            target="_blank"
            rel="noreferrer noopener"
            data-cy="order-details-geo-link"
          >
            {locationReferences.geo_link}
          </a>
        </span>
      </div>
      <div className={classes.Information}>
        <div className={classes.SubHeader}>
          <FormattedMessage id="photo" />
        </div>
        <div className={classes.imageWrapper}>
          <a href={locationReferences.photo} target="_blank" rel="noreferrer noopener">
            <img src={locationReferences.photo} alt="Foto da fachada" />
          </a>
        </div>
      </div>
    </Grid>
  );
}

LocationReferences.propTypes = {
  /**
   * Object that contains the address information
   */
  locationReferences: PropTypes.object.isRequired,
};

export default LocationReferences;

import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import PropTypes from 'prop-types';

import classes from 'src/components/common/CardList/style.module.scss';
import Spinner from 'src/components/common/Spinner';

const CardList = ({ cards, hasMore, loadMore, Element }) =>
  cards.length > 0 ? (
    <div className={classes.cardList}>
      <InfiniteScroll
        pageStart={0}
        initialLoad={false}
        loadMore={loadMore}
        hasMore={!loadMore ? false : hasMore}
        useWindow={false}
        loader={
          <div className={classes.loading} key="loader">
            <Spinner />
          </div>
        }
      >
        <ul>
          {cards.map(item => (
            <li key={item?.data?.id || item?.id || item?.public_id || null} className="fade-in">
              <Element data={item?.data || item} />
            </li>
          ))}
        </ul>
      </InfiniteScroll>
    </div>
  ) : null;

CardList.propTypes = {
  cards: PropTypes.array.isRequired,
  Element: PropTypes.elementType.isRequired,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
};

CardList.defaultProps = {
  hasMore: false,
  loadMore: () => {},
};

export default CardList;

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/BudgetDuringInspectionSection/style.module.scss';

const BudgetDuringInspectionSectionGridItem = ({ xs, dataCy, labelId, valueId }) => (
  <Grid item xs={xs} data-cy={dataCy}>
    <div className={classes.subHeader}>
      <FormattedMessage id={labelId} />
    </div>

    <FormattedMessage id={valueId} />
  </Grid>
);

BudgetDuringInspectionSectionGridItem.propTypes = {
  xs: PropTypes.number,
  dataCy: PropTypes.string.isRequired,
  labelId: PropTypes.string.isRequired,
  valueId: PropTypes.string.isRequired,
};

BudgetDuringInspectionSectionGridItem.defaultProps = {
  xs: 12,
};

export default BudgetDuringInspectionSectionGridItem;

import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const styles = {
  bootstrapInputCost: {
    borderRadius: 4,
    fontSize: 'calc(6px + 0.4vw)',
    padding: '8px 12px',
    width: 'calc(100% - 24px)',
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    fontFamily: 'Open Sans',
    backgroundColor: '#F4F3F4',
  },
  cost: {
    fontFamily: 'Open Sans',
    color: '#858585',
    fontSize: 'calc(6px + 0.3vw)',
    textAlign: 'left',
  },
};

const PackageConsumption = ({ classes, data }) => {
  if (data.requiredStandardMeters) {
    return (
      <Grid container spacing={16}>
        <Grid item xs={6}>
          <div style={styles.cost}>
            <label htmlFor="used-value">
              <FormattedMessage id="usedValue" />
            </label>
            <TextField
              id="used-value"
              data-cy="used-value"
              type="text"
              value={`${data.requiredStandardMeters.formattedValue}${data.requiredStandardMeters.unit}`}
              disabled
              fullWidth
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.bootstrapInputCost,
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div style={styles.cost}>
            <label htmlFor="balance">
              <FormattedMessage id="packagesBalance" />
            </label>
            <TextField
              id="balance"
              data-cy="balance"
              type="text"
              value={`${data.balance.formattedValue} ${data.balance.unit}`}
              disabled
              fullWidth
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.bootstrapInputCost,
                },
              }}
            />
          </div>
        </Grid>
      </Grid>
    );
  }
  return <FormattedMessage id="priceToDefine" />;
};

PackageConsumption.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default withStyles(styles)(PackageConsumption);

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ClientDetails from 'src/components/ClientsPage/ClientDetails';
import ClientForm from 'src/components/ClientsPage/ClientForm';
import ClientFullDetails from 'src/components/ClientsPage/ClientFullDetails';
import ClientList from 'src/components/ClientsPage/ClientList';
import ClientSummary from 'src/components/ClientsPage/ClientSummary';
import ClientUsers from 'src/components/ClientsPage/ClientUsers';
import CreateUser from 'src/components/ClientsPage/CreateUser';
import PackagePurchase from 'src/components/ClientsPage/PackagePurchase';
import UpdateUserPermissions from 'src/components/ClientsPage/UpdateUserPermissions';
import Section from 'src/components/Page/Section';

const ClientsPage = () => (
  <>
    <Section cols={6}>
      <Switch>
        <Route exact path="/clients">
          <Redirect to="/clients/enabled" />
        </Route>
        <Route path="/clients/new" component={ClientList} />
        <Route path="/clients/:status" component={ClientList} />
      </Switch>
    </Section>
    <Section cols={6}>
      <Switch>
        <Route path="/clients/new" render={() => <ClientForm action="new" />} />
        <Route path="/clients/:status/:id/edit" render={() => <ClientForm action="edit" />} />
        <Route path="/clients/:status/:id/details" component={ClientFullDetails} />
        <Route path="/clients/:status/:id/packages" component={PackagePurchase} />
        <Route path="/clients/:status/:id/summary" component={ClientSummary} />
        <Route path="/clients/:status/:id/users/:userId/edit" component={UpdateUserPermissions} />
        <Route path="/clients/:status/:id/users/new" component={CreateUser} />
        <Route path="/clients/:status/:id/users" component={ClientUsers} />
        <Route path="/clients/:status/:id" component={ClientDetails} />
      </Switch>
    </Section>
  </>
);

export default ClientsPage;

import React, { useRef, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';

import ColorButton from 'src/components/common/elements/ColorButton';
import classes from 'src/inspectionAttach/PropertyAttach.module.css';
import PropertySearch from 'src/inspectionAttach/PropertySearch';

const PropertyAttach = ({
  apiUrl,
  clientPanel,
  header,
  searchPlaceholder,
  subHeader,
  handleContinueWithouAttachment,
  handleSearchConfirm,
}) => {
  const [triggerReset, setTriggerReset] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelect = option => {
    setSelectedOption(option);
  };

  const handleCancel = () => {
    setTriggerReset(triggerReset + 1);
    setSelectedOption(null);
  };

  const handleConfirm = () => {
    if (selectedOption) {
      handleSearchConfirm(selectedOption);
    }
  };

  return (
    <div className={clientPanel ? classes.center : classes.centerHeight}>
      <Paper className={clientPanel ? null : classes.Paper} elevation={0}>
        <Grid container justify="center" alignItems="center" spacing={16}>
          <Grid item xs={10}>
            <div className={classes.Header}>
              <span>{header}</span>
            </div>

            <div className={classes.SubHeader}>
              <span>{subHeader}</span>
            </div>
          </Grid>
        </Grid>

        <div className={classes.Body}>
          <Grid container justify="center" alignItems="center" spacing={16}>
            <Grid item xs={10}>
              <PropertySearch
                handleSelect={handleSelect}
                searchPlaceholder={searchPlaceholder}
                apiUrl={apiUrl}
                resetValue={triggerReset}
              />
              <div className={`${classes.textSm} text-left`}>
                <FormattedMessage id="selectPropertyTip" />
              </div>

              {selectedOption && (
                <div className="mt-3">
                  <div className={classes.optionSelected}>
                    <p className={classes.bold}>Imóvel selecionado</p>
                    <div className={classes.optionInfo}>
                      <div>
                        <p className={`${classes.bold}`}>Identificação do imóvel</p>
                        <p>{selectedOption.identification}</p>
                      </div>
                      <div>
                        <p className={`${classes.bold}`}>Endereço</p>
                        <p>
                          {selectedOption?.address?.address}, {selectedOption?.address?.number},{' '}
                          {selectedOption?.address?.neighborhood}, {selectedOption?.address?.city} -{' '}
                          {selectedOption?.address?.state}, {selectedOption?.address?.zip_code}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={classes.buttons}>
                    <div>
                      <ColorButton color="red" onClick={handleCancel}>
                        <FormattedMessage id="BUTTON.CANCEL" />
                      </ColorButton>
                    </div>
                    <div>
                      <ColorButton color="green" onClick={handleConfirm}>
                        <FormattedMessage id="BUTTON.CONFIRM" />
                      </ColorButton>
                    </div>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid container justify="center" alignItems="center" spacing={16}>
            <Grid item xs={10}>
              <hr className="mt-5 mb-0" />
            </Grid>
          </Grid>

          <Grid container justify="center" alignItems="center" spacing={16}>
            <Grid item xs={10}>
              <div className={classes.Header}>
                <span>
                  <FormattedMessage id="selectPropertyFooterHeader" />
                </span>
              </div>
              <div className={classes.SubHeader}>
                <span>
                  <FormattedMessage id="selectPropertyFooterSubHeader" />
                </span>
              </div>
              <div className="mt-3">
                <ColorButton color="blue" onClick={() => handleContinueWithouAttachment()}>
                  <FormattedMessage id="createNew" />
                </ColorButton>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </div>
  );
  // }
};

PropertyAttach.propTypes = {
  header: PropTypes.object.isRequired,
  subHeader: PropTypes.object.isRequired,
  handleSearchConfirm: PropTypes.func.isRequired,
  handleContinueWithouAttachment: PropTypes.func.isRequired,
  apiUrl: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string,
  clientPanel: PropTypes.bool,
};

PropertyAttach.defaultProps = {
  searchPlaceholder: null,
  clientPanel: false,
};

export default PropertyAttach;

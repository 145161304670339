import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import ClientFiltersBar from 'src/components/ClientFiltersBar';
import FeatureSwitch from 'src/components/common/FeatureSwitch';
import ExtraActions from 'src/components/ExtraActions';
import ChecklistForm from 'src/components/ExtraActions/ChecklistForm';
import RepairBudgetForm from 'src/components/ExtraActions/RepairBudgetForm';
import OrderToolbar from 'src/components/OrderToolbar/OrderToolbar';
import { ITEMS_PER_PAGE } from 'src/constants';
import classes from 'src/containers/ClientOrders/ClientOrders.module.css';
import { AsyncServiceContext } from 'src/contexts/AsyncServiceContext';
import Empty from 'src/Empty';
import Loading from 'src/Loading';
import OrderDetails from 'src/orderDetails/OrderDetails';
import EditOrderFormPage from 'src/orderForm/OrderForm/OrderFormPage/EditOrderFormPage';
import OrdersList from 'src/OrdersList';
import {
  clearCurrentOrder,
  clearOrders,
  fetchOrders,
  setHasMoreOrders,
  setOrdersLoading,
  setOrderStatusToSearch,
  setOrderByDir,
} from 'src/store/ducks/orders';

let source = null;

class ClientOrders extends React.Component {
  static contextType = AsyncServiceContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      orderId: null,
      searchString: '',
      searchTags: [],
      searchType: '',
      selectedItems: new Set(),
      selectedItem: null,
    };
    this.timer = null;
  }

  componentDidMount() {
    const { params, path } = this.props.match;
    const { history } = this.props;

    this.unsubscribeFromHistory = history.listen(({ pathname }) => {
      this.handleLocationChange(pathname);
    });

    if (!params.orderId && path === '/orders') {
      this.getOrderListHandler();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { orders } = this.props;
    const { params, path } = this.props.match;
    const { searchString } = this.state;

    const { path: prevPath } = prevProps.match;
    const { searchString: prevSearchString } = prevState;

    if (!params.orderId && path === '/orders' && path !== prevPath && !orders.length) {
      this.getOrderListHandler(true);
    }

    if (searchString !== prevSearchString) {
      this.setState({ selectedItems: new Set() });
    }
  }

  componentWillUnmount() {
    this.unsubscribeFromHistory();
    this.props.clearCurrentOrder();
    this.props.clearOrders();
    this.props.setHasMoreOrders(true);
    this.props.setOrdersLoading(false);
    this.props.setOrderStatusToSearch('all');

    if (source) {
      source.cancel();
    }

    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  handleLocationChange = (pathname = '') => {
    this.props.clearOrders();
    this.props.setHasMoreOrders(true);
    this.props.setOrdersLoading(false);
    this.props.setOrderStatusToSearch('all');

    if (pathname === '/orders') {
      this.setState({ orderId: null, selectedItems: new Set(), selectedItem: null });

      this.props.clearCurrentOrder();
    }
  };

  getOrderListHandler = (append = false) => {
    const { searchString } = this.state;
    this.props.setOrderByDir('desc');
    this.props.fetchOrders({
      search: searchString,
      status: 'all',
      withLoading: true,
      append,
    });
  };

  /*
    Function to show the orders details
  */
  orderShowDetailsAction = orderIndex => {
    const { history, orders } = this.props;

    const order = { ...orders[orderIndex] };
    const orderId = order.id;

    this.setState({
      orderId,
      selectedItems: new Set(),
      selectedItem: order,
    });

    history.push(`/orders/${orderId}`);
  };

  /*
    Function to show the order edition
  */
  orderShowEditAction = () => {
    const { selectedItems } = this.state;
    const { history, match } = this.props;

    const newState = {
      selectedItems: new Set(),
      selectedItem: null,
    };

    if (selectedItems.size === 1) {
      newState.orderId = selectedItems.values().next().value;
    } else if (match.params.orderId) {
      newState.orderId = match.params.orderId;
    }

    this.setState(newState);

    history.push(`/orders/${newState.orderId}/edit`);
  };

  /*
    Function to delete the order
  */
  orderDeleteAction = () => {
    const { orderId: orderIdFromState } = this.state;
    const { currentOrder, history, match, intl } = this.props;

    const orderId = orderIdFromState || currentOrder.id;

    const message = intl.formatMessage({ id: 'MESSAGE.CANCEL_CONFIRMATION' });
    if (!window.confirm(message)) {
      return;
    }

    API.delete(`orders/${orderId}`)
      .then(() => {
        this.setState({
          orderId: null,
          selectedItems: new Set(),
          selectedItem: null,
        });

        if (match.path !== '/orders') {
          history.push('/orders');
        }

        this.getOrderListHandler();
      })
      .catch(error => {
        console.log(error);
      });
  };

  actionGeneratePhotos = () => {
    let { orderId } = this.state;
    const { params } = this.props.match;
    const { showReportLoadingToast } = this.context;

    orderId = orderId ? orderId : params?.orderId;

    API.post(`orders/${orderId}/photos`, {})
      .then(res => {
        showReportLoadingToast({
          label: 'arquivo',
          requestId: res.data?.request_id,
          withPictures: true,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  cancelEditOrder = () => {
    const { history } = this.props;

    this.setState({ orderId: null, selectedItem: null });

    history.push('/orders');
  };

  successfulOrderEdition = () => {
    const { history } = this.props;
    const { orderId } = this.state;

    this.setState({ orderId: null, selectedItem: null });

    history.push(`/orders/${orderId}`);
  };

  /*
    Function to load more items for the infinite scroll
  */
  loadMoreItems = page => {
    const { searchString, searchTags, searchType } = this.state;
    const { hasMoreOrders } = this.props;

    if (hasMoreOrders) {
      const offset = page * ITEMS_PER_PAGE;

      this.props.fetchOrders({
        status: 'all',
        limit: ITEMS_PER_PAGE,
        offset,
        search: searchString,
        tags: searchTags,
        type: searchType,
        withLoading: false,
        append: true,
      });
    }
  };

  /*
    Function to handle order item checkbox
  */
  checkOneItemAction = (id, e) => {
    const { selectedItems } = this.state;
    const { orders } = this.props;
    e.stopPropagation();
    const newSelectedItems = new Set(selectedItems);
    if (selectedItems.has(id)) {
      newSelectedItems.delete(id);
    } else {
      newSelectedItems.add(id);
    }
    let orderId = null;
    let selectedItem = null;
    if (newSelectedItems.size === 1) {
      const activeIndex = orders.findIndex(order => order.id === id);
      selectedItem = { ...orders[activeIndex] };
      orderId = selectedItem.id;
    }

    this.setState({
      selectedItems: newSelectedItems,
      orderId,
      selectedItem,
    });
  };

  triggerSearch = (value, tags, type) => {
    if (source !== null) {
      source.cancel();
    }

    source = API.CancelToken.source();

    this.setState({
      searchString: value,
      searchTags: tags,
      searchType: type,
    });

    this.props.fetchOrders({
      status: 'all',
      limit: ITEMS_PER_PAGE,
      search: value,
      tags,
      type,
      cancelToken: source.token,
      withLoading: true,
      append: false,
    });
  };

  searchHandler = (value, tags = [], type = null) => {
    const { history, setOrderStatusToSearch } = this.props;
    const { path } = this.props.match;

    setOrderStatusToSearch('search');

    clearTimeout(this.timer);

    this.timer = setTimeout(() => this.triggerSearch(value, tags, type), 500);

    if (path !== '/orders') {
      history.push('/orders');
    }
  };

  changeOrderByToSearch = () => {
    const { searchString, searchTags, searchType } = this.state;
    if (source !== null) {
      source.cancel();
    }

    source = API.CancelToken.source();

    this.setState({
      searchString: searchString,
      searchTags: searchTags,
      searchType: searchType,
    });

    this.props.fetchOrders({
      status: 'all',
      limit: ITEMS_PER_PAGE,
      search: searchString,
      tags: searchTags,
      type: searchType,
      cancelToken: source.token,
      withLoading: true,
      append: false,
    });
  };

  callActionHandler = (params, action) => {
    const { orderId: stateOrderId } = this.state;
    const { history } = this.props;
    const { params: matchParams } = this.props.match;

    const orderId = params?.orderId || matchParams?.orderId || stateOrderId;

    switch (action) {
      case 'export': {
        this.actionGeneratePhotos(params);
        break;
      }
      case 'edit': {
        this.orderShowEditAction(params);
        break;
      }
      case 'delete': {
        this.orderDeleteAction(params);
        break;
      }
      case 'extra': {
        history.push('/orders/extra-actions');
        break;
      }
      case 'checklist-form': {
        history.push('/orders/checklist');
        break;
      }
      case 'repair-budget-form': {
        history.push(`/orders/${orderId}/repair-budget`);
        break;
      }
      case 'order-details': {
        history.push(`/orders/${orderId}`);
        break;
      }
      default: {
        break;
      }
    }
  };

  clearTagsTypesAndFreeTermSearch = () => {
    this.setState({ searchString: '', searchTags: [], searchType: '' });
  };

  render() {
    const { selectedItem, selectedItems, orderId: stateOrderId, searchTags, searchType } = this.state;
    const { component, currentOrder, hasMoreOrders, orders, ordersLoading, history } = this.props;
    const { params } = this.props.match;

    const orderId = params?.orderId || stateOrderId || currentOrder?.id;
    const orderIndex = orders.findIndex(order => order.id === orderId);

    let contentElement = null;
    let requireToolbar = false;
    let toolbar = null;
    let requireFiltersBar = false;
    let filtersBar = null;

    switch (component) {
      case 'orders-list': {
        requireToolbar = true;
        requireFiltersBar = true;

        if (ordersLoading) {
          contentElement = <Loading clientPanel />;
        } else if (orders.length > 0) {
          contentElement = (
            <OrdersList
              orderShowDetailsAction={this.orderShowDetailsAction}
              data={orders}
              checkOne={this.checkOneItemAction}
              selectedCheckboxes={selectedItems}
              loadMoreItems={this.loadMoreItems}
              hasMoreItems={hasMoreOrders}
              fromPage="client"
              isClientPanel
            />
          );
        } else {
          contentElement = (
            <Empty
              header={<FormattedMessage id="empty.listHeader" />}
              subHeader={<FormattedMessage id="empty.listSubHeader" />}
            />
          );
        }
        break;
      }

      case 'order-details': {
        requireToolbar = true;
        contentElement = (
          <OrderDetails
            changePage={this.callActionHandler}
            orderEdit={this.orderShowEditAction}
            currentPage="client"
            clientPanel
          />
        );
        break;
      }

      case 'order-edit': {
        requireToolbar = false;
        contentElement = (
          <EditOrderFormPage
            editOrderId={orderId}
            successfulOrderEdition={() => this.successfulOrderEdition()}
            cancelEditOrder={() => this.cancelEditOrder()}
            clientPanel
          />
        );
        break;
      }

      case 'order-extra-actions': {
        requireToolbar = false;
        contentElement = (
          <ExtraActions
            changePage={this.callActionHandler}
            orderData={currentOrder || null}
            orderId={orderId}
            orderType={orders[orderIndex]?.type || currentOrder?.type}
            orderEdit={this.orderShowEditAction}
            clientPanel
          />
        );
        break;
      }

      case 'checklist-form': {
        requireToolbar = false;
        contentElement = (
          <ChecklistForm
            changePage={this.callActionHandler}
            orderId={orderId}
            orderEdit={this.orderShowEditAction}
            clientPanel
          />
        );
        break;
      }

      case 'repair-budget-form': {
        requireToolbar = false;
        contentElement = (
          <RepairBudgetForm
            changePage={this.callActionHandler}
            orderId={orderId}
            orderEdit={this.orderShowEditAction}
            clientPanel
          />
        );
        break;
      }

      default: {
        break;
      }
    }

    let contentClass = classes.ContentWrapper;

    if (requireToolbar) {
      contentClass = classes.ContainerWrapperWithToolbar;

      toolbar = (
        <OrderToolbar
          history={history}
          searchHandler={this.searchHandler}
          actionHandler={this.callActionHandler}
          selectedItem={selectedItem}
          selectedItems={selectedItems}
          clearChosenTypeAndTags={!searchTags.length && !searchType}
        />
      );
    }

    if (requireFiltersBar) {
      contentClass = classes.ContainerWrapperWithFiltersBar;

      filtersBar = (
        <FeatureSwitch feature="ORDER_STATUS_FILTERS">
          <ClientFiltersBar
            clearTagsTypesAndFreeTermSearch={this.clearTagsTypesAndFreeTermSearch}
            changeOrderByToSearch={this.changeOrderByToSearch}
          />
        </FeatureSwitch>
      );
    }

    return (
      <div className={classes.ContainerWrapper}>
        {toolbar}
        {filtersBar}
        <div className={contentClass}>{contentElement}</div>
      </div>
    );
  }
}

ClientOrders.propTypes = {
  component: PropTypes.oneOf([
    'orders-list',
    'order-details',
    'order-edit',
    'order-extra-actions',
    'checklist-form',
    'repair-budget-form',
  ]).isRequired,
  hasFeatureBar: PropTypes.bool,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currentOrder: PropTypes.object,
  hasMoreOrders: PropTypes.bool.isRequired,
  orders: PropTypes.array.isRequired,
  ordersLoading: PropTypes.bool.isRequired,
  orderStatusToSearch: PropTypes.string.isRequired,
  clearCurrentOrder: PropTypes.func.isRequired,
  clearOrders: PropTypes.func.isRequired,
  fetchOrders: PropTypes.func.isRequired,
  setHasMoreOrders: PropTypes.func.isRequired,
  setOrdersLoading: PropTypes.func.isRequired,
  setOrderStatusToSearch: PropTypes.func.isRequired,
  setOrderByDir: PropTypes.func.isRequired,
};

ClientOrders.defaultProps = {
  hasFeatureBar: false,
  currentOrder: null,
};

const mapStateToProps = state => ({
  currentOrder: state.orders.currentOrder,
  hasMoreOrders: state.orders.hasMoreOrders,
  orders: state.orders.orders,
  ordersLoading: state.orders.ordersLoading,
  orderStatusToSearch: state.orders.orderStatusToSearch,
  setOrderByDir: state.orders.setOrderByDir,
});

const mapDispatchToProps = {
  clearCurrentOrder,
  clearOrders,
  fetchOrders,
  setHasMoreOrders,
  setOrdersLoading,
  setOrderStatusToSearch,
  setOrderByDir,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(ClientOrders)));

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const ResendEnvelope = props => (
  <SvgIcon {...props}>
    <path
      fill="#393939"
      d="M14.5,9.1H3.6c-0.7,0-1.4,0.6-1.4,1.4l0,8.1c0,0.7,0.6,1.4,1.4,1.4h10.9c0.7,0,1.4-0.6,1.4-1.4v-8.1
        C15.9,9.8,15.2,9.1,14.5,9.1z M14.5,11.9l-5.4,3.4l-5.4-3.4v-1.4l5.4,3.4l5.4-3.4V11.9z"
    />
    <path
      fill="#393939"
      d="M20.3,10.5c-0.7-3.4-3.7-5.9-7.2-5.9c-2.5,0-4.7,1.2-6,3.1h2c1-1,2.4-1.6,4-1.6c2.7,0,5,1.9,5.7,4.4h-2
        l2.8,2.8l2.8-2.8H20.3z"
    />
  </SvgIcon>
);

ResendEnvelope.displayName = 'ResendEnvelope';
ResendEnvelope.muiName = 'SvgIcon';

export default ResendEnvelope;

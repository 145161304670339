import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const ReportSubmission = props => (
  <SvgIcon viewBox="0 0 34.016 34.016" {...props}>
    <path
      d="M21.2198 14.4677C23.0137 14.4677 24.468 13.0134 24.468 11.2195C24.468 9.42554 23.0137 7.97127 21.2198 7.97127C19.4258 7.97127 17.9715 9.42554 17.9715 11.2195C17.9715 11.3554 17.9799 11.4893 17.9961 11.6208L12.6473 14.2952C12.0631 13.7316 11.2682 13.385 10.3924 13.385C8.59842 13.385 7.14414 14.8392 7.14414 16.6332C7.14414 18.4271 8.59842 19.8814 10.3924 19.8814C11.2682 19.8814 12.0631 19.5347 12.6473 18.9712L17.9961 21.6456C17.9799 21.7771 17.9715 21.911 17.9715 22.0469C17.9715 23.8408 19.4258 25.2951 21.2198 25.2951C23.0137 25.2951 24.468 23.8408 24.468 22.0469C24.468 20.2529 23.0137 18.7987 21.2198 18.7987C20.3439 18.7987 19.5491 19.1453 18.9648 19.7089L13.616 17.0345C13.6322 16.903 13.6406 16.769 13.6406 16.6332C13.6406 16.4973 13.6322 16.3634 13.616 16.2319L18.9648 13.5575C19.549 14.1211 20.3439 14.4677 21.2198 14.4677Z"
      fill="#393939"
    />
    <path
      d="M16.8888 0.39209C7.90206 0.39209 0.647705 7.64644 0.647705 16.6332C0.647705 25.6199 7.90206 32.8743 16.8888 32.8743C25.8755 32.8743 33.1299 25.6199 33.1299 16.6332C33.1299 7.64644 25.8755 0.39209 16.8888 0.39209ZM16.8888 30.7088C9.09307 30.7088 2.81318 24.4289 2.81318 16.6332C2.81318 8.83746 9.09307 2.55757 16.8888 2.55757C24.6845 2.55757 30.9644 8.83746 30.9644 16.6332C30.9644 24.4289 24.6845 30.7088 16.8888 30.7088Z"
      fill="#393939"
    />
  </SvgIcon>
);

ReportSubmission.displayName = 'ReportSubmission';
ReportSubmission.muiName = 'SvgIcon';

export default ReportSubmission;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let MenuVistorias = props => (
  <SvgIcon {...props} viewBox="0 0 50 49.998">
    <path
      fill="#5678A0"
      d="M25-0.002c-13.808,0-25,11.193-25,25c0,13.808,11.193,25,25,25c13.806,0,25-11.192,25-25
	C50,11.19,38.807-0.002,25-0.002z M25,47.998c-12.703,0-23-10.297-23-23c0-12.703,10.297-23,23-23c12.702,0,23,10.297,23,23
	C48,37.701,37.702,47.998,25,47.998z"
    />
    <path
      fill="#FFFFFF"
      d="M20.77,19.851h8.459v-2.06l-2.644-1.03v-1.543c0-0.854-0.18-1.029-1.057-1.029h-1.058
		c-0.876,0-1.058,0.177-1.058,1.029v1.543l-2.644,1.03V19.851z"
    />
    <path
      fill="#FFFFFF"
      d="M31.874,15.733h-4.23l2.644,1.029v4.118H19.713v-4.118l2.644-1.029h-4.229
		c-0.877,0-1.587,0.691-1.587,1.545V34.26c0,0.853,0.71,1.546,1.587,1.546h13.746c0.877,0,1.586-0.693,1.586-1.546h0.001V17.278
		C33.46,16.424,32.751,15.733,31.874,15.733z"
    />
  </SvgIcon>
);
MenuVistorias = pure(MenuVistorias);
MenuVistorias.displayName = 'MenuVistorias';
MenuVistorias.muiName = 'SvgIcon';

export default MenuVistorias;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import PlanSwitch from 'src/components/common/PlanSwitch';

const styles = {
  bootstrapInput: {
    width: 'calc(100% - 24px)',
    padding: '8px 12px',
    border: '1px solid #ced4da',
    borderRadius: 4,
    fontWeight: 'normal',
    fontSize: 'calc(6px + 0.4vw)',
    fontFamily: 'Open Sans',

    '&:focus': {
      borderColor: '#80bdff',
    },

    '&.is-disabled': {
      backgroundColor: '#f4f3f4',
      borderColor: '#ced4da',
      cursor: 'not-allowed',
    },
  },
  bootstrapInputError: {
    width: 'calc(100% - 24px)',
    padding: '8px 12px',
    border: '1px solid red',
    borderRadius: 4,
    fontFamily: 'Open Sans',
    fontSize: 'calc(6px + 0.4vw)',

    '&:focus': {
      borderColor: '#80bdff',
    },
  },
  label: {
    marginTop: '0.3vh',
    fontFamily: 'Open Sans',
    fontSize: 'calc(6px + 0.3vw)',
  },
};

const numberFormatCustom = attr => {
  const { inputRef, onChange, unit, ...other } = attr;

  let prefix = '';
  let suffix = '';

  if (unit === 'square-meters') {
    prefix = '';
    suffix = ' m²';
  } else if (unit === 'monetary') {
    prefix = 'R$ ';
    suffix = '';
  } else if (unit === 'credits') {
    prefix = '';
    suffix = ' créditos';
  }

  return (
    <NumberFormat
      {...other}
      ref={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value ? values.floatValue : values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale
      prefix={prefix}
      suffix={suffix}
      isNumericString
      allowNegative={false}
    />
  );
};

const RequestFormWidgetPrice = ({ classes, id, label, onChange, options, rawErrors, required, value }) => {
  let error = false;

  if (rawErrors) {
    if (rawErrors.length > 0) {
      error = true;
    }
  }

  const bootstrapClasses = error ? classes.bootstrapInputError : classes.bootstrapInput;
  // const input = attr => numberFormatCustom(attr, options);

  return (
    <PlanSwitch planToHide={options.unit === 'square-meters' ? 'CONTROL' : undefined}>
      <div>
        <label style={styles.label} htmlFor={id}>
          <FormattedMessage id={label} />
          {required ? '*' : null}
        </label>

        <TextField
          disabled={options.disabled || options.forceDisabled}
          fullWidth
          id={id}
          className="custom"
          value={value}
          onChange={event => onChange(event.target.value)}
          data-cy={id}
          InputProps={{
            disableUnderline: true,
            inputProps: { unit: options.unit },
            classes: {
              input: classNames(
                'customs',
                {
                  'is-disabled': options.disabled || options.forceDisabled,
                },
                bootstrapClasses
              ),
            },
            inputComponent: numberFormatCustom,
          }}
        />
      </div>
    </PlanSwitch>
  );
};

RequestFormWidgetPrice.propTypes = {
  classes: PropTypes.object,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  rawErrors: PropTypes.array,
  required: PropTypes.bool.isRequired,
  value: PropTypes.number,
};

RequestFormWidgetPrice.defaultProps = {
  classes: {},
  rawErrors: [],
  value: undefined,
};

export default withStyles(styles)(RequestFormWidgetPrice);

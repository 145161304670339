import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const EletronicSign = ({ fillColor, ...props }) => {
  const color = fillColor || '#CF5F91';
  return (
    <SvgIcon {...props} viewBox="0 0 34.016 34.016">
      <g>
        <rect
          x="25"
          y="10.8"
          transform="matrix(0.7071 0.7071 -0.7071 0.7071 19.3268 -13.436)"
          fill={color}
          width="1.8"
          height="11.7"
        />
        <g>
          <path
            fill={color}
            d="M12.8,26.4c0.1-0.1,0.2-0.1,0.2-0.2C14.7,24.5,29,10.2,29,10.2c1.5-1.5,1.5-4,0-5.5l0,0l0,0
        c-1.5-1.5-4-1.5-5.5,0c0,0-14.3,14.3-15.9,15.9c-0.1,0.1-0.1,0.2-0.2,0.2L12.8,26.4z"
          />
          <path
            fill={color}
            d="M6.2,22.3c-2.1,2.9-3.5,7-2.6,7.9c0.9,0.9,5-0.6,7.9-2.6L6.2,22.3z"
          />
        </g>
        <polygon
          fill={color}
          points="9.4,30.4 12.4,28.6 30.8,28.6 30.8,30.4 	"
        />
      </g>
    </SvgIcon>
  );
};

EletronicSign.displayName = 'EletronicSign';
EletronicSign.muiName = 'SvgIcon';

export default EletronicSign;

import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';

import classes from 'src/Loading.module.css';

const Loading = ({ borderColor, clientPanel, paperBackground }) => (
  <Paper
    className={!clientPanel ? classes.Paper : ''}
    elevation={0}
    style={{ background: paperBackground, borderColor }}
  >
    <Grid className={classes.Container} justify="center" alignItems="center" alignContent="center" container>
      <Grid item>
        <CircularProgress size={50} />
      </Grid>
    </Grid>
  </Paper>
);

Loading.propTypes = {
  borderColor: PropTypes.string,
  clientPanel: PropTypes.bool,
  paperBackground: PropTypes.string,
};

Loading.defaultProps = {
  borderColor: '#DEDEDE',
  clientPanel: false,
  paperBackground: '#EAEAEA',
};

export default Loading;

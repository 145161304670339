import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { Archive, BorderColor, Cancel, Delete, MergeType, PictureAsPdf, Reply, Unarchive } from '@material-ui/icons';
import PropTypes from 'prop-types';

import ReportOptions from 'src/components/ReportOptions/ReportOptions';

const styles = {
  paper: {
    marginLeft: '1vw',
    marginRight: '1vw',
    backgroundColor: '#EAEAEA',
    border: '#DEDEDE',
    borderStyle: 'solid',
    borderWidth: '2px',
    minHeight: 'calc(100% - 4px)',
  },
  center: {
    textAlign: 'center',
    height: '100%',
  },
  header: {
    fontSize: 'calc(16px + 0.4vw)',
    marginTop: '6vh',
  },
  subHeader: {
    fontSize: 'calc(14px + 0.4vw)',
  },
  buttonsDiv: {
    marginTop: '30px',
    marginBottom: '5vh',
  },
  buttonsMargin: {
    marginTop: '2vh',
  },
  newButton: {
    backgroundColor: '#ffffff',
    color: 'black',
    fontFamily: 'Open Sans',
    fontSize: 'calc(6px + 0.4vw)',
    textTransform: 'none',
    width: '10vw',
    minWidth: '150px',
    height: '2vw',
    minHeight: '32px',
    padding: '2px 6px',
    '&:hover': {
      borderColor: '#0062cc',
    },
  },
  label: {
    width: '90%',
    justifyContent: 'left',
  },
};

class OrderSelectionMultiple extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      open: false,
    };
    this.orderTypeConfigurations = JSON.parse(window.sessionStorage.getItem('orderTypeConfigurations'));
    this.supportMode = document.cookie.replace(/(?:(?:^|.*;\s*)SupportMode\s*=\s*([^;]*).*$)|^.*$/, '$1') === 'true';
  }

  loadOrderTypeConfigurations = () => {
    this.orderTypeConfigurations = JSON.parse(window.sessionStorage.getItem('orderTypeConfigurations'));
  };

  showReportOptionsHandler = event => {
    const { currentTarget } = event;
    this.setState({
      anchorEl: currentTarget,
      open: true,
    });
  };

  closeReportOptionsHandler = () => {
    this.setState({
      anchorEl: null,
      open: false,
    });
  };

  deleteHandler = event => {
    const { actionHandler, intl } = this.props;
    const message = intl.formatMessage({ id: 'MESSAGE.DELETE_CONFIRMATION' });
    if (window.confirm(message)) {
      actionHandler(event, 'delete');
    }
  };

  cancelHandler = event => {
    const { actionHandler } = this.props;
    actionHandler(event, 'cancel');
  };

  render() {
    const { anchorEl, open } = this.state;
    const {
      actionHandler,
      classes,
      currentPage,
      flags,
      itemsAmount,
      multipleArchiveAllowed,
      orderId,
      orderStatus,
      orderType,
      isArchived,
    } = this.props;
    let parentOrderType = orderType;
    if (orderType === 'marketplace_docusign') parentOrderType = 'docusign';
    if (orderType === 'marketplace_fichacerta') parentOrderType = 'fichacerta';
    let configurations = {
      canBeArchived: multipleArchiveAllowed,
    };
    if (this.orderTypeConfigurations === null || Object.keys(this.orderTypeConfigurations).length === 0) {
      this.loadOrderTypeConfigurations();
    }
    if (this.orderTypeConfigurations[parentOrderType] && this.orderTypeConfigurations[parentOrderType][orderStatus]) {
      configurations = this.orderTypeConfigurations[parentOrderType][orderStatus];
    }

    return (
      <div style={styles.center}>
        <Paper style={styles.paper} elevation={0}>
          <div style={styles.header}>
            <span>
              <FormattedMessage
                id="items"
                values={{
                  items: itemsAmount,
                }}
              />
            </span>
          </div>
          <div style={styles.subHeader}>
            <span>
              <FormattedMessage id="selected" />
            </span>
          </div>
          <div style={styles.buttonsDiv}>
            <Grid container>
              <Grid item xs={12}>
                <div style={styles.buttonsMargin}>
                  <Button
                    disabled={configurations.canBeExported !== true}
                    variant="outlined"
                    size="small"
                    classes={{ root: classes.newButton, label: classes.label }}
                    onClick={e => actionHandler(e, 'export')}
                  >
                    <Reply
                      style={{
                        fontSize: '1.2vw',
                        minWidth: '48px',
                        minHeight: '24px',
                      }}
                    />
                    <FormattedMessage id="export" />
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={styles.buttonsMargin}>
                  <Button
                    disabled={configurations.canGenerateReport !== true}
                    variant="outlined"
                    size="small"
                    classes={{ root: classes.newButton, label: classes.label }}
                    onClick={this.showReportOptionsHandler}
                  >
                    <PictureAsPdf
                      style={{
                        fontSize: '1.2vw',
                        minWidth: '48px',
                        minHeight: '24px',
                      }}
                    />
                    <FormattedMessage id="generatePdf" />
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={styles.buttonsMargin}>
                  <Button
                    disabled={configurations.canBeEdited !== true}
                    variant="outlined"
                    size="small"
                    classes={{ root: classes.newButton, label: classes.label }}
                    onClick={e => actionHandler(e, 'edit')}
                  >
                    <BorderColor
                      style={{
                        fontSize: '1.2vw',
                        minWidth: '48px',
                        minHeight: '24px',
                      }}
                    />
                    <FormattedMessage id="edit" />
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={styles.buttonsMargin}>
                  {currentPage === 'independent' ? (
                    <Button
                      disabled={configurations.canBeDeleted !== true}
                      variant="outlined"
                      size="small"
                      classes={{ root: classes.newButton, label: classes.label }}
                      onClick={this.deleteHandler}
                    >
                      <Delete
                        style={{
                          fontSize: '1.2vw',
                          minWidth: '48px',
                          minHeight: '24px',
                        }}
                      />
                      <FormattedMessage id="delete" />
                    </Button>
                  ) : (
                    <Button
                      disabled={configurations.canBeCanceled !== true}
                      variant="outlined"
                      size="small"
                      classes={{ root: classes.newButton, label: classes.label }}
                      onClick={this.cancelHandler}
                    >
                      <Cancel
                        style={{
                          fontSize: '1.2vw',
                          minWidth: '48px',
                          minHeight: '24px',
                        }}
                      />
                      <FormattedMessage id="cancel" />
                    </Button>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={styles.buttonsMargin}>
                  {isArchived ? (
                    <Button
                      disabled={configurations.canBeArchived !== true}
                      variant="outlined"
                      size="small"
                      classes={{
                        root: classes.newButton,
                        label: classes.label,
                      }}
                      onClick={e => actionHandler(e, 'unarchive')}
                    >
                      <Unarchive
                        style={{
                          fontSize: '1.2vw',
                          minWidth: '48px',
                          minHeight: '24px',
                        }}
                      />
                      <FormattedMessage id="unarchive" />
                    </Button>
                  ) : (
                    <Button
                      disabled={configurations.canBeArchived !== true}
                      variant="outlined"
                      size="small"
                      classes={{
                        root: classes.newButton,
                        label: classes.label,
                      }}
                      onClick={e => actionHandler(e, 'archive')}
                    >
                      <Archive
                        style={{
                          fontSize: '1.2vw',
                          minWidth: '48px',
                          minHeight: '24px',
                        }}
                      />
                      <FormattedMessage id="archive" />
                    </Button>
                  )}
                </div>
              </Grid>
              {currentPage === 'independent' && this.supportMode ? (
                <Grid item xs={12}>
                  <div style={styles.buttonsMargin}>
                    <Button
                      variant="outlined"
                      size="small"
                      classes={{
                        root: classes.newButton,
                        label: classes.label,
                      }}
                      onClick={e => actionHandler(e, 'merge')}
                    >
                      <MergeType
                        style={{
                          fontSize: '1.2vw',
                          minWidth: '48px',
                          minHeight: '24px',
                        }}
                      />
                      <FormattedMessage id="merge" />
                    </Button>
                  </div>
                </Grid>
              ) : null}
            </Grid>
            <ReportOptions
              show={open}
              closeHandler={this.closeReportOptionsHandler}
              anchorEl={anchorEl}
              options={configurations.reportsOptions}
              entityId={orderId}
              flags={flags}
            />
          </div>
        </Paper>
      </div>
    );
  }
}

OrderSelectionMultiple.propTypes = {
  itemsAmount: PropTypes.number.isRequired,
  orderType: PropTypes.string,
  orderStatus: PropTypes.string,
  orderId: PropTypes.string,
  actionHandler: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  currentPage: PropTypes.string.isRequired,
  flags: PropTypes.object,
  multipleArchiveAllowed: PropTypes.bool.isRequired,
  isArchived: PropTypes.bool.isRequired,
};

OrderSelectionMultiple.defaultProps = {
  orderId: null,
  orderStatus: null,
  orderType: null,
  flags: {},
};

export default withStyles(styles)(injectIntl(OrderSelectionMultiple));

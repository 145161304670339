import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let TypeTombamento = props => (
  <SvgIcon {...props} fill="none" viewBox="0 0 34.016 34.016">
    <path
      d="M12.8248 3.76068V1.81709C12.8248 0.7463 12.5905 0.52002 11.4752 0.52002H10.4246C9.3094 0.52002 9.07504 0.742259 9.07504 1.81709V3.76068L5.70508 5.04966V7.63976H16.1948V5.04966L12.8248 3.76068Z"
      fill="#7F2100"
    />
    <path
      d="M21.0681 4.31838V11.1351H15.1323V13.5231L15.2858 13.7696L16.3647 15.495H9.95614V20.5136H16.4172L15.5121 22.3198L15.403 22.5339V24.8735H21.0681V25.6251C21.0398 26.6757 20.1751 27.5121 19.1286 27.5H2.40403C1.34132 27.5121 0.472571 26.6676 0.460449 25.6049C0.460449 25.6008 0.460449 25.6008 0.460449 25.6008V4.66992C0.472571 3.60721 1.34132 2.75462 2.40403 2.77079H7.51554L4.20619 4.04765V9.13896H17.3183V3.67995L14.2191 2.395H19.1932C20.2155 2.37884 21.0519 3.24355 21.0681 4.31838Z"
      fill="#7F2100"
    />
    <path
      d="M23.5004 22.7724V23.8633H16.413V22.7724L17.5039 20.5904V19.5034H10.9661V16.5052H17.5039V15.4142L16.1422 13.2363V12.1453H23.2256V13.2363L22.1386 15.4142V16.5052H23.2256V19.5034H22.1386V20.5904L22.3084 20.8651L23.5004 22.7724Z"
      fill="#7F2100"
    />
  </SvgIcon>
);

TypeTombamento = pure(TypeTombamento);
TypeTombamento.displayName = 'TypeTombamento';
TypeTombamento.muiName = 'SvgIcon';

export default TypeTombamento;

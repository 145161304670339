import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import moment from 'moment';
import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import ColorButton from 'src/components/common/elements/ColorButton';
import { ReactComponent as EditIcon } from 'src/components/common/icons/edit.svg';
import Modal from 'src/components/common/Modal';
import classes from 'src/orderDetails/AnnotationSection/style.module.scss';
import { showToast } from 'src/store/ducks/toasts';
import AnnotationIcon from 'src/svg-icons/annotation';

const AnnotationSection = ({ annotation, orderId, orderCode, inspectionIcon, refreshHandler, orderPage }) => {
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const intl = useIntl();

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  const saveAnnotation = async () => {
    const formData = new FormData(document.getElementById('annotationForm'));
    const formValues = {
      description: formData.get('description'),
    };
    try {
      await API.patch(`orders/${orderId}/annotation`, formValues);
      dispatch(
        showToast({
          type: 'success',
          title: intl.formatMessage({ id: 'SUCCESS' }),
          text: intl.formatMessage({ id: 'ANNOTATION_UPDATED_SUCCESS' }),
          duration: 5000,
        })
      );
      closeModal();
      if (refreshHandler) {
        refreshHandler();
      }
    } catch (err) {
      console.debug('AnnotationSection saveAnnotation error ', err?.response || err);

      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'UNKNOWN_ERROR' }),
          duration: 5000,
        })
      );
    }
  };

  return (
    <div>
      <div className={orderPage ? classes.HeaderOrderPage : classes.Header}>
        <div>
          {orderPage && <AnnotationIcon viewBox="0 0 25 25" className={classes.Icon} />}

          <FormattedMessage id="ANNOTATION" />
        </div>

        <button className={classes.editButton} onClick={openModal}>
          <EditIcon width={20} />
        </button>
      </div>

      {orderPage && annotation?.date && (
        <div className={classes.date}>
          Editado: {`${moment(annotation.date).format('DD/MM/YYYY')} - ${moment(annotation.date).format('HH[h]mm')}`}
        </div>
      )}

      <div className={orderPage ? classes.InformationOrderPage : classes.Information}>{annotation?.description}</div>

      {showModal && (
        <Modal onClose={closeModal}>
          <div>
            <div>
              <div className={classes.headerModal}>
                <span>{inspectionIcon}</span>

                <span>{orderCode}</span>
              </div>

              <hr />

              <div className="mb-3">
                <AnnotationIcon viewBox="0 0 25 25" className={classes.Icon} />

                <FormattedMessage id="ANNOTATION" />
              </div>

              <form id="annotationForm">
                <div className={classes.annotation}>
                  <textarea
                    defaultValue={annotation?.description || ''}
                    name="description"
                    placeholder="Registrar anotação..."
                  />
                </div>
              </form>

              <div className="text-right">
                <ColorButton color="red" onClick={closeModal}>
                  <FormattedMessage id="BUTTON.CANCEL" />
                </ColorButton>

                <ColorButton color="green" onClick={() => saveAnnotation()}>
                  <FormattedMessage id="BUTTON.CONFIRM" />
                </ColorButton>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

AnnotationSection.propTypes = {
  orderPage: PropTypes.bool,
  annotation: PropTypes.object.isRequired,
  orderId: PropTypes.string.isRequired,
  orderCode: PropTypes.string.isRequired,
  inspectionIcon: PropTypes.object,
  refreshHandler: PropTypes.func,
};

export default AnnotationSection;

export const DATE_MASK = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

export const DATETIME_MASK = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  ':',
  /\d/,
  /\d/,
];

export const TIME_MASK = [/\d/, /\d/, ':', /\d/, /\d/];

export const USERNAME_MASK = Array.from({ length: 100 }).map(() => /[a-z0-9\.\_\@]/);

export const masksByCountry = {
  BR: {
    CPF_MASK: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
    CPF_MASK_PLACEHOLDER: '000.000.000-00',
    CPF_ONLY_MASK: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
    CNPJ_MASK: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/],
    CNPJ_MASK_LENGTH: 14,
    CELLPHONE_MASK: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    CELLPHONE_MASK_LENGTH: 15,
    CELLPHONE_MASK_PLACEHOLDER: '(00) 00000-0000',
    PHONE_MASK: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/],
    PHONE_MASK_PLACEHOLDER: '(00) 0000-0000',
    ZIPCODE_MASK: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
  },

  PT: {
    CPF_MASK: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    CPF_MASK_PLACEHOLDER: '000000000',
    CPF_ONLY_MASK: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    CNPJ_MASK: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    CNPJ_MASK_LENGTH: 9,
    CELLPHONE_MASK: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/],
    CELLPHONE_MASK_LENGTH: 11,
    CELLPHONE_MASK_PLACEHOLDER: '00 000 0000',
    PHONE_MASK: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/],
    PHONE_MASK_PLACEHOLDER: '00 000 0000',
    ZIPCODE_MASK: [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
  },
};

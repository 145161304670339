import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let StatusExpressa = props => (
  <SvgIcon {...props}>
    <path
      d="M17.008,1.914c-8.332,0-15.094,6.762-15.094,15.094c0,8.331,6.762,15.094,15.094,15.094
	c8.331,0,15.094-6.763,15.094-15.094C32.102,8.676,25.339,1.914,17.008,1.914z M22.592,15.652l-7.817,13.403V19.002h-3.351V6.717
	h11.168l-4.468,8.935H22.592z"
    />
  </SvgIcon>
);
StatusExpressa = pure(StatusExpressa);
StatusExpressa.displayName = 'StatusExpressa';
StatusExpressa.muiName = 'SvgIcon';

export default StatusExpressa;

import React from 'react';

import PropTypes from 'prop-types';

import CardList from 'src/components/common/CardList';
import Spinner from 'src/components/common/Spinner';
import InspectorCard from 'src/components/InspectorsPage/InspectorList/InspectorCard';
import classes from 'src/components/InspectorsPage/InspectorList/style.module.scss';

const InspectorListComponent = ({ isBusy, cards }) =>
  isBusy ? (
    <div className={classes.spinner}>
      <Spinner />
    </div>
  ) : (
    <CardList cards={cards} Element={InspectorCard} />
  );

InspectorListComponent.propTypes = {
  isBusy: PropTypes.bool.isRequired,
  cards: PropTypes.array,
};

InspectorListComponent.defaultProps = {
  cards: [],
};

export default InspectorListComponent;

import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import SchemaForm from 'react-jsonschema-form';

import PropTypes from 'prop-types';

import apiRequest from 'src/apiRequest';
import classes from 'src/components/ExtraActions/ChecklistForm/style.module.scss';
import { Services } from 'src/orderForm/fields';
import uiSchema from 'src/orderForm/uiSchema/uiSchemaExtraAction.json';
import TypeGuiada from 'src/svg-icons/type-guiada';

class ChecklistForm extends Component {
  constructor(props) {
    super(props);

    const { intl } = this.props;

    this.state = {
      schema: {
        type: 'object',
        properties: {
          inspector: {
            title: intl.formatMessage({ id: 'RESPONSIBLE_FOR_INSPECTION' }),
            type: 'object',
            required: ['name', 'email'],
            properties: {
              name: {
                title: intl.formatMessage({ id: 'NAME' }),
                type: 'string',
              },
              email: {
                title: intl.formatMessage({ id: 'EMAIL' }),
                type: 'string',
              },
            },
          },
          services: {
            title: intl.formatMessage({ id: 'SERVICES' }),
            items: {
              type: 'object',
              additionalProperties: false,
              properties: {
                type: {
                  title: intl.formatMessage({ id: 'TYPE_OF_SERVICE' }),
                  type: 'string',
                },
                responsibleEmail: {
                  title: intl.formatMessage({ id: 'RESPONSIBLE_EMAIL' }),
                  type: 'string',
                },
              },
            },
            type: 'array',
          },
        },
      },
    };
  }

  onSubmit = async ({ formData }) => {
    const { changePage, orderId } = this.props;
    const url = `pwa/${orderId}`;
    const payload = {
      type: 'PWA',
      inspector_name: formData.inspector.name,
      inspector_mail: formData.inspector.email,
      available_services: formData.services.filter(service => service.type !== '' && service.responsibleEmail !== ''),
    };
    try {
      await apiRequest.post(url, payload);
      changePage(null, 'order-details');
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { clientPanel, intl } = this.props;
    const { schema } = this.state;

    return (
      <div className={classes.form} style={clientPanel ? { marginTop: '-15px' } : {}}>
        <h1>
          <TypeGuiada />
          {intl.formatMessage({ id: 'GUIDED_INSPECTION' })}
        </h1>
        <SchemaForm schema={schema} uiSchema={uiSchema} fields={{ servicesField: Services }} onSubmit={this.onSubmit}>
          <div className={`${classes.field} ${classes.isRightAligned}`}>
            <button type="submit">{intl.formatMessage({ id: 'REQUEST' })}</button>
          </div>
        </SchemaForm>
      </div>
    );
  }
}

ChecklistForm.propTypes = {
  clientPanel: PropTypes.bool.isRequired,
  changePage: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  orderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default injectIntl(ChecklistForm);

/**
 * @prettier
 **/
import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let ExpandLessIcon = props => (
  <SvgIcon {...props}>
    <polygon fill="#393939" points="5.5,13.1 16.5,13.1 11,7.1 	" />
    <path
      fill="#393939"
      d="M11,0.5C5.2,0.5,0.5,5.2,0.5,11S5.2,21.5,11,21.5S21.5,16.8,21.5,11S16.8,0.5,11,0.5z M11,19.5
		c-4.7,0-8.5-3.8-8.5-8.5S6.3,2.5,11,2.5s8.5,3.8,8.5,8.5S15.7,19.5,11,19.5z"
    />
  </SvgIcon>
);

ExpandLessIcon = pure(ExpandLessIcon);
ExpandLessIcon.displaName = 'ExpandLessIcon';
ExpandLessIcon.muiName = 'SvgIcon';

export default ExpandLessIcon;

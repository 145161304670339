import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

import ApiContext from 'src/contexts/ApiContext';
import classes from 'src/orderForm/OrderForm/OrderFormPage/OrderFormPageFooter.module.css';

const OrderFormPageFooter = ({
  children,
  handleFirstButton,
  handleSecondButton,
  textFirstButton,
  textSecondButton,
}) => {
  const { apiStatus: clientOrderFormApiStatus } = useSelector(state => state.clientOrderForm);

  return (
    <ApiContext.Consumer>
      {({ status }) => (
        <div className={classes.sticky}>
          <hr className={classes.hr} />

          <div className={classes.notification}>{children}</div>

          <span style={{ position: 'relative' }}>
            <Button
              variant="outlined"
              className={[classes.Button, classes.secondary].join(' ')}
              size="small"
              onClick={handleFirstButton}
              disabled={status === 'WORKING' || clientOrderFormApiStatus === 'WORKING'}
              data-cy={String(textFirstButton).toLowerCase()}
            >
              <FormattedMessage id={textFirstButton} />
            </Button>
          </span>

          <span style={{ position: 'relative' }}>
            <Button
              variant="outlined"
              size="small"
              className={[classes.Button, classes.primary].join(' ')}
              onClick={handleSecondButton}
              disabled={status === 'WORKING' || clientOrderFormApiStatus === 'WORKING'}
              data-cy={String(textSecondButton).toLowerCase()}
            >
              <FormattedMessage id={textSecondButton} />
            </Button>

            {(status === 'WORKING' || clientOrderFormApiStatus === 'WORKING') && (
              <CircularProgress size={20} className={classes.Spinner} />
            )}
          </span>
        </div>
      )}
    </ApiContext.Consumer>
  );
};

OrderFormPageFooter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  handleFirstButton: PropTypes.func.isRequired,
  handleSecondButton: PropTypes.func.isRequired,
  textFirstButton: PropTypes.string.isRequired,
  textSecondButton: PropTypes.string.isRequired,
};

OrderFormPageFooter.defaultProps = {
  children: null,
};

export default OrderFormPageFooter;

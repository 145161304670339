import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let StepPartiesInvolved = props => {
  if (props.on) {
    return (
      <SvgIcon {...props}>
        <g>
          <path
            fill="#E7A123"
            d="M43,39c4.41,0,7.999-3.589,7.999-8C50.999,26.588,47.41,23,43,23c-4.411,0-8,3.589-8,8.001
                    C35,35.412,38.589,39,43,39z"
          />
          <path
            fill="#E7A123"
            d="M43,48c-7.108,0-18,3.584-18,8v3.001h36V56C61,51.584,50.108,48,43,48z"
          />
          <path
            fill="#E7A123"
            d="M43,0C19.253,0,0,19.252,0,43s19.253,43,43,43c23.749,0,43-19.252,43-43S66.749,0,43,0z M43,21
                    c5.524,0,10,4.474,10,10C53,36.525,48.524,41,43,41c-5.525,0-10-4.474-10-9.999C33,25.475,37.475,21,43,21z M63,61H23v-5
                    c0-6.65,13.325-10.001,20-10.001c6.675,0,20,3.351,20,10.001V61z"
          />
        </g>
      </SvgIcon>
    );
  } else {
    return (
      <SvgIcon {...props}>
        <g>
          <path
            fill="#D7D7D7"
            d="M43,3c22.056,0,40,17.944,40,40c0,22.056-17.944,40-40,40C20.944,83,3,65.056,3,43C3,20.944,20.944,3,43,3
                    M43,0C19.253,0,0,19.252,0,43s19.253,43,43,43c23.749,0,43-19.252,43-43S66.749,0,43,0L43,0z"
          />
        </g>
        <g>
          <path
            fill="#D7D7D7"
            d="M43,45.999c-6.675,0-20,3.351-20,10.001v5h40v-5C63,49.35,49.675,45.999,43,45.999z M61,59.001H25V56
                    c0-4.416,10.891-8,18-8s18,3.584,18,8V59.001z"
          />
          <path
            fill="#D7D7D7"
            d="M43,41c5.524,0,10-4.474,10-9.999c0-5.526-4.476-10-10-10c-5.525,0-10,4.474-10,10
                    C33,36.525,37.475,41,43,41z M43,23c4.41,0,7.999,3.589,7.999,8.001c0,4.411-3.589,8-7.999,8c-4.411,0-8-3.589-8-8
                    C35,26.588,38.589,23,43,23z"
          />
        </g>
      </SvgIcon>
    );
  }
};

StepPartiesInvolved = pure(StepPartiesInvolved);
StepPartiesInvolved.displayName = 'StepPartiesInvolved';
StepPartiesInvolved.muiName = 'SvgIcon';

export default StepPartiesInvolved;

import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import AddCroqui from 'src/components/AddCroqui';
import AddMediaModal from 'src/components/AddMediaModal';
import AddRecommendationCode from 'src/components/AddRecommendationCode';
import AdvancedOptionsComponent from 'src/components/AdvancedOptions/AdvancedOptionsComponent';
import Modal from 'src/components/common/Modal';
import ForwardModal from 'src/components/ForwardModal';
import MakeEditable from 'src/components/MakeEditable';
import Reactivation from 'src/components/Reactivation';
import RemoveVNR from 'src/components/RemoveVNR';
import UpdateAllowedAtDate from 'src/components/UpdateAllowedAtDate';
import { useUser } from 'src/contexts/UserContext';

const components = {
  makeEditable: {
    component: MakeEditable,
    feature: 'MAKE_ORDER_EDITABLE',
    role: 'ROLE_FRANCHISEE',
  },
  addRecommendationCode: {
    component: AddRecommendationCode,
  },
  updateAllowedAtDate: {
    component: UpdateAllowedAtDate,
  },
  removeVNR: {
    component: RemoveVNR,
    role: 'ROLE_FRANCHISEE',
  },
  reactivation: {
    component: Reactivation,
    role: 'ROLE_FRANCHISEE',
  },
  addCroqui: {
    component: AddCroqui,
    role: 'ROLE_FRANCHISEE',
  },
  addMedia: {
    component: AddMediaModal,
    role: 'ROLE_FRANCHISEE',
  },
  forward: {
    component: ForwardModal,
    role: 'ROLE_FRANCHISEE',
    extraStyles: {
      overflow: 'visible',
    },
  },
};

const AdvancedOptionsContainer = ({
  orderFranchisee,
  orderId,
  orderStatus,
  orderType,
  refreshHandler,
  updateOrderItem,
}) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [advancedActions, setAdvancedActions] = useState([]);
  const [currentAction, setCurrentAction] = useState(null);
  const role = JSON.parse(window.sessionStorage.getItem('me'))?.authRole;
  const menuRef = useRef();
  const { franchiseeId: userFranchisee } = useUser();

  const toggleMenu = () => setIsMenuVisible(!isMenuVisible);

  const handleClose = () => setCurrentAction(null);

  const selectOption = action => {
    setCurrentAction(action);
    setIsMenuVisible(false);
  };

  const renderAction = () => {
    const Component = components[currentAction]?.component;

    if (!Component) return null;

    const extraStyles = components[currentAction]?.extraStyles || null;

    return (
      <Modal onClose={handleClose} extraStyles={extraStyles}>
        <Component
          orderId={orderId}
          closeModal={handleClose}
          refreshHandler={refreshHandler}
          updateOrderItem={updateOrderItem}
        />
      </Modal>
    );
  };

  // get advanced options from session storage
  useEffect(() => {
    const orderTypeConfigurations = JSON.parse(sessionStorage.getItem('orderTypeConfigurations') || null);
    const advancedActionsFromSession = orderTypeConfigurations?.[orderType]?.[orderStatus]?.advancedActions;

    if (userFranchisee !== orderFranchisee) {
      setAdvancedActions(() => {
        return advancedActionsFromSession?.filter(action => action === 'forward') || [];
      });

      return;
    }

    setAdvancedActions(advancedActionsFromSession || []);
  }, [orderFranchisee, orderStatus, orderType, userFranchisee]);

  // click away events
  useEffect(() => {
    const closeOnClickAway = evt => {
      if (menuRef?.current?.contains(evt.target)) return;
      setIsMenuVisible(false);
    };

    const closeOnEsc = evt => {
      if (evt.key !== 'Escape') return;
      setIsMenuVisible(false);
    };

    document.addEventListener('mouseup', closeOnClickAway);
    document.addEventListener('keyup', closeOnEsc);

    return () => {
      document.removeEventListener('mouseup', closeOnClickAway);
      document.removeEventListener('keyup', closeOnEsc);
    };
  }, [menuRef]);

  return (
    <AdvancedOptionsComponent
      menuRef={menuRef}
      advancedActions={advancedActions}
      isMenuVisible={isMenuVisible}
      toggleMenu={toggleMenu}
      selectOption={selectOption}
      CurrentAction={renderAction}
      components={components}
      role={role}
    />
  );
};

AdvancedOptionsContainer.propTypes = {
  orderFranchisee: PropTypes.number.isRequired,
  orderId: PropTypes.string.isRequired,
  orderType: PropTypes.string.isRequired,
  orderStatus: PropTypes.string.isRequired,
  refreshHandler: PropTypes.func.isRequired,
  updateOrderItem: PropTypes.func,
};

AdvancedOptionsContainer.defaultProps = {
  updateOrderItem: undefined,
};

export default AdvancedOptionsContainer;

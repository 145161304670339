import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const MenuSettings = props => (
  <SvgIcon {...props} viewBox="0 0 458.317 458.317">
    <g id="Layer_1" data-name="Layer 1">
      <path
        fill="#ffffff"
        d="M337.92,204.1H305.46a79.37,79.37,0,0,0-4.64-11.17l23-23a6.07,6.07,0,0,0,0-8.6l-26.84-26.84a6.08,6.08,0,0,0-8.59,0l-23,23a81.29,81.29,0,0,0-11.17-4.64V120.4a6.07,6.07,0,0,0-6.07-6.08h-38a6.08,6.08,0,0,0-6.08,6.08v32.45a80.57,80.57,0,0,0-11.17,4.64l-23-23a6.09,6.09,0,0,0-8.6,0l-26.84,26.84a6.09,6.09,0,0,0,0,8.6l23,23a80.57,80.57,0,0,0-4.64,11.17H120.4a6.08,6.08,0,0,0-6.08,6.08v38a6.08,6.08,0,0,0,6.08,6.08h32.45a80.57,80.57,0,0,0,4.64,11.17l-23,23a6.08,6.08,0,0,0,0,8.59l26.84,26.84a6.07,6.07,0,0,0,8.6,0l23-23a79.37,79.37,0,0,0,11.17,4.64v32.46a6.08,6.08,0,0,0,6.08,6.08h38a6.08,6.08,0,0,0,6.08-6.08V305.46a79.37,79.37,0,0,0,11.17-4.64l23,23a6.06,6.06,0,0,0,8.59,0l26.84-26.84a6.06,6.06,0,0,0,0-8.59l-23-23a79.37,79.37,0,0,0,4.64-11.17h32.46a6.08,6.08,0,0,0,6.08-6.08v-38A6.08,6.08,0,0,0,337.92,204.1ZM229.16,259.42a30.26,30.26,0,1,1,30.26-30.26A30.26,30.26,0,0,1,229.16,259.42Z"
        transform="translate(1 1)"
      />
    </g>
    <g id="Layer_2" data-name="Layer 2">
      <circle
        style={{ fill: 'none', stroke: '#5578a0', strokeWidth: '3%' }}
        cx="230.16"
        cy="230.16"
        r="220"
      />
    </g>
  </SvgIcon>
);

MenuSettings.displayName = 'MenuSettings';
MenuSettings.muiName = 'SvgIcon';

export default MenuSettings;

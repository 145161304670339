import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let Details = props => {
  if (props.on) {
    return (
      <SvgIcon {...props}>
        <g>
          <polygon
            fill="#B15688"
            points="22.23,40.294 24.8,40.294 26.8,40.294 26.8,42.293 26.8,61 35.8,61 35.8,47.471 35.8,45.47 
                    37.799,45.47 48.201,45.47 50.2,45.47 50.2,47.471 50.2,61 59.199,61 59.199,42.293 59.199,40.294 61.2,40.294 63.77,40.294 
                    43,21.685 	"
          />
          <path
            fill="#B15688"
            d="M43,0C19.252,0,0,19.252,0,43s19.252,43,43,43s43-19.252,43-43S66.748,0,43,0z M61.2,42.293V63H48.201
                    V47.471H37.799V63h-13V42.293H17L43,19l26.001,23.293H61.2z"
          />
        </g>
      </SvgIcon>
    );
  } else {
    return (
      <SvgIcon {...props}>
        <g>
          <path
            fill="#D7D7D7"
            d="M43,3c22.056,0,40,17.944,40,40c0,22.056-17.944,40-40,40C20.944,83,3,65.056,3,43C3,20.944,20.944,3,43,3
                    M43,0C19.252,0,0,19.252,0,43s19.252,43,43,43s43-19.252,43-43S66.748,0,43,0L43,0z"
          />
        </g>
        <path
          fill="#D7D7D7"
          d="M43,19L17,42.293h7.8V63h13V47.471h10.402V63H61.2V42.293h7.801L43,19z M59.199,40.294v1.999V61H50.2V47.471
                V45.47h-1.999H37.799H35.8v2.001V61h-9V42.293v-1.999h-2h-2.57L43,21.685l20.77,18.609H61.2H59.199z"
        />
      </SvgIcon>
    );
  }
};

Details = pure(Details);
Details.displayName = 'Details';
Details.muiName = 'SvgIcon';

export default Details;

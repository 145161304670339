import React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = {
  header: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fontSize: 'calc(10px + 0.4vw)',
    marginTop: '0.6vw',
    marginBottom: '0.6vw',
  },
  date: {
    fontFamily: 'Open Sans',
    textAlign: 'right',
    fontSize: 'calc(8px + 0.3vw)',
  },
  container: {
    alignItems: 'center',
  },
};

function OrderDetailsHeader(props) {
  const date = new Date(props.data.created_at.date);
  return (
    <Grid container classes={{ container: props.classes.container }}>
      <Grid item xs={8}>
        <div style={styles.header}>
          <span>{props.inspectionIcon}</span>
          <span data-cy="order-details-code">{props.data.code}</span>
          &#160; &#9679; &#160;
          <span data-cy="order-details-client">{props.data.form.clients ? props.data.form.clients.client : null}</span>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div style={styles.date} data-cy="order-details-date">
          <FormattedDate value={date} />
          &#160; &#9679; &#160;
          <FormattedTime value={date} />
        </div>
      </Grid>
    </Grid>
  );
}

OrderDetailsHeader.propTypes = {
  /**
   * Object that contains all the inspection data
   */
  data: PropTypes.object,
  /**
   * Icon for the current inspection
   */
  inspectionIcon: PropTypes.object,
};

export default withStyles(styles)(OrderDetailsHeader);

import React from 'react';

import PropTypes from 'prop-types';

import classes from 'src/components/SchedulePage/Timeline/CurrentTime/style.module.scss';

const CurrentTimeComponent = ({ currentTimePosition }) => (
  <div className={classes.currentTime} style={{ transform: `translate3d(${currentTimePosition}px, 0, 0)` }} />
);

CurrentTimeComponent.propTypes = {
  currentTimePosition: PropTypes.number.isRequired,
};

export default CurrentTimeComponent;

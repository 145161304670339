import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import Select from 'react-select/creatable';

import PropTypes from 'prop-types';

import classes from 'src/components/Tags/TagField/style.module.scss';

const animatedComponents = makeAnimated();

const TagField = ({ allTags, autoFocus, handleChange, ...props }) => {
  const intl = useIntl();

  return (
    <Select
      className={classes.select}
      classNamePrefix="select"
      components={{
        ...animatedComponents,
        Input: inputProps => (
          <components.Input {...inputProps} data-cy={`${String(props?.inputId || '').toLowerCase()}`} />
        ),
      }}
      options={allTags}
      isMulti
      autoFocus={autoFocus}
      placeholder={intl.formatMessage({ id: 'SELECT_A_BOOKMARK' })}
      noOptionsMessage={() => intl.formatMessage({ id: 'NO_BOOKMARK_FOUND_MESSAGE' })}
      formatCreateLabel={value => `${intl.formatMessage({ id: 'ADD' })} "${value}"`}
      onChange={handleChange}
      {...props}
    />
  );
};

TagField.propTypes = {
  allTags: PropTypes.array.isRequired,
  autoFocus: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  inputId: PropTypes.string,
};

TagField.defaultProps = {
  autoFocus: false,
};

const mapStateToProps = state => ({
  allTags: state.tags,
});

export default connect(mapStateToProps)(TagField);

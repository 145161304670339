import React, { forwardRef, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isWithinInterval } from 'date-fns';
import moment from 'moment';
import PropTypes from 'prop-types';

import { setDateTimeFromTimeString } from 'src/components/SchedulePage/helpers/setDateTimeFromTimeString';
import OrderCardComponent from 'src/components/SchedulePage/OrderCard/OrderCardComponent';
import validateAllowedAtDateFormat from 'src/components/SchedulePage/OrderCard/validateAllowedAtDateFormat';
import { useUser } from 'src/contexts/UserContext';
import {
  setSelectedOrder,
  notifyFilteredInspectors,
  clearFilteredInspectorsNotifications,
} from 'src/store/ducks/schedule';

const localKeysTypes = ['No local', 'Local'];
const realEstateKeysTypes = ['Na imobiliária', 'Imobiliária'];

const OrderCardContainer = ({ startOffset, endOffset, inspector, order: { order, schedule }, ...props }, ref) => {
  const dispatch = useDispatch();
  const {
    date: scheduleDate,
    dateAsDateObject: scheduleDateAsDateObject,
    ordersThatAreBeingEdited,
    todayUnavailabilitiesGroupedByInspector,
  } = useSelector(({ schedule: scheduleState }) => ({
    date: scheduleState.date,
    dateAsDateObject: scheduleState.dateAsDateObject,
    ordersThatAreBeingEdited: scheduleState?.ordersThatAreBeingEdited,
    todayUnavailabilitiesGroupedByInspector: scheduleState.todayUnavailabilitiesGroupedByInspector,
  }));

  const me = useUser();

  const isAlreadyBeingEditedByAnotherUser = useMemo(() => {
    return ordersThatAreBeingEdited.some(orderThatAreBeingEdited => {
      const publicIdIsEqual = orderThatAreBeingEdited?.order?.order?.public_id === order?.public_id;
      const idIsEqual = orderThatAreBeingEdited?.order?.order?.public_id === order?.id;

      const isAnotherUserEditing = orderThatAreBeingEdited?.sender?.id !== me?.uniqueId;

      return (publicIdIsEqual && isAnotherUserEditing) || (idIsEqual && isAnotherUserEditing);
    });
  }, [me, order, ordersThatAreBeingEdited]);

  const insideTimelineStyle = useMemo(() => {
    return (
      startOffset &&
      endOffset && {
        position: 'absolute',
        top: '50%',
        left: startOffset || 0,
        width: endOffset - startOffset + 1,
        transform: 'translate(-15px, -50%)',
        opacity: inspector?.wasNotFiltered ? 0.5 : 1,
      }
    );
  }, [endOffset, inspector, startOffset]);

  const boundSetSelectedOrder = orderId => dispatch(setSelectedOrder(orderId));

  const handleDragStart = (data, evt) => {
    const { currentTarget: target } = evt;
    const rect = target.getBoundingClientRect();
    const offsetX = evt.clientX - rect.x;

    const { order: draggedOrder, schedule: draggedSchedule } = data;

    dispatch(
      notifyFilteredInspectors({
        address_id: draggedOrder?.address_id,
        order_type_id: draggedOrder?.order_type_id,
      })
    );

    target.style.opacity = 0.4;
    evt.dataTransfer.dropEffect = 'move';
    evt.dataTransfer.effectAllowed = 'move';
    evt.dataTransfer.setData(
      'application/json',
      JSON.stringify({
        order: {
          id: draggedOrder?.id || draggedOrder?.public_id,
          client: draggedOrder?.client,
          code: draggedOrder?.code,
          identifier: draggedOrder?.identifier,
          type: draggedOrder?.type,
          credits: draggedOrder?.credits,
          address_id: draggedOrder?.address_id,
          order_type_id: draggedOrder?.order_type_id,
          building_type: draggedOrder?.building_type,
          allowed_at_date: draggedOrder?.allowed_at_date || null,
          accompanied_inspection: draggedOrder?.accompanied_inspection,
          furnished: draggedOrder?.furnished,
          modality: draggedOrder?.modality,
          urgency: draggedOrder?.urgency,
          created_at: draggedOrder?.created_at,
          access_information: draggedOrder?.access_information,
          details: draggedOrder?.details,
          search_tokens: draggedOrder?.search_tokens,
          status: draggedOrder?.status,
          city: draggedOrder?.city,
          neighborhood: draggedOrder?.neighborhood,
          street: draggedOrder?.street,
          number: draggedOrder?.number,
          complement: draggedOrder?.complement,
          state: draggedOrder?.state,
          zipcode: draggedOrder?.zipcode,
        },
        schedule: draggedSchedule,
        offsetX,
      })
    );
  };

  const handleDragEnd = evt => {
    const { currentTarget: target } = evt;
    target.style.opacity = 1;
  };

  const onDragEndCapture = useCallback(() => {
    dispatch(clearFilteredInspectorsNotifications());
  }, [dispatch]);

  const handleGetLockIconState = useCallback(() => {
    let lockIconState = false;
    let formattedAllowedAtDate = '';

    if (order?.allowed_at_date === null) {
      lockIconState = null;
    } else {
      const validatedDate = validateAllowedAtDateFormat(String(order?.allowed_at_date).trim());

      if (validatedDate) {
        lockIconState = scheduleDate?.diff(validatedDate, 'days') >= 1;

        formattedAllowedAtDate = moment(validatedDate).format('DD/MM/YYYY');
      }
    }

    return {
      lockIconState,
      formattedAllowedAtDate,
    };
  }, [order?.allowed_at_date, scheduleDate]);

  const handleGetKeyIconState = useCallback(() => {
    if (localKeysTypes.includes(order?.access_information?.type)) return 'ACCESS_INFORMATION_LOCAL_KEYS_TYPE';

    if (localKeysTypes.includes(order?.property_access?.type)) return 'PROPERTY_ACCESS_LOCAL_KEYS_TYPE';

    if (realEstateKeysTypes.includes(order?.access_information?.type))
      return 'ACCESS_INFORMATION_REAL_ESTATE_KEYS_TYPE';

    if (realEstateKeysTypes.includes(order?.property_access?.type)) return 'PROPERTY_ACCESS_REAL_ESTATE_KEYS_TYPE';

    if (order?.access_information?.type === 'Outro') return 'ACCESS_INFORMATION_OTHER_KEYS_TYPE';

    if (order?.property_access?.type === 'Outro') return 'PROPERTY_ACCESS_OTHER_KEYS_TYPE';

    if (order?.access_information?.keysLocation) return 'ACCESS_INFORMATION_KEYS_LOCATION_KEYS_TYPE';

    if (order?.property_access?.keysLocation) return 'PROPERTY_ACCESS_DETAILS_KEYS_TYPE';

    return false;
  }, [
    order?.access_information?.keysLocation,
    order?.access_information?.type,
    order?.property_access?.keysLocation,
    order?.property_access?.type,
  ]);

  const { formattedAllowedAtDate, lockIconState } = handleGetLockIconState();
  const keyIconState = handleGetKeyIconState();

  const formattedCreatedAtDate = moment(order?.created_at || '').format('DD/MM/YYYY');

  const orderAddressParts = [
    order?.zipcode && `${order.zipcode}, `,
    order?.street && `${order.street}, `,
    order?.number && `${order.number}, `,
    order?.complement && `${order.complement}, `,
    order?.neighborhood && `${order.neighborhood}, `,
    order?.city && `${order.city} - `,
    order?.state,
  ];

  const formattedOrderAddress = orderAddressParts.filter(Boolean).join('');

  const isUnavailable = useMemo(() => {
    if (todayUnavailabilitiesGroupedByInspector.has(inspector?.public_id)) {
      const inspectorTodayUnvailabilities = todayUnavailabilitiesGroupedByInspector.get(inspector?.public_id);

      const scheduleStartDate = setDateTimeFromTimeString(scheduleDateAsDateObject, schedule?.when?.start_time);
      const scheduleEndDate = setDateTimeFromTimeString(scheduleDateAsDateObject, schedule?.when?.end_time);

      return inspectorTodayUnvailabilities.some(unavailability => {
        const interval = { start: unavailability.start, end: unavailability.end };

        return isWithinInterval(scheduleStartDate, interval) || isWithinInterval(scheduleEndDate, interval);
      });
    }

    return false;
  }, [inspector, scheduleDateAsDateObject, schedule, todayUnavailabilitiesGroupedByInspector]);

  return (
    <OrderCardComponent
      order={order}
      schedule={schedule}
      currentScheduleUser={me?.uniqueId}
      setSelectedOrder={boundSetSelectedOrder}
      insideTimelineStyle={insideTimelineStyle}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragEndCapture={onDragEndCapture}
      isAlreadyBeingEditedByAnotherUser={isAlreadyBeingEditedByAnotherUser}
      isUnavailable={isUnavailable}
      lockIconState={lockIconState}
      keyIconState={keyIconState}
      formattedAllowedAtDate={formattedAllowedAtDate}
      formattedCreatedAtDate={formattedCreatedAtDate}
      formattedOrderAddress={formattedOrderAddress}
      forwardedRef={ref}
      {...props}
    />
  );
};

OrderCardContainer.propTypes = {
  startOffset: PropTypes.number,
  endOffset: PropTypes.number,
  inspector: PropTypes.object,
  order: PropTypes.object.isRequired,
  schedule: PropTypes.object.isRequired,
  inTimeline: PropTypes.bool,
};

OrderCardContainer.defaultProps = {
  startOffset: null,
  endOffset: null,
  inspector: null,
  inTimeline: false,
};

export default forwardRef(OrderCardContainer);

import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

const ArrayFieldTemplate = ({ items, onAddClick, ...props }) => {
  useEffect(() => {
    if (items.length > 0) return;
    onAddClick();
  }, [onAddClick, items]);

  return (
    <div className="array-field-template">
      {items.map((element, index) => (
        <div className="array-item" key={index}>
          {element.children}
        </div>
      ))}
    </div>
  );
};

ArrayFieldTemplate.propTypes = {
  items: PropTypes.array.isRequired,
  onAddClick: PropTypes.func.isRequired,
};

export default ArrayFieldTemplate;

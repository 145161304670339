import React from 'react';
import { FormattedMessage } from 'react-intl';

import PropTypes from 'prop-types';

import Radio from 'src/components/common/elements/Radio';
import classes from 'src/orderForm/fields/KeysLocationField/style.module.scss';

const KeysLocationField = ({ formData, name, onChange, schema }) => (
  <div className={classes.keysLocationField}>
    <div className={classes.field}>
      <div className={classes.title}>
        <FormattedMessage id="keysLocationRadio" />
      </div>

      <div className={classes.optionList}>
        {schema?.enum?.map(option => (
          <div className={classes.option} key={`${name}-${option}`}>
            <label>
              <Radio
                id={`${name}-${option}`}
                data-cy={`${name}-${option}`}
                className={classes.radio}
                name={name}
                value={option}
                checked={formData === option}
                onChange={event => onChange(event.target.value)}
              />

              <FormattedMessage id={option} />
            </label>
          </div>
        ))}
      </div>
    </div>
  </div>
);

KeysLocationField.propTypes = {
  formData: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  schema: PropTypes.object.isRequired,
};

KeysLocationField.defaultProps = {
  formData: null,
};

export default KeysLocationField;

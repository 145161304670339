import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import axios from 'axios';
import PropTypes from 'prop-types';

import api from 'src/apiRequest';
import AddCroquiComponent from 'src/components/AddCroqui/AddCroquiComponent';
import { showToast } from 'src/store/ducks/toasts';

let extension;
const AddCroquiContainer = ({ orderId, closeModal, refreshHandler, updateOrderItem }) => {
  const [uploadDone, setUploadDone] = useState(false);
  const intl = useIntl();
  const dispatch = useDispatch();

  const getUploadUri = async extension => {
    const { data } = await api.get(`/orders/${orderId}/croqui-upload-uri?file_extension=${extension}`);
    return data.uri;
  };

  const setCroquiSent = async () => {
    try {
      await api.put(`/orders/${orderId}/croqui-sent`, {
        filename: `croqui.${extension}`,
      });
      dispatch(
        showToast({
          type: 'success',
          title: 'Sucesso!',
          text: 'O croqui foi adicionado ao pedido com sucesso.',
          duration: 5000,
        })
      );
      closeModal();
    } catch (err) {
      dispatch(
        showToast({
          type: 'error',
          title: 'Erro!',
          text: 'Ocorreu um erro enquanto o croqui era adicionado ao pedido. Tente novamente.',
          duration: 5000,
        })
      );
    }
  };

  const handleChange = async image => {
    try {
      extension = image.name.indexOf('.' > 0) ? image.name.split('.').pop() : '';
      const uri = await getUploadUri(extension); // get signed uri
      const form = new FormData();
      form.set('file', image);
      await axios.put(uri, image, {
        headers: {
          'Content-Type': image.type,
        },
      }); // do s3 upload
      setUploadDone(true);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleCancel = () => {
    closeModal();
  };

  const handleSubmit = async () => {
    if (uploadDone) {
      await setCroquiSent();
    }
  };

  return (
    <AddCroquiComponent
      handleChange={handleChange}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      intl={intl}
    />
  );
};

AddCroquiContainer.propTypes = {
  orderId: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default AddCroquiContainer;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const Inspector = props => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      fill="#ffffff"
      d="M57.287,145.582h-0.9l-0.32-.308a7.448,7.448,0,1,0-.8.8l0.309,0.32v0.9l5.713,5.7,1.7-1.7Zm-6.856,
        0a5.142,5.142,0,1,1,5.142-5.142A5.135,5.135,0,0,1,50.431,145.582Z"
      transform="translate(-43 -133)"
    />
  </SvgIcon>
);

Inspector.displayName = 'Inspector';
Inspector.muiName = 'SvgIcon';

export default Inspector;

import React from 'react';
import { NavLink } from 'react-router-dom';

import PropTypes from 'prop-types';

import classes from 'src/components/common/Card/style.module.scss';

const Card = ({ className, id, children, url }) =>
  url ? (
    <NavLink to={url} className={classes.link} activeClassName={classes.isSelected}>
      <div id={id} className={`${classes.card} ${classes[className] || className}`}>
        {children}
      </div>
    </NavLink>
  ) : (
    <div id={id} className={`${classes.card} ${classes[className] || className}`}>
      {children}
    </div>
  );

Card.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  url: PropTypes.string,
};

Card.defaultProps = {
  id: null,
  className: null,
  url: null,
};

export default Card;

import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import Pdf from '@material-ui/icons/PictureAsPdf';
import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import Modal from 'src/components/common/Modal';
import ReportOptions from 'src/components/ReportOptions/ReportOptions';
import classes from 'src/orderDetails/Inspections.module.scss';
import ModalConfirmChanges from 'src/orderDetails/ModalConfirmChanges';
import { showToast } from 'src/store/ducks/toasts';
import InspectionIcon from 'src/svg-icons/inspections';

class Inspections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      askForceItem: null,
      loading: false,
      openReportList: false,
      anchorEl: null,
      selectedInspection: null,
      currentData: null,
      updatedData: null,
      originalUpdatedData: null,
    };
    this.orderTypeConfigurations = JSON.parse(window.sessionStorage.getItem('orderTypeConfigurations'));
  }

  loadOrderTypeConfigurations = () => {
    this.orderTypeConfigurations = JSON.parse(window.sessionStorage.getItem('orderTypeConfigurations'));
  };

  makeOfficialHandler = (id, forceConfirmed, rejectedData = false) => {
    this.setState({ loading: true });
    const { orderId, refreshHandler } = this.props;
    const { updatedData } = this.state;
    const force = forceConfirmed === true;
    let data = {
      inspection_id: id,
      force,
    };
    if (rejectedData) {
      data.updated_data = updatedData;
    }
    API.post(`orders/${orderId}/official`, data)
      .then(() => {
        this.setState({
          askForceItem: null,
          loading: false,
          showModal: false,
          currentData: null,
          updatedData: null,
          originalUpdatedData: null,
        });
        refreshHandler();
      })
      .catch(error => {
        if (error.response && error.response.status === 409) {
          this.setState({
            askForceItem: id,
          });

          if (error?.response?.data?.data_conflict) {
            this.setState({
              currentData: error?.response?.data?.current_data,
              updatedData: error?.response?.data?.updated_data,
              originalUpdatedData: JSON.parse(JSON.stringify(error?.response?.data?.updated_data)),
            });
            if (!error?.response?.data?.official_inspection_conflict) {
              this.setState({
                showModal: true,
              });
            }
          }

          this.setState({
            loading: false,
          });

          return;
        }

        const { intl, showToast } = this.props;

        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'UNKNOWN_ERROR' }),
          duration: 5000,
        });
      });
  };

  unassignHadler = id => {
    this.setState({ loading: true });
    const { refreshHandler, updateOrderItem } = this.props;
    API.delete(`inspections/${id}`)
      .then(res => {
        this.setState({ loading: false });
        updateOrderItem({ ...res.data });
        refreshHandler();
      })
      .catch(error => {
        console.error(error);
        this.setState({ loading: false });
      });
  };

  copyCancelHandler = () => {
    this.setState({
      askForceItem: null,
    });
  };

  handleReportClick = (event, inspection) => {
    const { currentTarget } = event;
    this.setState(state => ({
      anchorEl: currentTarget,
      openReportList: !state.openReportList,
      selectedInspection: inspection,
    }));
  };

  makeReportHandler = (withPictures, type, label) => {
    const { selectedInspection } = this.state;
    const { actionsMenuGeneratePDF } = this.props;
    this.setState({
      selectedInspection: null,
      openReportList: false,
    });
    actionsMenuGeneratePDF(withPictures, type, label, selectedInspection.id);
  };

  handleClose = event => {
    const { anchorEl } = this.state;
    if (event && anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ openReportList: false });
  };

  closeModal = () => {
    this.setState({ showModal: false, askForceItem: null, currentData: null, updatedData: null });
  };

  changeUpdatedData = (key, value) => {
    this.setState(prevState => ({
      updatedData: {
        ...prevState.updatedData,
        details: {
          ...prevState.updatedData.details,
          [key]: value,
        },
      },
    }));
  };

  render() {
    const {
      anchorEl,
      askForceItem,
      loading,
      openReportList,
      selectedInspection,
      showModal,
      currentData,
      updatedData,
      originalUpdatedData,
    } = this.state;
    const { inspections, intl, isFromTheSameFranchisee } = this.props;

    const currentInspection = selectedInspection || {};
    const itemsCount = inspections.length;
    let reportsOptions = [];

    if (this.orderTypeConfigurations === null || Object.keys(this.orderTypeConfigurations).length === 0) {
      this.loadOrderTypeConfigurations();
    }

    if (
      currentInspection &&
      this.orderTypeConfigurations[currentInspection.type] &&
      this.orderTypeConfigurations[currentInspection.type][currentInspection.progress_status]
    ) {
      ({ reportsOptions } = this.orderTypeConfigurations[currentInspection.type][currentInspection.progress_status]);
    }

    let items = [];

    if (isFromTheSameFranchisee) {
      items = inspections
        .filter(inspection => inspection.inspector !== 'Painel')
        .map((inspection, i) => {
          const inspector = inspection.inspector
            ? inspection.inspector
            : intl.formatMessage({ id: 'UNIDENTIFIED_INSPECTOR_MESSAGE' });
          const date = new Date(inspection.last_update);
          const datetime = date ? `${intl.formatDate(date)} ${intl.formatTime(date)}` : null;
          let message = null;

          switch (inspection.progress_status) {
            case 'FINALIZED':
              message = `${intl.formatMessage({ id: 'INSPECTION_CARRIED_OUT_IN' })} ${datetime}`;
              break;
            case 'PENDING':
              message = `${intl.formatMessage({ id: 'INSPECTION_ASSIGNED_OUT_IN' })} ${datetime}`;
              break;
            case 'IN_PROGRESS':
              message = intl.formatMessage({ id: 'INSPECTION_IN_PROGRESS' });
              break;
            default:
              break;
          }

          let text;
          let buttons = [];

          if (inspection.id === askForceItem && !showModal) {
            text = <ListItemText primary={intl.formatMessage({ id: 'ORDER_ALREADY_HAVE_AN_INSPECTION_MESSAGE' })} />;

            buttons.push(
              <Button variant="contained" onClick={() => this.copyCancelHandler()}>
                {intl.formatMessage({ id: 'CANCEL' })}
              </Button>
            );

            buttons.push(
              <Button
                variant="contained"
                disabled={loading}
                onClick={() =>
                  !currentData && !updatedData
                    ? this.makeOfficialHandler(inspection.id, true)
                    : this.setState({ showModal: true })
                }
              >
                {loading ? <CircularProgress size={23} /> : intl.formatMessage({ id: 'REPLACE' })}
              </Button>
            );
          } else {
            text = (
              <ListItemText primary={message} secondary={`${intl.formatMessage({ id: 'EXECUTED_BY' })} ${inspector}`} />
            );

            buttons.push(
              <Button
                key="official"
                variant="contained"
                disabled={loading}
                onClick={() => this.makeOfficialHandler(inspection.id)}
              >
                {loading ? <CircularProgress size={23} /> : intl.formatMessage({ id: 'COPY' })}
              </Button>
            );

            buttons.push(
              <Button
                key="report"
                variant="contained"
                disabled={loading}
                onClick={e => this.handleReportClick(e, inspection)}
              >
                {loading ? (
                  <CircularProgress size={23} />
                ) : (
                  <Pdf style={{ fontSize: '1.2vw', minWidth: '48px', minHeight: '24px' }} />
                )}
              </Button>
            );
          }

          if (inspection.progress_status !== 'FINALIZED') buttons = [];

          buttons.push(
            <Button
              key="remove"
              variant="contained"
              disabled={loading}
              onClick={() => this.unassignHadler(inspection.id)}
            >
              {loading ? (
                <CircularProgress size={23} />
              ) : (
                <DeleteIcon style={{ fontSize: '1.2vw', minWidth: '48px', minHeight: '24px' }} />
              )}
            </Button>
          );

          return (
            <Fragment key={inspection.id}>
              <ListItem className={classes.inspection}>
                <div className={classes.inspectionData}>{text}</div>

                <div className={classes.actions}>{buttons}</div>
              </ListItem>

              {itemsCount !== i + 1 && <Divider />}
            </Fragment>
          );
        });
    }

    if (items.length === 0) return null;

    return (
      <>
        <Grid item xs={12}>
          <div className={classes.Header}>
            <InspectionIcon viewBox="0 0 34.016 34.016" className={classes.Icon} />

            <FormattedMessage id="inspections" />
          </div>

          <ul className={classes.inspectionList} data-cy="order-details-inspections-list">
            {items}
          </ul>
        </Grid>

        <ReportOptions
          show={openReportList}
          closeHandler={this.handleClose}
          anchorEl={anchorEl}
          options={reportsOptions}
          entityId={currentInspection.id}
        />

        {showModal && (
          <Modal onClose={this.closeModal}>
            <ModalConfirmChanges
              onClose={this.closeModal}
              currentData={currentData}
              updatedData={updatedData}
              originalUpdatedData={originalUpdatedData}
              changeUpdatedData={this.changeUpdatedData}
              makeOfficialHandler={this.makeOfficialHandler}
              inspectionId={askForceItem}
              loading={loading}
            />
          </Modal>
        )}
      </>
    );
  }
}

Inspections.propTypes = {
  actionsMenuGeneratePDF: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  refreshHandler: PropTypes.func.isRequired,
  inspections: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  isFromTheSameFranchisee: PropTypes.bool.isRequired,
  updateOrderItem: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  showToast,
};

export default connect(null, mapDispatchToProps)(injectIntl(Inspections));

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let StepPartiesInvolved = props => {
  if (props.on) {
    return (
      <SvgIcon {...props}>
        <g>
          <path
            fill="#5299AA"
            d="M64.523,39.904h-3.285h-2.001v-1.999v-8.763c0-1.312-1.068-2.38-2.38-2.38h-8.763h-1.999v-2v-3.286
                    c0-1.917-1.56-3.477-3.477-3.477s-3.477,1.56-3.477,3.477v3.286v2h-1.999h-8.763c-1.301,0-2.36,1.068-2.36,2.38v6.325h1.265
                    c4.365,0,7.916,3.549,7.916,7.914c0,4.365-3.551,7.914-7.916,7.914H26v6.324c0,1.313,1.067,2.38,2.38,2.38h6.324v-1.284
                    c0-4.365,3.549-7.916,7.914-7.916c4.365,0,7.914,3.551,7.914,7.916v1.284h6.324c1.312,0,2.38-1.066,2.38-2.38v-8.763v-1.999h2.001
                    h3.285c1.917,0,3.477-1.56,3.477-3.477S66.44,39.904,64.523,39.904z"
          />
          <path
            fill="#5299AA"
            d="M43,0C19.252,0,0,19.252,0,43s19.252,43,43,43s43-19.252,43-43S66.748,0,43,0z M64.523,48.856h-3.285v8.763
                    c0,2.408-1.973,4.381-4.381,4.381h-8.323v-3.285c0-3.266-2.65-5.915-5.915-5.915c-3.265,0-5.915,2.649-5.915,5.915V62h-8.323
                    C25.971,62,24,60.027,24,57.619v-8.323h3.286c3.265,0,5.915-2.65,5.915-5.915c0-3.265-2.65-5.915-5.915-5.915h-3.264v-8.324
                    c0-2.41,1.949-4.38,4.359-4.38h8.763v-3.286c0-3.023,2.453-5.476,5.476-5.476c3.023,0,5.476,2.453,5.476,5.476v3.286h8.763
                    c2.408,0,4.381,1.971,4.381,4.38v8.763h3.285c3.023,0,5.476,2.453,5.476,5.476C69.999,46.404,67.547,48.856,64.523,48.856z"
          />
        </g>
      </SvgIcon>
    );
  } else {
    return (
      <SvgIcon {...props}>
        <g>
          <path
            fill="#D7D7D7"
            d="M43,3c22.056,0,40,17.944,40,40c0,22.056-17.944,40-40,40C20.944,83,3,65.056,3,43C3,20.944,20.944,3,43,3
                    M43,0C19.252,0,0,19.252,0,43s19.252,43,43,43s43-19.252,43-43S66.748,0,43,0L43,0z"
          />
        </g>
        <path
          fill="#D7D7D7"
          d="M64.523,37.905h-3.285v-8.763c0-2.41-1.973-4.38-4.381-4.38h-8.763v-3.286c0-3.023-2.452-5.476-5.476-5.476
                c-3.023,0-5.476,2.453-5.476,5.476v3.286h-8.763c-2.41,0-4.359,1.971-4.359,4.38v8.324h3.264c3.265,0,5.915,2.65,5.915,5.915
                c0,3.265-2.65,5.915-5.915,5.915H24v8.323C24,60.027,25.971,62,28.381,62h8.323v-3.285c0-3.266,2.65-5.915,5.915-5.915
                c3.265,0,5.915,2.649,5.915,5.915V62h8.323c2.408,0,4.381-1.973,4.381-4.381v-8.763h3.285c3.023,0,5.476-2.452,5.476-5.476
                C69.999,40.358,67.547,37.905,64.523,37.905z M64.523,46.857h-3.285h-2.001v1.999v8.763c0,1.313-1.068,2.38-2.38,2.38h-6.324v-1.284
                c0-4.365-3.549-7.916-7.914-7.916c-4.365,0-7.914,3.551-7.914,7.916v1.284h-6.324c-1.313,0-2.38-1.066-2.38-2.38v-6.324h1.285
                c4.365,0,7.916-3.549,7.916-7.914c0-4.365-3.551-7.914-7.916-7.914h-1.265v-6.325c0-1.312,1.059-2.38,2.36-2.38h8.763h1.999v-2
                v-3.286c0-1.917,1.56-3.477,3.477-3.477s3.477,1.56,3.477,3.477v3.286v2h1.999h8.763c1.312,0,2.38,1.068,2.38,2.38v8.763v1.999
                h2.001h3.285c1.917,0,3.477,1.56,3.477,3.477S66.44,46.857,64.523,46.857z"
        />
      </SvgIcon>
    );
  }
};

StepPartiesInvolved = pure(StepPartiesInvolved);
StepPartiesInvolved.displayName = 'StepPartiesInvolved';
StepPartiesInvolved.muiName = 'SvgIcon';

export default StepPartiesInvolved;

import React from 'react';
import { useIntl } from 'react-intl';

import PropTypes from 'prop-types';

import TagField from 'src/components/Tags/TagField';

const TagsFieldTemplate = ({ handleChange, schema, values }) => {
  const intl = useIntl();

  return (
    <>
      <label htmlFor="form-tags">{intl.formatMessage({ id: 'ENTER_BOOKMARKS_TO_FILTER_ORDER_MESSAGE' })}</label>
      <TagField
        defaultValue={values ? values.map(item => ({ value: item, label: item })) : []}
        handleChange={value => {
          const tags = value ? [...value.map(item => item.value)] : [];

          handleChange({
            formData: { tags },
            schema,
            errors: [],
          });
        }}
        inputId="form-tags"
      />
    </>
  );
};

TagsFieldTemplate.propTypes = {
  handleChange: PropTypes.func.isRequired,
  schema: PropTypes.object.isRequired,
  values: PropTypes.array,
};

TagsFieldTemplate.defaultProps = {
  values: [],
};

export default TagsFieldTemplate;

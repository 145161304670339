import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Menu, Tooltip } from '@material-ui/core';

import classes from 'src/components/ClientAppsMenu/style.module.scss';
import { APP_REDE_SEGUROS_URL, APP_REDE_IZEE_URL, APP_CLIENT_ACCOUNT_URL } from 'src/config';
import appsIcon from 'src/images/icons/apps-menu.svg';
import grupoRv from 'src/images/icons/grupo-rv.svg';
import redeIzeeIcon from 'src/images/icons/izee-logo.svg';
import redeSegurosIcon from 'src/images/icons/seguros-logo.svg';
import redeVistoriasIconDisabled from 'src/images/icons/vistorias-logo-disabled.svg';

const ClientAppsMenu = () => {
  const [open, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const intl = useIntl();

  const handleMenuToggle = event => {
    setMenuOpen(!open);
    setAnchorEl(event.target);
  };

  const handleRedirectToApp = appUrl => {
    const accessToken = sessionStorage.getItem('accessToken');
    const tokenType = sessionStorage.getItem('tokenType');

    window.open(`${appUrl}#access_token=${accessToken}&token_type=${tokenType}&expires_in=`, '_blank');
  };

  return (
    <nav className={classes.menuWrapper}>
      <Tooltip title={intl.formatMessage({ id: 'RVGROUP_APPS_MENU_MESSAGE' })} placement="bottom">
        <img className={classes.menuButton} src={appsIcon} alt="Apps menu" onClick={handleMenuToggle} />
      </Tooltip>
      <Menu
        classes={{ paper: classes.menu }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuToggle}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          style: {
            padding: 0,
            marginTop: '20px',
          },
        }}
      >
        <div className={classes.arrow} />
        <div className={classes.menuHeader}>
          <span>Soluções Grupo RV</span>
        </div>
        <div className={classes.menuItems}>
          <button className={classes.menuLink} type="button" disabled>
            <img width={130} src={redeVistoriasIconDisabled} alt="Menu link" />
          </button>
          <Tooltip title={intl.formatMessage({ id: 'SEGUROS_APPS_MENU_MESSAGE' })} placement="bottom">
            <button
              className={classes.menuLink}
              type="button"
              onClick={() => handleRedirectToApp(APP_REDE_SEGUROS_URL)}
            >
              <img width={130} src={redeSegurosIcon} alt="Menu link" />
            </button>
          </Tooltip>
          <Tooltip title={intl.formatMessage({ id: 'IZEE_APPS_MENU_MESSAGE' })} placement="bottom">
            <button className={classes.menuLink} type="button" onClick={() => handleRedirectToApp(APP_REDE_IZEE_URL)}>
              <img width={130} src={redeIzeeIcon} alt="Menu link" />
            </button>
          </Tooltip>
          <Tooltip title={intl.formatMessage({ id: 'CLIENT_APPS_MENU_MESSAGE' })} placement="bottom">
            <button
              className={classes.menuLink}
              type="button"
              onClick={() => handleRedirectToApp(APP_CLIENT_ACCOUNT_URL)}
            >
              <img width={130} src={grupoRv} alt="Menu link" />
            </button>
          </Tooltip>
        </div>
      </Menu>
    </nav>
  );
};

export default ClientAppsMenu;

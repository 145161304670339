import React, { useState, useRef } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { useIntl } from 'react-intl';

import ColorButton from 'src/components/common/elements/ColorButton';
import classes from 'src/components/common/ImageCrop/style.module.scss';
import Modal from 'src/components/common/Modal';
import EditIcon from 'src/svg-icons/edit';

import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
import 'react-image-crop/dist/ReactCrop.css';

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: 'px',
        width: 200,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const ImageCrop = ({ onConfirm, maxWidth = 400, maxHeight = 400, aspect = 1 }) => {
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [modalVisible, setIsModalVisible] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();

  const intl = useIntl();

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader.result.toString() || ''));
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, aspect));
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  const confirm = async () => {
    const canvas = previewCanvasRef.current;

    const blob = await new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        resolve(blob);
      }, 'image/png');
    });

    const avatar = new File([blob], 'avatar.png', {
      type: 'image/png',
      lastModified: new Date().getTime(),
    });

    onConfirm(avatar);
    setIsModalVisible(false);
  };

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setImgSrc(null);
    setCrop(undefined);
    setCompletedCrop();
  };

  return (
    <div className={classes.flex}>
      <div className={classes.icon} onClick={openModal}>
        <EditIcon style={{ fontSize: '16px' }} />
      </div>

      {modalVisible && (
        <Modal onClose={closeModal}>
          <div className={classes.preview}>
            {completedCrop && (
              <canvas
                ref={previewCanvasRef}
                style={{
                  marginRight: '10px',
                  border: '1px solid black',
                  objectFit: 'contain',
                  width: 80,
                  height: 80,
                }}
              />
            )}
            <div>
              <input type="file" accept="image/*" onChange={onSelectFile} />
            </div>
          </div>

          {imgSrc && (
            <>
              <div className="mb-2">
                <ReactCrop
                  className={classes.imageWrapper}
                  minWidth={200}
                  minHeight={200}
                  maxWidth={maxWidth}
                  maxHeight={maxHeight}
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={c => setCompletedCrop(c)}
                  aspect={aspect}
                >
                  <img ref={imgRef} alt="" src={imgSrc} onLoad={onImageLoad} />
                </ReactCrop>
              </div>
              <div className="text-center">
                <ColorButton onClick={closeModal}>{intl.formatMessage({ id: 'BUTTON.CANCEL' })}</ColorButton>
                <ColorButton color="green" onClick={confirm}>
                  {intl.formatMessage({ id: 'BUTTON.CONFIRM' })}
                </ColorButton>
              </div>
            </>
          )}
        </Modal>
      )}
    </div>
  );
};

export default ImageCrop;

import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import API from 'src/apiRequest';
import ClientFullDetailsComponent from 'src/components/ClientsPage/ClientFullDetails/ClientFullDetailsComponent';

const ClientFullDetailsContainer = () => {
  const { id } = useParams();
  const [details, setDetails] = useState();
  const [hasBillingAddress, setHasBillingAddress] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const { data } = await API.get(`/clients/${id}`);
        setDetails(data);
        setHasBillingAddress(
          !!(
            data.billing_address &&
            (data.billing_address.zipcode ||
              data.billing_address.street ||
              data.billing_address.number ||
              data.billing_address.complement ||
              data.billing_address.neighborhood ||
              data.billing_address.city ||
              data.billing_address.state)
          )
        );
      } catch (err) {
        console.log(err);
      }
    };

    fetchDetails();
  }, [id]);

  return <ClientFullDetailsComponent details={details} id={id} intl={intl} hasBillingAddress={hasBillingAddress} />;
};

export default ClientFullDetailsContainer;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const SectionRecusadas = props => (
  <SvgIcon viewBox="0 0 22 22" {...props}>
    <path
      fillRule="evenodd"
      fill="#FFFFFF"
      d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Zm0,2a9,9,0,1,1-9,9A9,9,0,0,1,11,2Z"
    />
    <path
      fillRule="evenodd"
      fill="#FFFFFF"
      d="M17.01,4.075L18.425,5.49,4.99,18.925,3.575,17.51Z"
    />
  </SvgIcon>
);

SectionRecusadas.displayName = 'SectionRecusadas';
SectionRecusadas.muiName = 'SvgIcon';

export default SectionRecusadas;

import React from 'react';
// import { useIntl } from 'react-intl';

import classes from 'src/components/NewRvPanelFeatureBar/style.module.scss';
import { FEATURE_BAR_REDIRECT_LINK } from 'src/config';
// import playIcon from 'src/images/icons/play.svg';
// import rvGroupBannerDetail from 'src/images/rvgroup-client-banner-detail.svg';
// import rvGroupBannerLeftDetail from 'src/images/rvgroup-client-banner-left-detail.svg';
// import rvGroupBannerText from 'src/images/rvgroup-client-banner-new-panel-text.svg';
// import rvGroupBannerRightDetail from 'src/images/rvgroup-client-banner-right-detail.svg';
import bannerClient from 'src/images/banner-client.png';

const NewRvPanelFeatureBar = () => {
  // const intl = useIntl();

  const handleRedirectToApp = event => {
    event.preventDefault();

    window.open(FEATURE_BAR_REDIRECT_LINK, '_blank');
  };

  return (
    <div>
      <img onClick={handleRedirectToApp} className={classes.imageFull} src={bannerClient} alt="Grupo RV" />
    </div>
    // <div className={classes.featureBar}>
    //   <img src={rvGroupBannerLeftDetail} alt="Grupo RV" />

    //   <nav>
    //     <img className={classes.featureBannerMiddleDetail} src={rvGroupBannerDetail} alt="Grupo RV" />

    //     <img className={classes.featureBannerText} src={rvGroupBannerText} alt="Grupo RV" />

    //     <button type="button" onClick={handleRedirectToApp}>
    //       <img src={playIcon} alt="Acessar" />

    //       {intl.formatMessage({ id: 'GET_TO_KNOW_MESSAGE' })}
    //     </button>
    //   </nav>

    //   <img src={rvGroupBannerRightDetail} alt="Grupo RV" />
    // </div>
  );
};

export default NewRvPanelFeatureBar;

import React from 'react';
import ReactTooltip from 'react-tooltip';

import PropTypes from 'prop-types';

import classes from 'src/components/ExtraActions/style.module.scss';
import TypeGuiada from 'src/svg-icons/type-guiada';

const ExtraActionsComponent = ({ changePage, intl, orderData, shouldShowButton }) => (
  <div className={classes.extraActions}>
    <h1>{intl.formatMessage({ id: 'EXTRA_ACTIONS' })}</h1>
    <p>{intl.formatMessage({ id: 'REQUEST_EXTRA_ACTION_MESSAGE' })}</p>
    <ul className={classes.itemList}>
      {shouldShowButton('checklist') && (
        <li>
          <button type="button" onClick={evt => changePage(evt, 'checklist-form')}>
            <figure>
              <TypeGuiada />
            </figure>
            <div className={classes.itemData}>
              <h2>{intl.formatMessage({ id: 'GUIDED_INSPECTION' })}</h2>
            </div>
          </button>
        </li>
      )}
      {shouldShowButton('repairBudget') && (
        <li
          data-tip={
            orderData.damages_count < 1 ? intl.formatMessage({ id: 'NON_CONFORMITIES_REPORTED_MESSAGE' }) : null
          }
        >
          <button
            type="button"
            onClick={evt => changePage(evt, 'repair-budget-form')}
            disabled={orderData.damages_count < 1}
          >
            <figure>
              <TypeGuiada />
            </figure>
            <div className={classes.itemData}>
              <h2>{intl.formatMessage({ id: 'REPAIR_BUDGET' })}</h2>
            </div>
          </button>
        </li>
      )}
    </ul>
    <ReactTooltip effect="solid" />
  </div>
);

ExtraActionsComponent.propTypes = {
  changePage: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  orderData: PropTypes.object,
  shouldShowButton: PropTypes.func.isRequired,
};

ExtraActionsComponent.defaultProps = {
  orderData: null,
};

export default ExtraActionsComponent;

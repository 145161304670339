import React from 'react';

import PropTypes from 'prop-types';

import classes from 'src/components/common/FullscreenSpinner/style.module.scss';
import Spinner from 'src/components/common/Spinner';

const FullscreenSpinner = ({ text }) => (
  <div className={classes.fullscreenSpinner}>
    <div className={classes.content}>
      <div className={classes.spinner}>
        <Spinner />
      </div>
      <div className={classes.text}>{text}</div>
    </div>
  </div>
);

FullscreenSpinner.propTypes = {
  text: PropTypes.string.isRequired,
};

export default FullscreenSpinner;

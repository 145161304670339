// import React from 'react';
import PropTypes from 'prop-types';

// const ErrorList = ({ errors }) => (
//   <ul className="error-list">
//     {errors.map((error, index) => <li key={index}>{error.stack}</li>)}
//   </ul>
// );

const ErrorList = () => null;

ErrorList.propTypes = {
  errors: PropTypes.array.isRequired,
};

export default ErrorList;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let MenuClientes = props => (
  <SvgIcon {...props} viewBox="0 0 50 49.998">
    <path
      fill="#5578A0"
      d="M25,0C11.193,0,0,11.193,0,25c0,13.809,11.193,25,25,25c13.807,0,25-11.191,25-25C50,11.193,38.807,0,25,0z
  		 M25,48C12.297,48,2,37.703,2,25S12.297,2,25,2c12.702,0,23,10.297,23,23S37.702,48,25,48z"
    />
    <path
      fill="#FFFFFF"
      d="M25,24.433c2.947,0,5.334-2.387,5.334-5.333c0-2.947-2.387-5.334-5.334-5.334s-5.333,2.387-5.333,5.334
  		C19.667,22.046,22.053,24.433,25,24.433z M25,27.101c-3.56,0-10.667,1.786-10.667,5.333v2.667h21.334v-2.667
  		C35.667,28.887,28.561,27.101,25,27.101z"
    />
  </SvgIcon>
);
MenuClientes = pure(MenuClientes);
MenuClientes.displayName = 'MenuClientes';
MenuClientes.muiName = 'SvgIcon';

export default MenuClientes;

import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/AdditionalsSection.module.css';
import OptionalIcon from 'src/svg-icons/optionals';

function AdditionalsSection({ additionals }) {
  return (
    <Grid item xs={12}>
      <div className={classes.Header}>
        <OptionalIcon viewBox="0 0 34.016 34.016" className={classes.Icon} />
        <FormattedMessage id="additionals" />
      </div>
      <Grid container spacing={8} className={classes.Information}>
        <Grid item xs={6} data-cy="order-details-additionals-modality">
          <div className={classes.SubHeader}>
            <FormattedMessage id="modality" />
          </div>
          <FormattedMessage id={additionals.modality} />
        </Grid>
        <Grid item xs={6} data-cy="order-details-additionals-urgency">
          <div className={classes.SubHeader}>
            <FormattedMessage id="urgency" />
          </div>
          <FormattedMessage id={additionals.urgency} />
        </Grid>
      </Grid>
    </Grid>
  );
}

AdditionalsSection.propTypes = {
  /**
   * Object that contains the additionals information
   */
  additionals: PropTypes.object.isRequired,
};

export default AdditionalsSection;

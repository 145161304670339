import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

const style = {
  fill: '#393939',
};

let Costs = props => (
  <SvgIcon {...props}>
    <g>
      <path
        style={style}
        d="M17,2C8.7,2,2,8.7,2,17s6.7,15,15,15s15-6.7,15-15S25.3,2,17,2z M17,30C9.8,30,4,24.2,4,17C4,9.8,9.8,4,17,4
        c7.2,0,13,5.8,13,13C30,24.2,24.2,30,17,30z"
      />
      <path
        style={style}
        d="M21.1,22c0,2-1.6,3.6-3.6,3.6S13.9,24,13.9,22V10.6c0-1.3,1-2.3,2.3-2.3s2.3,1,2.3,2.3v9.5
        c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9v-8.6h-1.4v8.6c0,1.3,1,2.3,2.3,2.3s2.3-1,2.3-2.3v-9.5c0-2-1.6-3.6-3.6-3.6
        s-3.6,1.6-3.6,3.6V22c0,2.8,2.2,5,5,5s5-2.2,5-5V11.5h-1.4V22z"
      />
    </g>
  </SvgIcon>
);
Costs = pure(Costs);
Costs.displayName = 'Costs';
Costs.muiName = 'SvgIcon';

export default Costs;

/**
 * @prettier
 */
import React from 'react';

class DocuSignHiddenStatus extends React.Component {
  componentDidMount() {
    let { value, label } = this.props;
    if (label === 'status') {
      if (value === undefined) value = 'created';
    } else if (label === 'type') {
      if (value === undefined) value = 'marketplace_docusign';
    }
    this.props.onChange(value);
  }

  render() {
    return <input type="text" style={{ display: 'none' }} data-cy="docusign-hidden-status" />;
  }
}

export default DocuSignHiddenStatus;

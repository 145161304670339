import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Tooltip from '@material-ui/core/Tooltip';

import classes from 'src/components/ClientsPage/ClientLink/style.module.scss';
import copy from 'src/components/common/icons/copy.svg';
import info from 'src/components/common/icons/info.svg';
import { BASE_URL } from 'src/config';
import { generateToken } from 'src/helpers/tokenGenerator';
import { showToast } from 'src/store/ducks/toasts';

const ClientLink = () => {
  const [url, setUrl] = useState('');
  const dispatch = useDispatch();

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(url);
    dispatch(
      showToast({
        type: 'success',
        title: 'Sucesso!',
        text: 'Link copiado.',
        duration: 5000,
      })
    );
  };

  useEffect(() => {
    const me = JSON.parse(sessionStorage.getItem('me'));
    const franchise = {
      id: me.franchiseeId,
      name: me.profile.name,
      email: me.profile.email,
      phone: me.profile.phone,
    };
    const token = generateToken(franchise);
    setUrl(`${BASE_URL}/new-client/${token}`);
  }, []);

  return (
    <div className={classes.item}>
      <div>
        <Tooltip
          title="Copie e envie ao cliente o link de preenchimento do formulário de auto cadastro. Após a realização do auto cadastro o cliente receberá um e-mail para definição da senha e acesso ao Painel de Vistorias."
          placement="top-end"
          classes={{ tooltip: classes.orderCardTooltip }}
        >
          <label className={classes.label} htmlFor="url">
            Link de auto cadastro
            <img className={classes.img} src={info} alt="" onClick={() => copyToClipboard()} />
          </label>
        </Tooltip>
        <input className={classes.input} type="text" name="url" id="url" disabled value={url} />
        <img className={classes.coppy} src={copy} alt="Copiar" title="Copiar link" onClick={() => copyToClipboard()} />
      </div>
    </div>
  );
};

export default ClientLink;

import React from 'react';

import PropTypes from 'prop-types';

import classes from 'src/components/common/SearchBox/style.module.scss';

const ClosableFilterOption = ({ id, type, handleClose, children }) => (
  <div id={classes[type]} className={classes.closableFilter}>
    {children}
    <button type="button" className={classes[type]} onClick={() => handleClose(type, id)}>
      &times;
    </button>
  </div>
);

ClosableFilterOption.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ClosableFilterOption;

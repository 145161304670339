import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import PropTypes from 'prop-types';

import API from 'src/apiRequest';

import InspectorsFilterComponent from './InspectorsFilterComponent';

const InspectorsFilterContainer = ({ handleSelect }) => {
  const [isBusy, setIsBusy] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const intl = useIntl();

  const handleChange = option => {
    const { value } = option;
    handleSelect(value);
  };

  useEffect(() => {
    const fetchInspectors = async () => {
      try {
        setIsBusy(true);
        const { data } = await API.get('inspectors');
        setSuggestions(data);
        setIsBusy(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchInspectors();
  }, []);

  return (
    <InspectorsFilterComponent
      suggestions={suggestions}
      isBusy={isBusy}
      searchPlaceholder={intl.formatMessage({
        id: 'PLACEHOLDER.SEARCH_INSPECTOR',
      })}
      handleChange={handleChange}
    />
  );
};

InspectorsFilterContainer.propTypes = {
  /**
   * Function to handle the selection
   */
  handleSelect: PropTypes.func.isRequired,
};

export default InspectorsFilterContainer;

import React from 'react';

import PropTypes from 'prop-types';

import classes from 'src/components/common/elements/GrayButton/style.module.scss';
import Spinner from 'src/components/common/Spinner';

const GrayButtonComponent = ({ children, isBusy, isDone, onClick, ...props }) => (
  <button className={classes.grayButton} type="button" onClick={onClick} disabled={isBusy || isDone} {...props}>
    {isBusy ? (
      <div className={classes.spinnerWrapper}>
        <Spinner />
      </div>
    ) : (
      children
    )}
  </button>
);

GrayButtonComponent.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  isBusy: PropTypes.bool,
  isDone: PropTypes.bool,
};

GrayButtonComponent.defaultProps = {
  isBusy: false,
  isDone: false,
};

export default GrayButtonComponent;

import React from 'react';
import { useIntl } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const styles = {
  bootstrapInput: {
    width: 'calc(100% - 24px)',
    padding: '8px 12px',
    border: '1px solid #ced4da',
    borderRadius: 4,
    fontFamily: 'Open Sans',
    fontSize: 'calc(6px + 0.4vw)',
    fontWeight: 'normal',
    '&:focus': {
      borderColor: '#80bdff',
    },
    '&:disabled': {
      background: '#f4f3f4',
    },
  },
  noPadding: {
    padding: 0,
  },
  bootstrapInputError: {
    width: 'calc(100% - 24px)',
    padding: '8px 12px',
    borderRadius: 4,
    border: '1px solid red',
    fontFamily: 'Open Sans',
    fontSize: 'calc(6px + 0.4vw)',
    '&:focus': {
      borderColor: '#80bdff',
    },
    '&:disabled': {
      background: '#f4f3f4',
    },
  },
  label: {
    fontFamily: 'Open Sans',
    marginTop: '0.3vh',
    fontSize: 'calc(6px + 0.3vw)',
  },
};

const RequestFormWidgetStringMultiline = ({
  id,
  classes,
  disabled,
  label,
  onChange,
  options: { rows },
  rawErrors,
  required,
  value,
  placeholder,
}) => {
  const intl = useIntl();
  const error = !!(rawErrors && rawErrors?.length > 0);

  return (
    <div>
      <label style={styles.label} htmlFor={id}>
        {intl.formatMessage({ id: label })}

        {required && '*'}
      </label>

      <TextField
        disabled={disabled}
        fullWidth
        id={id}
        data-cy={id}
        type="text"
        multiline
        rows={rows || 3}
        className="custom"
        value={value}
        onChange={event => onChange(event.target.value)}
        placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''}
        inputProps={{
          style: {
            resize: 'vertical',
          },
        }}
        InputProps={{
          disableUnderline: true,
          classes: {
            root: classes.noPadding,
            input: error ? classes.bootstrapInputError : classes.bootstrapInput,
          },
        }}
      />
    </div>
  );
};

RequestFormWidgetStringMultiline.propTypes = {
  id: PropTypes.string.isRequired,
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  rawErrors: PropTypes.array,
  required: PropTypes.bool,
  value: PropTypes.any,
  options: PropTypes.object,
  placeholder: PropTypes.string,
};

RequestFormWidgetStringMultiline.defaultProps = {
  classes: {},
  disabled: false,
  required: false,
  rawErrors: [],
  value: undefined,
  options: {},
  placeholder: null,
};

export default withStyles(styles)(RequestFormWidgetStringMultiline);

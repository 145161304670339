import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let Optionals = props => (
  <SvgIcon {...props}>
    <path
      fill="#393939"
      d="M17.008,2.008c-8.271,0-15,6.729-15,15c0,8.271,6.729,15,15,15c8.271,0,15-6.729,15-15
	C32.008,8.737,25.279,2.008,17.008,2.008z M17.008,30.008c-7.168,0-13-5.832-13-13s5.832-13,13-13s13,5.832,13,13
	S24.176,30.008,17.008,30.008z"
    />
    <path
      fill="#393939"
      d="M24.388,15.501h-1.119V12.52c0-0.82-0.671-1.49-1.49-1.49h-2.98V9.91c0-1.028-0.834-1.862-1.862-1.862
	s-1.864,0.834-1.864,1.862v1.12h-2.981c-0.82,0-1.483,0.67-1.483,1.49v2.832h1.111c1.111,0,2.013,0.902,2.013,2.013
	c0,1.113-0.902,2.013-2.013,2.013h-1.118v2.834c0,0.817,0.67,1.488,1.49,1.488h2.833v-1.116c0-1.112,0.901-2.014,2.012-2.014
	c1.11,0,2.011,0.901,2.011,2.014V23.7h2.832c0.819,0,1.49-0.671,1.49-1.488V19.23h1.119c1.027,0,1.862-0.835,1.862-1.865
	C26.25,16.336,25.415,15.501,24.388,15.501z"
    />
  </SvgIcon>
);
Optionals = pure(Optionals);
Optionals.displayName = 'Optionals';
Optionals.muiName = 'SvgIcon';

export default Optionals;

import React from 'react';
import MaskedInput from 'react-text-mask';

import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import ClientsSelect from 'src/components/OrdersReportsModal/components/ClientsSelect';
import MultipleSelect from 'src/components/OrdersReportsModal/components/MultipleSelect';
import classes from 'src/components/OrdersReportsModal/style.module.scss';
import { DATE_MASK } from 'src/helpers/mask';

const OrdersReportsModalComponent = ({ types, statuses, inspectors, handleSubmit, intl }) => (
  <div className={classes.ordersReportsModal}>
    <h3>{intl.formatMessage({ id: 'EXPORT_EXTRACT' })}</h3>

    <p>{intl.formatMessage({ id: 'ORDERS_REPORTS_FORM_INSTRUCTION' })}</p>

    <Formik
      initialValues={{
        clients: [],
        types: [],
        statuses: [],
        inspectors: [],
        minCreatedAt: '',
        maxCreatedAt: '',
        minFirstSentAt: '',
        maxFirstSentAt: '',
      }}
      onSubmit={handleSubmit}
    >
      {({ errors, isSubmitting, handleChange, setFieldValue }) => (
        <Form>
          <div className={classes.field}>
            <label htmlFor="clients">{intl.formatMessage({ id: 'CUSTOMERS' })}</label>

            <ClientsSelect handleSelectClient={values => setFieldValue('clients', values || [])} />

            {errors.clients && <div className={classes.error}>{errors.clients}</div>}
          </div>

          <div className={classes.field}>
            <label htmlFor="types">{intl.formatMessage({ id: 'TYPES' })}</label>

            <Field
              name="types"
              id="types"
              options={types}
              placeholder={intl.formatMessage({ id: 'TYPES' })}
              component={props => <MultipleSelect {...props} />}
            />

            {errors.types && <div className={classes.error}>{errors.types}</div>}
          </div>

          <div className={classes.field}>
            <label htmlFor="statuses">{intl.formatMessage({ id: 'STATUS' })}</label>

            <Field
              name="statuses"
              id="statuses"
              options={statuses}
              placeholder={intl.formatMessage({ id: 'STATUS' })}
              component={props => <MultipleSelect {...props} />}
            />

            {errors.types && <div className={classes.error}>{errors.types}</div>}
          </div>

          <div className={classes.field}>
            <label htmlFor="inspectors">{intl.formatMessage({ id: 'INSPECTORS' })}</label>

            <Field
              name="inspectors"
              id="inspectors"
              options={inspectors}
              placeholder={intl.formatMessage({ id: 'INSPECTORS' })}
              component={props => <MultipleSelect {...props} />}
            />

            {errors.types && <div className={classes.error}>{errors.types}</div>}
          </div>

          <div className={classes.fieldWrapper}>
            <label>{intl.formatMessage({ id: 'LABEL.PERIOD' })}</label>

            <section>
              <div className={classes.field}>
                <label htmlFor="minCreatedAt">
                  {intl.formatMessage({ id: 'FROM' })} {intl.formatMessage({ id: 'REQUESTED_AT' })}
                </label>

                <MaskedInput
                  id="minCreatedAt"
                  name="minCreatedAt"
                  type="text"
                  onChange={handleChange}
                  mask={DATE_MASK}
                  guide={false}
                />

                {errors.minCreatedAt && <div className={classes.error}>{errors.minCreatedAt}</div>}
              </div>

              <div className={classes.field}>
                <label htmlFor="maxCreatedAt">{intl.formatMessage({ id: 'UNTIL' })}</label>

                <MaskedInput
                  id="maxCreatedAt"
                  name="maxCreatedAt"
                  type="text"
                  onChange={handleChange}
                  mask={DATE_MASK}
                  guide={false}
                />

                {errors.maxCreatedAt && <div className={classes.error}>{errors.maxCreatedAt}</div>}
              </div>
            </section>

            <section>
              <div className={classes.field}>
                <label htmlFor="minFirstSentAt">
                  {intl.formatMessage({ id: 'FROM' })} {intl.formatMessage({ id: 'DELIVERED_AT' })}
                </label>

                <MaskedInput
                  id="minFirstSentAt"
                  name="minFirstSentAt"
                  type="text"
                  onChange={handleChange}
                  mask={DATE_MASK}
                  guide={false}
                />

                {errors.minFirstSentAt && <div className={classes.error}>{errors.minFirstSentAt}</div>}
              </div>

              <div className={classes.field}>
                <label htmlFor="maxFirstSentAt">{intl.formatMessage({ id: 'UNTIL' })}</label>

                <MaskedInput
                  id="maxFirstSentAt"
                  name="maxFirstSentAt"
                  type="text"
                  onChange={handleChange}
                  mask={DATE_MASK}
                  guide={false}
                />

                {errors.maxFirstSentAt && <div className={classes.error}>{errors.maxFirstSentAt}</div>}
              </div>
            </section>
          </div>

          <div className={`${classes.field} ${classes.actions}`}>
            <button className={`${classes.button} ${classes.isGreen}`} type="submit" disabled={isSubmitting}>
              {intl.formatMessage({ id: 'EXPORT_EXTRACT' })}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  </div>
);

OrdersReportsModalComponent.propTypes = {
  types: PropTypes.array.isRequired,
  statuses: PropTypes.array.isRequired,
  inspectors: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default OrdersReportsModalComponent;

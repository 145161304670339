import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import classes from 'src/orderDetails/PreviousInspectionSection.module.css';
import Previous from 'src/svg-icons/previous';

const PreviousInspectionSection = ({ orderId, previousInspection }) => {
  const [type, setType] = useState(null);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    let previousInspectionType = null;

    if (Object.keys(previousInspection).length > 0) {
      if (
        Object.prototype.hasOwnProperty.call(previousInspection, 'file') &&
        previousInspection.file.original_name !== undefined
      ) {
        previousInspectionType = 'file';
      } else if (Object.prototype.hasOwnProperty.call(previousInspection, 'inspection')) {
        previousInspectionType = 'inspection';
      }
    }

    setType(previousInspectionType);

    if (previousInspectionType === 'file') {
      API.get(`/orders/${orderId}/attachments`)
        .then(res => {
          setUrl(res.data.url);
        })
        .catch(err => {
          console.debug('PreviousInspectionSection.useEffect error ', err);
        });
    }
  }, []);

  let content;

  if (type === 'file' && url !== null) {
    content = (
      <Grid item xs={12}>
        <div className={classes.SubHeader}>
          <FormattedMessage id="file" />
        </div>

        <div>
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            data-cy="order-details-previous-inspection-file-original-name"
          >
            {previousInspection.file.original_name}
          </a>
        </div>
      </Grid>
    );
  } else if (type === 'inspection') {
    content = (
      <Grid item xs={12}>
        <div className={classes.SubHeader}>
          <FormattedMessage id="inspection" />
        </div>

        <div data-cy="order-details-previous-inspection-name-id">
          <Link to={`/orders/${previousInspection.inspection.id}`}>
            {previousInspection.inspection.name}
            {', '}
            {previousInspection.inspection.id}
          </Link>
        </div>
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <div className={classes.Header}>
        <Previous viewBox="0 0 34.016 34.016" className={classes.Icon} />

        <FormattedMessage id="previous" />
      </div>

      <Grid container spacing={16} className={classes.Information}>
        {content}
      </Grid>
    </Grid>
  );
};

PreviousInspectionSection.propTypes = {
  previousInspection: PropTypes.object.isRequired,
  orderId: PropTypes.string,
};

export default PreviousInspectionSection;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import axios from 'axios';
import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import ClientCard from 'src/components/ClientsPage/ClientList/ClientCard';
import classes from 'src/components/ClientsPage/ClientList/style.module.scss';
import CardList from 'src/components/common/CardList';
import Spinner from 'src/components/common/Spinner';
import { setOptions, setQuery } from 'src/store/ducks/filter';

let source = null;
const ClientList = ({
  cards: cardControl,
  query,
  setOptions,
  setQuery, // eslint-disable-line no-shadow
}) => {
  const isInitialMount = useRef(true);
  const [cards, setCards] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const params = useParams();
  const { lastReload } = cardControl;
  const status = params.status || 'enabled';

  const fetchCards = useCallback(
    async (page, append = false) => {
      if (source !== null) {
        source.cancel();
      }

      source = API.CancelToken.source();

      try {
        if (!append) setLoading(true);
        setHasMore(true);
        const { data } = await API.get('/clients', {
          params: {
            search: query.text ? query.text : null,
            page: page + 1,
            status,
          },
          cancelToken: source.token,
        });

        if (data.page * 15 >= data.total) {
          setHasMore(false);
        }

        if (append) {
          setCards(oldCards => [...oldCards, ...data.data.map(item => ({ data: item }))]);
        } else {
          setCards(data.data.map(item => ({ data: item })));
        }

        setLoading(false);
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        }
        console.log(err);
      }
    },
    [query.text, status]
  );

  // set possible filter options and load initial cards
  useEffect(() => {
    setOptions({
      basic: ['text'],
    });

    return () => {
      if (source) source.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isInitialMount.current) {
      setQuery({});
    } else {
      fetchCards(0);
    }
  }, [status, lastReload]); // eslint-disable-line react-hooks/exhaustive-deps

  // listen for changes and filter cards
  useEffect(() => {
    // do not fetch cards on mount
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    // clear right panel when using filter
    if (Object.entries(query).length > 0 && history.location.pathname !== `/clients/${status}`) {
      history.push(`/clients/${status}`);
    }

    fetchCards(0);
  }, [fetchCards, history, query, status]);

  const loadMore = page => {
    fetchCards(page, true);
  };

  return loading ? (
    <div className={classes.spinner}>
      <Spinner />
    </div>
  ) : (
    <CardList cards={cards} Element={ClientCard} hasMore={hasMore} loadMore={loadMore} />
  );
};

ClientList.propTypes = {
  cards: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  setOptions: PropTypes.func.isRequired,
  setQuery: PropTypes.func.isRequired,
};

const mapStateToProps = ({ cards, filter }) => ({
  query: filter.query,
  cards,
});

export default connect(mapStateToProps, { setOptions, setQuery })(ClientList);

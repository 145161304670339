import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let StatusModality = props => (
  <SvgIcon {...props}>
    <path d="M0.382812 0.65625C0.382812 0.292578 0.675391 0 1.03906 0H2.2832C2.88477 0 3.41797 0.35 3.6668 0.875H14.9051C15.6242 0.875 16.1492 1.55859 15.9605 2.25312L14.8395 6.41758C14.607 7.27617 13.8277 7.875 12.9391 7.875H5.05039L5.19805 8.6543C5.2582 8.96328 5.52891 9.1875 5.84336 9.1875H13.7266C14.0902 9.1875 14.3828 9.48008 14.3828 9.84375C14.3828 10.2074 14.0902 10.5 13.7266 10.5H5.84336C4.89727 10.5 4.08516 9.82734 3.91016 8.90039L2.49922 1.49023C2.48008 1.38633 2.38984 1.3125 2.2832 1.3125H1.03906C0.675391 1.3125 0.382812 1.01992 0.382812 0.65625ZM3.88281 12.6875C3.88281 12.5151 3.91676 12.3445 3.98272 12.1852C4.04868 12.026 4.14536 11.8813 4.26723 11.7594C4.38911 11.6375 4.5338 11.5409 4.69304 11.4749C4.85228 11.4089 5.02295 11.375 5.19531 11.375C5.36767 11.375 5.53834 11.4089 5.69758 11.4749C5.85682 11.5409 6.00151 11.6375 6.12339 11.7594C6.24527 11.8813 6.34195 12.026 6.4079 12.1852C6.47386 12.3445 6.50781 12.5151 6.50781 12.6875C6.50781 12.8599 6.47386 13.0305 6.4079 13.1898C6.34195 13.349 6.24527 13.4937 6.12339 13.6156C6.00151 13.7375 5.85682 13.8341 5.69758 13.9001C5.53834 13.9661 5.36767 14 5.19531 14C5.02295 14 4.85228 13.9661 4.69304 13.9001C4.5338 13.8341 4.38911 13.7375 4.26723 13.6156C4.14536 13.4937 4.04868 13.349 3.98272 13.1898C3.91676 13.0305 3.88281 12.8599 3.88281 12.6875ZM13.0703 11.375C13.4184 11.375 13.7522 11.5133 13.9984 11.7594C14.2445 12.0056 14.3828 12.3394 14.3828 12.6875C14.3828 13.0356 14.2445 13.3694 13.9984 13.6156C13.7522 13.8617 13.4184 14 13.0703 14C12.7222 14 12.3884 13.8617 12.1422 13.6156C11.8961 13.3694 11.7578 13.0356 11.7578 12.6875C11.7578 12.3394 11.8961 12.0056 12.1422 11.7594C12.3884 11.5133 12.7222 11.375 13.0703 11.375Z" />
  </SvgIcon>
);
StatusModality = pure(StatusModality);
StatusModality.displayName = 'StatusModality';
StatusModality.muiName = 'SvgIcon';

export default StatusModality;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let TypePiloto = props => (
  <SvgIcon {...props} viewBox="0 0 34.016 34.016">
    <path
      d="M12.3691 3.75711V1.81324C12.3691 0.742291 12.1387 0.52002 11.0193 0.52002H9.97259C8.85315 0.52002 8.62279 0.73825 8.62279 1.81324V3.75711L5.2483 5.05033V7.63676H15.7436V5.05033L12.3691 3.75711Z"
      fill="#5C099E"
    />
    <path
      d="M20.6134 4.31481V8.74004H18.2775V12.7773H18.9281V13.2986C16.1315 14.3938 14.2523 17.0934 14.2523 20.1648C14.2523 23.5353 16.5236 26.3844 19.6151 27.2614C19.3363 27.419 19.017 27.5038 18.6776 27.4998H1.94655C0.887732 27.516 0.0188508 26.6673 0.00268555 25.6044C0.00268555 25.6004 0.00268555 25.6004 0.00268555 25.6004V4.6664C0.0188508 3.60354 0.887732 2.75082 1.94655 2.76699H7.05881L3.74898 4.04404V9.13609H16.8671V3.67628L13.7674 2.39115H18.7382C19.7647 2.37498 20.5972 3.23982 20.6134 4.31481Z"
      fill="#5C099E"
    />
    <path
      d="M23.123 14.1837V12.7773H20.1405V14.1837C17.4571 14.8505 15.4647 17.2753 15.4647 20.1648C15.4647 23.1837 17.6349 25.6974 20.5002 26.2268C20.868 26.2955 21.2438 26.3319 21.6318 26.3319C25.0345 26.3319 27.7948 23.5716 27.7948 20.1648C27.7948 17.2753 25.8064 14.8505 23.123 14.1837ZM17.251 20.1971C17.251 18.128 18.6856 16.3943 20.6134 15.9376C20.9407 15.8608 21.2802 15.8204 21.6277 15.8204C24.0485 15.8204 26.0085 17.7804 26.0085 20.1971H17.251Z"
      fill="#5C099E"
    />
    <path d="M23.7696 9.95243H19.4899V11.5649H23.7696V9.95243Z" fill="#5C099E" />
  </SvgIcon>
);

TypePiloto = pure(TypePiloto);
TypePiloto.displayName = 'TypePiloto';
TypePiloto.muiName = 'SvgIcon';

export default TypePiloto;

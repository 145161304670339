import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const Tag = props => (
  <SvgIcon viewBox="0 0 34 34" {...props}>
    <path
      fill="#393939"
      d="M17,2C8.7,2,2,8.7,2,17s6.7,15,15,15s15-6.7,15-15S25.3,2,17,2z M17,30C9.8,30,4,24.2,4,17S9.8,4,17,4
        s13,5.8,13,13S24.2,30,17,30z"
    />
    <path
      fill="#393939"
      d="M22.5,22.7l4.3-4.6c0.6-0.6,0.6-1.6,0-2.2l-4.3-4.6c-0.3-0.3-0.7-0.5-1.2-0.5H9.7c-0.9,0-1.6,0.7-1.6,1.6v9.2
        c0,0.9,0.7,1.6,1.6,1.6h11.7C21.8,23.2,22.2,23.1,22.5,22.7z M21.4,17c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,
        1.5s-0.7,1.5-1.5,1.5 S21.4,17.8,21.4,17z"
    />
  </SvgIcon>
);
Tag.displayName = 'Tag';
Tag.muiName = 'SvgIcon';

export default Tag;

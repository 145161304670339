import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import AttachFile from 'src/svg-icons/attachments';

import classes from './style.module.scss';

function MediasSection({ medias }) {
  return (
    <Grid item xs={12}>
      <div className={classes.header}>
        <AttachFile viewBox="0 0 34.016 34.016" className={classes.icon} />
        <FormattedMessage id="medias" />
      </div>
      {medias.map(media => (
        <Grid container spacing={8} className={classes.information} key={media.external_id}>
          <Grid item xs={3} data-cy="order-details-media-type">
            <div className={classes.subHeader}>
              <FormattedMessage id="type" />
            </div>
            <FormattedMessage id={media.type} />
          </Grid>
          <Grid item xs={3} data-cy="order-details-media-external-id">
            <div className={classes.subHeader}>
              <FormattedMessage id="external_id" />
            </div>
            {media.external_id}
          </Grid>
          <Grid item xs={6} data-cy="order-details-media-external-uri">
            <div className={classes.subHeader}>
              <FormattedMessage id="external_uri" />
            </div>
            <span className={classes.limit}>
              <a href={media.external_uri} target="_blank" rel="noreferrer noopener">
                {media.external_uri}
              </a>
            </span>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

MediasSection.propTypes = {
  /**
   * Object that contains the address information
   */
  medias: PropTypes.array,
};

MediasSection.defaultProps = {
  medias: [],
};

export default MediasSection;

import React from 'react';
import { useIntl } from 'react-intl';
import MaskedInput from 'react-text-mask';

import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useUser } from 'src/contexts/UserContext';
import { DATE_MASK, DATETIME_MASK, masksByCountry } from 'src/helpers/mask';
import faqImage from 'src/images/icons/header-faq.svg';

const styles = {
  wrapper: {
    position: 'relative',
  },
  bootstrapInput: {
    borderRadius: 4,
    fontWeight: 'normal',
    border: '1px solid #ced4da',
    fontSize: 'calc(6px + 0.4vw)',
    padding: '8px 12px',
    width: 'calc(100% - 24px)',
    '&:focus': {
      borderColor: '#80bdff',
    },
    '&.is-disabled': {
      backgroundColor: '#f4f3f4',
      borderColor: '#ced4da',
      cursor: 'not-allowed',
    },
    fontFamily: 'Open Sans',
  },
  bootstrapInputError: {
    borderRadius: 4,
    border: '1px solid red',
    fontSize: 'calc(6px + 0.4vw)',
    padding: '8px 12px',
    width: 'calc(100% - 24px)',
    '&:focus': {
      borderColor: '#80bdff',
    },
    fontFamily: 'Open Sans',
  },
  label: {
    fontFamily: 'Open Sans',
    marginTop: '0.3vh',
    fontSize: 'calc(6px + 0.3vw)',
  },
  tooltipWrapper: {
    paddingLeft: '10px',
    position: 'absolute',
    top: '54%',
    right: 8,
  },
  tooltip: {
    cursor: 'pointer',
    backgroundColor: '#5882c1',
    borderRadius: '50%',
    display: 'block',
    height: 22,
  },
};

const MaskedElement = ({ inputRef, mask, ...props }) => {
  const me = useUser();
  const cpfMask = masksByCountry[me?.profile?.country || 'BR'].CPF_MASK;
  const cnpjMask = masksByCountry[me?.profile?.country || 'BR'].CNPJ_MASK;
  const phoneMask = masksByCountry[me?.profile?.country || 'BR'].PHONE_MASK;
  const cellphoneMask = masksByCountry[me?.profile?.country || 'BR'].CELLPHONE_MASK;
  const cellphoneMaskLength = masksByCountry[me?.profile?.country || 'BR'].CELLPHONE_MASK_LENGTH;

  const masks = {
    cpf: cpfMask,
    cnpj: cnpjMask,
    phone: value => (value.length >= cellphoneMaskLength ? cellphoneMask : phoneMask),
    date: DATE_MASK,
    datetime: DATETIME_MASK,
  };

  return mask ? (
    <MaskedInput {...props} ref={inputRef} guide={false} mask={masks[mask] || null} />
  ) : (
    <input {...props} ref={inputRef} />
  );
};

MaskedElement.propTypes = {
  inputRef: PropTypes.any,
  mask: PropTypes.any,
};

const RequestFormWidgetString = ({
  classes,
  disabled,
  formData,
  id,
  idSchema,
  label,
  name,
  onChange,
  options,
  placeholder,
  rawErrors,
  required,
  value,
}) => {
  const intl = useIntl();

  const error = rawErrors && rawErrors?.length > 0;
  const inputProps = options?.maxlength ? { maxLength: options.maxlength } : {};
  const bootstrapClasses = error ? classes.bootstrapInputError : classes.bootstrapInput;

  const handleChange = evt => {
    let { value: inputVal } = evt.target;

    if (options?.transform?.includes('remove-whitespace')) {
      inputVal = inputVal.replace(/\s/g, '');
    }

    onChange(inputVal);
  };

  return (
    <div style={styles.wrapper}>
      <label style={styles.label} htmlFor={id}>
        {intl.formatMessage({ id: label || name })}

        {required && '*'}
      </label>

      <TextField
        disabled={disabled}
        fullWidth
        type="text"
        name={id || idSchema?.$id}
        id={id || idSchema?.$id}
        data-cy={id || idSchema?.$id}
        inputProps={{ ...inputProps }}
        InputProps={{
          inputComponent: MaskedElement,
          inputProps: options?.mask ? { mask: options.mask } : {},
          placeholder:
            options?.placeholderIsTranslatable && placeholder
              ? intl.formatMessage({ id: placeholder })
              : placeholder || null,
          value: value || formData,
          onChange: handleChange,
          disableUnderline: true,
          classes: {
            input: classNames('customs', { 'is-disabled': disabled }, bootstrapClasses),
          },
        }}
      />

      {options?.tooltip && (
        <Tooltip title={intl.formatMessage({ id: options.tooltip?.message })} placement="top">
          <div style={styles.tooltipWrapper}>
            <img src={faqImage} style={styles.tooltip} alt="tooltip" data-cy={`${id || idSchema?.$id}-tooltip`} />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

RequestFormWidgetString.propTypes = {
  classes: PropTypes.any,
  disabled: PropTypes.any,
  formData: PropTypes.any,
  id: PropTypes.any,
  idSchema: PropTypes.any,
  label: PropTypes.any,
  name: PropTypes.any,
  onChange: PropTypes.any,
  options: PropTypes.any,
  placeholder: PropTypes.any,
  rawErrors: PropTypes.any,
  required: PropTypes.any,
  value: PropTypes.any,
};

export default withStyles(styles)(RequestFormWidgetString);

import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import axios from 'axios';

import api from 'src/apiRequest';
import InspectorDetailsComponent from 'src/components/InspectorsPage/InspectorDetails/InspectorDetailsComponent';
import { showToast } from 'src/store/ducks/toasts';

let source = null;

const InspectorDetailsContainer = () => {
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
  const [isEnableConfirmationVisible, setIsEnableConfirmationVisible] = useState(false);
  const [isExportDatabaseConfirmationVisible, setIsExportDatabaseConfirmationVisible] = useState(false);
  const [isBusy, setIsBusy] = useState(true);
  const [inspector, setInspector] = useState(null);

  const { id } = useParams();

  const dispatch = useDispatch();

  const history = useHistory();

  const intl = useIntl();

  const showDeleteConfirmation = () => {
    ReactTooltip.hide();
    setIsDeleteConfirmationVisible(true);
  };

  const hideDeleteConfirmation = () => {
    setIsDeleteConfirmationVisible(false);
    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 150);
  };

  const showEnableConfirmation = () => {
    ReactTooltip.hide();
    setIsEnableConfirmationVisible(true);
  };

  const hideEnableConfirmation = () => {
    setIsEnableConfirmationVisible(false);
    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 150);
  };

  const showExportDatabaseConfirmation = useCallback(() => {
    ReactTooltip.hide();

    setIsExportDatabaseConfirmationVisible(true);
  }, []);

  const hideExportDatabaseConfirmation = useCallback(() => {
    setIsExportDatabaseConfirmationVisible(false);

    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 150);
  }, []);

  const isSupportModeActive = useCallback(() => {
    return document.cookie.includes('SupportMode=true');
  }, []);

  const deleteInspector = async () => {
    try {
      await api.delete(`inspectors/${id}`);
      setIsDeleteConfirmationVisible(false);
      dispatch(
        showToast({
          type: 'success',
          title: intl.formatMessage({ id: 'SUCCESS' }),
          text: intl.formatMessage({ id: 'DELETE_INSPECTOR_SUCCESS' }),
          duration: 5000,
        })
      );
      history.push(`/inspectors/deleted/${id}`);
      await fetchInspector();
    } catch (err) {
      console.log(err);
      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'DELETE_INSPECTOR_ERROR' }),
          duration: 5000,
        })
      );
    }
  };

  const enableInspector = async () => {
    try {
      await api.post(`inspectors/${id}/activate`);
      setIsEnableConfirmationVisible(false);
      dispatch(
        showToast({
          type: 'success',
          title: intl.formatMessage({ id: 'SUCCESS' }),
          text: intl.formatMessage({ id: 'ENABLE_INSPECTOR_SUCCESS' }),
          duration: 5000,
        })
      );
      history.push(`/inspectors/active/${id}`);
      await fetchInspector();
    } catch (err) {
      console.log(err);
      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'ENABLE_INSPECTOR_ERROR' }),
          duration: 5000,
        })
      );
    }
  };

  const handleExportInspectorDatabase = useCallback(async () => {
    try {
      await api.post(`/inspectors/${id}/export-database`);

      dispatch(
        showToast({
          type: 'success',
          title: intl.formatMessage({ id: 'SUCCESS' }),
          text: intl.formatMessage({ id: 'EXPORT_DATABASE_SUCCESS' }),
          duration: 5000,
        })
      );
    } catch {
      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'EXPORT_DATABASE_ERROR' }),
          duration: 5000,
        })
      );
    } finally {
      setIsExportDatabaseConfirmationVisible(false);
    }
  }, [dispatch, id, intl]);

  const fetchInspector = async () => {
    try {
      setIsDeleteConfirmationVisible(false);
      setIsBusy(true);

      const { data } = await api.get(`/view/inspectors/${id}`, {
        cancelToken: source.token,
      });

      setInspector(data);
      setIsBusy(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (source !== null) {
      source.cancel();
    }
    source = api.CancelToken.source();

    setInspector(null);

    fetchInspector();

    return () => {
      if (source) source.cancel();
    };
  }, [id]);

  return (
    <InspectorDetailsComponent
      isBusy={isBusy}
      isSupportModeActive={isSupportModeActive}
      isDeleteConfirmationVisible={isDeleteConfirmationVisible}
      isEnableConfirmationVisible={isEnableConfirmationVisible}
      isExportDatabaseConfirmationVisible={isExportDatabaseConfirmationVisible}
      inspector={inspector}
      inspectorId={id}
      showDeleteConfirmation={showDeleteConfirmation}
      showEnableConfirmation={showEnableConfirmation}
      showExportDatabaseConfirmation={showExportDatabaseConfirmation}
      hideDeleteConfirmation={hideDeleteConfirmation}
      hideEnableConfirmation={hideEnableConfirmation}
      hideExportDatabaseConfirmation={hideExportDatabaseConfirmation}
      deleteInspector={deleteInspector}
      enableInspector={enableInspector}
      exportDatabase={handleExportInspectorDatabase}
      intl={intl}
    />
  );
};

export default InspectorDetailsContainer;

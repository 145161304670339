import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import { ReactComponent as Icon } from 'src/components/common/icons/wallet.svg';

import classes from './style.module.scss';

const FichaCertaSection = ({ data }) => {
  const { sintese, locacao } = data;
  return (
    <div className={classes.fichaCerta}>
      <div className={classes.header}>
        <Icon className={classes.icon} />
        <FormattedMessage id="fichaCerta" />
      </div>
      <Grid container spacing={8} className={classes.information}>
        {locacao.parecer_inquilinos?.score_conjunto && (
          <Grid item xs={6}>
            <div className={classes.subHeader}>Parecer Inquilinos</div>
            <span data-cy="order-details-parecer-inquilinos">{locacao.parecer_inquilinos?.score_conjunto}</span>
          </Grid>
        )}
        {locacao.parecer_fiadores?.score_conjunto && (
          <Grid item xs={6}>
            <div className={classes.subHeader}>Parecer Fiadores</div>
            <span data-cy="order-details-parecer-fiadores">{locacao.parecer_fiadores?.score_conjunto}</span>
          </Grid>
        )}
        {locacao.risco && (
          <Grid item xs={6}>
            <div className={classes.subHeader}>Risco</div>
            <span data-cy="order-details-risco">{locacao.risco}</span>
          </Grid>
        )}
      </Grid>

      {sintese && (
        <div className={classes.suitors}>
          <div className={classes.header}>Pretendentes</div>
          <div className={classes.title}>
            <div style={{ paddingLeft: '8px' }}>
              <FormattedMessage id="name" />
            </div>
            <div>
              <FormattedMessage id="type" />
            </div>
            <div style={{ width: '150px' }}>
              <FormattedMessage id="cnpj_or_cpf" />
            </div>
          </div>
          {sintese?.map(suitor => (
            <>
              <div className={classes.line} key={suitor.cpf || suitor.cnpj} style={{ paddingBottom: '0.5em' }}>
                <div style={{ width: '40%' }} className={classes.suitorName}>
                  {suitor.nome}
                </div>
                <div style={{ width: '40%' }}>
                  <FormattedMessage id={suitor.tipo_pretendente} />
                </div>
                <div style={{ width: '20%' }}>{suitor.cpf || suitor.cnpj}</div>
              </div>
              {suitor.parecer?.map(opinion => (
                <div className={classes.lineObservation} key={opinion.parecer_key}>
                  <div className={classes.action}>
                    <div className={classes.observationIcon}></div>
                    <div className={classes.actionName} title={opinion.recomendacao?.join(' ')}>
                      {opinion.risco}
                    </div>
                  </div>
                </div>
              ))}
            </>
          ))}
        </div>
      )}
    </div>
  );
};

FichaCertaSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FichaCertaSection;

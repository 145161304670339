import React from 'react';
import { FormattedMessage } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = {
  bootstrapInput: {
    borderRadius: 4,
    fontSize: 'calc(6px + 0.4vw)',
    padding: '8px 12px',
    width: 'calc(100% - 24px)',
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    fontFamily: 'Open Sans',
    backgroundColor: '#F4F3F4',
  },
};

function EditRequestFormFieldDisable(props) {
  return (
    <div>
      {
        <div style={styles.label}>
          <FormattedMessage id={props.name} />
          {props.required ? '*' : null}
        </div>
      }
      <TextField
        fullWidth={true}
        type={'text'}
        disabled
        className="custom"
        data-cy={`${String(props.name).toLowerCase()}`}
        value={props.formData}
        InputProps={{
          disableUnderline: true,
          classes: {
            input: props.classes.bootstrapInput,
          },
        }}
      />
    </div>
  );
}

export default withStyles(styles)(EditRequestFormFieldDisable);

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const DivergencesMenu = props => (
  <SvgIcon viewBox="0 0 27 27" {...props}>
    <g clipPath="url(#clip0_2780_1303)">
      <path
        d="M13.2645 -0.00195312C6.0828 -0.00195312 0.261719 5.81965 0.261719 13.0008C0.261719 20.1825 6.08332 26.0036 13.2645 26.0036C20.4452 26.0036 26.2673 20.1825 26.2673 13.0008C26.2673 5.81913 20.4457 -0.00195312 13.2645 -0.00195312ZM13.2645 24.9634C6.65753 24.9634 1.30194 19.6078 1.30194 13.0008C1.30194 6.39385 6.65753 1.03827 13.2645 1.03827C19.8709 1.03827 25.2271 6.39385 25.2271 13.0008C25.2271 19.6078 19.8709 24.9634 13.2645 24.9634Z"
        fill="#5678A0"
      />
      <path
        d="M9.75 6.25C9.05625 6.25 8.5 6.80625 8.5 7.5V17.5C8.5 17.8315 8.6317 18.1495 8.86612 18.3839C9.10054 18.6183 9.41848 18.75 9.75 18.75H12.25V17.5562L13.5563 16.25H9.75V15H14.8063L16.0563 13.75H9.75V12.5H17.3063L18.5 11.3063V10L14.75 6.25H9.75ZM14.125 7.1875L17.5625 10.625H14.125V7.1875ZM18.5938 13.125C18.5 13.125 18.4125 13.1562 18.3438 13.225L17.7063 13.8625L19.0125 15.1625L19.65 14.5312C19.7813 14.3938 19.7813 14.1687 19.65 14.0375L18.8375 13.225C18.7688 13.1562 18.6813 13.125 18.5938 13.125ZM17.3375 14.2313L13.5 18.075V19.375H14.8L18.6438 15.5312L17.3375 14.2313Z"
        fill="#FFFFFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_2780_1303">
        <rect width="26.0056" height="26.0045" fill="white" transform="translate(0.261719)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

DivergencesMenu.displayName = 'DivergencesMenu';
DivergencesMenu.muiName = 'SvgIcon';

export default DivergencesMenu;

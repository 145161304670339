import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const SectionCanceladas = props => (
  <SvgIcon {...props}>
    <path
      d="M17.008,2.945c-7.776,0-14.062,6.286-14.062,14.062c0,7.777,6.287,14.062,14.062,14.062
             c7.775,0,14.062-6.285,14.062-14.062C31.07,9.231,24.783,2.945,17.008,2.945z M24.039,
             22.056l-1.983,1.983l-5.048-5.048l-5.048,5.048l-1.983-1.983l5.048-5.048L9.977,11.96l1.983-1.983l5.048,
             5.048l5.048-5.048l1.983,1.983l-5.048,5.048L24.039,22.056z"
    />
  </SvgIcon>
);

SectionCanceladas.displayName = 'SectionCanceladas';
SectionCanceladas.muiName = 'SvgIcon';

export default SectionCanceladas;

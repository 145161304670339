import React, { useState, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import OrdersReportsModalComponent from 'src/components/OrdersReportsModal/OrdersReportsModalComponent';
import { ordersStatuses, orderType } from 'src/constants';
import { useUser } from 'src/contexts/UserContext';
import { formatDateString } from 'src/helpers/formatDateString';
import { showToast } from 'src/store/ducks/toasts';

const OrdersReportsModalContainer = ({ closeModal }) => {
  const [inspectiorsOptions, setInspectorsOptions] = useState([]);

  const dispatch = useDispatch();
  const intl = useIntl();

  const me = useUser();
  const features = me ? me.features : [];

  const typesOptions = useMemo(() => {
    return features
      .filter(item => item && item.includes('VISTORIA_'))
      .map(type => {
        const mappedType = orderType.get(type);

        return {
          value: mappedType,
          label: intl.formatMessage({ id: mappedType }),
        };
      });
  }, [features, intl]);

  const statusesOptions = Object.keys(ordersStatuses).map(statusKey => ({
    label: intl.formatMessage({ id: `LeftDrawer.${ordersStatuses[statusKey]}` }),
    value: statusKey,
  }));

  const handleSubmit = async (values, { setFieldError, setSubmitting }) => {
    try {
      let { error: minCreatedAtError, formattedDate: minCreatedAt } = formatDateString(values.minCreatedAt);
      let { error: maxCreatedAtError, formattedDate: maxCreatedAt } = formatDateString(values.maxCreatedAt);

      let { error: minFirstSentAtError, formattedDate: minFirstSentAt } = formatDateString(values.minFirstSentAt);
      let { error: maxFirstSentAtError, formattedDate: maxFirstSentAt } = formatDateString(values.maxFirstSentAt);

      if (minCreatedAtError) setFieldError('minCreatedAt', intl.formatMessage({ id: 'DATE_ERROR' }));
      if (maxCreatedAtError) setFieldError('maxCreatedAt', intl.formatMessage({ id: 'DATE_ERROR' }));

      if (minFirstSentAtError) setFieldError('minFirstSentAt', intl.formatMessage({ id: 'DATE_ERROR' }));
      if (maxFirstSentAtError) setFieldError('maxFirstSentAt', intl.formatMessage({ id: 'DATE_ERROR' }));

      if (minCreatedAtError || maxCreatedAtError || minFirstSentAtError || maxFirstSentAtError) return;

      const { data } = await API.get('/reports/orders', {
        params: {
          client_ids: values.clients?.length ? values.clients.map(client => client.value) : undefined,
          status: values.statuses?.length ? values.statuses.map(status => status.value) : undefined,
          types: values.types?.length ? values.types.map(type => type.value) : undefined,
          inspector_ids: values.inspectors?.length ? values.inspectors.map(inspector => inspector.value) : undefined,
          min_created_at: minCreatedAt || '',
          max_created_at: maxCreatedAt || '',
          min_first_sent_at: minFirstSentAt || '',
          max_first_sent_at: maxFirstSentAt || '',
        },
      });

      if (data?.file) window.open(data.file, '_blank');

      setSubmitting(false);
      closeModal();
    } catch (err) {
      console.debug('[DEBUG] OrdersReportsModalContainer handleSubmit error ', err);

      setSubmitting(false);

      dispatch(
        showToast({
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'EXPORT_EXTRACT_ERROR' }),
          type: 'error',
          duration: 4000,
        })
      );
    }
  };

  useEffect(() => {
    async function handleFetchInspectors() {
      try {
        const { data } = await API.get('/inspectors');

        setInspectorsOptions(data?.map(inspector => ({ label: inspector.name, value: inspector.public_id })) || []);
      } catch (err) {
        console.debug('[DEBUG] OrdersReportsModalContainer useEffect error ', err);

        dispatch(
          showToast({
            title: intl.formatMessage({ id: 'ERROR' }),
            text: intl.formatMessage({ id: 'FETCH_EXTRACT_INSPECTORS_DATA_ERROR' }),
            type: 'error',
            duration: 4000,
          })
        );
      }
    }

    handleFetchInspectors();
  }, []);

  return (
    <OrdersReportsModalComponent
      types={typesOptions}
      statuses={statusesOptions}
      inspectors={inspectiorsOptions}
      handleSubmit={handleSubmit}
      intl={intl}
    />
  );
};

OrdersReportsModalContainer.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default OrdersReportsModalContainer;

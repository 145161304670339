import React from 'react';
import { useIntl } from 'react-intl';

import moment from 'moment';
import PropTypes from 'prop-types';

import Icon from './Icon';
import OptionsComponent from './OptionsComponent';
import classes from './style.module.scss';

const InspectorRow = ({ inspector, orderId, refreshHandler }) => {
  const intl = useIntl();

  const date = moment(inspector.date);

  return (
    <>
      <div
        className={classes.line}
        key={inspector.action + inspector.name}
        style={{ paddingBottom: !inspector.observation ? '4em' : '0.5em' }}
      >
        <div className={classes.action} style={{ width: '35%' }}>
          <div className={classes.actionIcon}>
            <Icon action={inspector.action} />
          </div>
          <div className={classes.actionName}>{intl.formatMessage({ id: inspector.action })}</div>
        </div>
        <div style={{ width: '35%' }}>{inspector.name}</div>
        <div style={{ width: '20%' }} title={date.fromNow()}>
          {date.format('DD/MM/YYYY [às] HH:mm')}
        </div>
        <div style={{ width: '10%' }}>
          <OptionsComponent inspector={inspector} orderId={orderId} refreshHandler={refreshHandler} />
        </div>
      </div>
      {inspector.observation && (
        <div className={classes.lineObservation}>
          <div className={classes.action}>
            <div className={classes.observationIcon}></div>
            <div className={classes.actionName}>{inspector.observation}</div>
          </div>
        </div>
      )}
    </>
  );
};

InspectorRow.propTypes = {
  inspector: PropTypes.object.isRequired,
  orderId: PropTypes.string.isRequired,
  refreshHandler: PropTypes.func.isRequired,
};

export default InspectorRow;

import React from 'react';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-text-mask';

import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import Card from 'src/components/common/Card';
import ColorButton from 'src/components/common/elements/ColorButton';
import permissionsImage from 'src/components/common/icons/document-circle.svg';
import userCircleImage from 'src/components/common/icons/user-circle.svg';
import userImage from 'src/components/common/icons/user-permission.svg';
import Spinner from 'src/components/common/Spinner';
import classes from 'src/components/FranchiseeSettingsPage/UserForm/style.module.scss';
import { USERNAME_MASK } from 'src/helpers/mask';
import urlPrefix from 'src/helpers/url-prefix';

const UserFormComponent = ({
  userId,
  features,
  initialValues,
  isUserBusy,
  isFeaturesBusy,
  action,
  intl,
  handleSubmit,
  cpfMask,
  phoneMask,
  cellphoneMask,
  cellphoneMaskLength,
}) => (
  <div className={classes.userForm}>
    {isFeaturesBusy || isUserBusy ? (
      <div className={classes.spinner}>
        <Spinner />
      </div>
    ) : (
      <Card>
        <h2 className={classes.cardHead}>
          <div className={classes.back}>
            <Link to={action === 'edit' ? `${urlPrefix('users')}/${userId}` : urlPrefix('users')}>
              <div className={classes.arrowLeft} />
            </Link>
          </div>

          <div className={classes.icon}>
            <img src={userImage} alt={intl.formatMessage({ id: 'USER' })} />
          </div>

          {action === 'edit'
            ? intl.formatMessage({ id: 'USER_EDITION' })
            : intl.formatMessage({ id: 'USER_REGISTRATION' })}
        </h2>

        <Formik initialValues={{ ...initialValues, test: [] }} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <h3 className={classes.subtitle}>
                <div className={classes.icon}>
                  <img src={userCircleImage} alt={intl.formatMessage({ id: 'USER' })} />
                </div>

                {intl.formatMessage({ id: 'BASIC_DATA' })}
              </h3>

              <div className={classes.field}>
                <label htmlFor="name">{intl.formatMessage({ id: 'fullName' })}*</label>

                <Field name="name">
                  {({ field }) => <input {...field} type="text" id="name" data-cy="name" guide={false} required />}
                </Field>
              </div>

              <div className={classes.field}>
                <label htmlFor="cpf">{intl.formatMessage({ id: 'CPF' })}</label>

                <Field name="cpf">
                  {({ field }) => (
                    <MaskedInput {...field} type="text" id="cpf" data-cy="cpf" guide={false} mask={cpfMask} />
                  )}
                </Field>
              </div>

              <div className={classes.field}>
                <label htmlFor="username">{intl.formatMessage({ id: 'USER' })}*</label>

                <Field name="username">
                  {({ field }) => (
                    <MaskedInput
                      {...field}
                      type="text"
                      id="username"
                      data-cy="username"
                      guide={false}
                      mask={USERNAME_MASK}
                      disabled={action === 'edit'}
                      required
                    />
                  )}
                </Field>
              </div>

              <div className={classes.field}>
                <label htmlFor="email">{intl.formatMessage({ id: 'EMAIL' })}*</label>

                <Field type="email" name="email" id="email" data-cy="email" disabled={action === 'edit'} required />
              </div>

              <div className={classes.field}>
                <label htmlFor="phone">{intl.formatMessage({ id: 'CONTACT' })}</label>

                <Field name="phone">
                  {({ field }) => (
                    <MaskedInput
                      {...field}
                      type="text"
                      id="phone"
                      data-cy="phone"
                      guide={false}
                      mask={value => (value.length >= cellphoneMaskLength ? cellphoneMask : phoneMask)}
                    />
                  )}
                </Field>
              </div>

              <h3 className={classes.subtitle}>
                <div className={classes.icon}>
                  <img src={permissionsImage} alt={intl.formatMessage({ id: 'PERMISSIONS' })} />
                </div>

                {intl.formatMessage({ id: 'PERMISSIONS' })}
              </h3>

              <div className={classes.field}>
                <ul className={classes.permissionList}>
                  {features.map(feature => (
                    <li key={feature.description}>
                      <label title={intl.formatMessage({ id: `PERMISSION.${feature.description}` })}>
                        <Field
                          name="features"
                          data-cy={`feature-${feature.description}`}
                          type="checkbox"
                          value={`${feature.id}`}
                        />

                        {intl.formatMessage({ id: `PERMISSION.${feature.description}` })}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>

              <div className={classes.cardFooter}>
                <ColorButton type="submit" color="green" isBusy={isSubmitting}>
                  {intl.formatMessage({ id: 'SAVE' })}
                </ColorButton>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    )}
  </div>
);

UserFormComponent.propTypes = {
  userId: PropTypes.string,
  features: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  isUserBusy: PropTypes.bool.isRequired,
  isFeaturesBusy: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  cpfMask: PropTypes.array.isRequired,
  phoneMask: PropTypes.array.isRequired,
  cellphoneMask: PropTypes.array.isRequired,
  cellphoneMaskLength: PropTypes.number.isRequired,
};

UserFormComponent.defaultProps = {
  userId: null,
};

export default UserFormComponent;

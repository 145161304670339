import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/PartiesInvolvedSection.module.css';
import InvolvedParties from 'src/svg-icons/InvolvedParties';

function PartiesInvolvedSection(props) {
  let show = false;
  const partiesInvolved = Object.keys(props.partiesInvolved).map((partie, index) => {
    if (props.partiesInvolved[partie].length !== undefined) {
      return props.partiesInvolved[partie].map((person, index) => {
        if (person.name === undefined || person.name === '') {
          return null;
        }
        show = true;
        return (
          <Grid item xs={6} key={index} data-cy={`order-details-partie-person-name-${person.name}`}>
            <div className={classes.SubHeader}>
              <FormattedMessage id={`PERSON.${partie.toUpperCase()}`} /> {index + 1}
            </div>
            <div>{person.name}</div>
          </Grid>
        );
      });
    } else if (props.partiesInvolved[partie].name !== undefined && props.partiesInvolved[partie].name !== '') {
      show = true;
      return (
        <Grid
          item
          xs={6}
          key={index}
          data-cy={`order-details-partie-person-name-${props.partiesInvolved[partie].name}`}
        >
          <div className={classes.SubHeader}>
            <FormattedMessage id={`PERSON.${partie.toUpperCase()}`} />
          </div>
          <div>{props.partiesInvolved[partie].name}</div>
        </Grid>
      );
    } else {
      return null;
    }
  });

  if (show) {
    return (
      <Grid item xs={12}>
        <div className={classes.Header}>
          <InvolvedParties viewBox="0 0 34.016 34.016" className={classes.Icon} />
          <FormattedMessage id="parties" />
        </div>
        <Grid container spacing={16} className={classes.Information} data-cy="order-details-parties-involved">
          {partiesInvolved}
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
}

PartiesInvolvedSection.propTypes = {
  /**
   * Object that contains the partiesInvolved information
   */
  partiesInvolved: PropTypes.object.isRequired,
};

export default PartiesInvolvedSection;

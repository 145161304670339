import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const Costs = props => (
  <SvgIcon {...props} viewBox="0 0 34.016 34.016">
    <g>
      <path
        fill="#393939"
        d="M17.008,2.008c-8.271,0-15,6.729-15,15c0,8.271,6.729,15,15,15c8.271,0,15-6.729,15-15
            C32.008,8.737,25.279,2.008,17.008,2.008z M17.008,30.008c-7.168,0-13-5.832-13-13s5.832
            -13,13-13s13,5.832,13,13S24.176,30.008,17.008,30.008z"
      />
      <path
        fill="#393939"
        d="M17.422,15.83c-2.414-0.631-3.192-1.284-3.192-2.302c0-1.167,1.076-1.981,2.874-1.981
            c1.895,0,2.596,0.911,2.661,2.25h2.354c-0.074-1.841-1.193-3.533-3.418-4.081V7.37h-3.191v2.313
            c-2.065,0.45-3.726,1.798-3.726,3.866c0,2.474,2.033,3.705,5.002,4.424c2.661,0.642,3.192,1.583,3.192,2.58
            c0,0.738-0.521,1.916-2.874,1.916c-2.192,0-3.054-0.985-3.171-2.249h-2.342c0.128,2.346,1.874,3.663,3.917,
            4.103v2.323h3.191v-2.303c2.076-0.395,3.727-1.606,3.727-3.802C22.426,17.501,19.84,16.462,17.422,15.83z"
      />
    </g>
  </SvgIcon>
);
Costs.displayName = 'Costs';
Costs.muiName = 'SvgIcon';

export default Costs;

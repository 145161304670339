import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import Details from 'src/svg-icons/details';

import classes from './style.module.scss';

function LocationReferences({ data }) {
  return (
    <Grid item xs={12}>
      <div className={classes.Header}>
        <Details className={classes.Icon} />
        <FormattedMessage id="solicitations" />
      </div>
      <Grid container spacing={8} className={classes.Information}>
        <Grid item xs={6} data-cy="order-details-facade-installation-date">
          <div className={classes.SubHeader}>
            <FormattedMessage id="facade_installation_date" />
          </div>
          {data.facade_installation_date || '----'}
        </Grid>
        <Grid item xs={6} data-cy="order-details-adhesive-installation-date">
          <div className={classes.SubHeader}>
            <FormattedMessage id="adhesive_installation_date" />
          </div>
          {data.adhesive_installation_date || '----'}
        </Grid>
      </Grid>
      <Grid container spacing={8} className={classes.Information}>
        <Grid item xs={6} data-cy="order-details-neon-installation-date">
          <div className={classes.SubHeader}>
            <FormattedMessage id="neon_installation_date" />
          </div>
          {data.neon_installation_date || '----'}
        </Grid>
      </Grid>
    </Grid>
  );
}

LocationReferences.propTypes = {
  /**
   * Object that contains the address information
   */
  data: PropTypes.object.isRequired,
};

export default LocationReferences;

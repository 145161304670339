import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const AccompaniedInspection = props => (
  <SvgIcon viewBox="0 0 34 34" {...props}>
    <path
      fill="#393939"
      d="M17,2C8.7,2,2,8.7,2,17s6.7,15,15,15s15-6.7,15-15S25.3,2,17,2z M19.6,29.7v-2.1v-5.2h1.7v-6.9
        c0-0.9-0.8-1.7-1.7-1.7h-5.2c-0.9,0-1.7,0.8-1.7,1.7v6.9h1.7v7.3C8.5,28.5,4,23.3,4,17C4,9.8,9.8,
        4,17,4c7.2,0,13,5.8,13,13C30,23.3,25.5,28.5,19.6,29.7z"
    />
    <path
      fill="#393939"
      d="M17,6.9c-1.4,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S18.4,6.9,17,6.9z"
    />
  </SvgIcon>
);

AccompaniedInspection.displayName = 'AccompaniedInspection';
AccompaniedInspection.muiName = 'SvgIcon';

export default AccompaniedInspection;

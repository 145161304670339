import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import PropTypes from 'prop-types';
import * as yup from 'yup';

import API from 'src/apiRequest';
import MakeEditableComponent from 'src/components/MakeEditable/MakeEditableComponent';

const validationSchema = yup.object().shape({
  reason: yup.string().required('Campo obrigatório'),
});

const MakeEditableContainer = ({ orderId, closeModal, refreshHandler }) => {
  const [error, setError] = useState(null);
  const intl = useIntl();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await API.put(`orders/${orderId}/make-editable`, {
        reason: values.reason,
      });
      setSubmitting(false);
      refreshHandler();
      closeModal();
    } catch (err) {
      setSubmitting(false);
      console.log(err?.response || err);
      setError(
        err?.response?.status === 500 || !err?.response?.data?.message
          ? intl.formatMessage({ id: 'ORDER_RETURN_ERROR' })
          : intl.formatMessage({ id: err.response.data.message })
      );
    }
  };

  return (
    <MakeEditableComponent error={error} handleSubmit={handleSubmit} validationSchema={validationSchema} intl={intl} />
  );
};

MakeEditableContainer.propTypes = {
  orderId: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  refreshHandler: PropTypes.func.isRequired,
};

export default MakeEditableContainer;

import React from 'react';
import { FormattedMessage } from 'react-intl';

import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import PropTypes from 'prop-types';

import classes from 'src/components/ClientSteps/ClientSteps.module.css';
import Address from 'src/components/ClientSteps/StepIcons/Address/Address';
import Details from 'src/components/ClientSteps/StepIcons/Details/Details';
import Optionals from 'src/components/ClientSteps/StepIcons/Optionals/Optionals';
import OtherInformations from 'src/components/ClientSteps/StepIcons/OtherInformations/OtherInformations';
import PartiesInvolved from 'src/components/ClientSteps/StepIcons/PartiesInvolved/PartiesInvolved';
import Payment from 'src/components/ClientSteps/StepIcons/Payment/Payment';

function getIconByType(type, active) {
  let icon = null;
  switch (type) {
    case 'franchisees':
      icon = <Details viewBox="0 0 86 86" className={classes.StepIcon} on={active} />;
      break;
    case 'details':
      icon = <Details viewBox="0 0 86 86" className={classes.StepIcon} on={active} />;
      break;
    case 'address':
      icon = <Address viewBox="0 0 86 86" className={classes.StepIcon} on={active} />;
      break;
    case 'parties_involved':
      icon = <PartiesInvolved viewBox="0 0 86 86" className={classes.StepIcon} on={active} />;
      break;
    case 'other_informations':
      icon = <OtherInformations viewBox="0 0 86 86" className={classes.StepIcon} on={active} />;
      break;
    case 'additionals':
      icon = <Optionals viewBox="0 0 86 86" className={classes.StepIcon} on={active} />;
      break;
    case 'payment':
      icon = <Payment viewBox="0 0 86 86" className={classes.StepIcon} on={active} />;
      break;
    default:
      break;
  }
  return icon;
}

function ClientSteps({ activeStep, steps: propSteps }) {
  const steps = propSteps.map((label, index) => {
    const active = String(index === activeStep);
    return (
      <Step key={label}>
        <StepLabel icon={getIconByType(label, active)}>
          <FormattedMessage id={`step.${label}`} />
        </StepLabel>
      </Step>
    );
  });

  return (
    <div className={classes.wrapper}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className={classes.Stepper}
        connector={<StepConnector className={classes.StepConnector} />}
      >
        {steps}
      </Stepper>
      <hr className={classes.hr} />
    </div>
  );
}

ClientSteps.propTypes = {
  /**
   * Array with the steps
   */
  steps: PropTypes.array.isRequired,
  /**
   * Number of the active step
   */
  activeStep: PropTypes.number.isRequired,
};

export default ClientSteps;

import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link, NavLink, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import classes from 'src/components/CommercialPage/CommercialSidebar/style.module.scss';
import plusCircleImage from 'src/components/common/icons/plus-circle.svg';
import usersIcon from 'src/components/common/icons/users.svg';
import { orderType as parseOrderType } from 'src/constants';
import InspectorsIcon from 'src/svg-icons/inspectors';
import SettingsIcon from 'src/svg-icons/menu-settings';

const CommercialSidebar = () => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const page = pathname.split('/')[2];
  const features = JSON.parse(window.sessionStorage.getItem('me'))?.features.map(feature =>
    parseOrderType.get(feature)
  );

  const menu = useMemo(
    () => ({
      clients: {
        icon: <InspectorsIcon />,
        title: intl.formatMessage({ id: 'COMMERCIAL' }),
        link: '/commercial/clients',
        actions: [
          {
            link: '/commercial/clients/new',
            icon: plusCircleImage,
            title: intl.formatMessage({ id: 'ADD_CUSTOMER' }),
          },
        ],
        children: [
          {
            link: '/commercial/clients',
            title: intl.formatMessage({ id: 'CUSTOMERS' }),
            icon: usersIcon,
          },
        ],
      },
      settings: {
        icon: <SettingsIcon />,
        title: intl.formatMessage({ id: 'SETTINGS' }),
        link: '/commercial/settings',
        requiredFeature: 'user',
        actions: [
          {
            link: '/commercial/settings/users/new',
            icon: plusCircleImage,
            title: intl.formatMessage({ id: 'ADD_USER' }),
          },
        ],
        children: [
          {
            link: '/commercial/settings/users',
            icon: usersIcon,
            title: intl.formatMessage({ id: 'USERS' }),
          },
        ],
      },
    }),
    [intl]
  );

  const activeMenu = useMemo(() => menu[page] || null, [menu, page]);

  return (
    <aside className={classes.sideMenu}>
      <h3>
        <span className={classes.icon}>{activeMenu && activeMenu.icon}</span>
        {activeMenu && activeMenu.title}
        {activeMenu && activeMenu.actions && (
          <ul className={classes.actions}>
            {activeMenu.actions.map(action => {
              if (action.requiredFeature && features?.indexOf(action.requiredFeature) < 0) return null;

              return (
                <li className={classes.action} key={action.link} data-tip={action.title}>
                  <Link to={action.link}>
                    <img src={action.icon} alt={action.title} />
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </h3>
      <ul className={classes.menu}>
        {activeMenu &&
          activeMenu.children.map(child => (
            <li key={child.link}>
              <NavLink to={child.link} activeClassName={classes.isActive}>
                <span className={classes.icon}>
                  {typeof child.icon === 'string' ? <img src={child.icon} alt={child.title} /> : child.icon}
                </span>
                {child.title}
              </NavLink>
            </li>
          ))}
      </ul>
      <ul className={`${classes.menu} ${classes.isSecondary}`}>
        {Object.keys(menu).map(key => {
          if (key === page) return null;
          if (menu[key].requiredFeature && features?.indexOf(menu[key].requiredFeature) < 0) return null;

          return (
            <li key={key}>
              {menu[key].link ? (
                <NavLink to={menu[key].link} activeClassName={classes.isActive}>
                  <span className={classes.icon}>{menu[key].icon}</span>
                  {menu[key].title}
                </NavLink>
              ) : (
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault();

                    if (menu[key]?.onClick && typeof menu[key]?.onClick === 'function') {
                      menu[key].onClick();
                    }
                  }}
                >
                  <span className={classes.icon}>{menu[key].icon}</span>
                  {menu[key].title}
                </a>
              )}
            </li>
          );
        })}
      </ul>
      <ReactTooltip effect="solid" />
    </aside>
  );
};

export default CommercialSidebar;

import React from 'react';
import { FormattedMessage } from 'react-intl';

import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  label: {
    fontFamily: 'Open Sans',
    marginTop: '0.3vh',
    fontSize: 'calc(6px + 0.3vw)',
  },
};

function RequestFormWidgetCheckbox(props) {
  return (
    <Grid container alignItems={'center'} alignContent={'center'}>
      <Grid item xs={3}>
        <FormattedMessage id={props.options.checked} />
      </Grid>
      <Grid item xs={2}>
        <Checkbox
          disabled={props.disabled}
          type="checkbox"
          onChange={event =>
            props.onChange(
              event.target.value === props.options.checked ? props.options.unchecked : props.options.checked
            )
          }
          value={props.value === props.options.checked ? props.options.checked : props.options.unchecked}
          checked={props.value === props.options.checked ? true : false}
          color="primary"
          data-cy={String(props.label).toLowerCase()}
        />
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(RequestFormWidgetCheckbox);

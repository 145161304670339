import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const Inspectors = props => (
  <SvgIcon {...props} viewBox="0 0 50 50">
    <path
      fill="#5578A0"
      d="M25,0C11.2,0,0,11.2,0,25c0,13.8,11.2,25,25,25s25-11.2,25-25C50,11.2,38.8,0,25,0z M25,48C12.3,48,2,37.7,2,25
        S12.3,2,25,2c12.7,0,23,10.3,23,23S37.7,48,25,48z"
    />
    <path
      fill="#FFFFFF"
      d="M30.5,27.9h-1l-0.4-0.4c1.3-1.5,2.1-3.5,2.1-5.6c0-4.7-3.8-8.6-8.6-8.6S14,17.2,14,22c0,4.7,3.8,8.6,8.6,8.6
        c2.1,0,4.1-0.8,5.6-2.1l0.4,0.4v1l6.6,6.6l2-2L30.5,27.9z M22.6,27.9c-3.3,0-5.9-2.7-5.9-5.9s2.7-5.9,5.9-5.9s5.9,2.7,5.9,5.9
        S25.9,27.9,22.6,27.9z"
    />
  </SvgIcon>
);

Inspectors.displayName = 'Inspectors';
Inspectors.muiName = 'SvgIcon';

export default Inspectors;

import React, { useMemo } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/DesiredPropertySection.module.css';
import Address from 'src/svg-icons/address';

function DesiredPropertySection({ property }) {
  const { address = {}, real_estate_type, property_code } = property;
  const addressFields = useMemo(
    () => [
      { key: 'zipcode', label: 'zipcode' },
      { key: 'street', label: 'street' },
      { key: 'number', label: 'number' },
      { key: 'complement', label: 'complement' },
      { key: 'neighborhood', label: 'neighborhood' },
      { key: 'city', label: 'city' },
      { key: 'state', label: 'state' },
    ],
    [property]
  );

  const monetaryFields = useMemo(
    () => [
      { key: 'rent_value', label: 'rent_value' },
      { key: 'condominium_value', label: 'condominium_value' },
      { key: 'iptu_value', label: 'iptu_value' },
    ],
    [property]
  );

  // Função para renderizar os campos de valor monetário
  const renderMonetaryFields = () => {
    return monetaryFields.map(field => {
      const value = property[field.key];
      if (value) {
        return (
          <Grid item xs={4} key={field.key}>
            <div className={classes.SubHeader}>
              <FormattedMessage id={field.label} />
            </div>
            <span data-cy={`order-details-address-${field.key}`}>
              <FormattedNumber
                value={value}
                style="currency" // eslint-disable-line react/style-prop-object
                currency="BRL"
              />
            </span>
          </Grid>
        );
      }
      return null;
    });
  };

  return (
    <Grid item xs={12} data-cy="order-details-address">
      <div className={classes.Header}>
        <Address className={classes.Icon} />
        <FormattedMessage id="desired_property" />
      </div>
      <Grid container spacing={8} className={classes.Information}>
        {addressFields.map(
          field =>
            address[field.key] && (
              <Grid item xs={4} key={field.key}>
                <div className={classes.SubHeader}>
                  <FormattedMessage id={field.label} />
                </div>
                <span data-cy={`order-details-address-${field.key}`}>{address[field.key]}</span>
              </Grid>
            )
        )}
        {real_estate_type && (
          <Grid item xs={4}>
            <div className={classes.SubHeader}>
              <FormattedMessage id="real_estate_type" />
            </div>
            <span data-cy="order-details-address-real_estate_type">
              <FormattedMessage id={real_estate_type} />
            </span>
          </Grid>
        )}
        {property_code && (
          <Grid item xs={4}>
            <div className={classes.SubHeader}>
              <FormattedMessage id="property_code" />
            </div>
            <span data-cy="order-details-address-property_code">{property_code}</span>
          </Grid>
        )}
        {renderMonetaryFields()}
      </Grid>
    </Grid>
  );
}

DesiredPropertySection.propTypes = {
  /**
   * Object that contains the property information
   */
  property: PropTypes.object.isRequired,
};

export default DesiredPropertySection;

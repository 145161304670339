import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const CalendarMenu = props => (
  <SvgIcon {...props} viewBox="0 0 1162.2 1162.199">
    <g>
      <path
        fill="#5578A0"
        d="M581.1,0C260.333,0,0,260.333,0,581.1c0,320.768,260.333,581.1,581.1,581.1
        c320.768,0,581.1-260.332,581.1-581.1C1162.2,260.333,901.868,0,581.1,0z M581.1,1115.712
        c-295.199,0-534.612-239.413-534.612-534.612c0-295.198,239.413-534.612,534.612-534.612c295.2,0,534.612,239.414,534.612,534.612
        C1115.712,876.299,876.3,1115.712,581.1,1115.712z"
      />
      <g>
        <path
          fill="#FFFFFF"
          d="M774.015,343.336h-27.559v-55.118h-55.121v55.118H470.864v-55.118h-55.118v55.118h-27.56
          c-30.586,0-54.849,24.8-54.849,55.12l-0.27,385.824c0,30.307,24.532,55.119,55.118,55.119h385.829
          c30.303,0,55.117-24.812,55.117-55.119V398.456C829.132,368.136,804.317,343.336,774.015,343.336z M774.015,784.28H388.186
          V481.131h385.829V784.28z"
        />
        <rect
          x="443.307"
          y="536.251"
          fill="#FFFFFF"
          width="137.796"
          height="137.794"
        />
      </g>
    </g>
  </SvgIcon>
);

CalendarMenu.displayName = 'CalendarMenu';
CalendarMenu.muiName = 'SvgIcon';

export default CalendarMenu;

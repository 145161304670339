import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let Files = props => (
  <SvgIcon {...props}>
    <path
      fill="#4C8FC9"
      d="M8,16h8v2H8V16z M8,12h8v2H8V12z M14,2H6C4.9,2,4,2.9,4,4v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8L14,2zM18,20H6V4h7v5h5V20z"
    />
  </SvgIcon>
);
Files = pure(Files);
Files.displayName = 'Files';
Files.muiName = 'SvgIcon';

export default Files;

import React from 'react';
import { Portal } from 'react-portal';

import PropTypes from 'prop-types';

import classes from './style.module.scss';

const ModalComponent = ({ handleClick, children }) => (
  <Portal>
    <div className={classes.modal}>
      <div className={classes.overlay} onClick={handleClick} />
      <div className={classes.content}>{children}</div>
    </div>
  </Portal>
);

ModalComponent.propTypes = {
  handleClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ModalComponent;

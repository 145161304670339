import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let Details = props => (
  <SvgIcon {...props}>
    <path
      fill="#393939"
      d="M17.008,2.008c-8.271,0-15,6.729-15,15c0,8.271,6.729,15,15,15c8.271,0,15-6.729,15-15
    	C32.008,8.737,25.279,2.008,17.008,2.008z M17.008,30.008c-7.168,0-13-5.832-13-13s5.832-13,13-13s13,5.832,13,13
    	S24.176,30.008,17.008,30.008z"
    />
    <path
      fill="#393939"
      d="M20.645,15.182c1.51,0,2.718-1.218,2.718-2.727s-1.208-2.727-2.718-2.727s-2.728,1.218-2.728,2.727
    	S19.135,15.182,20.645,15.182z M13.372,15.182c1.509,0,2.718-1.218,2.718-2.727s-1.208-2.727-2.718-2.727s-2.727,1.218-2.727,2.727
    	S11.863,15.182,13.372,15.182z M13.372,17.001c-2.119,0-6.363,1.064-6.363,3.182v2.272h12.727v-2.272
    	C19.736,18.065,15.49,17.001,13.372,17.001z M20.645,17.001c-0.264,0-0.564,0.018-0.883,0.045c1.055,0.765,1.792,1.792,1.792,3.137
    	v2.272h5.454v-2.272C27.008,18.065,22.763,17.001,20.645,17.001z"
    />
  </SvgIcon>
);
Details = pure(Details);
Details.displayName = 'Details';
Details.muiName = 'SvgIcon';

export default Details;

import React from 'react';
import { useIntl } from 'react-intl';

import PropTypes from 'prop-types';

import Card from 'src/components/common/Card';
import classes from 'src/components/common/EmptyView/style.module.scss';

const EmptyView = ({ text }) => {
  const intl = useIntl();

  return (
    <div className={classes.emptyView}>
      <Card>
        <p>{text || intl.formatMessage({ id: 'SELECT_AN_ITEM_TO_CONTINUE_MESSAGE' })}</p>
      </Card>
    </div>
  );
};

EmptyView.propTypes = {
  text: PropTypes.string,
};

EmptyView.defaultProps = {
  text: '',
};

export default EmptyView;

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import EmptyView from 'src/components/common/EmptyView';
import UserDetails from 'src/components/FranchiseeSettingsPage/UserDetails';
import UserForm from 'src/components/FranchiseeSettingsPage/UserForm';
import UserList from 'src/components/FranchiseeSettingsPage/UserList';
import Section from 'src/components/Page/Section';

const FranchiseeSettingsPageComponent = () => (
  <>
    <Section cols={6}>
      <Switch>
        <Route exact path="/settings">
          <Redirect to="/settings/users" />
        </Route>
        <Route path="/settings/users" component={UserList} />
      </Switch>
    </Section>
    <Section cols={6}>
      <Switch>
        <Route exact path="/settings/users" component={EmptyView} />
        <Route path="/settings/users/new" render={() => <UserForm action="create" />} />
        <Route path="/settings/users/:id/edit" render={() => <UserForm action="edit" />} />
        <Route exact path="/settings/users/:id" component={UserDetails} />
      </Switch>
    </Section>
  </>
);

export default FranchiseeSettingsPageComponent;

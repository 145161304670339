import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let Empty = props => (
  <SvgIcon {...props}>
    <path
      fill="#D8D8D9"
      d="M43.335,40.546h17.278V36.34l-5.399-2.103v-3.153c0-1.743-0.368-2.103-2.159-2.103h-2.16
			c-1.79,0-2.16,0.361-2.16,2.103v3.153l-5.4,2.103V40.546z"
    />
    <path
      fill="#D8D8D9"
      d="M66.016,32.135h-8.64l5.399,2.103v8.411h-21.6v-8.411l5.4-2.103h-8.639c-1.79,0-3.24,1.413-3.24,3.155
			v34.688c0,1.742,1.45,3.155,3.24,3.155h28.077c1.791,0,3.24-1.413,3.24-3.155h0.001V35.29
			C69.255,33.548,67.807,32.135,66.016,32.135z"
    />
    <path
      fill="#D8D8D9"
      d="M52,4C25.49,4,4,25.49,4,52s21.49,48,48,48s48-21.49,48-48S78.51,4,52,4z M52,98C26.595,98,6,77.405,6,52
  		S26.595,6,52,6s46,20.595,46,46S77.405,98,52,98z"
    />
  </SvgIcon>
);
Empty = pure(Empty);
Empty.displayName = 'Empty';
Empty.muiName = 'SvgIcon';

export default Empty;

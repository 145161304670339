/**
 * @prettier
 **/
import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let ExpandMoreIcon = props => (
  <SvgIcon {...props}>
    <polygon fill="#393939" points="16.5,8.9 5.5,8.9 11,14.9 	" />
    <path
      fill="#393939"
      d="M11,21.5c5.8,0,10.5-4.7,10.5-10.5S16.8,0.5,11,0.5S0.5,5.2,0.5,11S5.2,21.5,11,21.5z M11,2.5
		c4.7,0,8.5,3.8,8.5,8.5s-3.8,8.5-8.5,8.5S2.5,15.7,2.5,11S6.3,2.5,11,2.5z"
    />
  </SvgIcon>
);

ExpandMoreIcon = pure(ExpandMoreIcon);
ExpandMoreIcon.displaName = 'ExpandMoreIcon';
ExpandMoreIcon.muiName = 'SvgIcon';

export default ExpandMoreIcon;

import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/FranchiseeSection/style.module.css';
import Franchisee from 'src/svg-icons/franchisee';

function FranchiseeSection(props) {
  const { franchisees } = props;

  return (
    <Grid item xs={12}>
      <div className={classes.Header}>
        <Franchisee viewBox="0 0 34.016 34.016" className={classes.Icon} />
        <FormattedMessage id="franchisee" />
      </div>
      <div className={classes.Information} data-cy="order-details-franchisee">
        {franchisees.franchisee}
      </div>
    </Grid>
  );
}

FranchiseeSection.propTypes = {
  /**
   * Object that contains the client information
   */
  franchisees: PropTypes.object.isRequired,
};

export default FranchiseeSection;

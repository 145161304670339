import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const AdditionalCosts = props => (
  <SvgIcon viewBox="0 0 34.016 34.016" {...props}>
    <path
      d="M15 0.327881C6.7 0.327881 0 7.02788 0 15.3279C0 23.6279 6.7 30.3279 15 30.3279C23.3 30.3279 30 23.6279 30 15.3279C30 7.02788 23.3 0.327881 15 0.327881ZM15 28.3279C7.8 28.3279 2 22.5279 2 15.3279C2 8.12788 7.8 2.32788 15 2.32788C22.2 2.32788 28 8.12788 28 15.3279C28 22.5279 22.2 28.3279 15 28.3279Z"
      fill="#393939"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 9.32788C8.46957 9.32788 7.96086 9.5386 7.58579 9.91367C7.21071 10.2887 7 10.7974 7 11.3279V15.3279C7 15.8583 7.21071 16.367 7.58579 16.7421C7.96086 17.1172 8.46957 17.3279 9 17.3279V11.3279H19C19 10.7974 18.7893 10.2887 18.4142 9.91367C18.0391 9.5386 17.5304 9.32788 17 9.32788H9ZM11 15.3279C11 14.7974 11.2107 14.2887 11.5858 13.9137C11.9609 13.5386 12.4696 13.3279 13 13.3279H21C21.5304 13.3279 22.0391 13.5386 22.4142 13.9137C22.7893 14.2887 23 14.7974 23 15.3279V19.3279C23 19.8583 22.7893 20.367 22.4142 20.7421C22.0391 21.1172 21.5304 21.3279 21 21.3279H13C12.4696 21.3279 11.9609 21.1172 11.5858 20.7421C11.2107 20.367 11 19.8583 11 19.3279V15.3279ZM17 19.3279C17.5304 19.3279 18.0391 19.1172 18.4142 18.7421C18.7893 18.367 19 17.8583 19 17.3279C19 16.7974 18.7893 16.2887 18.4142 15.9137C18.0391 15.5386 17.5304 15.3279 17 15.3279C16.4696 15.3279 15.9609 15.5386 15.5858 15.9137C15.2107 16.2887 15 16.7974 15 17.3279C15 17.8583 15.2107 18.367 15.5858 18.7421C15.9609 19.1172 16.4696 19.3279 17 19.3279Z"
      fill="#393939"
    />
  </SvgIcon>
);

AdditionalCosts.displayName = 'AdditionalCosts';
AdditionalCosts.muiName = 'SvgIcon';

export default AdditionalCosts;

import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';

import AutoSavingForm from 'src/components/FixAddress/AutoSavingForm';
import classes from 'src/components/FixAddress/style.module.scss';

const FixAddressComponent = ({
  initialValues,
  address,
  states,
  cities,
  neighborhoods,
  predictions,
  validationSchema,
  onCityChange,
  onNeighborhoodChange,
  onStateChange,
  onSubmit,
  selectPrediction,
  addressValidation: { city = false, state = false, neighborhood = false },
  formikRef,
}) => (
  <div className={classes.fixAddress}>
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
      innerRef={formikRef}
    >
      {({ values, errors }) => (
        <Form>
          <div className={classes.row}>
            <p className={classes.info}>Por favor, corrija os campos marcados como incorreto.</p>
          </div>
          <p className={classes.currentAddress}>
            O enredereço recebido foi:&nbsp;
            <strong>{address.neighborhood}</strong>
            ,&nbsp;
            <strong>{address.city}</strong>/<strong>{address.state}</strong>
          </p>
          <div className={classes.row}>
            <div className={classes.state}>
              <Field name="state">
                {({ field, form }) => (
                  <Select
                    {...field}
                    name={field.name}
                    id="state"
                    className={(errors?.state || (errors?.state && state === 'invalid')) && classes.hasError}
                    classNamePrefix="select"
                    placeholder="UF"
                    onChange={option => onStateChange(option, form)}
                    onBlur={field.onBlur}
                    options={states}
                    required
                    noOptionsMessage={() => 'Nenhum estado disponível.'}
                    isClearable
                  />
                )}
              </Field>
              {errors?.state || (errors?.state && state === 'invalid') ? (
                <p className={classes.error}>Esse campo está incorreto.</p>
              ) : null}
            </div>
            <div className={classes.city}>
              <Field name="city">
                {({ field, form }) => (
                  <Select
                    {...field}
                    name={field.name}
                    id="city"
                    className={(errors?.city || (errors?.city && city === 'invalid')) && classes.hasError}
                    classNamePrefix="select"
                    placeholder="Cidade"
                    onChange={option => onCityChange(option, form)}
                    onBlur={field.onBlur}
                    options={cities}
                    required
                    noOptionsMessage={() => 'Nenhuma cidade disponível.'}
                    isClearable
                    maxMenuHeight={150}
                  />
                )}
              </Field>
              {errors?.city || (errors?.city && city === 'invalid') ? (
                <p className={classes.error}>Esse campo está incorreto.</p>
              ) : null}
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.neighborhood}>
              <Field name="neighborhood">
                {({ field, form }) => (
                  <CreatableSelect
                    {...field}
                    name={field.name}
                    id="neighborhood"
                    className={
                      (errors?.neighborhood || (errors?.neighborhood && neighborhood === 'invalid')) && classes.hasError
                    }
                    classNamePrefix="select"
                    placeholder="Bairro"
                    onChange={option => onNeighborhoodChange(option, form)}
                    onBlur={field.onBlur}
                    options={neighborhoods}
                    required
                    noOptionsMessage={() => 'Nenhum bairro disponível.'}
                    isClearable
                    formatCreateLabel={inputValue => `Adicionar o bairro "${inputValue}"`}
                  />
                )}
              </Field>
              {errors?.neighborhood || (errors?.neighborhood && neighborhood === 'invalid') ? (
                <p className={classes.error}>Esse campo está incorreto.</p>
              ) : null}
            </div>
          </div>
          {predictions && predictions.length > 0 ? (
            <div className={classes.row}>
              <div className={classes.predictions}>
                Sugestões:&nbsp;
                {predictions.map(prediction => (
                  <div className={classes.prediction} key={prediction?.neighborhood?.id}>
                    <button type="button" onClick={() => selectPrediction(prediction)}>
                      {`${prediction?.neighborhood?.name}, ${prediction.city.name} - ${prediction.state.name}`}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          <AutoSavingForm values={values} />
        </Form>
      )}
    </Formik>
  </div>
);

FixAddressComponent.propTypes = {
  initialValues: PropTypes.object.isRequired,
  address: PropTypes.object.isRequired,
  states: PropTypes.array,
  cities: PropTypes.array,
  predictions: PropTypes.array,
  neighborhoods: PropTypes.array,
  addressValidation: PropTypes.object,
  validationSchema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onStateChange: PropTypes.func.isRequired,
  onCityChange: PropTypes.func.isRequired,
  onNeighborhoodChange: PropTypes.func.isRequired,
  selectPrediction: PropTypes.func.isRequired,
  formikRef: PropTypes.object.isRequired,
};

FixAddressComponent.defaultProps = {
  states: [],
  cities: [],
  neighborhoods: [],
  predictions: null,
  addressValidation: {},
};

export default FixAddressComponent;

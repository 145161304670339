import React from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import SearchBox from 'src/components/common/SearchBox';
import ActionBox from 'src/components/OrderToolbar/ActionBox/ActionBox';
import classes from 'src/components/OrderToolbar/OrderToolbar.module.css';

const OrderToolbar = ({
  actionHandler,
  searchHandler,
  selectedItem,
  selectedItems,
  clearChosenTypeAndTags,
  history,
}) => {
  const { currentOrder } = useSelector(state => state.orders);

  return (
    <div className={classes.OrderToolbarWrapper}>
      <SearchBox handler={searchHandler} isClientsPageHeader={false} clearChosenTypeAndTags={clearChosenTypeAndTags} />
      <ActionBox
        history={history}
        actionHandler={actionHandler}
        selectedItem={selectedItem || currentOrder}
        selectedItems={selectedItems}
      />
    </div>
  );
};

OrderToolbar.propTypes = {
  history: PropTypes.object.isRequired,
  searchHandler: PropTypes.func.isRequired,
  actionHandler: PropTypes.func.isRequired,
  selectedItems: PropTypes.object.isRequired,
  selectedItem: PropTypes.object,
  clearChosenTypeAndTags: PropTypes.bool,
};

OrderToolbar.defaultProps = {
  clearChosenTypeAndTags: false,
};

export default OrderToolbar;

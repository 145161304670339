import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Select from 'react-select';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Delete, Cancel, PictureAsPdf as Pdf, Input, Unarchive } from '@material-ui/icons';
import Archive from '@material-ui/icons/Archive';
import BorderColor from '@material-ui/icons/BorderColor';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import PropTypes from 'prop-types';

import ReportOptions from 'src/components/ReportOptions/ReportOptions';

const styles = {
  container: {
    marginTop: '1vw',
    marginLeft: '0.5vw',
  },
  newButtomDiv: {
    textAlign: 'right',
  },
  newButton: {
    backgroundColor: '#5882C1',
    color: '#ffffff',
    fontFamily: 'Open Sans',
    fontSize: 'calc(6px + 0.4vw)',
    textTransform: 'none',
    marginRight: '1.5vw',
    paddingLeft: '2vw',
    paddingRight: '2vw',
    height: '2vw',
    minHeight: '32px',
    '&:hover': {
      backgroundColor: '#202F4A',
      borderColor: '#0062cc',
    },
  },
  actionButton: {
    backgroundColor: '#ffffff',
    borderColor: '#D1D1D1',
    marginLeft: '0.5vw',
    height: '2vw',
    minWidth: '64px',
    minHeight: '32px',
    textTransform: 'none',
    padding: '5px 7px',
    fontWeight: '600',
    fontSize: '0.75rem',
    lineHeight: '1',
    cursor: 'pointer!important',
  },
  checkbox: {
    fontSize: '5vw',
    minWidth: '32px',
    minHeight: '16px',
  },
  select: {
    minWidth: '175px',
    fontSize: '12px',
    marginLeft: '0.5vw',
    maxHeight: '2vw',
    height: '100%',
    minHeight: '32px',
  },
};

class ActionsMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      open: false,
    };

    const orderTypeConfigurations = window.sessionStorage.getItem('orderTypeConfigurations');

    this.orderTypeConfigurations = orderTypeConfigurations ? JSON.parse(orderTypeConfigurations) : null;
  }

  loadOrderTypeConfigurations = () => {
    this.orderTypeConfigurations = JSON.parse(window.sessionStorage.getItem('orderTypeConfigurations'));
  };

  handleClick = event => {
    const { currentTarget } = event;
    this.setState(state => ({
      anchorEl: currentTarget,
      open: !state.open,
    }));
  };

  closeReportOptionsHandler = event => {
    const { anchorEl } = this.state;

    if (event !== undefined && anchorEl.contains(event.target)) return;

    this.setState({ open: false });
  };

  deleteHandler = event => {
    const { actionHandler, intl } = this.props;
    const message = intl.formatMessage({ id: 'MESSAGE.DELETE_CONFIRMATION' });
    if (window.confirm(message)) {
      actionHandler(event, 'delete');
    }
  };

  cancelHandler = event => {
    const { actionHandler } = this.props;
    actionHandler(event, 'cancel');
  };

  sendAndCloseHandler = (withPictures, type, label) => {
    const { actionsMenuGeneratePDF } = this.props;
    this.setState({ open: false });
    actionsMenuGeneratePDF(withPictures, type, label);
  };

  render() {
    const { open, anchorEl } = this.state;
    const {
      actionHandler,
      checkAll,
      checked,
      classes,
      currentPage,
      disableCheckbox,
      multipleArchiveAllowed,
      newOrderAction,
      orderId,
      orderStatus,
      orderType,
      flags,
      isArchived,
      orderByDir,
      changeOrderByDir,
      isFromTheSameFranchisee,
    } = this.props;

    const options = [
      { value: 'desc', label: 'Mais recente primeiro' },
      { value: 'asc', label: 'Mais antigo primeiro' },
    ];

    let parentOrderType = orderType;

    if (orderType === 'marketplace_docusign') parentOrderType = 'docusign';
    if (orderType === 'marketplace_fichacerta') parentOrderType = 'fichacerta';

    let configurations = {
      canBeArchived: multipleArchiveAllowed,
    };

    if (this.orderTypeConfigurations === null || Object.keys(this.orderTypeConfigurations).length === 0) {
      this.loadOrderTypeConfigurations();
    }

    if (this.orderTypeConfigurations?.[parentOrderType]?.[orderStatus]) {
      configurations = this.orderTypeConfigurations[parentOrderType][orderStatus];
    }

    return (
      <div>
        <Grid container classes={{ container: classes.container }}>
          <Grid item xs={9}>
            <div style={{ display: 'flex' }}>
              <div>
                <Button
                  variant="outlined"
                  classes={{ root: classes.actionButton }}
                  disableRipple
                  disableFocusRipple
                  disabled={disableCheckbox}
                  onClick={e => checkAll(e, checked ? 'uncheck' : 'check')}
                  data-cy="select-all-orders-button"
                >
                  {checked ? (
                    <CheckBox style={{ fontSize: '1.2vw', minWidth: '25px', minHeight: '20px', marginRight: '3px' }} />
                  ) : (
                    <CheckBoxOutlineBlank
                      style={{ fontSize: '1.2vw', minWidth: '25px', minHeight: '20px', marginRight: '3px' }}
                    />
                  )}
                </Button>

                <Tooltip title={<FormattedMessage id="ACTIONS_MENU.EXPORT" />} placement="bottom">
                  <span>
                    <Button
                      variant="outlined"
                      classes={{ root: classes.actionButton }}
                      disabled={configurations.canBeExported !== true}
                      onClick={e => actionHandler(e, 'export')}
                      data-cy="export-photos-button"
                    >
                      <Input style={{ fontSize: '1.2vw', minWidth: '25px', minHeight: '20px', marginRight: '3px' }} />

                      <FormattedMessage id="ACTIONS_MENU.EXPORT" />
                    </Button>
                  </span>
                </Tooltip>

                <Tooltip title={<FormattedMessage id="ACTIONS_MENU.GENERATE_PDF" />} placement="bottom">
                  <span>
                    <Button
                      variant="outlined"
                      classes={{ root: classes.actionButton }}
                      disabled={configurations.canGenerateReport !== true}
                      onClick={this.handleClick}
                      data-cy="export-report-button"
                    >
                      <Pdf style={{ fontSize: '1.2vw', minWidth: '25px', minHeight: '20px', marginRight: '3px' }} />

                      <FormattedMessage id="ACTIONS_MENU.GENERATE_PDF" />
                    </Button>
                  </span>
                </Tooltip>

                <Tooltip title={<FormattedMessage id="ACTIONS_MENU.EDIT" />} placement="bottom">
                  <span>
                    <Button
                      variant="outlined"
                      classes={{ root: classes.actionButton }}
                      disabled={configurations.canBeEdited !== true || !isFromTheSameFranchisee}
                      onClick={e => actionHandler(e, 'edit')}
                      data-cy="edit-order-button"
                    >
                      <BorderColor
                        style={{ fontSize: '1.2vw', minWidth: '25px', minHeight: '20px', marginRight: '3px' }}
                      />

                      <FormattedMessage id="ACTIONS_MENU.EDIT" />
                    </Button>
                  </span>
                </Tooltip>

                {currentPage === 'independent' ? (
                  <Tooltip title={<FormattedMessage id="ACTIONS_MENU.DELETE" />} placement="bottom">
                    <span>
                      <Button
                        variant="outlined"
                        classes={{ root: classes.actionButton }}
                        disabled={configurations.canBeDeleted !== true || !isFromTheSameFranchisee}
                        onClick={e => this.deleteHandler(e)}
                        data-cy="delete-order-button"
                      >
                        <Delete
                          style={{ fontSize: '1.2vw', minWidth: '25px', minHeight: '20px', marginRight: '3px' }}
                        />
                      </Button>
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title={<FormattedMessage id="ACTIONS_MENU.CANCEL" />} placement="bottom">
                    <span>
                      <Button
                        variant="outlined"
                        classes={{ root: classes.actionButton }}
                        disabled={configurations.canBeCanceled !== true || !isFromTheSameFranchisee}
                        onClick={e => this.cancelHandler(e)}
                        data-cy="cancel-order-button"
                      >
                        <Cancel
                          style={{ fontSize: '1.2vw', minWidth: '25px', minHeight: '20px', marginRight: '3px' }}
                        />

                        <FormattedMessage id="ACTIONS_MENU.CANCEL" />
                      </Button>
                    </span>
                  </Tooltip>
                )}

                {currentPage === 'archived' || isArchived ? (
                  <Tooltip title={<FormattedMessage id="ACTIONS_MENU.UNARCHIVE" />} placement="bottom">
                    <span>
                      <Button
                        variant="outlined"
                        classes={{ root: classes.actionButton }}
                        disabled={
                          configurations.canBeArchived !== true ||
                          orderStatus === 'FINISHED' ||
                          !isFromTheSameFranchisee
                        }
                        onClick={e => actionHandler(e, 'unarchive')}
                        data-cy="unarchive-order-button"
                      >
                        <Unarchive
                          style={{ fontSize: '1.2vw', minWidth: '25px', minHeight: '20px', marginRight: '3px' }}
                        />

                        <FormattedMessage id="ACTIONS_MENU.UNARCHIVE" />
                      </Button>
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title={<FormattedMessage id="ACTIONS_MENU.ARCHIVE" />} placement="bottom">
                    <span>
                      <Button
                        variant="outlined"
                        classes={{ root: classes.actionButton }}
                        disabled={
                          configurations.canBeArchived !== true ||
                          orderStatus === 'FINISHED' ||
                          !isFromTheSameFranchisee
                        }
                        onClick={e => actionHandler(e, 'archive')}
                        data-cy="archive-order-button"
                      >
                        <Archive
                          style={{ fontSize: '1.2vw', minWidth: '25px', minHeight: '20px', marginRight: '3px' }}
                        />

                        <FormattedMessage id="ACTIONS_MENU.ARCHIVE" />
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </div>

              <Select
                value={options?.find(option => option.value === orderByDir || '')}
                options={options}
                onChange={e => changeOrderByDir(e)}
                styles={{
                  control: base => ({ ...base, ...styles.select }),
                  dropdownIndicator: base => ({ ...base, ...{ padding: '6px' } }),
                  option: base => ({ ...base, ...{ fontSize: '12px' } }),
                }}
              />
            </div>
          </Grid>

          <Grid item xs={3} classes={{ item: classes.newButtomDiv }}>
            <Button
              variant="outlined"
              size="small"
              classes={{ root: classes.newButton }}
              onClick={newOrderAction}
              data-cy="new-order-button"
            >
              <FormattedMessage id="ACTIONS_MENU.NEW_ORDER" />
            </Button>
          </Grid>
        </Grid>

        {configurations.reportsOptions && orderId && (
          <ReportOptions
            show={open}
            closeHandler={this.closeReportOptionsHandler}
            anchorEl={anchorEl}
            options={configurations.reportsOptions}
            entityId={orderId}
            flags={flags}
            disablePortal
          />
        )}
      </div>
    );
  }
}

ActionsMenu.propTypes = {
  checkAll: PropTypes.func.isRequired,
  newOrderAction: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  disableCheckbox: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  currentPage: PropTypes.string,
  actionHandler: PropTypes.func.isRequired,
  multipleArchiveAllowed: PropTypes.bool.isRequired,
  actionsMenuGeneratePDF: PropTypes.func,
  orderType: PropTypes.string,
  orderId: PropTypes.string,
  orderStatus: PropTypes.string,
  flags: PropTypes.object,
  isArchived: PropTypes.bool.isRequired,
  changeOrderByDir: PropTypes.func.isRequired,
  orderByDir: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  isFromTheSameFranchisee: PropTypes.bool.isRequired,
};

ActionsMenu.defaultProps = {
  actionsMenuGeneratePDF: undefined,
  currentPage: null,
  orderId: undefined,
  orderType: undefined,
  orderStatus: undefined,
  flags: null,
};

export default withStyles(styles)(injectIntl(ActionsMenu));

import React from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';

import classes from 'src/components/ScreenLoading/style.module.scss';

const ScreenLoading = () => (
  <div className={classes.screenLoading}>
    <LinearProgress />
  </div>
);

export default ScreenLoading;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let TypeAvaliacaoHousi = props => (
  <SvgIcon {...props} fill="none" viewBox="0 0 34.016 34.016">
    <path
      d="M13.0463 3.7576V1.81221C13.0463 0.747218 12.8049 0.52002 11.6973 0.52002H10.6466C9.52476 0.52002 9.29756 0.747218 9.29756 1.81221V3.7576L5.91799 5.04979V7.63417H16.4117V5.04979L13.0463 3.7576Z"
      fill="#58002A"
    />
    <path
      d="M13.3303 13.2431C14.3811 11.7521 16.1135 10.7723 18.0589 10.7723H18.1157C19.2659 10.7865 20.3593 11.1273 21.2823 11.7521V4.31139C21.2681 3.2322 20.4303 2.38021 19.4079 2.38021H14.4379L17.5335 3.6582V9.12516H4.427V4.0416L7.73557 2.7636H2.62361C1.55862 2.7494 0.692423 3.6014 0.678223 4.66639V25.597C0.692423 26.662 1.55862 27.514 2.62361 27.4998H19.3511C20.3735 27.514 21.2255 26.7046 21.2823 25.6964L19.2375 22.2459C18.8967 22.3169 18.5417 22.3453 18.1867 22.3453C18.1157 22.3453 18.0589 22.3453 18.0163 22.3453C17.4767 22.3453 16.9371 22.2601 16.4259 22.1039V25.4834H12.1659V21.3087H10.0502V25.4834H5.97479V19.4485L6.75578 18.8663L11.3282 15.4157L12.2937 16.1399C12.3221 15.7707 12.3789 15.4015 12.4783 15.0465L11.3282 14.1945L5.12279 18.8663L3.4898 17.6451L11.3282 11.7379L13.3303 13.2431ZM18.0589 11.5959C17.9595 11.5959 17.8601 11.5959 17.7465 11.6101C17.8601 11.6101 17.9737 11.5959 18.1015 11.5959C18.1015 11.5959 18.0873 11.5959 18.0589 11.5959Z"
      fill="#58002A"
    />
    <path
      d="M21.2823 20.5419L21.2113 20.4283C21.2255 20.4141 21.2539 20.3857 21.2823 20.3715C22.3331 19.4627 22.9721 18.1563 23.0147 16.7647V16.6085C23.0289 15.0749 22.3473 13.7117 21.2823 12.7887C20.4303 12.0645 19.3227 11.6101 18.1015 11.5959C17.9879 11.5959 17.8743 11.5959 17.7465 11.6101C17.6329 11.6101 17.5193 11.6243 17.3915 11.6385C17.3205 11.6527 17.2495 11.6527 17.1785 11.6669C17.0365 11.6953 16.8945 11.7237 16.7667 11.7663C16.6247 11.8089 16.4969 11.8373 16.3549 11.8941C15.8579 12.0787 15.4035 12.3343 14.9917 12.6467C14.9349 12.6893 14.8781 12.7319 14.8213 12.7887C14.7503 12.8739 14.6651 12.9449 14.5657 13.0301C14.3527 13.2573 14.1539 13.4845 13.9835 13.7401C13.6001 14.2939 13.3303 14.9187 13.2025 15.6003C13.1457 15.8985 13.1173 16.1967 13.1031 16.5091C13.1031 16.5943 13.1031 16.6653 13.1031 16.7505C13.1741 18.8237 14.5373 20.5703 16.4117 21.2377C16.9087 21.4081 17.4483 21.5075 18.0163 21.5217C18.0731 21.5217 18.1299 21.5217 18.1867 21.5217H18.2293C18.4281 21.5217 18.6269 21.5075 18.8115 21.4791C18.8825 21.4649 18.9535 21.4649 19.0245 21.4365C19.2233 21.4081 19.4221 21.3513 19.6067 21.2945L21.2681 24.0919L22.5177 26.1792L24.0513 25.2136L21.2823 20.5419ZM17.1359 19.7325C16.8803 19.6615 16.6389 19.5621 16.4259 19.4343C15.9857 19.1929 15.6307 18.8521 15.3609 18.4545C15.0343 17.9717 14.8355 17.4037 14.7929 16.8073C14.7645 16.4523 14.8071 16.0973 14.9065 15.7281C15.0059 15.3731 15.1621 15.0607 15.3609 14.7767C16.1277 13.6691 17.5477 13.1153 18.9109 13.4987C20.6291 13.9815 21.6231 15.7849 21.1403 17.5031C20.6433 19.2213 18.8541 20.2153 17.1359 19.7325Z"
      fill="#58002A"
    />
  </SvgIcon>
);

TypeAvaliacaoHousi = pure(TypeAvaliacaoHousi);
TypeAvaliacaoHousi.displayName = 'TypeAvaliacaoHousi';
TypeAvaliacaoHousi.muiName = 'SvgIcon';

export default TypeAvaliacaoHousi;

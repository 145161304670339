import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import classes from 'src/components/FeatureModal/FeatureModal.module.css';
import RedeSeguros from 'src/components/FeatureModal/Icons/rede-confiax.png';

class FeatureModal extends Component {
  constructor(props) {
    super(props);
    const { intl } = this.props;

    const showModal =
      document.cookie.replace(/(?:(?:^|.*;\s*)IKnowAboutRedeSeguros\s*=\s*([^;]*).*$)|^.*$/, '$1') !== 'true';

    this.state = {
      open: showModal,
      features: {
        visitaDigital: {
          title: intl.formatMessage({ id: 'REDE_SEGUROS_TITLE_MESSAGE' }),
          description: intl.formatMessage({ id: 'REDE_SEGUROS_BODY_MESSAGE' }),
          img: RedeSeguros,
          external: 'https://seguros.redevistorias.com.br',
        },
      },
    };
  }

  handleClose = () => {
    document.cookie = 'IKnowAboutRedeSeguros=true; expires=Fri, 31 Dec 2030 23:59:59 GMT';
    this.setState({ open: false });
  };

  render() {
    const { open, features } = this.state;
    const { feature, intl } = this.props;

    let instructions = null;
    if (features[feature] && features[feature].external !== null) {
      instructions = (
        <p>
          {intl.formatMessage({ id: 'CLICK' })}
          &nbsp;
          <a href={features[feature].external} target="_blank" rel="noopener noreferrer">
            <b>{intl.formatMessage({ id: 'HERE' })}</b>
          </a>
          &nbsp;
          {intl.formatMessage({ id: 'TO_KNOW_MORE' })}.
        </p>
      );
    }

    return (
      <Dialog open={open} aria-labelledby="responsive-dialog-title" maxWidth="md">
        <Grid container alignItems="center" className={classes.Dialog}>
          <Grid item xs={4} className={classes.ImageWrapper}>
            <img src={features[feature].img} alt="Feature" />
          </Grid>
          <Grid item xs={8}>
            <DialogTitle id="responsive-dialog-title">{features[feature].title}</DialogTitle>
            <DialogContent className={classes.ContentWrapper}>
              <DialogContentText>
                {features[feature].description}
                <br />
                <br />
                <div>{instructions}</div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={this.handleClose}>
                {intl.formatMessage({ id: 'OK' })}
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

FeatureModal.propTypes = {
  feature: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(FeatureModal);

import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import PropTypes from 'prop-types';

import FeatureSwitchComponent from 'src/components/common/FeatureSwitch/FeatureSwitchComponent';

const FeatureSwitchContainer = ({ feature, children }) => {
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    const me = JSON.parse(sessionStorage.getItem('me'));
    setFeatures(me.features);
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [features]);

  if (feature) {
    if (Array.isArray(feature)) {
      const intersect = (a, b) => a.filter(Set.prototype.has, new Set(b));
      if (intersect(feature, features).length < 1) return null;
    } else if (typeof feature === 'string' && feature !== '') {
      if (features.indexOf(feature) === -1) return null;
    }
  }

  return <FeatureSwitchComponent key={1}>{children}</FeatureSwitchComponent>;
};

FeatureSwitchContainer.propTypes = {
  children: PropTypes.node,
  feature: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

FeatureSwitchContainer.defaultProps = {
  children: null,
  feature: null,
};

export default FeatureSwitchContainer;

import React from 'react';

import PropTypes from 'prop-types';

import AddMedia from '../AddMedia';
import ColorButton from '../common/elements/ColorButton';
import classes from './style.module.scss';


const AddMediaModalComponent = ({ isSubmitting, isValid, intl, onChange, onSubmit }) => (
  <div className={classes.addMediaModal}>
    <AddMedia onChange={onChange} key="add-media" />
    <div className={`${classes.field} ${classes.actions}`}>
      <ColorButton type="button" color="green" disabled={!isValid} isBusy={isSubmitting} onClick={onSubmit}>
        {intl.formatMessage({ id: 'SEND' })}
      </ColorButton>
    </div>
  </div>
);

AddMediaModalComponent.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AddMediaModalComponent;

import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import API from 'src/apiRequest';
import UpdateUserPermissionsComponent from 'src/components/ClientsPage/UpdateUserPermissions/UpdateUserPermissionsComponent';
import { useUser } from 'src/contexts/UserContext';
import { masksByCountry } from 'src/helpers/mask';
import { showToast } from 'src/store/ducks/toasts';

const UpdateUserPermissionsContainer = () => {
  const [isBusy, setIsBusy] = useState(true);
  const [isClientUserFormSubmitting, setIsClientUserFormSubmitting] = useState(false);
  const [isClientUserDeleteConfirmationVisible, setIsClientUserDeleteConfirmationVisible] = useState(false);
  const [clientUser, setClientUser] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissionsIds, setSelectedPermissionsIds] = useState([]);

  const dispatch = useDispatch();
  const { push } = useHistory();
  const intl = useIntl();
  const { id, status, userId } = useParams();

  const me = useUser();
  const cpfMask = masksByCountry[me?.profile?.country || 'BR'].CPF_MASK;
  const cpfMaskPlaceholder = masksByCountry[me?.profile?.country || 'BR'].CPF_MASK_PLACEHOLDER;
  const phoneMask = masksByCountry[me?.profile?.country || 'BR'].PHONE_MASK;
  const phoneMaskPlaceholder = masksByCountry[me?.profile?.country || 'BR'].PHONE_MASK_PLACEHOLDER;
  const cellphoneMask = masksByCountry[me?.profile?.country || 'BR'].CELLPHONE_MASK;
  const cellphoneMaskLength = masksByCountry[me?.profile?.country || 'BR'].CELLPHONE_MASK_LENGTH;

  useEffect(() => {
    async function fetchClientUserPermissions() {
      const alreadySelectedPermissionsIds = [];

      try {
        setIsBusy(true);

        const clientUserPermissionsResponse = await API.get(`/clients/${id}/users/${userId}`);

        setClientUser(clientUserPermissionsResponse.data);

        setPermissions(
          clientUserPermissionsResponse.data?.features?.map(feature => {
            if (feature?.selected) {
              alreadySelectedPermissionsIds.push(feature?.id);
            }

            return {
              id: feature?.id,
              description: intl.formatMessage({ id: `PERMISSION.${feature?.description}` }),
              selected: feature?.selected,
            };
          })
        );

        setSelectedPermissionsIds(alreadySelectedPermissionsIds);
      } catch {
        dispatch(
          showToast({
            type: 'error',
            title: intl.formatMessage({ id: 'ERROR' }),
            text: intl.formatMessage({ id: 'PERMISSIONS_LOAD_DATA_ERROR' }),
            duration: 5000,
          })
        );
      } finally {
        setIsBusy(false);
      }
    }

    fetchClientUserPermissions();
  }, [dispatch, id, intl, userId]);

  const handleSelectedPermissionsChange = useCallback(event => {
    event.persist();

    if (event?.target?.checked) {
      setSelectedPermissionsIds(previousSelectedPermissionsIds => [
        ...previousSelectedPermissionsIds,
        Number(event?.target?.value),
      ]);
    } else {
      setSelectedPermissionsIds(previousSelectedPermissionsIds =>
        previousSelectedPermissionsIds.filter(selectedPermission => selectedPermission !== Number(event?.target?.value))
      );
    }
  }, []);

  const handleClientUserFormSubmit = useCallback(async () => {
    if (!selectedPermissionsIds.length) {
      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'SELECT_AT_LEAST_ONE_PERMISSION_MESSAGE' }),
          duration: 5000,
        })
      );

      return;
    }

    try {
      setIsClientUserFormSubmitting(true);

      await API.put(`clients/${id}/users/${userId}`, {
        username: clientUser?.username,
        email: clientUser?.email,
        name: clientUser?.name,
        cpf: clientUser?.cpf,
        phone: clientUser?.phone,
        features: selectedPermissionsIds,
      });

      dispatch(
        showToast({
          type: 'success',
          title: intl.formatMessage({ id: 'SUCCESS' }),
          text: intl.formatMessage({ id: 'SAVE_SELECTED_PERMISSIONS_SUCCESS' }),
          duration: 5000,
        })
      );

      push(`/clients/${status}/${id}/users/`);
    } catch {
      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'SAVE_SELECTED_PERMISSIONS_ERROR' }),
          duration: 5000,
        })
      );
    } finally {
      setIsClientUserFormSubmitting(false);
    }
  }, [clientUser, dispatch, id, intl, push, selectedPermissionsIds, status, userId]);

  const handleToggleClientUserDeleteConfirmation = useCallback(() => {
    setIsClientUserDeleteConfirmationVisible(!isClientUserDeleteConfirmationVisible);
  }, [isClientUserDeleteConfirmationVisible]);

  const handleClientUserDeleteButtonClick = useCallback(async () => {
    try {
      await API.delete(`clients/${id}/users/${userId}`);

      dispatch(
        showToast({
          type: 'success',
          title: intl.formatMessage({ id: 'SUCCESS' }),
          text: intl.formatMessage({ id: 'USER_DELETE_SUCCESS' }),
          duration: 5000,
        })
      );

      push(`/clients/${status}/${id}/users/`);
    } catch {
      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'USER_DELETE_ERROR' }),
          duration: 5000,
        })
      );
    }
  }, [dispatch, id, intl, push, status, userId]);

  const onChange = evt => {
    const { name, value } = evt.target;

    setClientUser({ ...clientUser, [name]: value });
  };

  return (
    <UpdateUserPermissionsComponent
      isBusy={isBusy}
      isClientUserFormSubmitting={isClientUserFormSubmitting}
      isClientUserDeleteConfirmationVisible={isClientUserDeleteConfirmationVisible}
      permissions={permissions}
      handleSelectedPermissionsChange={handleSelectedPermissionsChange}
      handleClientUserDeleteButtonClick={handleClientUserDeleteButtonClick}
      handleClientUserFormSubmit={handleClientUserFormSubmit}
      handleToggleClientUserDeleteConfirmation={handleToggleClientUserDeleteConfirmation}
      intl={intl}
      user={clientUser}
      onChange={onChange}
      cpfMask={cpfMask}
      cpfMaskPlaceholder={cpfMaskPlaceholder}
      phoneMask={phoneMask}
      phoneMaskPlaceholder={phoneMaskPlaceholder}
      cellphoneMask={cellphoneMask}
      cellphoneMaskLength={cellphoneMaskLength}
    />
  );
};

export default UpdateUserPermissionsContainer;

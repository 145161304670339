import React from 'react';

import PropTypes from 'prop-types';

import classes from 'src/components/CommercialPage/ClientDetails/style.module.scss';
import Card from 'src/components/common/Card';
import clientImage from 'src/components/common/icons/client-circle.svg';
import detailsImage from 'src/components/common/icons/client-details.svg';
import contactImage from 'src/components/common/icons/contact-circle.svg';
import Spinner from 'src/components/common/Spinner';
import AddressIcon from 'src/svg-icons/address';
import ClientIcon from 'src/svg-icons/client';

const ClientDetails = ({ busy, client, intl }) =>
  busy ? (
    <div className={classes.clientDetails}>
      <div className={classes.spinner}>
        <Spinner />
      </div>
    </div>
  ) : (
    client && (
      <div className={classes.clientDetails}>
        <Card className="fade-in">
          <h2 className={classes.cardHead}>
            <div className={classes.icon}>
              <img src={detailsImage} alt={intl.formatMessage({ id: 'DETAILS' })} />
            </div>
            {intl.formatMessage({ id: 'DETAILS' })}
          </h2>
          <h3 className={classes.subtitle}>
            <div className={classes.icon}>
              <img src={clientImage} alt={intl.formatMessage({ id: 'CUSTOMER' })} />
            </div>
            {intl.formatMessage({ id: 'CUSTOMER' })}
          </h3>
          <div className={`${classes.field}`}>
            <p className={classes.label}>{intl.formatMessage({ id: 'CUSTOMER_TYPE' })}</p>
            <p className={classes.value}>
              {client?.client_type === 'pj'
                ? intl.formatMessage({ id: 'LEGAL_PERSON' })
                : intl.formatMessage({ id: 'NATURAL_PERSON' })}
            </p>
          </div>
          <div className={classes.field}>
            {client?.client_type === 'pj' ? (
              <p className={classes.label}>{intl.formatMessage({ id: 'FANTASY_NAME' })}</p>
            ) : (
              <p className={classes.label}>{intl.formatMessage({ id: 'NAME' })}</p>
            )}
            <p className={classes.value}>{client?.name}</p>
          </div>
          <div className={`${classes.field} ${classes.isFlex}`}>
            {client?.client_type === 'pj' ? (
              <>
                <div className={`${classes.field} ${classes.is6}`}>
                  <p className={classes.label}>{intl.formatMessage({ id: 'CNPJ' })}</p>
                  <p className={classes.value}>{client?.document}</p>
                </div>
                <div className={`${classes.field} ${classes.is6}`}>
                  <p className={classes.label}>{intl.formatMessage({ id: 'COMPANY_NAME' })}</p>
                  <p className={classes.value}>{client?.company_name}</p>
                </div>
              </>
            ) : (
              <div className={`${classes.field} ${classes.is6}`}>
                <p className={classes.label}>{intl.formatMessage({ id: 'CPF' })}</p>
                <p className={classes.value}>{client?.document}</p>
              </div>
            )}
          </div>
          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'USER' })}</p>
              <p className={classes.value}>{client?.username}</p>
            </div>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'EMAIL_ADDRESS' })}</p>
              <p className={classes.value}>{client?.contact.email}</p>
            </div>
          </div>
          {client?.client_type === 'pj' && (
            <>
              <h3 className={classes.subtitle}>
                <div className={classes.icon}>
                  <ClientIcon />
                </div>
                {intl.formatMessage({ id: 'RESPONSIBLE' })}
              </h3>
              <div className={classes.field}>
                <p className={classes.label}>{intl.formatMessage({ id: 'NAME' })}</p>
                <p className={classes.value}>{client?.responsible.name}</p>
              </div>
              <div className={`${classes.field} ${classes.isFlex}`}>
                <div className={`${classes.field} ${classes.is4}`}>
                  <p className={classes.label}>{intl.formatMessage({ id: 'CPF' })}</p>
                  <p className={classes.value}>{client?.responsible.document}</p>
                </div>
                <div className={`${classes.field} ${classes.is4}`}>
                  <p className={classes.label}>{intl.formatMessage({ id: 'PHONE' })}</p>
                  <p className={classes.value}>{client?.responsible.phone || '-----'}</p>
                </div>
                <div className={`${classes.field} ${classes.is4}`}>
                  <p className={classes.label}>{intl.formatMessage({ id: 'CELLPHONE' })}</p>
                  <p className={classes.value}>{client?.responsible.cellphone || '-----'}</p>
                </div>
              </div>
              <div className={classes.field}>
                <p className={classes.label}>{intl.formatMessage({ id: 'EMAIL' })}</p>
                <p className={classes.value}>{client?.responsible.email}</p>
              </div>
            </>
          )}
          <h3 className={classes.subtitle}>
            <div className={classes.icon}>
              <AddressIcon />
            </div>
            {intl.formatMessage({ id: 'CUSTOMER_ADDRESS' })}
          </h3>
          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'CEP' })}</p>
              <p className={classes.value}>{client?.address.zipcode}</p>
            </div>
          </div>
          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'STREET' })}</p>
              <p className={classes.value}>{client?.address.street}</p>
            </div>
            <div className={`${classes.field} ${classes.is3}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'NUMBER' })}</p>
              <p className={classes.value}>{client?.address.number || '-----'}</p>
            </div>
            <div className={`${classes.field} ${classes.is3}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'COMPLEMENT' })}</p>
              <p className={classes.value}>{client?.address.complement}</p>
            </div>
          </div>
          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is5}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'NEIGHBORHOOD' })}</p>
              <p className={classes.value}>{client?.address.neighborhood}</p>
            </div>
            <div className={`${classes.field} ${classes.is5}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'CITY' })}</p>
              <p className={classes.value}>{client?.address.city}</p>
            </div>
            <div className={`${classes.field} ${classes.is2}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'STATE' })}</p>
              <p className={classes.value}>{client?.address.state}</p>
            </div>
          </div>
          <h3 className={classes.subtitle}>
            <div className={classes.icon}>
              <img src={contactImage} alt={intl.formatMessage({ id: 'CONTACT' })} />
            </div>
            {intl.formatMessage({ id: 'CONTACT' })}
          </h3>
          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'PHONE' })}</p>
              <p className={classes.value}>{client?.contact.phone || '-----'}</p>
            </div>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'CELLPHONE' })}</p>
              <p className={classes.value}>{client?.contact.cellphone || '-----'}</p>
            </div>
          </div>
        </Card>
      </div>
    )
  );

ClientDetails.propTypes = {
  busy: PropTypes.bool.isRequired,
  client: PropTypes.object,
  intl: PropTypes.object.isRequired,
};

ClientDetails.defaultProps = {
  client: null,
};

export default ClientDetails;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let StatusPremium = props => (
  <SvgIcon {...props}>
    <path
      d="M16.993,1.914c-8.332,0-15.079,6.762-15.079,15.094c0,8.331,6.748,15.095,15.079,15.095
	c8.347,0,15.109-6.764,15.109-15.095C32.102,8.676,25.34,1.914,16.993,1.914z M23.393,26.063l-6.385-3.848l-6.384,3.848l1.69-7.26
	l-5.63-4.875l7.426-0.634l2.897-6.853l2.899,6.838l7.426,0.634l-5.631,4.875L23.393,26.063z"
    />
  </SvgIcon>
);
StatusPremium = pure(StatusPremium);
StatusPremium.displayName = 'StatusPremium';
StatusPremium.muiName = 'SvgIcon';

export default StatusPremium;

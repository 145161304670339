import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let MenuDispositivos = props => (
  <SvgIcon {...props}>
    <path
      fill="#FFFFFF"
      d="M35.5,16.337h-21c-1.284,0-2.333,1.05-2.333,2.333v12.66c0,1.283,1.049,2.332,2.333,2.332h21
  		c1.283,0,2.334-1.049,2.334-2.332V18.671C37.834,17.388,36.783,16.337,35.5,16.337z M33.167,31.33H16.833V18.671h16.334V31.33z
  		 M35.5,26.08c-0.598,0-1.08-0.482-1.08-1.081c0-0.595,0.482-1.079,1.08-1.079c0.596,0,1.08,0.484,1.08,1.079
  		C36.58,25.598,36.096,26.08,35.5,26.08z"
    />
    <path
      fill="#5578A0"
      d="M25,0C11.193,0,0,11.193,0,25c0,13.809,11.193,25,25,25c13.807,0,25-11.191,25-25C50,11.193,38.807,0,25,0z
  		 M25,48C12.297,48,2,37.703,2,25S12.297,2,25,2c12.702,0,23,10.297,23,23S37.702,48,25,48z"
    />
  </SvgIcon>
);
MenuDispositivos = pure(MenuDispositivos);
MenuDispositivos.displayName = 'MenuDispositivos';
MenuDispositivos.muiName = 'SvgIcon';

export default MenuDispositivos;

import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import API from 'src/apiRequest';
import ClientAssignFormComponent from 'src/components/CommercialPage/ClientAssignForm/ClientAssignFormComponent';
import { reloadCards } from 'src/store/ducks/cards';
import { showToast } from 'src/store/ducks/toasts';

const ClientAssignFormContainer = () => {
  const [clientWithNoFranchisee, setClientWithNoFranchisee] = useState(null);
  const [franchisees, setFranchisees] = useState([]);
  const [mailable, setMailable] = useState(false);
  const [selectedFranchisee, setSelectedFranchisee] = useState(null);
  const [busy, setBusy] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const { id: clientId } = useParams();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleChangeFranchisee = useCallback(franchisee => {
    setSelectedFranchisee(franchisee);
  }, []);

  const handleChangeMailable = useCallback(() => {
    setMailable(!mailable);
  }, [mailable]);

  const fetchClientWithNoFranchiseeById = useCallback(async () => {
    setBusy(true);

    try {
      const { data } = await API.get(`/clients/${clientId}`);

      setClientWithNoFranchisee(data);
    } catch (err) {
      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'CUSTOMER_DATA_LOAD_ERROR' }),
          duration: 5000,
        })
      );
    } finally {
      setBusy(false);
    }
  }, [clientId, dispatch, intl]);

  const fetchFranchisees = useCallback(async () => {
    setBusy(true);

    try {
      const { data } = await API.get('/franchisees');

      let formattedFranchisees = [];

      if (Array.isArray(data)) {
        formattedFranchisees = data.map(franchisee => ({
          value: franchisee?.id,
          label: franchisee?.name,
        }));
      }

      setFranchisees(formattedFranchisees);
    } catch (err) {
      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'FRANCHISES_LOAD_DATA_ERROR' }),
          duration: 5000,
        })
      );
    } finally {
      setBusy(false);
    }
  }, [dispatch, intl]);

  const handleFormSubmit = useCallback(
    async event => {
      event.preventDefault();

      if (!selectedFranchisee) {
        dispatch(
          showToast({
            type: 'error',
            title: intl.formatMessage({ id: 'ERROR' }),
            text: intl.formatMessage({ id: 'SELECT_ONE_FRANCHISE_MESSAGE' }),
            duration: 5000,
          })
        );

        return;
      }

      setRequestInProgress(true);

      try {
        const assignmentData = {
          client_id: clientWithNoFranchisee?.id,
          mailable,
        };

        await API.post(`/franchisees/${selectedFranchisee?.value}/clients`, assignmentData);

        dispatch(reloadCards());
        dispatch(
          showToast({
            type: 'success',
            title: intl.formatMessage({ id: 'SUCCESS' }),
            text: intl.formatMessage({ id: 'CUSTOMER_ASSIGNMENT_SUCCESS' }),
            duration: 5000,
          })
        );

        push('/commercial/clients');
      } catch (err) {
        dispatch(
          showToast({
            type: 'error',
            title: intl.formatMessage({ id: 'ERROR' }),
            text: intl.formatMessage({ id: 'CUSTOMER_ASSIGNMENT_ERROR' }),
            duration: 5000,
          })
        );
      } finally {
        setRequestInProgress(false);
      }
    },
    [clientWithNoFranchisee, dispatch, intl, mailable, push, selectedFranchisee]
  );

  useEffect(() => {
    setMailable(false);

    fetchClientWithNoFranchiseeById();
    fetchFranchisees();
  }, [fetchClientWithNoFranchiseeById, fetchFranchisees]);

  return (
    <ClientAssignFormComponent
      busy={busy}
      requestInProgress={requestInProgress}
      client={clientWithNoFranchisee}
      franchisees={franchisees}
      handleChangeFranchisee={handleChangeFranchisee}
      handleChangeMailable={handleChangeMailable}
      onSubmit={handleFormSubmit}
      intl={intl}
    />
  );
};

export default ClientAssignFormContainer;

/**
 * Removes duplicate objects from an array, producing an array of unique items.
 *
 * @param {Array} duplicatedItems The array containing duplicated items.
 * @param {string} uniqueKey The property key to determine uniqueness.
 * @returns {Array} An array containing unique items.
 */
const removeDuplicates = (duplicatedItems = [], uniqueKey = 'name') => {
  try {
    const uniqueItems = [];
    const seen = {};

    for (const item of duplicatedItems) {
      if (!seen[item?.[uniqueKey]]) {
        uniqueItems.push(item);
        seen[item?.[uniqueKey]] = true;
      }
    }

    return uniqueItems;
  } catch (err) {
    console.debug('[DEBUG] removeDuplicates error ', err);

    return [];
  }
};

export default removeDuplicates;

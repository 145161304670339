import React from 'react';
import { FormattedMessage } from 'react-intl';

import PropTypes from 'prop-types';

import Radio from 'src/components/common/elements/Radio';
import classes from 'src/orderForm/widgets/MultipleRadioWidget/style.module.scss';

const MultipleRadioWidget = ({ disabled, id, onChange, options, value }) => (
  <div className={classes.multipleRadioWidget}>
    <div className={classes.field}>
      <div className={classes.title}>{options?.label ? <FormattedMessage id={options?.label} /> : ''}</div>

      <div className={classes.optionList}>
        {options?.enumOptions?.map(option => (
          <div className={classes.option} key={`${id}-${option?.value}`}>
            <label data-cy={`${id}-${option?.label}`}>
              <Radio
                name={id}
                className={classes.radio}
                id={`${id}-${option?.value}`}
                data-cy={`${id}-${option?.value}`}
                value={option?.value?.toString()}
                checked={value === option?.value}
                onChange={event => onChange(event.target.value)}
                disabled={disabled}
              />

              <FormattedMessage id={option?.label} />
            </label>
          </div>
        ))}
      </div>
    </div>
  </div>
);

MultipleRadioWidget.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  value: PropTypes.string,
};

MultipleRadioWidget.defaultProps = {
  disabled: false,
  value: null,
};

export default MultipleRadioWidget;

import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import AutoLink from 'src/components/AutoLink';
import classes from 'src/orderDetails/ClientSection.module.css';
import AdditionalCosts from 'src/svg-icons/AdditionalCosts';

const AdditionalCostsSection = ({ additionalCosts }) => {
  return (
    <Grid item xs={12}>
      <div className={classes.Header}>
        <AdditionalCosts className={classes.Icon} />

        <FormattedMessage id="additionalCosts" />
      </div>

      <Grid container spacing={8} className={classes.Information}>
        <Grid item xs={12}>
          <div className={classes.SubHeader}>
            <FormattedMessage id="price" />
          </div>

          <span data-cy="additional-costs-price">{additionalCosts?.price} créditos</span>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.SubHeader}>
            <FormattedMessage id="description" />
          </div>

          {additionalCosts?.description && (
            <AutoLink text={additionalCosts?.description} data-cy="additional-costs-description" />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

AdditionalCostsSection.propTypes = {
  additionalCosts: PropTypes.object.isRequired,
};

export default AdditionalCostsSection;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let TypeFichaCerta = props => (
  <SvgIcon {...props} fill="none" viewBox="0 0 42 48">
    <path
      d="M22.5 5.73333V2.33333C22.5 0.4 22.1 0 20.1 0H18.2334C16.2334 0 15.8334 0.4 15.8334 2.33333V5.8L9.83337 8.13333V12.6667H28.5V8.06667L22.5 5.73333Z"
      fill="#00A385"
    />
    <path
      d="M10.3001 31.6665L17.4335 38.1998C16.5668 39.4665 16.1001 40.9998 16.1001 42.5998H6.43347C6.43347 38.4665 7.90014 34.6665 10.3001 31.6665Z"
      fill="#00A385"
    />
    <path
      d="M18.4333 37L11.9667 29.8666C14.8333 27.1333 18.6333 25.4 22.7667 25.1333L23.2333 34.7333C21.3667 34.9333 19.7 35.7333 18.4333 37Z"
      fill="#00A385"
    />
    <path
      d="M27.1668 39.6666C26.3668 38.9999 25.4334 38.5999 24.3668 38.5332L26.7001 36.1999C26.8334 36.0666 27.1668 36.1999 27.1668 36.3999V39.6666Z"
      fill="#00A385"
    />
    <path
      d="M28.1667 42.6H19.8334C20.1667 40.6666 21.7667 39.2 23.7667 39.1333C23.8334 39.1333 23.9667 39.1333 24.0334 39.1333C25.2334 39.1333 26.3667 39.6666 27.1667 40.5333C27.7 41.0666 28.0334 41.8 28.1667 42.6Z"
      fill="#00A385"
    />
    <path
      d="M29.5668 37C28.3001 35.7333 26.6335 34.9333 24.7668 34.7333L25.2334 25.1333C29.4334 25.4 33.1668 27.2 36.0334 29.8666L29.5668 37Z"
      fill="#00A385"
    />
    <path d="M30.5001 38.2L37.1667 32.1333V42.6H31.9001C31.9001 41 31.3667 39.4666 30.5001 38.2Z" fill="#00A385" />
    <path
      d="M37.1667 6.7335V28.5335C24.8334 16.7335 3.30006 26.4668 4.70006 44.3335H37.1667V44.6668C37.1001 46.5335 35.5667 48.0002 33.7001 48.0002H3.96673C2.10006 48.0002 0.500061 46.5335 0.500061 44.6002V7.40016C0.500061 5.46683 2.10006 4.00016 3.96673 4.00016H13.0334L7.16673 6.26683V15.3335H30.5001V5.60016L24.9667 3.3335H33.8334C35.6334 3.3335 37.1667 4.86683 37.1667 6.7335Z"
      fill="#00A385"
    />
    <path
      d="M37.6334 31.6666L37.1667 32.1333L30.5 38.2C31.3667 39.4666 31.8333 41 31.8333 42.6H41.4333C41.5 38.4666 40.0334 34.6666 37.6334 31.6666ZM25.2333 25.1333L24.7667 34.7333C26.6334 34.9333 28.3 35.7333 29.5667 37L36.0334 29.8666C33.1667 27.2 29.3667 25.4 25.2333 25.1333ZM24.0333 39.1333C23.9667 39.1333 23.8334 39.1333 23.7667 39.1333C21.8334 39.2666 20.1667 40.7333 19.8334 42.6H28.1667C28.0333 41.8 27.6334 41.0666 27.1 40.4666C26.3667 39.6666 25.2334 39.1333 24.0333 39.1333ZM27.1667 36.4C27.1667 36.2 26.8333 36.0666 26.7 36.2L24.3667 38.5333C25.3667 38.6 26.3667 39 27.1667 39.6666V36.4ZM22.7667 25.1333C18.5667 25.4 14.7667 27.2 11.9667 29.8666L18.4333 37C19.7 35.7333 21.3667 34.9333 23.2333 34.7333L22.7667 25.1333ZM6.43335 42.6H16.0333C16.0333 40.9333 16.5667 39.4 17.3667 38.2L10.2334 31.6666C7.90002 34.6666 6.43335 38.4666 6.43335 42.6Z"
      fill="#00A385"
    />
    <path
      d="M17.4333 38.1998C16.5666 39.4665 16.1 40.9998 16.1 42.5998H6.43329C6.43329 38.4665 7.89996 34.6665 10.3 31.6665L17.4333 38.1998Z"
      fill="#00A385"
    />
    <path
      d="M23.2333 34.7333C21.3666 34.9333 19.6999 35.7333 18.4333 37L11.9666 29.8666C14.8333 27.1333 18.6333 25.4 22.7666 25.1333L23.2333 34.7333Z"
      fill="#00A385"
    />
    <path
      d="M36.0333 29.8666L29.5666 37C28.2999 35.7333 26.6333 34.9333 24.7666 34.7333L25.2333 25.1333C29.3666 25.4 33.1666 27.2 36.0333 29.8666Z"
      fill="#00A385"
    />
    <path
      d="M41.4999 42.5998H31.8999C31.8999 40.9332 31.3666 39.3998 30.5666 38.1998L37.2333 32.1332L37.6999 31.6665C40.0333 34.6665 41.4999 38.4665 41.4999 42.5998Z"
      fill="#00A385"
    />
    <path
      d="M27.1665 36.3999V39.6666C26.3665 38.9999 25.4332 38.5999 24.3665 38.5332L26.6998 36.1999C26.8332 36.0666 27.1665 36.1999 27.1665 36.3999Z"
      fill="#00A385"
    />
    <path
      d="M28.1665 42.6H19.8331C20.1665 40.6666 21.7665 39.2 23.7665 39.1333C23.8331 39.1333 23.9665 39.1333 24.0331 39.1333C25.2331 39.1333 26.3665 39.6666 27.1665 40.5333C27.6998 41.0666 28.0331 41.8 28.1665 42.6Z"
      fill="#00A385"
    />
  </SvgIcon>
);

TypeFichaCerta = pure(TypeFichaCerta);
TypeFichaCerta.displayName = 'TypeFichaCerta';
TypeFichaCerta.muiName = 'SvgIcon';

export default TypeFichaCerta;

import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import classes from 'src/components/ClientsPage/PackagePurchase/style.module.scss';
import Card from 'src/components/common/Card';
import recordsImage from 'src/components/common/icons/records.svg';
import userImage from 'src/components/common/icons/user-circle.svg';
import Spinner from 'src/components/common/Spinner';

const PackagePurchaseComponent = ({ id, intl, loading, busy, onChange, onSubmit, packages, status }) =>
  loading ? (
    <div className={classes.spinner}>
      <Spinner />
    </div>
  ) : (
    <div className={classes.packagePurchase}>
      <Card className="fade-in">
        <h2 className={classes.cardHead}>
          <div className={classes.back}>
            <Link to={`../${id}`}>
              <div className={classes.arrowLeft} />
            </Link>
          </div>
          <div className={classes.icon}>
            <img src={recordsImage} alt={intl.formatMessage({ id: 'EXTRACT' })} />
          </div>
          {intl.formatMessage({ id: 'ADD_PACKAGE' })}
        </h2>
        <h3 className={classes.subtitle}>
          <div className={classes.icon}>
            <img src={userImage} alt={intl.formatMessage({ id: 'CUSTOMER' })} />
          </div>
          {intl.formatMessage({ id: 'AVAILABLE_PACKAGES' })}
        </h3>
        <form onSubmit={onSubmit}>
          <ul className={classes.packageList}>
            {packages.map(item => (
              <li className={classes.package} key={item.id}>
                <input type="radio" name="package" value={item.id} id={`pacote-${item.id}`} onChange={onChange} />
                <label htmlFor={`pacote-${item.id}`}>
                  <div className={classes.packageName}>{item.name}</div>
                  <div className={classes.packageArea}>{item.credits}</div>
                </label>
              </li>
            ))}
          </ul>
          <div className={classes.cardFooter}>
            <Link to={`/clients/${status}/${id}`} className={classes.button}>
              {intl.formatMessage({ id: 'CANCEL' })}
            </Link>
            <button type="submit" className={`${classes.button} ${classes.isGreen}`} disabled={busy}>
              {intl.formatMessage({ id: 'ADD' })}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );

PackagePurchaseComponent.propTypes = {
  id: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  busy: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  packages: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
};

export default PackagePurchaseComponent;

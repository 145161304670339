import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const Annotation = props => (
  <SvgIcon {...props} viewBox="0 0 25 25">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3843 3.0998C7.2583 3.0998 3.0998 7.26534 3.0998 12.4076C3.0998 17.5498 7.2583 21.7153 12.3843 21.7153C17.5103 21.7153 21.6688 17.5498 21.6688 12.4076C21.6688 7.26534 17.5103 3.0998 12.3843 3.0998ZM1.5498 12.4076C1.5498 6.41267 6.39889 1.5498 12.3843 1.5498C18.3697 1.5498 23.2188 6.41267 23.2188 12.4076C23.2188 18.4024 18.3697 23.2653 12.3843 23.2653C6.39889 23.2653 1.5498 18.4024 1.5498 12.4076Z"
      fill="#393939"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.164 11.8362C19.164 15.2604 16.1358 18.0362 12.4004 18.0362C11.1392 18.0362 9.95875 17.7198 8.94817 17.1689L5.63672 18.0362L6.76819 15.2704C6.05336 14.2873 5.63672 13.1063 5.63672 11.8362C5.63672 8.41206 8.6649 5.63623 12.4004 5.63623C16.1358 5.63623 19.164 8.41206 19.164 11.8362ZM9.86399 10.9505H8.17308V12.7219H9.86399V10.9505ZM16.6276 10.9505H14.9367V12.7219H16.6276V10.9505ZM11.5549 10.9505H13.2458V12.7219H11.5549V10.9505Z"
      fill="#393939"
    />
  </SvgIcon>
);

Annotation.displayName = 'Notes';
Annotation.muiName = 'SvgIcon';

export default Annotation;

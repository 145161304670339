import React from 'react';

import { withTheme } from '@rjsf/core';
import PropTypes from 'prop-types';

import FormComponent from 'src/components/common/Form/FormComponent';
import Theme from 'src/components/common/Form/Theme';

const ThemedForm = withTheme(Theme);

const FormContainer = ({ schema, ...props }) => <FormComponent Form={ThemedForm} schema={schema} {...props} />;

FormContainer.propTypes = {
  schema: PropTypes.object.isRequired,
};

export default FormContainer;

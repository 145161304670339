import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/AccessInformationSection/style.module.scss';
import KeysAddressSection from 'src/orderDetails/KeysAddressSection';
import AccessInformation from 'src/svg-icons/AccessInformation';

const AccessInformationSection = ({ data }) => (
  <Grid item xs={12}>
    <div className={classes.header}>
      <AccessInformation className={classes.icon} />
      <FormattedMessage id="accessInformation" />
    </div>
    <Grid container spacing={8} className={classes.information}>
      {!!data.type && (
        <Grid item xs={12} data-cy="order-details-access-info-type">
          <div className={classes.subHeader}>
            <FormattedMessage id="keysLocationRadio" />
          </div>
          <span>
            <FormattedMessage id={data.type} />
          </span>

          {(data.type === 'Outro' || data.type === 'Na imobiliária') && !!data.address && (
            <KeysAddressSection address={data.address} />
          )}
        </Grid>
      )}
      {!!data.keysDevolutionType && (
        <Grid item xs={12} data-cy="order-details-access-info-type">
          <div className={classes.subHeader}>
            <FormattedMessage id="keysDevolutionType" />
          </div>
          <span>
            <FormattedMessage id={data.keysDevolutionType} />
          </span>

          {(data.keysDevolutionType === 'Outro' || data.keysDevolutionType === 'Na imobiliária') && !!data.keysDevolutionAddress && (
            <KeysAddressSection address={data.keysDevolutionAddress} />
          )}
        </Grid>
      )}
      {!!data.keysLocation && (
        <Grid item xs={12} data-cy="order-details-keys-location">
          <div className={classes.subHeader}>
            <FormattedMessage id="keysLocation" />
          </div>
          <span>{data.keysLocation}</span>
        </Grid>
      )}
      <Grid item xs={12} data-cy="order-details-extra-instructions">
        <div className={classes.subHeader}>
          <FormattedMessage id="extraInstructions" />
        </div>

        {data.extraInstructions === true ? <FormattedMessage id="Yes" /> : <FormattedMessage id="No" />}
      </Grid>
      {!!data.extraInstructions && (
        <Grid item xs={12} data-cy="order-details-keys-location">
          <div className={classes.subHeader}>
            <FormattedMessage id="instructions" />
          </div>
          <span>{data.instructions}</span>
        </Grid>
      )}
    </Grid>
  </Grid>
);

AccessInformationSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AccessInformationSection;

import React, { useCallback, useEffect } from 'react';

import PropTypes from 'prop-types';

import ModalComponent from 'src/components/common/Modal/ModalComponent';

const ModalContainer = ({ onClose, ...props }) => {
  const handleKeyUp = useCallback(
    evt => {
      if (evt.key === 'Escape') {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyUp]);

  return <ModalComponent handleClick={onClose} handleKeyUp={handleKeyUp} {...props} />;
};

ModalContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ModalContainer;

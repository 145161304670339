import React from 'react';

import PropTypes from 'prop-types';

import classes from 'src/components/CommercialPage/CommercialLayout/style.module.scss';
import CommercialSidebar from 'src/components/CommercialPage/CommercialSidebar';
import PageHeader from 'src/components/common/PageHeader';

const CommercialLayout = ({ children }) => (
  <div className={classes.page}>
    <PageHeader />
    <div className={classes.container}>
      <CommercialSidebar />
      <main className={classes.content}>{children}</main>
    </div>
  </div>
);

CommercialLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CommercialLayout;

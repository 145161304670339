import React from 'react';
import { useIntl } from 'react-intl';

import classes from 'src/components/common/Form/DefaultChildren/style.module.scss';

const DefaultChildren = () => {
  const intl = useIntl();

  return (
    <button type="submit" className={classes.button}>
      {intl.formatMessage({ id: 'SAVE' })}
    </button>
  );
};

export default DefaultChildren;

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/AccompaniedInspectionSection/style.module.scss';
import AccompaniedInspection from 'src/svg-icons/AccompaniedInspection';
import Whatsapp from 'src/svg-icons/whatsapp';

const AccompaniedInspectionSection = ({ data }) => {
  const openWhatsapp = () => {
    const phone = data.companionContact.replace(/\D/g, '');
    window.open(`https://wa.me/+55${phone}`, '_blank');
  };
  return (
    <Grid item xs={12}>
      <div className={classes.header}>
        <AccompaniedInspection className={classes.icon} />
        <FormattedMessage id="accompaniedInspection" />
      </div>
      <Grid container spacing={8} className={classes.information}>
        <Grid item xs={8} data-cy="order-details-is-accompanied">
          <div className={classes.subHeader}>
            <FormattedMessage id="isAccompanied" />
          </div>
          {data.isAccompanied ? <FormattedMessage id="Yes" /> : <FormattedMessage id="No" />}
        </Grid>
        {data.scheduleDate ? (
          <Grid item xs={4} data-cy="order-details-schedule-date">
            <div className={classes.subHeader}>
              <FormattedMessage id="scheduleDateView" />
            </div>
            {data.scheduleDate}
          </Grid>
        ) : null}
        {data.companionName ? (
          <Grid item xs={8} data-cy="order-details-companion-name">
            <div className={classes.subHeader}>
              <FormattedMessage id="companionName" />
            </div>
            {data.companionName}
          </Grid>
        ) : null}
        {data.companionContact ? (
          <Grid item xs={4} data-cy="order-details-companion-contact">
            <div className={classes.subHeader}>
              <FormattedMessage id="phoneContact" />
            </div>
            {data.companionContact}
            <div className={classes.whatsapp} onClick={openWhatsapp}>
              <Whatsapp style={{ verticalAlign: 'middle', marginRight: '5px', fontSize: '18px' }} />
              Abrir no WhatsApp Web
            </div>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};

AccompaniedInspectionSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AccompaniedInspectionSection;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import PropTypes from 'prop-types';

import Card from 'src/components/common/Card';
import accountFilterIcon from 'src/components/common/icons/account-filter.svg';
import formationImage from 'src/components/common/icons/client-formation.svg';
import contactImage from 'src/components/common/icons/contact-circle.svg';
import databaseIcon from 'src/components/common/icons/database.svg';
import editImage from 'src/components/common/icons/edit.svg';
import enableImage from 'src/components/common/icons/enable.svg';
import importImage from 'src/components/common/icons/file-export.svg';
import detailsIcon from 'src/components/common/icons/inspector-details.svg';
import metricsIcon from 'src/components/common/icons/inspector-metrics.svg';
import keyIcon from 'src/components/common/icons/key.svg';
import trashImage from 'src/components/common/icons/trash.svg';
import moreInformationImage from 'src/components/common/icons/user-circle-add.svg';
import Spinner from 'src/components/common/Spinner';
import classes from 'src/components/InspectorsPage/InspectorDetails/style.module.scss';
import { BASE_URL } from 'src/config';
import formatSeconds from 'src/helpers/format-seconds';
import orderIcon from 'src/helpers/order-icon';
import AddressIcon from 'src/svg-icons/address';
import Client from 'src/svg-icons/client';

const InspectorDetailsComponent = ({
  isBusy,
  isSupportModeActive,
  isDeleteConfirmationVisible,
  isEnableConfirmationVisible,
  isExportDatabaseConfirmationVisible,
  inspector,
  inspectorId,
  hideDeleteConfirmation,
  hideEnableConfirmation,
  hideExportDatabaseConfirmation,
  showDeleteConfirmation,
  showEnableConfirmation,
  showExportDatabaseConfirmation,
  deleteInspector,
  enableInspector,
  exportDatabase,
  intl,
}) =>
  isBusy ? (
    <div className={classes.spinner}>
      <Spinner />
    </div>
  ) : (
    <div className={classes.inspectorDetails}>
      <Card className="fade-in">
        <div className={classes.toolbar}>
          {!isDeleteConfirmationVisible && !isExportDatabaseConfirmationVisible && !isEnableConfirmationVisible && (
            <div className={classes.actions}>
              {
                inspector?.status === 'active' &&
                <Link to={`/inspectors/${inspector?.status}/${inspectorId}/edit`} data-tip={intl.formatMessage({ id: 'EDIT' })}>
                  <img src={editImage} alt={intl.formatMessage({ id: 'EDIT' })} />
                </Link>
              }
              <Link
                to={`/inspectors/${inspector?.status}/${inspectorId}/report`}
                data-tip={intl.formatMessage({ id: 'REPORT_EXPORT' })}
              >
                <img src={importImage} alt={intl.formatMessage({ id: 'DEVICES_DATA_MIGRATION' })} />
              </Link>
              {inspector?.status === 'active' && isSupportModeActive() && (
                <button
                  type="button"
                  data-tip={intl.formatMessage({ id: 'EXPORT_DATABASE' })}
                  onClick={showExportDatabaseConfirmation}
                >
                  <img src={databaseIcon} alt={intl.formatMessage({ id: 'EXPORT_DATABASE' })} />
                </button>
              )}
              {inspector?.status === 'active' && (
                <button
                  type="button"
                  data-tip={intl.formatMessage({ id: 'INSPECTOR_DELETE' })}
                  onClick={showDeleteConfirmation}
                >
                  <img src={trashImage} alt={intl.formatMessage({ id: 'INSPECTOR_DELETE' })} />
                </button>
              )}
              {inspector?.status === 'deleted' && (
                <button
                  type="button"
                  data-tip={intl.formatMessage({ id: 'ACTIVATE' })}
                  onClick={showEnableConfirmation}
                >
                  <img src={enableImage} alt={intl.formatMessage({ id: 'ACTIVATE' })} />
                </button>
              )}
            </div>
          )}
          {isDeleteConfirmationVisible && (
            <div className={classes.confirm}>
              <div className={classes.text}>{intl.formatMessage({ id: 'WANT_TO_DELETE_INSPECTOR_MESSAGE' })}</div>
              <div className={classes.actions}>
                <button type="button" onClick={hideDeleteConfirmation}>
                  {intl.formatMessage({ id: 'NO' })}
                </button>
                <button type="button" onClick={deleteInspector}>
                  {intl.formatMessage({ id: 'YES' })}
                </button>
              </div>
            </div>
          )}
          {isEnableConfirmationVisible && (
            <div className={classes.confirm}>
              <div className={classes.text}>{intl.formatMessage({ id: 'WANT_TO_ENABLE_INSPECTOR_MESSAGE' })}</div>
              <div className={classes.actions}>
                <button type="button" onClick={hideEnableConfirmation}>
                  {intl.formatMessage({ id: 'NO' })}
                </button>
                <button type="button" onClick={enableInspector}>
                  {intl.formatMessage({ id: 'YES' })}
                </button>
              </div>
            </div>
          )}
          {isExportDatabaseConfirmationVisible && (
            <div className={classes.confirm}>
              <div className={classes.text}>{intl.formatMessage({ id: 'WANT_TO_EXPORT_DATABASE_MESSAGE' })}</div>
              <div className={classes.actions}>
                <button type="button" onClick={hideExportDatabaseConfirmation}>
                  {intl.formatMessage({ id: 'NO' })}
                </button>
                <button type="button" onClick={exportDatabase}>
                  {intl.formatMessage({ id: 'YES' })}
                </button>
              </div>
            </div>
          )}
        </div>
      </Card>
      {inspector?.details && (
        <Card className="fade-in">
          <h2 className={classes.cardHead}>
            <div className={classes.icon}>
              <img src={detailsIcon} alt={intl.formatMessage({ id: 'DETAILS' })} />
            </div>
            {intl.formatMessage({ id: 'DETAILS' })}
          </h2>
          <h3 className={classes.subtitle}>
            <div className={classes.icon}>
              <Client />
            </div>
            {intl.formatMessage({ id: 'PERSONAL_DATA' })}
          </h3>
          <div className={classes.field}>
            <p className={classes.label}>{intl.formatMessage({ id: 'NAME' })}</p>
            <p className={classes.value}>{inspector?.details?.name || '-----'}</p>
          </div>
          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'CPF' })}</p>
              <p className={classes.value}>{inspector?.details?.document || '-----'}</p>
            </div>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'RG' })}</p>
              <p className={classes.value}>{inspector?.details?.rg || '-----'}</p>
            </div>
          </div>
          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'BIRTH_DATE' })}</p>
              <p className={classes.value}>{inspector?.details?.birth_date || '-----'}</p>
            </div>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'GENRE' })}</p>
              <p className={classes.value}>{inspector?.details?.genre || '-----'}</p>
            </div>
          </div>
          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'MARITAL_STATUS' })}</p>
              <p className={classes.value}>{inspector?.details?.marital_status || '-----'}</p>
            </div>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'NUMBER_OF_CHILDREN' })}</p>
              <p className={classes.value}>{inspector?.details?.children || '-----'}</p>
            </div>
          </div>
          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'USERNAME' })}</p>
              <p className={classes.value}>{inspector?.details?.username || '-----'}</p>
            </div>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'PROFILE_IMAGE' })}</p>
              {inspector?.details?.avatar ? (
                <img
                  className="mr-2"
                  width={50}
                  height={50}
                  src={BASE_URL + '/api/images/' + inspector?.details?.avatar}
                  alt={intl.formatMessage({ id: 'PROFILE_IMAGE' })}
                />
              ) : (
                <p className={classes.value}>-----</p>
              )}
            </div>
          </div>
          <h3 className={classes.subtitle}>
            <div className={classes.icon}>
              <img src={contactImage} alt={intl.formatMessage({ id: 'CONTACT' })} />
            </div>
            {intl.formatMessage({ id: 'CONTACT' })}
          </h3>
          <div className={classes.field}>
            <p className={classes.label}>{intl.formatMessage({ id: 'EMAIL_ADDRESS' })}</p>
            <p className={classes.value}>{inspector?.details?.contact?.email || '-----'}</p>
          </div>
          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'PHONE' })}</p>
              <p className={classes.value}>{inspector?.details?.contact.phone || '-----'}</p>
            </div>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'CELLPHONE' })}</p>
              <p className={classes.value}>{inspector?.details?.contact.cellphone || '-----'}</p>
            </div>
          </div>
          <h3 className={classes.subtitle}>
            <div className={classes.icon}>
              <AddressIcon />
            </div>
            {intl.formatMessage({ id: 'ADDRESS' })}
          </h3>
          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'CEP' })}</p>
              <p className={classes.value}>{inspector?.details?.address?.zipcode || '-----'}</p>
            </div>
          </div>
          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'STREET' })}</p>
              <p className={classes.value}>{inspector?.details?.address?.street || '-----'}</p>
            </div>
            <div className={`${classes.field} ${classes.is3}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'NUMBER' })}</p>
              <p className={classes.value}>{inspector?.details?.address?.number || '-----'}</p>
            </div>
            <div className={`${classes.field} ${classes.is3}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'COMPLEMENT' })}</p>
              <p className={classes.value}>{inspector?.details?.address?.complement || '-----'}</p>
            </div>
          </div>
          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is5}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'NEIGHBORHOOD' })}</p>
              <p className={classes.value}>{inspector?.details?.address?.neighborhood || '-----'}</p>
            </div>
            <div className={`${classes.field} ${classes.is5}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'CITY' })}</p>
              <p className={classes.value}>{inspector?.details?.address?.city || '-----'}</p>
            </div>
            <div className={`${classes.field} ${classes.is2}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'STATE' })}</p>
              <p className={classes.value}>{inspector?.details?.address?.state || '-----'}</p>
            </div>
          </div>
          <h3 className={classes.subtitle}>
            <div className={classes.icon}>
              <img src={formationImage} alt={intl.formatMessage({ id: 'FORMATION' })} />
            </div>
            {intl.formatMessage({ id: 'FORMATION' })}
          </h3>
          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'EDUCATION' })}</p>
              <p className={classes.value}>{inspector?.details?.academic_background?.education || '-----'}</p>
            </div>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'COURSE' })}</p>
              <p className={classes.value}>{inspector?.details?.academic_background?.course || '-----'}</p>
            </div>
          </div>
          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'HAS_TECHNICAL_SPECIALIZATION' })}</p>
              <p className={classes.value}>
                {inspector?.details?.academic_background?.has_technical_expertise || '-----'}
              </p>
            </div>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'instructions' })}</p>
              <p className={classes.value}>{inspector?.details?.academic_background?.technical_expertise || '-----'}</p>
            </div>
          </div>
          <h3 className={classes.subtitle}>
            <div className={classes.icon}>
              <img src={moreInformationImage} alt={intl.formatMessage({ id: 'MORE_INFORMATION' })} />
            </div>
            {intl.formatMessage({ id: 'MORE_INFORMATION' })}
          </h3>
          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'HAS_EXPERIENCE' })}</p>
              <p className={classes.value}>{inspector?.details?.extra_info?.experience || '-----'}</p>
            </div>
          </div>
          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'HAS_VEHICLE' })}</p>
              <p className={classes.value}>{inspector?.details?.extra_info?.has_vehicle || '-----'}</p>
            </div>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'VEHICLE_TYPE' })}</p>
              <p className={classes.value}>
                {inspector?.details?.extra_info?.vehicle_type?.map((item, i) => (
                  <span key={item}>
                    {item}
                    {inspector?.details.extra_info?.vehicle_type?.length - 1 === i ? '' : ', '}{' '}
                  </span>
                )) || '-----'}
              </p>
            </div>
          </div>
          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'HAS_SOFTWARE_EXPERIENCE' })}</p>
              <p className={classes.value}>
                {inspector?.details?.extra_info?.software_experience?.map((item, i) => (
                  <span key={item}>
                    {item}
                    {inspector.details.extra_info.software_experience?.length - 1 === i ? '' : ', '}{' '}
                  </span>
                )) || '-----'}
              </p>
            </div>
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'LAST_REMUNERATION' })}</p>
              <p className={classes.value}>{inspector?.details?.extra_info?.last_remuneration || '-----'}</p>
            </div>
          </div>
        </Card>
      )}
      <Card className="fade-in">
        <h2 className={classes.cardHeadWithLink}>
          <div>
            <div className={classes.icon}>
              <img src={accountFilterIcon} alt={intl.formatMessage({ id: 'FILTER_INFORMATION' })} />
            </div>
            {intl.formatMessage({ id: 'FILTER_INFORMATION' })}
          </div>
          {inspector?.status === 'active' && (
            <Link
              to={`/inspectors/${inspector?.status}/${inspectorId}/filters/edit`}
              data-tip={intl.formatMessage({ id: 'UPDATE_FILTER' })}
            >
              <img src={editImage} alt={intl.formatMessage({ id: 'EDIT' })} />
            </Link>
          )}
        </h2>
        <h3 className={classes.subtitle}>
          <div className={classes.icon}>
            <img src={keyIcon} alt={intl.formatMessage({ id: 'REQUIREMENTS' })} />
          </div>
          {intl.formatMessage({ id: 'REQUIREMENTS' })}
        </h3>
        <div className={classes.filtersTypesList}>
          <div className={classes.firstColumn}>
            <div>
              <h4>{intl.formatMessage({ id: 'INSPECTION_TYPES' })}</h4>
              {!!inspector?.filtersSettings?.orderTypes.length && (
                <ul>
                  {inspector?.filtersSettings?.orderTypes.map((orderTypeItem, index, orderTypes) => (
                    <li key={orderTypeItem?.id}>
                      <FormattedMessage id={orderTypeItem?.description} />
                      {index !== orderTypes.length - 1 && ',\u00A0'}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className={classes.secondColumn}>
            <h4>{intl.formatMessage({ id: 'NEIGHBORHOODS' })}</h4>
            {!!inspector?.filtersSettings?.locations.length && (
              <ul>
                {inspector?.filtersSettings?.locations.map((location, index, locations) => (
                  <li key={location.id}>
                    {location?.description}
                    {index !== locations.length - 1 && ',\u00A0'}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </Card>
      <Card className="fade-in">
        <h2 className={classes.cardHead}>
          <div className={classes.icon}>
            <img src={metricsIcon} alt={intl.formatMessage({ id: 'PRODUCTIVITIES' })} />
          </div>
          {intl.formatMessage({ id: 'PRODUCTIVITIES' })}
        </h2>
        <div className={classes.metricList}>
          <div className={classes.metric}>
            <h4>{intl.formatMessage({ id: 'INSPECTION_CREDITS_MESSAGE' })}</h4>
            <div className={classes.totalNumber}>
              {(+inspector.metrics.total.total_credits).toFixed(1).replace('.', ',')}
            </div>
            <div className={classes.metricsByType}>
              {Object.keys(inspector.metrics.by_type).map(key => (
                <div className={classes.metricItem} key={key}>
                  <div className={classes.type}>
                    <img
                      src={orderIcon(key)}
                      alt={intl.formatMessage({ id: 'ORDER_ICON' })}
                      data-tip={intl.formatMessage({ id: key })}
                    />
                  </div>
                  <div className={classes.number}>
                    {(+inspector.metrics.by_type[key].total_credits).toFixed(1).replace('.', ',')}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={classes.metric}>
            <h4>{intl.formatMessage({ id: 'METERS_SURVEYED_MESSAGE' })}</h4>
            <div className={classes.totalNumber}>
              {(+inspector.metrics.total.total_area).toFixed(1).replace('.', ',')}m<sup>2</sup>
            </div>
            <div className={classes.metricsByType}>
              {Object.keys(inspector.metrics.by_type).map(key => (
                <div className={classes.metricItem} key={key}>
                  <div className={classes.type}>
                    <img
                      src={orderIcon(key)}
                      alt={intl.formatMessage({ id: 'ORDER_ICON' })}
                      data-tip={intl.formatMessage({ id: key })}
                    />
                  </div>
                  <div className={classes.number}>
                    {(+inspector.metrics.by_type[key].total_area).toFixed(1).replace('.', ',')}m<sup>2</sup>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={classes.metric}>
            <h4>{intl.formatMessage({ id: 'INSPECTIONS_TIME_SPENT' })}</h4>
            <div className={classes.totalNumber}>{formatSeconds(inspector.metrics.total.time_spent)}</div>
            <div className={classes.metricsByType}>
              {Object.keys(inspector.metrics.by_type).map(key => (
                <div className={classes.metricItem} key={key}>
                  <div className={classes.type}>
                    <img
                      src={orderIcon(key)}
                      alt={intl.formatMessage({ id: 'ORDER_ICON' })}
                      data-tip={intl.formatMessage({ id: key })}
                    />
                  </div>
                  <div className={classes.number}>{formatSeconds(inspector.metrics.by_type[key].time_spent)}</div>
                </div>
              ))}
            </div>
          </div>
          <div className={classes.metric}>
            <h4>{intl.formatMessage({ id: 'INSPECTION_AVERAGE_TIME' })}</h4>
            <div className={classes.totalNumber}>{formatSeconds(inspector.metrics.total.avg_time)}</div>
            <div className={classes.metricsByType}>
              {Object.keys(inspector.metrics.by_type).map(key => (
                <div className={classes.metricItem} key={key}>
                  <div className={classes.type}>
                    <img
                      src={orderIcon(key)}
                      alt={intl.formatMessage({ id: 'ORDER_ICON' })}
                      data-tip={intl.formatMessage({ id: key })}
                    />
                  </div>
                  <div className={classes.number}>{formatSeconds(inspector.metrics.by_type[key].avg_time)}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Card>
      <ReactTooltip effect="solid" />
    </div>
  );

InspectorDetailsComponent.propTypes = {
  isBusy: PropTypes.bool.isRequired,
  isSupportModeActive: PropTypes.func.isRequired,
  isDeleteConfirmationVisible: PropTypes.bool.isRequired,
  isEnableConfirmationVisible: PropTypes.bool.isRequired,
  isExportDatabaseConfirmationVisible: PropTypes.bool.isRequired,
  inspector: PropTypes.object,
  inspectorId: PropTypes.string.isRequired,
  hideDeleteConfirmation: PropTypes.func.isRequired,
  hideEnableConfirmation: PropTypes.func.isRequired,
  hideExportDatabaseConfirmation: PropTypes.func.isRequired,
  showDeleteConfirmation: PropTypes.func.isRequired,
  showEnableConfirmation: PropTypes.func.isRequired,
  showExportDatabaseConfirmation: PropTypes.func.isRequired,
  enableInspector: PropTypes.func.isRequired,
  deleteInspector: PropTypes.func.isRequired,
  exportDatabase: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

InspectorDetailsComponent.defaultProps = {
  inspector: null,
};

export default InspectorDetailsComponent;

import React from 'react';

import AddressSelectWidget from 'src/orderForm/widgets/AddressSelectWidget';
import HiddenStatusDocusignWidget from 'src/orderForm/widgets/HiddenStatusDocusignWidget';
import MultipleCheckboxes from 'src/orderForm/widgets/MultipleCheckboxes';
import MultipleRadioWidget from 'src/orderForm/widgets/MultipleRadioWidget';
import RadioWidget from 'src/orderForm/widgets/RadioWidget';
import CustomWidgetArea from 'src/orderForm/widgets/RequestFormWidgetArea';
import CustomWidgetAreaDisable from 'src/orderForm/widgets/RequestFormWidgetAreaDisable';
import CustomWidgetCheckbox from 'src/orderForm/widgets/RequestFormWidgetCheckbox';
import CustomWidgetNumber from 'src/orderForm/widgets/RequestFormWidgetNumber';
import CustomWidgetPrice from 'src/orderForm/widgets/RequestFormWidgetPrice';
import CustomWidgetPriceArea from 'src/orderForm/widgets/RequestFormWidgetPriceArea';
import CustomWidgetPriceString from 'src/orderForm/widgets/RequestFormWidgetPriceString';
import CustomWidgetString from 'src/orderForm/widgets/RequestFormWidgetString';
import CustomWidgetStringMultiline from 'src/orderForm/widgets/RequestFormWidgetStringMultiline';
import ZipcodeWidget from 'src/orderForm/widgets/ZipcodeWidget';

const makeWidgets = ({
  addressSelectedState,
  addressSelectedCity,
  addressSelectedNeighborhood,

  accessInformationAddressSelectedState,
  accessInformationAddressSelectedCity,
  accessInformationAddressSelectedNeighborhood,

  accessInformationKeysDevolutionAddressSelectedState,
  accessInformationKeysDevolutionAddressSelectedCity,
  accessInformationKeysDevolutionAddressSelectedNeighborhood,

  propertyAccessAddressSelectedState,
  propertyAccessAddressSelectedCity,
  propertyAccessAddressSelectedNeighborhood,

  propertyAccessKeysDevolutionAddressSelectedState,
  propertyAccessKeysDevolutionAddressSelectedCity,
  propertyAccessKeysDevolutionAddressSelectedNeighborhood,

  desiredPropertyAddressSelectedState,
  desiredPropertyAddressSelectedCity,
  desiredPropertyAddressSelectedNeighborhood,

  mainSuitorAddressSelectedState,
  mainSuitorAddressSelectedCity,
  mainSuitorAddressSelectedNeighborhood,

  addressNeighborhoodSelectWidgetOnCreateOption,
  accessInformationAddressNeighborhoodSelectWidgetOnCreateOption,
  accessInformationKeysDevolutionAddressNeighborhoodSelectWidgetOnCreateOption,
  propertyAccessAddressNeighborhoodSelectWidgetOnCreateOption,
  propertyAccessKeysDevolutionAddressNeighborhoodSelectWidgetOnCreateOption,
  desiredPropertyAddressNeighborhoodSelectWidgetOnCreateOption,
  mainSuitorAddressNeighborhoodSelectWidgetOnCreateOption,

  addressCitySelectWidgetOnCreateOption,
  accessInformationAddressCitySelectWidgetOnCreateOption,
  accessInformationKeysDevolutionAddressCitySelectWidgetOnCreateOption,
  propertyAccessAddressCitySelectWidgetOnCreateOption,
  propertyAccessKeysDevolutionAddressCitySelectWidgetOnCreateOption,
  desiredPropertyAddressCitySelectWidgetOnCreateOption,
  mainSuitorAddressCitySelectWidgetOnCreateOption,
}) => ({
  customNumberWidget: CustomWidgetNumber,
  customStringWidget: CustomWidgetString,
  zipcodeWidget: ZipcodeWidget,
  radioWidget: RadioWidget,
  customPriceWidget: CustomWidgetPrice,
  customPriceAreaWidget: CustomWidgetPriceArea,
  customPriceStringWidget: CustomWidgetPriceString,
  customMultilineWidget: CustomWidgetStringMultiline,
  customAreaWidget: CustomWidgetArea,
  customAreaDisableWidget: CustomWidgetAreaDisable,
  customWidgetCheckbox: CustomWidgetCheckbox,
  hiddenDocuSignStatus: HiddenStatusDocusignWidget,
  MultipleCheckboxes,
  multipleRadioWidget: MultipleRadioWidget,
  addressStateSelectWidget: props => <AddressSelectWidget {...props} value={addressSelectedState} />,
  addressCitySelectWidget: props => (
    <AddressSelectWidget
      {...props}
      value={addressSelectedCity}
      onCreateOption={addressCitySelectWidgetOnCreateOption}
    />
  ),
  addressNeighborhoodSelectWidget: props => (
    <AddressSelectWidget
      {...props}
      value={addressSelectedNeighborhood}
      onCreateOption={addressNeighborhoodSelectWidgetOnCreateOption}
    />
  ),
  accessInformationAddressStateSelectWidget: props => (
    <AddressSelectWidget {...props} value={accessInformationAddressSelectedState} />
  ),
  accessInformationAddressCitySelectWidget: props => (
    <AddressSelectWidget
      {...props}
      value={accessInformationAddressSelectedCity}
      onCreateOption={accessInformationAddressCitySelectWidgetOnCreateOption}
    />
  ),
  accessInformationAddressNeighborhoodSelectWidget: props => (
    <AddressSelectWidget
      {...props}
      value={accessInformationAddressSelectedNeighborhood}
      onCreateOption={accessInformationAddressNeighborhoodSelectWidgetOnCreateOption}
    />
  ),
  accessInformationKeysDevolutionAddressStateSelectWidget: props => (
    <AddressSelectWidget {...props} value={accessInformationKeysDevolutionAddressSelectedState} />
  ),
  accessInformationKeysDevolutionAddressCitySelectWidget: props => (
    <AddressSelectWidget
      {...props}
      value={accessInformationKeysDevolutionAddressSelectedCity}
      onCreateOption={accessInformationKeysDevolutionAddressCitySelectWidgetOnCreateOption}
    />
  ),
  accessInformationKeysDevolutionAddressNeighborhoodSelectWidget: props => (
    <AddressSelectWidget
      {...props}
      value={accessInformationKeysDevolutionAddressSelectedNeighborhood}
      onCreateOption={accessInformationKeysDevolutionAddressNeighborhoodSelectWidgetOnCreateOption}
    />
  ),
  propertyAccessAddressStateSelectWidget: props => (
    <AddressSelectWidget {...props} value={propertyAccessAddressSelectedState} />
  ),
  propertyAccessAddressCitySelectWidget: props => (
    <AddressSelectWidget
      {...props}
      value={propertyAccessAddressSelectedCity}
      onCreateOption={propertyAccessAddressCitySelectWidgetOnCreateOption}
    />
  ),
  propertyAccessAddressNeighborhoodSelectWidget: props => (
    <AddressSelectWidget
      {...props}
      value={propertyAccessAddressSelectedNeighborhood}
      onCreateOption={propertyAccessAddressNeighborhoodSelectWidgetOnCreateOption}
    />
  ),
  propertyAccessKeysDevolutionAddressStateSelectWidget: props => (
    <AddressSelectWidget {...props} value={propertyAccessKeysDevolutionAddressSelectedState} />
  ),
  propertyAccessKeysDevolutionAddressCitySelectWidget: props => (
    <AddressSelectWidget
      {...props}
      value={propertyAccessKeysDevolutionAddressSelectedCity}
      onCreateOption={propertyAccessKeysDevolutionAddressCitySelectWidgetOnCreateOption}
    />
  ),
  propertyAccessKeysDevolutionAddressNeighborhoodSelectWidget: props => (
    <AddressSelectWidget
      {...props}
      value={propertyAccessKeysDevolutionAddressSelectedNeighborhood}
      onCreateOption={propertyAccessKeysDevolutionAddressNeighborhoodSelectWidgetOnCreateOption}
    />
  ),
  desiredPropertyAddressStateSelectWidget: props => (
    <AddressSelectWidget {...props} value={desiredPropertyAddressSelectedState} />
  ),
  desiredPropertyAddressCitySelectWidget: props => (
    <AddressSelectWidget
      {...props}
      value={desiredPropertyAddressSelectedCity}
      onCreateOption={desiredPropertyAddressCitySelectWidgetOnCreateOption}
    />
  ),
  desiredPropertyAddressNeighborhoodSelectWidget: props => (
    <AddressSelectWidget
      {...props}
      value={desiredPropertyAddressSelectedNeighborhood}
      onCreateOption={desiredPropertyAddressNeighborhoodSelectWidgetOnCreateOption}
    />
  ),
  mainSuitorAddressStateSelectWidget: props => (
    <AddressSelectWidget {...props} value={mainSuitorAddressSelectedState} />
  ),
  mainSuitorAddressCitySelectWidget: props => (
    <AddressSelectWidget
      {...props}
      value={mainSuitorAddressSelectedCity}
      onCreateOption={mainSuitorAddressCitySelectWidgetOnCreateOption}
    />
  ),
  mainSuitorAddressNeighborhoodSelectWidget: props => (
    <AddressSelectWidget
      {...props}
      value={mainSuitorAddressSelectedNeighborhood}
      onCreateOption={mainSuitorAddressNeighborhoodSelectWidgetOnCreateOption}
    />
  ),
});

export default makeWidgets;

import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/CostSection.module.css';
import CostIcon from 'src/svg-icons/costs';

function CostSection({ cost }) {
  if (Object.keys(cost).length > 0) {
    return (
      <Grid item xs={12}>
        <div className={classes.Header}>
          <CostIcon className={classes.Icon} />
          <FormattedMessage id="cost" />
        </div>
        <Grid container spacing={8} className={classes.Information}>
          <Grid item xs={6} data-cy="order-details-cost-condominium-cost">
            <div className={classes.SubHeader}>
              <FormattedMessage id="condominiumCost" />
            </div>
            {cost.condominiumCost ? (
              <FormattedNumber
                value={cost.condominiumCost}
                style="currency" // eslint-disable-line react/style-prop-object
                currency="BRL"
              />
            ) : null}
          </Grid>
          <Grid item xs={6} data-cy="order-details-cost-iptu-cost">
            <div className={classes.SubHeader}>
              <FormattedMessage id="iptuCost" />
            </div>
            {cost.iptuCost ? (
              <FormattedNumber
                value={cost.iptuCost}
                style="currency" // eslint-disable-line react/style-prop-object
                currency="BRL"
              />
            ) : null}
          </Grid>
          <Grid item xs={6} data-cy="order-details-cost-rent-cost">
            <div className={classes.SubHeader}>
              <FormattedMessage id="rentCost" />
            </div>
            {cost.rentCost ? (
              <FormattedNumber
                value={cost.rentCost}
                style="currency" // eslint-disable-line react/style-prop-object
                currency="BRL"
              />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return null;
}

CostSection.propTypes = {
  /**
   * Object that contains the cost information
   */
  cost: PropTypes.object.isRequired,
};

export default CostSection;

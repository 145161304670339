import axios from 'axios';

import { API_COMMERCIAL_BASE_URL } from 'src/config';

const accessToken = window.sessionStorage.getItem('accessToken');
const tokenType = window.sessionStorage.getItem('tokenType');
const instance = axios.create({
  baseURL: API_COMMERCIAL_BASE_URL,
  headers: { Authorization: `${tokenType} ${accessToken}` },
});

export default instance;

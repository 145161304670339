import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import Card from 'src/components/common/Card';
import userIcon from 'src/components/common/icons/user.svg';
import classes from 'src/components/InspectorsPage/InspectorList/InspectorCard/style.module.scss';

const InspectorCard = ({ data }) => {
  const intl = useIntl();
  const params = useParams();
  const status = params.status || 'active';

  return (
    <Card url={`/inspectors/${status}/${data.public_id}`}>
      <div className={classes.inspector}>
        <figure className={classes.image}>
          <img src={userIcon} alt={intl.formatMessage({ id: 'USER_ICON' })} />
        </figure>
        <div className={classes.name}>{data.name}</div>
        <div className={classes.neighborhood}>
          {data.neighborhood || intl.formatMessage({ id: 'NEIGHBORHOOD_NOT_REGISTERED_MESSAGE' })}
        </div>
        <div className={classes.phone}>
          {data.cellphone || data.phone || intl.formatMessage({ id: 'PHONE_NOT_REGISTERED_MESSAGE' })}
        </div>
      </div>
    </Card>
  );
};

InspectorCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default InspectorCard;

/* eslint-disable indent */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import AsyncSelect from 'react-select/async';
import { CSSTransition } from 'react-transition-group';

import { ClickAwayListener } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import ptBR from 'date-fns/locale/pt-BR';
import moment from 'moment';
import PropTypes from 'prop-types';

import ActiveFiltersAndFreeTermSearch from 'src/components/common/SearchBox/ActiveFiltersAndFreeTermSearch';
import InspectorsField from 'src/components/common/SearchBox/InspectorsField';
import classes from 'src/components/common/SearchBox/style.module.scss';
import TagField from 'src/components/Tags/TagField';
import { orderType } from 'src/constants';
import { setQuery } from 'src/store/ducks/filter';

const animatedComponents = makeAnimated();
const language = 'pt-BR';
let timeout = null;

const SearchBox = ({
  advancedPlaceholder,
  handler,
  options,
  selectableOptions,
  placeholder,
  query,
  redux,
  setQuery, // eslint-disable-line no-shadow
  isClientsPageHeader = false,
  clearChosenTypeAndTags,
  value: stringValue,
}) => {
  registerLocale(language, ptBR);

  const me = JSON.parse(window.sessionStorage.getItem('me'));
  const features = me ? me.features : [];
  const types = features.filter(item => item && item.includes('VISTORIA_'));

  const [advancedSearchVisible, setAdvancedSearchVisible] = useState(false);
  const [chosenInspectors, setChosenInspectors] = useState([]);
  const [chosenTags, setChosenTags] = useState([]);
  const [chosenType, setChosenType] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [addresses, setAddresses] = useState([]);
  const [orderTypes, setOrderTypes] = useState([]);

  const [loadedAddresses, setLoadedAddresses] = useState([]);
  const [loadedOrderTypes, setLoadedOrderTypes] = useState([]);

  const [loadedAddressesPage, setLoadedAddressesPage] = useState(0);
  const [loadedOrderTypesPage, setLoadedOrderTypesPage] = useState(0);

  const [isAddressesSelectLoading, setIsAddressesSelectLoading] = useState(false);
  const [isOrderTypesSelectLoading, setIsOrderTypesSelectLoading] = useState(false);

  const [chosenAddresses, setChosenAddresses] = useState([]);
  const [chosenOrderTypes, setChosenOrderTypes] = useState([]);

  const addressesSelectRef = useRef(null);
  const orderTypesSelectRef = useRef(null);
  const textRef = useRef(null);

  const intl = useIntl();
  const { listen: listenRouteChange } = useHistory();
  const { pathname } = useLocation();

  const isScheduleBoxFilter = useMemo(() => {
    const is = redux && options.advanced && options.advanced.includes('schedule') && pathname.includes('/schedule');

    return is;
  }, [options.advanced, pathname, redux]);

  const isInspectorsFilter = useMemo(() => {
    const is = redux && options.advanced && options.advanced.includes('inspectors');

    return is;
  }, [options.advanced, redux]);

  const hasFreeTermSearch = useMemo(
    () => !isScheduleBoxFilter && !isInspectorsFilter,
    [isScheduleBoxFilter, isInspectorsFilter]
  );

  const isSearchBoxClickableInSchedulePage = useMemo(() => {
    if (isScheduleBoxFilter) {
      if ((query.addresses && query.addresses.length) || (query.orderTypes && query.orderTypes.length)) {
        return false;
      }
    }

    return true;
  }, [isScheduleBoxFilter, query.addresses, query.orderTypes]);

  const isSearchBoxClickable = useMemo(
    () => !hasFreeTermSearch && isSearchBoxClickableInSchedulePage,
    [hasFreeTermSearch, isSearchBoxClickableInSchedulePage]
  );

  useEffect(() => {
    listenRouteChange(() => {
      setChosenInspectors([]);
      setChosenTags([]);
      setChosenType(null);
      setStartDate('');
      setEndDate('');
      setAddresses([]);
      setOrderTypes([]);
      setChosenAddresses([]);
      setChosenOrderTypes([]);
    });
  }, [listenRouteChange]);

  useEffect(() => {
    if (clearChosenTypeAndTags) {
      setChosenTags([]);
      setChosenType(null);
    }
  }, [clearChosenTypeAndTags]);

  useEffect(() => {
    setSearchString(stringValue);
  }, [stringValue]);

  useEffect(() => {
    setSearchString(query.text || '');
    setStartDate(query.startDate ? moment(query.startDate).toDate() : '');
    setEndDate(query.endDate ? moment(query.endDate).toDate() : '');
  }, [query]);

  useEffect(() => {
    setAddresses(selectableOptions?.addresses || []);
    setOrderTypes(selectableOptions?.orderTypes || []);

    setLoadedAddresses(selectableOptions?.addresses?.slice(0, 30));
    setLoadedOrderTypes(selectableOptions?.orderTypes?.slice(0, 30));

    setLoadedAddressesPage(1);
    setLoadedOrderTypesPage(1);
  }, [selectableOptions]);

  const doSearch = useCallback(
    text => {
      // new way
      if (redux) {
        const filter = {
          addresses: [],
          orderTypes: [],
        };

        if (text) filter.text = text;
        if (chosenType) filter.orderType = chosenType.value;
        if (chosenTags) filter.tags = chosenTags;
        if (chosenInspectors.length > 0) filter.inspectors = chosenInspectors;
        if (startDate) filter.startDate = moment(startDate).toISOString();
        if (endDate) filter.endDate = moment(endDate).toISOString();

        if (chosenAddresses.length) {
          filter.addresses = chosenAddresses.map(address => ({
            id: address?.value,
            description: address?.label,
          }));
        }

        if (chosenOrderTypes.length) {
          filter.orderTypes = chosenOrderTypes.map(orderTypeItem => ({
            id: orderTypeItem?.value,
            description: orderTypeItem?.label,
          }));
        }

        if (handler) {
          handler(filter);
          return;
        }

        setQuery(filter);
        return;
      }

      // old way
      handler(text, chosenTags, chosenType ? chosenType.value : null);
    },
    [
      chosenAddresses,
      chosenInspectors,
      chosenOrderTypes,
      chosenTags,
      chosenType,
      endDate,
      handler,
      redux,
      setQuery,
      startDate,
    ]
  );

  const searchWithDelayHandler = useCallback(
    e => {
      const { value } = e.target;

      clearTimeout(timeout);
      setSearchString(value);

      timeout = setTimeout(doSearch, 600, value);
    },
    [doSearch]
  );

  const toggleAdvancedSearch = useCallback(() => {
    setChosenTags([]);

    setTimeout(() => {
      setAdvancedSearchVisible(!advancedSearchVisible);
    }, 50);
  }, [advancedSearchVisible]);

  const handleInputChange = useCallback(e => {
    const { value } = e.target;

    switch (e.target.getAttribute('name')) {
      case 'startDate':
        setStartDate(value);
        break;
      case 'endDate':
        setEndDate(value);
        break;
      default:
        setSearchString(value);
        break;
    }
  }, []);

  const handleTagsChange = useCallback(value => {
    if (!value) return;
    setChosenTags(value.map(item => item.value));
  }, []);

  const handleInspectorsChange = useCallback(value => {
    if (!value) setChosenInspectors([]);
    else setChosenInspectors(value.map(item => item.value));
  }, []);

  const handleTypeChange = useCallback(type => {
    setChosenType(type);
  }, []);

  const handleSubmit = useCallback(
    evt => {
      evt.preventDefault();
      doSearch(searchString);
      toggleAdvancedSearch();
    },
    [doSearch, searchString, toggleAdvancedSearch]
  );

  const handleAddressesChange = useCallback(addressesItems => {
    setChosenAddresses(addressesItems || []);
  }, []);

  const handleOrderTypesChange = useCallback(orderTypesItems => {
    setChosenOrderTypes(orderTypesItems || []);
  }, []);

  const handleClearScheduleFilters = useCallback(() => {
    if (addressesSelectRef.current) addressesSelectRef.current.select.select.clearValue();
    if (orderTypesSelectRef.current) orderTypesSelectRef.current.select.select.clearValue();
    if (textRef.current) textRef.current.value = '';

    setChosenAddresses([]);
    setChosenOrderTypes([]);
    setSearchString('');
  }, []);

  const handleCloseScheduleFilterOption = useCallback(
    (optionType, id) => {
      const closeScheduleFilterOptionStrategy = {
        addresses: () => {
          setChosenAddresses(previousChosenAddrsetChosenAddresses => {
            const addressIndex = previousChosenAddrsetChosenAddresses.findIndex(address => address?.value === id);

            if (addressIndex !== -1) {
              previousChosenAddrsetChosenAddresses.splice(addressIndex, 1);
            }

            return previousChosenAddrsetChosenAddresses;
          });
        },
        'order-types': () => {
          setChosenOrderTypes(previousChosenOrderTypes => {
            const orderTypeIndex = previousChosenOrderTypes.findIndex(orderTypeItem => orderTypeItem?.value === id);

            if (orderTypeIndex !== -1) {
              previousChosenOrderTypes.splice(orderTypeIndex, 1);
            }

            return previousChosenOrderTypes;
          });
        },
      };

      closeScheduleFilterOptionStrategy[optionType]();

      doSearch(searchString);
    },
    [doSearch, searchString]
  );

  const handleLoadMoreAddresses = useCallback(() => {
    try {
      if (addresses.length === loadedAddresses.length) {
        return;
      }

      setIsAddressesSelectLoading(true);

      const moreThirtyAddressesItems = addresses.slice(loadedAddressesPage * 30, loadedAddressesPage * 30 + 30);

      setLoadedAddresses(prevLoadedAddresses => [...prevLoadedAddresses, ...moreThirtyAddressesItems]);
      setLoadedAddressesPage(prevLoadedAddressesPage => prevLoadedAddressesPage + 1);
    } catch {
      setLoadedAddresses([]);
    } finally {
      setTimeout(() => {
        setIsAddressesSelectLoading(false);
      }, 500);
    }
  }, [addresses, loadedAddresses.length, loadedAddressesPage]);

  const handleLoadMoreOrderTypes = useCallback(() => {
    try {
      if (orderTypes.length === loadedOrderTypes.length) {
        return;
      }

      setIsOrderTypesSelectLoading(true);

      const moreThirtyOrderTypesItems = orderTypes.slice(loadedOrderTypesPage * 30, loadedOrderTypesPage * 30 + 30);

      setLoadedOrderTypes(prevLoadedOrderTypes => [...prevLoadedOrderTypes, ...moreThirtyOrderTypesItems]);
      setLoadedOrderTypesPage(prevLoadedOrderTypesPage => prevLoadedOrderTypesPage + 1);
    } catch {
      setLoadedOrderTypes([]);
    } finally {
      setTimeout(() => {
        setIsOrderTypesSelectLoading(false);
      }, 500);
    }
  }, [orderTypes, loadedOrderTypes.length, loadedOrderTypesPage]);

  const handleFilterOptions = useCallback(
    (inputValue, itemType) => {
      const handleFilterOptionsStrategy = {
        addresses: () => {
          if (addresses.length) {
            const addressesFounded = addresses
              .filter(address => address?.description.toLowerCase().includes(inputValue.toLowerCase()))
              .map(address => ({
                value: address?.id,
                label: address?.description,
              }));

            return addressesFounded;
          }

          return [];
        },
        'order-types': () => {
          if (orderTypes.length) {
            const orderTypesFounded = orderTypes
              .filter(orderTypeItem => orderTypeItem?.description.toLowerCase().includes(inputValue.toLowerCase()))
              .map(orderTypeItem => ({
                value: orderTypeItem?.id,
                label: intl.formatMessage({ id: orderTypeItem?.description }),
              }));

            return orderTypesFounded;
          }

          return [];
        },
      };

      return handleFilterOptionsStrategy[itemType]();
    },
    [addresses, intl, orderTypes]
  );

  const handleLoadOptions = useCallback(
    (inputValue, callback, itemType) => {
      try {
        return callback(handleFilterOptions(inputValue, itemType));
      } catch {
        return [];
      }
    },
    [handleFilterOptions]
  );

  return (
    <div className={classes.searchBoxWrapper} data-cy="header-search-box">
      {ActiveFiltersAndFreeTermSearch({
        placeholder,
        isScheduleBoxFilter,
        isSearchBoxClickable,
        hasFreeTermSearch,
        searchString,
        chosenType,
        query,
        searchWithDelayHandler,
        handleCloseScheduleFilterOption,
        toggleAdvancedSearch,
        options,
        intl,
        moment,
      })}
      {(!redux || options.advanced) && !isClientsPageHeader && (
        <div className={classes.advancedSearchButton}>
          <button
            type="button"
            className={advancedSearchVisible ? classes.isOpen : null}
            onClick={toggleAdvancedSearch}
            data-cy="header-search-box-arrow-down"
          >
            <ArrowDropDown />
          </button>
        </div>
      )}
      <CSSTransition in={advancedSearchVisible} timeout={300} classNames="slide-fade" unmountOnExit>
        <ClickAwayListener onClickAway={handleSubmit}>
          <div className={classes.advancedSearchWrapper} style={{ zIndex: 999 }}>
            <div className={classes.head}>{advancedPlaceholder || intl.formatMessage({ id: 'ADVANCED_SEARCH' })}</div>
            <div className={classes.body}>
              <form onSubmit={handleSubmit}>
                {(!redux || options?.basic) && (
                  <div className={classes.field}>
                    <label htmlFor="basic-search">{intl.formatMessage({ id: 'SEARCH_BY_FREE_TERM' })}</label>
                    <input
                      type="text"
                      id="basic-search"
                      name="text"
                      ref={textRef}
                      className={`${classes.searchBox} ${classes.isSmall}`}
                      value={searchString}
                      placeholder={placeholder || intl.formatMessage({ id: 'SEARCH' })}
                      onChange={handleInputChange}
                      data-cy="header-search-box-basic-search-input"
                    />
                  </div>
                )}
                {isInspectorsFilter && (
                  <div className={classes.field}>
                    <label htmlFor="react-select-2-input">{intl.formatMessage({ id: 'SEARCH_BY_INSPECTORS' })}</label>
                    <InspectorsField
                      name="header-search-inspectors-select"
                      intl={intl}
                      handleChange={handleInspectorsChange}
                      defaultValue={
                        chosenInspectors
                          ? chosenInspectors.map(item => ({
                              value: item,
                              label: item,
                            }))
                          : []
                      }
                    />
                  </div>
                )}
                {redux &&
                  options.advanced &&
                  (options.advanced.includes('start_date') || options.advanced.includes('end_date')) && (
                    <div className={classes.dates}>
                      {redux && options.advanced && options.advanced.includes('start_date') && (
                        <div className={classes.field}>
                          <label htmlFor="startDate">{intl.formatMessage({ id: 'FROM_DAY' })}</label>
                          <DatePicker
                            id="startDate"
                            autoComplete="off"
                            locale="pt-BR"
                            dateFormat="dd 'de' MMMM 'de' yyy"
                            selected={startDate}
                            onChange={setStartDate}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            data-cy="header-search-start-date-input"
                          />
                        </div>
                      )}
                      {redux && options.advanced && options.advanced.includes('end_date') && (
                        <div className={classes.field}>
                          <label htmlFor="endDate">{intl.formatMessage({ id: 'TO_DAY' })}</label>
                          <DatePicker
                            id="endDate"
                            autoComplete="off"
                            locale="pt-BR"
                            dateFormat="dd 'de' MMMM 'de' yyy"
                            selected={endDate}
                            onChange={setEndDate}
                            selectsEnd
                            endDate={endDate}
                            minDate={startDate}
                            data-cy="header-search-end-date-input"
                          />
                        </div>
                      )}
                    </div>
                  )}
                {(!redux || (options.advanced && options.advanced.includes('order_type'))) && (
                  <div className={classes.field}>
                    <label htmlFor="react-select-2-input">{intl.formatMessage({ id: 'SEARCH_BY_TYPE' })}</label>
                    <Select
                      className={classes.select}
                      classNamePrefix="select"
                      placeholder={`${intl.formatMessage({
                        id: 'SELECT_A_TYPE_MESSAGE',
                      })}...`}
                      noOptionsMessage={() => intl.formatMessage({ id: 'NO_TYPE_AVAILABLE_MESSAGE' })}
                      defaultValue={chosenType}
                      options={types.map(type => ({
                        value: orderType.get(type),
                        label: intl.formatMessage({ id: orderType.get(type) }),
                      }))}
                      onChange={handleTypeChange}
                      isClearable
                      components={{
                        ...animatedComponents,
                        Input: inputProps => <components.Input {...inputProps} data-cy="header-search-type-select" />,
                      }}
                    />
                  </div>
                )}
                {(!redux || options?.advanced?.includes('tags')) && (
                  <div className={classes.field}>
                    <label htmlFor="react-select-2-input">
                      {intl.formatMessage({
                        id: 'INSERT_LABELS_TO_FILTER_MESSAGE',
                      })}
                    </label>
                    <TagField handleChange={handleTagsChange} inputId="header-search-tags-select" />
                  </div>
                )}
                {isScheduleBoxFilter && (
                  <>
                    <div className={classes.field}>
                      <label htmlFor="react-select-2-input">{intl.formatMessage({ id: 'NEIGHBORHOODS' })}</label>
                      <AsyncSelect
                        id={classes.addressesSelect}
                        name="addresses"
                        placeholder={intl.formatMessage({
                          id: 'NEIGHBORHOODS',
                        })}
                        className={classes.select}
                        classNamePrefix="select"
                        ref={addressesSelectRef}
                        isMulti
                        components={{
                          ...animatedComponents,
                          Input: inputProps => (
                            <components.Input {...inputProps} data-cy="header-search-addresses-select" />
                          ),
                        }}
                        closeMenuOnSelect={chosenAddresses.length === addresses.length}
                        loadOptions={(inputValue, callback) => handleLoadOptions(inputValue, callback, 'addresses')}
                        defaultOptions={
                          loadedAddresses
                            ? loadedAddresses.map(address => ({
                                value: address?.id,
                                label: address?.description,
                              }))
                            : []
                        }
                        defaultValue={
                          chosenAddresses
                            ? chosenAddresses.map(chosenAddress => ({
                                value: chosenAddress?.value,
                                label: chosenAddress?.label,
                              }))
                            : []
                        }
                        loadingMessage={() =>
                          intl.formatMessage({
                            id: 'NEIGHBORHOOD_LOADING_MESSAGE',
                          })
                        }
                        noOptionsMessage={() =>
                          intl.formatMessage({
                            id: 'NEIGHBORHOOD_NOT_FOUND_MESSAGE',
                          })
                        }
                        isLoading={isAddressesSelectLoading}
                        isDisabled={!addresses.length}
                        onChange={handleAddressesChange}
                        onMenuScrollToBottom={handleLoadMoreAddresses}
                        {...(chosenAddresses.length === addresses.length && {
                          menuIsOpen: false,
                        })}
                      />
                    </div>
                    <div className={classes.field}>
                      <label htmlFor="react-select-2-input">{intl.formatMessage({ id: 'INSPECTION_TYPES' })}</label>
                      <AsyncSelect
                        id={classes.orderTypesSelect}
                        name="order-types"
                        placeholder={intl.formatMessage({
                          id: 'INSPECTION_TYPES',
                        })}
                        className={classes.select}
                        classNamePrefix="select"
                        ref={orderTypesSelectRef}
                        isMulti
                        components={{
                          ...animatedComponents,
                          Input: inputProps => (
                            <components.Input {...inputProps} data-cy="header-search-order-types-select" />
                          ),
                        }}
                        closeMenuOnSelect={chosenOrderTypes.length === orderTypes.length}
                        loadOptions={(inputValue, callback) => handleLoadOptions(inputValue, callback, 'order-types')}
                        defaultOptions={
                          loadedOrderTypes
                            ? loadedOrderTypes.map(orderTypeItem => ({
                                value: orderTypeItem?.id,
                                label: intl.formatMessage({
                                  id: orderTypeItem?.description,
                                }),
                              }))
                            : []
                        }
                        defaultValue={
                          chosenOrderTypes
                            ? chosenOrderTypes.map(chosenOrderType => ({
                                value: chosenOrderType?.value,
                                label: chosenOrderType?.label,
                              }))
                            : []
                        }
                        loadingMessage={() =>
                          intl.formatMessage({
                            id: 'INSPECTION_TYPES_LOADING_MESSAGE',
                          })
                        }
                        noOptionsMessage={() =>
                          intl.formatMessage({
                            id: 'INSPECTION_TYPES_NOT_FOUND_MESSAGE',
                          })
                        }
                        isLoading={isOrderTypesSelectLoading}
                        isDisabled={!orderTypes.length}
                        onChange={handleOrderTypesChange}
                        onMenuScrollToBottom={handleLoadMoreOrderTypes}
                        {...(chosenOrderTypes.length === orderTypes.length && {
                          menuIsOpen: false,
                        })}
                      />
                    </div>
                  </>
                )}
                {isScheduleBoxFilter ? (
                  <div className={classes.scheduleFilterButtons}>
                    <button
                      type="button"
                      className={classes.clearScheduleFilters}
                      onClick={handleClearScheduleFilters}
                      data-cy="header-search-clear-filters-button"
                    >
                      {intl.formatMessage({ id: 'CLEAR_FILTER_MESSAGE' })}
                    </button>
                    <button type="submit" data-cy="header-search-submit-button">
                      {intl.formatMessage({ id: 'SEARCH' })}
                    </button>
                  </div>
                ) : (
                  <div className={classes.field}>
                    <button type="submit" data-cy="header-search-submit-button">
                      {intl.formatMessage({ id: 'SEARCH' })}
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </ClickAwayListener>
      </CSSTransition>
    </div>
  );
};

SearchBox.propTypes = {
  advancedPlaceholder: PropTypes.string,
  handler: PropTypes.func,
  options: PropTypes.object,
  selectableOptions: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  query: PropTypes.object,
  redux: PropTypes.bool,
  setQuery: PropTypes.func.isRequired,
  value: PropTypes.string,
  isClientsPageHeader: PropTypes.bool,
  clearChosenTypeAndTags: PropTypes.bool,
};

SearchBox.defaultProps = {
  advancedPlaceholder: null,
  handler: null,
  options: null,
  placeholder: null,
  query: {},
  redux: false,
  value: null,
  isClientsPageHeader: true,
  clearChosenTypeAndTags: false,
};

const mapStateToProps = ({ filter }, { options, query }) => ({
  options: options || filter.options,
  query: query || filter.query,
  selectableOptions: filter.selectableOptions,
});

export default connect(mapStateToProps, { setQuery })(SearchBox);

import React, { useCallback } from 'react';

import { Fade } from '@material-ui/core';
import PropTypes from 'prop-types';

import Spinner from 'src/components/common/Spinner';
import deviceImage from 'src/components/SchedulePage/Timeline/InspectorList/icons/device-black.svg';
import inspectionImage from 'src/components/SchedulePage/Timeline/InspectorList/icons/inspection-black.svg';
import locationImage from 'src/components/SchedulePage/Timeline/InspectorList/icons/location-black.svg';
import classes from 'src/components/SchedulePage/Timeline/InspectorList/style.module.scss';

const InspectorListComponent = ({ inspectors, busy }) => {
  const getInspectorFeedbackOpacity = useCallback(inspector => {
    const applyLessOpacity = inspector?.wasNotFiltered || inspector?.status === 'DEVICE_NOT_CONNECTED';

    return applyLessOpacity ? 0.5 : 1;
  }, []);

  const getInspectorWarningMessage = useCallback(
    inspector => {
      if (inspector?.status === 'DEVICE_NOT_CONNECTED') {
        return (
          <p className={classes.warningMessage}>
            <img src={deviceImage} alt="dipositivo" />

            <span>Vistoriador sem dipositivo</span>
          </p>
        );
      }

      if (inspector?.pendingOrderBasedMessage) {
        if (inspector?.pendingOrderBasedMessage === 'ORDER_TYPE') {
          return (
            <Fade timeout={300} in={!!(Array.isArray(inspectors) && inspectors.length)}>
              <p className={classes.warningMessage}>
                <img src={inspectionImage} alt="Tipo de vistoria" />

                <span>Não faz o tipo de vistoria</span>
              </p>
            </Fade>
          );
        }

        if (inspector?.pendingOrderBasedMessage === 'ADDRESS') {
          return (
            <Fade timeout={300} in={!!(Array.isArray(inspectors) && inspectors.length)}>
              <p className={classes.warningMessage}>
                <img src={locationImage} alt="Bairro" />

                <span>Não atende o bairro</span>
              </p>
            </Fade>
          );
        }
      }

      return <div />;
    },
    [inspectors]
  );

  return (
    <div className={classes.inspectorList}>
      <header>
        <h4>Vistoriadores</h4>
      </header>

      {busy ? (
        <div className={classes.spinnerWrapper}>
          <Spinner />
        </div>
      ) : (
        !!inspectors?.length &&
        inspectors.map(inspector => (
          <div className={classes.line} key={inspector?.public_id}>
            <div className={classes.inspector} style={{ opacity: getInspectorFeedbackOpacity(inspector) }}>
              <div className={classes.name} title={inspector?.name}>
                {inspector?.name}
              </div>

              {inspector?.region && <div className={classes.location}>{inspector?.region}</div>}

              {getInspectorWarningMessage(inspector)}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

InspectorListComponent.propTypes = {
  busy: PropTypes.bool.isRequired,
  inspectors: PropTypes.array,
};

InspectorListComponent.defaultProps = {
  inspectors: null,
};

export default InspectorListComponent;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let StepPartiesInvolved = props => {
  if (props.on) {
    return (
      <SvgIcon {...props}>
        <g>
          <path
            fill="#869E41"
            d="M42.5,22C32.3,22,24,30.444,24,40.826c0,10.379,8.3,18.825,18.5,18.825h1.206h1.999v2v3.974
                    C54.968,59.957,61,50.333,61,40.826C61,30.444,52.701,22,42.5,22z"
          />
          <path
            fill="#869E41"
            d="M43,0C19.252,0,0,19.252,0,43c0,23.749,19.252,43,43,43s43-19.251,43-43C86,19.252,66.748,0,43,0z
                    M43.706,69.001V61.65H42.5C31.189,61.65,22,52.314,22,40.826C22,29.335,31.189,20,42.5,20C53.81,20,63,29.335,63,40.826
                    C63,51.85,55.426,63.268,43.706,69.001z"
          />
        </g>
      </SvgIcon>
    );
  } else {
    return (
      <SvgIcon {...props}>
        <g>
          <path
            fill="#D7D7D7"
            d="M43,3c22.056,0,40,17.944,40,40c0,22.056-17.944,40-40,40C20.944,83,3,65.056,3,43C3,20.944,20.944,3,43,3
                    M43,0C19.252,0,0,19.252,0,43c0,23.749,19.252,43,43,43s43-19.251,43-43C86,19.252,66.748,0,43,0L43,0z"
          />
        </g>
        <path
          fill="#D7D7D7"
          d="M42.5,20C31.189,20,22,29.335,22,40.826C22,52.314,31.189,61.65,42.5,61.65h1.206v7.351
                C55.426,63.268,63,51.85,63,40.826C63,29.335,53.81,20,42.5,20z M45.705,65.624V61.65v-2h-1.999H42.5
                C32.3,59.65,24,51.205,24,40.826C24,30.444,32.3,22,42.5,22S61,30.444,61,40.826C61,50.333,54.968,59.957,45.705,65.624z"
        />
      </SvgIcon>
    );
  }
};

StepPartiesInvolved = pure(StepPartiesInvolved);
StepPartiesInvolved.displayName = 'StepPartiesInvolved';
StepPartiesInvolved.muiName = 'SvgIcon';

export default StepPartiesInvolved;

import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import PropTypes from 'prop-types';

const styles = {
  bootstrapInput: {
    borderRadius: 4,
    border: '1px solid #ced4da',
    fontSize: 'calc(6px + 0.3vw)',
    padding: '8px 12px',
    width: 'calc(100% - 24px)',
    '&:focus': {
      borderColor: '#80bdff',
    },
    fontFamily: 'Open Sans',
  },
  label: {
    fontFamily: 'Open Sans',
    marginTop: '0.3vh',
    marginBottom: '0.5rem',
    fontSize: 'calc(6px + 0.3vw)',
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  plusButton: {
    backgroundColor: '#5882C1',
    boxShadow: 'none',
    color: '#ffffff',
    height: '22px',
    width: '22px',
    minHeight: '22px',
    minWidth: '22px',
  },
  removeButton: {
    backgroundColor: '#E93F36',
    boxShadow: 'none',
    color: '#ffffff',
    height: '22px',
    width: '22px',
    minHeight: '22px',
    minWidth: '22px',
  },
};

const ArrayFieldTemplate = props => {
  const { classes, className, disabled, items, onAddClick, title, uiSchema } = props;

  let visibilityStyle = {};

  if (uiSchema && uiSchema.items && uiSchema.items['ui:hidden']) {
    visibilityStyle = { display: 'none' };
  }

  return (
    <div className={className} style={visibilityStyle}>
      {items &&
        items.map(element => (
          <div key={element.index} style={visibilityStyle}>
            <Grid container alignItems="center">
              <Grid item xs={11}>
                <label style={styles.label} htmlFor={element.children.props.idSchema.$id}>
                  {`${title} ${element.index + 1}`}
                </label>
                <div>{element.children}</div>
              </Grid>
              <Grid item alignItems="center" xs={1}>
                {items.length > 1 && (
                  <Grid item xs={12} style={{ marginBottom: '12px' }}>
                    <div style={styles.flex}>
                      <Button
                        disabled={disabled}
                        variant="fab"
                        mini
                        onClick={element.onDropIndexClick(element.index)}
                        classes={{ mini: classes.removeButton }}
                        data-cy={`${String(title).toLowerCase()}-remove`}
                      >
                        <RemoveIcon />
                      </Button>
                    </div>
                  </Grid>
                )}
                {element.index === items.length - 1 && (
                  <Grid item xs={12}>
                    <div style={styles.flex}>
                      <Button
                        disabled={disabled}
                        variant="fab"
                        mini
                        onClick={onAddClick}
                        classes={{ mini: classes.plusButton }}
                        data-cy={`${String(title).toLowerCase()}-add`}
                      >
                        <AddIcon />
                      </Button>
                    </div>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
        ))}
    </div>
  );
};

ArrayFieldTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  items: PropTypes.array.isRequired,
  onAddClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  uiSchema: PropTypes.object.isRequired,
};

ArrayFieldTemplate.defaultProps = {
  disabled: false,
};

export default withStyles(styles)(ArrayFieldTemplate);

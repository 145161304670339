import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';

import styles from 'src/orderDetails/ResourcesSection/style.module.css';
import resourcesItemIcon from 'src/png-icons/mini_icon_recursos.png';
import ResourcesIcon from 'src/svg-icons/resources';

class ResourcesSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Grid container direction="row" className={styles.Resources}>
        <ResourcesIcon viewBox="0 0 34.016 34.016" className={styles.Icon} />
        <Grid item xs={11}>
          <p className={styles.Header}>
            <FormattedMessage id="resources" />
          </p>
          <List disablePadding>
            <ListItem disableGutters>
              <ListItemIcon classes={{ root: styles.ListIcon }}>
                <img src={resourcesItemIcon} alt="" />
              </ListItemIcon>
              <ListItemText>
                <a
                  href={this.props.data?.envelope_url || this.props.data?.form?.sender_view_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.Link}
                  data-cy="order-details-sender-view-url"
                >
                  <FormattedMessage id="SIGNED_FILES" />
                </a>
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    );
  }
}

ResourcesSection.propTypes = {
  data: PropTypes.object,
};

ResourcesSection.defaultProps = {
  data: null,
};

export default ResourcesSection;

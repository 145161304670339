import React from 'react';
import Select from 'react-select';

import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import classes from 'src/components/ForwardModal/style.module.scss';

const ForwardModalComponent = ({ error, franchisees, handleSubmit, validationSchema, intl }) => (
  <div className={classes.forwardModal}>
    <h3>{intl.formatMessage({ id: 'ORDER_FORWARD' })}</h3>

    <Formik initialValues={{ franchiseeId: '' }} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {({ errors, isSubmitting }) => (
        <Form>
          <div className={classes.field}>
            <label htmlFor="franchiseeId">{intl.formatMessage({ id: 'FRANCHISEES' })}</label>

            <Field
              name="franchiseeId"
              id="franchiseeId"
              options={franchisees}
              component={({ options, field, form }) => (
                <Select
                  className={classes.select}
                  classNamePrefix="select"
                  placeholder={intl.formatMessage({ id: 'FRANCHISEES' })}
                  options={options}
                  name={field.name}
                  value={options ? options.find(option => option.value === field.value) : ''}
                  onChange={option => form.setFieldValue(field.name, option.value)}
                  onBlur={field.onBlur}
                />
              )}
            />

            {(error || errors.franchiseeId) && <div className={classes.error}>{error || errors.franchiseeId}</div>}
          </div>

          <div className={`${classes.field} ${classes.actions}`}>
            <button className={`${classes.button} ${classes.isGreen}`} type="submit" disabled={isSubmitting}>
              {intl.formatMessage({ id: 'SEND' })}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  </div>
);

ForwardModalComponent.propTypes = {
  error: PropTypes.string,
  franchisees: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

ForwardModalComponent.defaultProps = {
  error: null,
};

export default ForwardModalComponent;

import React from 'react';

import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import DrawerMenu from 'src/leftDrawer/DrawerMenu';
import DrawerTitle from 'src/leftDrawer/DrawerTitle';

const styles = {
  paper: {
    backgroundColor: '#1C3B5E',
    fontFamily: 'Open Sans',
    width: 220,
  },
};

function LeftDrawer({
  menu,
  handleMenuChange,
  handleSubMenuChange,
  handleCollapseSubMenuChange,
  classes,
  isDisplayingSearchResults,
  subMenuToOpenByIndex,
}) {
  return (
    <Drawer variant="permanent" classes={{ paper: classes.paper }}>
      <DrawerTitle />

      <DrawerMenu
        menu={menu}
        handleMenuChange={handleMenuChange}
        handleSubMenuChange={handleSubMenuChange}
        handleCollapseSubMenuChange={handleCollapseSubMenuChange}
        isDisplayingSearchResults={isDisplayingSearchResults}
        subMenuToOpenByIndex={subMenuToOpenByIndex}
      />
    </Drawer>
  );
}

LeftDrawer.propTypes = {
  /**
   * Array with the menu options
   */
  menu: PropTypes.array.isRequired,
  /**
   * Function to handle the change of the menu
   */
  handleMenuChange: PropTypes.func.isRequired,
  /**
   * Function to handle the change of the sub-menu
   */
  handleSubMenuChange: PropTypes.func.isRequired,
  /**
   * Style classes
   */
  classes: PropTypes.object,
  /**
   * Function to handle the url change of the collapse-menu (submenu inside submenu)
   */
  handleCollapseSubMenuChange: PropTypes.func.isRequired,
  /**
   * Is displaying search results list
   */
  isDisplayingSearchResults: PropTypes.bool,
  /**
   * Default submenu index to open (if it is provided)
   */
  subMenuToOpenByIndex: PropTypes.number,
};

LeftDrawer.defaultProps = {
  classes: {},
  isDisplayingSearchResults: false,
  subMenuToOpenByIndex: null,
};

export default withStyles(styles)(LeftDrawer);

import InspectionsTypes from 'src/store/ducks/inspections/types';

const initialState = {
  currentInspection: null,

  currentInspectionError: null,

  currentInspectionLoading: false,
};

const inspectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case InspectionsTypes.FETCH_INSPECTION_BY_ID_REQUEST: {
      return {
        ...state,
        currentInspectionLoading: true,
      };
    }

    case InspectionsTypes.FETCH_INSPECTION_BY_ID_SUCCESS: {
      return {
        ...state,
        currentInspection: action.payload,
        currentInspectionLoading: false,
      };
    }

    case InspectionsTypes.FETCH_INSPECTION_BY_ID_ERROR: {
      return {
        ...state,
        currentInspectionError: action.payload,
        currentInspectionLoading: false,
      };
    }

    case InspectionsTypes.CLEAR_CURRENT_INSPECTION: {
      return {
        ...state,
        currentInspection: null,
        currentInspectionError: null,
      };
    }

    default: {
      return state;
    }
  }
};

export default inspectionsReducer;

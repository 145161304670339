/**
 * Attaches the given timeString to the dateInstance (generates a new instance of it)
 *
 * @param {Date} dateInstance A JS Date class instance
 * @param {string} timeString A string like "HH:SS"
 * @returns Date
 */
export const setDateTimeFromTimeString = (dateInstance, timeString = '') => {
  const [hours, minutes] = timeString.split(':').map(Number);

  const newDate = new Date(dateInstance);
  newDate.setHours(hours, minutes, 0, 0);

  return newDate;
};

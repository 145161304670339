import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { components as ReactSelectBaseComponents } from 'react-select';
import AsyncSelect from 'react-select/async';

import MenuItem from '@material-ui/core/MenuItem';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import classes from 'src/inspectionAttach/InspectionSearch.module.css';

const Option = ({ children, innerProps, innerRef, isFocused, isSelected }) => (
  <MenuItem
    buttonRef={innerRef}
    selected={isFocused}
    component="div"
    style={{
      fontWeight: isSelected ? 500 : 400,
    }}
    {...innerProps}
  >
    {children}
  </MenuItem>
);

const LoadingMessage = ({ getStyles, innerProps, ...props }) => (
  <div {...innerProps} style={getStyles('loadingMessage', props)}>
    <FormattedMessage id="loading" />
  </div>
);

const NoOptionsMessage = ({ getStyles, innerProps, ...props }) => (
  <div {...innerProps} style={getStyles('loadingMessage', props)}>
    <FormattedMessage id="noOption" />
  </div>
);

const components = {
  Option,
  LoadingMessage,
  NoOptionsMessage,
  Input: inputProps => <ReactSelectBaseComponents.Input {...inputProps} data-cy="inspection-search-select" />,
};

const InspectionSearch = ({ apiUrl, handleSelect, searchPlaceholder }) => {
  const [value, setValue] = useState('');

  const onChange = selectValue => {
    setValue(selectValue);
    handleSelect(selectValue);
  };

  const handleFetchSuggestions = (inputValue, callback) => {
    if (!inputValue) callback([]);

    API.get(apiUrl + inputValue).then(({ data }) => {
      const mappedSuggestions = data?.map(suggestion => {
        const label = suggestion?.code ? `${suggestion?.identifier} (${suggestion?.code})` : suggestion?.identifier;

        return {
          value: suggestion?.id,
          label,
          ...suggestion,
        };
      });

      callback(mappedSuggestions || []);
    });
  };

  const handleFetchSuggestionsDebounced = debounce(handleFetchSuggestions, 500);

  return (
    <div className={classes.Search}>
      <AsyncSelect
        value={value}
        placeholder={searchPlaceholder}
        components={components}
        loadOptions={handleFetchSuggestionsDebounced}
        onChange={onChange}
      />
    </div>
  );
};

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.func,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
};

LoadingMessage.propTypes = {
  getStyles: PropTypes.func,
  innerProps: PropTypes.object,
};

NoOptionsMessage.propTypes = {
  getStyles: PropTypes.func,
  innerProps: PropTypes.object,
};

InspectionSearch.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  apiUrl: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string,
};

export default InspectionSearch;

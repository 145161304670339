import React, { useState, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import * as yup from 'yup';

import API from 'src/apiRequest';
import ForwardModalComponent from 'src/components/ForwardModal/ForwardModalComponent';
import { showToast } from 'src/store/ducks/toasts';

const ForwardModalContainer = ({ orderId, closeModal }) => {
  const [error, setError] = useState(null);
  const [franchiseesOptions, setFranchiseesOptions] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await API.put(`/orders/${orderId}/franchisees`, { id: values.franchiseeId });

      dispatch(
        showToast({
          title: intl.formatMessage({ id: 'SUCCESS' }),
          text: intl.formatMessage({ id: 'ORDER_FORWARD_SUCCESSFULLY' }),
          type: 'success',
          duration: 4000,
        })
      );

      setSubmitting(false);
      closeModal();

      history.push('/orders');
    } catch (err) {
      setSubmitting(false);

      console.debug('[DEBUG] ForwardModalContainer handleSubmit error ', err);

      setError(intl.formatMessage({ id: 'ORDER_FORWARD_ERROR' }));
    }
  };

  useEffect(() => {
    async function fetchFranchisees() {
      const { data } = await API.get('/franchisees', { params: { format: 'order_forward' } });

      setFranchiseesOptions(data?.map(franchisee => ({ label: franchisee.name, value: franchisee.id })) || []);
    }

    fetchFranchisees();
  }, []);

  const validationSchema = useMemo(
    () => yup.object().shape({ franchiseeId: yup.string().required(intl.formatMessage({ id: 'REQUIRED_FIELD' })) }),
    [intl]
  );

  return (
    <ForwardModalComponent
      error={error}
      franchisees={franchiseesOptions}
      handleSubmit={handleSubmit}
      validationSchema={validationSchema}
      intl={intl}
    />
  );
};

ForwardModalContainer.propTypes = {
  orderId: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ForwardModalContainer;

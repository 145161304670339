import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const Address = props => (
  <SvgIcon {...props} viewBox="0 0 34.016 34.016">
    <path
      fill="#393939"
      d="M17.008,2.008c-8.271,0-15,6.729-15,15c0,8.271,6.729,15,15,15c8.271,0,15-6.729,15-15
  		C32.008,8.737,25.279,2.008,17.008,2.008z M17.008,30.008c-7.168,0-13-5.832-13-13c0-7.168,5.832-13,13-13c7.168,0,13,5.832,13,13
  		C30.008,24.176,24.176,30.008,17.008,30.008z"
    />
    <path
      fill="#393939"
      d="M17.008,7.648c-3.732,0-6.75,3.018-6.75,6.75c0,5.063,6.75,12.536,6.75,12.536s6.75-7.473,6.75-12.536
  		C23.758,10.666,20.739,7.648,17.008,7.648z M17.008,16.809c-1.331,0-2.411-1.081-2.411-2.411s1.08-2.411,2.411-2.411
  		c1.331,0,2.411,1.08,2.411,2.411S18.339,16.809,17.008,16.809z"
    />
  </SvgIcon>
);

Address.displayName = 'Address';
Address.muiName = 'SvgIcon';

export default Address;

import React from 'react';
import ReactDOM from 'react-dom';
import JssProvider from 'react-jss/lib/JssProvider';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';

import App from 'src/App';
import ClientsPublicPage from 'src/components/ClientsPublicPage';
import store from 'src/store';

import 'src/index.scss';

const generateClassName = createGenerateClassName();
const jss = create(jssPreset());
jss.options.insertionPoint = document.getElementById('jss-insertion-point');

const rootEl = document.getElementById('root');

const renderApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <BrowserRouter>
          <Switch>
            <Route path="/new-client/:hash" component={ClientsPublicPage} />

            <Route path="/" component={App} />
          </Switch>
        </BrowserRouter>
      </JssProvider>
    </Provider>,
    rootEl
  );
};

renderApp();

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let TypeChaves = props => (
  <SvgIcon {...props} viewBox="0 0 34.016 34.016">
    <path
      fill="#879E43"
      d="M29.312,29.137l-0.622-2.121l-2.177-0.606l-0.623-2.122
	l-1.701-0.474c-1.875,0.985-4.258,0.73-5.839-0.814c-1.933-1.883-1.933-4.936,0-6.819c1.932-1.884,5.064-1.884,6.997,0
	c1.521,1.482,1.825,3.683,0.951,5.473l5.346,5.21l0.233,2.501L29.312,29.137z M22.082,17.544c-0.645-0.627-1.688-0.627-2.332,0
	c-0.645,0.628-0.645,1.646,0,2.273c0.645,0.627,1.688,0.627,2.332,0C22.727,19.189,22.727,18.172,22.082,17.544z M21.815,13.449
	c-3.461,0-6.267,2.735-6.267,6.108c0,3.373,2.806,6.108,6.267,6.108c1.047,0,2.025-0.263,2.892-0.706l0.737,2.634h1.978v0.643
	c0,1.066-0.886,1.93-1.978,1.93H8.292c-1.093,0-1.979-0.863-1.979-1.93V7.02c0-1.065,0.886-1.93,1.979-1.93h5.278l-3.299,1.287
	v5.144h13.194V6.377L20.167,5.09h5.277c1.092,0,1.978,0.864,1.978,1.93v9.822C26.397,14.833,24.275,13.449,21.815,13.449z
	 M22.146,10.235H11.59V7.663l3.299-1.286V4.448c0-1.065,0.227-1.286,1.319-1.286h1.319c1.093,0,1.319,0.221,1.319,1.286v1.929
	l3.299,1.286V10.235z"
    />
  </SvgIcon>
);
TypeChaves = pure(TypeChaves);
TypeChaves.displayName = 'TypeChaves';
TypeChaves.muiName = 'SvgIcon';

export default TypeChaves;

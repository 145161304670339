import React from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import Select from 'react-select';

import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import classes from 'src/inspectionAttach/DevicesFilter.module.css';

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

const LoadingMessage = props => {
  return (
    <div {...props} style={props.getStyles('loadingMessage', props)}>
      <FormattedMessage id="loading" />
    </div>
  );
};

const components = {
  Option,
  LoadingMessage,
};

class DevicesFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      loading: true,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    API.get(this.props.apiUrl)
      .then(res => {
        this.setState({
          suggestions: res.data,
          loading: false,
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleChange = name => value => {
    this.setState({
      [name]: value,
    });
    this.props.handleSelect(value);
  };

  render() {
    return (
      <div className={classes.Search}>
        <Select
          options={this.state.suggestions.map(suggestion => ({
            value: suggestion.id,
            label: suggestion.name,
            ...suggestion,
          }))}
          components={components}
          value={this.state.single}
          name={this.props.name}
          onChange={this.handleChange('single')}
          placeholder={this.props.searchPlaceholder}
          isLoading={this.state.loading}
          backspaceRemovesValue={false}
          autoFocus
        />
      </div>
    );
  }
}

DevicesFilter.propTypes = {
  /**
   * Function to handle the selection
   */
  handleSelect: PropTypes.func.isRequired,
  /**
   * URL of the api to make the search
   */
  apiUrl: PropTypes.string.isRequired,
  /**
   * String for the placeholder of the search bar
   */
  searchPlaceholder: PropTypes.string,
  /**
   * Generate an HTML input with this name, containing the current value
   */
  name: PropTypes.string,
};

export default injectIntl(DevicesFilter);

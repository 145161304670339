import React from 'react';
import { injectIntl } from 'react-intl';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import FeatureSwitch from 'src/components/common/FeatureSwitch';
import classes from 'src/components/ReportOptions/style.module.scss';
import { useAsyncService } from 'src/contexts/AsyncServiceContext';
import UserContext from 'src/contexts/UserContext';
import ReportItem from 'src/svg-icons/report-item';
import ReportItemDownload from 'src/svg-icons/report-item-download';
import ReportItemDownloadAll from 'src/svg-icons/report-item-download-all';
import ReportPhotos from 'src/svg-icons/report-photos';
import ReportPrint from 'src/svg-icons/report-print';

const ReportOptions = ({ anchorEl, closeHandler, entityId, intl, options, show, flags }) => {
  const { showReportLoadingToast } = useAsyncService();
  
  const me = JSON.parse(sessionStorage.getItem('me'));

  const close = () => {
    closeHandler();
  };

  const handleOptionSelected = async (reportOptions, picture, version) => {
    const isInspection = !Number.isNaN(parseFloat(entityId)) && Number.isFinite(entityId);
    const entityType = isInspection ? 'inspections' : 'orders';

    const payload = {
      ...reportOptions,
      withPictures: picture,
      version,
    };

    try {
      close();
      const { data } = await API.post(`${entityType}/${entityId}/report`, payload);

      if (data?.flag === 'cache') return;

      showReportLoadingToast({
        label: payload?.label || '',
        requestId: data?.request_id,
        withPictures: !!payload?.withPictures,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleDownloadAll = (reportOptions, picture, version) => {
    reportOptions.map(option => handleOptionSelected(option, picture, version));
  };

  const getOptionsEl = () => {
    let optionsEl = [];

    const filter = {
      damages: flags?.has_damages !== false,
      repair_budget: !!flags?.has_repair_budget,
      signature: !!flags?.has_signature,
      divergence: !!flags?.has_finished_divergence,
      predial: me.profile?.franchisee_type !== 'OFFICE' && me.type !== 'OFFICE'
    };

    if (show === true) {
      const filteredOptions = options.filter(option =>
        typeof filter[option.type] === 'undefined' ? true : filter[option.type]
      );
      const optionsElPhotos = filteredOptions.map((option, index) => (
        <>
          {index === 0 && (
            <>
              <li className={classes.listTitle}>
                <div className={classes.reportIcon}>
                  <ReportPhotos />
                </div>
                {intl.formatMessage({ id: 'REPORT.WITH_PICTURES' })}
              </li>
              <li>
                <button
                  type="button"
                  className={classes.button}
                  onClick={() => handleDownloadAll(filteredOptions, true, 2)}
                >
                  <div className={classes.reportIcon}>
                    <ReportItemDownloadAll />
                    {intl.formatMessage({ id: 'REPORT.DOWNLOADALLPHOTOS' })}
                  </div>

                  <div className={classes.reportIconDownload}>
                    <ReportItemDownload />
                  </div>
                </button>
              </li>
            </>
          )}
          <FeatureSwitch feature={option.tags ? 'REPORT_DAMAGE_TAGS' : ''} key={`${option.type}-${option.label}`}>
            <li>
              <button
                type="button"
                className={classes.button}
                onClick={() => handleOptionSelected(option, true, 2)}
                data-cy={`${String(option.label).toLowerCase().replaceAll(' ', '-')}-report-with-pictures-option`}
              >
                <div className={classes.reportIcon}>
                  <ReportItem />
                  {option.label}
                </div>

                <div className={classes.reportIconDownload}>
                  <ReportItemDownload />
                </div>
              </button>
            </li>
          </FeatureSwitch>
        </>
      ));
      const optionsElPrintFormat = filteredOptions.map((option, index) => (
        <>
          {index === 0 && (
            <>
              <li className={classes.listTitle}>
                <div className={classes.reportIcon}>
                  <ReportPrint />
                </div>
                {intl.formatMessage({ id: 'REPORT.WITHOUT_PICTURES' })}
              </li>
              <li>
                <button
                  type="button"
                  className={classes.button}
                  onClick={() => handleDownloadAll(filteredOptions, false, 2)}
                >
                  <div className={classes.reportIcon}>
                    <ReportItemDownloadAll />
                    {intl.formatMessage({ id: 'REPORT.DOWNLOADALL' })}
                  </div>

                  <div className={classes.reportIconDownload}>
                    <ReportItemDownload />
                  </div>
                </button>
              </li>
            </>
          )}
          <FeatureSwitch feature={option.tags ? 'REPORT_DAMAGE_TAGS' : ''} key={`${option.type}-${option.label}`}>
            <li>
              <button
                type="button"
                className={classes.button}
                onClick={() => handleOptionSelected(option, false, 2)}
                data-cy={`${String(option.label).toLowerCase().replaceAll(' ', '-')}-report-without-pictures-option`}
              >
                <div className={classes.reportIcon}>
                  <ReportItem />
                  {option.label}
                </div>

                <div className={classes.reportIconDownload}>
                  <ReportItemDownload />
                </div>
              </button>
            </li>
          </FeatureSwitch>
        </>
      ));

      optionsEl = [...optionsElPhotos, ...optionsElPrintFormat];

      if (optionsEl.length === 0) {
        optionsEl.push(<li className={classes.listTitle}>{intl.formatMessage({ id: 'REPORT.NONEXISTENT' })}</li>);
      }
    }
    return optionsEl;
  };

  return (
    <UserContext.Consumer>
      {context => (
        <div className={classes.root}>
          <Popper open={show} className={classes.position} anchorEl={anchorEl} placement="bottom" transition>
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={close}>
                    <ul className={classes.reportOptions}>{getOptionsEl(context)}</ul>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      )}
    </UserContext.Consumer>
  );
};

ReportOptions.propTypes = {
  anchorEl: PropTypes.object,
  closeHandler: PropTypes.func.isRequired,
  options: PropTypes.array,
  entityId: PropTypes.string,
  intl: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  flags: PropTypes.object,
};

ReportOptions.defaultProps = {
  anchorEl: undefined,
  entityId: null,
  options: [],
  flags: null,
};

export default injectIntl(ReportOptions);

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Select, { components } from 'react-select';

import PropTypes from 'prop-types';

import RealEstateAddressOption from 'src/orderForm/fields/RealEstateAdressesSelectField/RealEstateAddressOption';
import classes from 'src/orderForm/fields/RealEstateAdressesSelectField/style.module.scss';

const RealEstateAdressesSelectField = ({ formData, onChange, uiSchema }) => {
  const intl = useIntl();

  const realEstateOptions = uiSchema['ui:options'] || [];

  const label = uiSchema['ui:label'];
  const id = uiSchema['ui:id'];

  const mappedAdressesOptions = realEstateOptions?.map(({ public_id, name, address }) => {
    const streetText = address?.street ? `${address?.street}, ` : '';
    const numberText = address?.number ? `${address?.number}, ` : '';
    const neighborhoodText = address?.neighborhood ? `${address?.neighborhood}, ` : '';
    const cityText = address?.city;

    return {
      label: name,
      value: public_id,
      realEstateAddressText: streetText + numberText + neighborhoodText + cityText,
    };
  });

  const isSelectBlockedWithOneOption = !realEstateOptions?.length || realEstateOptions?.length <= 1;

  return (
    <>
      <div className={`${classes.title} ${isSelectBlockedWithOneOption ? classes.hidden : ''}`}>
        <FormattedMessage id={label} />
      </div>

      <Select
        id={id}
        className={`${classes.realEstateAddressSelect} ${isSelectBlockedWithOneOption ? classes.hidden : ''}`}
        options={mappedAdressesOptions || []}
        onChange={option => onChange(option?.value)}
        value={mappedAdressesOptions?.filter(option => option?.value === formData)?.at(0) || null}
        components={{
          Input: inputProps => <components.Input {...inputProps} data-cy={id} />,
          Option: RealEstateAddressOption,
        }}
        placeholder={intl.formatMessage({ id: 'SELECT_A_REAL_ESTATE' })}
        noOptionsMessage={() => intl.formatMessage({ id: '  NO_OPTIONS_AVAILABLE_MESSAGE' })}
        isDisabled={isSelectBlockedWithOneOption}
      />
    </>
  );
};

RealEstateAdressesSelectField.propTypes = {
  formData: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  uiSchema: PropTypes.object,
};

RealEstateAdressesSelectField.defaultProps = {
  formData: null,
  uiSchema: null,
};

export default RealEstateAdressesSelectField;

import React from 'react';
import MaskedInput from 'react-text-mask';

import PropTypes from 'prop-types';

import classes from 'src/components/ClientsPublicPage/ClientFormLink/style.module.scss';
import Radio from 'src/components/common/elements/Radio';
import AddressIcon from 'src/components/common/icons/address.svg';
import folderImage from 'src/components/common/icons/folder-image.svg';
import ResponsibleIcon from 'src/components/common/icons/responsible.svg';
import walletImage from 'src/components/common/icons/wallet2.svg';
import { USERNAME_MASK } from 'src/helpers/mask';

const classificationsNamesTranslations = {
  'CLASSIFICATION.IMOBILIARIA': 'Imobiliária',
  'CLASSIFICATION.CORRETOR_DE_IMOVEIS': 'Corretor de imóveis',
  'CLASSIFICATION.PROPRIETARIO': 'Proprietário',
  'CLASSIFICATION.CONDOMINIO': 'Condomínio',
  'CLASSIFICATION.ADMINISTRADORA_DE_CONDOMINIOS': 'Administradora de condomínios',
  'CLASSIFICATION.SINDICO_PROFISSIONAL': 'Síndico profissional',
  'CLASSIFICATION.CONSTRUTORA_INCORPORADORA': 'Construtora, Incorporadora',
  'CLASSIFICATION.EMPREITEIRA': 'Empreiteira',
  'CLASSIFICATION.INSTITUICAO_FINANCEIRA': 'Instituição Financeira',
  'CLASSIFICATION.REDE_DE_VAREJO': 'Rede de Varejo',
  'CLASSIFICATION.PESSOA_FISICA': 'Pessoa física',
  'CLASSIFICATION.OUTROS': 'Outros',
};

const ClientFormLinkComponent = ({
  busy,
  client,
  classifications,
  states,
  cities,
  type,
  privacyPolicy,
  receiveInformation,
  onChange,
  onSubmit,
  zipcodeMask,
  cnpjMask,
  cpfOnlyMask,
  phoneMask,
  cellphoneMask,
  cellphoneMaskLength,
}) => (
  <div className={classes.clientFormLink}>
    <div className={classes.formContainer}>
      <form onSubmit={onSubmit}>
        <h3 className={classes.subtitle}>
          <div className={classes.icon}>
            <img src={folderImage} alt="Dados cadastrais" />
          </div>
          Dados cadastrais
        </h3>

        <div className={`${classes.field} ${classes.hasRadio}`}>
          <div className={classes.radio}>
            <Radio
              name="client_type"
              className={classes.radioInput}
              id="clientTypePJ"
              data-cy="clientTypePJ"
              value="pj"
              onChange={onChange}
              checked={type === 'pj'}
            />

            <label htmlFor="clientTypePJ">Pessoa jurídica</label>
          </div>

          <div className={classes.radio}>
            <Radio
              name="client_type"
              className={classes.radioInput}
              id="clientTypePF"
              data-cy="clientTypePF"
              value="pf"
              onChange={onChange}
              checked={type === 'pf'}
            />

            <label htmlFor="clientTypePF">Pessoa física</label>
          </div>
        </div>

        <div className={`${classes.field} ${classes.isFlex}`}>
          {type === 'pj' ? (
            <>
              <div className={`${classes.field} ${classes.is6}`}>
                <label htmlFor="name">Nome fantasia*</label>

                <input
                  type="text"
                  name="name"
                  id="name"
                  data-cy="name"
                  required
                  value={client?.name || ''}
                  onChange={onChange}
                />
              </div>
              <div className={`${classes.field} ${classes.is6}`}>
                <label htmlFor="company_name">Razão social*</label>

                <input
                  type="text"
                  name="company_name"
                  id="company_name"
                  data-cy="company_name"
                  required
                  value={client?.company_name || ''}
                  onChange={onChange}
                />
              </div>
            </>
          ) : (
            <div className={`${classes.field} ${classes.is12}`}>
              <label htmlFor="name">Nome*</label>

              <input
                type="text"
                name="name"
                id="name"
                data-cy="name"
                required
                value={client?.name || ''}
                onChange={onChange}
              />
            </div>
          )}
        </div>

        <div className={`${classes.field} ${classes.isFlex}`}>
          <div className={`${classes.field} ${classes.is6}`}>
            <label htmlFor="classification">Categoria*</label>

            <select
              name="classification"
              id="classification"
              data-cy="classification"
              value={client?.classification || ''}
              required
              onChange={onChange}
            >
              <option value="" disabled />

              {classifications.map(classification => (
                <option value={classification} key={classification}>
                  {classificationsNamesTranslations[`CLASSIFICATION.${classification}`]}
                </option>
              ))}
            </select>
          </div>

          {type === 'pj' ? (
            <>
              <div className={`${classes.field} ${classes.is6}`}>
                <label htmlFor="cnpj">CNPJ*</label>

                <MaskedInput
                  type="text"
                  name="document"
                  id="cnpj"
                  data-cy="cnpj"
                  required
                  value={client?.document || ''}
                  onChange={onChange}
                  guide={false}
                  mask={cnpjMask}
                />
              </div>
            </>
          ) : (
            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="cpf">CPF*</label>

              <MaskedInput
                type="text"
                name="document"
                id="cpf"
                data-cy="cpf"
                required
                value={client?.document || ''}
                onChange={onChange}
                guide={false}
                mask={cpfOnlyMask}
              />
            </div>
          )}
        </div>
        <div className={`${classes.field} ${classes.isFlex}`}>
          <div className={`${classes.field} ${classes.is6}`}>
            <label htmlFor="username">Usuário*</label>

            <MaskedInput
              type="text"
              name="username"
              id="username"
              data-cy="username"
              required
              value={client?.username || ''}
              onChange={onChange}
              guide={false}
              mask={USERNAME_MASK}
            />
          </div>

          <div className={`${classes.field} ${classes.is6}`}>
            <label htmlFor="email">E-mail*</label>

            <input
              type="email"
              name="contact[email]"
              id="email"
              data-cy="email"
              required
              value={client?.contact?.email || ''}
              onChange={onChange}
            />
          </div>
        </div>

        <h3 className={classes.subtitle}>
          <div className={classes.icon}>
            <img src={AddressIcon} alt="Endereço" />
          </div>
          Endereço
        </h3>

        <div className={`${classes.field} ${classes.isFlex}`}>
          <div className={`${classes.field} ${classes.is6}`}>
            <label htmlFor="zipcode">CEP*</label>

            <MaskedInput
              type="text"
              name="address[zipcode]"
              id="zipcode"
              data-cy="zipcode"
              value={client?.address?.zipcode || ''}
              onChange={onChange}
              guide={false}
              mask={zipcodeMask}
              required
            />
          </div>
        </div>

        <div className={`${classes.field} ${classes.isFlex}`}>
          <div className={`${classes.field} ${classes.is8}`}>
            <label htmlFor="street">Logradouro*</label>

            <input
              type="text"
              name="address[street]"
              id="street"
              data-cy="street"
              value={client?.address?.street || ''}
              onChange={onChange}
              required
            />
          </div>

          <div className={`${classes.field} ${classes.is4}`}>
            <label htmlFor="number">Número*</label>

            <input
              type="text"
              name="address[number]"
              id="number"
              data-cy="number"
              value={client?.address?.number || ''}
              onChange={onChange}
              required
            />
          </div>
        </div>

        <div className={`${classes.field} ${classes.isFlex}`}>
          <div className={`${classes.field} ${classes.is2}`}>
            <label htmlFor="state">Estato*</label>

            <select
              name="address[state_id]"
              id="state"
              data-cy="state"
              value={client?.address?.state_id || ''}
              onChange={onChange}
              required
            >
              <option value="" disabled />

              {states.map(state => (
                <option value={state.id} key={state.id}>
                  {state.name}
                </option>
              ))}
            </select>
          </div>

          <div className={`${classes.field} ${classes.is5}`}>
            <label htmlFor="city">Cidade*</label>

            <select
              name="address[city_id]"
              id="city"
              data-cy="city"
              value={client?.address?.city_id || ''}
              onChange={onChange}
              required
            >
              <option value="" disabled />

              {cities.map(city => (
                <option value={city.id} key={city.id}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>

          <div className={`${classes.field} ${classes.is5}`}>
            <label htmlFor="neighborhood">Bairro*</label>

            <input
              type="text"
              name="address[neighborhood]"
              id="neighborhood"
              data-cy="neighborhood"
              value={client?.address?.neighborhood || ''}
              onChange={onChange}
              required
            />
          </div>
        </div>

        <div className={`${classes.field} ${classes.isFlex}`}>
          <div className={`${classes.field} ${classes.is12}`}>
            <label htmlFor="complement">Complemento</label>

            <input
              type="text"
              name="address[complement]"
              id="complement"
              data-cy="complement"
              value={client?.address?.complement || ''}
              onChange={onChange}
            />
          </div>
        </div>

        {type === 'pj' && (
          <>
            <h3 className={classes.subtitle}>
              <div className={classes.icon}>
                <img src={ResponsibleIcon} alt="Responsável comercial" />
              </div>
              Responsável comercial
            </h3>

            <div className={classes.field}>
              <label htmlFor="responsibleName">Nome*</label>

              <input
                type="text"
                name="responsible[name]"
                id="responsibleName"
                data-cy="responsibleName"
                required
                value={client?.responsible?.name || ''}
                onChange={onChange}
              />
            </div>

            <div className={`${classes.field} ${classes.isFlex}`}>
              <div className={`${classes.field} ${classes.is6}`}>
                <label htmlFor="responsibleDocument">CPF*</label>

                <MaskedInput
                  type="text"
                  name="responsible[document]"
                  id="responsibleDocument"
                  data-cy="responsibleDocument"
                  required
                  value={client?.responsible?.document || ''}
                  onChange={onChange}
                  guide={false}
                  mask={cpfOnlyMask}
                />
              </div>

              <div className={`${classes.field} ${classes.is6}`}>
                <label htmlFor="responsiblePhone">Telefone</label>

                <MaskedInput
                  type="text"
                  name="responsible[phone]"
                  id="responsiblePhone"
                  data-cy="responsiblePhone"
                  value={client?.responsible?.phone || ''}
                  onChange={onChange}
                  guide={false}
                  mask={value => (value.length >= cellphoneMaskLength ? cellphoneMask : phoneMask)}
                />
              </div>
            </div>

            <div className={`${classes.field} ${classes.isFlex}`}>
              <div className={`${classes.field} ${classes.is6}`}>
                <label htmlFor="responsibleCellphone">Celular</label>

                <MaskedInput
                  type="text"
                  name="responsible[cellphone]"
                  id="responsibleCellphone"
                  data-cy="responsibleCellphone"
                  value={client?.responsible?.cellphone || ''}
                  onChange={onChange}
                  guide={false}
                  mask={value => (value.length >= cellphoneMaskLength ? cellphoneMask : phoneMask)}
                />
              </div>

              <div className={`${classes.field} ${classes.is6}`}>
                <label htmlFor="ResponsibleEmail">E-mail</label>

                <input
                  type="text"
                  name="responsible[email]"
                  id="ResponsibleEmail"
                  data-cy="ResponsibleEmail"
                  value={client?.responsible?.email || ''}
                  onChange={onChange}
                />
              </div>
            </div>
          </>
        )}

        <h3 className={classes.subtitle}>
          <div className={classes.icon}>
            <img src={walletImage} alt="Carteira de imóveis" />
          </div>
          Carteira de imóveis
        </h3>

        <div className={`${classes.field} ${classes.isFlex}`}>
          <div className={`${classes.field} ${classes.is6}`}>
            <label htmlFor="properties_purchase_sell">Imóveis para compra/venda</label>

            <input
              type="number"
              name="propertiesWallet[properties_purchase_sell]"
              id="properties_purchase_sell"
              data-cy="properties_purchase_sell"
              min="0"
              value={client?.propertiesWallet?.properties_purchase_sell || ''}
              onChange={onChange}
            />
          </div>

          <div className={`${classes.field} ${classes.is6}`}>
            <label htmlFor="properties_rent">Imóveis para locação</label>

            <input
              type="number"
              name="propertiesWallet[properties_rent]"
              id="properties_rent"
              data-cy="properties_rent"
              min="0"
              value={client?.propertiesWallet?.properties_rent || ''}
              onChange={onChange}
            />
          </div>
        </div>
        <div className={classes.field}>
          <label htmlFor="potential_inspections">Potencial mensal de Vistorias</label>

          <input
            type="number"
            name="propertiesWallet[potential_inspections]"
            id="potential_inspections"
            data-cy="potential_inspections"
            min="0"
            value={client?.propertiesWallet?.potential_inspections || ''}
            onChange={onChange}
          />
        </div>

        <div className={classes.field}>
          <div className={classes.checkbox}>
            <label>
              <input
                required
                type="checkbox"
                name="privacy_policy"
                id="privacy_policy"
                data-cy="privacy_policy"
                onChange={onChange}
                checked={privacyPolicy}
              />

              <div>
                Eu li e concordo com a{' '}
                <a
                  href="https://s3.amazonaws.com/contrato.redevistorias.com.br/E11C7828-481F-4238-843B-DFFB2CA65E2D/CBDAB8FD-07BD-4F42-84D1-99DBF225AC15.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Política de Privacidade.
                </a>
              </div>
            </label>
          </div>
        </div>

        <div className={classes.field}>
          <div className={classes.checkbox}>
            <label>
              <input
                type="checkbox"
                name="receive_information"
                id="receive_information"
                data-cy="receive_information"
                onChange={onChange}
                checked={receiveInformation}
              />
              Eu concordo em ser contatado para receber informações e atualizações sobre os produtos e serviços.
            </label>
          </div>
        </div>

        <div className={classes.field}>
          <p className={classes.text}>
            Ao marcar esta caixa, você confirma que leu, compreendeu e concorda com os Termos de Serviço e a Política de
            Privacidade da nossa plataforma. Certifique-se de revisar esses documentos antes de prosseguir.
          </p>
        </div>

        <div className={classes.cardFooter}>
          <button type="submit" className={`${classes.button} ${classes.isGreen}`} disabled={busy}>
            Finalizar cadastro
          </button>
        </div>
      </form>
    </div>
  </div>
);

ClientFormLinkComponent.propTypes = {
  busy: PropTypes.bool.isRequired,
  client: PropTypes.object,
  classifications: PropTypes.array.isRequired,
  billingModalities: PropTypes.array.isRequired,
  billingModalitiesPanel: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  type: PropTypes.string,
  privacyPolicy: PropTypes.bool,
  receiveInformation: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  zipcodeMask: PropTypes.array.isRequired,
  cnpjMask: PropTypes.array.isRequired,
  cpfOnlyMask: PropTypes.array.isRequired,
  phoneMask: PropTypes.array.isRequired,
  cellphoneMask: PropTypes.array.isRequired,
  cellphoneMaskLength: PropTypes.number.isRequired,
};

ClientFormLinkComponent.defaultProps = {
  client: null,
  type: null,
};

export default ClientFormLinkComponent;

import React from 'react';

import PropTypes from 'prop-types';

import ClientCard from 'src/components/CommercialPage/ClientList/ClientCard';
import classes from 'src/components/CommercialPage/ClientList/style.module.scss';
import CardList from 'src/components/common/CardList';
import Spinner from 'src/components/common/Spinner';

const ClientListComponent = ({ busy, clients, loadMore, hasMore }) =>
  busy ? (
    <div className={classes.spinner}>
      <Spinner />
    </div>
  ) : (
    <CardList cards={clients} Element={ClientCard} hasMore={hasMore} loadMore={loadMore} />
  );

ClientListComponent.propTypes = {
  busy: PropTypes.bool.isRequired,
  clients: PropTypes.array.isRequired,
  loadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
};

export default ClientListComponent;

import React from 'react';

import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import ColorButton from 'src/components/common/elements/ColorButton';
import classes from 'src/components/RemoveVNR/style.module.scss';

const RemoveVNRComponent = ({ error, validationSchema, handleSubmit, intl }) => (
  <div className={classes.removeVNR}>
    <h3>{intl.formatMessage({ id: 'REMOVE_VNR' })}</h3>
    <Formik
      initialValues={{ category: '', observation: '' }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ errors, isSubmitting }) => (
        <Form>
          <div className={classes.field}>
            <label htmlFor="category">{intl.formatMessage({ id: 'REASON' })}*</label>
            <Field as="select" name="category" id="category">
              <option value=""></option>
              <option value="Vistoria remarcada">Vistoria remarcada</option>
              <option value="Vistoria realizada no mesmo dia">Vistoria realizada no mesmo dia</option>
              <option value="Erro do vistoriador">Erro do vistoriador</option>
              <option value="Outros">Outros</option>
            </Field>
            {errors?.category && <div className={classes.error}>{error || errors.category}</div>}
          </div>
          <div className={classes.field}>
            <label htmlFor="observation">{intl.formatMessage({ id: 'MORE_INFORMATION' })}</label>
            <Field type="text" name="observation" id="observation" />
            {(error || errors.observation) && <div className={classes.error}>{error || errors.observation}</div>}
          </div>
          <div className={`${classes.field} ${classes.actions}`}>
            <ColorButton type="submit" color="green" isBusy={isSubmitting}>
              {intl.formatMessage({ id: 'SEND' })}
            </ColorButton>
          </div>
        </Form>
      )}
    </Formik>
  </div>
);

RemoveVNRComponent.propTypes = {
  error: PropTypes.string,
  validationSchema: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

RemoveVNRComponent.defaultProps = {
  error: null,
};

export default RemoveVNRComponent;

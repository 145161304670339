import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/BudgetDuringInspectionSection/style.module.scss';

const BudgetDuringInspectionSectionGridItemDetails = ({ xs, dataCy, labelId, value }) => (
  <Grid item xs={xs} data-cy={dataCy}>
    <div className={classes.subHeader}>
      <FormattedMessage id={labelId} />
    </div>

    {value}
  </Grid>
);

BudgetDuringInspectionSectionGridItemDetails.propTypes = {
  xs: PropTypes.number,
  dataCy: PropTypes.string.isRequired,
  labelId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

BudgetDuringInspectionSectionGridItemDetails.defaultProps = {
  xs: 12,
};

export default BudgetDuringInspectionSectionGridItemDetails;

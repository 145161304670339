import { APP_COMMERCIAL_PANEL_BASE_URL } from 'src/config';

function getCommercialPanelOpenSaleLink(saleId) {
  const accessToken = sessionStorage.getItem('accessToken');
  const tokenType = sessionStorage.getItem('tokenType');

  return `${APP_COMMERCIAL_PANEL_BASE_URL}/open-orders/${saleId}#access_token=${accessToken}&token_type=${tokenType}&expires_in=`;
}

export default getCommercialPanelOpenSaleLink;

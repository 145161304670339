import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const Service = props => (
  <SvgIcon {...props} viewBox="0 0 60 60">
    <path
      fill="#393939"
      d="M30,0C13.4,0,0,13.4,0,30s13.4,30,30,30s30-13.4,30-30S46.6,0,30,0z M30,58C14.5,58,2,45.5,2,30S14.5,2,30,2
      s28,12.5,28,28S45.5,58,30,58z"
    />
    <path
      fill="#393939"
      d="M35.6,27.7l7,6.1l2.5-2.8l-2.4-8.6L30.6,12.3L25,18.6l5.8,5.1L15,41.9l4.8,4.2L35.6,27.7z"
    />
  </SvgIcon>
);

Service.displayName = 'Service';
Service.muiName = 'SvgIcon';

export default Service;

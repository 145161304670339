import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import Details from 'src/svg-icons/details';

import classes from './style.module.scss';

function LocationReferences({ data }) {
  return (
    <Grid item xs={12}>
      <div className={classes.Header}>
        <Details className={classes.Icon} />
        <FormattedMessage id="construction_details" />
      </div>
      <Grid container spacing={8} className={classes.Information}>
        <Grid item xs={6} data-cy="order-details-work-week">
          <div className={classes.SubHeader}>
            <FormattedMessage id="work_week" />
          </div>
          {data.work_week || '----'}
        </Grid>
        <Grid item xs={6} data-cy="order-details-opening-forecast-date">
          <div className={classes.SubHeader}>
            <FormattedMessage id="opening_forecast_date" />
          </div>
          {data.opening_forecast_date || '----'}
        </Grid>
      </Grid>
      <Grid container spacing={8} className={classes.Information}>
        <Grid item xs={12} data-cy="order-details-work-completion-at-sixty">
          <div className={classes.SubHeader}>
            <FormattedMessage id="work_completion_at_sixty" />
          </div>
          {data.work_completion_at_sixty || '----'}
        </Grid>
      </Grid>
    </Grid>
  );
}

LocationReferences.propTypes = {
  /**
   * Object that contains the address information
   */
  data: PropTypes.object.isRequired,
};

export default LocationReferences;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let TypeEntradaCurtaTemporada = props => (
  <SvgIcon {...props} fill="none" viewBox="0 0 109 123">
    <path
      d="M96.277 8.42872H73.9707C73.491 8.42872 73.0592 8.47662 72.6275 8.62031C72.2437 2.68099 69.3655 0.238213 62.8415 0.238213H57.2289C50.7529 0.238213 47.8267 2.68099 47.4909 8.62031C47.0592 8.47662 46.5795 8.42872 46.0998 8.42872H23.7934C16.8377 8.42872 11.1292 13.9849 11.1292 20.7863V61.5952C11.1292 61.9783 11.2251 62.4094 11.3211 62.7926H4.22141C1.91882 62.7926 0 64.7085 0 67.0555V88.849C0 91.1959 1.91882 93.0639 4.22141 93.0639H11.3211C11.2251 93.4471 11.1292 93.8782 11.1292 94.3093V110.595C11.1292 117.444 16.7897 123 23.7455 123H96.277C103.233 123 108.893 117.444 108.893 110.595V20.7863C108.893 13.9849 103.233 8.42872 96.277 8.42872ZM51.6644 18.0562V9.91355C51.6644 5.41116 52.6238 4.50111 57.2289 4.50111H62.8415C67.4467 4.50111 68.4061 5.41116 68.4061 9.91355V18.1041L82.3655 23.5644V34.3893H37.7529V23.4686L51.6644 18.0562ZM104.672 110.595C104.672 115.145 100.93 118.785 96.325 118.785H23.7455C19.1403 118.785 15.3986 115.145 15.3986 110.595V94.3093H40.5352V105.182H46.0518L76.753 83.3886V75.1981L46.0518 50.7224H40.5352V61.5952H15.3986V20.7863C15.3986 16.2839 19.1882 12.6437 23.7934 12.6437H46.0998L32.1403 18.0562V39.8496H87.9301V18.0562L73.9707 12.6437H96.277C100.882 12.6437 104.624 16.2839 104.624 20.7863V110.595H104.672Z"
      fill="#559AA9"
    />
  </SvgIcon>
);

TypeEntradaCurtaTemporada = pure(TypeEntradaCurtaTemporada);
TypeEntradaCurtaTemporada.displayName = 'TypeEntradaCurtaTemporada';
TypeEntradaCurtaTemporada.muiName = 'SvgIcon';

export default TypeEntradaCurtaTemporada;

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import axios from 'axios';

import API from 'src/apiRequest';
import InspectorListComponent from 'src/components/InspectorsPage/InspectorList/InspectorListComponent';
import { setOptions, setQuery } from 'src/store/ducks/filter';

let source = null;
const InspectorListContainer = () => {
  const params = useParams();
  const status = params.status === 'deleted' ? 'deleted' : 'active';
  const [cards, setCards] = useState(null);
  const [isBusy, setIsBusy] = useState(true);
  const isInitialMount = useRef(true);
  const dispatch = useDispatch();
  const { query, lastReload } = useSelector(({ cards: card, filter }) => ({
    query: filter.query,
    lastReload: card.lastReload,
  }));

  // set possible filter options and clear query
  useEffect(() => {
    if (isInitialMount.current) {
      dispatch(
        setOptions({
          basic: ['text'],
        })
      );
      dispatch(setQuery({}));
    }
  }, [isInitialMount, dispatch]);

  useEffect(() => {
    const fetchCards = async () => {
      if (source !== null) {
        source.cancel();
      }

      source = API.CancelToken.source();

      try {
        setIsBusy(true);
        const { data } = await API.get('/inspectors', {
          params: {
            name: query?.text || null,
            status: status,
          },
          cancelToken: source.token,
        });
        setCards(data);
        setIsBusy(false);
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.log(err);
        }
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      fetchCards();
    }

    return () => {
      if (source) source.cancel();
    };
  }, [query, status, lastReload]);

  return <InspectorListComponent cards={cards} isBusy={isBusy} />;
};

export default InspectorListContainer;

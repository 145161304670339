import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const ReportItemDownload = props => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.699951 14.3003C0.699951 13.8032 1.10289 13.4003 1.59995 13.4003H12.4C12.897 13.4003 13.3 13.8032 13.3 14.3003C13.3 14.7973 12.897 15.2003 12.4 15.2003H1.59995C1.10289 15.2003 0.699951 14.7973 0.699951 14.3003ZM3.66355 7.3639C4.01503 7.01242 4.58487 7.01242 4.93635 7.3639L6.09995 8.5275V1.70029C6.09995 1.20324 6.50289 0.800293 6.99995 0.800293C7.49701 0.800293 7.89995 1.20324 7.89995 1.70029L7.89995 8.5275L9.06356 7.3639C9.41503 7.01242 9.98488 7.01242 10.3363 7.3639C10.6878 7.71537 10.6878 8.28522 10.3363 8.63669L7.63635 11.3367C7.46756 11.5055 7.23865 11.6003 6.99995 11.6003C6.76126 11.6003 6.53234 11.5055 6.36355 11.3367L3.66355 8.63669C3.31208 8.28522 3.31208 7.71537 3.66355 7.3639Z"
      fill="#0485BE"
    />
  </SvgIcon>
);

ReportItemDownload.displayName = 'ReportItemDownload';
ReportItemDownload.muiName = 'SvgIcon';

export default ReportItemDownload;

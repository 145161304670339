import axios from 'axios';

import config from 'src/config';
import statesBr from 'src/data/states-br.json';
import statesPt from 'src/data/states-pt.json';

const zipcodeValidationHandlers = {
  BR: zipcode => /^\d{5}-\d{3}$/.test(zipcode),

  PT: postalCode => /^\d{4}-\d{3}$/.test(postalCode),
};

const zipcodePreparationHandlers = {
  BR: zipcode => zipcode.replace('-', ''),

  PT: postalCode => postalCode.replace('-', ''),
};

const getLocationByZipcodeHandlers = {
  BR: async (instance, zipcode) => {
    try {
      const { data } = await instance.get('/locations', { params: { cep: zipcode } });

      return {
        zipcode: data?.zipcode,
        street: data?.street,
        neighborhood: data?.neighborhood,
        neighborhood_id: data?.neighborhood_id,
        city: data?.city,
        city_id: data?.city_id,
        state: data?.state,
        state_id: data?.state_id,
      };
    } catch (err) {
      if (err.response.status === 404) {
        console.debug(`[DEBUG] getLocationByZipcode ${AddressService.errors.LOCATION_NOT_FOUND}`, err);

        return { error: AddressService.errors.LOCATION_NOT_FOUND };
      }

      console.debug('[DEBUG] getLocationByZipcode error', err);

      return null;
    }
  },

  PT: async (instance, postalCode) => {
    try {
      const { data } = await instance.get(`/codigo_postal/${postalCode}`);

      return {
        zipcode: data?.CP,
        street: Array.isArray(data?.ruas) && data?.ruas?.length ? data.ruas[0] : null,
        neighborhood: null,
        neighborhood_id: null,
        city: data?.Concelho,
        city_id: data?.Concelho,
        state: data?.Distrito,
        state_id: data?.Distrito,
      };
    } catch (err) {
      if (err.response.status === 404) {
        console.debug(`[DEBUG] getLocationByZipcode ${AddressService.errors.LOCATION_NOT_FOUND}`, err);

        return { error: AddressService.errors.LOCATION_NOT_FOUND };
      }

      console.debug('[DEBUG] getLocationByZipcode error', err);

      return null;
    }
  },
};

const getStateHandlers = {
  BR: () => statesBr,

  PT: () => statesPt,
};

const getCitiesByStateHandlers = {
  BR: async (instance, stateId) => {
    try {
      const { data } = await instance.get('/cities', { params: { state_id: stateId } });

      return data;
    } catch {
      return [];
    }
  },

  PT: async (instance, districtName) => {
    try {
      const { data } = await instance.get(`/distrito/${districtName}/municipios`);

      if (!Array.isArray(data?.municipios) || !data?.municipios?.length) return [];

      return data?.municipios?.map(city => ({
        id: city?.nome,
        name: city?.nome,
        tokenized: city?.nome,
      }));
    } catch {
      return [];
    }
  },
};

const getNeighborhoodsByCityHandlers = {
  BR: async (instance, cityId) => {
    try {
      const { data } = await instance.get('/neighborhoods', { params: { city_id: cityId } });

      return data;
    } catch {
      return [];
    }
  },

  PT: async (instance, cityName) => {
    try {
      const { data } = await instance.get(`/municipio/${cityName}/freguesias`);

      if (!Array.isArray(data?.freguesias) || !data?.freguesias?.length) return [];

      return data?.freguesias?.map(neighborhood => ({ id: neighborhood, name: neighborhood }));
    } catch {
      return [];
    }
  },
};

class AddressService {
  #country;
  #apiInstance;

  static errors = {
    LOCATION_NOT_FOUND: 'LOCATION_NOT_FOUND',
  };

  constructor(country = 'BR') {
    this.#country = country;

    this.#makeAddressApiInstance();
  }

  #makeAddressApiInstance() {
    this.#apiInstance = axios.create({
      baseURL: this.#country === 'PT' ? config.PT_ADDRESS_API_BASE_URL : config.RV_ADDRESS_API_BASE_URL,
    });
  }

  #prepareZipcode(zipcodeIdentifier) {
    return zipcodePreparationHandlers[this.#country](zipcodeIdentifier);
  }

  validateZipcode(zipcodeIdentifier) {
    if (typeof zipcodeIdentifier !== 'string') return;

    return zipcodeValidationHandlers[this.#country](zipcodeIdentifier);
  }

  async getLocationByZipcode(zipcodeIdentifier) {
    if (!this.validateZipcode(zipcodeIdentifier)) return;

    zipcodeIdentifier = this.#prepareZipcode(zipcodeIdentifier);

    return getLocationByZipcodeHandlers[this.#country](this.#apiInstance, zipcodeIdentifier);
  }

  getStates() {
    return getStateHandlers[this.#country](this.#apiInstance);
  }

  async getCitiesByState(stateIdentifier) {
    return getCitiesByStateHandlers[this.#country](this.#apiInstance, stateIdentifier);
  }

  async getNeighborhoodsByCity(cityIdentifier) {
    return getNeighborhoodsByCityHandlers[this.#country](this.#apiInstance, cityIdentifier);
  }
}

export default AddressService;

import React from 'react';
import { useIntl } from 'react-intl';

import PropTypes from 'prop-types';

import ExtraActionsComponent from 'src/components/ExtraActions/ExtraActionsComponent';
import { orderType as parseOrderType } from 'src/constants';

const ExtraActionsContainer = ({ changePage, orderData, orderType }) => {
  const intl = useIntl();

  const features = JSON.parse(window.sessionStorage.getItem('me')).features.map(feature => parseOrderType.get(feature));

  const orderTypeConfigurations = JSON.parse(window.sessionStorage.getItem('orderTypeConfigurations'));

  const shouldShowButton = feature =>
    orderTypeConfigurations &&
    orderTypeConfigurations[orderType] &&
    orderTypeConfigurations[orderType][orderData.status] &&
    orderTypeConfigurations[orderType][orderData.status].extraActions &&
    orderTypeConfigurations[orderType][orderData.status].extraActions.indexOf(feature) > -1 &&
    features.indexOf(feature) > -1;

  return (
    <ExtraActionsComponent
      changePage={changePage}
      intl={intl}
      orderData={orderData}
      shouldShowButton={shouldShowButton}
    />
  );
};

ExtraActionsContainer.propTypes = {
  changePage: PropTypes.func.isRequired,
  orderData: PropTypes.object,
  orderType: PropTypes.string.isRequired,
};

ExtraActionsContainer.defaultProps = {
  orderData: null,
};

export default ExtraActionsContainer;

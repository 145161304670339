import React from 'react';

import logoRede from 'src/images/logo_rede.svg';

const styles = {
  title: {
    backgroundImage: `url(${logoRede})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '120px auto',
    backgroundPosition: 'center',
    width: '100%',
    paddingBottom: 70,
  },
};
function DrawerTitle() {
  return <div style={styles.title} />;
}

export default DrawerTitle;

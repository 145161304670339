import API from 'src/apiRequest';
import InspectionsTypes from 'src/store/ducks/inspections/types';

const InspectionsCreators = {
  fetchInspectionByIdRequest: () => ({
    type: InspectionsTypes.FETCH_INSPECTION_BY_ID_REQUEST,
  }),

  fetchInspectionByIdSuccess: inspection => ({
    type: InspectionsTypes.FETCH_INSPECTION_BY_ID_SUCCESS,
    payload: inspection,
  }),

  fetchInspectionByIdError: error => ({
    type: InspectionsTypes.FETCH_INSPECTION_BY_ID_ERROR,
    payload: error,
  }),

  clearCurrentInspection: () => ({
    type: InspectionsTypes.CLEAR_CURRENT_INSPECTION,
  }),
};

export function fetchInspectionById(inspectionId) {
  return async dispatch => {
    dispatch(InspectionsCreators.fetchInspectionByIdRequest());

    try {
      const inspection = await API.get(`view/inspections/${inspectionId}`);

      dispatch(InspectionsCreators.fetchInspectionByIdSuccess(inspection.data));
    } catch (err) {
      dispatch(InspectionsCreators.fetchInspectionByIdError(err));

      console.error('fetchInspectionById', err);
    }
  };
}

export function clearCurrentInspection() {
  return dispatch => dispatch(InspectionsCreators.clearCurrentInspection());
}

/**
 * @prettier
 **/
import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let ShieldIcon = props => (
  <SvgIcon {...props}>
    <path
      fill="#626262"
      d="M11,16.9c0,0,4.3-1.8,4.3-4c0-3.2,0-3.9,0-6C12.1,6.5,11,5.5,11,5.5V16.9z"
    />
    <path
      fill="#626262"
      d="M17.8,4.2C12.3,4.2,11,2,11,2S9.7,4.2,4.2,4.2c-0.1,0-0.1,0-0.2,0c0,3.2,0,4.3,0,9.3c0,3.6,7,6.5,7,6.5
		s7-2.9,7-6.5c0-4.9,0-6.1,0-9.3C17.9,4.2,17.9,4.2,17.8,4.2z M16.4,13.5c0,1.6-3,3.7-5.4,4.8c-2.4-1.1-5.4-3.2-5.4-4.8V5.7
		C8.3,5.5,10,4.8,11,4.1c1,0.7,2.7,1.4,5.4,1.6V13.5z"
    />
  </SvgIcon>
);

ShieldIcon = pure(ShieldIcon);
ShieldIcon.displaName = 'ShieldIcon';
ShieldIcon.muiName = 'SvgIcon';

export default ShieldIcon;

import React from 'react';
import { FormattedMessage, FormattedDate, FormattedNumber } from 'react-intl';

import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';

import PlanSwitch from 'src/components/common/PlanSwitch';
import OrderIcon from 'src/components/OrderIcon/OrderIcon';
import classes from 'src/components/OrderItem/OrderItem.module.scss';
import Forward from 'src/svg-icons/Forward';
import StatusExpressa from 'src/svg-icons/status-expressa';
import StatusModality from 'src/svg-icons/status-modality';
import StatusPremium from 'src/svg-icons/status-premium';

function OrderItem({ checkOne, data, fromPage, inspectionItemSelect, isClientPanel, selectedCheckboxes, user }) {
  const paperStyle = [classes.Paper];

  if (user.authRole === 'ROLE_FRANCHISEE') {
    if (data?.status === 'WAITING') {
      paperStyle.push(classes.active);
    } else if (fromPage === 'independent') {
      paperStyle.push(classes.active);
    }
  }

  if (inspectionItemSelect) paperStyle.push(classes.selected);

  let title = null;

  if (fromPage !== 'client' && fromPage !== 'independent') {
    title = (
      <h2>
        <OrderIcon orderType={data.type} />
        <span>{data.identifier}</span>
        &#160; &#9679; &#160;
        <span>{data.client}</span>
      </h2>
    );
  } else {
    title = (
      <h2>
        <OrderIcon orderType={data.type} />
        <span>{data.identifier}</span>
      </h2>
    );
  }

  const owner = data.inspector;
  const userFranchisee = user?.franchiseeId || user?.profile?.franchisee_id || null;
  const franchiseeNameIsVisible = data?.franchisee_name && (data?.franchisee_id !== userFranchisee);

  return (
    <Paper elevation={0} className={paperStyle.join(' ')}>
      <div className={classes.container}>
        <div className={classes.action}>
          <Checkbox
            checked={selectedCheckboxes.has(data.id)}
            onClick={e => checkOne(data.id, e)}
            color="primary"
            data-cy={`${data?.id}-order-item-checkbox`}
          />
        </div>

        <div className={classes.details}>
          <div className={classes.rowContainer}>
            <div className={classes.leftItem} data-cy={`${data?.id}-order-item-title`}>
              {title}
            </div>

            <div className={classes.rightItem} data-cy={`${data?.id}-order-item-created-at`}>
              <FormattedDate value={data.created_at} />
            </div>
          </div>

          <div className={classes.rowContainer}>
            <div className={classes.leftItem} data-cy={`${data?.id}-order-item-type`}>
              {data.type !== null && <FormattedMessage id={data.type || ''} />}
              &#160; &#9679; &#160;
              {data.code}
            </div>

            <div className={classes.rightItem} data-cy={`${data?.id}-order-item-credits`}>
              <PlanSwitch planToHide="CONTROL">
                <FormattedMessage id="OrderItem.credit" />{' '}
                {data.credits !== undefined ? <FormattedNumber value={data.credits / 100} style="decimal" /> : ' -- '}
              </PlanSwitch>
            </div>
          </div>

          <div className={classes.rowContainer}>
            <div className={classes.leftItem} data-cy={`${data?.id}-order-item-owner`}>
              <div>
                {owner} &#160; &#160; {data.inspector_document}
              </div>

              {data.schedule && <div>Agendamento &#160; &#9679; &#160; {data.schedule}</div>}
            </div>

            <div className={`${classes.rightItem} ${classes.flexible}`}>
              {data.client_modality === 'PAY_PER_USE' && (
                <i>
                  <StatusModality viewBox="0 0 16 14" />
                </i>
              )}

              {data.modality === 'premium' && (
                <i>
                  <StatusPremium viewBox="0 0 34.016 34.016" />
                </i>
              )}

              {data.urgency === 'express' && (
                <i>
                  <StatusExpressa viewBox="0 0 34.016 34.016" />
                </i>
              )}

              {data.franchisee_id && data.franchisee_id !== userFranchisee && (
                <i>
                  <Forward />
                </i>
              )}

              <span className={classes.Status} data-cy={`${data?.id}-order-item-status`}>
                {data.status !== null && <FormattedMessage id={data.status} />}
              </span>
            </div>
          </div>

          {franchiseeNameIsVisible && (
            <div className={classes.rowContainer}>
              <b>{data.franchisee_name}</b>
            </div>
          )}

          <div className={classes.tagList} data-cy={`${data?.id}-order-item-tag-list`}>
            {data?.tags?.map(tag => (
              <div className={classes.tagItem} key={tag} data-cy={`${data?.id}-order-item-tag-${tag}-item`}>
                {tag}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Paper>
  );
}

OrderItem.propTypes = {
  data: PropTypes.object,
  inspectionItemSelect: PropTypes.bool,
  checkOne: PropTypes.func,
  selectedCheckboxes: PropTypes.object,
  fromPage: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  isClientPanel: PropTypes.bool.isRequired,
};

export default OrderItem;

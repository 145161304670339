import React from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import PropTypes from 'prop-types';

import Card from 'src/components/common/Card';
import ColorButton from 'src/components/common/elements/ColorButton';
import accountFilterIcon from 'src/components/common/icons/account-filter.svg';
import keyIcon from 'src/components/common/icons/key.svg';
import Spinner from 'src/components/common/Spinner';
import AvailabilityCalendar from 'src/components/InspectorsPage/InspectorFiltersDetails/AvailabilityCalendar';
import classes from 'src/components/InspectorsPage/InspectorFiltersDetails/style.module.scss';

const InspectorFiltersDetailsComponent = ({
  isSubmitingData,
  isLoadingData,
  inspectorId,

  select,
  handleSelectAllNeighborhoods,
  unselect,

  states,
  cities,
  neighborhoods,
  orderTypes,

  loadedCities,
  loadedNeighborhoods,

  selectedNeighborhoods,
  selectedOrderTypes,

  isCityLoading,
  isCitySelectLoading,

  isNeighborhoodLoading,
  isNeighborhoodSelectLoading,

  handleFetchCities,
  handleFetchNeighborhoods,
  handleLoadOptions,
  handleSubmit,

  handleLoadMoreCities,
  handleLoadMoreNeighborhoods,

  handleSelectCalendarDateRange,
  handleDeleteCalendarDateRange,
  schedules,

  intl,
}) => (
  <div className={classes.inspectorFiltersForm}>
    <Card className="fade-in">
      <h2 className={classes.cardHead}>
        <div className={classes.back}>
          <Link to={`/inspectors/active/${inspectorId}`}>
            <div className={classes.arrowLeft} />
          </Link>
        </div>
        <div className={classes.icon}>
          <img src={accountFilterIcon} alt={intl.formatMessage({ id: 'EDIT_FILTER_INFORMATION_MESSAGE' })} />
        </div>
        {intl.formatMessage({ id: 'EDIT_FILTER_INFORMATION_MESSAGE' })}
      </h2>
      <h3 className={classes.subtitle}>
        <div className={classes.icon}>
          <img src={keyIcon} alt={intl.formatMessage({ id: 'REQUIREMENTS' })} />
        </div>
        {intl.formatMessage({ id: 'REQUIREMENTS' })}
      </h3>
      <form onSubmit={handleSubmit}>
        <div className={classes.sectionTitle}>
          <h4>{intl.formatMessage({ id: 'NEIGHBORHOODS' })}</h4>
          <p>{intl.formatMessage({ id: 'FILTER_INFORMATION_MESSAGE' })}</p>
        </div>
        <div className={classes.addressInputsWrapper}>
          <div className={classes.selectAddress}>
            <h5 className={classes.subtitle}>{intl.formatMessage({ id: 'STATE' })}</h5>
            <Select
              id="states"
              name="states"
              placeholder={intl.formatMessage({ id: 'STATES' })}
              className={classes.addressSelect}
              classNamePrefix="select"
              isClearable
              options={states}
              noOptionsMessage={() => intl.formatMessage({ id: 'STATE_NOT_FOUND_MESSAGE' })}
              isDisabled={states.length === 0}
              onChange={handleFetchCities}
            />
            <h5 className={classes.subtitle}>{intl.formatMessage({ id: 'CITY' })}</h5>
            <AsyncSelect
              id="cities"
              name="cities"
              placeholder={intl.formatMessage({ id: 'CITIES' })}
              className={classes.addressSelect}
              classNamePrefix="select"
              isClearable
              loadOptions={(inputValue, callback) => handleLoadOptions(inputValue, callback, 'cities')}
              defaultOptions={loadedCities}
              loadingMessage={() => intl.formatMessage({ id: 'CITY_LOADING_MESSAGE' })}
              noOptionsMessage={() => intl.formatMessage({ id: 'CITY_NOT_FOUND_MESSAGE' })}
              isLoading={isCitySelectLoading}
              isDisabled={states.length === 0 || cities.length === 0 || isCityLoading}
              onChange={handleFetchNeighborhoods}
              onMenuScrollToBottom={handleLoadMoreCities}
            />
            <div className={classes.neighborhoodSubtitle}>
              <h5 className={classes.subtitle}>{intl.formatMessage({ id: 'NEIGHBORHOOD' })}</h5>
              <button type="button" onClick={handleSelectAllNeighborhoods} disabled={!loadedNeighborhoods?.length}>
                {intl.formatMessage({ id: 'ADD_ALL' })}
              </button>
            </div>
            <AsyncSelect
              id="neighborhoods"
              name="neighborhoods"
              placeholder={intl.formatMessage({ id: 'NEIGHBORHOODS' })}
              className={classes.addressSelect}
              classNamePrefix="select"
              closeMenuOnSelect={false}
              loadOptions={(inputValue, callback) => handleLoadOptions(inputValue, callback, 'neighborhoods')}
              defaultOptions={loadedNeighborhoods}
              loadingMessage={() => intl.formatMessage({ id: 'NEIGHBORHOOD_LOADING_MESSAGE' })}
              noOptionsMessage={() => intl.formatMessage({ id: 'NEIGHBORHOOD_NOT_FOUND_MESSAGE' })}
              isLoading={isNeighborhoodSelectLoading}
              isDisabled={
                states.length === 0 ||
                cities.length === 0 ||
                neighborhoods.length === 0 ||
                isCityLoading ||
                isNeighborhoodLoading
              }
              onChange={neighborhood => select(neighborhood, neighborhood?.value?.index, 'neighborhoods')}
              onMenuScrollToBottom={handleLoadMoreNeighborhoods}
              {...((neighborhoods.length === 0 || loadedNeighborhoods.length === 0) && { menuIsOpen: false })}
            />
          </div>
          <div id={classes.selectedNeighborhoodsList} className={classes.list}>
            <ul>
              {isLoadingData ? (
                <div className={classes.spinner}>
                  <Spinner />
                </div>
              ) : (
                selectedNeighborhoods.map((selectedNeighborhood, index) => (
                  <li key={selectedNeighborhood?.value?.id}>
                    <button type="button" onClick={() => unselect(selectedNeighborhood, index, 'neighborhoods')}>
                      <p>
                        <span>{selectedNeighborhood?.value?.name}</span>
                        <span>
                          {selectedNeighborhood?.value?.city} - {selectedNeighborhood?.value?.state}
                        </span>
                      </p>
                    </button>
                  </li>
                ))
              )}
            </ul>
            <p className={classes.helper}>
              {intl.formatMessage({
                id: 'SELECTED_NEIGHBORHOOD_FILTER_MESSAGE',
              })}
            </p>
          </div>
        </div>
        <div className={classes.sectionTitle}>
          <h4>{intl.formatMessage({ id: 'INSPECTION_TYPES' })}</h4>
          <p>{intl.formatMessage({ id: 'SELECT_INSPECTION_TYPES_MESSAGE' })}</p>
        </div>
        <div className={classes.listWrapper}>
          <div className={classes.list}>
            <ul>
              {isLoadingData ? (
                <div className={classes.spinner}>
                  <Spinner />
                </div>
              ) : (
                orderTypes.map((orderTypeItem, index) => (
                  <li key={orderTypeItem?.id}>
                    <button type="button" onClick={() => select(orderTypeItem, index, 'order-types')}>
                      {intl.formatMessage({ id: orderTypeItem?.description })}
                    </button>
                  </li>
                ))
              )}
            </ul>
            <p className={classes.helper}>
              {intl.formatMessage({
                id: 'NOT_SELECT_INSPECTION_TYPES_MESSAGE',
              })}
            </p>
          </div>
          <div className={classes.list}>
            <ul>
              {isLoadingData ? (
                <div className={classes.spinner}>
                  <Spinner />
                </div>
              ) : (
                selectedOrderTypes.map((selectedOrderType, index) => (
                  <li key={selectedOrderType?.id}>
                    <button type="button" onClick={() => unselect(selectedOrderType, index, 'order-types')}>
                      {intl.formatMessage({
                        id: selectedOrderType?.description,
                      })}
                    </button>
                  </li>
                ))
              )}
            </ul>
            <p className={classes.helper}>{intl.formatMessage({ id: 'SELECTED_INSPECTION_TYPES_MESSAGE' })}</p>
          </div>
        </div>
        <div className={classes.sectionTitle}>
          <h4>{intl.formatMessage({ id: 'AVAILABILITY' })}</h4>
          <p>{intl.formatMessage({ id: 'INSPECTOR_AVAILABILITY_MESSAGE' })}</p>
        </div>
        <div className={classes.scheduleWrapper}>
          <AvailabilityCalendar
            handleSelectCalendarDateRange={handleSelectCalendarDateRange}
            handleDeleteCalendarDateRange={handleDeleteCalendarDateRange}
            schedules={schedules}
          />
        </div>
        <div className={classes.cardFooter}>
          <Link to={`/inspectors/active/${inspectorId}`} className={classes.button} disabled={isSubmitingData}>
            {intl.formatMessage({ id: 'CANCEL' })}
          </Link>
          <ColorButton type="submit" color="green" isBusy={isSubmitingData} disabled={isLoadingData}>
            {intl.formatMessage({ id: 'SAVE' })}
          </ColorButton>
        </div>
      </form>
    </Card>
  </div>
);

InspectorFiltersDetailsComponent.propTypes = {
  isSubmitingData: PropTypes.bool.isRequired,
  isLoadingData: PropTypes.bool.isRequired,
  inspectorId: PropTypes.string.isRequired,

  select: PropTypes.func.isRequired,
  handleSelectAllNeighborhoods: PropTypes.func.isRequired,
  unselect: PropTypes.func.isRequired,

  orderTypes: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  neighborhoods: PropTypes.array.isRequired,

  loadedCities: PropTypes.array.isRequired,
  loadedNeighborhoods: PropTypes.array.isRequired,

  selectedNeighborhoods: PropTypes.array.isRequired,
  selectedOrderTypes: PropTypes.array.isRequired,

  isCityLoading: PropTypes.bool.isRequired,
  isCitySelectLoading: PropTypes.bool.isRequired,

  isNeighborhoodLoading: PropTypes.bool.isRequired,
  isNeighborhoodSelectLoading: PropTypes.bool.isRequired,

  handleFetchCities: PropTypes.func.isRequired,
  handleFetchNeighborhoods: PropTypes.func.isRequired,
  handleLoadOptions: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  handleLoadMoreCities: PropTypes.func.isRequired,
  handleLoadMoreNeighborhoods: PropTypes.func.isRequired,

  handleSelectCalendarDateRange: PropTypes.func.isRequired,
  handleDeleteCalendarDateRange: PropTypes.func.isRequired,
  schedules: PropTypes.array.isRequired,

  intl: PropTypes.object.isRequired,
};

export default InspectorFiltersDetailsComponent;

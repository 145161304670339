import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import api from 'src/apiRequest';
import ServiceComponent from 'src/components/MarketplacePage/Service/ServiceComponent';
import { showToast } from 'src/store/ducks/toasts';

const ServiceContainer = () => {
  const [service, setService] = useState(null);
  const [formData, setFormData] = useState({});
  const [busy, setBusy] = useState(true);
  const [formBusy, setFormBusy] = useState(false);
  const { service: serviceSlug } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const location = useLocation();
  const { type } = location?.state || {};

  const schema = useMemo(
    () => ({
      type: 'object',
      required: ['name'],
      properties: {
        name: {
          type: 'string',
          title: intl.formatMessage({ id: 'NAME' }),
          description: intl.formatMessage({ id: 'DOCUSIGN_NAME_FORM_MESSAGE' }),
        },
      },
    }),
    [intl]
  );

  useEffect(() => {
    const fetchService = async () => {
      try {
        setBusy(true);
        const endpoint = type === 'erp' ? `/erp/services/${serviceSlug}` : `/marketplace/${serviceSlug}`;
        const { data } = await api.get(endpoint);
        setService(data);
        setFormData(data?.data || {});
        setBusy(false);
      } catch (err) {
        dispatch(
          showToast({
            type: 'error',
            title: intl.formatMessage({ id: 'ERROR' }),
            text: intl.formatMessage({ id: 'SERVICE_LOAD_ERROR' }),
            duration: 5000,
          })
        );
        history.push('/marketplace');
        console.log(err);
      }
    };

    fetchService();
  }, [dispatch, history, serviceSlug, location, type, intl]);

  const handleDisableClick = async () => {
    try {
      await api.delete(`/erp/services/${service.slug}`);
      setFormData({
        hooks: [],
        apiKey: '',
      });
      setService(oldState => ({
        ...oldState,
        enabled: false,
        status: 'AVAILABLE',
      }));
      dispatch(
        showToast({
          type: 'success',
          title: intl.formatMessage({ id: 'SUCCESS' }),
          text: `${intl.formatMessage({
            id: 'INTEGRATION_WITH_SERVICE_MESSAGE',
          })} ${service.name} ${intl.formatMessage({
            id: 'HAS_BEEN_DEACTIVATED_MESSAGE',
          })}`,
          duration: 5000,
        })
      );
    } catch (err) {
      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: `${intl.formatMessage({ id: 'SERVICE_DEACTIVATION_ERROR' })} ${service.name}, ${intl.formatMessage({
            id: 'TRY_AGAIN_LATER_MESSAGE',
          })}`,
          duration: 5000,
        })
      );
    }
  };

  const handleChange = ({ formData: data }) => {
    setFormData(data);
  };

  const handleSubmit = async ({ formData: values }) => {
    let action = 'create';
    let endpoint = '/erp/services';
    let payload = {
      ...values,
      client: service.slug,
    };

    if (service.enabled || service.status === 'ENABLED') {
      action = 'edit';
    }

    if (type !== 'erp') {
      endpoint = '/marketplace';
      payload = {
        item: serviceSlug,
        name: values.name,
      };
    }

    try {
      setFormBusy(true);
      let data;
      if (action === 'create') {
        ({ data } = await api.post(endpoint, payload));
      } else {
        await api.put(`${endpoint}/${service.public_id || service.slug}`, payload);
      }

      if (type !== 'erp') {
        setService(oldState => ({ ...oldState, enabled: true }));
      } else {
        setService(oldState => ({ ...oldState, status: 'ENABLED' }));
        if (action === 'create') setFormData(oldState => ({ ...oldState, apiKey: data.api_key }));
      }

      const text =
        action === 'create'
          ? `${intl.formatMessage({
            id: 'INTEGRATION_WITH_SERVICE_MESSAGE',
          })} ${service.name} ${intl.formatMessage({
            id: 'WAS_SUCCESSFULLY_DONE_MESSAGE',
          })}`
          : `${intl.formatMessage({
            id: 'INTEGRATION_WITH_SERVICE_MESSAGE',
          })} ${service.name} ${intl.formatMessage({
            id: 'SUCCESSFULLY_UPDATED_MESSAGE',
          })}`;
      dispatch(
        showToast({
          type: 'success',
          title: intl.formatMessage({ id: 'SUCCESS' }),
          text,
          duration: 5000,
        })
      );
    } catch (err) {
      const text =
        action === 'create'
          ? `${intl.formatMessage({
            id: 'SERVICE_INTEGRATION_ACTIVATION_ERROR',
          })} ${service.name}, ${intl.formatMessage({
            id: 'TRY_AGAIN_LATER_MESSAGE',
          })}`
          : `${intl.formatMessage({
            id: 'SERVICE_INTEGRATION_UPDATE_ERROR',
          })} ${service.name}, ${intl.formatMessage({
            id: 'TRY_AGAIN_LATER_MESSAGE',
          })}`;
      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text,
          duration: 5000,
        })
      );
    } finally {
      setFormBusy(false);
    }
  };

  const handleError = errors => {
    console.log('errors', errors);
  };

  return (
    <ServiceComponent
      busy={busy}
      formBusy={formBusy}
      schema={service?.schema?.form || schema}
      formData={formData}
      onChange={handleChange}
      onDisableClick={handleDisableClick}
      onSubmit={handleSubmit}
      onError={handleError}
      service={service}
      type={type}
      intl={intl}
    />
  );
};

export default ServiceContainer;

/**
 * @prettier
 **/
import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let StatusDoneIcon = props => (
  <SvgIcon {...props}>
    <path
      fill="#E2E1E1"
      d="M26,0C11.641,0,0,11.641,0,26s11.641,26,26,26s26-11.641,26-26S40.359,0,26,0z M26,50.001
		C12.746,50.001,2,39.255,2,26C2,12.746,12.746,2,26,2c13.255,0,24.001,10.746,24.001,24C50.001,39.255,39.255,50.001,26,50.001z"
    />
    <polygon
      fill="#E2E1E1"
      points="21.487,34.036 13.543,24.458 11.995,25.823 21.262,36.997 41.006,19.595 39.682,17.999 	"
    />
  </SvgIcon>
);

StatusDoneIcon = pure(StatusDoneIcon);
StatusDoneIcon.displaName = 'StatusDoneIcon';
StatusDoneIcon.muiName = 'SvgIcon';

export default StatusDoneIcon;

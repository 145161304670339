import API from 'src/apiRequest';

// Actions
const ACTIONS = {
  SYNC: 'tags/SYNC',
};

// Reducer
const initialState = [];
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.SYNC:
      if (!action.payload) return state;
      return action.payload.map(item => ({
        value: item.name,
        label: item.name,
      }));
    default:
      return state;
  }
}

// Actions
export const syncTags = () => async dispatch => {
  try {
    const { data: tags } = await API.get('orders/tags');

    dispatch({
      type: ACTIONS.SYNC,
      payload: tags,
    });
  } catch (err) {
    dispatch({
      type: ACTIONS.SYNC,
      payload: null,
    });
  }
};

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let TypeAvaliacaoImobiliaria = props => (
  <SvgIcon {...props} viewBox="0 0 34.016 34.016">
    <path
      fill="#503C77"
      d="M19,28.9v-0.9v-2.8v-2.2l1.9,1c0.5,0.3,1.2,0.5,2,0.7c0.2,0.1,0.4,0.1,0.7,0.2v-0.9c-1.6-0.6-2.7-1.3-3.4-2.1
		c-0.8-0.9-1.2-2-1.2-3.3c0-1.4,0.6-2.7,1.7-3.6c0.8-0.6,1.7-1.1,2.9-1.3v-0.5v-1.3h1.3h1.5h1v-5c0-1.1-0.9-1.9-2-1.9h-5.2l3.3,1.3
		v5.1H10.3V6.2l3.3-1.3H8.4c-1.1,0-2,0.9-2,1.9l0,21.1c0,1.1,0.9,1.9,2,1.9h13.3c-0.7-0.2-1.3-0.4-1.9-0.6L19,28.9z"
    />
    <path
      fill="#503C77"
      d="M11.7,10.1h10.5V7.5l-3.3-1.3V4.3c0-1.1-0.2-1.3-1.3-1.3h-1.3c-1.1,0-1.3,0.2-1.3,1.3v1.9l-3.3,1.3V10.1z"
    />
    <path
      fill="#503C77"
      d="M30.7,23.3c-0.3-0.5-0.7-0.9-1.3-1.3c-0.6-0.4-1.6-0.9-3-1.4v-3.2c1.2,0.1,2.3,0.4,3.4,0.8l1-2.5
      c-1.3-0.6-2.8-0.9-4.4-1v-1.6h-1.5v1.6c-1.5,0.1-2.6,0.5-3.4,1.2c-0.8,0.7-1.2,1.5-1.2,2.6c0,1,0.3,1.8,0.9,2.4
      c0.6,0.7,1.6,1.3,3,1.8l0.7,0.3v3.3c-0.7,0-1.5-0.2-2.3-0.4c-0.9-0.2-1.6-0.5-2.2-0.8v2.8c1.3,0.6,2.8,0.9,4.6,0.9v2.2h1.5v-2.2
      c1.5-0.1,2.6-0.5,3.4-1.2c0.8-0.7,1.2-1.6,1.2-2.7C31.1,24.3,30.9,23.8,30.7,23.3z M24.9,20c-0.5-0.2-0.8-0.4-1.1-0.6
      c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.6,0.5-1,1.4-1.1V20z M26.4,26.4v-2.7c0.5,0.2,0.8,0.4,1.1,0.6c0.2,0.2,0.4,0.5,0.4,0.8
      C27.9,25.8,27.4,26.2,26.4,26.4z"
    />
  </SvgIcon>
);
TypeAvaliacaoImobiliaria = pure(TypeAvaliacaoImobiliaria);
TypeAvaliacaoImobiliaria.displayName = 'TypeAvaliacaoImobiliaria';
TypeAvaliacaoImobiliaria.muiName = 'SvgIcon';

export default TypeAvaliacaoImobiliaria;

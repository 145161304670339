import React from 'react';

import Archive from '@material-ui/icons/Archive';
import pure from 'recompose/pure';

let SectionArquivadas = props => <Archive {...props} />;
SectionArquivadas = pure(SectionArquivadas);
SectionArquivadas.displayName = 'SectionArquivadas';
SectionArquivadas.muiName = 'SvgIcon';

export default SectionArquivadas;

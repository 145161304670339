import React from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';

import PropTypes from 'prop-types';

import classes from 'src/components/OrdersReportsModal/style.module.scss';

const MultipleSelect = ({ placeholder, options, field, form, ...props }) => {
  const intl = useIntl();

  return (
    <Select
      className={classes.select}
      classNamePrefix="select"
      name={field.name}
      value={field.value}
      placeholder={placeholder}
      options={options}
      noOptionsMessage={() => intl.formatMessage({ id: 'NO_SELECT_OPTIONS' })}
      onChange={values => form.setFieldValue(field.name, values || [])}
      onBlur={field.onBlur}
      isMulti
      isClearable
      closeMenuOnSelect
      {...props}
    />
  );
};

MultipleSelect.propTypes = {
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

export default MultipleSelect;

import React from 'react';
import { useIntl } from 'react-intl';

import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';

import classes from 'src/components/common/ConfirmAlert/style.module.scss';
import { useConfirmAlert } from 'src/contexts/ConfirmAlertContext';

const ConfirmAlert = () => {
  const intl = useIntl();
  const { isOpen, message, onOk, onCancel } = useConfirmAlert();

  return (
    <Dialog className={classes.confirmAlert} open={isOpen}>
      <DialogTitle>{message}</DialogTitle>
      <DialogActions>
        <Button className={classes.okButton} onClick={onOk} tabIndex={2}>
          {intl.formatMessage({ id: 'OK' })}
        </Button>
        <Button className={classes.cancelButton} onClick={onCancel} tabIndex={1} autoFocus>
          {intl.formatMessage({ id: 'CANCEL' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmAlert;

import React from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as InspectionFinalized } from 'src/components/common/icons/activity-log/inspection-finalized.svg';
import { ReactComponent as InspectionScheduled } from 'src/components/common/icons/activity-log/inspection-scheduled.svg';
import { ReactComponent as OrderCreated } from 'src/components/common/icons/activity-log/order-created.svg';
import { ReactComponent as OrderSent } from 'src/components/common/icons/activity-log/order-sent.svg';
import { ReactComponent as PriceChanged} from 'src/components/common/icons/activity-log/price-changed.svg';
import { ReactComponent as ReportDownloaded } from 'src/components/common/icons/activity-log/report-downloaded.svg';

const icon = {
  // DIVERGENCE_SENT: null,
  INSPECTION_FINALIZED: <InspectionFinalized />,
  INSPECTION_SCHEDULED: <InspectionScheduled />,
  // ORDER_ACCEPTED: null,
  // ORDER_CANCELED: null,
  ORDER_CREATED: <OrderCreated />,
  // ORDER_REJECTED: null,
  // ORDER_RETURNED: null,
  ORDER_SENT: <OrderSent />,
  REPORT_DOWNLOADED: <ReportDownloaded />,
  ORDER_PRICE_CHANGED: <PriceChanged />,
};

const Icon = ({ action }) => icon[action] || <InspectionFinalized />;

Icon.propTypes = {
  action: PropTypes.string.isRequired,
};

export default Icon;

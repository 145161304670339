import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import * as yup from 'yup';

import API from 'src/apiRequest';
import RemoveVNRComponent from 'src/components/RemoveVNR/RemoveVNRComponent';
import { showToast } from 'src/store/ducks/toasts';

const RemoveVNRContainer = ({ orderId, closeModal, refreshHandler, updateOrderItem }) => {
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const intl = useIntl();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        category: yup.string().required(intl.formatMessage({ id: 'REQUIRED_FIELD' })),
        observation: yup.string().when('category', {
          is: 'Outros',
          then: yup.string().required(intl.formatMessage({ id: 'REQUIRED_FIELD' })),
        }),
      }),
    [intl]
  );

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { data } = await API.put(`orders/${orderId}/remove-vnr`, {
        reason: {
          category: values.category,
          observation: values.observation,
        },
      });
      refreshHandler();
      updateOrderItem({ ...data });
      dispatch(
        showToast({
          type: 'success',
          title: intl.formatMessage({ id: 'SUCCESS' }),
          text: intl.formatMessage({
            id: 'VNR_REMOVED',
          }),
          duration: 5000,
        })
      );
      closeModal();
    } catch (err) {
      console.log(err?.response || err);
      setError(
        err?.response?.status === 500 || !err?.response?.data?.message
          ? intl.formatMessage({ id: 'UNKNOWN_ERROR' })
          : intl.formatMessage({ id: err.response.data.message })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <RemoveVNRComponent error={error} validationSchema={validationSchema} handleSubmit={handleSubmit} intl={intl} />
  );
};

RemoveVNRContainer.propTypes = {
  orderId: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  refreshHandler: PropTypes.func.isRequired,
  updateOrderItem: PropTypes.func.isRequired,
};

export default RemoveVNRContainer;

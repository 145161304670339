import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import styles from 'src/orderDetails/SignersSection/style.module.css';
import Client from 'src/svg-icons/client';
import DeclinedIcon from 'src/svg-icons/declined';
import AcceptedIcon from 'src/svg-icons/status-done';
import WaitingIcon from 'src/svg-icons/status-waiting';

const SignersSection = props => {
  const { signers } = props;
  // Renders the status icon
  // Status: PENDING, FINISHED AND DECLINE
  const RenderIcon = ({ status }) => {
    if (status.toLowerCase() === 'pending') return <WaitingIcon viewBox="0 0 55 55" className={styles.StatusIcon} />;
    if (status.toLowerCase() === 'finished') return <AcceptedIcon viewBox="0 0 55 55" className={styles.StatusIcon} />;
    if (status.toLowerCase() === 'declined') return <DeclinedIcon viewBox="0 0 55 55" className={styles.StatusIcon} />;

    return null;
  };

  return (
    <Grid container direction="row" className={styles.signersSection}>
      <Client viewBox="0 0 34.016 34.016" className={styles.Icon} />
      <Grid item xs={11}>
        {signers.map((signer, index) => (
          <div key={signer.email} data-cy="order-details-recipients-signer">
            <p className={styles.Header}>
              <FormattedMessage id="recipients" /> <span>{index + 1}</span>
            </p>
            <div className={styles.SignersContainer}>
              <div className={styles.Information}>
                <p data-cy="order-details-recipients-signer-name">{signer.name}</p>
                <p data-cy="order-details-recipients-signer-email">{signer.email}</p>
              </div>
              <RenderIcon status={signer.status} />
            </div>
          </div>
        ))}
      </Grid>
    </Grid>
  );
};

SignersSection.propTypes = {
  signers: PropTypes.array.isRequired,
};

export default SignersSection;

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = {
  bootstrapInput: {
    borderRadius: 4,
    fontWeight: 'normal',
    border: '1px solid #ced4da',
    fontSize: 'calc(6px + 0.4vw)',
    padding: '8px 12px',
    width: 'calc(100% - 24px)',
    '&:focus': {
      borderColor: '#80bdff',
    },
    fontFamily: 'Open Sans',
    backgroundColor: '#F4F3F4',
  },
  bootstrapInputError: {
    borderRadius: 4,
    border: '1px solid red',
    fontSize: 'calc(6px + 0.4vw)',
    padding: '8px 12px',
    width: 'calc(100% - 24px)',
    '&:focus': {
      borderColor: '#80bdff',
    },
    fontFamily: 'Open Sans',
  },
  label: {
    fontFamily: 'Open Sans',
    marginTop: '0.3vh',
    fontSize: 'calc(6px + 0.3vw)',
  },
};

const RequestFormWidgetPriceString = props => {
  const { classes, id, intl, label, rawErrors, required } = props;
  let error = false;
  if (rawErrors) {
    if (rawErrors.length > 0) {
      error = true;
    }
  }
  return (
    <div>
      {
        <label style={styles.label} htmlFor={id}>
          <FormattedMessage id={label} />
          {required ? ' *' : null}
        </label>
      }
      <TextField
        disabled
        fullWidth
        id={id}
        data-cy={id}
        type="text"
        className="custom"
        value={intl.formatMessage({ id: 'priceToDefine' })}
        InputProps={{
          disableUnderline: true,
          classes: {
            input: error ? classes.bootstrapInputError : classes.bootstrapInput,
          },
        }}
      />
    </div>
  );
};

export default withStyles(styles)(injectIntl(RequestFormWidgetPriceString));

import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import PropTypes from 'prop-types';

const baseButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '2px',
  marginLeft: '-4px',
  cursor: 'pointer',
  background: 'none',
  border: 'none',
  fontWeight: 'normal',
  fontSize: '0.625rem',
};

const styles = {
  addButton: {
    ...baseButtonStyles,
    color: '#007bff',
  },
  removeButton: {
    ...baseButtonStyles,
    color: '#dc3545',
  },
  circle: {
    height: '20px',
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '4px',
    marginBottom: '12px',
  },
};

const SuitorsArrayFieldTemplate = ({ className, disabled, items, onAddClick, title, uiSchema }) => {
  const visibilityStyle = {};

  if (uiSchema && uiSchema.items && uiSchema.items['ui:hidden']) visibilityStyle.display = 'none';

  const renderInitialAddButton = () => (
    <div style={visibilityStyle}>
      <Grid container>
        <div style={styles.buttonsWrapper}>
          <button
            onClick={onAddClick}
            data-cy={`${String(title).toLowerCase()}-add`}
            disabled={disabled}
            style={styles.addButton}
          >
            <AddCircle style={styles.circle} />

            <FormattedMessage id="ADD_SUITOR" />
          </button>
        </div>
      </Grid>
    </div>
  );

  return (
    <div className={className} style={visibilityStyle}>
      {!items?.length && renderInitialAddButton()}

      {items?.map(element => (
        <div key={element.index} style={visibilityStyle}>
          <Grid container>
            <Grid item xs={12}>
              {element.children}
            </Grid>

            <div style={styles.buttonsWrapper}>
              {element.index === items.length - 1 && (
                <button
                  onClick={onAddClick}
                  data-cy={`${String(title).toLowerCase()}-add`}
                  disabled={disabled}
                  style={styles.addButton}
                >
                  <AddCircle style={styles.circle} />

                  <FormattedMessage id="ADD_SUITOR" />
                </button>
              )}

              {items.length > 1 && (
                <button
                  onClick={element.onDropIndexClick(element.index)}
                  data-cy={`${String(title).toLowerCase()}-remove`}
                  disabled={disabled}
                  style={styles.removeButton}
                >
                  <RemoveCircle style={styles.circle} />

                  <FormattedMessage id="REMOVE_SUITOR" />
                </button>
              )}
            </div>
          </Grid>
        </div>
      ))}
    </div>
  );
};

SuitorsArrayFieldTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  items: PropTypes.array.isRequired,
  onAddClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  uiSchema: PropTypes.object.isRequired,
};

SuitorsArrayFieldTemplate.defaultProps = {
  disabled: false,
};

export default withStyles(styles)(SuitorsArrayFieldTemplate);

import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import apiRequest from 'src/apiRequest';
import ClientRegistrationFormComponent from 'src/components/CommercialPage/ClientRegistrationForm/ClientRegistrationFormComponent';
import { useUser } from 'src/contexts/UserContext';
import { parseInputName } from 'src/helpers';
import { masksByCountry } from 'src/helpers/mask';
import AddressService from 'src/services/addressService';
import { showToast } from 'src/store/ducks/toasts';

const classifications = [
  'IMOBILIARIA',
  'CORRETOR_DE_IMOVEIS',
  'PROPRIETARIO',
  'CONDOMINIO',
  'ADMINISTRADORA_DE_CONDOMINIOS',
  'SINDICO_PROFISSIONAL',
  'CONSTRUTORA_INCORPORADORA',
  'EMPREITEIRA',
  'INSTITUICAO_FINANCEIRA',
  'REDE_DE_VAREJO',
  'PESSOA_FISICA',
  'OUTROS',
];

const origins = ['SA', 'UNIDADE', 'FRANQUIA'];

const ClientRegistrationFormContainer = () => {
  const [busy, setBusy] = useState(false);
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState('pj');
  const [client, setClient] = useState({ client_type: 'pj', contact: { assignment_email_rule: 'CREATOR' } });

  const [states, setStates] = useState([]);

  const [cities, setCities] = useState([]);
  const [billingCities, setBillingCities] = useState([]);

  const [neighborhoods, setNeighborhoods] = useState([]);
  const [billingNeighborhoods, setBillingNeighborhoods] = useState([]);
  const [showBillingAddress, setShowBillingAddress] = useState(false);

  const [franchisees, setFranchisees] = useState([]);
  const [selectedFranchisee, setSelectedFranchisee] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();

  const me = useUser();
  const addressService = new AddressService(me?.profile?.country);
  const isBrazilianFranchisee = me?.profile?.country === 'BR' || !me?.profile?.country;
  const zipcodeMask = masksByCountry[me?.profile?.country || 'BR'].ZIPCODE_MASK;
  const cnpjMask = masksByCountry[me?.profile?.country || 'BR'].CNPJ_MASK;
  const cpfOnlyMask = masksByCountry[me?.profile?.country || 'BR'].CPF_ONLY_MASK;
  const phoneMask = masksByCountry[me?.profile?.country || 'BR'].PHONE_MASK;
  const cellphoneMask = masksByCountry[me?.profile?.country || 'BR'].CELLPHONE_MASK;
  const cellphoneMaskLength = masksByCountry[me?.profile?.country || 'BR'].CELLPHONE_MASK_LENGTH;

  const fillNeighborhoods = async (parent, city) => {
    try {
      const data = await addressService.getNeighborhoodsByCity(city);
      setClient(state => ({
        ...state,
        [parent]: {
          ...state[parent],
          neighborhood: null,
          neighborhood_id: null,
        },
      }));
      if (parent === 'address') setNeighborhoods(data);
      if (parent === 'billing_address') setBillingNeighborhoods(data);
    } catch (err) {
      console.debug('[DEBUG] fillNeighborhoods error ', err);
    }
  };

  const fillCities = async (parent, state) => {
    try {
      const data = await addressService.getCitiesByState(state);
      setClient(oldState => ({
        ...oldState,
        [parent]: {
          ...oldState[parent],
          city: null,
          city_id: null,
        },
      }));
      if (parent === 'address') setCities(data);
      if (parent === 'billing_address') setBillingCities(data);
    } catch (err) {
      console.debug('[DEBUG] fillCities error ', err);
    }
  };

  const fillAddress = async (parent, zipcode) => {
    try {
      const address = await addressService.getLocationByZipcode(zipcode);

      await fillCities(parent, address.state_id);
      await fillNeighborhoods(parent, address.city_id);

      setClient({
        ...client,
        [parent]: {
          ...client[parent],
          street: address.street,
          neighborhood: address.neighborhood,
          neighborhood_id: address.neighborhood_id,
          city: address.city,
          city_id: address.city_id,
          state: address.state,
          state_id: address.state_id,
          zipcode,
        },
      });
    } catch (err) {
      console.debug('[DEBUG] fillAddress error ', err);
    }
  };

  const fillFranchisees = async () => {
    try {
      const { data } = await apiRequest.get('/franchisees');

      let formattedFranchisees = [];

      if (Array.isArray(data)) {
        formattedFranchisees = data.map(franchisee => ({
          value: franchisee?.id,
          label: franchisee?.name,
        }));
      }

      setFranchisees(formattedFranchisees);
    } catch (err) {
      console.debug('[DEBUG] fillFranchisees error ', err);

      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'FRANCHISES_LOAD_DATA_ERROR' }),
          duration: 5000,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChange = async evt => {
    const { name } = evt.target;
    let { value } = evt.target;
    const parsed = parseInputName(name, value);
    const parent = Object.keys(parsed)[0];

    if (name === 'client_type') setType(value);

    if (name === 'showBillingAddress') {
      if (value === 'true') {
        const state = { ...client };
        delete state.billing_address;
        setClient(state);
      }

      setShowBillingAddress(value === 'true');
      return;
    }

    if (name.includes('zipcode') && addressService.validateZipcode(value)) {
      await fillAddress(parent, value);
      return;
    }

    if (name.includes('state_id')) {
      await fillCities(parent, value);
      value = isBrazilianFranchisee ? Number(value) : value;
      parsed[parent].state_id = value;
      parsed[parent].state = states.find(state => state.id === value)?.name;
    }

    if (name.includes('city_id')) {
      await fillNeighborhoods(parent, value);
      value = isBrazilianFranchisee ? Number(value) : value;
      parsed[parent].city_id = value;

      if (name.includes('billing_address')) {
        parsed[parent].city = billingCities.find(city => city.id === value)?.name;
      } else {
        parsed[parent].city = cities.find(city => city.id === value)?.name;
      }
    }

    if (name.includes('neighborhood_id')) {
      value = isBrazilianFranchisee ? Number(value) : value;
      parsed[parent].neighborhood_id = value;

      if (name.includes('billing_address')) {
        parsed[parent].neighborhood = billingNeighborhoods.find(neighborhood => neighborhood.id === value)?.name;
      } else {
        parsed[parent].neighborhood = neighborhoods.find(neighborhood => neighborhood.id === value)?.name;
      }
    }

    const parsedValue = typeof parsed[parent] !== 'string' ? { ...client[parent], ...parsed[parent] } : parsed[parent];
    setClient({ ...client, [parent]: parsedValue });
  };

  const handleChangeFranchisee = franchisee => setSelectedFranchisee(franchisee);

  const handleSubmit = async evt => {
    evt.preventDefault();

    if (!selectedFranchisee?.value) {
      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'SELECT_ONE_FRANCHISE_MESSAGE' }),
          duration: 3000,
        })
      );

      return;
    }

    try {
      setBusy(true);

      const payload = {
        ...client,
        franchisee_id: selectedFranchisee.value,
      };

      if (!showBillingAddress) delete payload.billing_address;

      await apiRequest.post('/clients', payload);

      history.replace('/commercial/clients');

      dispatch(
        showToast({
          type: 'success',
          title: intl.formatMessage({ id: 'SUCCESS' }),
          text: intl.formatMessage({ id: 'NEW_CUSTOMER_ADDED_MESSAGE' }),
          duration: 3000,
        })
      );
    } catch (err) {
      console.debug('[DEBUG] handleSubmit error ', err);

      let message;

      if (err.response) {
        const firstKey = Object.keys(err.response.data)[0];

        message = intl.formatMessage({ id: `${firstKey}.${err.response.data[firstKey]}` });
      } else {
        message = intl.formatMessage({ id: 'DATA_SAVE_ERROR' });
      }

      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: message,
          duration: 3000,
        })
      );
    } finally {
      setBusy(false);
    }
  };

  useEffect(() => {
    fillFranchisees();
    setStates(addressService.getStates());
  }, []);

  return (
    <ClientRegistrationFormComponent
      busy={busy}
      client={client}
      franchisees={franchisees}
      loading={loading}
      showBillingAddress={showBillingAddress}
      origins={origins}
      classifications={classifications}
      zipcodeMask={zipcodeMask}
      states={states}
      cities={cities}
      billingCities={billingCities}
      neighborhoods={neighborhoods}
      billingNeighborhoods={billingNeighborhoods}
      type={type}
      intl={intl}
      onChange={handleChange}
      onChangeFranchisee={handleChangeFranchisee}
      onSubmit={handleSubmit}
      cnpjMask={cnpjMask}
      cpfOnlyMask={cpfOnlyMask}
      phoneMask={phoneMask}
      cellphoneMask={cellphoneMask}
      cellphoneMaskLength={cellphoneMaskLength}
    />
  );
};

export default ClientRegistrationFormContainer;

import React from 'react';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = {
  container: {
    fontWeight: 'bold',
    alignItems: 'center',
    paddingLeft: '1vw',
    paddingRight: '1vw',
  },
  header: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fontSize: 'calc(10px + 0.4vw)',
    marginTop: '0.6vw',
    marginBottom: '0.6vw',
  },
  date: {
    fontFamily: 'Open Sans',
    textAlign: 'right',
    fontSize: 'calc(6px + 0.4vw)',
  },
  hr: {
    display: 'block',
    border: 0,
    borderTop: '1px solid #E9E9E9',
    padding: 0,
  },
};

function OrderFormPageHeader({ classes, inspectionIcon, text }) {
  return (
    <Grid container classes={{ container: classes.container }}>
      <Grid item xs={8}>
        <div style={styles.header}>
          <span>{inspectionIcon}</span>
          <span>
            <FormattedMessage id={text} />
          </span>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div style={styles.date}>
          <FormattedDate value={new Date()} />
          &#160; &#9679; &#160;
          <FormattedTime value={new Date()} />
        </div>
      </Grid>
      <Grid item xs={12}>
        <hr style={styles.hr} />
      </Grid>
    </Grid>
  );
}

OrderFormPageHeader.propTypes = {
  /**
   * Object with the inspection icon
   */
  classes: PropTypes.object,
  inspectionIcon: PropTypes.object,
  text: PropTypes.string,
};

OrderFormPageHeader.defaultProps = {
  text: 'orderForm.newOrder',
};

export default withStyles(styles)(OrderFormPageHeader);

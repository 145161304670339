import React from 'react';

import PropTypes from 'prop-types';

import classes from 'src/components/common/elements/ImageUpload/style.module.scss';
import Spinner from 'src/components/common/Spinner';

const ImageUploadComponent = ({ name, id, value, blob, isBusy, image, handleChange }) => (
  <div className={classes.imageUpload}>
    {isBusy && (
      <div className={classes.isBusy}>
        <div className={classes.spinnerWrapper}>
          <Spinner />
        </div>
      </div>
    )}
    <input type="file" id={id} onChange={handleChange} accept="image/*" />
    <label htmlFor={id}>Selecionar imagem...</label>
    {image && (
      <div className={classes.fileInfo}>
        <span className={classes.fileName}>{image.name}</span> (
        {(image.size / 1024).toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
        KB)
      </div>
    )}
    {(value || blob) && (
      <div className={classes.imageWrapper}>
        <img src={value || blob} alt="Imagem da fachada" />
      </div>
    )}
  </div>
);

ImageUploadComponent.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isBusy: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  blob: PropTypes.string,
  value: PropTypes.string,
  image: PropTypes.object,
  remoteUri: PropTypes.object,
};

ImageUploadComponent.defaultProps = {
  blob: null,
  value: null,
  image: null,
  remoteUri: null,
};

export default ImageUploadComponent;

import React from 'react';

import PropTypes from 'prop-types';

import classes from 'src/components/common/elements/ColorButton/style.module.scss';
import Spinner from 'src/components/common/Spinner';

const ColorButtonComponent = ({ children, color, disabled, isBusy, isDone, onClick, ...props }) => (
  <button
    className={`${classes.colorButton} ${classes[`is-${color}`] || ''}`}
    type="button"
    onClick={onClick}
    disabled={disabled || isBusy || isDone}
    {...props}
  >
    {isBusy ? (
      <div className={classes.spinnerWrapper}>
        <Spinner color="inherit" />
      </div>
    ) : (
      children
    )}
  </button>
);

ColorButtonComponent.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  isBusy: PropTypes.bool,
  isDone: PropTypes.bool,
  onClick: PropTypes.func,
};

ColorButtonComponent.defaultProps = {
  color: null,
  disabled: false,
  isBusy: false,
  isDone: false,
  onClick: null,
};

export default ColorButtonComponent;

import React from 'react';
import Select from 'react-select';

import PropTypes from 'prop-types';

import Card from 'src/components/common/Card';
import CurrencyInput from 'src/components/common/Form/CurrencyInput';
import budgetImage from 'src/components/common/icons/budget.svg';
import Spinner from 'src/components/common/Spinner';
import classes from 'src/components/ExtraActions/RepairBudgetForm/style.module.scss';

const RepairBudgetFormComponent = ({
  busy,
  cancel,
  clientPanel,
  currentRoom,
  currentTag,
  filterTag,
  data,
  handleInputChange,
  handleRoomChange,
  handleTagChange,
  intl,
  isLoading,
  onSubmit,
  options,
  optionsTag,
  roomsWithDamages,
}) =>
  isLoading ? (
    <div className={classes.repairBudgetForm} style={clientPanel ? { marginTop: '-15px' } : {}}>
      <div className={classes.spinner}>
        <Spinner />
      </div>
    </div>
  ) : (
    <div className={`${classes.repairBudgetForm} ${clientPanel ? classes.isClientPanel : null}`}>
      <Card>
        <h2 className={classes.cardHead}>
          <div className={classes.icon}>
            <img src={budgetImage} alt={intl.formatMessage({ id: 'DETAILS' })} />
          </div>
          {intl.formatMessage({ id: 'REPAIRS' })}
        </h2>
        <div className={`${classes.field} ${classes.isFlex} ${classes.headActions}`}>
          <div className={`${classes.field} ${classes.is4}`}>
            <label>Exibir</label>
            <Select
              className={classes.select}
              classNamePrefix="select"
              placeholder={`${intl.formatMessage({
                id: 'SELECT_AN_OPTION_MESSAGE',
              })}...`}
              value={optionsTag?.find(option => option.value === currentTag || '')}
              noOptionsMessage={() => intl.formatMessage({ id: 'NO_OPTIONS_AVAILABLE_MESSAGE' })}
              options={optionsTag}
              onChange={handleTagChange}
            />
          </div>
          <div className={`${classes.field} ${classes.is8}`}>
            <label>Selecione o local</label>
            <Select
              className={classes.select}
              classNamePrefix="select"
              placeholder={`${intl.formatMessage({
                id: 'SELECT_AN_OPTION_MESSAGE',
              })}...`}
              noOptionsMessage={() => intl.formatMessage({ id: 'NO_OPTIONS_AVAILABLE_MESSAGE' })}
              options={options}
              onChange={handleRoomChange}
            />
          </div>
        </div>
        {currentRoom ? (
          <div className={classes.damageList}>
            {filterTag?.length ? (
              currentRoom?.value === -1 ? (
                data?.damages?.find(i => filterTag?.some(r => i?.tags?.includes(r))) ? (
                  <h3 className={classes.roomName}>{currentRoom.label}</h3>
                ) : (
                  ''
                )
              ) : roomsWithDamages[currentRoom.value]?.damages?.find(i =>
                filterTag?.some(r => i?.tags?.includes(r))
              ) ? (
                  <h3 className={classes.roomName}>{currentRoom.label}</h3>
                ) : (
                  ''
                )
            ) : (
              <h3 className={classes.roomName}>{currentRoom.label}</h3>
            )}
            {currentRoom.value === -1 &&
              data.damages.map((damage, damageIndex) => {
                if (filterTag?.length) {
                  if (damage?.tags && filterTag?.some(r => damage?.tags?.includes(r))) {
                    return (
                      <div className={classes.damage} key={`${currentRoom.label}-${damageIndex}`}>
                        <label htmlFor="room-damage">{intl.formatMessage({ id: 'DESCRIPTION' })}</label>
                        <p id="room-damage">{damage.description}</p>
                        <div className={`${classes.field} ${classes.isFlex}`}>
                          <div className={`${classes.field} ${classes.is9}`}>
                            <label htmlFor={`observation-G-G-${damageIndex}`}>
                              {intl.formatMessage({ id: 'NOTES_FROM_ADMINISTRATOR' })}
                            </label>
                            <textarea
                              name={`observation-G-G-${damageIndex}`}
                              id={`observation-G-G-${damageIndex}`}
                              value={damage.repairBudget ? damage.repairBudget.observation : ''}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className={`${classes.field} ${classes.is3}`}>
                            <label htmlFor={`price-G-G-${damageIndex}`}>
                              {intl.formatMessage({ id: 'REPAIR_VALUE' })}
                            </label>
                            <CurrencyInput
                              type="text"
                              name={`price-G-G-${damageIndex}`}
                              id={`price-G-G-${damageIndex}`}
                              value={damage.repairBudget ? damage.repairBudget.price : ''}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                } else {
                  return (
                    <div className={classes.damage} key={`${currentRoom.label}-${damageIndex}`}>
                      <label htmlFor="room-damage">{intl.formatMessage({ id: 'DESCRIPTION' })}</label>
                      <p id="room-damage">{damage.description}</p>
                      <div className={`${classes.field} ${classes.isFlex}`}>
                        <div className={`${classes.field} ${classes.is9}`}>
                          <label htmlFor={`observation-G-G-${damageIndex}`}>
                            {intl.formatMessage({ id: 'NOTES_FROM_ADMINISTRATOR' })}
                          </label>
                          <textarea
                            name={`observation-G-G-${damageIndex}`}
                            id={`observation-G-G-${damageIndex}`}
                            value={damage.repairBudget ? damage.repairBudget.observation : ''}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className={`${classes.field} ${classes.is3}`}>
                          <label htmlFor={`price-G-G-${damageIndex}`}>
                            {intl.formatMessage({ id: 'REPAIR_VALUE' })}
                          </label>
                          <CurrencyInput
                            type="text"
                            name={`price-G-G-${damageIndex}`}
                            id={`price-G-G-${damageIndex}`}
                            value={damage.repairBudget ? damage.repairBudget.price : ''}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            {roomsWithDamages[currentRoom.value]?.damages?.map((damage, damageIndex) => {
              if (filterTag?.length) {
                if (damage?.tags && filterTag?.some(r => damage?.tags?.includes(r))) {
                  return (
                    <div className={classes.damage} key={`${currentRoom.label}-${damageIndex}`}>
                      <label htmlFor="room-damage">{intl.formatMessage({ id: 'DESCRIPTION' })}</label>
                      <p id="room-damage">{damage.description}</p>
                      <div className={`${classes.field} ${classes.isFlex}`}>
                        <div className={`${classes.field} ${classes.is9}`}>
                          <label htmlFor={`observation-${currentRoom.value}-R-${damageIndex}`}>
                            {intl.formatMessage({ id: 'NOTES_FROM_ADMINISTRATOR' })}
                          </label>
                          <textarea
                            name={`observation-${currentRoom.value}-R-${damageIndex}`}
                            id={`observation-${currentRoom.value}-R-${damageIndex}`}
                            value={damage.repairBudget ? damage.repairBudget.observation : ''}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className={`${classes.field} ${classes.is3}`}>
                          <label htmlFor={`price-${currentRoom.value}-R-${damageIndex}`}>
                            {intl.formatMessage({ id: 'REPAIR_VALUE' })}
                          </label>
                          <CurrencyInput
                            type="text"
                            name={`price-${currentRoom.value}-R-${damageIndex}`}
                            id={`price-${currentRoom.value}-R-${damageIndex}`}
                            value={damage.repairBudget ? damage.repairBudget.price : ''}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              } else {
                return (
                  <div className={classes.damage} key={`${currentRoom.label}-${damageIndex}`}>
                    <label htmlFor="room-damage">{intl.formatMessage({ id: 'DESCRIPTION' })}</label>
                    <p id="room-damage">{damage.description}</p>
                    <div className={`${classes.field} ${classes.isFlex}`}>
                      <div className={`${classes.field} ${classes.is9}`}>
                        <label htmlFor={`observation-${currentRoom.value}-R-${damageIndex}`}>
                          {intl.formatMessage({ id: 'NOTES_FROM_ADMINISTRATOR' })}
                        </label>
                        <textarea
                          name={`observation-${currentRoom.value}-R-${damageIndex}`}
                          id={`observation-${currentRoom.value}-R-${damageIndex}`}
                          value={damage.repairBudget ? damage.repairBudget.observation : ''}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className={`${classes.field} ${classes.is3}`}>
                        <label htmlFor={`price-${currentRoom.value}-R-${damageIndex}`}>
                          {intl.formatMessage({ id: 'REPAIR_VALUE' })}
                        </label>
                        <CurrencyInput
                          type="text"
                          name={`price-${currentRoom.value}-R-${damageIndex}`}
                          id={`price-${currentRoom.value}-R-${damageIndex}`}
                          value={damage.repairBudget ? damage.repairBudget.price : ''}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                );
              }
            }) || null}
            {roomsWithDamages[currentRoom.value]?.items?.map((item, itemIndex) => {
              if (item?.damages) {
                if (filterTag?.length) {
                  if (item?.damages?.find(i => filterTag?.some(r => i?.tags?.includes(r)))) {
                    return (
                      <div className={classes.room} key={`${currentRoom.value}-${itemIndex}`}>
                        <h3 className={classes.roomName}>{`${+currentRoom.value + 1}.${itemIndex + 1} ${
                          item.name
                        }`}</h3>
                        {item.damages.map((damage, damageIndex) => {
                          if (damage?.tags && filterTag?.some(r => damage?.tags?.includes(r))) {
                            return (
                              <div className={classes.damage} key={`${currentRoom.value}-${itemIndex}-${damageIndex}`}>
                                <label htmlFor="room-damage">{intl.formatMessage({ id: 'DESCRIPTION' })}</label>
                                <p id="room-damage">{damage.description}</p>
                                <div className={`${classes.field} ${classes.isFlex}`}>
                                  <div className={`${classes.field} ${classes.is9}`}>
                                    <label htmlFor={`observation-${currentRoom.value}-${itemIndex}-${damageIndex}`}>
                                      {intl.formatMessage({
                                        id: 'NOTES_FROM_ADMINISTRATOR',
                                      })}
                                    </label>
                                    <textarea
                                      name={`observation-${currentRoom.value}-${itemIndex}-${damageIndex}`}
                                      id={`observation-${currentRoom.value}-${itemIndex}-${damageIndex}`}
                                      value={damage.repairBudget ? damage.repairBudget.observation : ''}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                  <div className={`${classes.field} ${classes.is3}`}>
                                    <label htmlFor={`price-${currentRoom.value}-${itemIndex}-${damageIndex}`}>
                                      {intl.formatMessage({ id: 'REPAIR_VALUE' })}
                                    </label>
                                    <CurrencyInput
                                      type="text"
                                      name={`price-${currentRoom.value}-${itemIndex}-${damageIndex}`}
                                      id={`price-${currentRoom.value}-${itemIndex}-${damageIndex}`}
                                      value={damage.repairBudget ? damage.repairBudget.price : ''}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </div>
                    );
                  }
                } else {
                  if (item?.damages?.length) {
                    return (
                      <div className={classes.room} key={`${currentRoom.value}-${itemIndex}`}>
                        <h3 className={classes.roomName}>{`${+currentRoom.value + 1}.${itemIndex + 1} ${
                          item.name
                        }`}</h3>
                        {item.damages.map((damage, damageIndex) => (
                          <div className={classes.damage} key={`${currentRoom.value}-${itemIndex}-${damageIndex}`}>
                            <label htmlFor="room-damage">{intl.formatMessage({ id: 'DESCRIPTION' })}</label>
                            <p id="room-damage">{damage.description}</p>
                            <div className={`${classes.field} ${classes.isFlex}`}>
                              <div className={`${classes.field} ${classes.is9}`}>
                                <label htmlFor={`observation-${currentRoom.value}-${itemIndex}-${damageIndex}`}>
                                  {intl.formatMessage({
                                    id: 'NOTES_FROM_ADMINISTRATOR',
                                  })}
                                </label>
                                <textarea
                                  name={`observation-${currentRoom.value}-${itemIndex}-${damageIndex}`}
                                  id={`observation-${currentRoom.value}-${itemIndex}-${damageIndex}`}
                                  value={damage.repairBudget ? damage.repairBudget.observation : ''}
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className={`${classes.field} ${classes.is3}`}>
                                <label htmlFor={`price-${currentRoom.value}-${itemIndex}-${damageIndex}`}>
                                  {intl.formatMessage({ id: 'REPAIR_VALUE' })}
                                </label>
                                <CurrencyInput
                                  type="text"
                                  name={`price-${currentRoom.value}-${itemIndex}-${damageIndex}`}
                                  id={`price-${currentRoom.value}-${itemIndex}-${damageIndex}`}
                                  value={damage.repairBudget ? damage.repairBudget.price : ''}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    );
                  }
                }
              }
              return null;
            }) || null}
          </div>
        ) : (
          <p className={`${classes.isCentered} ${classes.borderTop}`}>Selecione um cômodo</p>
        )}
        <div className={classes.cardFooter}>
          <button type="button" className={classes.button} disabled={busy} onClick={cancel}>
            {intl.formatMessage({ id: 'CANCEL' })}
          </button>
          <button type="submit" className={`${classes.button} ${classes.isGreen}`} disabled={busy} onClick={onSubmit}>
            {intl.formatMessage({ id: 'SAVE' })}
          </button>
        </div>
      </Card>
    </div>
  );

RepairBudgetFormComponent.propTypes = {
  busy: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  clientPanel: PropTypes.bool.isRequired,
  currentRoom: PropTypes.object.isRequired,
  filterTag: PropTypes.array.isRequired,
  currentTag: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleRoomChange: PropTypes.func.isRequired,
  handleTagChange: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  optionsTag: PropTypes.array.isRequired,
  roomsWithDamages: PropTypes.object.isRequired,
};

export default RepairBudgetFormComponent;

import React from 'react';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/TagSection/style.module.scss';

function TagSection({ tags }) {
  return (
    <Grid item xs={12}>
      <ul className={classes.tagList} data-cy="order-details-tags">
        {tags.tags.map(item => (
          <li key={item} data-cy={`order-details-tag-${item}`}>
            {item}
          </li>
        ))}
      </ul>
    </Grid>
  );
}

TagSection.propTypes = {
  /**
   * Object that contains the client information
   */
  tags: PropTypes.object.isRequired,
};

export default TagSection;

import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import AddCircle from '@material-ui/icons/AddCircle';
import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import FeatureSwitch from 'src/components/common/FeatureSwitch';
import DeleteAttachment from 'src/components/DeleteAttachment';
import classes from 'src/orderDetails/Attachments.module.scss';
import * as toastDuck from 'src/store/ducks/toasts';
import AttachFile from 'src/svg-icons/attachments';

class Attachments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attachments: props.data,
      loading: false,
    };
  }

  openFileHandler = async (orderId, filename, location) => {
    try {
      const res = await API.get(`/orders/${orderId}/attachments`, {
        params: { filename, location },
      });
      window.open(res.data.url, '_blank');
    } catch (err) {
      console.log(err);
    }
  };

  notifyFileUploadHandler = async (orderId, remoteName, originalName, extension) => {
    try {
      const res = await API.post(`/orders/${orderId}/attachments`, {
        original_name: originalName,
        remote_name: remoteName,
        extension,
      });
      this.setState({ attachments: res.data });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  fileUploadHandler = async (e, orderId) => {
    const { intl, showToast } = this.props;

    try {
      this.setState({ loading: true });

      const file = e.target.files[0];

      if (file?.type !== 'application/pdf') {
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'ATTACH_ONLY_PDF_FILES' }),
          duration: 5000,
        });

        throw new Error('Invalid file type');
      }

      const originalName = file.name;
      const fileExtension = file.name.split('.').pop();
      const res = await API.get('/upload');
      const response = await fetch(res.data.url, {
        method: 'PUT',
        contentType: 'binary/octet-stream',
        processData: false,
        body: file,
      });

      if (response.ok) {
        this.notifyFileUploadHandler(orderId, res.data.remote_name, originalName, fileExtension);
      }
    } catch (err) {
      this.setState({ loading: false });

      console.debug('Attachments.fileUploadHandler error ', err);
    }
  };

  onDelete = remoteName => {
    const { attachments } = this.state;
    const index = attachments.findIndex(attachment => attachment.remote_name === remoteName);
    const updatedAttachments = [...attachments];
    updatedAttachments.splice(index, 1);
    this.setState({
      attachments: updatedAttachments,
    });
  };

  render() {
    const { attachments, loading } = this.state;
    const { canAdd, orderId } = this.props;

    const files = attachments.map(attachment => (
      <li key={attachment.remote_name}>
        <button
          type="button"
          href="#"
          className={classes.link}
          onClick={() => this.openFileHandler(orderId, attachment.remote_name, attachment.location)}
          data-cy="order-details-attachment-original-name-button"
        >
          {attachment.original_name}
        </button>

        <FeatureSwitch feature="DELETE_ATTACHMENT">
          <DeleteAttachment orderId={orderId} remoteName={attachment.remote_name} onDelete={this.onDelete} />
        </FeatureSwitch>
      </li>
    ));

    let buttonAdd = (
      <p>
        <input
          type="file"
          accept="application/pdf"
          id="attachment-upload"
          style={{ display: 'none' }}
          onChange={e => this.fileUploadHandler(e, orderId)}
          data-cy="order-details-attachment-upload-input"
        />

        <label htmlFor="attachment-upload" className={classes.link}>
          <AddCircle style={{ verticalAlign: 'middle', marginRight: '5px' }} />

          <FormattedMessage id="ADD" />
        </label>
      </p>
    );

    if (loading) {
      buttonAdd = (
        <p>
          <FormattedMessage id="sending" />
        </p>
      );
    }

    if (canAdd === false && files.length < 1) {
      return null;
    }

    return (
      <div>
        <div className={classes.header}>
          <AttachFile viewBox="0 0 34.016 34.016" className={classes.icon} />

          <FormattedMessage id="attachments" />
        </div>

        <div className={classes.information}>
          <ol>{files}</ol>

          {canAdd ? buttonAdd : null}
        </div>
      </div>
    );
  }
}

Attachments.propTypes = {
  canAdd: PropTypes.bool.isRequired,
  data: PropTypes.array,
  intl: PropTypes.object.isRequired,
  orderId: PropTypes.string.isRequired,
  showToast: PropTypes.func.isRequired,
};

Attachments.defaultProps = {
  data: [],
};

const mapDispatchToProps = {
  showToast: toastDuck.showToast,
};

export default connect(null, mapDispatchToProps)(injectIntl(Attachments));

import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import classes from 'src/components/ClientsPage/ClientFullDetails/style.module.scss';
import Card from 'src/components/common/Card';
import clientImage from 'src/components/common/icons/client-circle.svg';
import detailsImage from 'src/components/common/icons/client-details.svg';
import contactImage from 'src/components/common/icons/contact-circle.svg';
import walletImage from 'src/components/common/icons/wallet.svg';
import Spinner from 'src/components/common/Spinner';
import AddressIcon from 'src/svg-icons/address';
import ClientIcon from 'src/svg-icons/client';

const ClientFullDetailsComponent = ({ details, id, intl, hasBillingAddress }) =>
  details ? (
    <div className={classes.clientFullDetails}>
      <Card className="fade-in">
        <h2 className={classes.cardHead}>
          <div className={classes.back}>
            <Link to={`../${id}`}>
              <div className={classes.arrowLeft} />
            </Link>
          </div>
          <div className={classes.icon}>
            <img src={detailsImage} alt={intl.formatMessage({ id: 'DETAILS' })} />
          </div>
          {intl.formatMessage({ id: 'DETAILS' })}
        </h2>
        <h3 className={classes.subtitle}>
          <div className={classes.icon}>
            <img src={clientImage} alt={intl.formatMessage({ id: 'CUSTOMER' })} />
          </div>
          {intl.formatMessage({ id: 'CUSTOMER' })}
        </h3>
        <div className={`${classes.field}`}>
          <p className={classes.label}>{intl.formatMessage({ id: 'CUSTOMER_TYPE' })}</p>
          <p className={classes.value}>
            {details.client_type === 'pj'
              ? intl.formatMessage({ id: 'LEGAL_PERSON' })
              : intl.formatMessage({ id: 'NATURAL_PERSON' })}
          </p>
        </div>
        <div className={classes.field}>
          {details.client_type === 'pj' ? (
            <p className={classes.label}>{intl.formatMessage({ id: 'FANTASY_NAME' })}</p>
          ) : (
            <p className={classes.label}>{intl.formatMessage({ id: 'NAME' })}</p>
          )}
          <p className={classes.value}>{details.name}</p>
        </div>
        <div className={`${classes.field} ${classes.isFlex}`}>
          {details.classification && (
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'CLASSIFICATION' })}</p>
              <p className={classes.value}>{intl.formatMessage({ id: `CLASSIFICATION.${details.classification}` })}</p>
            </div>
          )}
          {details.origin && (
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'ORIGIN' })}</p>
              <p className={classes.value}>{intl.formatMessage({ id: `ORIGIN.${details.origin}` })}</p>
            </div>
          )}
        </div>
        <div className={`${classes.field} ${classes.isFlex}`}>
          {details.client_type === 'pj' ? (
            <>
              <div className={`${classes.field} ${classes.is6}`}>
                <p className={classes.label}>{intl.formatMessage({ id: 'CNPJ' })}</p>
                <p className={classes.value}>{details.document}</p>
              </div>
              <div className={`${classes.field} ${classes.is6}`}>
                <p className={classes.label}>{intl.formatMessage({ id: 'COMPANY_NAME' })}</p>
                <p className={classes.value}>{details.company_name}</p>
              </div>
            </>
          ) : (
            <div className={`${classes.field} ${classes.is6}`}>
              <p className={classes.label}>{intl.formatMessage({ id: 'CPF' })}</p>
              <p className={classes.value}>{details.document}</p>
            </div>
          )}
        </div>
        <div className={`${classes.field} ${classes.isFlex}`}>
          <div className={`${classes.field} ${classes.is6}`}>
            <p className={classes.label}>{intl.formatMessage({ id: 'USER' })}</p>
            <p className={classes.value}>{details.username}</p>
          </div>
          <div className={`${classes.field} ${classes.is6}`}>
            <p className={classes.label}>{intl.formatMessage({ id: 'EMAIL_ADDRESS' })}</p>
            <p className={classes.value}>{details.contact.email}</p>
          </div>
        </div>
        <div className={`${classes.field} ${classes.isFlex}`}>
          <div className={`${classes.field} ${classes.is6}`}>
            <p className={classes.label}>{intl.formatMessage({ id: 'BILLING_MODALITIES' })}</p>
            <p className={classes.value}>
              {details.billing_modality
                ? intl.formatMessage({ id: `BILLING_MODALITY.${details.billing_modality}` })
                : '--'}
            </p>
          </div>

          <div className={`${classes.field} ${classes.is6}`}>
            <p className={classes.label}>{intl.formatMessage({ id: 'BILLING_MODALITIES_PANEL' })}</p>
            <p className={classes.value}>
              {details.modality ? intl.formatMessage({ id: `BILLING_MODALITY.${details.modality}` }) : '--'}
            </p>
          </div>
        </div>
        <h3 className={classes.subtitle}>
          <div className={classes.icon}>
            <img src={walletImage} alt={intl.formatMessage({ id: 'PROPERTIES_PORTFOLIO' })} />
          </div>
          {intl.formatMessage({ id: 'PROPERTIES_PORTFOLIO' })}
        </h3>
        <div className={`${classes.field} ${classes.isFlex}`}>
          <div className={`${classes.field} ${classes.is6}`}>
            <p className={classes.label}>{intl.formatMessage({ id: 'PROPERTIES_FOR_PURCHASE_SELL' })}</p>
            <p className={classes.value}>{details.propertiesWallet?.properties_purchase_sell || '----'}</p>
          </div>
          <div className={`${classes.field} ${classes.is6}`}>
            <p className={classes.label}>{intl.formatMessage({ id: 'PROPERTIES_FOR_RENT' })}</p>
            <p className={classes.value}>{details.propertiesWallet?.properties_rent || '----'}</p>
          </div>
        </div>
        <div className={`${classes.field} ${classes.isFlex}`}>
          <div className={`${classes.field} ${classes.is6}`}>
            <p className={classes.label}>{intl.formatMessage({ id: 'MONTHLY_POTENTIAL_INSPECTIONS' })}</p>
            <p className={classes.value}>{details.propertiesWallet?.potential_inspections || '----'}</p>
          </div>
        </div>
        {details.client_type === 'pj' && (
          <>
            <h3 className={classes.subtitle}>
              <div className={classes.icon}>
                <ClientIcon />
              </div>
              {intl.formatMessage({ id: 'RESPONSIBLE' })}
            </h3>
            <div className={classes.field}>
              <p className={classes.label}>{intl.formatMessage({ id: 'NAME' })}</p>
              <p className={classes.value}>{details.responsible.name}</p>
            </div>
            <div className={`${classes.field} ${classes.isFlex}`}>
              <div className={`${classes.field} ${classes.is4}`}>
                <p className={classes.label}>{intl.formatMessage({ id: 'CPF' })}</p>
                <p className={classes.value}>{details.responsible.document}</p>
              </div>
              <div className={`${classes.field} ${classes.is4}`}>
                <p className={classes.label}>{intl.formatMessage({ id: 'PHONE' })}</p>
                <p className={classes.value}>{details.responsible.phone || '-----'}</p>
              </div>
              <div className={`${classes.field} ${classes.is4}`}>
                <p className={classes.label}>{intl.formatMessage({ id: 'CELLPHONE' })}</p>
                <p className={classes.value}>{details.responsible.cellphone || '-----'}</p>
              </div>
            </div>
            <div className={classes.field}>
              <p className={classes.label}>{intl.formatMessage({ id: 'EMAIL' })}</p>
              <p className={classes.value}>{details.responsible.email}</p>
            </div>
          </>
        )}
        <h3 className={classes.subtitle}>
          <div className={classes.icon}>
            <AddressIcon />
          </div>
          {intl.formatMessage({ id: 'CUSTOMER_ADDRESS' })}
        </h3>
        <div className={`${classes.field} ${classes.isFlex}`}>
          <div className={`${classes.field} ${classes.is6}`}>
            <p className={classes.label}>{intl.formatMessage({ id: 'CEP' })}</p>
            <p className={classes.value}>{details.address.zipcode}</p>
          </div>
        </div>
        <div className={`${classes.field} ${classes.isFlex}`}>
          <div className={`${classes.field} ${classes.is6}`}>
            <p className={classes.label}>{intl.formatMessage({ id: 'STREET' })}</p>
            <p className={classes.value}>{details.address.street}</p>
          </div>
          <div className={`${classes.field} ${classes.is3}`}>
            <p className={classes.label}>{intl.formatMessage({ id: 'NUMBER' })}</p>
            <p className={classes.value}>{details.address.number || '-----'}</p>
          </div>
          <div className={`${classes.field} ${classes.is3}`}>
            <p className={classes.label}>{intl.formatMessage({ id: 'COMPLEMENT' })}</p>
            <p className={classes.value}>{details.address.complement}</p>
          </div>
        </div>
        <div className={`${classes.field} ${classes.isFlex}`}>
          <div className={`${classes.field} ${classes.is5}`}>
            <p className={classes.label}>{intl.formatMessage({ id: 'NEIGHBORHOOD' })}</p>
            <p className={classes.value}>{details.address.neighborhood}</p>
          </div>
          <div className={`${classes.field} ${classes.is5}`}>
            <p className={classes.label}>{intl.formatMessage({ id: 'CITY' })}</p>
            <p className={classes.value}>{details.address.city}</p>
          </div>
          <div className={`${classes.field} ${classes.is2}`}>
            <p className={classes.label}>{intl.formatMessage({ id: 'STATE' })}</p>
            <p className={classes.value}>{details.address.state}</p>
          </div>
        </div>
        {hasBillingAddress && (
          <>
            <h3 className={classes.subtitle}>
              <div className={classes.icon}>
                <AddressIcon />
              </div>
              {intl.formatMessage({ id: 'BILLING_ADDRESS' })}
            </h3>
            <div className={`${classes.field} ${classes.isFlex}`}>
              <div className={`${classes.field} ${classes.is6}`}>
                <p className={classes.label}>{intl.formatMessage({ id: 'CEP' })}</p>
                <p className={classes.value}>{details.billing_address.zipcode}</p>
              </div>
            </div>
            <div className={`${classes.field} ${classes.isFlex}`}>
              <div className={`${classes.field} ${classes.is6}`}>
                <p className={classes.label}>{intl.formatMessage({ id: 'STREET' })}</p>
                <p className={classes.value}>{details.billing_address.street}</p>
              </div>
              <div className={`${classes.field} ${classes.is3}`}>
                <p className={classes.label}>{intl.formatMessage({ id: 'NUMBER' })}</p>
                <p className={classes.value}>{details.billing_address.number}</p>
              </div>
              <div className={`${classes.field} ${classes.is3}`}>
                <p className={classes.label}>{intl.formatMessage({ id: 'COMPLEMENT' })}</p>
                <p className={classes.value}>{details.billing_address.complement}</p>
              </div>
            </div>
            <div className={`${classes.field} ${classes.isFlex}`}>
              <div className={`${classes.field} ${classes.is5}`}>
                <p className={classes.label}>{intl.formatMessage({ id: 'NEIGHBORHOOD' })}</p>
                <p className={classes.value}>{details.billing_address.neighborhood}</p>
              </div>
              <div className={`${classes.field} ${classes.is5}`}>
                <p className={classes.label}>{intl.formatMessage({ id: 'CITY' })}</p>
                <p className={classes.value}>{details.billing_address.city}</p>
              </div>
              <div className={`${classes.field} ${classes.is2}`}>
                <p className={classes.label}>{intl.formatMessage({ id: 'STATE' })}</p>
                <p className={classes.value}>{details.billing_address.state}</p>
              </div>
            </div>
          </>
        )}

        <h3 className={classes.subtitle}>
          <div className={classes.icon}>
            <ClientIcon />
          </div>

          {intl.formatMessage({ id: 'BILLING_DATA' })}
        </h3>

        <div className={`${classes.field} ${classes.isFlex}`}>
          <div className={`${classes.field} ${classes.is6}`}>
            <p className={classes.label}>{intl.formatMessage({ id: 'BILLING_CONTACT' })}</p>

            <p className={classes.value}>{details.billing_contact || '-----'}</p>
          </div>

          <div className={`${classes.field} ${classes.is6}`}>
            <p className={classes.label}>{intl.formatMessage({ id: 'BILLING_EMAIL' })}</p>

            <p className={classes.value}>{details.billing_email || '-----'}</p>
          </div>

          <div className={`${classes.field} ${classes.is6}`}>
            <p className={classes.label}>{intl.formatMessage({ id: 'BILLING_PHONE' })}</p>

            <p className={classes.value}>{details.billing_phone || '-----'}</p>
          </div>
        </div>

        <h3 className={classes.subtitle}>
          <div className={classes.icon}>
            <img src={contactImage} alt={intl.formatMessage({ id: 'CONTACT' })} />
          </div>

          {intl.formatMessage({ id: 'CONTACT' })}
        </h3>

        <div className={`${classes.field} ${classes.isFlex}`}>
          <div className={`${classes.field} ${classes.is6}`}>
            <p className={classes.label}>{intl.formatMessage({ id: 'PHONE' })}</p>

            <p className={classes.value}>{details.contact.phone || '-----'}</p>
          </div>

          <div className={`${classes.field} ${classes.is6}`}>
            <p className={classes.label}>{intl.formatMessage({ id: 'CELLPHONE' })}</p>

            <p className={classes.value}>{details.contact.cellphone || '-----'}</p>
          </div>
        </div>
      </Card>
    </div>
  ) : (
    <div className={classes.spinner}>
      <Spinner />
    </div>
  );

ClientFullDetailsComponent.propTypes = {
  details: PropTypes.object,
  id: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  hasBillingAddress: PropTypes.bool.isRequired,
};

ClientFullDetailsComponent.defaultProps = {
  details: null,
};

export default ClientFullDetailsComponent;

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import CurrentTimeComponent from 'src/components/SchedulePage/Timeline/CurrentTime/CurrentTimeComponent';

let interval;

const CurrentTimeContainer = ({ getOffsetByTime, selectedDateIsNotToday }) => {
  const [currentTimePosition, setCurrentTimePosition] = useState(0);

  useEffect(() => {
    if (!selectedDateIsNotToday) {
      const position = getOffsetByTime();

      setCurrentTimePosition(position);

      interval = setInterval(() => {
        setCurrentTimePosition(getOffsetByTime());
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [getOffsetByTime, selectedDateIsNotToday]);

  if (selectedDateIsNotToday) return null;

  return <CurrentTimeComponent currentTimePosition={currentTimePosition} />;
};

CurrentTimeContainer.propTypes = {
  getOffsetByTime: PropTypes.func.isRequired,
  selectedDateIsNotToday: PropTypes.bool.isRequired,
};

export default CurrentTimeContainer;

import React from 'react';

import PropTypes from 'prop-types';

import classes from 'src/components/common/elements/Radio/style.module.scss';

const Radio = ({ checked, className, disabled, id, name, value, onChange, required }) => (
  <div className={`${classes.radio} ${className}`}>
    <input
      type="radio"
      name={name}
      value={value}
      checked={checked}
      disabled={disabled}
      id={id}
      onChange={onChange}
      required={required}
    />
    <label htmlFor={id} />
  </div>
);

Radio.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

Radio.defaultProps = {
  checked: false,
  className: null,
  disabled: false,
  onChange: null,
};

export default Radio;

/* eslint-disable indent */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

import { orderType as parseOrderType } from 'src/constants';
import ApiContext from 'src/contexts/ApiContext';
import classes from 'src/orderDetails/OrderDetailsFooter.module.scss';
import ResendEnvelope from 'src/svg-icons/resend-envelope';
import ResyncEnvelope from 'src/svg-icons/resync-envelope';
import TypeEntrada from 'src/svg-icons/type-guiada';

function MakeButton(onClick, textKey, style, processing = false, disabled = false) {
  return (
    <span style={{ position: 'relative' }} key={textKey}>
      <Button
        variant="outlined"
        className={[classes.Button, style].join(' ')}
        size="small"
        onClick={onClick}
        disabled={processing || disabled}
        data-cy={`order-details-footer-button-${textKey}`}
      >
        <FormattedMessage id={`BUTTON.${textKey}`} />
      </Button>
      {processing && <CircularProgress size={20} className={classes.Spinner} />}
    </span>
  );
}

const inspectionStateMachine = {
  active: {
    WAITING: ['REJECT', 'ACCEPT'],
    ACCEPTED: ['ASSIGN_INSPECTOR'],
    ASSIGNED: [],
    IN_PROGRESS: [],
    FINALIZED_BY_DEVICE: ['SEND'],
    VNR: ['CANCEL'],
  },
  rejected: {
    REJECTED: ['ARCHIVE'],
  },
  sent: {
    FINISHED: ['ARCHIVE'],
  },
  independent: {
    PENDING: ['ASSIGN_ORDER'],
    IN_PROGRESS: ['ASSIGN_ORDER'],
    FINALIZED: ['ASSIGN_ORDER'],
  },
  archived: {
    ACCEPTED: [],
    ASSIGNED: [],
    IN_PROGRESS: [],
    FINALIZED_BY_DEVICE: [],
    FINISHED: [],
    REJECTED: [],
  },
  client: {
    WAITING: [],
    ACCEPTED: [],
    ASSIGNED: [],
    IN_PROGRESS: [],
    FINALIZED_BY_DEVICE: [],
    FINISHED: [],
    REJECTED: [],
  },
  preview: {
    PENDING: ['BACK_TO_ORDER'],
    IN_PROGRESS: ['BACK_TO_ORDER'],
    FINALIZED: ['BACK_TO_ORDER'],
  },
  canceled: {
    VNR: [],
  },
};

const marketPlaceStateMachine = {
  active: {
    WAITING: ['REJECT', 'ACCEPT'],
    ACCEPTED: ['DOCUSIGN_ACCEPTED'],
    ASSIGNED: [],
    IN_PROGRESS: [],
    FINALIZED_BY_DEVICE: ['SEND'],
  },
  rejected: {
    REJECTED: ['ARCHIVE'],
  },
  sent: {
    FINISHED: ['ARCHIVE'],
  },
  archived: {
    ACCEPTED: [],
    ASSIGNED: [],
    IN_PROGRESS: [],
    FINALIZED_BY_DEVICE: [],
    FINISHED: [],
    REJECTED: [],
  },
  client: {
    WAITING: ['REJECT', 'ACCEPT'],
    ACCEPTED: ['DOCUSIGN_ACCEPTED'],
    ASSIGNED: [],
    IN_PROGRESS: [],
    FINALIZED_BY_DEVICE: ['SEND'],
    FINISHED: [],
    REJECTED: [],
  },
  preview: {
    PENDING: ['BACK_TO_ORDER'],
    IN_PROGRESS: ['BACK_TO_ORDER'],
    FINALIZED: ['BACK_TO_ORDER'],
  },
};

const OrderDetailsFooter = ({
  backToOrder,
  changePage,
  changeOrderStatus,
  children,
  currentPage,
  data,
  marketPlace,
  newStatus,
  openAssignDevice,
  openAssignOrder,
  orderEdit,
  orderStatus,
  resendEnvelope,
  resyncEnvelope,
  readonly,
  address,
  medias,
  requestInProgress,
  saveMedia,
}) => {
  const features = JSON.parse(window.sessionStorage.getItem('me')).features.map(feature => parseOrderType.get(feature));
  const permissions = JSON.parse(window.sessionStorage.getItem('orderTypeConfigurations'));

  const isSupportModeActive = () => {
    const cookies = document.cookie;
    return cookies.includes('SupportMode=true');
  };

  const shouldShowExtraActions = () => {
    if (
      !permissions ||
      !permissions[data.type] ||
      !permissions[data.type][data.status] ||
      !permissions[data.type][data.status].extraActions
    ) {
      return false;
    }

    let shouldShow = false;
    permissions[data.type][data.status].extraActions.forEach(extraAction => {
      if (features.indexOf(extraAction) > -1) {
        shouldShow = true;
      }
    });

    return shouldShow;
  };

  let buttons = [];
  if (readonly === true) {
    buttons = null;
  } else if (newStatus !== null && data.type !== 'marketplace_docusign' && data.type !== 'marketplace_fichacerta') {
    let disabled = false;
    let action = changeOrderStatus;

    if (newStatus === 'APPROVE') {
      const me = JSON.parse(sessionStorage.getItem('me'));
      const { address_validation: addressValidation } = data;
      if (
        me.features.indexOf('SCHEDULE') !== -1 &&
        (addressValidation?.state === 'invalid' ||
          addressValidation?.city === 'invalid' ||
          addressValidation?.neighborhood === 'invalid') &&
        !address
      ) {
        disabled = true;
      }
    }

    if (
      newStatus === 'SEND' &&
      data?.type === 'tourVirtual' &&
      (medias.length < 1 || medias[0].isValid !== undefined)
    ) {
      disabled = medias.filter(media => media.type === 'TOUR_VIRTUAL' && media.isValid === true).length < 1;
      action = async status => {
        try {
          await saveMedia();
        } catch (err) {
          console.log(err);
        }
        changeOrderStatus(status);
      };
    }

    buttons = [
      processing => MakeButton(() => changeOrderStatus(null), 'CANCEL', classes.secondary, processing),
      processing => MakeButton(() => action(newStatus), 'CONFIRM', classes.primary, processing, disabled),
    ];
  } else if (data.type === 'marketplace_docusign' && data.status === 'IN_PROGRESS') {
    const buttonVisible = permissions.docusign && permissions.docusign[data.status].canBeEdited;
    if (buttonVisible) {
      buttons.push(status => (
        <button
          type="button"
          className={`${classes.newButton} ${classes.isResend}`}
          onClick={evt => resendEnvelope(evt, classes.isSuccess)}
          disabled={status === 'WORKING_RESEND'}
          key="RESEND_ENVELOPE"
          data-cy="order-details-footer-button-resend-envelope"
        >
          {status === 'WORKING_RESEND' ? (
            <CircularProgress size={20} className={classes.Spinner} />
          ) : (
            <>
              <ResendEnvelope />
              <FormattedMessage id="BUTTON.RESEND_ENVELOPE" />
            </>
          )}
        </button>
      ));
      if (isSupportModeActive()) {
        buttons.push(status => (
          <button
            type="button"
            className={`${classes.newButton} ${classes.isResync}`}
            onClick={evt => resyncEnvelope(evt, classes.isSuccess)}
            disabled={status === 'WORKING_RESYNC'}
            key="SYNC_ENVELOPE"
            data-cy="order-details-footer-button-sync-envelope"
          >
            {status === 'WORKING_RESYNC' ? (
              <CircularProgress size={20} className={classes.Spinner} />
            ) : (
              <>
                <ResyncEnvelope style={{ position: 'relative', top: '1px', fontSize: '1em' }} />
                <FormattedMessage id="BUTTON.RESYNC_ENVELOPE" />
              </>
            )}
          </button>
        ));
      }
    }
  } else if (shouldShowExtraActions()) {
    const shouldShowButton = feature =>
      permissions &&
      permissions[data.type] &&
      permissions[data.type][data.status] &&
      permissions[data.type][data.status].extraActions &&
      permissions[data.type][data.status].extraActions.indexOf(feature) > -1 &&
      features.indexOf(feature) > -1;

    if (data.type === 'curtaTemporada' && shouldShowButton('checklist')) {
      buttons.push(processing => (
        <button
          type="button"
          className={classes.newButton}
          onClick={e => changePage(e, 'checklist-form')}
          disabled={processing}
          key="EXTRA_ACTIONS"
          data-cy="order-details-footer-button-extra-actions"
        >
          <TypeEntrada />
          <FormattedMessage id="ASSISTED_INSPECTION" />
        </button>
      ));
    } else {
      buttons.push(processing => (
        <button
          type="button"
          className={classes.newButton}
          onClick={e => changePage(e, 'extra')}
          disabled={processing}
          key="EXTRA_ACTIONS"
          data-cy="order-details-footer-button-extra-actions"
        >
          <TypeEntrada />
          <FormattedMessage id="BUTTON.EXTRA_ACTIONS" />
        </button>
      ));
    }
  } else {
    const isMarketplace = data.type && data.type.includes('marketplace_');
    const buttonsStateMachine = isMarketplace ? marketPlaceStateMachine : inspectionStateMachine;
    if (buttonsStateMachine[currentPage] && buttonsStateMachine[currentPage][orderStatus]) {
      buttons = buttonsStateMachine[currentPage][orderStatus].map((buttonType, index) => {
        let button = null;
        if (isMarketplace && data.status === 'WAITING' && index !== 0) {
          return null;
        }
        switch (buttonType) {
          case 'ACCEPT':
            button = processing =>
              MakeButton(() => changeOrderStatus('APPROVE'), 'ACCEPT', classes.primary, processing);
            break;
          case 'REJECT':
            button = processing =>
              MakeButton(() => changeOrderStatus('REJECT'), 'REJECT', classes.secondary, processing);
            break;
          case 'ASSIGN_INSPECTOR':
            button = processing =>
              MakeButton(() => openAssignDevice(), 'ASSIGN_INSPECTOR', classes.primary, processing);
            break;
          case 'ARCHIVE':
            // button = MakeButton();
            break;
          case 'SEND':
            button = processing => MakeButton(() => changeOrderStatus('SEND'), 'SEND', classes.primary, processing);
            break;
          case 'ASSIGN_ORDER':
            button = processing =>
              MakeButton(() => openAssignOrder(data.form.inspection_type), 'ASSIGN_ORDER', classes.primary, processing);
            break;
          case 'EDIT':
            button = processing => MakeButton(() => orderEdit(), 'EDIT', classes.primary, processing);
            break;
          case 'DOCUSIGN_ACCEPTED':
            button = processing =>
              MakeButton(() => marketPlace('DOCUSIGN_ACCEPTED', data), 'SEND', classes.primary, processing);
            break;
          case 'BACK_TO_ORDER':
            button = processing => MakeButton(() => backToOrder(), 'BACK_TO_ORDER', classes.primary, processing);
            break;
          case 'CANCEL':
            button = processing =>
              MakeButton(e => changePage(e, 'cancel'), 'CANCEL_ORDER', classes.secondary, processing);
            break;
          default:
            break;
        }

        return button;
      });
    }
  }

  if (buttons) {
    return (
      <ApiContext.Consumer>
        {apiContext => (
          <div className={classes.sticky}>
            {buttons.length > 0 ? <hr className={classes.hr} /> : null}
            {children}
            {data.type === 'marketplace_docusign' && data.status === 'IN_PROGRESS' ? (
              <div className={classes.actions}>{buttons.map(f => (f ? f(apiContext.status) : null))}</div>
            ) : (
              buttons.map(f => (f ? f(apiContext.status === 'WORKING' || requestInProgress) : null))
            )}
          </div>
        )}
      </ApiContext.Consumer>
    );
  }
  return null;
};

OrderDetailsFooter.propTypes = {
  data: PropTypes.object.isRequired,
  /**
   * Function to change the status of the order
   */
  changeOrderStatus: PropTypes.func.isRequired,
  /**
   * Function to assign an inspector to a device
   */
  openAssignDevice: PropTypes.func,
  /**
   * Function to assign an inspector to an order
   */
  openAssignOrder: PropTypes.func,
  /**
   * String with the status (based on the active submanu)
   */
  orderStatus: PropTypes.string,
  /**
   * Function to open the edit form of the order, only present
   * in the client panel
   */
  orderEdit: PropTypes.func,
  /**
   * String with the new order status
   */
  resendEnvelope: PropTypes.func.isRequired,
  resyncEnvelope: PropTypes.func.isRequired,
  newStatus: PropTypes.string,
  backToOrder: PropTypes.func,
  changePage: PropTypes.func,
  history: PropTypes.object.isRequired,
  children: PropTypes.node,
  currentPage: PropTypes.string,
  marketPlace: PropTypes.func,
  readonly: PropTypes.bool,
  address: PropTypes.object,
  medias: PropTypes.array,
  requestInProgress: PropTypes.bool.isRequired,
  saveMedia: PropTypes.func.isRequired,
};

OrderDetailsFooter.defaultProps = {
  newStatus: null,
  orderStatus: null,
  orderEdit: null,
  openAssignDevice: null,
  openAssignOrder: null,
  backToOrder: null,
  changePage: null,
  children: null,
  currentPage: null,
  marketPlace: null,
  readonly: null,
  address: null,
  medias: [],
};

export default withRouter(OrderDetailsFooter);

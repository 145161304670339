// Actions
export const ACTIONS = {
  CHANGE_API_STATUS: 'clientOrderForm/CHANGE_API_STATUS',
};

// Reducer
const initialState = {
  apiStatus: 'IDLE',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.CHANGE_API_STATUS: {
      return {
        ...state,
        apiStatus: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

// actions
export const changeApiStatus = payload => ({
  type: ACTIONS.CHANGE_API_STATUS,
  payload,
});

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const CircleRede = props => (
  <SvgIcon viewBox="0 0 48 48" {...props}>
    <path
      fill="#488DCA"
      d="M36.997,29.128v-8.106c0-1.716-1.601-2.476-1.912-2.607l-9.281-5.034c-1.065-0.579-2.277-0.585-3.465-0.02
        c-1.96,0.938-8.633,4.61-9.422,5.044c-0.957,0.359-2.034,1.25-1.929,2.583c-0.002,0.716,0.002,1.735,0.005,2.815v0.062
        c0.007,1.905,0.014,4.061-0.005,5.427c-0.013,0.931,0.258,1.66,0.807,2.165c0.619,0.57,1.375,0.654,1.769,0.654
        c0.059,0,0.107,0,0.133-0.002h2.107c2.458,0,3.694-1.475,3.914-1.766c0.009-0.011,0.872-1.085,1.249-1.516
        c0.299-0.345,0.661-0.447,1.02-0.264c0.642,0.255,1.321,0.383,2.016,0.383c3.021,0,5.479-2.46,5.479-5.482
        c0-3.021-2.459-5.479-5.479-5.479c-3.023,0-5.481,2.458-5.481,5.479c0,1.253,0.436,2.479,1.226,3.452
        c0.109,0.132,0.208,0.561-0.047,0.942l-1.224,1.487l-0.028,0.037c-0.035,0.045-0.858,1.132-2.643,1.132h-2.13h-0.037l-0.029,0.002
        c0,0-0.017,0-0.04,0c-0.105,0-0.464-0.017-0.698-0.238c-0.199-0.186-0.295-0.511-0.288-0.964c0.02-1.39,0.01-3.556,0.005-5.469
        L12.585,23.8c-0.003-1.092-0.006-2.125-0.003-2.836l-0.003-0.079c-0.06-0.629,0.919-0.996,0.928-0.997l0.057-0.02l0.068-0.037
        c0.07-0.039,7.387-4.074,9.395-5.032c0.742-0.354,1.385-0.361,2.017-0.018l9.32,5.055l0.094,0.044
        c0.01,0.003,0.945,0.379,0.945,1.141v8.228l0.013,0.081c0.002,0.005,0.066,0.459-0.187,0.765
        c-0.286,0.349-0.895,0.422-1.355,0.422h-8.876c-1.49,0-3.08,0.788-3.988,1.462c-0.172,0.128-0.278,0.297-0.306,0.487
        c-0.029,0.207,0.037,0.431,0.185,0.629c0.128,0.17,0.333,0.264,0.58,0.264c0.214,0,0.447-0.07,0.604-0.187
        c0.735-0.528,1.924-1.062,2.925-1.062h8.876c1.167,0,2.037-0.337,2.585-1.002C37.105,30.325,37.034,29.385,36.997,29.128z
         M20.115,23.466c0-2.142,1.743-3.886,3.886-3.886c2.142,0,3.886,1.744,3.886,3.886c0,2.146-1.744,3.889-3.886,3.889
        C21.857,27.354,20.115,25.611,20.115,23.466z"
    />
  </SvgIcon>
);

CircleRede.displayName = 'CircleRede';
CircleRede.muiName = 'SvgIcon';

export default CircleRede;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const TypeGuiada = props => (
  <SvgIcon {...props} viewBox="0 0 34 34">
    <path
      fill="#393939"
      d="M11.6,10h10.5V7.5l-3.3-1.3V4.3c0-1.1-0.2-1.3-1.3-1.3h-1.3c-1.1,0-1.3,0.2-1.3,1.3v1.9l-3.3,1.3V10z
        M27.3,6.8c0-1.1-0.9-1.9-2-1.9h-5.2l3.3,1.3v5.1H10.3V6.2l3.3-1.3H8.4c-1.1,0-2,0.9-2,1.9v21.1c0,1.1,0.9,1.9,2,1.9h9.1V14.3h9.9
        V6.8z M21.5,29.9h3.8c1.1,0,2-0.9,2-1.9v-2.3h-5.8V29.9z M30.8,15.5H18.6v16.1h1.8v-7.2h10.4L27.3,20L30.8,15.5z"
    />
  </SvgIcon>
);

TypeGuiada.displayName = 'TypeGuiada';
TypeGuiada.muiName = 'SvgIcon';

export default TypeGuiada;

import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

const PlanSwitch = ({ planToHide, children }) => {
  const [userPlans, setUserPlans] = useState([]);

  const me = sessionStorage.getItem('me');

  const hasPlanToHide = () => userPlans.some(userPlan => userPlan?.type === planToHide);

  useEffect(() => {
    const parsedMe = JSON.parse(me) || {};

    setUserPlans([parsedMe.plan]);
  }, [me]);

  return hasPlanToHide() ? null : children;
};

PlanSwitch.propTypes = {
  planToHide: PropTypes.oneOf(['CONTROL']),
  children: PropTypes.node,
};

PlanSwitch.defaultProps = {
  planToHide: null,
  children: null,
};

export default PlanSwitch;

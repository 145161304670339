import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import MenuIcon from 'src/leftDrawer/MenuIcon';

const styles = {
  select: {
    background: '#1E2941',
    borderLeft: '3px solid white',
  },
  list: {
    color: '#ffff',
    fontWeight: 'normal',
    fontSize: 12,
  },
  collapseSubMenuItem: {
    background: '#1f4065',
    paddingLeft: '68px',
  },
  collapseSubMenuActiveItem: {
    background: '#1a3655',
    paddingLeft: '68px',
  },
};

function DrawerCollapseSubMenu({
  subMenuOption,
  collapseSubMenu,
  handleCollapseSubMenuChange,
  classes,
  isOpenByDefault,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleCollapseToggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (isOpenByDefault) setIsOpen(true);
  }, [isOpenByDefault]);

  return (
    <div style={styles.list}>
      <ListItem
        button
        disableRipple
        disabled={subMenuOption.disabled}
        onClick={() => {
          handleCollapseToggle();
        }}
        style={subMenuOption.active ? styles.select : null}
        data-cy={`${subMenuOption.key}-left-menu-option`}
      >
        <ListItemIcon classes={{ root: classes.icon }}>
          <MenuIcon menuKey={subMenuOption.key} />
        </ListItemIcon>
        <FormattedMessage id={'LeftDrawer.' + subMenuOption.key} />
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {collapseSubMenu.map((collapseOption, collapseOptionIndex) => (
            <ListItem
              key={collapseOption.key}
              button
              disableRipple
              disabled={collapseOption.disabled}
              onClick={() =>
                handleCollapseSubMenuChange(subMenuOption.index, collapseOptionIndex, collapseOption.routeLink)
              }
              style={collapseOption.active ? styles.collapseSubMenuActiveItem : styles.collapseSubMenuItem}
              data-cy={`${collapseOption.key}-left-sub-menu-option`}
            >
              <FormattedMessage id={'LeftDrawer.' + collapseOption.key} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </div>
  );
}

DrawerCollapseSubMenu.propTypes = {
  /**
   * Submenu options props
   */
  subMenuOption: PropTypes.object.isRequired,
  /**
   * Array with the sub-menu options
   */
  collapseSubMenu: PropTypes.array.isRequired,
  /**
   * Style classes
   */
  classes: PropTypes.object,
  /**
   * Function to handle the url change of the collapse-menu (submenu inside submenu)
   */
  handleCollapseSubMenuChange: PropTypes.func.isRequired,
  /**
   * Is open by default (when parent component is created)
   */
  isOpenByDefault: PropTypes.bool,
  /**
   * Default submenu index to open (if it is provided)
   */
  subMenuToOpenByIndex: PropTypes.number,
};

DrawerCollapseSubMenu.defaultProps = {
  classes: {},
  isOpenByDefault: false,
  subMenuToOpenByIndex: null,
};

export default withStyles(styles)(DrawerCollapseSubMenu);

import React from 'react';
import { FormattedMessage } from 'react-intl';
import MaskedInput from 'react-text-mask';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useUser } from 'src/contexts/UserContext';
import { masksByCountry } from 'src/helpers/mask';

const styles = {
  bootstrapInput: {
    borderRadius: 4,
    fontWeight: 'normal',
    border: '1px solid #ced4da',
    fontSize: 'calc(6px + 0.4vw)',
    padding: '8px 12px',
    maxWidth: '200px',
    width: 'calc(100% - 24px)',
    '&:focus': {
      borderColor: '#80bdff',
    },
    '&.is-disabled': {
      backgroundColor: '#f4f3f4',
      borderColor: '#ced4da',
      cursor: 'not-allowed',
    },
    fontFamily: 'Open Sans',
  },
  bootstrapInputError: {
    borderRadius: 4,
    border: '1px solid red',
    fontSize: 'calc(6px + 0.4vw)',
    padding: '8px 12px',
    width: 'calc(100% - 24px)',
    '&:focus': {
      borderColor: '#80bdff',
    },
    fontFamily: 'Open Sans',
  },
  label: {
    fontFamily: 'Open Sans',
    marginTop: '0.3vh',
    fontSize: 'calc(6px + 0.3vw)',
  },
};

const ZipcodeElement = ({ inputRef, ...other }) => {
  const me = useUser();
  const zipcodeMask = masksByCountry[me?.profile?.country || 'BR'].ZIPCODE_MASK;

  return <MaskedInput {...other} ref={inputRef} guide={false} mask={zipcodeMask} autoComplete="off" />;
};

ZipcodeElement.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.object.isRequired]),
};

const ZipcodeWidget = ({ classes, disabled, id, label, onChange, options, rawErrors, required, value }) => {
  const error = rawErrors && rawErrors.length > 0;
  const inputProps = options.maxlength ? { maxLength: options.maxlength } : {};
  const bootstrapClasses = error ? classes.bootstrapInputError : classes.bootstrapInput;

  return (
    <div>
      <label style={styles.label} htmlFor={id}>
        <FormattedMessage id={label} />

        {required ? '*' : null}
      </label>

      <TextField
        disabled={disabled}
        fullWidth
        type="text"
        name={id}
        id={id}
        data-cy={id}
        inputProps={inputProps}
        InputProps={{
          inputComponent: ZipcodeElement,
          value,
          onChange: event => onChange(event.target.value),
          disableUnderline: true,
          classes: {
            input: classNames({ 'is-disabled': disabled }, bootstrapClasses),
          },
        }}
      />
    </div>
  );
};

ZipcodeWidget.propTypes = {
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.object,
  rawErrors: PropTypes.array,
  required: PropTypes.bool,
  value: PropTypes.string,
};

export default withStyles(styles)(ZipcodeWidget);

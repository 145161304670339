import React from 'react';
import { FormattedMessage } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const styles = {
  bootstrapInput: {
    width: 'calc(100% - 24px)',
    height: '19px',
    padding: '8px 12px',
    border: '1px solid #ced4da',
    borderRadius: 4,
    fontFamily: 'Open Sans',
    fontSize: 'calc(6px + 0.4vw)',
    fontWeight: 'normal',
    '&:focus': {
      borderColor: '#80bdff',
    },
  },
  bootstrapInputError: {
    width: 'calc(100% - 24px)',
    padding: '8px 12px',
    border: '1px solid red',
    borderRadius: 4,
    fontFamily: 'Open Sans',
    fontSize: 'calc(6px + 0.4vw)',
    '&:focus': {
      borderColor: '#80bdff',
    },
  },
  label: {
    marginTop: '0.3vh',
    fontSize: 'calc(6px + 0.3vw)',
    fontFamily: 'Open Sans',
  },
};

function RequestFormWidgetNumber(props) {
  const { classes, disabled, id, label, onChange, rawErrors, required, value } = props;
  let error = false;

  if (rawErrors) {
    if (rawErrors.length > 0) {
      error = true;
    }
  }

  return (
    <div>
      <label style={styles.label} htmlFor={id}>
        <FormattedMessage id={label} />
        {required ? '*' : null}
      </label>
      <TextField
        disabled={disabled}
        fullWidth
        id={id}
        data-cy={id}
        type="number"
        className="custom"
        value={value}
        onChange={event => onChange(event.target.value)}
        inputProps={{ min: 0 }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          classes: {
            input: error ? classes.bootstrapInputError : classes.bootstrapInput,
          },
          disableUnderline: true,
        }}
      />
    </div>
  );
}

RequestFormWidgetNumber.propTypes = {
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  rawErrors: PropTypes.array,
  required: PropTypes.bool.isRequired,
  value: PropTypes.number,
};

RequestFormWidgetNumber.defaultProps = {
  classes: {},
  disabled: false,
  rawErrors: [],
  value: undefined,
};

export default withStyles(styles)(RequestFormWidgetNumber);

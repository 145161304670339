import React from 'react';

import PropTypes from 'prop-types';

import classes from 'src/components/Page/Section/style.module.scss';

const Section = ({ className, children, cols, padding }) => (
  <div className={`${classes.section} ${className || ''} ${classes[`is${cols}`]}`}>
    <div className={`${classes.contentWrapper} ${padding ? classes.withPadding : null}`}>{children}</div>
  </div>
);

Section.propTypes = {
  children: PropTypes.node,
  cols: PropTypes.number,
  padding: PropTypes.bool,
  className: PropTypes.string,
};

Section.defaultProps = {
  className: null,
  children: null,
  cols: 12,
  padding: false,
};

export default Section;

// Actions
export const ACTIONS = {
  HIDE: 'toasts/HIDE',
  SHOW: 'toasts/SHOW',
};

// Reducer
const initialState = [];
export default function reducer(state = initialState, action = {}) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.SHOW: {
      const toast = { ...payload };
      toast.id = payload.id || Math.random().toString(36).substring(7);
      return [toast, ...state];
    }
    case ACTIONS.HIDE:
      return state.filter(toast => toast.id !== payload);
    default:
      return state;
  }
}

// actions
/**
 *
 * @param {Object} payload Toast information
 * @param {('success'|'error'|'loading')} payload.type Toast type
 * @param {string} payload.title Toast title
 * @param {string} payload.text Toast text
 * @param {number} payload.duration If set, toast will hide after this time (in milliseconds)
 * @param {string=} payload.link Toast link
 * @param {function=} payload.onClick Toast onClick action
 * @returns
 */
export const showToast = payload => ({
  type: ACTIONS.SHOW,
  payload,
});

export const hideToast = payload => ({
  type: ACTIONS.HIDE,
  payload,
});

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import Spinner from 'src/components/common/Spinner';
import classes from 'src/components/MarketplacePage/Home/style.module.scss';
import blockedImage from 'src/images/icons/blocked.svg';
import blockedImage2 from 'src/images/icons/blocked2.svg';
import blockedPlan from 'src/images/icons/blockedPlan.svg';
import checkImage from 'src/images/icons/check.svg';

import Modal from './ModalPlan/ModalContainer';

const HomeComponent = ({ busy, services, intl }) => {
  const [modalVisible, setIsModalVisible] = useState(false);
  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };
  return (
    <div className={classes.home}>
      <h1>{intl.formatMessage({ id: 'PANEL_INTEGRATIONS_MESSAGE' })}</h1>
      <p>{intl.formatMessage({ id: 'PANEL_FEATURES_COMBINE_MESSAGE' })}</p>
      {busy ? (
        <div className={classes.spinnerWrapper}>
          <Spinner />
        </div>
      ) : (
        <div className={classes.serviceList}>
          {services.map(service => (
            <div
              className={`${classes.service} ${service.enabled || service.status === 'ENABLED' ? classes.enabled : ''}`}
              key={service.slug}
            >
              {!service.disabledByModality ? (
                <Link
                  to={{
                    pathname: `/marketplace/${service.slug}`,
                    state: { type: service.type },
                  }}
                >
                  {service?.logo_url ? (
                    <div className={classes.image}>
                      <img src={service.logo_url} alt={service.name} />
                    </div>
                  ) : (
                    service.name
                  )}
                  {(service.enabled || service.status === 'ENABLED') && (
                    <div className={classes.flag}>
                      <img src={checkImage} alt={intl.formatMessage({ id: 'ACTIVATED' })} />
                    </div>
                  )}
                </Link>
              ) : (
                <span onClick={openModal}>
                  {service?.logo_url ? (
                    <div className={classes.image}>
                      <img src={service.logo_url} alt={service.name} />
                    </div>
                  ) : (
                    service.name
                  )}

                  <div className={`${classes.flag} ${classes.blocked}`}>
                    <img src={blockedImage} alt={intl.formatMessage({ id: 'DISABLED' })} />
                  </div>
                </span>
              )}
            </div>
          ))}
        </div>
      )}
      {modalVisible && (
        <Modal onClose={closeModal}>
          <div className={classes.ModalHeader}>
            <img src={blockedImage2} alt={intl.formatMessage({ id: 'DISABLED' })} />
            <p className={classes.ModalTitle}>Ferramenta indisponível</p>
          </div>
          <div className={classes.ModalContent}>
            <div>
              <p className="mb-2">O acesso completo desta ferramenta está indisponível no plano AVULSO do Painel.</p>
              <p>Assine um de nossos planos para ter acesso completo a essa e muitas outras funções!</p>
              <p>
                <b>Fale com seu consultor para saber mais!</b>
              </p>
            </div>
            <div className={classes.ModalImage}>
              <img src={blockedPlan} alt={intl.formatMessage({ id: 'DISABLED' })} />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

HomeComponent.propTypes = {
  busy: PropTypes.bool.isRequired,
  services: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
};

export default HomeComponent;

import React from 'react';

import PropTypes from 'prop-types';

import Icon from 'src/orderDetails/ActivityLog/Icon';

import classes from './style.module.scss';

const OrderHistoric = ({ intl, historic }) => (
  <div className={classes.activityLog}>
    {historic.length > 0 ? (
      <div className={classes.timeline}>
        <div className={classes.title}>
          <div style={{ paddingLeft: '10px' }}>Ação</div>

          <div style={{ paddingRight: '10px' }}>Data e Hora</div>
        </div>
        {historic.map(record => (
          <>
            <div className={classes.line} key={record.id} style={{ paddingBottom: !record.notes ? '4em' : '0.5em' }}>
              <div style={{ width: '50%' }} className={classes.action}>
                <div className={classes.actionIcon}>
                  <Icon action={record.type} />
                </div>
                <div className={classes.actionName}>{intl.formatMessage({ id: record.type })}</div>
              </div>

              <div style={{ paddingRight: '10px' }} title={record.humanDate}>
                {record.date}
              </div>
            </div>
            {record.notes && (
              <div className={classes.lineObservation}>
                <div className={classes.action}>
                  <div className={classes.observationIcon}></div>
                  <div className={classes.actionName}>{record.notes}</div>
                </div>
              </div>
            )}
          </>
        ))}
      </div>
    ) : (
      <div className={classes.noResult}>
        <span>Sem histórico</span>
      </div>
    )}
  </div>
);

OrderHistoric.propTypes = {
  intl: PropTypes.object.isRequired,
  historic: PropTypes.array.isRequired,
};

export default OrderHistoric;

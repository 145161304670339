import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import Spinner from 'src/components/common/Spinner';
import OrderCard from 'src/components/SchedulePage/OrderCard';
import hours from 'src/components/SchedulePage/Timeline/Content/constants/hours';
import useInspectorTimelineIntervals from 'src/components/SchedulePage/Timeline/Content/hooks/useInspectorTimelineIntervals';
import classes from 'src/components/SchedulePage/Timeline/Content/style.module.scss';
import CurrentTime from 'src/components/SchedulePage/Timeline/CurrentTime';

const ContentComponent = ({
  busy,
  elementRef,
  orderRef,
  setHoursRef,
  inspectors,
  orders,
  getOffsetByTime,
  selectedDateIsNotToday,
  onDragOver,
  onDragEnter,
  onDragLeave,
  onDrop,
  handleCompareTimelineInterval,
  intl,
}) => {
  const inspectorTimelineIntervals = useInspectorTimelineIntervals();

  if (busy || !inspectors) {
    return (
      <div className={classes.spinnerWrapper}>
        <Spinner />
      </div>
    );
  }

  const handleRenderUnavailabilityTooltip = unavailability => (
    <Tooltip
      key={unavailability.start.getHours()}
      title={
        <div>
          <span className={classes.block}>{intl.formatMessage({ id: 'UNAVAILABLE_INSPECTOR' })}</span>

          <span className={classes.block}>
            De: {format(unavailability.start, 'dd/MM/yyyy - HH:mm')} / Até:{' '}
            {format(unavailability.end, 'dd/MM/yyyy - HH:mm')}
          </span>

          {unavailability.observations && <span className={classes.block}>{unavailability.unavailability}</span>}
        </div>
      }
      placement="top-start"
      classes={{ tooltip: classes.unavailabilityTooltip }}
    >
      <div className={classes.unavailableInterval} />
    </Tooltip>
  );

  return (
    <div className={classes.content} ref={elementRef}>
      <CurrentTime getOffsetByTime={getOffsetByTime} selectedDateIsNotToday={selectedDateIsNotToday} />

      <div className={classes.hours} ref={setHoursRef}>
        {hours.map(hour => (
          <div className={classes.time} key={hour}>
            {' '}
            <div>{hour}</div>
          </div>
        ))}
      </div>

      <div className={classes.orderList}>
        {inspectors?.map(inspector => (
          <div className={classes.line} key={inspector.public_id}>
            <div
              className={`timeline-track ${classes.track}`}
              onDragOver={onDragOver}
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              onDrop={evt => onDrop(inspector.public_id, evt)}
            >
              {(!!inspector.todayUnavailabilities?.length || !!inspector.todayAvailabilities?.length) &&
                inspectorTimelineIntervals.map(({ halfAnHourInterval, halfAnHourIntervalWithScheduleDate }) => {
                  if (inspector.todayUnavailabilities?.length) {
                    const unavailability = inspector.todayUnavailabilities.find(todayUnavailability =>
                      handleCompareTimelineInterval(
                        halfAnHourIntervalWithScheduleDate,
                        todayUnavailability.start,
                        todayUnavailability.end
                      )
                    );

                    if (unavailability) return handleRenderUnavailabilityTooltip(unavailability);
                  }

                  if (inspector.todayAvailabilities?.length) {
                    const isAvailable = inspector.todayAvailabilities?.some(availability =>
                      handleCompareTimelineInterval(halfAnHourInterval, availability.start_time, availability.end_time)
                    );

                    if (isAvailable) return <div key={halfAnHourInterval} className={classes.availableInterval} />;
                  }

                  return <div key={halfAnHourInterval} className={classes.busyInterval} />;
                })}

              {!!orders?.length &&
                orders
                  .filter(order => order.schedule.who.inspector_id === inspector.public_id)
                  .map(order => (
                    <OrderCard
                      key={order?.order?.public_id}
                      order={order}
                      startOffset={getOffsetByTime(order.schedule.when.start_time)}
                      endOffset={getOffsetByTime(order.schedule.when.end_time)}
                      inspector={inspector}
                      forwardedRef={orderRef}
                      inTimeline
                    />
                  ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

ContentComponent.propTypes = {
  elementRef: PropTypes.object.isRequired,
  orderRef: PropTypes.object.isRequired,
  busy: PropTypes.bool.isRequired,
  inspectors: PropTypes.array,
  orders: PropTypes.array,
  setHoursRef: PropTypes.func.isRequired,
  getOffsetByTime: PropTypes.func.isRequired,
  onDragEnter: PropTypes.func.isRequired,
  onDragLeave: PropTypes.func.isRequired,
  onDragOver: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  selectedDateIsNotToday: PropTypes.bool.isRequired,
  handleCompareTimelineInterval: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

ContentComponent.defaultProps = {
  inspectors: null,
  orders: null,
};

export default ContentComponent;

import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import apiRequest from 'src/apiRequest';
import ClientFormComponent from 'src/components/ClientsPage/ClientForm/ClientFormComponent';
import { useUser } from 'src/contexts/UserContext';
import { parseInputName } from 'src/helpers';
import { masksByCountry } from 'src/helpers/mask';
import AddressService from 'src/services/addressService';
import apiCommercialRequest from 'src/services/apiCommercialRequest';
import { showToast } from 'src/store/ducks/toasts';

const classifications = [
  'IMOBILIARIA',
  'CORRETOR_DE_IMOVEIS',
  'PROPRIETARIO',
  'CONDOMINIO',
  'ADMINISTRADORA_DE_CONDOMINIOS',
  'SINDICO_PROFISSIONAL',
  'CONSTRUTORA_INCORPORADORA',
  'EMPREITEIRA',
  'INSTITUICAO_FINANCEIRA',
  'REDE_DE_VAREJO',
  'PESSOA_FISICA',
  'OUTROS',
];

const billingModalities = ['SEPARATE', 'PRE_PAID', 'POST_PAID'];

const ClientFormContainer = ({ action }) => {
  const [busy, setBusy] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingCnpj, setLoadingCnpj] = useState(false);
  const [type, setType] = useState('pj');
  const [showBillingAddress, setShowBillingAddress] = useState(false);
  const [client, setClient] = useState({ client_type: 'pj', contact: { assignment_email_rule: 'CREATOR' } });
  const [billingModalitiesPanel, setBillingModalitiesPanel] = useState([
    'PAY_PER_USE',
    'BASIC_PLAN',
    'ADVANCED_PLAN',
    'PROFESSIONAL_PLAN',
  ]);

  const [states, setStates] = useState([]);

  const [cities, setCities] = useState([]);
  const [billingCities, setBillingCities] = useState([]);

  const [neighborhoods, setNeighborhoods] = useState([]);
  const [billingNeighborhoods, setBillingNeighborhoods] = useState([]);

  const dispatch = useDispatch();

  const intl = useIntl();
  const history = useHistory();
  const { id, status } = useParams();

  const me = useUser();
  const addressService = new AddressService(me?.profile?.country);

  const isBrazilianFranchisee = me?.profile?.country === 'BR' || !me?.profile?.country;
  const franchiseeCountry = me?.profile?.country || 'BR';

  const zipcodeMask = masksByCountry[franchiseeCountry].ZIPCODE_MASK;
  const cnpjMask = masksByCountry[franchiseeCountry].CNPJ_MASK;
  const cpfOnlyMask = masksByCountry[franchiseeCountry].CPF_ONLY_MASK;
  const phoneMask = masksByCountry[franchiseeCountry].PHONE_MASK;
  const cellphoneMask = masksByCountry[franchiseeCountry].CELLPHONE_MASK;
  const cellphoneMaskLength = masksByCountry[franchiseeCountry].CELLPHONE_MASK_LENGTH;

  const handleNormalizeName = (name = '') => name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const fillNeighborhoods = async (parent, city) => {
    try {
      const data = await addressService.getNeighborhoodsByCity(city);

      setClient(state => ({
        ...state,
        [parent]: {
          ...state[parent],
          neighborhood: null,
          neighborhood_id: null,
        },
      }));

      if (parent === 'address') setNeighborhoods(data);
      if (parent === 'billing_address') setBillingNeighborhoods(data);

      return data;
    } catch (err) {
      console.debug('[DEBUG] fillNeighborhoods error ', err);
    }
  };

  const fillCities = async (parent, state) => {
    try {
      const data = await addressService.getCitiesByState(state);

      setClient(oldState => ({
        ...oldState,
        [parent]: {
          ...oldState[parent],
          city: null,
          city_id: null,
        },
      }));

      if (parent === 'address') setCities(data);
      if (parent === 'billing_address') setBillingCities(data);
    } catch (err) {
      console.debug('[DEBUG] fillCities error ', err);
    }
  };

  const fillAddress = async (parent, zipcode) => {
    try {
      const address = await addressService.getLocationByZipcode(zipcode);

      await fillCities(parent, address?.state_id);
      await fillNeighborhoods(parent, address?.city_id);

      setClient({
        ...client,
        [parent]: {
          ...client[parent],
          street: address?.street,
          neighborhood: address?.neighborhood,
          neighborhood_id: address?.neighborhood_id,
          city: address?.city,
          city_id: address?.city_id,
          state: address?.state,
          state_id: address?.state_id,
          zipcode,
        },
      });
    } catch (err) {
      console.debug('[DEBUG] fillAddress error ', err);
    }
  };

  const findCnpj = async documentValue => {
    if (loadingCnpj) return;
    setLoadingCnpj(true);

    try {
      const { data } = await apiCommercialRequest.get(`/companies/${documentValue.replace(/[^\d]+/g, '')}`);

      await fillAddress('address', data?.cep);

      setClient(state => ({
        ...state,
        company_name: data?.legal_name || '',
        name: data?.company_name || '',
        document: documentValue,
        address: {
          ...state['address'],
          number: data?.number || '',
          complement: data?.complement || '',
        },
        contact: {
          ...state['contact'],
          email: data?.email || '',
          phone: data?.first_phone || '',
        },
        hotsite_configuration: {
          ...state['hotsite_configuration'],
          cc_email: data?.email || '',
        },
      }));
    } catch (err) {
      console.debug('[DEBUG] findCnpj error ', err);

      setClient(state => ({
        ...state,
        company_name: '',
        name: '',
        document: documentValue,
        address: {
          ...state['address'],
          number: '',
          complement: '',
          street: '',
          neighborhood: '',
          neighborhood_id: '',
          city: '',
          city_id: '',
          state: '',
          state_id: '',
          zipcode: '',
        },
        contact: {
          ...state['contact'],
          email: '',
          phone: '',
        },
        hotsite_configuration: {
          ...state['hotsite_configuration'],
          cc_email: '',
        },
      }));

      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'SEARCH' }),
          text: intl.formatMessage({ id: 'cnpjNotFound' }),
          duration: 5000,
        })
      );
    } finally {
      setLoadingCnpj(false);
    }
  };

  useEffect(() => {
    const fetchClient = async () => {
      try {
        const { data } = await apiRequest.get(`/clients/${id}`);

        if (data.address) {
          const stateId = data.address?.state_id || states.find(state => state?.name === data.address?.state)?.id;

          if (stateId) await fillCities('address', stateId);

          if (data.address?.city_id) {
            const fetchedNeighborhoods = await fillNeighborhoods('address', data.address.city_id);

            if (!data.address?.neighborhood_id && fetchedNeighborhoods?.length) {
              const neighborhoodFound = fetchedNeighborhoods.find(neighborhood => {
                return handleNormalizeName(neighborhood?.name) === handleNormalizeName(data.address?.neighborhood);
              });

              data.address.neighborhood_id = neighborhoodFound?.id || '';
            }
          }
        }

        if (data.billing_address) {
          const stateId =
            data.billing_address?.state_id || states.find(state => state?.name === data.billing_address?.state)?.id;

          if (stateId) await fillCities('billing_address', stateId);

          if (data.billing_address?.city_id) {
            const fetchedNeighborhoods = await fillNeighborhoods('billing_address', data.billing_address.city_id);

            if (!data.billing_address?.neighborhood_id && fetchedNeighborhoods?.length) {
              const neighborhoodFound = fetchedNeighborhoods.find(neighborhood => {
                return (
                  handleNormalizeName(neighborhood?.name) === handleNormalizeName(data.billing_address?.neighborhood)
                );
              });

              data.billing_address.neighborhood_id = neighborhoodFound?.id || '';
            }
          }
        }

        setType(data?.client_type);
        setClient(data);
        setShowBillingAddress(
          !!(
            data?.billing_address?.zipcode ||
            data?.billing_address?.street ||
            data?.billing_address?.number ||
            data?.billing_address?.complement ||
            data?.billing_address?.neighborhood ||
            data?.billing_address?.city ||
            data?.billing_address?.state
          )
        );

        if (me.type === 'OFFICE' || data.modality === 'BASIC_LEGACY_PLAN') {
          setBillingModalitiesPanel([
            'PAY_PER_USE',
            'BASIC_LEGACY_PLAN',
            'BASIC_PLAN',
            'ADVANCED_PLAN',
            'PROFESSIONAL_PLAN',
          ]);
        } else {
          setBillingModalitiesPanel(['PAY_PER_USE', 'BASIC_PLAN', 'ADVANCED_PLAN', 'PROFESSIONAL_PLAN']);
        }

        setLoading(false);
      } catch (err) {
        console.debug('[DEBUG] useEffect fetchClient error ', err);
      }
    };

    setStates(addressService.getStates());

    if (action === 'edit') {
      fetchClient();
    } else {
      if (me.type === 'OFFICE') {
        setBillingModalitiesPanel([
          'PAY_PER_USE',
          'BASIC_LEGACY_PLAN',
          'BASIC_PLAN',
          'ADVANCED_PLAN',
          'PROFESSIONAL_PLAN',
        ]);
      } else {
        setBillingModalitiesPanel(['PAY_PER_USE', 'BASIC_PLAN', 'ADVANCED_PLAN', 'PROFESSIONAL_PLAN']);
      }
      setLoading(false);
    }
  }, [action, id]);

  const handleChange = async evt => {
    const { name } = evt.target;
    let { value } = evt.target;

    const parsed = parseInputName(name, value);
    const parent = Object.keys(parsed)[0];

    if (name === 'client_type') setType(value);

    if (name === 'showBillingAddress') {
      if (value === 'true') {
        const state = { ...client };
        delete state.billing_address;

        setClient(state);
      }

      setShowBillingAddress(value === 'true');
      return;
    }

    if (name.includes('zipcode') && addressService.validateZipcode(value)) {
      await fillAddress(parent, value);
      return;
    }

    if (name.includes('state_id')) {
      await fillCities(parent, value);

      value = isBrazilianFranchisee ? Number(value) : value;
      parsed[parent].state_id = value;
      parsed[parent].state = states.find(state => state?.id === value)?.name;
    }

    if (name.includes('city_id')) {
      await fillNeighborhoods(parent, value);

      value = isBrazilianFranchisee ? Number(value) : value;
      parsed[parent].city_id = value;

      if (name.includes('billing_address')) {
        parsed[parent].city = billingCities.find(billingCity => billingCity?.id === value)?.name;
      } else {
        parsed[parent].city = cities.find(city => city?.id === value)?.name;
      }
    }

    if (name.includes('neighborhood_id')) {
      value = isBrazilianFranchisee && Number.isInteger(Number(value)) ? Number(value) : value;

      parsed[parent].neighborhood_id = value;

      const neighborhoodsToSearch = name.includes('billing_address') ? billingNeighborhoods : neighborhoods;

      const neighborhoodFound = neighborhoodsToSearch.find(neighborhood => {
        return neighborhood?.id === value || neighborhood?.name === value;
      });

      parsed[parent].neighborhood = neighborhoodFound?.name || '';
    }

    if (name === 'document' && value.length === 18 && action === 'new') findCnpj(value);

    const parsedValue = typeof parsed[parent] !== 'string' ? { ...client[parent], ...parsed[parent] } : parsed[parent];
    setClient({ ...client, [parent]: parsedValue });
  };

  const handleSubmit = async evt => {
    evt.preventDefault();

    try {
      setBusy(true);

      const payload = { ...client };

      // Ensure 'hotsite_configuration' exists, initialize it if undefined
      if (!payload.hotsite_configuration) {
        payload.hotsite_configuration = {}; // Initialize as an empty object
      }

      if (
        payload?.hotsite_configuration?.can_register_maintenances === undefined ||
        payload?.hotsite_configuration?.can_register_maintenances === 'true'
      ) {
        payload.hotsite_configuration.can_register_maintenances = true;
      } else if (payload?.hotsite_configuration?.can_register_maintenances === 'false') {
        payload.hotsite_configuration.can_register_maintenances = false;
      }

      if (action === 'edit') {
        await apiRequest.put(`/clients/${id}`, payload);
      } else {
        await apiRequest.post('/clients', payload);
      }

      let redirectURL;
      if (action === 'edit') {
        redirectURL = `/clients/${status}/${id}`;
      } else {
        redirectURL = '/clients';
      }

      history.replace(redirectURL);
    } catch (err) {
      console.debug('[DEBUG] handleSubmit error ', err);

      let message;

      if (err.response) {
        const firstKey = Object.keys(err.response.data)[0];
        message = intl.formatMessage({ id: `${firstKey}.${err.response.data[firstKey]}` });
      } else {
        message = intl.formatMessage({ id: 'DATA_SAVE_ERROR' });
      }

      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: message,
          duration: 3000,
        })
      );
    } finally {
      setBusy(false);
    }
  };

  return (
    <ClientFormComponent
      action={action}
      busy={busy}
      client={client}
      clientId={id}
      loading={loading}
      loadingCnpj={loadingCnpj}
      showBillingAddress={showBillingAddress}
      classifications={classifications}
      billingModalities={billingModalities}
      billingModalitiesPanel={billingModalitiesPanel}
      zipcodeMask={zipcodeMask}
      states={states}
      cities={cities}
      billingCities={billingCities}
      neighborhoods={neighborhoods}
      billingNeighborhoods={billingNeighborhoods}
      status={status}
      type={type}
      intl={intl}
      onChange={handleChange}
      onSubmit={handleSubmit}
      cnpjMask={cnpjMask}
      cpfOnlyMask={cpfOnlyMask}
      phoneMask={phoneMask}
      cellphoneMask={cellphoneMask}
      cellphoneMaskLength={cellphoneMaskLength}
    />
  );
};

ClientFormContainer.propTypes = {
  action: PropTypes.string.isRequired,
};

export default ClientFormContainer;

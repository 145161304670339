import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const ReportItem = props => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.307617 2.30766C0.307617 1.11812 1.27193 0.153809 2.46146 0.153809H7.4C7.97124 0.153809 8.51908 0.380731 8.923 0.784656L12.5998 4.4615C13.0038 4.86543 13.2307 5.41326 13.2307 5.9845V15.2307C13.2307 16.4203 12.2664 17.3846 11.0768 17.3846H2.46146C1.27193 17.3846 0.307617 16.4203 0.307617 15.2307V2.30766ZM2.46146 8.76919C2.46146 8.17442 2.94362 7.69227 3.53839 7.69227H9.99992C10.5947 7.69227 11.0768 8.17442 11.0768 8.76919C11.0768 9.36396 10.5947 9.84612 9.99992 9.84612H3.53839C2.94362 9.84612 2.46146 9.36396 2.46146 8.76919ZM3.53839 12C2.94362 12 2.46146 12.4821 2.46146 13.0769C2.46146 13.6717 2.94362 14.1538 3.53839 14.1538H9.99992C10.5947 14.1538 11.0768 13.6717 11.0768 13.0769C11.0768 12.4821 10.5947 12 9.99992 12H3.53839Z"
      fill="#626262"
    />
  </SvgIcon>
);

ReportItem.displayName = 'ReportItem';
ReportItem.muiName = 'SvgIcon';

export default ReportItem;

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import PropTypes from 'prop-types';

import OrderIcon from 'src/components/OrderIcon/OrderIcon';
import classes from 'src/components/OrderOptionList/OrderOptionsList.module.scss';
import { orderType } from 'src/constants';
import faqImage from 'src/images/icons/circle-faq-dark.svg';

const OrderOptionsList = ({ features, handleOrderOptionSelect, withDescription }) => {
  const intl = useIntl();
  const config = JSON.parse(window.sessionStorage.getItem('orderTypeConfigurations'));
  const hasHelp = option => {
    const includeHelp = ['VISTORIA_DOCUSIGN'];

    return includeHelp.includes(option);
  };

  const options = features
    .filter(feature => {
      if (!feature || (feature === 'VISTORIA_DOCUSIGN' && !config?.docusign)) return false;

      return feature.indexOf('VISTORIA') !== -1;
    })
    .map(option => (
      <li key={option}>
        <button
          type="button"
          className={hasHelp(option) ? classes.hasHelp : null}
          onClick={() => handleOrderOptionSelect(orderType.get(option))}
          data-cy={`${orderType.get(option)}-new-order-option`}
        >
          <div className={classes.icon}>
            <OrderIcon orderType={option} />
          </div>
          <div className="text">
            <div className={classes.title}>
              <FormattedMessage id={orderType.get(option)} />
            </div>
            {withDescription && intl.formatMessage({ id: `description.${orderType.get(option)}` }).trim() !== '' ? (
              <div className={classes.description}>
                <FormattedMessage id={`description.${orderType.get(option)}`} defaultMessage=" " />
              </div>
            ) : null}
            {hasHelp(option) ? (
              <a
                href="https://gruporv.movidesk.com/kb"
                className={classes.help}
                target="_blank"
                rel="noreferrer noopener"
                onClick={evt => evt.stopPropagation()}
                title="Visite nosso FAQ e tire suas dúvidas"
              >
                <img src={faqImage} alt="FAQ" />
              </a>
            ) : null}
          </div>
        </button>
      </li>
    ));

  return (
    <div className={classes.orderOptionsList}>
      <div className={classes.title}>
        <h1>
          <FormattedMessage id="OrderOptionsList.newOrder" />
        </h1>
        <h2>
          <FormattedMessage id="OrderOptionsList.select" />
        </h2>
      </div>
      <ul className={`${classes.typeList} ${withDescription ? classes.hasDescription : ''}`}>{options}</ul>
    </div>
  );
};

OrderOptionsList.propTypes = {
  /**
   * Function to hanlde the option selection
   */
  handleOrderOptionSelect: PropTypes.func.isRequired,
  /**
   * Features of the current user
   */
  features: PropTypes.array.isRequired,
  /**
   * Boolean to know if the button have the order type description
   */
  withDescription: PropTypes.bool.isRequired,
};

export default OrderOptionsList;

import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import * as yup from 'yup';

import FixAddressComponent from 'src/components/FixAddress/FixAddressComponent';
import { useUser } from 'src/contexts/UserContext';
import states from 'src/data/states-br.json';
import AddressService from 'src/services/addressService';

const validationSchema = yup.object().shape({
  state: yup.object().required(),
  city: yup.object().required(),
  neighborhood: yup.object().required(),
});

const FixAddressContainer = ({ orderData, onChange }) => {
  const [cities, setCities] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const {
    address_validation: addressValidation,
    form: { address },
  } = orderData;
  const formik = useRef();
  const initialValues = {
    state: '',
    city: '',
    neighborhood: '',
  };
  const me = useUser();
  const addressService = new AddressService(me?.profile?.country);

  const fetchNeighborhoods = async (city, prefill = false) => {
    if (city) {
      const data = await addressService.getNeighborhoodsByCity(city);
      setNeighborhoods(
        data.map(neighborhood => ({
          value: neighborhood.id,
          label: neighborhood.name,
        }))
      );
      if (prefill) {
        const neighborhoodOption = data.find(
          neighborhood => neighborhood.name === orderData?.form?.address?.neighborhood
        );
        formik.current.setFieldValue('neighborhood', {
          value: neighborhoodOption.id,
          label: neighborhoodOption.name,
        });
      }
    }
  };

  const fetchCities = async (state, prefill = false) => {
    if (state) {
      const data = await addressService.getCitiesByState(state);
      setCities(data.map(city => ({ value: city.id, label: city.name })));
      if (prefill) {
        const cityOption = data.find(city => city.name === orderData?.form?.address?.city);
        formik.current.setFieldValue('city', {
          value: cityOption.id,
          label: cityOption.name,
        });

        if (orderData?.address_validation?.neighborhood === 'valid') {
          fetchNeighborhoods(cityOption?.id || null, true);
        } else {
          fetchNeighborhoods(cityOption?.id || null, false);
        }
      }
    }
  };

  const handleStateChange = state => {
    formik.current.setFieldValue('state', state || '');
    formik.current.setFieldValue('city', '');
    formik.current.setFieldValue('neighborhood', '');
    setNeighborhoods([]);
    setCities([]);
    fetchCities(state?.value || null);
  };

  const handleCityChange = async city => {
    formik.current.setFieldValue('city', city || '');
    formik.current.setFieldValue('neighborhood', '');
    setNeighborhoods([]);
    fetchNeighborhoods(city?.value || null);
  };

  const handleNeighborhoodChange = async neighborhood => {
    formik.current.setFieldValue('neighborhood', neighborhood || '');
    if (!neighborhood) onChange(null);
  };

  const selectPrediction = ({ state, city, neighborhood }) => {
    formik.current.setFieldValue('state', {
      value: state.id,
      label: state.name,
    });
    formik.current.setFieldValue('city', { value: city.id, label: city.name });
    formik.current.setFieldValue('neighborhood', {
      value: neighborhood.id,
      label: neighborhood.name,
    });
    fetchCities(state.id);
    fetchNeighborhoods(city.id);
  };

  const handleSubmit = values => {
    onChange({
      state: {
        id: values.state?.value,
        name: values.state?.label,
      },
      city: {
        id: values.city?.value,
        name: values.city?.label,
      },
      neighborhood: {
        id: typeof values.neighborhood?.value === 'number' ? values.neighborhood?.value : null,
        name: values.neighborhood?.label,
      },
    });
  };

  useEffect(() => {
    let stateOption = null;
    if (orderData?.address_validation?.state === 'valid') {
      stateOption = states.find(state => state.name === orderData?.form?.address?.state);
      formik.current.setFieldValue('state', stateOption);

      if (orderData?.address_validation?.city === 'valid') {
        fetchCities(stateOption.id, true);
      } else {
        fetchCities(stateOption.id, false);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FixAddressComponent
      initialValues={initialValues}
      address={address}
      states={states.map(state => ({ value: state.id, label: state.name }))}
      cities={cities}
      neighborhoods={neighborhoods}
      predictions={orderData?.address_validation?.predictions}
      addressValidation={addressValidation || undefined}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      onCityChange={handleCityChange}
      onNeighborhoodChange={handleNeighborhoodChange}
      onStateChange={handleStateChange}
      selectPrediction={selectPrediction}
      formikRef={formik}
    />
  );
};

FixAddressContainer.propTypes = {
  orderData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FixAddressContainer;

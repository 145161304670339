import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Select, { components } from 'react-select';

import { AddCircle } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { v4 as uuidV4 } from 'uuid';

import RemoveRecipientIcon from 'src/orderForm/fields/RecipientsField/RemoveRecipientIcon.svg';
import ReportTypeSelectOption from 'src/orderForm/fields/RecipientsField/ReportTypeSelectOption';
import classes from 'src/orderForm/fields/RecipientsField/style.module.scss';

const RecipientsField = ({ formData, onChange, registry }) => {
  const [recipientItems, setRecipientItems] = useState(() => {
    if (!Array.isArray(formData) || !formData?.length) return [];

    const initialState = formData.map(item => ({ id: uuidV4(), ...item }));

    return initialState;
  });

  const intl = useIntl();

  const handleMapRecipientItemsWithoutId = items => items?.map(({ id, ...item }) => item) || [];

  const handleEmailInputChange = (email, itemId) => {
    setRecipientItems(state => {
      const withUpdatedItem = state.map(item => {
        if (item?.id !== itemId) return item;

        return { ...item, email };
      });
      onChange(handleMapRecipientItemsWithoutId(withUpdatedItem));

      return withUpdatedItem;
    });
  };

  const handleRecipientSelectChange = (itemId, selectedOptions) => {
    setRecipientItems(state => {
      const withUpdatedItem = state.map(item => {
        if (item?.id !== itemId) return item;

        return {
          ...item,
          reports: selectedOptions?.map(selectedOption => selectedOption?.value) || [],
        };
      });
      onChange(handleMapRecipientItemsWithoutId(withUpdatedItem));

      return withUpdatedItem;
    });
  };

  const handleAddRecipientItem = () => {
    setRecipientItems(state => {
      const withNewEmptyItem = [...state, { id: uuidV4() }];
      onChange(handleMapRecipientItemsWithoutId(withNewEmptyItem));

      return withNewEmptyItem;
    });
  };

  const handleRemoveRecipientItem = itemId => {
    setRecipientItems(state => {
      const withoutItem = state.filter(item => item?.id !== itemId);
      onChange(handleMapRecipientItemsWithoutId(withoutItem));

      return withoutItem;
    });
  };

  const enumValues = registry?.definitions?.recipient?.properties?.reports?.items?.enum || [];
  const enumLabels = registry?.definitions?.recipient?.properties?.reports?.items?.enumNames || [];

  const mappedOptions = enumValues?.map((enumValue, index) => ({
    label: enumLabels[index],
    value: enumValue,
  }));

  return (
    <section className={classes.recipientsField}>
      <header>
        <h4>
          <FormattedMessage id="RECIPIENT" />
        </h4>

        <h4>
          <FormattedMessage id="SELECT_DOCUMENT" />
        </h4>
      </header>

      <ul>
        {recipientItems?.map(recipientItem => (
          <li key={recipientItem?.id}>
            <input
              id="recipient-email-input"
              data-cy="recipient-email-input"
              type="email"
              onChange={event => handleEmailInputChange(event.target.value, recipientItem?.id)}
              placeholder={intl.formatMessage({ id: 'TYPE_AN_EMAIL_ADDRESS' })}
              value={recipientItem?.email}
            />

            <Select
              id="recipient-report-type-select"
              className={classes.select}
              classNamePrefix="select"
              options={mappedOptions || []}
              value={
                recipientItem?.reports?.map(report => ({
                  label: mappedOptions?.find(mappedOption => mappedOption?.value === report)?.label || '',
                  value: report,
                })) || []
              }
              onChange={options => handleRecipientSelectChange(recipientItem?.id, options)}
              components={{
                Input: inputProps => <components.Input {...inputProps} data-cy="recipient-report-type-input" />,
                Option: ReportTypeSelectOption,
              }}
              placeholder={intl.formatMessage({ id: 'SELECT_THE_REPORTS' })}
              noOptionsMessage={() => intl.formatMessage({ id: 'NO_OPTIONS_AVAILABLE_MESSAGE' })}
              hideSelectedOptions={false}
              isMulti
            />

            {recipientItems?.length > 1 && (
              <button type="button" onClick={() => handleRemoveRecipientItem(recipientItem?.id)}>
                <img src={RemoveRecipientIcon} alt="Remove Recipient Icon" />
              </button>
            )}
          </li>
        ))}
      </ul>

      <button className={classes.addRecipientButton} type="button" onClick={handleAddRecipientItem}>
        <AddCircle className={classes.addCircle} />

        <FormattedMessage id="ADD_RECIPIENT" />
      </button>
    </section>
  );
};

RecipientsField.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  registry: PropTypes.object.isRequired,
};

RecipientsField.defaultProps = {
  formData: null,
};

export default RecipientsField;

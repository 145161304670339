import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/RecommendationSection/style.module.scss';
import Recommendation from 'src/svg-icons/recommendation';

const RecommendationSection = ({ data }) => (
  <Grid item xs={12}>
    <div className={classes.header}>
      <Recommendation className={classes.icon} />
      <FormattedMessage id="recommendation" />
    </div>
    <Grid container spacing={8} className={classes.information}>
      <Grid item xs={12} data-cy="order-details-recommendation-code">
        <div className={classes.subHeader}>
          <FormattedMessage id="code" />
        </div>
        <span>{data.code}</span>
      </Grid>
    </Grid>
  </Grid>
);

RecommendationSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RecommendationSection;

import React from 'react';

import PropTypes from 'prop-types';

import Extract from 'src/images/icons/extract.png';
import CalendarIcon from 'src/svg-icons/calendar-menu';
import MenuDivergences from 'src/svg-icons/divergences-menu';
import FinishedOrders from 'src/svg-icons/finished-orders';
import InspectorsIcon from 'src/svg-icons/inspector';
import SubMenuAtivos from 'src/svg-icons/menu-ativos';
import MenuClientes from 'src/svg-icons/menu-clientes';
import MenuDashboard from 'src/svg-icons/menu-dashboard';
import MenuDispositivos from 'src/svg-icons/menu-dispositivos';
import SubMenuRemovidos from 'src/svg-icons/menu-removidos';
import SettingsIcon from 'src/svg-icons/menu-settings';
import MenuVistorias from 'src/svg-icons/menu-vistorias';
import SectionArquivadas from 'src/svg-icons/section-arquivadas';
import SectionCanceladas from 'src/svg-icons/section-canceladas';
import SectionEnviadas from 'src/svg-icons/section-enviadas';
import SectionIndependentes from 'src/svg-icons/section-independentes';
import SectionPrincipal from 'src/svg-icons/section-principal';
import SectionRecusadas from 'src/svg-icons/section-recusadas';
import TypeNone from 'src/svg-icons/type-none';

const menuIcons = {
  active: <SectionPrincipal viewBox="0 0 34.016 34.016" style={{ marginRight: '16px', fontSize: '18px' }} />,
  main: <SectionPrincipal viewBox="0 0 34.016 34.016" style={{ marginRight: '16px', fontSize: '18px' }} />,
  finished: <FinishedOrders viewBox="0 0 34.016 34.016" style={{ marginRight: '16px', fontSize: '16px' }} />,
  sent: <SectionEnviadas viewBox="0 0 34.016 34.016" style={{ marginRight: '16px', fontSize: '18px' }} />,
  archived: <SectionArquivadas viewBox="0 0 24 24" style={{ marginRight: '16px', fontSize: '18px' }} />,
  independent: <SectionIndependentes viewBox="0 0 34.016 34.016" style={{ marginRight: '16px', fontSize: '18px' }} />,
  rejected: <SectionRecusadas style={{ marginRight: '18px', fontSize: '16px' }} />,
  canceled: <SectionCanceladas viewBox="0 0 34.016 34.016" style={{ marginRight: '16px', fontSize: '18px' }} />,
  inspections: <MenuVistorias viewBox="0 0 50 49.998" style={{ marginRight: '8px', fontSize: '34px' }} />,
  clients: <MenuClientes viewBox="0 0 50 49.998" style={{ marginRight: '8px', fontSize: '34px' }} />,
  divergences: <MenuDivergences style={{ marginRight: '8px', fontSize: '34px' }} />,
  dashboard: <MenuDashboard style={{ marginRight: '8px', fontSize: '34px' }} />,
  devices: <MenuDispositivos viewBox="0 0 50 49.998" style={{ marginRight: '8px', fontSize: '34px' }} />,
  actives: <SubMenuAtivos viewBox="0 0 34.016 34.016" style={{ marginRight: '8px', fontSize: '18px' }} />,
  deleted: <SubMenuRemovidos viewBox="0 0 34.016 34.016" style={{ marginRight: '8px', fontSize: '18px' }} />,
  schedule: <CalendarIcon style={{ marginRight: '8px', fontSize: '34px' }} />,
  settings: <SettingsIcon style={{ marginRight: '8px', fontSize: '34px' }} />,
  inspectors: (
    <span
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '34px',
        height: '34px',
        marginRight: '0.5em',
        border: '1px solid #5578a0',
        borderRadius: '50%',
      }}
    >
      <InspectorsIcon style={{ fontSize: '16px' }} />
    </span>
  ),
  extract: (
    <span
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '34px',
        height: '34px',
        marginRight: '0.5em',
        border: '1px solid #5578a0',
        borderRadius: '50%',
      }}
    >
      <img style={{ height: 'auto', width: '18px' }} src={Extract} alt="Extract" />
    </span>
  ),
};

function MenuIcon({ menuKey }) {
  if (menuKey in menuIcons) {
    return menuIcons[menuKey];
  }

  return <TypeNone viewBox="0 0 34.016 34.016" style={{ marginRight: '16px', fontSize: '18px' }} />;
}

MenuIcon.propTypes = {
  /**
   * String with the menu key
   */
  menuKey: PropTypes.string.isRequired,
};

export default MenuIcon;

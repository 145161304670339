import React from 'react';

import PropTypes from 'prop-types';

import classes from 'src/components/CommercialPage/ClientList/ClientCard/style.module.scss';
import Card from 'src/components/common/Card';
import clientImage from 'src/components/common/icons/client-details.svg';

const ClientCard = ({ data }) => (
  <Card url={`/commercial/clients/${data?.id}`}>
    <div className={classes.name}>
      <div className={classes.icon}>
        <img src={clientImage} alt="Cliente" />
      </div>
      <div className={classes.text}>{data?.name}</div>
    </div>
    {(data?.phone || data?.email) && (
      <div className={classes.line}>
        {data?.phone && <div className={classes.phone}>{data?.phone}</div>}
        {data?.email && <div className={classes.email}>{data?.email}</div>}
      </div>
    )}
  </Card>
);

ClientCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ClientCard;

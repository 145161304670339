import React from 'react';

import PropTypes from 'prop-types';

import classes from 'src/components/AddCroqui/style.module.scss';
import ColorButton from 'src/components/common/elements/ColorButton';
import ImageUpload from 'src/components/common/elements/ImageUpload';

const AddCroquiComponent = ({ handleChange, handleCancel, handleSubmit, intl }) => (
  <div className={classes.addCroqui}>
    <h3>{intl.formatMessage({ id: 'ADD_CROQUI' })}</h3>
    <div className={classes.field}>
      <div className={classes.field}>
        <label htmlFor="file">{intl.formatMessage({ id: 'CROQUI_EXPLANATION' })}</label>
        <ImageUpload name="file" id="file" onChange={handleChange} />
      </div>
      <div className={`${classes.field} ${classes.actions}`}>
        <ColorButton type="submit" color="red" onClick={handleCancel}>
          {intl.formatMessage({ id: 'CANCEL' })}
        </ColorButton>
        <ColorButton type="submit" color="green" onClick={handleSubmit}>
          {intl.formatMessage({ id: 'SAVE' })}
        </ColorButton>
      </div>
    </div>
  </div>
);

AddCroquiComponent.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  uri: PropTypes.string,
};

AddCroquiComponent.defaultProps = {
  uri: null,
};

export default AddCroquiComponent;

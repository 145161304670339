import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import API from 'src/apiRequest';
import ClientListComponent from 'src/components/CommercialPage/ClientList/ClientListComponent';
import { setOptions, setQuery } from 'src/store/ducks/filter';
import { showToast } from 'src/store/ducks/toasts';

const ClientListContainer = () => {
  const [clientsWithNoFranchisee, setClientsWithNoFranchisee] = useState([]);
  const [busy, setBusy] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const isInitialMount = useRef(true);

  const dispatch = useDispatch();
  const { query, lastReload } = useSelector(state => ({
    query: state.filter.query,
    lastReload: state.cards.lastReload,
  }));
  const intl = useIntl();

  const fetchClientsWithNoFranchisee = useCallback(
    async (page, append = false, filter) => {
      if (!append) setBusy(true);

      try {
        const { data } = await API.get('/clients', {
          params: {
            page: page ? page + 1 : null,
            search: filter?.text || null,
          },
        });

        setHasMore((data?.page + 1) * data?.quantity < data?.total);

        if (!append) {
          setClientsWithNoFranchisee(data?.data);
        } else {
          setClientsWithNoFranchisee(previousClients => [...previousClients, ...(data?.data || [])]);
        }
      } catch (err) {
        dispatch(
          showToast({
            type: 'error',
            title: intl.formatMessage({ id: 'ERROR' }),
            text: intl.formatMessage({ id: 'CUSTOMERS_LOAD_LIST_ERROR' }),
            duration: 5000,
          })
        );
      } finally {
        setBusy(false);
      }
    },
    [dispatch, intl]
  );

  const loadMore = useCallback(
    page => {
      fetchClientsWithNoFranchisee(page, true, query);
    },
    [fetchClientsWithNoFranchisee, query]
  );

  useEffect(() => {
    if (isInitialMount.current) {
      dispatch(setOptions({ basic: ['text'] }));
      dispatch(setQuery({}));

      isInitialMount.current = false;
    } else {
      fetchClientsWithNoFranchisee(null, false, query);
    }
  }, [dispatch, fetchClientsWithNoFranchisee, lastReload, query]);

  return <ClientListComponent busy={busy} clients={clientsWithNoFranchisee} loadMore={loadMore} hasMore={hasMore} />;
};

export default ClientListContainer;

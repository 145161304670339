/* eslint-disable indent */
import React from 'react';
import { injectIntl } from 'react-intl';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import classes from 'src/components/AcceptedTermsModal/style.module.css';
import FileIcon from 'src/svg-icons/Files';

class AcceptedTermsModal extends React.Component {
  constructor() {
    super();
    this.state = {
      checked: false,
      acceptedTerms: false,
    };
  }

  componentDidMount() {
    const { contracts } = this.props;

    this.setState({
      acceptedTerms: contracts?.pending.length === 0,
    });
  }

  isChecked = event => {
    this.setState({
      checked: event.target.checked,
    });
  };

  acceptTerms = () => {
    const { contracts } = this.props;

    API.post('contracts/accept', {
      contracts: contracts?.pending,
    })
      .then(res => {
        if (res.status >= 200 && res.status <= 299) {
          this.setState({
            acceptedTerms: true,
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const { contracts, intl } = this.props;
    const { acceptedTerms, checked, name } = this.state;

    const modalClasses = [classes.Backdrop];

    if (acceptedTerms) {
      modalClasses.push(classes.hide);
    }

    return (
      <div className={modalClasses.join(' ')}>
        <div className={classes.Modal}>
          <p className={classes.Title}>
            {contracts?.pending.length > 1
              ? intl.formatMessage({
                  id: 'REGISTRATION_READ_CONTRACTS_MESSAGE',
                })
              : intl.formatMessage({
                  id: 'REGISTRATION_READ_CONTRACT_MESSAGE',
                })}
            :
          </p>
          <div className={classes.Files}>
            {contracts?.pending.map(contract => (
              // eslint-disable-next-line react/jsx-key
              <div>
                <FileIcon viewBox="0 0 26 26" className={classes.Icon} />
                <a href={contract.url} target="_blank" rel="noopener noreferrer">
                  {contract.name}
                </a>
              </div>
            ))}
          </div>
          <span className={classes.actions}>
            <span className={classes.checkboxWrapper}>
              <Checkbox onChange={this.isChecked} classes={{ checked: classes.checked }} id="terms" />
              <label htmlFor="terms">{intl.formatMessage({ id: 'ACCEPT_CONTRACT_MESSAGE' })}</label>
            </span>
            <Button className={classes.Button} disabled={!checked} onClick={this.acceptTerms}>
              {intl.formatMessage({ id: 'CONFIRM' })}
            </Button>
          </span>
        </div>
      </div>
    );
  }
}

AcceptedTermsModal.propTypes = {
  contracts: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(AcceptedTermsModal);

import React from 'react';

import Content from 'src/components/SchedulePage/Timeline/Content';
import InspectorList from 'src/components/SchedulePage/Timeline/InspectorList';
import PendingOrders from 'src/components/SchedulePage/Timeline/PendingOrders';
import classes from 'src/components/SchedulePage/Timeline/style.module.scss';

const TimelineComponent = () => (
  <div className={classes.timeline}>
    <div className={classes.leftListsContainer}>
      <PendingOrders />

      <InspectorList />
    </div>

    <div className={classes.contentWrapper}>
      <Content />
    </div>
  </div>
);

export default TimelineComponent;

import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ClientsPage from 'src/components/ClientsPage';
import PageHeader from 'src/components/common/PageHeader';
import SideBar from 'src/components/common/SideBar';
import FranchiseeSettingsPage from 'src/components/FranchiseeSettingsPage';
import InspectorsPage from 'src/components/InspectorsPage';
import classes from 'src/components/Page/style.module.scss';
import SchedulePage from 'src/components/SchedulePage';

const Page = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const handleToggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  return (
    <div className={classes.page}>
      <PageHeader sidebarCollapsed={sidebarCollapsed} />
      <div className={classes.container}>
        <SideBar handleToggleSidebar={handleToggleSidebar} sidebarCollapsed={sidebarCollapsed} />
        <main className={classes.content}>
          <Switch>
            <Route path="/clients" component={ClientsPage} />
            <Route path="/inspectors" component={InspectorsPage} />
            <Route path="/schedule" render={() => <SchedulePage sidebarCollapsed={sidebarCollapsed} />} />
            <Route path="/settings" component={FranchiseeSettingsPage} />
            <Redirect to="/" />
          </Switch>
        </main>
      </div>
    </div>
  );
};

export default Page;

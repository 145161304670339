import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import MaskedInput from 'react-text-mask';

import Moment from 'moment';
import PropTypes from 'prop-types';

import classes from 'src/components/AssignInspector/AssignInspector.module.scss';
import { orderType as parseOrderType } from 'src/constants';
import ApiContext from 'src/contexts/ApiContext';
import { TIME_MASK } from 'src/helpers/mask';
import agendaImage from 'src/images/icons/calendar-circle.svg';
import InspectorsFilter from 'src/inspectionAttach/InspectorsFilter';

class AssignInspector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dates: [],
      errorMessage: null,
    };
    this.features = null;
  }

  componentDidMount() {
    const dateOptions = Array(30)
      .fill()
      .map((item, index) => {
        const day = Moment().add(index, 'days').format('DD/MM/YYYY');
        return { value: day, label: day };
      });

    this.features = JSON.parse(window.sessionStorage.getItem('me'))?.features.map(feature =>
      parseOrderType.get(feature)
    );

    this.setState({
      dates: dateOptions,
    });
  }

  selectInspectorHandler = () => {
    this.setState({ errorMessage: null });
  };

  assignConfirmedHandler = evt => {
    evt.preventDefault();
    const { assignDeviceToOrder, intl } = this.props;
    const formData = new FormData(document.getElementById('assignInspectorForm'));
    const startTime = new Date();
    const endTime = new Date();
    const [startHour, startMinute] = formData.get('start_time').split(':');
    startTime.setHours(startHour);
    startTime.setMinutes(startMinute);
    const [endHour, endMinute] = formData.get('end_time').split(':');
    endTime.setHours(endHour);
    endTime.setMinutes(endMinute);

    if (formData.get('inspector').length !== 36) {
      this.setState({
        errorMessage: intl.formatMessage({ id: 'MESSAGE.INSPECTOR_REQUIRED' }),
      });
      return;
    }

    if (formData.get('date').length !== 10) {
      this.setState({
        errorMessage: intl.formatMessage({ id: 'MESSAGE.DATE_REQUIRED' }),
      });
      return;
    }

    if (formData.get('start_time').length < 5 || formData.get('end_time').length < 5) {
      this.setState({
        errorMessage: intl.formatMessage({ id: 'MESSAGE.INVALID_DATE' }),
      });
      return;
    }

    if (startTime >= endTime) {
      this.setState({
        errorMessage: intl.formatMessage({ id: 'MESSAGE.WRONG_DATE' }),
      });
      return;
    }

    const date = String(formData.get('date')).replace(/(\d+[/])(\d+[/])/, '$2$1');

    const formattedDate = Moment(new Date(date)).format('YYYY-MM-DD');

    const formValues = {
      inspector: formData.get('inspector'),
      date: formattedDate,
      start_time: formData.get('start_time'),
      end_time: formData.get('end_time'),
      message: formData.get('message'),
    };

    assignDeviceToOrder(formValues);
  };

  render() {
    const { dates, errorMessage } = this.state;
    const { cancelHandler, intl } = this.props;

    if (this.features?.indexOf('schedule') < -1) {
      return (
        <div className={classes.AssignInspector}>
          <div className={classes.Title}>
            <h2>{intl.formatMessage({ id: 'SCHEDULE' })}</h2>
            <h3>{intl.formatMessage({ id: 'ASSIGNMENT' })}</h3>
            <div className={classes.badge}>{intl.formatMessage({ id: 'NEW' })}</div>
          </div>
          <div className={classes.content}>
            <div className={classes.icon}>
              <img src={agendaImage} alt={intl.formatMessage({ id: 'SCHEDULE' })} />
            </div>
            <div className={classes.text}>
              {intl.formatMessage({ id: 'INSPECTOR_ASSIGN_01_MESSAGE' })}
              <br />
              {intl.formatMessage({ id: 'INSPECTOR_ASSIGN_02_MESSAGE' })}
              <br />
              {intl.formatMessage({ id: 'INSPECTOR_ASSIGN_03_MESSAGE' })}
            </div>
          </div>
          <hr />
          <div className={classes.actions}>
            <Link to="/schedule">{intl.formatMessage({ id: 'GO_TO_SCHEDULE' })}</Link>
          </div>
        </div>
      );
    }

    return (
      <ApiContext.Consumer>
        {({ status }) => (
          <form id="assignInspectorForm" onSubmit={this.assignConfirmedHandler}>
            <div className={classes.AssignInspector}>
              <div className={classes.Title}>
                <h2>{intl.formatMessage({ id: 'ASSIGN_INSPECTOR_MESSAGE' })}</h2>
                <h3>
                  {intl.formatMessage({
                    id: 'SHOULD_PERFORM_INSPECTION_MESSAGE',
                  })}
                </h3>
              </div>
              <div className={classes.InspectorFilter}>
                <InspectorsFilter handleSelect={this.selectInspectorHandler} />
              </div>
              <div className={classes.Schedule}>
                <div>
                  <label htmlFor="date">
                    <FormattedMessage id="LABEL.DATE" />
                  </label>
                  <Select
                    options={dates}
                    id="date"
                    isSearchable={false}
                    placeholder={intl.formatMessage({
                      id: 'PLACEHOLDER.SELECT_DATE',
                    })}
                    name="date"
                  />
                </div>
                <div className={classes.twoColumns}>
                  <div>
                    <label htmlFor="start_time">
                      <FormattedMessage id="LABEL.START_TIME" />
                    </label>
                    <MaskedInput
                      type="text"
                      name="start_time"
                      id="start_time"
                      placeholder={intl.formatMessage({
                        id: 'LABEL.START_TIME',
                      })}
                      required
                      guide={false}
                      mask={TIME_MASK}
                    />
                  </div>
                  <div>
                    <label htmlFor="end_date">
                      <FormattedMessage id="LABEL.END_TIME" />
                    </label>
                    <MaskedInput
                      type="text"
                      name="end_time"
                      id="end_date"
                      placeholder={intl.formatMessage({ id: 'LABEL.END_TIME' })}
                      required
                      guide={false}
                      mask={TIME_MASK}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.Observations}>
                <label htmlFor="observations">
                  <FormattedMessage id="LABEL.MESSAGE" />
                </label>
                <textarea name="message" id="observations" />
              </div>
              <div>
                <hr />
              </div>
              {errorMessage ? <div className={classes.ErrorMessage}>{errorMessage}</div> : null}
              <div className={classes.Actions}>
                <button
                  type="button"
                  className={classes.Cancel}
                  onClick={cancelHandler}
                  disabled={status === 'WORKING'}
                >
                  <FormattedMessage id="BUTTON.CANCEL" />
                </button>
                <button type="submit" className={`${classes.Assign} ${status === 'WORKING' ? classes.isBusy : undefined}`} disabled={status === 'WORKING'}>
                  <FormattedMessage id="BUTTON.ASSIGN" />
                </button>
              </div>
            </div>
          </form>
        )}
      </ApiContext.Consumer>
    );
  }
}

AssignInspector.propTypes = {
  assignDeviceToOrder: PropTypes.func.isRequired,
  cancelHandler: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(AssignInspector);

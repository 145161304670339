import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ClientAssignForm from 'src/components/CommercialPage/ClientAssignForm';
import ClientList from 'src/components/CommercialPage/ClientList';
import ClientRegistrationForm from 'src/components/CommercialPage/ClientRegistrationForm';
import CommercialLayout from 'src/components/CommercialPage/CommercialLayout';
import EmptyView from 'src/components/common/EmptyView';
import UserDetails from 'src/components/FranchiseeSettingsPage/UserDetails';
import UserForm from 'src/components/FranchiseeSettingsPage/UserForm';
import UserList from 'src/components/FranchiseeSettingsPage/UserList';
import Section from 'src/components/Page/Section';

const CommercialPage = () => (
  <CommercialLayout>
    <Section cols={6}>
      <Switch>
        <Route path="/commercial/clients" component={ClientList} />
        <Route path="/commercial/clients/new" component={ClientList} />
        <Route exact path="/commercial/settings">
          <Redirect to="/commercial/settings/users" />
        </Route>
        <Route path="/commercial/settings/users" component={UserList} />
      </Switch>
    </Section>
    <Section cols={6}>
      <Switch>
        <Route path="/commercial/clients/new" component={ClientRegistrationForm} />
        <Route path="/commercial/clients/:id" component={ClientAssignForm} />
        <Route exact path="/commercial/settings/users" component={EmptyView} />
        <Route path="/commercial/settings/users/new" render={() => <UserForm action="create" />} />
        <Route path="/commercial/settings/users/:id/edit" render={() => <UserForm action="edit" />} />
        <Route exact path="/commercial/settings/users/:id" component={UserDetails} />
      </Switch>
    </Section>
  </CommercialLayout>
);

export default CommercialPage;

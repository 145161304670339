import React from 'react';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-text-mask';

import PropTypes from 'prop-types';

import classes from 'src/components/ClientsPage/UpdateUserPermissions/style.module.scss';
import Card from 'src/components/common/Card';
import GrayButton from 'src/components/common/elements/GrayButton';
import inspectionTypesIcon from 'src/components/common/icons/records.svg';
import trashImage from 'src/components/common/icons/trash.svg';
import userCircleImage from 'src/components/common/icons/user-circle.svg';
import Spinner from 'src/components/common/Spinner';
import { USERNAME_MASK } from 'src/helpers/mask';

const UpdateUserPermissionsComponent = ({
  isBusy,
  isClientUserFormSubmitting,
  isClientUserDeleteConfirmationVisible,
  permissions,
  handleSelectedPermissionsChange,
  handleClientUserDeleteButtonClick,
  handleClientUserFormSubmit,
  handleToggleClientUserDeleteConfirmation,
  intl,
  onChange,
  user,
  cpfMask,
  cpfMaskPlaceholder,
  phoneMask,
  phoneMaskPlaceholder,
  cellphoneMask,
  cellphoneMaskLength,
}) => (
  <div className={classes.permissionsDetails}>
    {isBusy ? (
      <div className={classes.spinner}>
        <Spinner />
      </div>
    ) : (
      <>
        <Card className="fade-in">
          {isClientUserDeleteConfirmationVisible ? (
            <div className={classes.confirm}>
              <div className={classes.text}>{intl.formatMessage({ id: 'WANT_TO_DELETE_USER_MESSAGE' })}</div>

              <div className={classes.actions}>
                <button type="button" onClick={handleToggleClientUserDeleteConfirmation}>
                  {intl.formatMessage({ id: 'NO' })}
                </button>

                <button type="button" onClick={handleClientUserDeleteButtonClick}>
                  {intl.formatMessage({ id: 'YES' })}
                </button>
              </div>
            </div>
          ) : (
            <div className={classes.toolbar}>
              <div className={classes.actions}>
                <button type="button" onClick={handleToggleClientUserDeleteConfirmation}>
                  <img src={trashImage} alt={intl.formatMessage({ id: 'DELETE' })} />
                </button>
              </div>
            </div>
          )}
        </Card>

        <Card className="fade-in">
          <h3 className={classes.subtitle}>
            <div className={classes.icon}>
              <img src={userCircleImage} alt={intl.formatMessage({ id: 'USER' })} />
            </div>

            {intl.formatMessage({ id: 'BASIC_DATA' })}
          </h3>

          <div className={classes.field}>
            <label htmlFor="name">{intl.formatMessage({ id: 'fullName' })}*</label>

            <input
              type="text"
              name="name"
              id="name"
              data-cy="name"
              required
              onChange={onChange}
              value={user.name || ''}
              placeholder="ex: Usuario Exemplo"
            />
          </div>

          <div className={classes.field}>
            <label htmlFor="cpf">{intl.formatMessage({ id: 'CPF' })}</label>

            <MaskedInput
              type="text"
              name="cpf"
              id="cpf"
              data-cy="cpf"
              onChange={onChange}
              value={user.cpf || ''}
              placeholder={`ex: ${cpfMaskPlaceholder}`}
              guide={false}
              mask={cpfMask}
            />
          </div>

          <div className={classes.field}>
            <label htmlFor="username">{intl.formatMessage({ id: 'USER' })}*</label>

            <MaskedInput
              type="text"
              name="username"
              id="username"
              data-cy="username"
              required
              onChange={onChange}
              disabled
              value={user.username || ''}
              placeholder="ex: usuario_exemplo"
              guide={false}
              mask={USERNAME_MASK}
            />
          </div>

          <div className={classes.field}>
            <label htmlFor="email">{intl.formatMessage({ id: 'EMAIL' })}*</label>

            <input
              type="email"
              name="email"
              id="email"
              data-cy="email"
              required
              disabled
              onChange={onChange}
              value={user.email || ''}
              placeholder="ex: usuario_exemplo@redevistorias.com.br"
            />
          </div>

          <div className={classes.field}>
            <label htmlFor="phone">{intl.formatMessage({ id: 'CONTACT' })}</label>

            <MaskedInput
              type="text"
              name="phone"
              id="phone"
              data-cy="phone"
              onChange={onChange}
              value={user.phone || ''}
              placeholder={`ex: ${phoneMaskPlaceholder}`}
              guide={false}
              mask={value => (value.length >= cellphoneMaskLength ? cellphoneMask : phoneMask)}
            />
          </div>

          <div className={classes.permissions}>
            <h3 className={classes.subtitle}>
              <div className={classes.icon}>
                <img src={inspectionTypesIcon} alt={intl.formatMessage({ id: 'PERMISSIONS' })} />
              </div>

              {intl.formatMessage({ id: 'PERMISSIONS' })}
            </h3>

            <div className={classes.field}>
              <div className={classes.permissionsList}>
                {permissions.length ? (
                  permissions.map(permission => (
                    <div className={classes.permission} key={permission?.id}>
                      <input
                        type="checkbox"
                        name="permission"
                        value={permission?.id}
                        onChange={handleSelectedPermissionsChange}
                        defaultChecked={permission?.selected}
                      />

                      {permission?.description}
                    </div>
                  ))
                ) : (
                  <div className={classes.empty}>{intl.formatMessage({ id: 'NOT_FOUND_MESSAGE' })}</div>
                )}
              </div>
            </div>
          </div>

          <div className={classes.actions}>
            <Link to="../">Cancelar</Link>

            {!!permissions.length && (
              <GrayButton type="button" onClick={handleClientUserFormSubmit} isBusy={isClientUserFormSubmitting}>
                {intl.formatMessage({ id: 'SAVE' })}
              </GrayButton>
            )}
          </div>
        </Card>
      </>
    )}
  </div>
);

UpdateUserPermissionsComponent.propTypes = {
  isBusy: PropTypes.bool.isRequired,
  permissions: PropTypes.array,
  isClientUserFormSubmitting: PropTypes.bool.isRequired,
  isClientUserDeleteConfirmationVisible: PropTypes.bool.isRequired,
  handleSelectedPermissionsChange: PropTypes.func.isRequired,
  handleClientUserDeleteButtonClick: PropTypes.func.isRequired,
  handleClientUserFormSubmit: PropTypes.func.isRequired,
  handleToggleClientUserDeleteConfirmation: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  cpfMask: PropTypes.array.isRequired,
  cpfMaskPlaceholder: PropTypes.string.isRequired,
  phoneMask: PropTypes.array.isRequired,
  phoneMaskPlaceholder: PropTypes.string.isRequired,
  cellphoneMask: PropTypes.array.isRequired,
  cellphoneMaskLength: PropTypes.number.isRequired,
};

UpdateUserPermissionsComponent.defaultProps = {
  permissions: [],
};

export default UpdateUserPermissionsComponent;

import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { format } from 'date-fns';

import API from 'src/apiRequest';
import InspectorReportComponent from 'src/components/InspectorsPage/InspectorReport/InspectorReportComponent';
import { showToast } from 'src/store/ducks/toasts';

const InspectorReportContainer = () => {
  const [isBusy, setIsBusy] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const dispatch = useDispatch();
  const intl = useIntl();

  const { id, status } = useParams();

  const handleStartDateChange = date => setStartDate(date);
  const handleEndDateChange = date => setEndDate(date);

  const handleExportInspectorReport = useCallback(async () => {
    if (!startDate || !endDate) {
      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'EXPORT_STATISTICS_ERROR_MESSAGE' }),
          duration: 5000,
        })
      );

      return;
    }

    setIsBusy(true);

    try {
      const {data} = await API.get('/inspectors/inspections', {
        params: {
          start_date: format(new Date(startDate), 'yyyy-MM-dd'),
          end_date: format(new Date(endDate), 'yyyy-MM-dd'),
          inspector: `{${id}}`,
        },
      });
      dispatch(
        showToast({
          type: 'success',
          title: intl.formatMessage({ id: 'inspectionsTitle' }),
          text: intl.formatMessage({ id: 'inspectionsSummary' }),
          link: data.file,
        })
      );
    } finally {
      setIsBusy(false);
    }
  }, [dispatch, endDate, id, intl, startDate]);

  useEffect(() => {
    const today = new Date();
    const oneMonthAgo = today.setDate(today.getDate() - 30);

    setStartDate(oneMonthAgo);
    setEndDate(new Date());
  }, []);

  return (
    <InspectorReportComponent
      id={id}
      status={status || 'active'}
      isBusy={isBusy}
      onClick={handleExportInspectorReport}
      startDate={startDate}
      endDate={endDate}
      onStartDateChange={handleStartDateChange}
      onEndDateChange={handleEndDateChange}
      intl={intl}
    />
  );
};

export default InspectorReportContainer;

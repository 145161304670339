import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = {
  bootstrapInput: {
    borderRadius: 4,
    fontSize: 'calc(6px + 0.4vw)',
    padding: '8px 12px',
    width: 'calc(100% - 24px)',
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    fontFamily: 'Open Sans',
    backgroundColor: '#F4F3F4',
  },
  label: {
    fontFamily: 'Open Sans',
    marginTop: '0.3vh',
    fontSize: 'calc(6px + 0.3vw)',
  },
};

// Define a custom component for handling the root position object
class RequestFormPreviousPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props.formData };
  }

  render() {
    let { id, name } = this.state;
    return (
      <div>
        {this.props.schema.title ? (
          <div style={styles.label}>
            <FormattedMessage id={this.props.schema.title} />
          </div>
        ) : null}
        <Grid container alignItems={'center'}>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth={true}
              value={name}
              data-cy={`${String(this.props.schema?.title).toLowerCase()}-name`}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: this.props.classes.bootstrapInput,
                },
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: this.props.classes.label,
                },
              }}
            />
            <TextField hidden value={id} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(RequestFormPreviousPanel);

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let Details = props => (
  <SvgIcon {...props}>
    <path
      fill="#393939"
      d="M17.008,2.008c-8.271,0-15,6.729-15,15c0,8.271,6.729,15,15,15c8.271,0,15-6.729,15-15
    	C32.008,8.737,25.279,2.008,17.008,2.008z M17.008,30.008c-7.168,0-13-5.832-13-13s5.832-13,13-13s13,5.832,13,13
    	S24.176,30.008,17.008,30.008z"
    />
    <path
      fill="#393939"
      d="M18.935,17.581c2.056-0.724,3.529-2.683,3.529-4.986c0-2.921-2.373-5.295-5.294-5.295
    	s-5.295,2.374-5.295,5.295c0,2.303,1.474,4.262,3.53,4.986v3.84h-3.53v3.528h3.53v1.766h3.53V17.581z M15.405,12.595
    	c0-0.971,0.794-1.765,1.765-1.765s1.765,0.793,1.765,1.765c0,0.971-0.795,1.765-1.765,1.765S15.405,13.566,15.405,12.595z"
    />
  </SvgIcon>
);
Details = pure(Details);
Details.displayName = 'Details';
Details.muiName = 'SvgIcon';

export default Details;

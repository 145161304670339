import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import ColorButton from 'src/components/common/elements/ColorButton';
import { showToast } from 'src/store/ducks/toasts';
import ArrowRight from 'src/svg-icons/ArrowRight';
import ButtonCheck from 'src/svg-icons/ButtonCheck.svg';
import ButtonCheckActive from 'src/svg-icons/ButtonCheckActive.svg';
import ButtonX from 'src/svg-icons/ButtonX.svg';
import ButtonXActive from 'src/svg-icons/ButtonXActive.svg';
import EditIcon from 'src/svg-icons/edit';

import classes from './style.module.scss';

const ModalConfirmChanges = ({
  inspectionId,
  onClose,
  currentData,
  updatedData,
  originalUpdatedData,
  changeUpdatedData,
  makeOfficialHandler,
  loading,
}) => {
  const [acceptType, setAcceptType] = useState(true);
  const [acceptArea, setAcceptArea] = useState(true);
  const [acceptFurnished, setAcceptFurnished] = useState(true);
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    const data = JSON.parse(JSON.stringify(updatedData));

    if (!acceptType) {
      changeUpdatedData('building_type', currentData?.details?.building_type);
      data.details.building_type = currentData?.details?.building_type;
    } else {
      changeUpdatedData('building_type', originalUpdatedData?.details?.building_type);
      data.details.building_type = originalUpdatedData?.details?.building_type;
    }
    if (!acceptArea) {
      changeUpdatedData('area', currentData?.details?.area);
      data.details.area = currentData?.details?.area;
    } else {
      changeUpdatedData('area', originalUpdatedData?.details?.area);
      data.details.area = originalUpdatedData?.details?.area;
    }
    if (!acceptFurnished) {
      changeUpdatedData('furnished', currentData?.details?.furnished);
      data.details.furnished = currentData?.details?.furnished;
    } else {
      changeUpdatedData('furnished', originalUpdatedData?.details?.furnished);
      data.details.furnished = originalUpdatedData?.details?.furnished;
    }

    API.post('/financial/orderprice', data)
      .then(res => {
        if(res?.data?.value || (!res?.data?.value && !currentData?.details?.value)){
          changeUpdatedData('price', res?.data?.value);
        }
      })
      .catch(error => {
        console.error(error?.response);
        dispatch(
          showToast({
            type: 'error',
            title: intl.formatMessage({ id: 'ERROR' }),
            text: 'code:' + error?.response?.status,
            duration: 5000,
          })
        );
      });
  }, [acceptType, acceptArea, acceptFurnished]);

  const onConfirm = () => {
    if (!acceptType || !acceptArea || !acceptFurnished) {
      makeOfficialHandler(inspectionId, true, true);
    } else {
      makeOfficialHandler(inspectionId, true);
    }
  };

  return (
    <div className={classes.contentModal}>
      <div className={classes.headerModal}>
        <EditIcon />
        <span>Alterações no pedido</span>
      </div>
      <hr />

      <div className={classes.bodyModal}>
        <span>
          As informações do pedido listadas abaixo foram alteradas e podem resultar numa alteração de preço. Utilize os
          botões de <span className={classes.green}>Aprovar</span> ou <span className={classes.blue}>Ignorar</span> para
          manter as alterações no pedido.
        </span>

        <div className={classes.listConflicts}>
          {currentData?.details?.building_type !== originalUpdatedData?.details?.building_type && (
            <div className={classes.itensConflicts}>
              <span className={classes.grow1}>Tipo</span>
              <span className={classes.grow1}>{currentData?.details?.building_type}</span>
              <span className={classes.arrowRight}>
                <ArrowRight />
              </span>
              <span className={classes.grow1}>{updatedData?.details?.building_type}</span>
              <div className={classes.actionsConflicts}>
                <span className={classes.actionsButton} onClick={() => !loading && setAcceptType(true)}>
                  {acceptType ? <img src={ButtonCheckActive} alt="" /> : <img src={ButtonCheck} alt="" />}
                </span>
                <span className={classes.actionsButton} onClick={() => !loading && setAcceptType(false)}>
                  {acceptType ? <img src={ButtonX} alt="" /> : <img src={ButtonXActive} alt="" />}
                </span>
              </div>
            </div>
          )}
          {currentData?.details?.area !== originalUpdatedData?.details?.area && (
            <div className={classes.itensConflicts}>
              <span className={classes.grow1}>Área</span>
              <span className={classes.grow1}>{currentData?.details?.area}m²</span>
              <span className={classes.arrowRight}>
                <ArrowRight />
              </span>
              <span className={classes.grow1}>{updatedData?.details?.area}m²</span>
              <div className={classes.actionsConflicts}>
                <span className={classes.actionsButton} onClick={() => !loading && setAcceptArea(true)}>
                  {acceptArea ? <img src={ButtonCheckActive} alt="" /> : <img src={ButtonCheck} alt="" />}
                </span>
                <span className={classes.actionsButton} onClick={() => !loading && setAcceptArea(false)}>
                  {acceptArea ? <img src={ButtonX} alt="" /> : <img src={ButtonXActive} alt="" />}
                </span>
              </div>
            </div>
          )}
          {currentData?.details?.furnished !== originalUpdatedData?.details?.furnished && (
            <div className={classes.itensConflicts}>
              <span className={classes.grow1}>Mobília</span>
              <span className={classes.grow1}>{intl.formatMessage({ id: currentData?.details?.furnished })}</span>
              <span className={classes.arrowRight}>
                <ArrowRight />
              </span>
              <span className={classes.grow1}>{intl.formatMessage({ id: updatedData?.details?.furnished })}</span>
              <div className={classes.actionsConflicts}>
                <span className={classes.actionsButton} onClick={() => !loading && setAcceptFurnished(true)}>
                  {acceptFurnished ? <img src={ButtonCheckActive} alt="" /> : <img src={ButtonCheck} alt="" />}
                </span>
                <span className={classes.actionsButton} onClick={() => !loading && setAcceptFurnished(false)}>
                  {acceptFurnished ? <img src={ButtonX} alt="" /> : <img src={ButtonXActive} alt="" />}
                </span>
              </div>
            </div>
          )}
          <hr />
          <div className={classes.itensConflicts}>
            <span className={classes.grow1}>Preço</span>
            <span className={classes.grow1}>
              <p>Valor atual</p>

              {currentData?.details?.price ? <b>{currentData?.details?.price} créditos</b> : <b>A combinar</b>}
            </span>
            <span className={classes.arrowRight}>
              <ArrowRight />
            </span>
            <span className={classes.grow1}>
              <p>Valor atualizado</p>

              {updatedData?.details?.price ? <b>{updatedData?.details?.price} créditos</b> : <b>A combinar</b>}
            </span>
            <div className={classes.actionsConflicts}></div>
          </div>
        </div>
        <hr />
        <div></div>
      </div>

      <div className="text-center">
        <ColorButton color="default" onClick={() => onClose()} isBusy={loading}>
          <FormattedMessage id="BUTTON.CANCEL" />
        </ColorButton>
        <ColorButton color="green" onClick={() => onConfirm()} isBusy={loading}>
          <FormattedMessage id="BUTTON.CONFIRM" />
        </ColorButton>
      </div>
    </div>
  );
};

ModalConfirmChanges.propTypes = {
  onClose: PropTypes.func.isRequired,
  changeUpdatedData: PropTypes.func.isRequired,
  makeOfficialHandler: PropTypes.func.isRequired,
  currentData: PropTypes.object.isRequired,
  updatedData: PropTypes.object.isRequired,
  originalUpdatedData: PropTypes.object.isRequired,
  inspectionId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ModalConfirmChanges;

import React from 'react';
import { FormattedMessage } from 'react-intl';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import DrawerCollapseSubMenu from 'src/leftDrawer/DrawerCollapseSubMenu';
import DrawerSubMenuItem from 'src/leftDrawer/DrawerSubMenuItem';
import MenuIcon from 'src/leftDrawer/MenuIcon';

const styles = {
  icon: {
    color: '#ffffff',
    fontSize: 20,
  },
  select: {
    background: '#1E2941',
    borderLeft: '3px solid white',
  },
  list: {
    color: '#ffff',
    fontWeight: 'normal',
    fontSize: 12,
  },
};

const DrawerSubMenu = ({
  subMenu,
  handleSubMenuChange,
  handleCollapseSubMenuChange,
  subMenuToOpenByIndex,
  classes,
}) => (
  <div style={styles.list}>
    <List component="nav">
      {subMenu.map((subMenuOption, subMenuIndex) => {
        if (Array.isArray(subMenuOption.collapseSubMenu) && !!subMenuOption.collapseSubMenu.length) {
          const isOpenByDefault = Number.isInteger(subMenuToOpenByIndex)
            ? subMenuToOpenByIndex === subMenuIndex
            : subMenuIndex === 0;

          return (
            <DrawerCollapseSubMenu
              key={subMenuIndex}
              subMenuOption={{ ...subMenuOption, index: subMenuIndex }}
              collapseSubMenu={subMenuOption.collapseSubMenu}
              handleCollapseSubMenuChange={handleCollapseSubMenuChange}
              isOpenByDefault={isOpenByDefault}
            />
          );
        }

        return (
          <DrawerSubMenuItem key={subMenuIndex} content={subMenuOption.content}>
            <ListItem
              button
              disableRipple
              disabled={subMenuOption.disabled}
              onClick={e => handleSubMenuChange(subMenuIndex, e, subMenuOption.routeLink)}
              style={subMenuOption.active ? styles.select : null}
              data-cy={`${subMenuOption.key}-left-menu-option`}
            >
              <ListItemIcon classes={{ root: classes.icon }}>
                <MenuIcon menuKey={subMenuOption.key} />
              </ListItemIcon>
              <FormattedMessage id={'LeftDrawer.' + subMenuOption.key} />
            </ListItem>
          </DrawerSubMenuItem>
        );
      })}
    </List>
  </div>
);

DrawerSubMenu.propTypes = {
  /**
   * Array with the sub-menu options
   */
  subMenu: PropTypes.array.isRequired,
  /**
   * Function to change the selected sub-menu option
   */
  handleSubMenuChange: PropTypes.func.isRequired,
  /**
   * Style classes
   */
  classes: PropTypes.object,
  /**
   * Function to handle the url change of the collapse-menu (submenu inside submenu)
   */
  handleCollapseSubMenuChange: PropTypes.func.isRequired,
  /**
   * Default submenu index to open (if it is provided)
   */
  subMenuToOpenByIndex: PropTypes.number,
};

DrawerSubMenu.defaultProps = {
  classes: {},
  subMenuToOpenByIndex: null,
};

export default withStyles(styles)(DrawerSubMenu);

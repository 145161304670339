import React, { useEffect, useRef } from 'react';

import classes from 'src/components/MovideskChatButton/style.module.scss';
import { MOVIDESK_APP_ID } from 'src/config';
import chatIcon from 'src/images/icons/chat.svg';

import 'src/components/MovideskChatButton/resets.scss';

var mdChatClient = MOVIDESK_APP_ID;

window.mdChatClient = mdChatClient;

const MovideskChatButton = () => {
  const showMoviDesk = useRef(false);
  const buttonWrapperRef = useRef(null);

  const handleClickOutside = event => {
    if (window.movideskChatWidgetChangeWindowState) {
      const isMovideskElement = event?.target?.querySelector('[class^="md-"]');

      if (buttonWrapperRef?.current && !buttonWrapperRef?.current?.contains(event?.target) && !isMovideskElement) {
        showMoviDesk.current = false;
        window.movideskChatWidgetChangeWindowState('minimized');
      }
    }
  };

  const handleToggleChat = () => {
    if (window.movideskChatWidgetChangeWindowState) {
      if (showMoviDesk?.current) {
        showMoviDesk.current = false;
        window.movideskChatWidgetChangeWindowState('minimized');

        return;
      }

      showMoviDesk.current = true;
      window.movideskChatWidgetChangeWindowState('maximized');
    }
  };

  useEffect(() => {
    const movideskScript = document.createElement('script');

    movideskScript.type = 'text/javascript';
    movideskScript.src = 'https://chat.movidesk.com/Scripts/chat-widget.min.js';
    movideskScript.onload = () => {
      const me = JSON.parse(window.sessionStorage.getItem('me'));

      window.movideskLogin({
        name: me?.name,
        email: me?.profile?.email,
        codeReference: me?.profile?.username,
        organizationCodeReference: me?.franchiseeId,
        stayConnected: false,
        emptySubject: false,
        startChat: false,
      });

      window.movideskChatWidgetChangeWindowState('minimized');
    };

    document.body.appendChild(movideskScript);
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <div className={classes.movideskChatButton} onClick={handleToggleChat} ref={buttonWrapperRef} title="Movidesk">
      <img src={chatIcon} alt="Movidesk Chat" />
    </div>
  );
};

export default MovideskChatButton;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let SectionPrincipal = props => (
  <SvgIcon {...props}>
    <path
      d="M19.978,2.157H8.098c-1.634,0-2.956,1.336-2.956,2.97L5.127,28.889c0,1.633,1.322,2.97,2.956,2.97h17.835
	c1.633,0,2.971-1.337,2.971-2.97V11.067L19.978,2.157z M22.948,25.918H11.067v-2.97h11.881V25.918z M22.948,19.978H11.067v-2.97
	h11.881V19.978z M18.493,12.553V4.385l8.168,8.168H18.493z"
    />
  </SvgIcon>
);
SectionPrincipal = pure(SectionPrincipal);
SectionPrincipal.displayName = 'SectionPrincipal';
SectionPrincipal.muiName = 'SvgIcon';

export default SectionPrincipal;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let MenuDashboard = props => (
  <SvgIcon {...props} viewBox="0 0 50 49.998">
    <path
      fill="#5578A0"
      d="M25,0C11.193,0,0,11.193,0,25c0,13.809,11.193,25,25,25c13.807,0,25-11.191,25-25C50,11.193,38.807,0,25,0zM25,48C12.297,48,2,37.703,2,25S12.297,2,25,2c12.702,0,23,10.297,23,23S37.702,48,25,48z"
    />
    <path
      transform="translate(13, 13)"
      d="M3 13H11V3H3V13ZM3 21H11V15H3V21ZM13 21H21V11H13V21ZM13 3V9H21V3H13Z"
      fill="#FFFFFF"
    />
  </SvgIcon>
);
MenuDashboard = pure(MenuDashboard);
MenuDashboard.displayName = 'MenuDashboard';
MenuDashboard.muiName = 'SvgIcon';

export default MenuDashboard;

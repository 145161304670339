import { useEffect } from 'react';

import { useFormikContext } from 'formik';

const OrderFormContext = () => {
  const { setFieldValue, values } = useFormikContext();

  useEffect(() => {
    const copyDetailsButton = document.querySelector('button[name="copy-details-to-inspector"]');

    if (!copyDetailsButton) return;

    const handleCopyOrderDetailsToObservationFormField = () => {
      setFieldValue('schedule.observation', values?.order?.details);
    };

    copyDetailsButton.addEventListener('click', handleCopyOrderDetailsToObservationFormField);

    return () => {
      copyDetailsButton.removeEventListener('click', handleCopyOrderDetailsToObservationFormField);
    };
  }, [setFieldValue, values]);

  return null;
};

export default OrderFormContext;

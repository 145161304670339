import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Form from 'react-jsonschema-form';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import { dateTimePattern } from 'src/helpers/isValidDateTimeFormat';
import classes from 'src/orderForm/ClientOrderForm/ClientOrderForm.module.css';
import ArrayFieldTemplate from 'src/orderForm/fields/ArrayFieldTemplate';
import AttachmentFiles from 'src/orderForm/fields/AttachmentFiles';
import AutoCompleteOrCreate from 'src/orderForm/fields/AutoCompleteOrCreate';
import DocuSignFile from 'src/orderForm/fields/DocuSignFile';
import DocuSignSignersField from 'src/orderForm/fields/DocuSignSigners';
import EditRequestFormFieldDisable from 'src/orderForm/fields/EditRequestFormFieldDisable';
import KeysLocationField from 'src/orderForm/fields/KeysLocationField';
import RealEstateAdressesSelectField from 'src/orderForm/fields/RealEstateAdressesSelectField';
import RecipientsField from 'src/orderForm/fields/RecipientsField';
import CustomFieldDisable from 'src/orderForm/fields/RequestFormFieldDisable';
import RequestFormFieldSelect from 'src/orderForm/fields/RequestFormFieldSelect';
import RequestFormFieldUsrSelect from 'src/orderForm/fields/RequestFormFieldUsrSelect';
import People from 'src/orderForm/fields/RequestFormPeople';
import Person from 'src/orderForm/fields/RequestFormPerson';
import RequestFormPreviousFile from 'src/orderForm/fields/RequestFormPreviousFile';
import RequestFormPreviousPanel from 'src/orderForm/fields/RequestFormPreviousPanel';
import SuitorsArrayFieldTemplate from 'src/orderForm/fields/SuitorsArrayFieldTemplate';
import TagsFieldTemplate from 'src/orderForm/fields/TagsFieldTemplate';
import * as OrderFormTitleIcons from 'src/orderForm/OrderForm/OrderFormTitleIcons';
import containerClasses from 'src/orderForm/style.module.scss';
import CustomWidgetString from 'src/orderForm/widgets/RequestFormWidgetString';

class ClientOrderForm extends Component {
  customTitleFieldIcons = {
    parties_involved: <OrderFormTitleIcons.InvolvedParties viewBox="0 0 34.016 34.016" />,
    parties: <OrderFormTitleIcons.InvolvedParties viewBox="0 0 34.016 34.016" />,
    keys: <OrderFormTitleIcons.KeysIcon viewBox="0 0 34.016 34.016" />,
    mainAddress: <OrderFormTitleIcons.Address viewBox="0 0 34.016 34.016" />,
    details: <OrderFormTitleIcons.Details viewBox="0 0 34.016 34.016" />,
    photoBuildingDetails: <OrderFormTitleIcons.Details viewBox="0 0 34.016 34.016" />,
    client: <OrderFormTitleIcons.Client viewBox="0 0 34.016 34.016" />,
    clients: <OrderFormTitleIcons.Client viewBox="0 0 34.016 34.016" />,
    franchisees: <OrderFormTitleIcons.Franchisee viewBox="0 0 34.016 34.016" />,
    previous: <OrderFormTitleIcons.Previous viewBox="0 0 34.016 34.016" />,
    additionals: <OrderFormTitleIcons.Previous viewBox="0 0 34.016 34.016" />,
    recommendation: <OrderFormTitleIcons.Recommendation viewBox="0 0 34.016 34.016" />,
    tags: <OrderFormTitleIcons.Tag />,
    accessInformation: <OrderFormTitleIcons.AccessInformation />,
    accompaniedInspection: <OrderFormTitleIcons.AccompaniedInspection />,
    propertyInformations: <OrderFormTitleIcons.PropertyInformations />,
    propertyAccess: <OrderFormTitleIcons.PropertyAccess />,
    interestedPerson: <OrderFormTitleIcons.InterestedPerson />,
    potentialSchedule: <OrderFormTitleIcons.PotentialSchedule />,
    reportSubmission: <OrderFormTitleIcons.ReportSubmission />,
    vacationNotice: <OrderFormTitleIcons.ReportSubmission />,
    desired_property: <OrderFormTitleIcons.Property />,
    main_suitor: <OrderFormTitleIcons.Client />,
    main_suitor_address: <OrderFormTitleIcons.Property />,
    suitors: <OrderFormTitleIcons.ClientPlus />,
    budgetDuringInspection: <OrderFormTitleIcons.BudgetDuringInspection />,
  };

  objectFieldTemplateClasses = {
    simple: 'col-md-4',
    foursided: 'col-md-4',
    others: 'col-md-4',
    companionName: 'col-md-4',
    companionContact: 'col-md-4',
    scheduleDate: 'col-md-4',
    street: 'col-md-6',
    neighborhood: 'col-md-6',
    area: 'col-md-6',
    building_id: 'col-md-6',
    furnished: 'col-md-6',
    link: 'col-md-6',
    highlights: 'col-md-6',
    fullName: 'col-md-6',
    whatsapp: 'col-md-6',
    date1: 'col-md-6',
    time1: 'col-md-6',
    date2: 'col-md-6',
    time2: 'col-md-6',
    roomsCount: 'col-md-6',
    suitesCount: 'col-md-6',
    contact: 'col-md-6',
    name: 'col-md-6',
    complement: 'col-md-3',
    number: 'col-md-3',
    state: 'col-md-3',
    city: 'col-md-3',
    building_type: 'col-md-3',
    purpose: 'col-md-3',
    property_code: 'col-md-4',
    real_estate_type: 'col-md-4',
    rent_value: 'col-md-4',
    iptu_value: 'col-md-6',
    condominium_value: 'col-md-6',
    income_origin: 'col-md-6',
    income_value: 'col-md-6',
    suitor_type: 'col-md-3',
    suitor_name: 'col-md-6',
    suitor_natural_document: 'col-md-3',
    suitor_legal_document: 'col-md-3',
    reside_or_participant: 'col-md-12',
    income: 'container-fluid p-0',
    main: 'container-fluid p-0',
  };

  customFormats = { 'date-with-time': dateTimePattern };

  constructor(props) {
    super(props);

    const { schema, uiSchema, handleChange } = props;

    if (schema && schema.title === 'tags') {
      uiSchema.tags['ui:ArrayFieldTemplate'] = () => TagsFieldTemplate({ handleChange, schema });
    }

    this.fields = {
      person: Person,
      people: People,
      TitleField: this.customTitleField,
      customFieldSelect: RequestFormFieldSelect,
      customFieldUsrSelect: RequestFormFieldUsrSelect,
      customDisableField: CustomFieldDisable,
      customPreviousFile: RequestFormPreviousFile,
      customPreviousPanel: RequestFormPreviousPanel,
      customEditDisableField: EditRequestFormFieldDisable,
      autoCompleteOrCreate: AutoCompleteOrCreate,
      autoCompleteOrCreateAddress: CustomWidgetString,
      docusignFile: DocuSignFile,
      docuSignSignersField: DocuSignSignersField,
      attachmentFiles: AttachmentFiles,
      keysLocationField: KeysLocationField,
      realEstateAdressesSelectField: RealEstateAdressesSelectField,
      recipientsField: RecipientsField,
    };
  }

  /**
   * @description Transform/translate error message by error name
   */
  transformErrorMessage = error => {
    const { intl } = this.props;

    try {
      const messages = {
        maxLength: () => intl.formatMessage({ id: 'MUST_BE_LONGER_THAN_100_CHARS_ERROR_MESSAGE' }),
        required: () => intl.formatMessage({ id: 'requiredField' }),
        minLength: () => intl.formatMessage({ id: 'minLength' }),
        format: errorParams => {
          if (errorParams.format === 'date-with-time') return intl.formatMessage({ id: 'invalidDateTimeFormat' });

          return intl.formatMessage({ id: 'invalidFormat' });
        },
        type: errorParams => {
          if (errorParams.type === 'array') return intl.formatMessage({ id: 'shouldBeAnArray' });

          return intl.formatMessage({ id: 'invalidFormat' });
        },
      };

      if (error.name in messages) error.message = messages[error.name](error.params);

      return error;
    } catch {
      return error;
    }
  };

  /**
   * @description Function to transform the error of the form
   */
  transformErrors = errors =>
    errors.filter(error => error.name !== 'enum' && error.name !== 'oneOf').map(this.transformErrorMessage);

  /**
   * @description Function called when the form is submitted and have errors
   */
  onError = errors => {
    const { handleError } = this.props;

    if (errors.length > 0) {
      handleError(errors.length);
    }
  };

  customTitleField = ({ title, required }) => {
    if (
      [
        'schedule',
        'acAddress',
        'paAddress',
        'address',
        'keysDevolutionAddress',
        'income',
        'main',
        'desiredPropertyAddress',
      ].includes(title)
    ) {
      return null;
    }

    try {
      const titleIcon = this.customTitleFieldIcons?.[title] || null;
      let legend = required ? `${(<FormattedMessage id={title} />)}*` : <FormattedMessage id={title} />;

      if (title === 'details') {
        legend = required ? (
          `${(<FormattedMessage id={`${title}_title`} />)}*`
        ) : (
          <FormattedMessage id={`${title}_title`} />
        );
      }

      return (
        <div id="custom">
          <Grid container alignContent="center" spacing={8} style={{ marginBottom: '10px' }}>
            <Grid item>{titleIcon}</Grid>

            <Grid item className={classes.title}>
              {legend}
            </Grid>
          </Grid>
        </div>
      );
    } catch {
      return null;
    }
  };

  objectFieldTemplate = ({ properties, title, TitleField }) => (
    <div>
      {title && <TitleField title={title} />}

      <Grid container className={classes.content}>
        {properties.map(prop => {
          const uiSchema = prop.content?.props?.uiSchema;

          if (uiSchema?.['ui:widget'] === 'hidden') return null;

          let className = containerClasses.schemaObjectContainer;

          if (uiSchema?.['ui:options']?.indented) {
            className = containerClasses.schemaObjectContainer;
          } else {
            className = this.objectFieldTemplateClasses?.[prop.name] || 'col-md-12';
          }

          return (
            <div className={className} key={prop.content?.key}>
              {prop.content}
            </div>
          );
        })}
      </Grid>
    </div>
  );

  customFieldTemplate = ({ classNames, help, description, errors, children, label }) => {
    if (
      ['acAddress', 'paAddress', 'keysDevolutionAddress', 'income', 'main', 'desiredPropertyAddress'].includes(label)
    ) {
      return <div className={containerClasses.removeNested}>{children}</div>;
    }

    return (
      <div className={classNames}>
        {description}

        {children}

        <div className={classes.error}>{errors}</div>

        {help}
      </div>
    );
  };

  render() {
    const { formData, handleChange, newOrderType, onSubmit, schema, submitted, uiSchema, widgets } = this.props;

    return (
      <Form
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        onSubmit={onSubmit}
        onChange={handleChange}
        ArrayFieldTemplate={newOrderType === 'marketplace_fichacerta' ? SuitorsArrayFieldTemplate : ArrayFieldTemplate}
        ObjectFieldTemplate={this.objectFieldTemplate}
        fields={this.fields}
        widgets={widgets}
        liveValidate={submitted}
        showErrorList={false}
        transformErrors={this.transformErrors}
        onError={this.onError}
        FieldTemplate={this.customFieldTemplate}
        customFormats={this.customFormats}
      >
        <button
          type="submit"
          data-cy="client-order-form-submit"
          ref={btn => {
            this.submitButton = btn;
          }}
          hidden
        />
      </Form>
    );
  }
}

ClientOrderForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  handleChange: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  schema: PropTypes.object,
  submitted: PropTypes.bool.isRequired,
  uiSchema: PropTypes.object.isRequired,
  widgets: PropTypes.object.isRequired,
  newOrderType: PropTypes.string,
};

ClientOrderForm.defaultProps = {
  formData: {},
  newOrderType: undefined,
  schema: undefined,
};

export default ClientOrderForm;

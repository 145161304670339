import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import classes from 'src/components/ClientFiltersBar/style.module.scss';
import { setQuery } from 'src/store/ducks/filter';
import { clearOrders, fetchOrders, setOrderStatusToSearch, setOrderByDir } from 'src/store/ducks/orders';

let source = null;
const styles = {
  select: {
    minWidth: '175px',
    fontSize: '12px',
    marginLeft: '0.5vw',
    maxHeight: '2vw',
    height: '100%',
    minHeight: '32px',
  },
};

const options = [
  { value: 'desc', label: 'Mais recente primeiro' },
  { value: 'asc', label: 'Mais antigo primeiro' },
];

const ClientFiltersBar = ({ clearTagsTypesAndFreeTermSearch, changeOrderByToSearch }) => {
  const [defaultOrderByDir, setDefaultOrderByDir] = useState(useSelector(state => state.orders.orderByDir));
  const [activeStatus, setActiveStatus] = useState('');
  const intl = useIntl();
  const dispatch = useDispatch();
  const orderStatusToSearch = useSelector(state => state.orders.orderStatusToSearch);

  const handleFetchOrdersByStatus = (status = 'in_progress', orderByDir = 'asc') => {
    if (source !== null) source.cancel();

    setDefaultOrderByDir(orderByDir);
    setActiveStatus(status);

    source = API.CancelToken.source();

    clearTagsTypesAndFreeTermSearch();

    dispatch(clearOrders());
    dispatch(setQuery({}));
    dispatch(setOrderByDir(orderByDir));
    dispatch(setOrderStatusToSearch(status));
    dispatch(fetchOrders({ status, orderByDir, append: false, cancelToken: source?.token }));
  };

  const changeOrderByDir = e => {
    const orderDir = e.value;
    if (orderStatusToSearch === 'search') {
      dispatch(setOrderByDir(orderDir));
      setDefaultOrderByDir(orderDir);
      changeOrderByToSearch();
    } else {
      handleFetchOrdersByStatus(activeStatus, orderDir);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setOrderStatusToSearch('all'));

      if (source) source.cancel();
    };
  }, [dispatch]);

  return (
    <div className={classes.content}>
      <nav className={classes.clientsFiltersBar}>
        <button
          type="button"
          className={orderStatusToSearch === 'all' && classes.activeFilter}
          onClick={() => handleFetchOrdersByStatus('all', 'desc')}
          data-cy="all-orders-tab"
        >
          {intl.formatMessage({ id: 'ALL_INSPECTIONS' })}
        </button>
        <button
          type="button"
          className={orderStatusToSearch === 'waiting' && classes.activeFilter}
          onClick={() => handleFetchOrdersByStatus('waiting')}
          data-cy="waiting-orders-tab"
        >
          {intl.formatMessage({ id: 'WAITING_INSPECTIONS' })}
        </button>
        <button
          type="button"
          className={orderStatusToSearch === 'accepted' && classes.activeFilter}
          onClick={() => handleFetchOrdersByStatus('accepted')}
          data-cy="accepted-orders-tab"
        >
          {intl.formatMessage({ id: 'ACCEPTED_INSPECTIONS' })}
        </button>
        <button
          type="button"
          className={orderStatusToSearch === 'assigned' && classes.activeFilter}
          onClick={() => handleFetchOrdersByStatus('assigned')}
          data-cy="assigned-orders-tab"
        >
          {intl.formatMessage({ id: 'ASSIGNED_INSPECTIONS' })}
        </button>
        <button
          type="button"
          className={orderStatusToSearch === 'in_progress' && classes.activeFilter}
          onClick={() => handleFetchOrdersByStatus('in_progress')}
          data-cy="in-progress-orders-tab"
        >
          {intl.formatMessage({ id: 'IN_PROGRESS_INSPECTIONS' })}
        </button>
        <button
          type="button"
          className={orderStatusToSearch === 'finalized' && classes.activeFilter}
          onClick={() => handleFetchOrdersByStatus('finalized')}
          data-cy="finalized-orders-tab"
        >
          {intl.formatMessage({ id: 'FINISHED_INSPECTIONS' })}
        </button>
        <button
          type="button"
          className={orderStatusToSearch === 'sent' && classes.activeFilter}
          onClick={() => handleFetchOrdersByStatus('sent', 'desc')}
          data-cy="sent-orders-tab"
        >
          {intl.formatMessage({ id: 'SENT_INSPECTIONS' })}
        </button>
        <button
          type="button"
          className={orderStatusToSearch === 'rejected' && classes.activeFilter}
          onClick={() => handleFetchOrdersByStatus('rejected', 'desc')}
          data-cy="rejected-orders-tab"
        >
          {intl.formatMessage({ id: 'REJECTED_INSPECTIONS' })}
        </button>
        <button
          type="button"
          className={orderStatusToSearch === 'canceled' && classes.activeFilter}
          onClick={() => handleFetchOrdersByStatus('canceled', 'desc')}
          data-cy="canceled-orders-tab"
        >
          {intl.formatMessage({ id: 'CANCELED_INSPECTIONS' })}
        </button>
      </nav>
      <Select
        value={options?.find(option => option.value === defaultOrderByDir || '')}
        options={options}
        onChange={e => changeOrderByDir(e)}
        styles={{
          control: base => ({
            ...base,
            ...styles.select,
          }),
          dropdownIndicator: base => ({
            ...base,
            ...{ padding: '6px' },
          }),
          option: base => ({
            ...base,
            ...{ fontSize: '12px' },
          }),
        }}
      />
    </div>
  );
};

ClientFiltersBar.propTypes = {
  clearTagsTypesAndFreeTermSearch: PropTypes.func.isRequired,
  changeOrderByToSearch: PropTypes.func.isRequired,
};

export default ClientFiltersBar;

import React from 'react';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import Card from 'src/components/common/Card';
import GrayButton from 'src/components/common/elements/GrayButton';
import detailsImage from 'src/components/common/icons/client-details.svg';
import fileExport from 'src/components/common/icons/file-export.svg';
import classes from 'src/components/InspectorsPage/InspectorReport/style.module.scss';

const InspectorReportComponent = ({
  id,
  status,
  isBusy,
  onClick,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  intl,
}) => (
  <div className={classes.exportGeneralStatistics}>
    <Card className="fade-in">
      <h2 className={classes.cardHead}>
        <div className={classes.back}>
          <Link to={`/inspectors/${status}/${id}`}>
            <div className={classes.arrowLeft} />
          </Link>
        </div>
        <div className={classes.icon}>
          <img src={detailsImage} alt={intl.formatMessage({ id: 'DETAILS' })} />
        </div>
        {intl.formatMessage({ id: 'REPORT_EXPORT' })}
      </h2>
      <p>{intl.formatMessage({ id: 'INSPECTOR_INDIVIDUAL_SPREADSHEET_GENERATE_MESSAGE' })}</p>
      <form>
        <div className={classes.statisticsDates}>
          <div className={classes.startDate}>
            <label htmlFor="startDate">{intl.formatMessage({ id: 'FROM_DAY' })}</label>
            <DatePicker
              id="startDate"
              name="startDate"
              placeholderText={intl.formatMessage({ id: 'INITIAL_DATE' })}
              autoComplete="off"
              locale="pt-BR"
              dateFormat="dd 'de' MMMM 'de' yyy"
              onChange={onStartDateChange}
              selectsStart
              startDate={startDate}
              maxDate={new Date()}
              endDate={endDate}
              selected={startDate}
              popperPlacement="top-end"
            />
          </div>
          <div className={classes.endDate}>
            <label htmlFor="endDate">{intl.formatMessage({ id: 'TO_DAY' })}</label>
            <DatePicker
              id="endDate"
              name="endDate"
              placeholderText={intl.formatMessage({ id: 'FINAL_DATE' })}
              autoComplete="off"
              locale="pt-BR"
              dateFormat="dd 'de' MMMM 'de' yyy"
              onChange={onEndDateChange}
              selectsEnd
              endDate={endDate}
              maxDate={new Date()}
              minDate={startDate}
              selected={endDate}
              popperPlacement="top-end"
            />
          </div>
        </div>
        <GrayButton style={{ width: '180px' }} onClick={onClick} isBusy={isBusy}>
          <img src={fileExport} alt={intl.formatMessage({ id: 'METRICS' })} />
          {intl.formatMessage({ id: 'REPORT_EXPORT' })}
        </GrayButton>
      </form>
    </Card>
  </div>
);

InspectorReportComponent.propTypes = {
  id: PropTypes.string,
  status: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isBusy: PropTypes.bool.isRequired,
  startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
  endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
  onStartDateChange: PropTypes.func.isRequired,
  onEndDateChange: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

InspectorReportComponent.defaultProps = {
  id: null,
  startDate: null,
  endDate: null,
};

export default InspectorReportComponent;

import React from 'react';
import Select from 'react-select';
import MaskedInput from 'react-text-mask';

import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';

import InspectorOption from 'src/components/SchedulePage/OrderModal/OrderForm/InspectorOption/InspectorOption';
import OrderFormContext from 'src/components/SchedulePage/OrderModal/OrderForm/OrderFormContext';
import classes from 'src/components/SchedulePage/OrderModal/OrderForm/style.module.scss';
import { TIME_MASK } from 'src/helpers/mask';
import time from 'src/images/icons/time.svg';

const OrderFormComponent = ({
  initialValues,
  inspectors,
  date,
  closeModal,
  onSubmit,
  warningInspectorMessage,
  handleInspectorSelectChange,
  handlePreSelectInspector,
  handleValidateAccompaniedInspection,
  scheduleDateAlert,
  scheduleDate,
  validationSchema,
  intl,
  handleValidateInspectorAvailability,
  warningAvailabilityMessage,
}) => (
  <div className={classes.orderForm}>
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validate={handleValidateInspectorAvailability}
    >
      {({ errors, touched, values }) => (
        <Form>
          <div className={classes.field}>
            <label htmlFor="inspector">{intl.formatMessage({ id: 'INSPECTOR' })}</label>

            <Field name="schedule.who.inspector_id">
              {({ field, form }) => (
                <Select
                  {...field}
                  name={field.name}
                  id="inspector"
                  className={classes.select}
                  classNamePrefix="select"
                  defaultValue={handlePreSelectInspector(field)}
                  placeholder={intl.formatMessage({
                    id: 'SELECT_AN_INSPECTOR',
                  })}
                  value={inspectors?.find(option => option.value === field.value || '')}
                  onChange={(selectedOption, triggeredAction) =>
                    handleInspectorSelectChange(selectedOption, triggeredAction, form, field)
                  }
                  onBlur={field.onBlur}
                  noOptionsMessage={() => intl.formatMessage({ id: 'NO_INSPECTOR_AVAILABLE_MESSAGE' })}
                  options={inspectors}
                  isOptionDisabled={option => option.disabled}
                  isClearable
                  required
                  components={{ Option: InspectorOption }}
                />
              )}
            </Field>

            {errors?.schedule?.who?.inspector_id && (
              <div className={classes.error}>{errors?.schedule?.who?.inspector_id}</div>
            )}

            {warningInspectorMessage && <div className={classes.warning}>{warningInspectorMessage}</div>}
          </div>

          <div className={classes.field}>
            <label htmlFor="date">{intl.formatMessage({ id: 'DATE' })}</label>

            <Field component="input" type="text" name="schedule.when.date" id="date" value={date} disabled />
          </div>

          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="start-time">{intl.formatMessage({ id: 'START_TIME' })}</label>

              <Field
                name="schedule.when.start_time"
                onChange={handleValidateAccompaniedInspection(values?.schedule?.when)}
              >
                {({ field }) => (
                  <MaskedInput {...field} type="text" id="start-time" mask={TIME_MASK} placeholder="hh:mm" />
                )}
              </Field>

              {errors?.schedule?.when?.start_time && touched?.schedule?.when?.start_time && (
                <div className={classes.error}>{errors?.schedule?.when?.start_time}</div>
              )}

              {warningAvailabilityMessage && (
                <div className={classes.unavailable}>
                  <p>{warningAvailabilityMessage}</p>
                </div>
              )}
            </div>

            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="end-time">{intl.formatMessage({ id: 'END_TIME' })}</label>

              <Field name="schedule.when.end_time">
                {({ field }) => (
                  <MaskedInput
                    {...field}
                    type="text"
                    id="end-time"
                    mask={TIME_MASK}
                    placeholder="hh:mm"
                    disabled={values?.schedule?.flextime === true}
                  />
                )}
              </Field>

              {errors?.schedule?.when?.end_time && touched?.schedule?.when?.end_time && (
                <div className={classes.error}>{errors?.schedule?.when?.end_time}</div>
              )}
            </div>
          </div>

          <div className={classes.alert}>
            <div className={`${classes.field} ${classes.hasCheckbox}`} style={{ marginTop: '.5em' }}>
              <Field type="checkbox" name="schedule.flextime" id="flextime" />

              <label htmlFor="flextime">{intl.formatMessage({ id: 'FLEXTIME' })}</label>
            </div>

            {scheduleDateAlert && (
              <div>
                <span className={classes.status}>
                  <img className={classes.iconDelayed} src={time} alt="time" />
                  {intl.formatMessage({ id: 'scheduleDateAlert' })} - {scheduleDate}
                </span>
              </div>
            )}
          </div>

          <div className={classes.field}>
            <label htmlFor="observation">{intl.formatMessage({ id: 'NOTES_TO_THE_INSPECTOR' })}</label>

            <Field component="textarea" name="schedule.observation" id="observation" />
          </div>

          <div className={`${classes.field} ${classes.actions}`}>
            <button type="button" className={classes.button} onClick={closeModal}>
              {intl.formatMessage({ id: 'CANCEL' })}
            </button>

            <button type="submit" className={`${classes.button} ${classes.isGreen}`}>
              {intl.formatMessage({ id: 'TO_SCHEDULE' })}
            </button>
          </div>

          <OrderFormContext />
        </Form>
      )}
    </Formik>
  </div>
);

OrderFormComponent.propTypes = {
  initialValues: PropTypes.object.isRequired,
  inspectors: PropTypes.array,
  date: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
  warningInspectorMessage: PropTypes.string.isRequired,
  handleInspectorSelectChange: PropTypes.func.isRequired,
  handlePreSelectInspector: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  handleValidateInspectorAvailability: PropTypes.func.isRequired,
  warningAvailabilityMessage: PropTypes.string.isRequired,
  handleValidateAccompaniedInspection: PropTypes.func.isRequired,
  scheduleDateAlert: PropTypes.bool,
  scheduleDate: PropTypes.string,
};

OrderFormComponent.defaultProps = {
  inspectors: [],
};

export default OrderFormComponent;

/**
 * @prettier
 **/
import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let DownloadIcon = props => (
  <SvgIcon {...props}>
    <polygon
      fill="#626262"
      points="9.1,13.2 11,14.9 12.9,13.2 12.9,13.2 16,10.4 14.1,8.7 12.2,10.4 12.2,4 9.8,4 9.8,10.4 7.9,8.7 
		6,10.4 9.1,13.2 	"
    />
    <rect x="3" y="16" fill="#626262" width="16" height="2" />
  </SvgIcon>
);

DownloadIcon = pure(DownloadIcon);
DownloadIcon.displaName = 'DownloadIcon';
DownloadIcon.muiName = 'SvgIcon';

export default DownloadIcon;

import React from 'react';
import { useIntl } from 'react-intl';

import MarketplacePageComponent from 'src/components/MarketplacePage/MarketplacePageComponent';

const MarketplacePageContainer = () => {
  const intl = useIntl();

  return <MarketplacePageComponent intl={intl} />;
};

export default MarketplacePageContainer;

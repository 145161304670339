import React from 'react';
import { useIntl } from 'react-intl';

import PropTypes from 'prop-types';

import classes from 'src/components/SchedulePage/OrderModal/style.module.scss';

const OrderKeysLocationComponent = ({ keysLocationInformation, handleOpenAddress }) => {
  const intl = useIntl();

  const address = keysLocationInformation?.address;
  const observation = keysLocationInformation?.keysLocation;
  const type = keysLocationInformation?.type;

  if (type === 'Outro' || type === 'Na imobiliária') {
    const fullAddress = `${address?.street}, ${address?.number}, ${address?.neighborhood}${
      address?.complement ? ` - ${address.complement}` : ''
    }, ${address?.city}/${address?.state}`;

    return (
      <>
        <button
          className={classes.orderLinkButton}
          type="button"
          onClick={() => handleOpenAddress(address)}
          title={fullAddress}
        >
          {fullAddress}
        </button>

        {observation && (
          <>
            {' '}
            - {intl.formatMessage({ id: 'keysLocation' })}: {observation}
          </>
        )}
      </>
    );
  }

  if (type === 'No local') {
    return intl.formatMessage({ id: type });
  }

  return observation;
};

OrderKeysLocationComponent.propTypes = {
  keysLocationInformation: PropTypes.object.isRequired,
  handleOpenAddress: PropTypes.func.isRequired,
};

export default OrderKeysLocationComponent;

import React from 'react';
import { useIntl } from 'react-intl';

import ClientLayoutComponent from 'src/components/ClientLayout/ClientLayoutComponent';
import { useUser } from 'src/contexts/UserContext';

const ClientLayoutContainer = ({ ...props }) => {
  const user = useUser();
  const intl = useIntl();

  return <ClientLayoutComponent user={user} intl={intl} {...props} />;
};

export default ClientLayoutContainer;

import React from 'react';
import { connect } from 'react-redux';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';

import PropTypes from 'prop-types';

import classes from 'src/components/common/SearchBox/InspectorsField/style.module.scss';

const animatedComponents = makeAnimated();

const InspectorsField = ({ allInspectors, autoFocus, handleChange, intl, ...props }) => (
  <Select
    className={classes.select}
    classNamePrefix="select"
    options={allInspectors}
    isMulti
    autoFocus={autoFocus}
    placeholder={`${intl.formatMessage({ id: 'SELECT' })}...`}
    noOptionsMessage={() => intl.formatMessage({ id: 'NO_INSPECTOR_FOUND_MESSAGE' })}
    formatCreateLabel={value => `${intl.formatMessage({ id: 'ADD' })} "${value}"`}
    onChange={handleChange}
    components={{
      ...animatedComponents,
      Input: inputProps => <components.Input {...inputProps} data-cy={`${String(props.name).toLowerCase()}`} />,
    }}
    {...props}
  />
);

InspectorsField.propTypes = {
  allInspectors: PropTypes.array.isRequired,
  autoFocus: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

InspectorsField.defaultProps = {
  autoFocus: false,
};

const mapStateToProps = state => ({
  allInspectors: state.inspectors,
});

export default connect(mapStateToProps)(InspectorsField);

import { combineReducers } from 'redux';

import cards from 'src/store/ducks/cards';
import clientOrderForm from 'src/store/ducks/clientOrderForm';
import divergences from 'src/store/ducks/divergences';
import filter from 'src/store/ducks/filter';
import inspectionsReducer from 'src/store/ducks/inspections/reducer';
import inspectors from 'src/store/ducks/inspectors';
import ordersReducer from 'src/store/ducks/orders/reducer';
import schedule from 'src/store/ducks/schedule';
import tags from 'src/store/ducks/tags';
import toasts from 'src/store/ducks/toasts';

const rootReducer = combineReducers({
  cards,
  clientOrderForm,
  filter,
  tags,
  inspectors,
  schedule,
  toasts,
  orders: ordersReducer,
  inspections: inspectionsReducer,
  divergences,
});

export default rootReducer;

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/CancelSection/style.module.scss';
import AccessInformation from 'src/svg-icons/AccessInformation';

const CancelSectionComponent = ({ order }) => (
  <Grid item xs={12}>
    <div className={classes.header}>
      <AccessInformation className={classes.icon} />
      <FormattedMessage id="cancelInformation" />
    </div>
    <Grid container spacing={8} className={classes.information}>
      <Grid item xs={6}>
        <div className={classes.subHeader}>Motivo do cancelamento</div>
        <span data-cy="order-details-cancel-reason">{order.messages.cancel.reason}</span>
      </Grid>
      <Grid item xs={6}>
        <div className={classes.subHeader}>Observações</div>
        <span data-cy="order-details-cancel-observation">{order.messages.cancel.observation || '-----'}</span>
      </Grid>
    </Grid>
    <Grid container spacing={8} className={classes.information}>
      <Grid item xs={6}>
        <div className={classes.subHeader}>Taxa de deslocamento cobrada?</div>
        <span data-cy="order-details-cancel-should-be-charged">
          {order.messages.cancel.should_be_charged ? 'Sim' : 'Não'}
        </span>
      </Grid>
      <Grid item xs={6}>
        <div className={classes.subHeader}>Foto enviada ao cliente?</div>
        <span data-cy="order-details-cancel-send-photo-to-client">
          {order.messages.cancel.send_photo_to_client ? 'Sim' : 'Não'}
        </span>
      </Grid>
    </Grid>
    {order.messages.cancel.photo && (
      <Grid container spacing={8} className={classes.information}>
        <Grid item xs={12}>
          <div className={classes.subHeader}>Foto enviada pelo vistoriador</div>
          <img className={classes.photo} src={order.messages.cancel.photo} alt="Foto enviada pelo vistoriador" />
        </Grid>
      </Grid>
    )}
  </Grid>
);

CancelSectionComponent.propTypes = {
  order: PropTypes.object.isRequired,
};

export default CancelSectionComponent;

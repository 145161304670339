/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { format, getDay } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';
import PropTypes from 'prop-types';

import classes from 'src/components/InspectorsPage/InspectorFiltersDetails/AvailabilityCalendar/style.module.scss';
import 'src/components/InspectorsPage/InspectorFiltersDetails/AvailabilityCalendar/style.css';

const localizer = momentLocalizer(moment);

const calendarDateFormatMapper = {
  0: date => format(date, 'iiiiii', { locale: ptBR }),
  1: date => format(date, 'EEEEEE', { locale: ptBR }),
  2: date => format(date, 'EEEEEE', { locale: ptBR }),
  3: date => format(date, 'EEEEEE', { locale: ptBR }),
  4: date => format(date, 'EEEEEE', { locale: ptBR }),
  5: date => format(date, 'EEEEEE', { locale: ptBR }),
  6: date => format(date, 'iiiiii', { locale: ptBR }),
};

const AvailabilityCalendarComponent = ({ handleSelectCalendarDateRange, handleDeleteCalendarDateRange, schedules }) => (
  <Calendar
    selectable
    toolbar={false}
    step={30}
    timeslots={1}
    localizer={localizer}
    date={new Date('1970-01-01')}
    events={[
      ...schedules.sunday,
      ...schedules.monday,
      ...schedules.tuesday,
      ...schedules.wednesday,
      ...schedules.thursday,
      ...schedules.friday,
      ...schedules.saturday,
    ]}
    showMultiDayTimes={false}
    defaultView={Views.WEEK}
    onSelectEvent={handleDeleteCalendarDateRange}
    onSelectSlot={handleSelectCalendarDateRange}
    components={{
      header: ({ date }) => <p className={classes.scheduleHeader}>{calendarDateFormatMapper[getDay(date)](date)}</p>,
      eventWrapper: ({ children }) => <div className={classes.scheduleNode}>{children}</div>,
    }}
  />
);

AvailabilityCalendarComponent.propTypes = {
  handleSelectCalendarDateRange: PropTypes.func.isRequired,
  handleDeleteCalendarDateRange: PropTypes.func.isRequired,
  schedules: PropTypes.array.isRequired,
};

export default AvailabilityCalendarComponent;

import React from 'react';
import { Portal } from 'react-portal';

import PropTypes from 'prop-types';

import classes from 'src/components/common/Modal/style.module.scss';

const ModalComponent = ({ extraStyles, handleClick, children }) => (
  <Portal>
    <div className={classes.modal}>
      <div className={classes.overlay} onClick={handleClick} />
      <div className={classes.content} style={extraStyles}>
        {children}
      </div>
    </div>
  </Portal>
);

ModalComponent.propTypes = {
  handleClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  extraStyles: PropTypes.object,
};

ModalComponent.defaultProps = {
  extraStyles: null,
};

export default ModalComponent;

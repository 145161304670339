import React, { useEffect } from 'react';

import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import AddMediaComponent from './AddMediaComponent';

const initialValues = {
  external_uri: '',
  external_id: '',
};

const validationSchema = yup.object().shape({
  external_uri: yup.string().url().required(),
  external_id: yup.string().required(),
});

const FormikContext = ({ onChange }) => {
  const { values, errors } = useFormikContext();

  useEffect(() => {
    onChange([{
      type: 'TOUR_VIRTUAL',
      ...values,
      isValid: Object.keys(errors).length === 0,
    }]);
  }, [values, errors, onChange]);

  return null;
};

const AddMediaContainer = ({ onChange }) => {
  return (
    <AddMediaComponent
      initialValues={initialValues}
      FormikContext={FormikContext}
      validationSchema={validationSchema}
      onChange={onChange}
    />
  );
};

AddMediaContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default AddMediaContainer;

// Actions
export const ACTIONS = {
  SET_QUERY: 'filter/SET_QUERY',
  SET_OPTIONS: 'filter/SET_OPTIONS',
  SET_SELECTABLE_OPTIONS: 'filter/SET_SELECTABLE_OPTIONS',
};

// Reducer
const initialState = {
  options: {},
  query: {},
  selectableOptions: {
    addresses: [],
    orderTypes: [],
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.SET_OPTIONS:
      return {
        ...state,
        options: action.payload,
      };

    case ACTIONS.SET_QUERY:
      return {
        ...state,
        query: action.payload,
      };

    case ACTIONS.SET_SELECTABLE_OPTIONS:
      return {
        ...state,
        selectableOptions: {
          ...state.selectableOptions,
          addresses: action.payload.addresses,
          orderTypes: action.payload.orderTypes,
        },
      };

    default:
      return state;
  }
}

// actions
export const setQuery = query => ({
  type: ACTIONS.SET_QUERY,
  payload: query,
});

export const setOptions = options => ({
  type: ACTIONS.SET_OPTIONS,
  payload: options,
});

export const setSelectableOptions = selectableOptions => ({
  type: ACTIONS.SET_SELECTABLE_OPTIONS,
  payload: selectableOptions,
});

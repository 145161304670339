import React from 'react';

import PropTypes from 'prop-types';

import Card from 'src/components/common/Card';
import userIcon from 'src/components/common/icons/user.svg';
import classes from 'src/components/FranchiseeSettingsPage/UserList/UserCard/style.module.scss';
import urlPrefix from 'src/helpers/url-prefix';

const UserCard = ({ data }) => (
  <Card className="isInspector" url={`${urlPrefix('users')}/${data.id}`}>
    <div className={classes.name}>
      <div className={classes.icon}>
        <img src={userIcon} alt="Cliente" />
      </div>
      <div className={classes.text}>{data.username}</div>
    </div>
    <div className={classes.line}>
      {data.phone ? <div className={classes.phone}>{data.phone}</div> : null}
      {data.email ? <div className={classes.email}>{data.email}</div> : null}
    </div>
  </Card>
);

UserCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default UserCard;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let Franchisee = props => (
  <SvgIcon {...props}>
    <path
      fill="#393939"
      d="M17,2C8.7,2,2,8.7,2,17s6.7,15,15,15s15-6.7,15-15S25.3,2,17,2z M17,30C9.8,30,4,24.2,4,17S9.8,4,17,4
			s13,5.8,13,13S24.2,30,17,30z"
    />
    <path
      fill="#393939"
      d="M23.6,17.1c-1.1,0-2-0.8-2.2-1.9c-0.1,1.1-1.1,1.9-2.2,1.9C18,17.1,17,16.2,17,15c0,1.2-1,2.1-2.2,2.1c-1.1,0-2-0.8-2.2-1.9c-0.1,1.1-1.1,1.9-2.2,1.9c-0.3,0-0.6-0.1-0.9-0.2v8.4h5.8v-5.5h3.4v5.5h5.8v-8.4C24.2,17,23.9,17.1,23.6,17.1z"
    />
    <rect x="8.2" y="9.5" fill="#393939" width="17.5" height="2.5" />
    <path
      fill="#393939"
      d="M8.2,13v0.8c0,1.2,1,2.2,2.2,2.2c1.1,0,2-0.8,2.2-1.9c0.1,1.1,1.1,1.9,2.2,1.9c1.2,0,2.2-1,2.2-2.2c0,1.2,1,2.2,2.2,2.2c1.1,0,2-0.8,2.2-1.9c0.1,1.1,1.1,1.9,2.2,1.9c1.2,0,2.2-1,2.2-2.2V13H8.2z"
    />
  </SvgIcon>
);
Franchisee = pure(Franchisee);
Franchisee.displayName = 'Franchisee';
Franchisee.muiName = 'SvgIcon';

export default Franchisee;

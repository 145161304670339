import React from 'react';
import { Route, Switch, NavLink } from 'react-router-dom';

import PropTypes from 'prop-types';

import ClientLayout from 'src/components/ClientLayout';
import Card from 'src/components/common/Card';
import Home from 'src/components/MarketplacePage/Home';
import Service from 'src/components/MarketplacePage/Service';
import classes from 'src/components/MarketplacePage/style.module.scss';
import installedImage from 'src/images/icons/installed.svg';
import squaresImage from 'src/images/icons/squares.svg';

const MarketplacePageComponent = ({ intl }) => (
  <ClientLayout>
    <div className={classes.marketplace}>
      <Card className={classes.card}>
        <div className={classes.menu}>
          <ul>
            <li>
              <NavLink to="/marketplace" exact activeClassName={classes.isActive}>
                <img src={squaresImage} alt={intl.formatMessage({ id: 'GALLERY' })} />
                {intl.formatMessage({ id: 'GALLERY' })}
              </NavLink>
            </li>
            <li>
              <NavLink to="/marketplace/enabled" exact activeClassName={classes.isActive}>
                <img src={installedImage} alt={intl.formatMessage({ id: 'INSTALLED' })} />
                {intl.formatMessage({ id: 'INSTALLED' })}
              </NavLink>
            </li>
          </ul>
        </div>
        <div className={classes.content}>
          <Switch>
            <Route path="/marketplace/enabled" render={props => <Home filterEnabled {...props} />} />
            <Route path="/marketplace/:service" component={Service} />
            <Route exactly path="/marketplace" component={Home} />
          </Switch>
        </div>
      </Card>
    </div>
  </ClientLayout>
);

MarketplacePageComponent.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default MarketplacePageComponent;

import React from 'react';

import { utils } from '@rjsf/core';

import ArrayFieldTemplate from 'src/components/common/Form/ArrayFieldTemplate';
import DefaultChildren from 'src/components/common/Form/DefaultChildren';
import ErrorList from 'src/components/common/Form/ErrorList';
import FieldTemplate from 'src/components/common/Form/FieldTemplate';
import ObjectFieldTemplate from 'src/components/common/Form/ObjectFieldTemplate';
import customWidgets from 'src/components/common/Form/widgets';

const { getDefaultRegistry } = utils;
const { fields, widgets } = getDefaultRegistry();

const theme = {
  ArrayFieldTemplate,
  ErrorList,
  FieldTemplate,
  ObjectFieldTemplate,
  children: <DefaultChildren />,
  fields,
  widgets: { ...widgets, ...customWidgets },
};

export default theme;

import React from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { CSSTransition } from 'react-transition-group';

import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import PropTypes from 'prop-types';

import classes from 'src/components/ClientsPage/ClientSummary/style.module.scss';
import Card from 'src/components/common/Card';
import arrowDownImage from 'src/components/common/icons/arrow-down-circle.svg';
import arrowUpImage from 'src/components/common/icons/arrow-up-circle.svg';
import downloadImage from 'src/components/common/icons/download.svg';
import recordsImage from 'src/components/common/icons/records.svg';
import SearchBox from 'src/components/common/SearchBox';
import Spinner from 'src/components/common/Spinner';

const ClientSummaryComponent = ({
  exportSummary,
  handleFilter,
  id,
  intl,
  isDropdownMenuVisible,
  isExportingSummary,
  items,
  loading,
  menuButtonRef,
  numberFormat,
  pageNumbers: PageNumbers,
  pagination,
  query,
  setIsDropdownMenuVisible,
  setPage,
  setSummaryType,
  summaryType,
  summaryOptions,
}) =>
  items ? (
    <div className={classes.clientSummary}>
      <Card className="fade-in">
        <h2 className={classes.cardHead}>
          <div className={classes.back}>
            <Link to={`../${id}`}>
              <div className={classes.arrowLeft} />
            </Link>
          </div>

          <div className={classes.headActions}>
            <button type="button" onClick={exportSummary} data-tip={intl.formatMessage({ id: 'EXPORT_EXTRACT' })}>
              {isExportingSummary ? (
                <CircularProgress size={20} className={classes.Spinner} />
              ) : (
                <img src={downloadImage} alt={intl.formatMessage({ id: 'EXPORT_EXTRACT' })} />
              )}
            </button>
          </div>

          <div className={classes.icon}>
            <img src={recordsImage} alt={intl.formatMessage({ id: 'EXTRACT' })} />
          </div>

          <div className={classes.dropdown}>
            <button
              type="button"
              className={classes.dropdownTrigger}
              ref={menuButtonRef}
              onClick={() => {
                setIsDropdownMenuVisible(!isDropdownMenuVisible);
              }}
            >
              {!!summaryOptions.get(summaryType) && intl.formatMessage({ id: summaryOptions.get(summaryType) })}
            </button>

            <CSSTransition in={isDropdownMenuVisible} timeout={300} classNames="menu" unmountOnExit>
              <div className={classes.menu}>
                <ul>
                  {Array.from(summaryOptions, ([type, translationKey]) => (
                    <li key={type}>
                      <button type="button" onClick={() => setSummaryType(type)}>
                        {intl.formatMessage({ id: translationKey })}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </CSSTransition>
          </div>
        </h2>

        <div className={classes.filter}>
          <SearchBox
            advancedPlaceholder={intl.formatMessage({ id: 'FILTER' })}
            handler={handleFilter}
            options={{ advanced: ['start_date', 'end_date'] }}
            placeholder={intl.formatMessage({ id: 'FILTER' })}
            query={query}
            redux
            isClientsPageHeader={false}
          />
        </div>

        <div className={classes.list}>
          {items.map(item => (
            <div className={`${classes.item} fade-in`} key={`${item.operation}-${item.id}-${item.created_at}`}>
              <div className={classes.icon}>
                {item.credits >= 0 ? (
                  <img src={arrowUpImage} alt={intl.formatMessage({ id: 'POSITIVE' })} />
                ) : (
                  <img src={arrowDownImage} alt={intl.formatMessage({ id: 'NEGATIVE' })} />
                )}
              </div>

              <div className={classes.text}>
                <div className={classes.highlight}>{`${numberFormat.format((item.credits / 100).toFixed(1))}`}</div>

                <div className={classes.separator}>•</div>

                <div className={classes.normal}>{item.identifier}</div>
              </div>

              <div className={classes.date}>
                {`${moment(item.created_at).format('DD/MM/YYYY')} • ${moment(item.created_at).format('HH[h]mm')}`}
              </div>
            </div>
          ))}

          {items.length === 0 && (
            <p className={classes.empty}>{intl.formatMessage({ id: 'NO_ITEMS_SO_FAR_MESSAGE' })}</p>
          )}

          {loading && (
            <div className={`${classes.spinner} ${classes.fadeContent}`}>
              <Spinner />
            </div>
          )}
        </div>

        {items.length > 0 && (
          <div className={classes.pagination}>
            <button
              type="button"
              className={`${classes.page} ${classes.isFirst}`}
              disabled={pagination.currentPage === 1}
              onClick={() => setPage(pagination.currentPage - 1)}
            >
              <div className={classes.arrowLeft} />
            </button>

            <PageNumbers />

            <button
              type="button"
              className={`${classes.page} ${classes.isLast}`}
              disabled={pagination.currentPage === pagination.totalPages}
              onClick={() => setPage(pagination.currentPage + 1)}
            >
              <div className={classes.arrowRight} />
            </button>
          </div>
        )}

        <ReactTooltip effect="solid" />
      </Card>
    </div>
  ) : (
    <div className={classes.spinner}>
      <Spinner />
    </div>
  );

ClientSummaryComponent.propTypes = {
  exportSummary: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  isDropdownMenuVisible: PropTypes.bool.isRequired,
  isExportingSummary: PropTypes.bool.isRequired,
  items: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  menuButtonRef: PropTypes.object.isRequired,
  numberFormat: PropTypes.object.isRequired,
  pageNumbers: PropTypes.func,
  pagination: PropTypes.object,
  query: PropTypes.object.isRequired,
  setIsDropdownMenuVisible: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setSummaryType: PropTypes.func.isRequired,
  summaryType: PropTypes.string.isRequired,
  summaryOptions: PropTypes.instanceOf(Map).isRequired,
};

ClientSummaryComponent.defaultProps = {
  items: null,
  pageNumbers: null,
  pagination: null,
};

export default ClientSummaryComponent;

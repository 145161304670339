import { connect } from 'react-redux';

import TimelineComponent from 'src/components/SchedulePage/Timeline/TimelineComponent';

const mapStateToProps = ({ schedule }) => ({
  date: schedule.date,
});

const TimelineContainer = connect(mapStateToProps)(TimelineComponent);

export default TimelineContainer;

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/PhotoBuildingDetailsSection/style.module.scss';
import Details from 'src/svg-icons/details';

const PhotoBuildingDetailsSection = ({ data }) => (
  <Grid item xs={12}>
    <div className={classes.header}>
      <Details className={classes.icon} />
      <FormattedMessage id="propertyInformations" />
    </div>
    <Grid container spacing={8} className={classes.information}>
      {data.area ? (
        <Grid item xs={6} data-cy="order-details-photo-building-details-area">
          <div className={classes.subHeader}>
            <FormattedMessage id="area" />
          </div>
          {`${data.area} m²`}
        </Grid>
      ) : null}
      {data.roomsCount ? (
        <Grid item xs={6} data-cy="order-details-photo-building-details-rooms-count">
          <div className={classes.subHeader}>
            <FormattedMessage id="roomsCount" />
          </div>
          {data.roomsCount}
        </Grid>
      ) : null}
      {data.suitesCount ? (
        <Grid item xs={6} data-cy="order-details-photo-building-details-suites-count">
          <div className={classes.subHeader}>
            <FormattedMessage id="suitesCount" />
          </div>
          {data.suitesCount}
        </Grid>
      ) : null}
      {data.furnished ? (
        <Grid item xs={6} data-cy="order-details-photo-building-details-furnished">
          <div className={classes.subHeader}>
            <FormattedMessage id="furnished" />
          </div>
          <FormattedMessage id={data.furnished} />
        </Grid>
      ) : null}
      {data.specificCharacteristics ? (
        <Grid item xs={12} data-cy="order-details-photo-building-details-specific-characteristics">
          <div className={classes.subHeader}>
            <FormattedMessage id="specificCharacteristics" />
          </div>
          {data.specificCharacteristics}
        </Grid>
      ) : null}
      {data.rooms && data.rooms.length > 0 ? (
        <Grid item xs={12}>
          <div className={classes.subHeader}>
            <FormattedMessage id="GENERAL_FEATURES" />
          </div>
          <Grid container xs={12} data-cy="order-details-photo-building-details-rooms">
            {data.rooms.map(room => (
              <Grid item xs={6} key={room} data-cy={`order-details-photo-building-details-room-${room}`}>
                {room}
              </Grid>
            ))}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  </Grid>
);

PhotoBuildingDetailsSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PhotoBuildingDetailsSection;

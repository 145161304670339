import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { showToast } from 'src/store/ducks/toasts';

import api from '../../apiRequest';
import AddMediaModalComponent from './AddMediaModalComponent';

const AddMediaModalContainer = ({ orderId, closeModal, refreshHandler }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [medias, setMedias] = useState([]);
  const intl = useIntl();
  const dispatch = useDispatch();

  const onChange = useCallback(values => {
    setMedias(values);
    setIsValid(values.filter(media => media.isValid === true).length > 0);
  }, []);

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      await api.post(
        `/orders/${orderId}/medias`,
        medias.map(media => ({
          external_uri: media.external_uri,
          external_id: media.external_id,
          type: media.type,
        }))
      );
      dispatch(showToast({
        title: 'Sucesso',
        text: 'A mídia foi adicionada com sucesso!',
        type: 'success',
        duration: 4000,
      }));
      closeModal();
      refreshHandler();
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return <AddMediaModalComponent isSubmitting={isSubmitting} isValid={isValid} intl={intl} onChange={onChange} onSubmit={onSubmit} />;
};

AddMediaModalContainer.propTypes = {
  orderId: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  refreshHandler: PropTypes.func.isRequired,
};

export default AddMediaModalContainer;

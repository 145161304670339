import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const SeguroLever = props => (
  <SvgIcon viewBox="297.638 155.903 850.394 850.394" {...props}>
    <path
      fill="#393939"
      d="M625.985,404.833c0-54.236,42.613-96.851,96.85-96.851s96.85,42.614,96.85,96.851v77.479h58.11v-77.479
      c0-85.229-69.731-154.96-154.96-154.96s-154.96,69.731-154.96,154.96v77.479h58.11V404.833z M897.165,521.053h-348.66
      c-21.307,0-38.74,17.433-38.74,38.74v271.18c0,21.307,17.434,38.74,38.74,38.74h348.66c21.307,0,38.74-17.434,38.74-38.74v-271.18
      C935.905,536.549,918.472,521.053,897.165,521.053z"
    />
  </SvgIcon>
);

SeguroLever.displayName = 'SeguroLever';
SeguroLever.muiName = 'SvgIcon';

export default SeguroLever;

import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';

import PropTypes from 'prop-types';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const defaultMaskOptions = {
  prefix: 'R$ ',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 6, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const CurrencyInput = ({ maskOptions, onChange, value, ...inputProps }) => {
  const [currentValue, setCurrentValue] = useState(value);

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  const handleChange = evt => {
    const { value: inputValue } = evt.target;
    setCurrentValue(inputValue);
    onChange(evt);
  };

  const onBlur = evt => {
    const { value: inputValue } = evt.target;
    const input = inputValue.split(',');
    let newValue;

    if (inputValue === '') return;

    if (input.length > 1) {
      input[1] = input[1].padEnd(2, '0');
      newValue = input.join(',');
    } else {
      newValue = `${inputValue},00`;
    }

    setCurrentValue(newValue);
    onChange({ target: { name: evt.target.name, value: newValue } });
  };

  return (
    <MaskedInput
      mask={currencyMask}
      value={currentValue}
      onChange={handleChange}
      onBlur={onBlur}
      {...inputProps}
    />
  );
};

CurrencyInput.propTypes = {
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.bool,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.bool,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.string,
    requireDecimal: PropTypes.bool,
    allowNegative: PropTypes.bool,
    allowLeadingZeroes: PropTypes.bool,
    integerLimit: PropTypes.number,
  }),
  onChange: PropTypes.func,
  value: PropTypes.string,
};

CurrencyInput.defaultProps = {
  maskOptions: {},
  onChange: null,
  value: '',
};

export default CurrencyInput;

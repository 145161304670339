import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import ClientApp from 'src/ClientApp';
import AccessDenied from 'src/components/AccessDenied';
import CommercialPage from 'src/components/CommercialPage';
import Toast from 'src/components/common/Toast';
import MarketplacePage from 'src/components/MarketplacePage';
import Page from 'src/components/Page';
import ScreenLoading from 'src/components/ScreenLoading';
import UnknownError from 'src/components/UnknownError/UnknownError';
import { FORBIDDEN_REDIRECT_URL } from 'src/config';
import getUserLanguage from 'src/config/language';
import { AsyncServiceProvider } from 'src/contexts/AsyncServiceContext';
import { ConfirmAlertProvider } from 'src/contexts/ConfirmAlertContext';
import UserContext from 'src/contexts/UserContext';
import FranchiseeApp from 'src/FranchiseeApp';
import getSupportPanelLink from 'src/helpers/get-support-panel-link';
import { checkAccessTokenIsExpired } from 'src/services/isTokenExpired';
import * as divergencesDuck from 'src/store/ducks/divergences';
import * as tagsDuck from 'src/store/ducks/tags';
import * as toastDuck from 'src/store/ducks/toasts';
import messagesPTBR from 'src/translations/pt_br.json';

class App extends Component {
  static contextType = UserContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      localeConfig: {
        locale: 'pt-BR',
        messages: messagesPTBR,
      },
      authRole: '',
      name: '',
      features: [],
      ordersChanged: false,
      accountBalance: null,
      inspectionsTemplateLoaded: false,
    };
  }

  async componentDidMount() {
    const { history, syncTags } = this.props;

    try {
      if(checkAccessTokenIsExpired()){
        return;
      }

      const { data } = await API.get('me');

      API.defaults.headers.common['notificationToken'] = `individual_${data.unique_id}`;
      API.defaults.headers.common['Individual-UUID'] = `individual_${data.unique_id}`;
      API.defaults.headers.common['Panel-UUID'] = `panel_${data.panel_id}`;

      if (data.status === 'disabled') {
        history.push('/access-denied');
      }

      this.initDivergences(data.role, data.features);

      // Request inspections templates if not support user
      if (data.role !== 'ROLE_SUPPORT' && data.role !== 'ROLE_INSPECTOR' && data.status !== 'disabled') {
        try {
          const { data: orderTypeConfigurations } = await API.get('templates/inspections');
          window.sessionStorage.setItem('orderTypeConfigurations', JSON.stringify(orderTypeConfigurations));
          this.setState({ inspectionsTemplateLoaded: true });
        } catch (err) {
          console.debug('App GET /templates/inspections error ', err);
        }
      } else {
        this.setState({ inspectionsTemplateLoaded: true });
      }

      const features = [...new Set(data.features)]; // remove duplicates

      if (features.includes('ORDER_MARKETPLACE_DOCUSIGN')) {
        features[features.indexOf('ORDER_MARKETPLACE_DOCUSIGN')] = 'VISTORIA_DOCUSIGN';
      }

      if (features.includes('ORDER_MARKETPLACE_FICHA_CERTA')) {
        features[features.indexOf('ORDER_MARKETPLACE_FICHA_CERTA')] = 'VISTORIA_FICHA_CERTA';
      }

      const me = {
        authRole: data.role,
        name: data.name || 'Usuário',
        features,
        uniqueId: data.unique_id,
        accountBalance: data?.details?.availableStandardMeters,
        acceptedTerms: data?.details?.acceptedTerms,
        franchiseeId: data?.details?.franchiseeId,
        type: data?.details?.type,
        contracts: data?.details?.contracts,
        profile: data?.profile,
        panelId: data.panel_id,
        plan: data?.details?.plan,
        identifier: data?.identifier,
        dashboards: data?.details?.dashboards,
        disabledByModality: data?.details?.modality === 'PAY_PER_USE' ? true : false,
        address: data?.details?.address,
      };

      history.listen(() => {
        ReactTooltip.hide();
      });

      if (data.status !== 'disabled') {
        if (data.role === 'ROLE_FRANCHISEE' || data.role === 'ROLE_CLIENT') {
          syncTags();
          if (history.location.pathname === '/') history.push('/orders');
        }

        if (data.role === 'ROLE_SUPPORT') {
          getSupportPanelLink();
        }

        if (data.role === 'ROLE_COMMERCIAL') {
          if (history.location.pathname === '/') history.push('/commercial');
        }
      } else {
        me.status = data.status;
      }

      window.sessionStorage.setItem('me', JSON.stringify(me));
      window.sessionStorage.setItem('uniqueId', me.uniqueId);

      const { locale, messages } = getUserLanguage(me?.profile?.country);

      this.setState({ ...me, localeConfig: { locale, messages } });
    } catch (err) {
      console.debug('App.componentDidMount error ', err);

      if (err.response && err.response.status && err.response.status === 403) {
        window.location = FORBIDDEN_REDIRECT_URL;

        return;
      }
    }
  }

  initDivergences = async (role, features) => {
    const { setCount } = this.props;

    if ((role !== 'ROLE_FRANCHISEE' && role !== 'ROLE_CLIENT') || features.indexOf('DIVERGENCE_PANEL') < 0) return;

    try {
      const { data } = await API.get('/count/order-divergences', {
        params: {
          status:
            role === 'ROLE_FRANCHISEE'
              ? ['WAITING_FRANCHISEE_FEEDBACK', 'WAITING_FRANCHISEE_AGREEMENT']
              : ['WAITING_CLIENT_FEEDBACK'],
        },
      });
      setCount(data.count);
    } catch (err) {
      console.debug('App.initDivergences error ', err);
    }
  };

  setOrdersChanged = changed => this.setState({ ordersChanged: changed });

  ordersUpdated = () => this.setState({ ordersChanged: false });

  getFranchiseeApp = () => {
    const { features, franchiseeId, name, ordersChanged } = this.state;
    const { history } = this.props;

    return (
      <UserContext.Provider value={this.state}>
        <FranchiseeApp
          userName={name}
          features={features}
          ordersChanged={ordersChanged}
          ordersUpdated={this.ordersUpdated}
          history={history}
          franchiseeId={franchiseeId}
        />

        <UnknownError />
      </UserContext.Provider>
    );
  };

  getClientApp = () => {
    const { accountBalance, contracts, features, name } = this.state;

    return (
      <UserContext.Provider value={this.state}>
        <ClientApp userName={name} features={features} balance={accountBalance} contracts={contracts} />

        <UnknownError />
      </UserContext.Provider>
    );
  };

  render() {
    const { authRole, inspectionsTemplateLoaded, localeConfig } = this.state;

    const me = window.sessionStorage.getItem('me');
    const orderTypeConfigurations = window.sessionStorage.getItem('orderTypeConfigurations');

    if ((!inspectionsTemplateLoaded && !orderTypeConfigurations) || !me) {
      return <ScreenLoading />;
    }

    if (!authRole) {
      return <ScreenLoading />;
    }

    return (
      <IntlProvider locale={localeConfig.locale} messages={localeConfig.messages}>
        <ConfirmAlertProvider>
          <AsyncServiceProvider setOrdersChanged={this.setOrdersChanged}>
            <UserContext.Provider value={this.state}>
              <Switch>
                <Route path="/access-denied" component={AccessDenied} />

                <Route
                  path="/orders"
                  render={() => {
                    if (authRole === 'ROLE_FRANCHISEE') {
                      return this.getFranchiseeApp();
                    } else if (authRole === 'ROLE_CLIENT') {
                      return this.getClientApp();
                    }

                    return null;
                  }}
                />

                <Route path="/marketplace">
                  <MarketplacePage />
                </Route>

                {authRole === 'ROLE_CLIENT' && <Redirect to="/orders" />}

                <Route path="/inspections">{this.getFranchiseeApp()}</Route>

                <Route exact path="/commercial">
                  <Redirect to="/commercial/clients" />
                </Route>

                <Route path="/commercial" component={CommercialPage} />

                <Route path="/:page">
                  <Page />
                </Route>

                <Route
                  path="*"
                  render={() => {
                    if (authRole === 'ROLE_FRANCHISEE' || authRole === 'ROLE_CLIENT') {
                      return <Redirect to="/orders" />;
                    }

                    if (authRole === 'ROLE_COMMERCIAL') {
                      return <Redirect to="/commercial" />;
                    }

                    return <Redirect to="/" />;
                  }}
                />
              </Switch>

              <Toast />
            </UserContext.Provider>
          </AsyncServiceProvider>
        </ConfirmAlertProvider>
      </IntlProvider>
    );
  }
}

App.propTypes = {
  history: PropTypes.object.isRequired,
  setCount: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  syncTags: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ divergenceCount: state?.divergences?.count });

const mapDispatchToProps = {
  setCount: divergencesDuck.setCount,
  syncTags: tagsDuck.syncTags,
  showToast: toastDuck.showToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));

import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import PropTypes from 'prop-types';
import * as yup from 'yup';

import API from 'src/apiRequest';
import ReactivationComponent from 'src/components/Reactivation/ReactivationComponent';

const validationSchema = yup.object().shape({
  reason: yup.string().required('Campo obrigatório'),
});

const ReactivationContainer = ({ orderId, closeModal, refreshHandler }) => {
  const [error, setError] = useState(null);
  const intl = useIntl();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await API.put(`orders/${orderId}/reactivate`, {
        reason: values.reason,
      });
      setSubmitting(false);
      refreshHandler();
      closeModal();
    } catch (err) {
      setSubmitting(false);
      console.log(err?.response || err);
      setError(
        err?.response?.status === 500 || intl.formatMessage({ id: 'UNKNOWN_ERROR' })
      );
    }
  };

  return (
    <ReactivationComponent error={error} handleSubmit={handleSubmit} validationSchema={validationSchema} intl={intl} />
  );
};

ReactivationContainer.propTypes = {
  orderId: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  refreshHandler: PropTypes.func.isRequired,
};

export default ReactivationContainer;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let TypePredialO2 = props => (
  <SvgIcon {...props} fill="none">
    <g>
      <path
        className="st0"
        d="M14.7,21.9h3.5v-3.5h3.5V15h3.5v-3.5h2.3V7c0-1.1-0.9-1.9-2-1.9h-5.3l3.3,1.3v5.1H10.3V6.3L13.6,5H8.3
		c-1.1,0-2,0.9-2,1.9V28c0,1.1,0.9,1.9,2,1.9h17.1c1.6,0,2-2,2-2H14.7V21.9z"
        fill="#A35F26"
      />
      <path
        className="st0"
        d="M11.6,10.2h10.5V7.6l-3.3-1.3V4.4c0-1.1-0.2-1.3-1.3-1.3h-1.3c-1.1,0-1.3,0.2-1.3,1.3v1.9l-3.3,1.3
		C11.6,7.6,11.6,10.2,11.6,10.2z"
        fill="#A35F26"
      />
      <polygon
        className="st0"
        points="26.5,12.8 26.5,16.3 23,16.3 23,19.8 19.5,19.8 19.5,23.2 16,23.2 16,26.7 29.8,26.7 29.8,23.2
		29.8,19.8 29.8,16.3 29.8,12.8 	"
        fill="#A35F26"
      />
    </g>
  </SvgIcon>
);

TypePredialO2 = pure(TypePredialO2);
TypePredialO2.displayName = 'TypePredialO2';
TypePredialO2.muiName = 'SvgIcon';

export default TypePredialO2;

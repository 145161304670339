import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import DocumentIcon from '@material-ui/icons/Description';
import PropTypes from 'prop-types';

import styles from 'src/orderDetails/FilesToSignSection/style.module.css';
import DocumentSectionIcon from 'src/svg-icons/documents-circle';

const FilesToSignSection = props => {
  const { documents } = props;
  return (
    <Grid item xs={12}>
      <div className={styles.Header}>
        <DocumentSectionIcon viewBox="0 0 34.016 34.016" className={styles.Icon} />
        <FormattedMessage id="documents" />
      </div>
      {documents.map(document => (
        <div className={styles.Information} key={document.id}>
          <DocumentIcon viewBox="0 0 34 34" nativeColor="fill=#858585" className={styles.smallIcon} />
          <p data-cy="order-details-document-name">{document.name}</p>
        </div>
      ))}
    </Grid>
  );
};

FilesToSignSection.propTypes = {
  documents: PropTypes.array.isRequired,
};

export default FilesToSignSection;

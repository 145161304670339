import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import * as yup from 'yup';

import API from 'src/apiRequest';
import OrderCancelPageComponent from 'src/components/OrderCancelPage/OrderCancelPageComponent';
import { showToast } from 'src/store/ducks/toasts';

const options = [
  {
    value: 'Solicitado pelo cliente',
    label: 'Solicitado pelo cliente',
    disabled: false,
  },
  {
    value: 'Vistoria não realizada (VNR)',
    label: 'Vistoria não realizada (VNR)',
    disabled: false,
  },
];

const validationSchema = yup.object().shape({
  reason: yup.string().required('Campo obrigatório'),
  observation: yup.string().required('Campo obrigatório'),
});

const OrderCancelPageContainer = ({ order, cancelOrder, changePage, intl, ...props }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isBusy, setIsBusy] = useState(false);
  const [reasonOptions, setReasonOptions] = useState(options);
  const [orderDetails, setOrderDetails] = useState(null);
  const dispatch = useDispatch();

  const initialValues = {
    reason: order.status === 'VNR' ? 'Vistoria não realizada (VNR)' : '',
    observation: '',
    shouldBeCharged: order.status === 'VNR',
    sendPhotoToClient: order.status === 'VNR',
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!order.id) throw new Error('order id is required');

        const { data } = await API.get(`view/orders/${order.id}`);
        setOrderDetails(data);
      } catch (err) {
        console.debug('OrderCancelPageContainer.fetchData error', err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();

    if (order.status !== 'VNR') return;

    setReasonOptions(
      options.map(option => {
        if (option.value !== 'Vistoria não realizada (VNR)') option.disabled = true;
        return { ...option };
      })
    );
  }, [order]);

  const handleBack = evt => {
    changePage(evt, 'order-details', order.id);
  };

  const handleSubmit = async values => {
    const message = intl.formatMessage({ id: 'MESSAGE.CANCEL_CONFIRMATION' });
    if (!window.confirm(message)) {
      return;
    }

    try {
      setIsBusy(true);
      const payload = {
        action: 'cancel',
        message: {
          reason: values.reason,
          observation: values.observation,
          should_be_charged: values.shouldBeCharged,
        },
      };

      if (orderDetails?.messages?.vnr?.photo) {
        payload.message.send_photo_to_client = values.sendPhotoToClient;
        if (values.sendPhotoToClient) payload.message.photo = orderDetails.messages.vnr.photo;
      }

      await API.post(`orders/${order.id}/status`, payload);
      cancelOrder(order.id);
      dispatch(
        showToast({
          type: 'success',
          title: 'Pedido cancelado',
          text: 'Pedido cancelado com sucesso.',
          duration: 5000,
        })
      );
    } catch (err) {
      dispatch(
        showToast({
          type: 'error',
          title: 'Erro',
          text: 'Não foi possível cancelar esse pedido.',
          duration: 5000,
        })
      );
    } finally {
      setIsBusy(false);
    }
  };

  return (
    <OrderCancelPageComponent
      isBusy={isBusy}
      isLoading={isLoading}
      order={orderDetails}
      onSubmit={handleSubmit}
      goBack={handleBack}
      initialValues={initialValues}
      reasonOptions={reasonOptions}
      validationSchema={validationSchema}
      {...props}
    />
  );
};

OrderCancelPageContainer.propTypes = {
  intl: PropTypes.object.isRequired,
  cancelOrder: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
};

export default injectIntl(OrderCancelPageContainer);

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { AddCircle } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { ReactComponent as OrderHistory } from 'src/components/common/icons/order-history.svg';

import InspectorRow from './InspectorRow';
import classes from './style.module.scss';

const InspectorsComponent = ({ inspectors, canAdd, orderId, refreshHandler }) => {
  const history = useHistory();

  const goToAddInspector = () => {
    history.push(`/orders/${orderId}/performing-inspector`);
  };

  return (
    <div className={classes.inspectorsContainer}>
      <div className={classes.header}>
        <OrderHistory className={classes.icon} />
        Vistoriadores executantes
      </div>
      {inspectors.length > 0 && (
        <div className={classes.timeline}>
          <div className={classes.title}>
            <div style={{ width: '35%', paddingLeft: '8px' }}>Ação</div>
            <div style={{ width: '35%' }}>Vistoriador</div>
            <div style={{ width: '20%' }}>Data e Hora</div>
            <div style={{ width: '10%' }}></div>
          </div>
          {inspectors.map((inspector, index) => (
            <InspectorRow inspector={inspector} key={index} orderId={orderId} refreshHandler={refreshHandler}/>
          ))}
        </div>
      )}
      {canAdd && (
        <p>
          <input
            type="button"
            id="add-inspector"
            className={classes.addInspectorInput}
            onClick={goToAddInspector}
            data-cy="order-details-add-inspector"
          />
          <label htmlFor="add-inspector" className={classes.link}>
            <AddCircle className={classes.buttonIcon} />
            <FormattedMessage id="ADD_INSPECTOR" />
          </label>
        </p>
      )}
    </div>
  );
};

InspectorsComponent.propTypes = {
  inspectors: PropTypes.array,
  canAdd: PropTypes.bool.isRequired,
  orderId: PropTypes.string.isRequired,
  refreshHandler: PropTypes.func.isRequired,
};

InspectorsComponent.defaultProps = {
  inspectors: [],
};

export default InspectorsComponent;

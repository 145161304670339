import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import PropTypes from 'prop-types';

import Checkbox from 'src/components/common/elements/Checkbox';
import exclamationSolidImage from 'src/components/common/icons/exclamation-solid.svg';
import pointCleaningImage from 'src/components/common/icons/point-cleaning-alt.svg';
import pointEnergyImage from 'src/components/common/icons/point-energy.svg';
import classes from 'src/orderForm/GenericWarning/style.module.scss';

const GenericWarning = ({ onChange, submitted }) => {
  const [checked, setChecked] = useState(false);
  const intl = useIntl();

  const handleChange = evt => {
    const { checked: isChecked } = evt.target;
    setChecked(isChecked);
    onChange(isChecked);
  };

  return (
    <div className={classes.genericWarning}>
      <h2>
        <div className={classes.icon}>
          <img src={exclamationSolidImage} alt={intl.formatMessage({ id: 'GET_CERTIFIED' })} />
        </div>
        {intl.formatMessage({ id: 'MAKE_SURE_THAT' })}
      </h2>
      <ul>
        <li>
          <div className={classes.icon}>
            <img src={pointEnergyImage} alt={intl.formatMessage({ id: 'POWER' })} />
          </div>
          <div className={classes.text}>{intl.formatMessage({ id: 'PROPERTY_HAS_POWER_SUPPLY_MESSAGE' })}</div>
        </li>
        <li>
          <div className={classes.icon}>
            <img src={pointCleaningImage} alt={intl.formatMessage({ id: 'CLEANING' })} />
          </div>
          <div className={classes.text}>
            {intl.formatMessage({
              id: 'PROPERTY_CLEANING_HAS_BEEN_CARRIED_MESSAGE',
            })}
          </div>
        </li>
      </ul>
      <div className={classes.field}>
        <label>
          <Checkbox
            className={classes.inputCheckbox}
            name="generic-checkbox"
            id="generic-checkbox"
            data-cy="generic-checkbox"
            value="true"
            checked={checked}
            onChange={handleChange}
          />
          {intl.formatMessage({
            id: 'AWARE_OF_THE_INFORMATION_LISTED_MESSAGE',
          })}
        </label>
        {submitted && !checked ? (
          <ul className="error-detail bs-callout bs-callout-info">
            <li className="text-danger">{intl.formatMessage({ id: 'ACCEPT_TERMS_MESSAGE' })}</li>
          </ul>
        ) : null}
      </div>
    </div>
  );
};

GenericWarning.propTypes = {
  onChange: PropTypes.func.isRequired,
  submitted: PropTypes.bool.isRequired,
};

export default GenericWarning;

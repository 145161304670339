import React from 'react';
import { IntlProvider } from 'react-intl';
import { Route, Switch } from 'react-router-dom';

import ClientLinkLayout from 'src/components/ClientLinkLayout/ClientLinkLayoutContainer';
import ClientFormLink from 'src/components/ClientsPublicPage/ClientFormLink';
import ClientSuccess from 'src/components/ClientsPublicPage/ClientSuccess';
import Toast from 'src/components/common/Toast';
import messagesPTBR from 'src/translations/pt_br.json';

const ClientsPublicPage = () => (
  <IntlProvider locale="pt-BR" messages={messagesPTBR}>
    <ClientLinkLayout>
      <Switch>
        <Route path="/new-client/:hash/success" render={() => <ClientSuccess />} />

        <Route path="/new-client/:hash" render={() => <ClientFormLink />} />
      </Switch>

      <Toast />
    </ClientLinkLayout>
  </IntlProvider>
);

export default ClientsPublicPage;

import React from 'react';

import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import classes from './style.module.scss';

const AddMediaComponent = ({ initialValues, onChange, validationSchema, FormikContext }) => (
  <div className={classes.addMedia}>
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
    >
      {({ values, errors }) => (
        <Form>
          <p className={classes.info}>Preencha todos os campos abaixo.</p>
          <div className={classes.section}>
            <label htmlFor="external-id">ID do Banib *</label>
            <Field type="text" name="external_id" id="external-id" required />
          </div>
          <div className={classes.section}>
            <label htmlFor="external-uri">Link do tour virtual *</label>
            <Field type="text" name="external_uri" id="external-uri" required />
          </div>
          <FormikContext onChange={onChange} />
        </Form>
      )}
    </Formik>
  </div>
);

AddMediaComponent.propTypes = {
  initialValues: PropTypes.object.isRequired,
  FormikContext: PropTypes.elementType.isRequired,
  validationSchema: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AddMediaComponent;

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { AppBar, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';
import PropTypes from 'prop-types';

import PlanSwitch from 'src/components/common/PlanSwitch';
import SearchBox from 'src/components/common/SearchBox';
import classes from 'src/Header.module.scss';
import HeaderMenu from 'src/HeaderMenu';
import DrawerTitle from 'src/leftDrawer/DrawerTitle';


const style = {
  appBar: {
    backgroundColor: '#D1D1D1',
    position: 'fixed',
    top: 0,
    height: 54,
    paddingLeft: 220,
    boxShadow: 'none',
  },
  clientAppBar: {
    backgroundColor: '#202F4A',
    color: '#ffffff',
    height: 70,
    boxShadow: 'none',
  },
  container: {
    display: 'flex',
    margin: 'auto',
    width: '100%',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
  },
  balance: {
    paddingTop: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  paddingRight: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    paddingRight: '1vw',
  },
};

const Header = ({ balance, client, features, headerSearchValue, handleHeaderSearch, userName, activeSubMenu }) => {
  const intl = useIntl();
  if (client) {
    let balanceElement = null;

    if (balance !== null) {
      const wallet = balance.slice(0, balance.indexOf(','));

      balanceElement = (
        <PlanSwitch planToHide="CONTROL">
          <div style={style.balance}>
            <AccountBalanceWallet style={{ verticalAlign: 'bottom', marginRight: '10px' }} />
            <FormattedMessage id="account.balance" />
            <Tooltip
              title={intl.formatMessage({ id: 'Financial.credits' })}
              placement="right"
            >
              <span>{`${wallet}`}</span>
            </Tooltip>
          </div>
        </PlanSwitch>
      );
    }

    return (
      <AppBar position="static" color="default" style={style.clientAppBar}>
        <Grid container>
          <Grid item xs={10} md={10} style={style.container}>
            <Grid item xs={5}>
              <DrawerTitle />
            </Grid>
            <Grid item xs={2}>
              {balanceElement}
            </Grid>
            <Grid item xs={5}>
              <HeaderMenu />
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
    );
  }

  return (
    <div>
      <AppBar position="static" color="default" style={style.appBar}>
        <Grid container>
          <Grid item xs={6}>
            <div className={classes.searchWrapper}>
              <SearchBox
                value={headerSearchValue}
                options={activeSubMenu === 'active' ? { advanced: ['activeTabOrderStatus'] } : {}}
                handler={handleHeaderSearch}
                isClientsPageHeader={false}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={style.paddingRight}>
              <HeaderMenu userName={userName} client={client} features={features} />
            </div>
          </Grid>
        </Grid>
      </AppBar>
    </div>
  );
};

Header.propTypes = {
  handleHeaderSearch: PropTypes.func,
  userName: PropTypes.string.isRequired,
  client: PropTypes.bool,
  balance: PropTypes.string,
  features: PropTypes.array,
  headerSearchValue: PropTypes.string,
  activeSubMenu: PropTypes.string,
};

Header.defaultProps = {
  handleHeaderSearch: () => {},
  client: false,
  balance: '',
  features: [],
  headerSearchValue: '',
  activeSubMenu: '',
};

export default Header;

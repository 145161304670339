import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let TypeIntermediariaBHomy = props => (
  <SvgIcon {...props} fill="none" viewBox="0 0 34.016 34.016">
    <path
      d="M13.0276 3.7576V1.81587C13.0276 0.744852 12.7946 0.52002 11.6786 0.52002H10.6322C9.51208 0.52002 9.28316 0.740764 9.28316 1.81587V3.7576L5.91068 5.04937V7.63698H16.4001V5.04937L13.0276 3.7576Z"
      fill="#6F6CA6"
    />
    <path
      d="M21.2729 4.31355V9.95889H16.6822V15.0115H11.6337V21.503H16.6822V26.5556H21.0031C20.6678 27.1279 20.0424 27.504 19.3352 27.4999H2.60769C1.54893 27.5122 0.678217 26.666 0.661865 25.6031C0.661865 25.599 0.661865 25.599 0.661865 25.599V4.66511C0.678217 3.60226 1.54893 2.75199 2.60769 2.76834H7.71751L4.41043 4.04375V9.13723H17.5243V3.67585L14.4257 2.39226H19.4006C20.4226 2.37591 21.2565 3.23845 21.2729 4.31355Z"
      fill="#6F6CA6"
    />
    <path
      d="M26.9999 16.2378V20.2766H21.9514V25.3292H17.9085V20.2766H12.86V16.2378H17.9085V11.1852H21.9514V16.2378H26.9999Z"
      fill="#6F6CA6"
    />
  </SvgIcon>
);

TypeIntermediariaBHomy = pure(TypeIntermediariaBHomy);
TypeIntermediariaBHomy.displayName = 'TypeIntermediariaBHomy';
TypeIntermediariaBHomy.muiName = 'SvgIcon';

export default TypeIntermediariaBHomy;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let StepPartiesInvolved = props => {
  if (props.on) {
    return (
      <SvgIcon {...props}>
        <g>
          <ellipse fill="#EC6024" cx="43" cy="36.45" rx="5.714" ry="5.875" />
          <path
            fill="#EC6024"
            d="M43,22c-7.72,0-14,6.482-14,14.45c0,9.283,10.108,22.633,14,27.431c3.894-4.797,14-18.139,14-27.431
                    C57,28.482,50.72,22,43,22z M43,44.324c-4.254,0-7.715-3.532-7.715-7.875s3.46-7.875,7.715-7.875c4.254,0,7.715,3.532,7.715,7.875
                    S47.254,44.324,43,44.324z"
          />
          <path
            fill="#EC6024"
            d="M42.917,0C19.214,0,0,19.214,0,42.917c0,23.702,19.214,42.917,42.917,42.917
                    c23.702,0,42.917-19.215,42.917-42.917C85.834,19.214,66.619,0,42.917,0z M43,67c0,0-16-18.213-16-30.55C27,27.355,34.153,20,43,20
                    s16.001,7.354,16.001,16.449C59.001,48.787,43,67,43,67z"
          />
        </g>
      </SvgIcon>
    );
  } else {
    return (
      <SvgIcon {...props}>
        <g>
          <path
            fill="#D7D7D7"
            d="M42.917,3c22.01,0,39.917,17.907,39.917,39.917c0,22.011-17.906,39.917-39.917,39.917
                    C20.907,82.834,3,64.928,3,42.917C3,20.907,20.907,3,42.917,3 M42.917,0C19.214,0,0,19.214,0,42.917
                    c0,23.702,19.214,42.917,42.917,42.917c23.702,0,42.917-19.215,42.917-42.917C85.834,19.214,66.619,0,42.917,0L42.917,0z"
          />
        </g>
        <g>
          <path
            fill="#D7D7D7"
            d="M43,28.575c-4.254,0-7.715,3.532-7.715,7.875s3.46,7.875,7.715,7.875c4.254,0,7.715-3.532,7.715-7.875
                    S47.254,28.575,43,28.575z M43,42.325c-3.154,0-5.714-2.633-5.714-5.875s2.56-5.874,5.714-5.874s5.715,2.631,5.715,5.874
                    S46.154,42.325,43,42.325z"
          />
          <path
            fill="#D7D7D7"
            d="M43,20c-8.847,0-16,7.354-16,16.449C27,48.787,43,67,43,67s16.001-18.213,16.001-30.55
                    C59.001,27.355,51.847,20,43,20z M29,36.45C29,28.482,35.28,22,43,22s14,6.482,14,14.45c0,9.292-10.106,22.634-14,27.431
                    C39.108,59.083,29,45.732,29,36.45z"
          />
        </g>
      </SvgIcon>
    );
  }
};

StepPartiesInvolved = pure(StepPartiesInvolved);
StepPartiesInvolved.displayName = 'StepPartiesInvolved';
StepPartiesInvolved.muiName = 'SvgIcon';

export default StepPartiesInvolved;

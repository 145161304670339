import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import API from 'src/apiRequest';
import CreateUserComponent from 'src/components/ClientsPage/CreateUser/CreateUserComponent';
import { useUser } from 'src/contexts/UserContext';
import { masksByCountry } from 'src/helpers/mask';
import { showToast } from 'src/store/ducks/toasts';

const CreateUserContainer = () => {
  const [busy, setBusy] = useState(false);
  const [features, setFeatures] = useState([]);
  const [user, setUser] = useState({ name: '', cpf: '', phone: '', username: '', email: '', features: [] });

  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const { id, status } = useParams();

  const me = useUser();
  const cpfMask = masksByCountry[me?.profile?.country || 'BR'].CPF_MASK;
  const cpfMaskPlaceholder = masksByCountry[me?.profile?.country || 'BR'].CPF_MASK_PLACEHOLDER;
  const phoneMask = masksByCountry[me?.profile?.country || 'BR'].PHONE_MASK;
  const phoneMaskPlaceholder = masksByCountry[me?.profile?.country || 'BR'].PHONE_MASK_PLACEHOLDER;
  const cellphoneMask = masksByCountry[me?.profile?.country || 'BR'].CELLPHONE_MASK;
  const cellphoneMaskLength = masksByCountry[me?.profile?.country || 'BR'].CELLPHONE_MASK_LENGTH;

  const onChange = evt => {
    const { name, value } = evt.target;

    setUser({ ...user, [name]: value });
  };

  const onSubmit = async evt => {
    evt.preventDefault();

    if (!user.features.length) {
      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'SELECT_AT_LEAST_ONE_PERMISSION_MESSAGE' }),
          duration: 5000,
        })
      );

      return;
    }

    try {
      setBusy(true);
      await API.post(`clients/${id}/users`, user);
      history.push(`/clients/${status}/${id}`);
    } catch (err) {
      if (err.response && err.response.status < 500) {
        const firstKey = Object.keys(err.response.data)[0];
        const message = intl.formatMessage({ id: `${firstKey}.${err.response.data[firstKey]}` });
        dispatch(
          showToast({
            type: 'error',
            title: intl.formatMessage({ id: 'ERROR' }),
            text: message,
            duration: 5000,
          })
        );
      } else {
        dispatch(
          showToast({
            type: 'error',
            title: intl.formatMessage({ id: 'ERROR' }),
            text: intl.formatMessage({ id: 'USER_SAVE_ERROR' }),
            duration: 5000,
          })
        );
      }
    } finally {
      setBusy(false);
    }
  };

  const handleSelectedPermissionsChange = useCallback(
    event => {
      if (event.target.checked) {
        setUser({
          ...user,
          features: [...user.features, Number(event.target.value)],
        });
      } else {
        setUser({
          ...user,
          features: user.features.filter(feature => feature !== Number(event.target.value)),
        });
      }
    },
    [user]
  );

  useEffect(() => {
    async function fetchFeatures() {
      const { data: clientPermissionsResponse } = await API.get(`clients/${id}/features`);
      setFeatures(clientPermissionsResponse);
      setUser({
        ...user,
        features: clientPermissionsResponse.filter(feature => feature.rule === 'opt-out').map(feature => feature.id),
      });
    }

    fetchFeatures();
  }, [id]);

  return (
    <CreateUserComponent
      busy={busy}
      features={features}
      handleSelectedPermissionsChange={handleSelectedPermissionsChange}
      id={id}
      intl={intl}
      onChange={onChange}
      onSubmit={onSubmit}
      status={status}
      user={user}
      cpfMask={cpfMask}
      cpfMaskPlaceholder={cpfMaskPlaceholder}
      phoneMask={phoneMask}
      phoneMaskPlaceholder={phoneMaskPlaceholder}
      cellphoneMask={cellphoneMask}
      cellphoneMaskLength={cellphoneMaskLength}
    />
  );
};

export default CreateUserContainer;

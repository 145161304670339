import React from 'react';
import { useIntl } from 'react-intl';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = {
  input: {
    minHeight: '37px',
    height: '37px',
  },
  isDisabled: {
    backgroundColor: '#f4f3f4',
    borderColor: '#ced4da',
    cursor: 'not-allowed',
  },
  label: {
    fontFamily: 'Open Sans',
    marginTop: '0.3vh',
    fontSize: 'calc(6px + 0.3vw)',
  },
};

const AddressSelectWidget = ({ disabled, label, onChange, onCreateOption, options, required, value }) => {
  const intl = useIntl();

  const selectDefaultProps = {
    id: `${label}-address-select-widget`,
    value,
    options: options?.enumOptions || [],
    onChange: option => option?.value && onChange(option.value),
    placeholder: null,
    isDisabled: disabled,
    styles: { control: base => ({ ...base, ...styles.input }) },
    components: {
      Input: inputProps => <components.Input {...inputProps} data-cy={`${label}-address-select-widget`} />,
    },
    noOptionsMessage: () => intl.formatMessage({ id: 'NO_OPTIONS_AVAILABLE_MESSAGE' }),
  };

  return (
    <div>
      <label style={styles.label}>
        {intl.formatMessage({ id: label })}

        {required && '*'}
      </label>

      {options?.creatable ? (
        <CreatableSelect
          {...selectDefaultProps}
          onCreateOption={onCreateOption}
          formatCreateLabel={userInput => `${intl.formatMessage({ id: 'INSERT' })}: ${userInput}`}
        />
      ) : (
        <Select {...selectDefaultProps} />
      )}
    </div>
  );
};

AddressSelectWidget.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onCreateOption: PropTypes.func,
  options: PropTypes.object.isRequired,
  required: PropTypes.bool.isRequired,
  value: PropTypes.object,
};

AddressSelectWidget.defaultProps = {
  disabled: false,
  onCreateOption: () => {},
  value: null,
};

export default withStyles(styles)(AddressSelectWidget);

import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import MaskedInput from 'react-text-mask';

import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

import UserContext from 'src/contexts/UserContext';
import { masksByCountry } from 'src/helpers/mask';

const styles = {
  bootstrapInput: {
    borderRadius: 4,
    fontWeight: 'normal',
    border: '1px solid #ced4da',
    fontSize: 'calc(6px + 0.3vw)',
    padding: '8px 12px',
    width: 'calc(100% - 24px)',
    '&:focus': {
      borderColor: '#80bdff',
    },
    fontFamily: 'Open Sans',
  },
  label: {
    fontFamily: 'Open Sans',
    marginTop: '0.3vh',
    marginBottom: '0.5rem',
    fontSize: 'calc(6px + 0.3vw)',
  },
  label2: {
    display: 'flex',
    alignItems: 'center',
  },
};

class RequestFormPerson extends Component {
  static contextType = UserContext;

  constructor(props, context) {
    super(props, context);

    const { formData } = props;
    this.state = { ...formData };
  }

  componentDidUpdate(prevProps) {
    const { formData } = this.props;

    if (formData.name !== prevProps.formData.name || formData.signature !== prevProps.formData.signature) {
      this.setState({
        name: formData.name,
        signature: formData.signature,
      });
    }
  }

  onChange = (name, title) => {
    const { onChange } = this.props;

    return event => {
      this.setState(
        {
          [name]: event.target.value,
          title,
        },
        () => onChange(this.state)
      );
    };
  };

  onChangeSig = name => {
    const { onChange } = this.props;

    return event => {
      this.setState(
        {
          [name]: event.target.checked,
        },
        () => onChange(this.state)
      );
    };
  };

  DocumentElement = ({ inputRef, ...other }) => {
    const me = this.context;

    const cnpjMask = masksByCountry[me?.profile?.country || 'BR'].CNPJ_MASK;
    const cnpjMaskLength = masksByCountry[me?.profile?.country || 'BR'].CNPJ_MASK_LENGTH;
    const cpfMask = masksByCountry[me?.profile?.country || 'BR'].CPF_MASK;

    return (
      <MaskedInput
        {...other}
        ref={inputRef}
        guide={false}
        mask={(value, config) => {
          const lastCharacter = value[value.length - 1];
          const isLastCharacterANumber = /\d/.test(lastCharacter);
          if (isLastCharacterANumber) {
            return value.length > cnpjMaskLength ? cnpjMask : cpfMask;
          } else {
            return config?.previousConformedValue?.length > cnpjMaskLength ? cnpjMask : cpfMask;
          }
        }}
      />
    );
  };

  render() {
    let { document, name, signature } = this.state;

    const { classes, disabled, idSchema, intl, schema } = this.props;

    if (name === undefined) {
      name = '';
    }

    if (document === undefined) {
      document = '';
    }

    if (signature === undefined) {
      signature = false;
    }

    return (
      <div>
        {schema.title && (
          <label style={styles.label} htmlFor={idSchema.$id}>
            {schema.title}
          </label>
        )}

        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <TextField
                disabled={disabled}
                id={idSchema.$id}
                data-cy={`${String(schema?.title).toLowerCase()}-name`}
                fullWidth
                value={name}
                onChange={this.onChange('name', schema.title)}
                inputProps={{ placeholder: intl.formatMessage({ id: 'NAME' }) }}
                InputProps={{
                  disableUnderline: true,
                  classes: { input: classes.bootstrapInput },
                }}
                InputLabelProps={{
                  shrink: true,
                  classes: { root: classes.label },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                disabled={disabled}
                data-cy={`${String(schema?.title).toLowerCase()}-document`}
                fullWidth
                inputProps={{ placeholder: intl.formatMessage({ id: 'DOCUMENT' }) }}
                InputProps={{
                  inputComponent: this.DocumentElement,
                  value: document,
                  onChange: this.onChange('document', schema.title),
                  disableUnderline: true,
                  classes: { input: classes.bootstrapInput },
                }}
                InputLabelProps={{
                  shrink: true,
                  classes: { root: classes.label },
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <div style={styles.label2}>
                <Checkbox
                  disabled={disabled}
                  type="checkbox"
                  data-cy={`${String(schema?.title).toLowerCase()}-signature`}
                  onChange={this.onChangeSig('signature')}
                  value="signature"
                  checked={signature}
                  color="primary"
                />

                <FormattedMessage id="signature" />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

RequestFormPerson.propTypes = {
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  formData: PropTypes.object,
  idSchema: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  schema: PropTypes.object.isRequired,
};

RequestFormPerson.defaultProps = {
  classes: {},
  disabled: false,
  formData: null,
};

export default withStyles(styles)(injectIntl(RequestFormPerson));

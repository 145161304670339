import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let TypeTransferencia = props => (
  <SvgIcon {...props} viewBox="0 0 34.016 34.016">
    <path
      fill="#EB6230"
      d="M11.642,28.154l5.96-4.226l-5.96-4.754v1.924H5.062v5.133h6.579
			V28.154z M11.642,10.194h10.526V7.628l-3.289-1.283V4.421c0-1.063-0.227-1.283-1.316-1.283h-1.315c-1.09,0-1.316,0.22-1.316,1.283
			v1.924l-3.29,1.283V10.194z M24.142,23.665h-1.974l-6.579-4.49V17.25l6.579-5.132h1.974v1.925h3.291V6.987
			c0-1.063-0.885-1.925-1.974-1.925h-5.265l3.291,1.283v5.132H10.326V6.345l3.289-1.283H8.353c-1.09,0-1.974,0.862-1.974,1.925
			v12.829h3.947V17.25h1.315l7.237,5.772v1.926l-7.237,5.131h13.817c1.089,0,1.974-0.861,1.974-1.925v-6.415h-3.291V23.665z
			 M6.379,28.154c0,1.063,0.883,1.925,1.974,1.925h1.974v-2.566H6.379V28.154z M22.826,15.325v-1.924l-5.959,4.755l5.959,4.225
			v-1.925h5.922v-5.131H22.826z"
    />
  </SvgIcon>
);
TypeTransferencia = pure(TypeTransferencia);
TypeTransferencia.displayName = 'TypeTransferencia';
TypeTransferencia.muiName = 'SvgIcon';

export default TypeTransferencia;

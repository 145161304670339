import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import ListRefresh from 'src/components/ListRefresh/ListRefresh';
import OrderItem from 'src/components/OrderItem/OrderItem';
import UserContext from 'src/contexts/UserContext';

class OrdersList extends Component {
  componentDidMount() {
    const { data, orderShowDetailsAction, orderIdFromUrl } = this.props;

    if (orderIdFromUrl && Array.isArray(data) && data.length) {
      const orderIndex = data.findIndex(order => String(order.id) === String(orderIdFromUrl));

      if (orderIndex !== -1) {
        orderShowDetailsAction(orderIndex);
      }
    }
  }

  refreshButton = () => {
    const { classes, refreshData, showListRefresh } = this.props;

    return (
      showListRefresh && (
        <ListSubheader classes={{ root: classes.refreshButton }}>
          <ListItem button disableRipple classes={{ default: classes.firstListItem }} onClick={refreshData}>
            <Grid item xs={12}>
              <ListRefresh />
            </Grid>
          </ListItem>
        </ListSubheader>
      )
    );
  };

  getItems = () => {
    const {
      checkOne,
      classes,
      data,
      fromPage,
      isClientPanel,
      orderShowDetailsAction,
      selectedCheckboxes,
      selectedItem,
    } = this.props;

    return data.map((inspection, index) => (
      <ListItem
        className="fade-in"
        key={inspection.id}
        button
        disableRipple
        disabled={inspection.id === null}
        classes={index === 0 ? { default: classes.firstListItem } : { default: classes.listItem }}
        onClick={e => orderShowDetailsAction(index, e)}
      >
        <Grid item xs={12}>
          <UserContext.Consumer>
            {user => (
              <OrderItem
                data={inspection}
                inspectionItemSelect={selectedItem === index}
                checkOne={checkOne}
                selectedCheckboxes={selectedCheckboxes}
                user={user}
                fromPage={fromPage}
                isClientPanel={isClientPanel || false}
              />
            )}
          </UserContext.Consumer>
        </Grid>
      </ListItem>
    ));
  };

  render() {
    const { hasMoreItems, loadMoreItems } = this.props;

    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMoreItems}
        hasMore={hasMoreItems}
        loader={<LinearProgress style={{ top: '10px' }} key="progress" />}
        useWindow={false}
      >
        {this.refreshButton()}
        {this.getItems()}
      </InfiniteScroll>
    );
  }
}

const styles = {
  firstListItem: {
    marginTop: 0,
    padding: '0px 1vw 0px 1vw',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  listItem: {
    marginTop: 12,
    padding: '0px 1vw 0px 1vw',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  refreshButton: {
    padding: 0,
    marginBottom: 12,
  },
};

OrdersList.propTypes = {
  orderIdFromUrl: PropTypes.string,
  orderShowDetailsAction: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  checkOne: PropTypes.func.isRequired,
  selectedCheckboxes: PropTypes.object.isRequired,
  selectedItem: PropTypes.number,
  loadMoreItems: PropTypes.func.isRequired,
  hasMoreItems: PropTypes.bool.isRequired,
  showListRefresh: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  fromPage: PropTypes.string.isRequired,
  refreshData: PropTypes.func,
  isClientPanel: PropTypes.bool,
};

OrdersList.defaultProps = {
  orderIdFromUrl: null,
  showListRefresh: false,
  selectedItem: null,
  refreshData: null,
  isClientPanel: false,
};

export default withStyles(styles)(OrdersList);

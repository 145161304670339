import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import MaskedInput from 'react-text-mask';

import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

import UserContext from 'src/contexts/UserContext';
import { masksByCountry } from 'src/helpers/mask';

const styles = {
  bootstrapInput: {
    borderRadius: 4,
    fontWeight: 'normal',
    border: '1px solid #ced4da',
    fontSize: 'calc(6px + 0.3vw)',
    padding: '8px 12px',
    width: 'calc(100% - 24px)',
    '&:focus': {
      borderColor: '#80bdff',
    },
    fontFamily: 'Open Sans',
  },
  label: {
    fontFamily: 'Open Sans',
    marginTop: '0.3vh',
    marginBottom: '0.5rem',
    fontSize: 'calc(6px + 0.3vw)',
  },
  label2: {
    display: 'flex',
    alignItems: 'center',
  },
};

class RequestFormPeople extends Component {
  static contextType = UserContext;

  constructor(props, context) {
    super(props, context);

    this.state = { ...props.formData };
  }

  componentDidUpdate(prevProps) {
    const { formData } = this.props;

    if (formData.name !== prevProps.formData.name || formData.signature !== prevProps.formData.signature) {
      this.setState({
        name: formData.name,
        signature: formData.signature,
      });
    }
  }

  onChange = (name, title) => {
    const { onChange } = this.props;

    return event => {
      this.setState(
        {
          [name]: event.target.value,
          title,
        },
        () => onChange(this.state)
      );
    };
  };

  onChangeSig = name => {
    const { onChange } = this.props;

    return event => {
      this.setState(
        {
          [name]: event.target.checked,
        },
        () => onChange(this.state)
      );
    };
  };

  DocumentElement = ({ inputRef, ...other }) => {
    const me = this.context;

    const cnpjMask = masksByCountry[me?.profile?.country || 'BR'].CNPJ_MASK;
    const cnpjMaskLength = masksByCountry[me?.profile?.country || 'BR'].CNPJ_MASK_LENGTH;
    const cpfMask = masksByCountry[me?.profile?.country || 'BR'].CPF_MASK;

    return (
      <MaskedInput
        {...other}
        ref={inputRef}
        guide={false}
        mask={(value, config) => {
          const lastCharacter = value[value.length - 1];
          const isLastCharacterANumber = /\d/.test(lastCharacter);
          if (isLastCharacterANumber) {
            return value.length > cnpjMaskLength ? cnpjMask : cpfMask;
          } else {
            return config?.previousConformedValue?.length > cnpjMaskLength ? cnpjMask : cpfMask;
          }
        }}
      />
    );
  };

  render() {
    let { document, name, signature } = this.state;

    const { classes, disabled, idSchema, schema, uiSchema } = this.props;

    if (name === undefined) {
      name = '';
    }

    if (document === undefined) {
      document = '';
    }

    if (signature === undefined) {
      signature = false;
    }

    return (
      <div>
        {schema.title && (
          <label style={styles.label} htmlFor={idSchema.$id}>
            {schema.title}
          </label>
        )}

        <Grid container alignItems="center">
          <Grid item xs={12}>
            <TextField
              disabled={disabled}
              id={idSchema.$id}
              fullWidth
              value={name}
              onChange={this.onChange('name', uiSchema['ui:title'])}
              data-cy={`${String(schema?.title || idSchema.$id).toLowerCase()}-name`}
              inputProps={{ placeholder: 'Nome' }}
              InputProps={{
                disableUnderline: true,
                classes: { input: classes.bootstrapInput },
              }}
              InputLabelProps={{
                shrink: true,
                classes: { root: classes.label },
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled={disabled}
              fullWidth
              inputProps={{ placeholder: 'Documento' }}
              data-cy={`${String(schema?.title || idSchema.$id).toLowerCase()}-document`}
              InputProps={{
                inputComponent: this.DocumentElement,
                value: document,
                onChange: this.onChange('document', schema.title),
                disableUnderline: true,
                classes: { input: classes.bootstrapInput },
              }}
              InputLabelProps={{
                shrink: true,
                classes: { root: classes.label },
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <div style={styles.label2}>
              <Checkbox
                disabled={disabled}
                type="checkbox"
                onChange={this.onChangeSig('signature')}
                value="signature"
                checked={signature}
                color="primary"
                data-cy={`${String(schema?.title || idSchema.$id).toLowerCase()}-signature`}
              />

              <FormattedMessage id="signature" />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

RequestFormPeople.propTypes = {
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  formData: PropTypes.object.isRequired,
  idSchema: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object.isRequired,
};

RequestFormPeople.defaultProps = {
  classes: {},
  disabled: false,
};

export default withStyles(styles)(RequestFormPeople);

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import API from 'src/apiRequest';
import InspectorListComponent from 'src/components/SchedulePage/Timeline/InspectorList/InspectorListComponent';
import {
  setInitialFilteredInspectors,
  setInspectors,
  setInspectorsAvailabilities,
  setInspectorsUnavailabilities,
} from 'src/store/ducks/schedule';

const InspectorListContainer = () => {
  const [busy, setBusy] = useState(false);

  const { date, inspectors } = useSelector(({ schedule }) => ({
    date: schedule.date,
    inspectors: schedule.filteredInspectors,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchInspectors = async () => {
      setBusy(true);

      try {
        const formattedSchedulesDate = date.format('YYYY-MM-DD');

        const schedulePromises = [
          API.get('/assignables'),
          API.get('/scheduler/inspector-unavailabilities', { params: { date: formattedSchedulesDate } }),
        ];

        const [{ data: assignables }, { data: unavailabilities }] = await Promise.all(schedulePromises);

        dispatch(setInitialFilteredInspectors(assignables));
        dispatch(setInspectors(assignables));
        dispatch(setInspectorsAvailabilities());
        dispatch(setInspectorsUnavailabilities(unavailabilities || []));
      } catch (err) {
        console.debug('InspectorListContainer.fetchInspectors error', err);
      } finally {
        setBusy(false);
      }
    };

    fetchInspectors();
  }, [date, dispatch]);

  return <InspectorListComponent busy={busy} inspectors={inspectors} />;
};

export default InspectorListContainer;

/* eslint-disable quotes */
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import PropTypes from 'prop-types';

import OrderIcon from 'src/components/OrderIcon/OrderIcon';
import OrderOptionsList from 'src/components/OrderOptionList/OrderOptionsList';
import InspectionAttach from 'src/inspectionAttach/InspectionAttach';
import PropertyAttach from 'src/inspectionAttach/PropertyAttach';
import ClientOrderFormPage from 'src/orderForm/ClientOrderForm/ClientOrderFormPage/ClientOrderFormPage';

class ClientNewOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeComponent: 'ORDERS_OPTIONS', // ORDERS_OPTIONS, ORDER_ATTACH, ORDER_FORM
      newOrderType: null, // Type of the new order
      previousOrderId: null,
      previousOrderFile: null,
      previousPropertyId: null,
    };
    this.handleOrderOptionSelect = this.handleOrderOptionSelect.bind(this);
    this.cancelNewOrder = this.cancelNewOrder.bind(this);
    this.orderAttachPanel = this.orderAttachPanel.bind(this);
    this.orderAttachFile = this.orderAttachFile.bind(this);
    this.orderSelectProperty = this.orderSelectProperty.bind(this);
  }

  UNSAFE_componentWillReceiveProps() {
    // eslint-disable-line
    this.cancelNewOrder();
  }

  /*
    Function to handle selection of a new order type from the order options
  */
  handleOrderOptionSelect(option) {
    if (
      option === 'saida' ||
      option === 'saidaCurtaTemporada' ||
      option === 'conferencia' ||
      option === 'aditivo' ||
      option === 'acompanhamentoObraMM' ||
      option === 'conferenciaFinalDeObra' ||
      option === 'intermediariaBHomy' ||
      option === 'revistoriaBrookfield'
    ) {
      this.setState({
        activeComponent: 'ORDER_ATTACH',
        newOrderType: option,
      });
    } else if (option === 'marketplace_docusign' || option === 'marketplace_fichacerta') {
      this.setState({
        activeComponent: 'ORDER_FORM',
        newOrderType: option,
      });
    } else {
      this.setState({
        activeComponent: 'ORDER_PROPERTY_ATTACH',
        newOrderType: option,
      });
    }
  }

  /*
    Function to cancel the order, close the form and open the orders options again
  */
  cancelNewOrder() {
    this.setState({
      activeComponent: 'ORDERS_OPTIONS',
      newOrderType: null,
      previousOrderId: null,
      previousOrderFile: null,
      previousPropertyId: null,
    });
  }

  /*
   * Function to hanlde select property
   */
  orderSelectProperty(option) {
    this.setState({
      activeComponent: 'ORDER_FORM',
      previousPropertyId: option?.public_id,
    });
  }

  /*
   * Function to hanlde the association of an order to one order of the panel
   */
  orderAttachPanel(option) {
    this.setState({
      activeComponent: 'ORDER_FORM',
      previousOrderId: option.id,
    });
  }

  /*
   * Function to hanlde the association of a file to an order
   */
  orderAttachFile(fileExtension, originalName, remoteName) {
    const previousOrderFile = {
      file: {
        original_name: originalName,
        remote_name: remoteName,
        extension: fileExtension,
      },
    };
    this.setState({
      previousOrderFile,
      activeComponent: 'ORDER_FORM',
    });
  }

  /*
   * Function to handle order creation without previous inspection
   */
  continueWithoutAttachment = option => {
    this.setState({
      activeComponent: 'ORDER_FORM',
      previousOrderId: null,
      previousPropertyId: null,
    });
  };

  render() {
    const { activeComponent, newOrderType, previousOrderFile, previousOrderId, previousPropertyId } = this.state;
    const { features, intl } = this.props;

    switch (activeComponent) {
      case 'ORDERS_OPTIONS':
        return (
          <OrderOptionsList
            features={features}
            handleOrderOptionSelect={this.handleOrderOptionSelect}
            withDescription
          />
        );
      case 'ORDER_FORM':
        return (
          <ClientOrderFormPage
            newOrderType={newOrderType}
            inspectionIcon={<OrderIcon orderType={newOrderType} fontSize="calc(16px + 0.8vw)" />}
            cancelNewOrder={this.cancelNewOrder}
            previousOrderId={previousOrderId}
            previousOrderFile={previousOrderFile}
            previousPropertyId={previousPropertyId}
          />
        );
      case 'ORDER_ATTACH': {
        let previousOrderType = null;
        let header = null;
        let previousType = null;

        if (newOrderType === 'saida') {
          previousOrderType = "['entrada','aditivo']";
          header = 'inspection.saida';
          previousType = intl.formatMessage({ id: 'entradaOrAditivo' });
        } else if (newOrderType === 'saidaCurtaTemporada') {
          previousOrderType = "['entradaCurtaTemporada']";
          header = 'inspection.saidaCurtaTemporada';
          previousType = intl.formatMessage({ id: 'entradaCurtaTemporada' });
        } else if (newOrderType === 'conferencia') {
          previousOrderType = "['saida','conferencia']";
          header = 'inspection.conferencia';
          previousType = intl.formatMessage({ id: 'saidaOrConferencia' });
        } else if (newOrderType === 'conferenciaFinalDeObra') {
          previousOrderType = "['finalDeObra','conferenciaFinalDeObra']";
          header = 'inspection.conferenciaFinalDeObra';
          previousType = intl.formatMessage({ id: 'finalDeObraOrConferenciaFinalDeObra' });
        } else if (newOrderType === 'aditivo') {
          previousOrderType = 'entrada';
          header = 'inspection.aditivo';
          previousType = intl.formatMessage({ id: previousOrderType });
        } else if (newOrderType === 'revistoriaBrookfield') {
          previousOrderType = "['brookfield', 'revistoriaBrookfield']";
          header = 'inspection.brookfield';
          previousType = intl.formatMessage({
            id: 'brookfieldOrRevistoria',
          });
        } else if (newOrderType === 'acompanhamentoObraMM') {
          previousOrderType = 'acompanhamentoObraMM';
          header = 'inspection.acompanhamentoObraMM';
          previousType = intl.formatMessage({ id: previousOrderType });
        } else if (newOrderType === 'intermediariaBHomy') {
          previousOrderType = "['entradaBHomy','intermediariaBHomy']";
          header = 'inspection.intermediariaBHomy';
          previousType = intl.formatMessage({ id: 'entradaBHomyOrIntermediariaBHomy' });
        }

        return (
          <InspectionAttach
            header={<FormattedMessage id={header} />}
            subHeader={<FormattedMessage id="inspectionAttach.entry" values={{ type: previousType }} />}
            searchPlaceholder={intl.formatMessage(
              {
                id: 'inspectionAttach.searchEntry',
              },
              {
                type: previousType.toLowerCase(),
              }
            )}
            apiUrl={`/orders?type=${previousOrderType}&status=sent&search=`}
            clientPanel
            handleSearchConfirm={this.orderAttachPanel}
            handleFileConfirm={this.orderAttachFile}
            handleContinueWithouAttachment={this.continueWithoutAttachment}
            previousType={previousType}
            newOrderType={newOrderType}
          />
        );
      }
      case 'ORDER_PROPERTY_ATTACH':
        return (
          <PropertyAttach
            header={<FormattedMessage id="selectPropertyHeader" />}
            subHeader={<FormattedMessage id="selectPropertySubHeader" />}
            searchPlaceholder={intl.formatMessage({
              id: 'selectProperty',
            })}
            apiUrl="client-properties"
            clientPanel
            handleSearchConfirm={this.orderSelectProperty}
            handleContinueWithouAttachment={this.continueWithoutAttachment}
          />
        );
      default:
        return null;
    }
  }
}

ClientNewOrder.propTypes = {
  /**
   * Array of the features enable for the current user
   */
  features: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(ClientNewOrder);

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let TypeSaidaCurtaTemporada = props => (
  <SvgIcon {...props} fill="none" viewBox="0 0 109 119">
    <path
      d="M107.385 72.9982L94.514 62.9931V19.8158C94.514 13.2105 89.0258 7.86805 82.3719 7.86805H60.759C60.3219 7.86805 59.8848 7.9652 59.3991 8.11091C59.0591 2.37984 56.2422 0 49.9768 0H44.5372C38.2719 0 35.4549 2.37984 35.1149 8.11091C34.6778 7.9652 34.2407 7.86805 33.755 7.86805H12.1421C5.39109 7.86805 0 13.2105 0 19.8158V106.462C0 113.019 5.43966 118.41 12.1421 118.41H82.3719C89.0744 118.41 94.514 113.019 94.514 106.462V94.6111C94.514 93.1541 94.514 88.103 94.514 88.103L107.142 79.5064C108.21 78.7778 108.842 77.6122 108.89 76.3008C108.939 75.0381 108.405 73.7753 107.385 72.9982ZM39.1461 17.2417V9.37366C39.1461 5.0025 40.0689 4.12827 44.5372 4.12827H49.9768C54.4451 4.12827 55.3679 5.0025 55.3679 9.37366V17.2417L68.8699 22.4871V33.0264H25.6441V22.4871L39.1461 17.2417ZM90.4343 56.5821L79.6035 48.7141H74.2124V59.2048H33.755V90.7256H74.2124V101.216H79.6035L90.4343 94.6597V106.51C90.4343 110.833 86.7916 114.378 82.3719 114.378H12.1421C7.6738 114.378 4.07974 110.882 4.07974 106.51V19.8644C4.07974 15.4933 7.6738 11.9963 12.1421 11.9963H33.755L20.253 17.2417V38.2233H74.2124V17.2417L60.7104 11.9963H82.3234C86.7916 11.9963 90.3857 15.4933 90.3857 19.8644V56.5821H90.4343Z"
      fill="#E59F33"
    />
  </SvgIcon>
);

TypeSaidaCurtaTemporada = pure(TypeSaidaCurtaTemporada);
TypeSaidaCurtaTemporada.displayName = 'TypeSaidaCurtaTemporada';
TypeSaidaCurtaTemporada.muiName = 'SvgIcon';

export default TypeSaidaCurtaTemporada;

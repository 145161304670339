import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const ReportItemDownloadAll = props => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.80005 5.40107C1.80005 4.40696 2.60594 3.60107 3.60005 3.60107H7.20005L9.00005 5.40107H12.6C13.5942 5.40107 14.4 6.20696 14.4 7.20107V8.10107H7.20005C5.70888 8.10107 4.50005 9.30991 4.50005 10.8011V12.1511C4.50005 12.8967 3.89563 13.5011 3.15005 13.5011C2.40446 13.5011 1.80005 12.8966 1.80005 12.1511V5.40107Z"
      fill="#0485BE"
    />
    <path
      d="M5.40005 10.8011C5.40005 9.80696 6.20594 9.00107 7.20005 9.00107H14.4C15.3942 9.00107 16.2 9.80696 16.2 10.8011V12.6011C16.2 13.5952 15.3942 14.4011 14.4 14.4011H1.80005H3.60005C4.59416 14.4011 5.40005 13.5952 5.40005 12.6011V10.8011Z"
      fill="#0485BE"
    />
  </SvgIcon>
);

ReportItemDownloadAll.displayName = 'ReportItemDownloadAll';
ReportItemDownloadAll.muiName = 'SvgIcon';

export default ReportItemDownloadAll;

import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import ReportProblem from '@material-ui/icons/ReportProblem';
import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import AddMedia from 'src/components/AddMedia';
import FixAddress from 'src/components/FixAddress';
import OrderIcon from 'src/components/OrderIcon/OrderIcon';
import ApiContext from 'src/contexts/ApiContext';
import UserContext from 'src/contexts/UserContext';
import getCommercialPanelOpenSaleLink from 'src/helpers/get-commercial-panel-open-sale-link';
import Loading from 'src/Loading';
import FooterMessage from 'src/orderDetails/FooterMessage';
import OrderDetailsBody from 'src/orderDetails/OrderDetailsBody';
import OrderDetailsFooter from 'src/orderDetails/OrderDetailsFooter';
import OrderDetailsHeader from 'src/orderDetails/OrderDetailsHeader';
import { fetchInspectionById, clearCurrentInspection } from 'src/store/ducks/inspections';
import { fetchOrderById, clearCurrentOrder } from 'src/store/ducks/orders';
import { showToast } from 'src/store/ducks/toasts';

const styles = {
  container: {
    alignItems: 'center',
    paddingLeft: '1.5vw',
    paddingRight: '1.5vw',
  },
  paper: {
    margin: '0 1vw 1vw 1vw',
    paddingTop: '0.5vw',
    minHeight: 'calc(100% - 4px)',
  },
  footerMessage: {
    color: '#858585',
    width: '80%',
    margin: '1rem auto 0',
  },
  footerErrorMessage: {
    color: 'red',
    width: '80%',
    margin: '1rem auto 0',
  },
  fallbackErrorMessage: {
    fontWeight: '400',
    fontSize: 'calc(16px + 0.4vw)',
    fontFamily: 'Open Sans',
    lineHeight: 1.4,
    textAlign: 'center',
    margin: '6vh auto 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

class OrderDetails extends Component {
  static contextType = UserContext;

  constructor(props, context) {
    super(props, context);
    const { currentPage, intl, orderStatus } = props;

    this.state = {
      loading: true,
      data: {},
      newStatus: null,
      message: '',
      currentPage,
      orderStatus,
      footerErrorMessage: null,
      apiStatus: 'IDLE',
      fallbackErrorMessage: intl.formatMessage({ id: 'ORDER_LOAD_ERROR_MESSAGE' }),
      requestInProgress: false,
    };
  }

  componentDidMount() {
    const { orderId } = this.props.match.params;
    this.props.clearCurrentOrder();
    this.props.clearCurrentInspection();

    this.requestOrderDetails();

    if (orderId) {
      this.props.notifyExistingOrderIdInUrl(orderId);
    }
  }

  componentDidUpdate(prevProps) {
    const { orderId: prevOrderId } = prevProps.match.params;
    const { currentOrder: prevCurrentOrder, currentOrderError: prevCurrentOrderError } = prevProps.ordersState;
    const { currentInspection: prevCurrentInspection, currentInspectionError: prevCurrentInspectionError } =
      prevProps.inspectionsState;

    const { orderId } = this.props.match.params;
    const { currentOrder, currentOrderError } = this.props.ordersState;
    const { currentInspection, currentInspectionError } = this.props.inspectionsState;

    if (orderId !== prevOrderId) {
      this.requestOrderDetails();
    }

    if (currentOrder && currentOrder !== prevCurrentOrder && !currentOrderError) {
      const { currentPageByOrderStatus, orderStatus } = this.props;
      const { currentPage } = this.state;

      let currentOrderStatus = orderStatus;
      let newCurrentPage = currentPage;

      if (currentOrder?.status) {
        currentOrderStatus = currentOrder?.status;
      }

      if (currentPageByOrderStatus) {
        newCurrentPage = currentPageByOrderStatus.get(currentOrderStatus);
      }

      this.setState({
        data: currentOrder,
        loading: false,
        newStatus: null,
        preview: false,
        message: '',
        currentPage: newCurrentPage,
        orderStatus: currentOrder?.is_archived ? 'archived' : currentOrderStatus,
      });
    }

    if (currentInspection && currentInspection !== prevCurrentInspection && !currentInspectionError) {
      const { currentPageByOrderStatus, orderStatus } = this.props;
      const { currentPage } = this.state;

      let currentInspectionStatus = orderStatus;
      let newCurrentPage = currentPage;

      if (currentPageByOrderStatus) {
        newCurrentPage = currentPageByOrderStatus.get(currentInspectionStatus);
      }

      this.setState({
        data: currentInspection,
        loading: false,
        newStatus: null,
        preview: false,
        message: '',
        currentPage: newCurrentPage,
        orderStatus: currentInspectionStatus,
      });
    }

    if (currentOrderError && currentOrderError !== prevCurrentOrderError) {
      const { intl } = this.props;

      if (currentOrderError?.response?.status && currentOrderError?.response?.status === 403) {
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({
            id: 'ORDER_PERMISSION_ERROR_MESSAGE',
          }),
          duration: 5000,
        });

        this.setState({
          data: {},
          loading: false,
          fallbackErrorMessage: intl.formatMessage({
            id: 'ORDER_PERMISSION_ERROR_MESSAGE',
          }),
        });
      } else {
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'ORDER_LOAD_ERROR_MESSAGE' }),
          duration: 5000,
        });

        this.setState({ data: {}, loading: false });
      }
    }

    if (currentInspectionError && currentInspectionError !== prevCurrentInspectionError) {
      this.props.showError();
    }
  }

  requestOrderDetails = () => {
    const { history } = this.props;
    const { params, url } = this.props.match;
    const { currentPage } = this.state;

    let type = 'orders';

    if (currentPage === 'independent' || url.includes('inspections')) {
      type = 'inspections';
    }

    if (!params.orderId) {
      history.push(`/${type}`);

      return;
    }

    this.setState({ loading: true });

    if (type === 'orders') {
      this.props.fetchOrderById(params.orderId);

      return;
    }

    this.props.fetchInspectionById(params.orderId);
  };

  marketPlace = (type, data) => {
    const { changePage, refreshData } = this.props;
    const { orderId } = this.props.match.params;

    if (type && data) {
      switch (type) {
        case 'DOCUSIGN_ACCEPTED':
          this.setState({ apiStatus: 'WORKING' });
          data.form.status = 'sent';
          API.put(
            `orders/${orderId}`,
            JSON.stringify({
              type: data.type,
              ...data.form,
            })
          )
            .then(() => {
              this.setState({ apiStatus: 'IDLE' });
              if (typeof refreshData === 'function') refreshData();
              if (typeof changePage === 'function') changePage('orders_list');
            })
            .catch(error => {
              this.setState({ apiStatus: 'ERROR' });
              console.log(error);
            });
          break;
        default:
          break;
      }
    }
  };

  /**
   * Function to change the order status
   */
  changeOrderStatus = action => {
    const { address, message, newStatus } = this.state;
    const { refreshData, rejectOrder, updateOrderItem } = this.props;
    const { orderId } = this.props.match.params;

    if (action !== null) {
      switch (newStatus) {
        case 'APPROVE':
          this.setState({ requestInProgress: true });

          API.post(`orders/${orderId}/status`, {
            action: 'approve',
            message,
            address: address || undefined,
          })
            .then(res => {
              const data = { ...res.data };
              updateOrderItem(data);
              this.setState({
                newStatus: null,
                message: '',
                footerErrorMessage: null,
                orderStatus: data.status,
              });
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.setState({ requestInProgress: false });
            });
          break;
        case 'SEND':
          this.setState({ requestInProgress: true });

          API.post(`orders/${orderId}/status`, {
            action: 'send',
          })
            .then(() => {
              refreshData();
            })
            .catch(error => {
              let m = null;
              switch (error.response.data.error) {
                case 'INVALID_CREDITS_ZERO':
                  m = { id: 'MESSAGE.INVALID_CREDITS_ZERO' };
                  break;
                case 'OFFICIAL_INSPECTION_NULL':
                  m = { id: 'MESSAGE.OFFICIAL_REQUIRED' };
                  break;
                case 'SINGLE_ORDER_PURSCHASE_IS_OPEN':
                  m = { id: 'MESSAGE.SINGLE_ORDER_PURSCHASE_IS_OPEN', saleId: error.response.data.sale_id };
                  break;
                default:
                  m = { id: 'MESSAGE.TRANSITION_FAILED' };
                  break;
              }
              this.setState({ footerErrorMessage: m });
            })
            .finally(() => {
              this.setState({ requestInProgress: false });
            });
          break;
        case 'REJECT':
          rejectOrder(message);
          break;
        default:
          this.setState({
            newStatus: action,
          });
      }
    } else {
      this.setState({
        newStatus: null,
        message: '',
        footerErrorMessage: null,
      });
    }
  };

  resendEvelope = async (evt, className) => {
    const { orderId } = this.props.match.params;
    const { currentTarget: target } = evt;
    const payload = { type: 'docusign', action: 'resend' };

    if (target.disabled) return;

    try {
      this.setState({ apiStatus: 'WORKING_RESEND' });
      await API.put(`orders/${orderId}`, payload);
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ apiStatus: 'IDLE' });
      target.classList.add(className);
      target.disabled = true;
    }
  };

  resyncEvelope = async (evt, className) => {
    const { orderId } = this.props.match.params;
    const { currentTarget: target } = evt;

    if (target.disabled) return;

    try {
      this.setState({ apiStatus: 'WORKING_RESYNC' });
      await API.put(`orders/${orderId}/sync`);
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ apiStatus: 'IDLE' });
      target.classList.add(className);
      target.disabled = true;
    }
  };

  onChangeMessage = value => {
    this.setState({
      message: value,
    });
  };

  onChangeAddress = value => {
    this.setState({
      address: value,
    });
  };

  onChangeMedia = values => {
    this.setState({
      medias: values,
    });
  };

  saveMedia = async () => {
    const { data: order, medias } = this.state;
    try {
      await API.post(
        `/orders/${order.id}/medias`,
        medias
          .filter(media => media.isValid)
          .map(media => ({ external_uri: media.external_uri, external_id: media.external_id, type: media.type }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const {
      apiStatus,
      currentPage: currentPageState,
      data,
      footerErrorMessage,
      loading,
      message,
      newStatus,
      orderStatus,
      preview,
      fallbackErrorMessage,
      address,
      medias,
      requestInProgress,
    } = this.state;
    const {
      actionsMenuGeneratePDF,
      changePage,
      classes,
      clientPanel,
      openAssignDevice,
      openAssignOrder,
      orderEdit,
      readonly,
      updateOrderItem,
    } = this.props;
    const { franchiseeId: userFranchisee } = this.context;

    const isFromTheSameFranchisee = userFranchisee === data.form?.franchisees?.franchisee_id || !data.form?.franchisees;
    let footerMessage;

    if (isFromTheSameFranchisee || clientPanel) {
      if (footerErrorMessage !== null) {
        let footerMessageValues = null;

        if (
          footerErrorMessage.id === 'MESSAGE.SINGLE_ORDER_PURSCHASE_IS_OPEN' &&
          footerErrorMessage.saleId
        ) {
          footerMessageValues = {
            saleId: (
              <a
                href={getCommercialPanelOpenSaleLink(footerErrorMessage.saleId)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {footerErrorMessage.saleId}
              </a>
            ),
          };
        }

        footerMessage = (
          <p style={styles.footerErrorMessage}>
            <small>
              <FormattedMessage
                id={footerErrorMessage.id}
                values={footerMessageValues}
              />
            </small>
          </p>
        );
      } else if (newStatus === 'REJECT') {
        footerMessage = <FooterMessage value={message} onChange={this.onChangeMessage} newStatus={newStatus} />;
      } else if (newStatus === 'APPROVE') {
        const { address_validation: addressValidation } = data;
        const me = JSON.parse(sessionStorage.getItem('me'));
        footerMessage = [
          <FooterMessage value={message} onChange={this.onChangeMessage} newStatus={newStatus} key="footer-message" />,
        ];

        if (
          me.features.indexOf('SCHEDULE') !== -1 &&
          (addressValidation?.state === 'invalid' ||
            addressValidation?.city === 'invalid' ||
            addressValidation?.neighborhood === 'invalid')
        ) {
          footerMessage.unshift(<FixAddress orderData={data} onChange={this.onChangeAddress} key="fix-address" />);
        }
      } else if (newStatus === 'SEND' && data.type === 'tourVirtual' && (data?.medias || []).length < 1) {
        footerMessage = [<AddMedia onChange={this.onChangeMedia} key="add-media" />];
      }
    }

    let content;

    if (loading) {
      content = <Loading clientPanel={clientPanel} />;
    } else {
      content = (
        <Paper style={styles.paper} elevation={0}>
          {data && Object.keys(data).length ? (
            <Grid container classes={{ container: classes.container }}>
              <OrderDetailsHeader
                data={data}
                inspectionIcon={data.type && <OrderIcon orderType={data.type} fontSize="calc(16px + 0.8vw)" />}
              />

              <OrderDetailsBody
                data={data}
                currentPage={currentPageState}
                refreshHandler={this.requestOrderDetails}
                actionsMenuGeneratePDF={actionsMenuGeneratePDF}
                updateOrderItem={updateOrderItem}
              />

              {(isFromTheSameFranchisee || clientPanel) && (
                <OrderDetailsFooter
                  data={data}
                  address={address}
                  changePage={changePage}
                  changeOrderStatus={this.changeOrderStatus}
                  openAssignDevice={openAssignDevice}
                  openAssignOrder={openAssignOrder}
                  currentPage={currentPageState}
                  orderStatus={orderStatus}
                  orderEdit={orderEdit}
                  resendEnvelope={this.resendEvelope}
                  resyncEnvelope={this.resyncEvelope}
                  newStatus={newStatus}
                  preview={preview}
                  backToOrder={this.requestOrderDetails}
                  readonly={readonly}
                  marketPlace={this.marketPlace}
                  requestInProgress={requestInProgress}
                  saveMedia={this.saveMedia}
                  medias={(data?.medias || []).length > 0 ? data.medias : medias}
                >
                  {footerMessage}
                </OrderDetailsFooter>
              )}
            </Grid>
          ) : (
            <div style={styles.fallbackErrorMessage}>
              <ReportProblem color="error" style={{ fontSize: 50 }} />

              <p>{fallbackErrorMessage}</p>
            </div>
          )}
        </Paper>
      );
    }

    return <ApiContext.Provider value={{ status: apiStatus }}>{content}</ApiContext.Provider>;
  }
}

OrderDetails.propTypes = {
  notifyExistingOrderIdInUrl: PropTypes.func,
  updateOrderItem: PropTypes.func,
  rejectOrder: PropTypes.func,
  openAssignDevice: PropTypes.func,
  openAssignOrder: PropTypes.func,
  currentPageByOrderStatus: PropTypes.object,
  actionsMenuGeneratePDF: PropTypes.func,
  changePage: PropTypes.func,
  classes: PropTypes.object,
  clientPanel: PropTypes.bool,
  currentPage: PropTypes.string,
  history: PropTypes.object.isRequired,
  orderEdit: PropTypes.func,
  orderStatus: PropTypes.string,
  readonly: PropTypes.bool,
  refreshData: PropTypes.func,
  showError: PropTypes.func,
  showToast: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  ordersState: PropTypes.shape({
    currentOrder: PropTypes.object,
    currentOrderError: PropTypes.object,
  }).isRequired,
  inspectionsState: PropTypes.shape({
    currentInspection: PropTypes.object,
    currentInspectionError: PropTypes.object,
  }).isRequired,
  fetchOrderById: PropTypes.func.isRequired,
  fetchInspectionById: PropTypes.func.isRequired,
  clearCurrentOrder: PropTypes.func.isRequired,
  clearCurrentInspection: PropTypes.func.isRequired,
};

OrderDetails.defaultProps = {
  actionsMenuGeneratePDF: undefined,
  changePage: undefined,
  classes: {},
  clientPanel: false,
  notifyExistingOrderIdInUrl: () => { },
  orderEdit: undefined,
  orderStatus: '',
  readonly: false,
  refreshData: undefined,
  rejectOrder: undefined,
  openAssignDevice: undefined,
  openAssignOrder: undefined,
  showError: undefined,
  updateOrderItem: undefined,
  currentPage: '',
  currentPageByOrderStatus: undefined,
  ordersState: {
    currentOrder: null,
    currentOrderError: null,
  },
  inspectionsState: {
    currentInspection: null,
    currentInspectionError: null,
  },
};

const mapStateToProps = state => ({
  inspectionsState: state.inspections,
  ordersState: state.orders,
});

const mapDispatchToProps = {
  showToast,
  fetchOrderById,
  fetchInspectionById,
  clearCurrentOrder,
  clearCurrentInspection,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(withRouter(OrderDetails))));

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let Forward = props => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    className="si-glyph si-glyph-circle-forward"
    viewBox="0 -0.5 17 17"
    {...props}
  >
    <path
      fill="#858585"
      fillRule="evenodd"
      d="M.076 7.989c0 4.401 3.562 7.969 7.955 7.969 4.394 0 7.955-3.567 7.955-7.969S12.424.02 8.031.02C3.639.021.076 3.588.076 7.989zm3.914 2.105c0-2.528 1.169-4.264 3.785-4.264l.256.001V4.198c.213-.214.463-.179.678.036l3.229 2.422a.552.552 0 010 .775L8.709 9.853a.488.488 0 01-.678-.035V8.22l-.236-.001c-1.816 0-2.439.677-3.232 2.126-.167.308-.573.63-.573-.251z"
      className="si-glyph-fill"
    ></path>
  </SvgIcon>
);

Forward = pure(Forward);
Forward.displaName = 'Forward';
Forward.muiName = 'SvgIcon';

export default Forward;

import React from 'react';
import Select from 'react-select';

import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

import classes from 'src/components/CommercialPage/ClientAssignForm/style.module.scss';
import ClientDetails from 'src/components/CommercialPage/ClientDetails';
import Card from 'src/components/common/Card';
import ColorButton from 'src/components/common/elements/ColorButton';
import inspectionTypesIcon from 'src/components/common/icons/records.svg';
import userPermissionImage from 'src/components/common/icons/user-permission.svg';
import Spinner from 'src/components/common/Spinner';

const ClientAssignFormComponent = ({
  busy,
  requestInProgress,
  client,
  franchisees,
  handleChangeFranchisee,
  handleChangeMailable,
  onSubmit,
  intl,
}) =>
  busy ? (
    <div className={classes.clientAssignForm}>
      <div className={classes.spinner}>
        <Spinner />
      </div>
    </div>
  ) : (
    <>
      <ClientDetails busy={busy} client={client} intl={intl} />
      <div className={classes.clientAssignForm}>
        <Card className="fade-in">
          <h2 className={classes.cardHead}>
            <div className={classes.icon}>
              <img src={userPermissionImage} alt={intl.formatMessage({ id: 'ASSIGN_CUSTOMER_MESSAGE' })} />
            </div>
            {intl.formatMessage({ id: 'ASSIGN_CUSTOMER_MESSAGE' })}
          </h2>
          <form onSubmit={onSubmit}>
            <h3 className={classes.subtitle}>
              <div className={classes.icon}>
                <img src={inspectionTypesIcon} alt={intl.formatMessage({ id: 'FRANCHISES' })} />
              </div>
              {intl.formatMessage({ id: 'FRANCHISES' })}
            </h3>
            <div className={classes.field}>
              <Select
                id={classes.commercialFranchiseesSelect}
                name="franchisee"
                placeholder={intl.formatMessage({
                  id: 'SELECT_ONE_FRANCHISE_MESSAGE',
                })}
                classNamePrefix="select"
                isClearable
                required
                options={franchisees}
                noOptionsMessage={() => intl.formatMessage({ id: 'NO_FRANCHISES_AVAILABLE_MESSAGE' })}
                isDisabled={!franchisees.length}
                onChange={handleChangeFranchisee}
              />
            </div>
            <div className={classes.checkbox}>
              <Checkbox
                id="mailable"
                type="checkbox"
                className={classes.inputCheckbox}
                color="primary"
                onChange={handleChangeMailable}
                disabled
                style={{ opacity: 0.5 }}
              />
              <label htmlFor="mailable" style={{ opacity: 0.5 }}>
                {intl.formatMessage({ id: 'SEND_EMAIL_WITH_USER_MESSAGE' })}
              </label>
            </div>
            <div className={classes.cardFooter}>
              <ColorButton type="submit" color="green" isBusy={busy}>
                {requestInProgress ? (
                  <CircularProgress size={20} className={classes.Spinner} />
                ) : (
                  intl.formatMessage({ id: 'SAVE' })
                )}
              </ColorButton>
            </div>
          </form>
        </Card>
      </div>
    </>
  );

ClientAssignFormComponent.propTypes = {
  busy: PropTypes.bool.isRequired,
  requestInProgress: PropTypes.bool.isRequired,
  client: PropTypes.object,
  franchisees: PropTypes.array,
  handleChangeFranchisee: PropTypes.func.isRequired,
  handleChangeMailable: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

ClientAssignFormComponent.defaultProps = {
  client: null,
  franchisees: [],
};

export default ClientAssignFormComponent;

import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import PropTypes from 'prop-types';

import api from 'src/apiRequest';
import HomeComponent from 'src/components/MarketplacePage/Home/HomeComponent';

const HomeContainer = ({ filterEnabled }) => {
  const [services, setServices] = useState([]);
  const [busy, setBusy] = useState(true);
  const intl = useIntl();

  useEffect(() => {
    setBusy(true);
    const marketplaceRequest = api.get('marketplace');
    const clientsRequest = api.get('erp/services');
    const me = JSON.parse(window.sessionStorage.getItem('me'));

    Promise.allSettled([marketplaceRequest, clientsRequest])
      .then(values => {
        const [
          {
            value: { data: marketplaceData },
          },
          {
            value: { data: clientsData },
          },
        ] = values;
        const mergedData = [
          ...marketplaceData.map(service => ({
            ...service,
            type: 'marketplace',
            disabledByModality: false,
          })),
          ...clientsData.map(service => ({
            ...service,
            type: 'erp',
            disabledByModality: me.disabledByModality,
          })),
        ];

        setServices(
          !filterEnabled ? mergedData : mergedData.filter(service => service.status === 'ENABLED' || service.enabled)
        );
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setBusy(false);
      });
  }, [filterEnabled]);

  return <HomeComponent services={services} busy={busy} intl={intl} />;
};

HomeContainer.propTypes = {
  filterEnabled: PropTypes.bool,
};

HomeContainer.defaultProps = {
  filterEnabled: false,
};

export default HomeContainer;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';

import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

import classes from './style.module.scss';

const Option = ({ innerRef, isDisabled, isFocused, isSelected, innerProps, children }) => (
  <MenuItem
    buttonRef={innerRef}
    selected={isFocused}
    component="div"
    style={{
      fontWeight: isSelected ? 500 : 400,
    }}
    disabled={isDisabled}
    {...innerProps}
  >
    {children}
  </MenuItem>
);

Option.propTypes = {
  innerRef: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  innerProps: PropTypes.object,
  children: PropTypes.object
};

const LoadingMessage = ({ getStyles, ...props }) => (
  <div {...props} style={getStyles('loadingMessage', props)}>
    <FormattedMessage id="loading" />
  </div>
);

LoadingMessage.propTypes = {
  getStyles: PropTypes.func.isRequired
};

const components = {
  Option,
  LoadingMessage,
};

const InspectorsFilterComponent = ({ suggestions, isBusy, searchPlaceholder, handleChange }) => (
  <div className={classes.Search}>
    <Select
      options={suggestions.map(suggestion => ({
        value: suggestion.public_id,
        label: suggestion.name,
      }))}
      isOptionDisabled={option => option.disabled}
      components={components}
      name="inspector"
      onChange={handleChange}
      placeholder={searchPlaceholder}
      isLoading={isBusy}
      backspaceRemovesValue={false}
      autoFocus
    />
  </div>
);

InspectorsFilterComponent.propTypes = {
  suggestions: PropTypes.array.isRequired,
  isBusy: PropTypes.bool.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default InspectorsFilterComponent;

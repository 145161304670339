import React from 'react';
import { FormattedMessage } from 'react-intl';

import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  paper: {
    textAlign: 'center',
    backgroundColor: '#D7D7D7',
  },
};

function ListRefresh(props) {
  return (
    <Paper style={styles.paper} elevation={0}>
      <FormattedMessage id="BUTTON.NEW_ITEMS_NOTIFICATION" />
    </Paper>
  );
}

export default withStyles(styles)(ListRefresh);

import React from 'react';

import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import classes from 'src/components/Reactivation/style.module.scss';

const ReactivationComponent = ({ error, handleSubmit, validationSchema, intl }) => (
  <div className={classes.reactivation}>
    <h3>{intl.formatMessage({ id: 'REACTIVATE_ORDER' })}</h3>
    <Formik initialValues={{ reason: '' }} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {({ errors, isSubmitting }) => (
        <Form>
          <div className={classes.field}>
            <label htmlFor="reason">{intl.formatMessage({ id: 'REASON' })}</label>
            <Field name="reason" id="reason" as="textarea" />
            {(error || errors.reason) && <div className={classes.error}>{error || errors.reason}</div>}
          </div>
          <div className={`${classes.field} ${classes.actions}`}>
            <button className={`${classes.button} ${classes.isGreen}`} type="submit" disabled={isSubmitting}>
              {intl.formatMessage({ id: 'SEND' })}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  </div>
);

ReactivationComponent.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

ReactivationComponent.defaultProps = {
  error: null,
};

export default ReactivationComponent;

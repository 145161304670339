import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Moment from 'react-moment';

import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import classes from 'src/components/ReportHistory/ReportHistory.module.css';
import Download from 'src/svg-icons/Download';
import ExpandLess from 'src/svg-icons/ExpandLess';
import ExpandMore from 'src/svg-icons/ExpandMore';
import History from 'src/svg-icons/History';
import Shield from 'src/svg-icons/Shield';

const ReportHistory = ({ data }) => {
  const reports = Object.values(data).sort((a, b) => parseFloat(b.version) - parseFloat(a.version));
  const [open, setOpen] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const intl = useIntl();

  let moreButton = null;

  const showTypeHandler = (version, show) => {
    const openStatus = { ...open };
    openStatus[version] = show;

    setOpen(openStatus);
  };

  const showMoreHandler = show => {
    setIsExpanded(show);
  };

  const formatReportVersion = reportData => {
    if (['divergence_v2', 'full_divergence', 'simple_divergence'].includes(reportData.type)) {
      return `${intl.formatMessage({ id: 'DIVERGENCE_REPORT' })}`;
    }
    const { tags } = reportData;
    const [modality, type] = reportData.type.split('_');
    let format;
    if (modality === 'fc') {
      format = intl.formatMessage({ id: 'fichaCerta' });
    } else {
      format =
        modality === 'full' ? intl.formatMessage({ id: 'WITH_PHOTOS' }) : intl.formatMessage({ id: 'WITHOUT_PHOTOS' });
    }
    let verboseType;
    let verboseTags;
    switch (type) {
      case 'report':
        verboseType = '';
        break;
      case 'damages':
        verboseTags = tags ? tags.map(tag => intl.formatMessage({ id: `TAG.${tag.toUpperCase()}` })).join(', ') : null;
        verboseType = intl.formatMessage({ id: 'NON_CONFORMITIES' });
        verboseType = verboseTags ? `${verboseType} (${verboseTags.replace(/,([^,]*)$/, ' e$1')})` : verboseType;
        break;
      default:
        verboseType = type.charAt(0).toUpperCase() + type.slice(1);
    }
    return `${intl.formatMessage({ id: 'REPORT' })} ${verboseType} ${format}`;
  };

  const showHashHandler = async (event, hash) => {
    try {
      await navigator.clipboard.writeText(hash);
      alert(intl.formatMessage({ id: 'INTEGRITY_CODE_COPIED' }));
    } catch (err) {
      alert(intl.formatMessage({ id: 'INTEGRITY_CODE_COPY_FAILED' }, { hash }));
      console.error('Async: Could not copy text: ', err);
    }
  };

  if (reports.length > 1) {
    if (isExpanded === false) {
      moreButton = (
        <button type="button" className={classes.ShowMoreButton} onClick={() => showMoreHandler(true)}>
          <FormattedMessage id="SHOW_MORE" />
        </button>
      );
    } else {
      moreButton = (
        <button type="button" className={classes.ShowMoreButton} onClick={() => showMoreHandler(false)}>
          <FormattedMessage id="SHOW_LESS" />
        </button>
      );
    }
  }

  return (
    <Grid item xs={12}>
      <div className={classes.Header}>
        <History viewBox="0 0 34.016 34.016" className={classes.Icon} />
        <FormattedMessage id="report_history" />
      </div>
      <div className={classes.ListWrapper}>
        <ul>
          {reports.map((version, index) => {
            const versionName =
              index === 0 ? `${version.version} (${intl.formatMessage({ id: 'ACTUAL' })})` : version.version;

            let versionComponent = (
              <li key={version.hash} data-cy={`order-details-report-${version.hash}-version`}>
                <p className={classes.VersionTitle} data-cy={`order-details-report-${version.hash}-title`}>
                  <span>{`${intl.formatMessage({
                    id: 'VERSION',
                  })} ${versionName}`}</span>
                  <span data-cy={`order-details-report-${version.hash}-date`}>
                    <Moment format="DD/MM - HH:mm">{version.date}</Moment>
                  </span>
                  <span className={classes.ListAction}>
                    {open[version.version] ? (
                      <ExpandLess onClick={() => showTypeHandler(version.version, false)} />
                    ) : (
                      <ExpandMore onClick={() => showTypeHandler(version.version, true)} />
                    )}
                  </span>
                </p>
                <Collapse in={open[version.version] === true} timeout="auto" unmountOnExit>
                  <ul data-cy={`order-details-report-${version.hash}-files`}>
                    {version?.files?.map(file => (
                      <li key={file.hash}>
                        <p>
                          <span>{formatReportVersion(file)}</span>
                          <span style={{ margin: '0 .5em', whiteSpace: 'nowrap' }}>
                            <Moment format="DD/MM - HH:mm">{file.date}</Moment>
                          </span>
                          <span className={classes.ListAction}>
                            <a href={file.file} target="_blank" rel="noopener noreferrer">
                              <Download />
                            </a>
                            <Shield className={classes.IntegrityCode} onClick={e => showHashHandler(e, file.hash)} />
                          </span>
                        </p>
                      </li>
                    ))}
                  </ul>
                </Collapse>
              </li>
            );
            if (index !== 0) {
              versionComponent = (
                <Collapse in={isExpanded} timeout="auto" unmountOnExit key="collapsed">
                  {versionComponent}
                </Collapse>
              );
            }
            return versionComponent;
          })}
        </ul>
        {moreButton}
      </div>
    </Grid>
  );
};

ReportHistory.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ReportHistory;

import React from 'react';
import Select, { components } from 'react-select';

import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';

import Card from 'src/components/common/Card';
import Checkbox from 'src/components/common/elements/Checkbox';
import ColorButton from 'src/components/common/elements/ColorButton';
import situationCircleImage from 'src/components/common/icons/situation-circle.svg';
import Spinner from 'src/components/common/Spinner';
import classes from 'src/components/OrderCancelPage/style.module.scss';
import ClientIcon from 'src/svg-icons/client';
import DetailsIcon from 'src/svg-icons/details';

const OrderCancelPageComponent = ({
  isLoading,
  isBusy,
  order,
  inspectionIcon,
  initialValues,
  reasonOptions,
  validationSchema,
  onSubmit,
  goBack,
}) => (
  <div className={classes.orderCancelPage}>
    {isLoading ? (
      <div className={classes.spinnerWrapper}>
        <Spinner />
      </div>
    ) : (
      <Card data-cy="cancel-order-card">
        {order && (
          <h2 className={classes.cardHead}>
            <div className={classes.back}>
              <button type="button" onClick={goBack}>
                <div className={classes.arrowLeft} />
              </button>
            </div>
            <div className={classes.icon}>{inspectionIcon}</div>
            {`${order.form.details.building_id} ● ${order.form.clients.client}`}
          </h2>
        )}
        <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
          {({ values, errors }) => (
            <Form>
              {order?.messages?.vnr ? (
                <div className={classes.inspectorObservations}>
                  <h3 className={classes.subtitle}>
                    <div className={classes.icon}>
                      <ClientIcon />
                    </div>
                    Observações do vistoriador
                  </h3>
                  <div className={classes.field}>
                    <p>{order?.messages?.vnr?.message}</p>
                  </div>
                  {order?.messages?.vnr?.photo && (
                    <>
                      <h3 className={classes.subtitle}>
                        <div className={classes.icon}>
                          <DetailsIcon />
                        </div>
                        Foto enviada pelo vistoriador
                      </h3>
                      <div className={classes.field}>
                        <img src={order?.messages?.vnr?.photo} className={classes.image} alt="Foto recebida" />
                      </div>
                      <div className={`${classes.field} ${classes.isFlex}`}>
                        <Field
                          as={Checkbox}
                          name="sendPhotoToClient"
                          id="send-photo-to-client"
                          value="true"
                          checked={values.sendPhotoToClient}
                        />
                        <label htmlFor="send-photo-to-client" className={classes.marginLeft}>
                          Enviar foto ao cliente
                        </label>
                      </div>
                    </>
                  )}
                </div>
              ) : null}
              <h3 className={classes.subtitle}>
                <div className={classes.icon}>
                  <img src={situationCircleImage} alt="Análise da situação" />
                </div>
                Análise da situação
              </h3>
              <div className={classes.field}>
                <label htmlFor="reason">Motivo</label>
                <Field name="reason">
                  {({ field, form }) => (
                    <Select
                      {...field}
                      name={field.name}
                      id="reason"
                      className={classes.select}
                      classNamePrefix="select"
                      placeholder="Selecione o motivo"
                      value={reasonOptions?.find(option => option.value === field.value || '')}
                      onChange={option => form.setFieldValue(field.name, option?.value)}
                      onBlur={field.onBlur}
                      noOptionsMessage={() => 'Nenhuma opção disponível.'}
                      options={reasonOptions}
                      isOptionDisabled={option => option.disabled}
                      components={{
                        Input: inputProps => <components.Input {...inputProps} data-cy="cancel-order-reason-select" />,
                      }}
                      isClearable
                    />
                  )}
                </Field>
                {errors.reason ? <div className={classes.error}>{errors.reason}</div> : null}
              </div>
              <div className={classes.field}>
                <label htmlFor="observation">Detalhes (será enviado ao cliente)</label>
                <Field
                  component="textarea"
                  name="observation"
                  id="observation"
                  data-cy="cancel-order-observation-textarea"
                />
                {errors.observation ? <div className={classes.error}>{errors.observation}</div> : null}
              </div>
              <div className={`${classes.field} ${classes.isFlex}`}>
                <Field
                  as={Checkbox}
                  name="shouldBeCharged"
                  id="shouldBeCharged"
                  value="true"
                  data-cy="cancel-order-should-be-charged-input"
                  checked={values.shouldBeCharged}
                />
                <label htmlFor="shouldBeCharged" className={classes.marginLeft}>
                  Cobrar taxa de deslocamento
                </label>
              </div>
              <div className={`${classes.field} ${classes.buttons}`}>
                <ColorButton onClick={goBack} data-cy="cancel-order-go-back-button">
                  Voltar
                </ColorButton>
                <ColorButton type="submit" color="red" isBusy={isBusy} data-cy="cancel-order-submit-button">
                  Cancelar pedido
                </ColorButton>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    )}
  </div>
);

OrderCancelPageComponent.propTypes = {
  isBusy: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  inspectionIcon: PropTypes.node.isRequired,
  initialValues: PropTypes.object.isRequired,
  reasonOptions: PropTypes.array.isRequired,
  validationSchema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  order: PropTypes.object,
};

OrderCancelPageComponent.defaultProps = {
  order: null,
};

export default OrderCancelPageComponent;

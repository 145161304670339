import React from 'react';

import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import classes from 'src/components/AddRecommendationCode/style.module.scss';
import ColorButton from 'src/components/common/elements/ColorButton';

const AddRecommendationCodeComponent = ({ error, validationSchema, handleSubmit, intl }) => (
  <div className={classes.addRecommendationCode}>
    <h3>{intl.formatMessage({ id: 'ADD_REFERRAL_CODE_MESSAGE' })}</h3>
    <Formik initialValues={{ code: '' }} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {({ errors, isSubmitting }) => (
        <Form>
          <div className={classes.field}>
            <label htmlFor="code">{intl.formatMessage({ id: 'CODE' })}</label>
            <Field type="text" name="code" id="code" />
            {(error || errors.code) && <div className={classes.error}>{error || errors.code}</div>}
          </div>
          <div className={`${classes.field} ${classes.actions}`}>
            <ColorButton type="submit" color="green" isBusy={isSubmitting}>
              {intl.formatMessage({ id: 'SEND' })}
            </ColorButton>
          </div>
        </Form>
      )}
    </Formik>
  </div>
);

AddRecommendationCodeComponent.propTypes = {
  error: PropTypes.string,
  validationSchema: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

AddRecommendationCodeComponent.defaultProps = {
  error: null,
};

export default AddRecommendationCodeComponent;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let MenuAtivos = props => (
  <SvgIcon {...props}>
    <path
      fill="#FFFFFF"
      d="M30.576,13.943L20.823,13.1l-3.814-8.982l-3.812,8.995l-9.756,0.83l7.409,6.416l-2.226,9.54l8.386-5.062
    	l8.384,5.062l-2.211-9.54L30.576,13.943z"
    />
  </SvgIcon>
);
MenuAtivos = pure(MenuAtivos);
MenuAtivos.displayName = 'MenuAtivos';
MenuAtivos.muiName = 'SvgIcon';

export default MenuAtivos;

import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import API from 'src/apiRequest';
import ClientUsersComponent from 'src/components/ClientsPage/ClientUsers/ClientUsersComponent';

const ClientUsersContainer = () => {
  const { id, status } = useParams();
  const [users, setUsers] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    setUsers([]);
    try {
      const fetchData = async () => {
        const { data } = await API.get(`/clients/${id}/users`);
        setUsers(data);
      };
      fetchData();
    } catch (err) {
      console.log(err);
    }
  }, [id]);

  return <ClientUsersComponent id={id} intl={intl} status={status} users={users} />;
};

export default ClientUsersContainer;

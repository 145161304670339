import messagesPTBR from 'src/translations/pt_br.json';
import messagesPTPT from 'src/translations/pt_pt.json';

const messagesByLanguage = {
  ES: null,
  BR: messagesPTBR,
  PT: messagesPTPT,
};

const localeByLanguage = {
  ES: 'es-ES',
  BR: 'pt-BR',
  PT: 'pt-PT',
};

function getUserLanguage(language = 'BR') {
  try {
    return {
      locale: localeByLanguage[language],
      messages: messagesByLanguage[language],
    };
  } catch {
    return {
      locale: localeByLanguage.BR,
      messages: messagesByLanguage.BR,
    };
  }
}

export default getUserLanguage;

import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import RmIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';

import classes from 'src/orderForm/fields/Services/style.module.scss';
import Service from 'src/svg-icons/service';

/*
    Data example:
      [
        { type: 'Eletricista', responsibleEmail: 'duyle.campos@redevistorias.com.br' },
        { type: 'Encanador', responsibleEmail: 'mateus.silva@redevistorias.com.br' }
      ]
*/
class Services extends Component {
  //  create services atribute in state to manage formData information
  constructor(props) {
    super(props);
    const services = props.formData
      ? [...props.formData]
      : [
        {
          type: '',
          responsibleEmail: '',
        },
      ];
    this.state = {
      services,
    };
    props.onChange(services);
  }

  /*
      onChange method returns an onChange event and send changin info to parent
      services out of event for resources maintenance
  */
  onChange = (propertyName, index) => {
    const { services: stateServices } = this.state;
    const { onChange } = this.props;
    const services = [...stateServices];
    return event => {
      services[index][propertyName] = event.target.value;
      this.setState({ services });
      onChange(services);
    };
  };

  // hanClick triggered when button add is clicked
  handleAddClick = () => {
    const { services: stateServices } = this.state;
    const { onChange } = this.props;
    const services = [...stateServices];
    services.push({ type: '', responsibleEmail: '' });
    this.setState({
      services,
    });
    onChange(services);
  };

  /*
      handleDeleteClick triggered when RM button is clicked
      send `splice` information to this.props.onChange
      update state
  */
  handleDeleteClick = index => () => {
    const { services: stateServices } = this.state;
    const { onChange } = this.props;
    const services = [...stateServices];
    if (services.length === 1) {
      services[0].type = '';
      services[0].responsibleEmail = '';
    } else {
      services.splice(index, 1);
    }
    this.setState({ services });
    onChange(services);
  };

  isDisabled = () => {
    const { services } = this.state;

    return (
      (services[0].type === '' && services[0].responsibleEmail === '' && services.length === 1) ||
      (services[0].type === undefined && services[0].responsibleEmail === undefined && services.length === 1)
    );
  };

  render() {
    const { services } = this.state;
    const { name } = this.props;

    return (
      <Grid container direction="row" className={classes.rootContainer}>
        <Grid item xs={12}>
          {services &&
            services.map((service, index) => (
              <div className={classes.service} key={`service-${index}`}>
                <div className={classes.flexHeader}>
                  <p className={classes.title}>
                    <Service viewBox="0 0 34.016 34.016" />
                    <span>
                      <FormattedMessage id={name} />
                      {` ${index + 1}`}
                    </span>
                  </p>
                  <Button
                    disabled={this.isDisabled()}
                    variant="fab"
                    mini
                    classes={{
                      mini: classes.removeButton,
                      disabled: classes.removeButtonDisabled,
                    }}
                    onClick={this.handleDeleteClick(index)}
                  >
                    <RmIcon />
                  </Button>
                </div>
                <div>
                  <div className={classes.field}>
                    <label className="label" htmlFor={`field-type-${index}`}>
                      <FormattedMessage id="SERVICES.TYPE" />*
                    </label>
                    <input
                      className="form-control"
                      id={`field-type-${index}`}
                      type="text"
                      value={service.type}
                      onChange={this.onChange('type', index)}
                    />
                  </div>
                  <div className={classes.field}>
                    <label className="label" htmlFor={`field-email-${index}`}>
                      <FormattedMessage id="recipients.email" />*
                    </label>
                    <input
                      className="form-control"
                      id={`field-email-${index}`}
                      type="email"
                      value={service.responsibleEmail}
                      onChange={this.onChange('responsibleEmail', index)}
                    />
                  </div>
                </div>
              </div>
            ))}
        </Grid>
        <div className={classes.grayLines}>
          <Button variant="fab" mini onClick={this.handleAddClick} classes={{ mini: classes.plusButton }}>
            <AddIcon />
          </Button>
        </div>
      </Grid>
    );
  }
}

Services.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  formData: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      responsibleEmail: PropTypes.string,
    })
  ),
};

Services.defaulProps = {
  formData: [
    {
      type: '',
      responsibleEmail: '',
    },
  ],
};

export default injectIntl(Services, { forwardRef: true });

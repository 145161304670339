import { SUPPORT_PANEL_URL } from 'src/config';

function getSupportPanelLink() {
  const accessToken = sessionStorage.getItem('accessToken');
  const tokenType = sessionStorage.getItem('tokenType');

  window.location.href = `${SUPPORT_PANEL_URL}#access_token=${accessToken}&token_type=${tokenType}&expires_in=`;
}

export default getSupportPanelLink;

/* eslint-disable camelcase */
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import ClientSuccessComponent from 'src/components/ClientsPublicPage/ClientSuccess/ClientSuccessComponent';
import { decodeToken } from 'src/helpers/tokenGenerator';

const ClientSuccessContainer = () => {
  const { hash } = useParams();
  const franchise = useMemo(() => decodeToken(hash), [hash]);

  return <ClientSuccessComponent franchise={franchise} />;
};

export default ClientSuccessContainer;

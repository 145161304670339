import React from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import PropTypes from 'prop-types';

import Card from 'src/components/common/Card';
import permissionsImage from 'src/components/common/icons/document-circle.svg';
import editImage from 'src/components/common/icons/edit.svg';
import detailsImage from 'src/components/common/icons/inspector-details.svg';
import trashImage from 'src/components/common/icons/trash.svg';
import userImage from 'src/components/common/icons/user-circle.svg';
import Spinner from 'src/components/common/Spinner';
import urlPrefix from 'src/helpers/url-prefix';

import classes from './style.module.scss';

const ClientDetailsComponent = ({
  id,
  isFeaturesBusy,
  isUserBusy,
  isDeleteConfirmationVisible,
  intl,
  features,
  user,
  cancelDelete,
  handleDelete,
  showDeleteConfirmation,
}) => {
  if (isUserBusy) {
    return (
      <div className={classes.clientDetails}>
        <div className={classes.spinner}>
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.clientDetails}>
      {user ? (
        <>
          <Card className="fade-in">
            <div className={classes.toolbar}>
              {!isDeleteConfirmationVisible && (
                <div className={classes.actions}>
                  <Link to={`${urlPrefix('users')}/${id}/edit`} data-tip={intl.formatMessage({ id: 'USER_EDIT' })}>
                    <img src={editImage} alt={intl.formatMessage({ id: 'EDIT' })} />
                  </Link>
                  <button
                    type="button"
                    data-tip={intl.formatMessage({ id: 'USER_DELETE' })}
                    onClick={showDeleteConfirmation}
                  >
                    <img src={trashImage} alt={intl.formatMessage({ id: 'INSPECTOR_DELETE' })} />
                  </button>
                </div>
              )}
              {isDeleteConfirmationVisible && (
                <div className={classes.confirm}>
                  <div className={classes.text}>{intl.formatMessage({ id: 'DELETE_USER_MESSAGE' })}?</div>
                  <div className={classes.actions}>
                    <button type="button" onClick={cancelDelete}>
                      {intl.formatMessage({ id: 'NO' })}
                    </button>
                    <button type="button" onClick={handleDelete}>
                      {intl.formatMessage({ id: 'YES' })}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Card>
          <Card className="fade-in">
            <h2 className={classes.cardHead}>
              <div className={classes.icon}>
                <img src={detailsImage} alt={intl.formatMessage({ id: 'DETAILS' })} />
              </div>
              {intl.formatMessage({ id: 'DETAILS' })}
            </h2>
            <h3 className={classes.subtitle}>
              <div className={classes.icon}>
                <img src={userImage} alt={intl.formatMessage({ id: 'USER' })} />
              </div>
              {intl.formatMessage({ id: 'BASIC_DATA' })}
            </h3>
            <div className={`${classes.field} ${classes.isFlex}`}>
              <div className={`${classes.field} ${classes.is6}`}>
                <p className={classes.label}>{intl.formatMessage({ id: 'fullName' })}</p>
                <p className={classes.value} title={user?.name}>
                  {user?.name || '-----'}
                </p>
              </div>
              <div className={`${classes.field} ${classes.is6}`}>
                <p className={classes.label}>{intl.formatMessage({ id: 'CPF' })}</p>
                <p className={classes.value} title={user?.cpf}>
                  {user?.cpf || '-----'}
                </p>
              </div>
            </div>
            <div className={`${classes.field} ${classes.isFlex}`}>
              <div className={`${classes.field} ${classes.is6}`}>
                <p className={classes.label}>{intl.formatMessage({ id: 'USER' })}</p>
                <p className={classes.value} title={user?.username}>
                  {user?.username || '-----'}
                </p>
              </div>
              <div className={`${classes.field} ${classes.is6}`}>
                <p className={classes.label}>{intl.formatMessage({ id: 'EMAIL' })}</p>
                <p className={classes.value} title={user?.email}>
                  {user?.email || '-----'}
                </p>
              </div>
            </div>
            <div className={`${classes.field} ${classes.isFlex}`}>
              <div className={`${classes.field} ${classes.is6}`}>
                <p className={classes.label}>{intl.formatMessage({ id: 'CONTACT' })}</p>
                <p className={classes.value} title={user?.phone}>
                  {user?.phone || '-----'}
                </p>
              </div>
              <div className={`${classes.field} ${classes.is6}`}>
                <p className={classes.label}>{intl.formatMessage({ id: 'CREATED_AT' })}</p>
                <p className={classes.value}>{user?.created_at || '-----'}</p>
              </div>
            </div>
            <h3 className={classes.subtitle}>
              <div className={classes.icon}>
                <img src={permissionsImage} alt={intl.formatMessage({ id: 'USER' })} />
              </div>
              {intl.formatMessage({ id: 'PERMISSIONS' })}
            </h3>
            <div className={classes.field}>
              {isFeaturesBusy ? (
                <Spinner />
              ) : (
                <ul className={classes.permissionList}>
                  {features.map(feature => (
                    <li
                      key={feature?.description}
                      title={intl.formatMessage({
                        id: `PERMISSION.${feature?.description}`,
                      })}
                    >
                      <input type="checkbox" checked={feature?.selected} readOnly />
                      {intl.formatMessage({
                        id: `PERMISSION.${feature?.description}`,
                      })}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </Card>
          <ReactTooltip effect="solid" />
        </>
      ) : (
        <Card className="fade-in">
          <div style={{ textAlign: 'center' }}>
            <h2 className={classes.subtitle} style={{ display: 'block' }}>
              {intl.formatMessage({ id: 'NOT_FOUND_MESSAGE' })}
            </h2>
          </div>
        </Card>
      )}
    </div>
  );
};

ClientDetailsComponent.propTypes = {
  id: PropTypes.string.isRequired,
  isFeaturesBusy: PropTypes.bool.isRequired,
  isUserBusy: PropTypes.bool.isRequired,
  isDeleteConfirmationVisible: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  features: PropTypes.array,
  user: PropTypes.object,
  cancelDelete: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  showDeleteConfirmation: PropTypes.func.isRequired,
};

ClientDetailsComponent.defaultProps = {
  features: null,
  user: null,
};

export default ClientDetailsComponent;

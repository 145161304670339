import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let Resources = props => (
  <SvgIcon {...props}>
    <path
      fill="#393939"
      d="M17,2C8.7,2,2,8.7,2,17s6.7,15,15,15s15-6.7,15-15S25.3,2,17,2z M17,30C9.8,30,4,24.2,4,17S9.8,4,17,4
		s13,5.8,13,13S24.2,30,17,30z"
    />
    <path
      fill="#393939"
      d="M23.6,11.5h-3.3V9.8c0-0.5-0.2-0.9-0.5-1.2c-0.3-0.3-0.7-0.5-1.2-0.5h-3.3c-0.9,0-1.7,0.7-1.7,1.7v1.7h-3.3
		c-0.9,0-1.7,0.7-1.7,1.7v9.1c0,0.9,0.7,1.7,1.7,1.7h13.3c0.9,0,1.7-0.7,1.7-1.7v-9.1C25.3,12.2,24.5,11.5,23.6,11.5z M15.4,9.8h3.3
		v1.7h-3.3V9.8z"
    />
  </SvgIcon>
);

Resources = pure(Resources);
Resources.displaName = 'Resources';
Resources.muiName = 'SvgIcon';

export default Resources;

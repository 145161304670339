import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import ClientLinkLayoutComponent from 'src/components/ClientLinkLayout/ClientLinkLayoutComponent';
import { decodeToken } from 'src/helpers/tokenGenerator';

const ClientLinkLayoutContainer = ({ ...props }) => {
  const { hash } = useParams();
  const franchise = useMemo(() => decodeToken(hash), [hash]);
  return <ClientLinkLayoutComponent franchise={franchise} {...props} />;
};

export default ClientLinkLayoutContainer;

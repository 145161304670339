import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const remove = props => (
  <SvgIcon {...props} viewBox="0 0 11 13">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.71429 0C4.41668 0 4.14462 0.173877 4.01152 0.449139L3.44297 1.625H0.785714C0.351776 1.625 0 1.98877 0 2.4375C0 2.88623 0.351777 3.25 0.785715 3.25L0.785714 11.375C0.785714 12.2725 1.48927 13 2.35714 13H8.64286C9.51073 13 10.2143 12.2725 10.2143 11.375V3.25C10.6482 3.25 11 2.88623 11 2.4375C11 1.98877 10.6482 1.625 10.2143 1.625H7.55703L6.98848 0.449139C6.85539 0.173877 6.58332 0 6.28572 0H4.71429ZM3.14286 4.875C3.14286 4.42627 3.49463 4.0625 3.92857 4.0625C4.36251 4.0625 4.71429 4.42627 4.71429 4.875V9.75C4.71429 10.1987 4.36251 10.5625 3.92857 10.5625C3.49463 10.5625 3.14286 10.1987 3.14286 9.75V4.875ZM7.07143 4.0625C6.63749 4.0625 6.28571 4.42627 6.28571 4.875V9.75C6.28571 10.1987 6.63749 10.5625 7.07143 10.5625C7.50537 10.5625 7.85714 10.1987 7.85714 9.75V4.875C7.85714 4.42627 7.50537 4.0625 7.07143 4.0625Z"
      fill="#393939"
    />
  </SvgIcon>
);

remove.displayName = 'remove';
remove.muiName = 'SvgIcon';

export default remove;

import React from 'react';

import PropTypes from 'prop-types';

import classes from 'src/components/common/elements/Checkbox/style.module.scss';

const Checkbox = ({ checked, className, disabled, id, name, value, onChange, ...props }) => (
  <div className={`${classes.checkbox} ${className}`}>
    <input
      type="checkbox"
      name={name}
      value={value}
      checked={checked}
      disabled={disabled}
      id={id}
      onChange={onChange}
      {...props}
    />
    <label htmlFor={id} />
  </div>
);

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  checked: false,
  className: '',
  disabled: false,
  onChange: null,
};

export default Checkbox;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let TypeAcompanhamentoObraMM = props => (
  <SvgIcon {...props} fill="none" viewBox="0 0 34.016 34.016">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.3101 2.37038V25.6538C21.3101 26.6754 20.0218 27.5041 18.4381 27.5041H3.58192C3.48315 27.5041 3.38819 27.5003 3.28971 27.4964L3.27831 27.4959C1.83413 27.3933 0.709961 26.6056 0.709961 25.6497V2.37038C0.709961 1.55803 1.52231 0.864655 2.65879 0.618487C2.95009 0.552842 3.2578 0.52002 3.58192 0.52002H18.4381C20.0218 0.52002 21.3101 1.34878 21.3101 2.37038ZM17.7857 2.72729H3.27002V3.33118H3.26985V24.957H3.27002V25.2969H17.7857V23.0363H5.78076V20.0493H11.0079V17.7887H5.78076V15.5153H17.8475V13.2546H5.78076V10.4981H11.0079V8.23743H5.78076V4.98794H17.7857V2.72729Z"
      fill="#E66800"
    />
  </SvgIcon>
);

TypeAcompanhamentoObraMM = pure(TypeAcompanhamentoObraMM);
TypeAcompanhamentoObraMM.displayName = 'TypeAcompanhamentoObraMM';
TypeAcompanhamentoObraMM.muiName = 'SvgIcon';

export default TypeAcompanhamentoObraMM;

import React from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';
import PropTypes from 'prop-types';

import classes from 'src/components/ClientsPage/ClientUsers/style.module.scss';
import Card from 'src/components/common/Card';
import userCircleImage from 'src/components/common/icons/user-circle.svg';
import userPermissionImage from 'src/components/common/icons/user-permission.svg';
import Spinner from 'src/components/common/Spinner';

const ClientUsersComponent = ({ id, intl, status, users }) =>
  !users.length ? (
    <div className={classes.spinner}>
      <Spinner />
    </div>
  ) : (
    <div className={classes.clientUsers}>
      <Card className="fade-in">
        <h2 className={classes.cardHead}>
          <div className={classes.back}>
            <Link to={`../${id}`}>
              <div className={classes.arrowLeft} />
            </Link>
          </div>
          <div className={classes.icon}>
            <img src={userPermissionImage} alt={intl.formatMessage({ id: 'USERS' })} />
          </div>
          {intl.formatMessage({ id: 'USERS' })}
        </h2>
        <div className={classes.list}>
          {users.length &&
            users.map(user => (
              <Link to={`/clients/${status}/${id}/users/${user.id}/edit`} key={user.id}>
                <div className={classes.item}>
                  <div className={classes.icon}>
                    <img src={userCircleImage} alt={intl.formatMessage({ id: 'USER' })} />
                  </div>
                  <div className={classes.text}>
                    <div className={classes.highlight}>{user.username}</div>
                    {user.email && (
                      <>
                        <div className={classes.separator}>•</div>
                        <div className={classes.normal} title={user.email}>
                          {user.email}
                        </div>
                      </>
                    )}
                  </div>
                  <div className={classes.date}>{`${moment(user.created_at).format('DD/MM/YYYY')}`}</div>
                </div>
              </Link>
            ))}
          {!users.length && <p className={classes.empty}>{intl.formatMessage({ id: 'NO_USERS_CREATED_MESSAGE' })}</p>}
        </div>
      </Card>
    </div>
  );

ClientUsersComponent.propTypes = {
  id: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  users: PropTypes.array,
};

ClientUsersComponent.defaultProps = {
  users: [],
};

export default ClientUsersComponent;

import React from 'react';
import DatePicker from 'react-datepicker';

import PropTypes from 'prop-types';

import Card from 'src/components/common/Card';
import GrayButton from 'src/components/common/elements/GrayButton';
import fileExport from 'src/components/common/icons/file-export.svg';
import classes from 'src/components/InspectorsPage/InspectorList/ExportGeneralStatistics/style.module.scss';

const ExportGeneralStatisticsComponent = ({
  isBusy,
  onClick,
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
  intl,
}) => (
  <div className={classes.exportGeneralStatistics}>
    <Card>
      <p>{intl.formatMessage({ id: 'INSPECTOR_SPREADSHEET_GENERATE_MESSAGE' })}</p>
      <form>
        <div className={classes.statisticsDates}>
          <div className={classes.startDate}>
            <label htmlFor="startDate">{intl.formatMessage({ id: 'FROM_DAY' })}</label>
            <DatePicker
              id="startDate"
              name="startDate"
              placeholderText={intl.formatMessage({ id: 'INITIAL_DATE' })}
              autoComplete="off"
              locale="pt-BR"
              dateFormat="dd 'de' MMMM 'de' yyy"
              onChange={handleStartDateChange}
              selectsStart
              startDate={startDate}
              maxDate={new Date()}
              endDate={endDate}
              selected={startDate}
              popperPlacement="top-end"
            />
          </div>
          <div className={classes.endDate}>
            <label htmlFor="endDate">{intl.formatMessage({ id: 'TO_DAY' })}</label>
            <DatePicker
              id="endDate"
              name="endDate"
              placeholderText={intl.formatMessage({ id: 'FINAL_DATE' })}
              autoComplete="off"
              locale="pt-BR"
              dateFormat="dd 'de' MMMM 'de' yyy"
              onChange={handleEndDateChange}
              selectsEnd
              endDate={endDate}
              maxDate={new Date()}
              minDate={startDate}
              selected={endDate}
              popperPlacement="top-end"
            />
          </div>
        </div>
        <GrayButton style={{ width: '180px' }} onClick={onClick} isBusy={isBusy}>
          <img src={fileExport} alt={intl.formatMessage({ id: 'METRICS' })} />
          {intl.formatMessage({ id: 'REPORT_EXPORT' })}
        </GrayButton>
      </form>
    </Card>
  </div>
);

ExportGeneralStatisticsComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
  isBusy: PropTypes.bool.isRequired,
  startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
  endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
  handleStartDateChange: PropTypes.func.isRequired,
  handleEndDateChange: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

ExportGeneralStatisticsComponent.defaultProps = {
  startDate: null,
  endDate: null,
};

export default ExportGeneralStatisticsComponent;

import React, { useRef, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import PropTypes from 'prop-types';

import Element from 'src/components/common/Toast/Element';
import Icon from 'src/components/common/Toast/Icon';
import classes from 'src/components/common/Toast/style.module.scss';
import CloseImage from 'src/images/icons/times.svg';

const handleExiting = el => {
  el.style.transform += ' scale(0.9)';
};

const setToastsPosition = container => {
  if (!container) return;
  const els = container.querySelectorAll('[class^=style_toast]:not(.toast-exit-done)');
  let totalHeight = 0;
  for (let i = 0; i < els.length; i++) {
    const top = totalHeight + 10 * i;
    els[i].style.transform = `translate3d(0, -${top}px, 0)`;
    els[i].style.transitionDelay = `${0.015 * i}s`;
    totalHeight += els[i].offsetHeight;
  }
};

const timeouts = {};
const ToastComponent = ({ hideToast, toasts, handleToastClick }) => {
  const container = useRef();

  useEffect(() => {
    toasts.forEach(toast => {
      if (toast.duration) {
        if (!timeouts[toast.id]) {
          timeouts[toast.id] = setTimeout(() => {
            hideToast(toast.id);
          }, toast.duration);
        }
      }
    });
  }, [hideToast, toasts]);

  const handleCloseClick = (toastId, evt) => {
    evt.preventDefault();
    clearTimeout(timeouts[toastId]);
    hideToast(toastId);
  };

  return (
    <div className={classes.toastContainer} ref={container}>
      <TransitionGroup component={null}>
        {toasts.map(toast => (
          <CSSTransition
            timeout={300}
            classNames="toast"
            onEnter={() => setToastsPosition(container.current)}
            onExiting={handleExiting}
            onExited={() => setToastsPosition(container.current)}
            unmountOnExit
            key={toast.id}
          >
            <Element
              data={toast}
              className={classes.toast}
              handleClick={() => handleToastClick(toast.id, toast.onClick)}
            >
              <button type="button" className={classes.closeButton} onClick={evt => handleCloseClick(toast.id, evt)}>
                <img src={CloseImage} alt="Fechar" />
              </button>
              <div className={classes.icon}>
                <Icon type={toast.type} />
              </div>
              <div className={classes.content}>
                <h5 className={classes.title}>{toast.title}</h5>
                <p className={classes.text}>{toast.text}</p>
              </div>
            </Element>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  );
};

ToastComponent.propTypes = {
  hideToast: PropTypes.func.isRequired,
  toasts: PropTypes.array,
  handleToastClick: PropTypes.func.isRequired,
};

ToastComponent.defaultProps = {
  toasts: [],
};

export default ToastComponent;

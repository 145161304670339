import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const FotosDeCaptacao = props => (
  <SvgIcon viewBox="297.638 155.903 850.394 850.394" {...props}>
    <path
      fill="#393939"
      d="M722.835,583.036c44.551,0,81.354-36.803,81.354-83.291c0-46.486-36.803-83.291-81.354-83.291
      s-81.354,36.805-81.354,83.291C641.481,546.234,678.284,583.036,722.835,583.036z"
    />
    <path
      fill="#393939"
      d="M521.387,707.006h402.896c27.118,0,50.362-23.244,50.362-52.3V344.785c0-29.055-23.244-52.299-50.362-52.299
      h-79.417l-46.488-52.299H647.292l-46.488,52.299h-79.417c-27.118,0-50.361,23.244-50.361,52.299v309.921
      C471.024,683.761,494.269,707.006,521.387,707.006z M722.835,368.03c69.732,0,125.905,58.11,125.905,129.779
      c0,73.605-56.173,129.779-125.905,129.779S596.93,569.478,596.93,497.809S653.103,368.03,722.835,368.03z"
    />
    <polygon
      fill="#393939"
      points="453.448,785.651 502.471,820.755 453.448,855.857 453.448,897.974 557.089,820.755 453.448,743.533
      "
    />
    <polygon
      fill="#393939"
      points="567.553,785.651 616.577,820.755 567.553,855.857 567.553,897.974 671.204,820.755 567.553,743.533
      "
    />
    <polygon
      fill="#393939"
      points="681.667,785.651 730.693,820.755 681.667,855.857 681.667,897.974 785.312,820.755 681.667,743.533
      "
    />
    <polygon
      fill="#393939"
      points="795.776,785.651 844.8,820.755 795.776,855.857 795.776,897.974 899.427,820.755 795.776,743.533
      "
    />
    <polygon
      fill="#393939"
      points="909.871,785.651 958.907,820.755 909.871,855.857 909.871,897.974 1013.522,820.755
      909.871,743.533 "
    />
  </SvgIcon>
);

FotosDeCaptacao.displayName = 'FotosDeCaptacao';
FotosDeCaptacao.muiName = 'SvgIcon';

export default FotosDeCaptacao;

import React, { useState } from 'react';

import PropTypes from 'prop-types';

import ImageUploadComponent from 'src/components/common/elements/ImageUpload/ImageUploadComponents';

const ImageUploadContainer = ({ name, id, value, onChange }) => {
  const [blob, setBlob] = useState(null);
  const [image, setImage] = useState(null);
  const [isBusy, setIsBusy] = useState(false);

  const handleChange = async evt => {
    setIsBusy(true);
    const { files } = evt.target;
    const file = files.item(0);
    setImage(file);
    await onChange(file);
    setBlob(URL.createObjectURL(file));
    setIsBusy(false);
  };

  return (
    <ImageUploadComponent
      name={name}
      id={id}
      value={value}
      blob={blob}
      image={image}
      isBusy={isBusy}
      handleChange={handleChange}
    />
  );
};

ImageUploadContainer.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

ImageUploadContainer.defaultProps = {
  value: null,
};

export default ImageUploadContainer;

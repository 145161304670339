import React from 'react';

import PropTypes from 'prop-types';

import classes from 'src/orderForm/fields/RecipientsField/ReportTypeSelectOption/style.module.scss';

const ReportTypeSelectOption = ({ children, innerProps, innerRef, isSelected }) => (
  <div className={classes.reportTypeSelectOption} ref={innerRef} {...innerProps}>
    <input type="checkbox" checked={isSelected} readOnly />

    <span>{children}</span>
  </div>
);

ReportTypeSelectOption.propTypes = {
  children: PropTypes.node.isRequired,
  innerProps: PropTypes.object.isRequired,
  innerRef: PropTypes.func,
  isSelected: PropTypes.bool.isRequired,
};

ReportTypeSelectOption.defaultProps = {
  innerRef: null,
};

export default ReportTypeSelectOption;

import React from 'react';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-text-mask';

import PropTypes from 'prop-types';

import classes from 'src/components/ClientsPage/CreateUser/style.module.scss';
import Card from 'src/components/common/Card';
import userPermissionImage from 'src/components/common/icons/user-permission.svg';
import { USERNAME_MASK } from 'src/helpers/mask';

const CreateUserComponent = ({
  busy,
  features,
  handleSelectedPermissionsChange,
  id,
  intl,
  onChange,
  onSubmit,
  status,
  user,
  cpfMask,
  cpfMaskPlaceholder,
  phoneMask,
  phoneMaskPlaceholder,
  cellphoneMask,
  cellphoneMaskLength,
}) => (
  <div className={classes.createUser}>
    <Card className="fade-in">
      <h2 className={classes.cardHead}>
        <div className={classes.back}>
          <Link to={`/clients/${status}/${id}`}>
            <div className={classes.arrowLeft} />
          </Link>
        </div>

        <div className={classes.icon}>
          <img src={userPermissionImage} alt={intl.formatMessage({ id: 'USER' })} />
        </div>

        {intl.formatMessage({ id: 'ADD_USER' })}
      </h2>

      <form onSubmit={onSubmit}>
        <div className={classes.field}>
          <label htmlFor="name">{intl.formatMessage({ id: 'fullName' })}*</label>

          <input
            type="text"
            name="name"
            id="name"
            data-cy="name"
            required
            onChange={onChange}
            value={user.name || ''}
            placeholder="ex: Usuario Exemplo"
          />
        </div>

        <div className={classes.field}>
          <label htmlFor="cpf">{intl.formatMessage({ id: 'CPF' })}</label>

          <MaskedInput
            type="text"
            name="cpf"
            id="cpf"
            data-cy="cpf"
            onChange={onChange}
            value={user.cpf || ''}
            placeholder={`ex: ${cpfMaskPlaceholder}`}
            guide={false}
            mask={cpfMask}
          />
        </div>
        <div className={classes.field}>
          <label htmlFor="username">{intl.formatMessage({ id: 'USER' })}*</label>

          <MaskedInput
            type="text"
            name="username"
            id="username"
            data-cy="username"
            required
            onChange={onChange}
            value={user.username || ''}
            placeholder="ex: usuario_exemplo"
            guide={false}
            mask={USERNAME_MASK}
          />
        </div>

        <div className={classes.field}>
          <label htmlFor="email">{intl.formatMessage({ id: 'EMAIL' })}*</label>

          <input
            type="email"
            name="email"
            id="email"
            data-cy="email"
            required
            onChange={onChange}
            value={user.email || ''}
            placeholder="ex: usuario_exemplo@redevistorias.com.br"
          />
        </div>

        <div className={classes.field}>
          <label htmlFor="phone">{intl.formatMessage({ id: 'CONTACT' })}</label>

          <MaskedInput
            type="text"
            name="phone"
            id="phone"
            data-cy="phone"
            onChange={onChange}
            value={user.phone || ''}
            placeholder={`ex: ${phoneMaskPlaceholder}`}
            guide={false}
            mask={value => (value.length >= cellphoneMaskLength ? cellphoneMask : phoneMask)}
          />
        </div>

        <div className={classes.permissionsList}>
          {features.length ? (
            features.map(feature => (
              <div key={feature.id}>
                <label title={intl.formatMessage({ id: `PERMISSION.${feature.description}` })}>
                  <input
                    type="checkbox"
                    name="feature"
                    data-cy={`feature-${feature.description}`}
                    value={feature.id}
                    onChange={handleSelectedPermissionsChange}
                    defaultChecked={feature?.rule === 'opt-out'}
                  />

                  {intl.formatMessage({ id: `PERMISSION.${feature.description}` })}
                </label>
              </div>
            ))
          ) : (
            <div className={classes.empty}>{intl.formatMessage({ id: 'NOT_FOUND_MESSAGE' })}</div>
          )}
        </div>

        <div className={classes.cardFooter}>
          <Link to={`/clients/${status}/${id}`} className={classes.button}>
            {intl.formatMessage({ id: 'CANCEL' })}
          </Link>

          <button type="submit" className={`${classes.button} ${classes.isGreen}`} disabled={busy}>
            {intl.formatMessage({ id: 'SAVE' })}
          </button>
        </div>
      </form>
    </Card>
  </div>
);

CreateUserComponent.propTypes = {
  busy: PropTypes.bool.isRequired,
  features: PropTypes.array.isRequired,
  handleSelectedPermissionsChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  cpfMask: PropTypes.array.isRequired,
  cpfMaskPlaceholder: PropTypes.string.isRequired,
  phoneMask: PropTypes.array.isRequired,
  phoneMaskPlaceholder: PropTypes.string.isRequired,
  cellphoneMask: PropTypes.array.isRequired,
  cellphoneMaskLength: PropTypes.number.isRequired,
};

export default CreateUserComponent;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const VisitaDigital = props => (
  <SvgIcon viewBox="0 0 157 157" {...props}>
    <path
      d="M78.112 49.0618C60.4483 49.0618 45.3615 60.0464 39.2494 75.5555C45.3615 91.0685 60.4443 102.049 78.112 102.049C95.7758 102.049 110.859 91.0645 116.975 75.5555C110.859 60.0503 95.7719 49.0618 78.112 49.0618ZM78.112 93.2192C68.3631 93.2192 60.4483 85.3083 60.4483 75.5555C60.4483 65.8027 68.3631 57.8917 78.112 57.8917C87.8609 57.8917 95.7758 65.8066 95.7758 75.5555C95.7758 85.3044 87.8609 93.2192 78.112 93.2192Z"
      fill="#393939"
    />
    <path
      d="M78.1125 64.9601C72.2468 64.9601 67.515 69.6918 67.515 75.5576C67.515 81.4233 72.2468 86.155 78.1125 86.155C83.9783 86.155 88.71 81.4233 88.71 75.5576C88.71 69.6918 83.9783 64.9601 78.1125 64.9601Z"
      fill="#393939"
    />
    <path
      d="M78.4988 25.8856L104.384 51.7712L104.384 32.357C114.682 32.357 124.559 36.4478 131.84 43.7295C139.122 51.0113 143.213 60.8874 143.213 71.1854C143.213 77.6568 141.595 83.934 138.683 89.3053L148.131 98.7535C153.179 90.7937 156.156 81.3455 156.156 71.1854C156.156 57.4548 150.701 44.2866 140.992 34.5776C131.283 24.8686 118.115 19.4142 104.384 19.4142L104.384 -1.63903e-05L78.4988 25.8856Z"
      fill="#393939"
    />
    <path
      d="M77.6568 131.114L51.7712 105.229L51.7712 124.643C41.4733 124.643 31.5971 120.552 24.3154 113.27C17.0336 105.989 12.9428 96.1126 12.9428 85.8146C12.9428 79.3432 14.5607 73.066 17.4728 67.6947L8.02454 58.2465C2.97684 66.2063 2.07589e-07 75.6545 3.28747e-07 85.8146C4.92482e-07 99.5452 5.45445 112.713 15.1634 122.422C24.8724 132.131 38.0406 137.586 51.7712 137.586L51.7712 157L77.6568 131.114Z"
      fill="#393939"
    />
  </SvgIcon>
);

VisitaDigital.displayName = 'VisitaDigital';
VisitaDigital.muiName = 'SvgIcon';

export default VisitaDigital;

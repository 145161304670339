import React from 'react';
import { useIntl } from 'react-intl';

import ReportProblem from '@material-ui/icons/ReportProblem';

import classes from 'src/ErrorPage.module.css';

const ErrorPage = () => {
  const intl = useIntl();

  return (
    <div className={classes.ErrorPage}>
      <div className={classes.Icon}>
        <ReportProblem color="error" style={{ fontSize: 50 }} />
      </div>
      <h2>{intl.formatMessage({ id: 'ORDER_SHOW_ERROR_MESSAGE' })}</h2>
      <div>
        <p>{intl.formatMessage({ id: 'ORDER_REQUESTED_SHOW_ERROR_MESSAGE' })}</p>
      </div>
      <h3>{intl.formatMessage({ id: 'HOW_TO_SOLVE' })}</h3>
      <p>{intl.formatMessage({ id: 'VERIFY_THE_FOLLOWING_RULES_MESSAGE' })}</p>
      <ul>
        <li>
          {intl.formatMessage({ id: 'PROPERTY_TYPE_VALUE_MESSAGE' })}
          <ul>
            <li>{intl.formatMessage({ id: 'DUPLEX' })}</li>
            <li>{intl.formatMessage({ id: 'APARTMENT' })}</li>
            <li>{intl.formatMessage({ id: 'HOUSE' })}</li>
            <li>{intl.formatMessage({ id: 'KITETTE' })}</li>
            <li>{intl.formatMessage({ id: 'LOFT' })}</li>
            <li>{intl.formatMessage({ id: 'STORE' })}</li>
            <li>{intl.formatMessage({ id: 'ROOM' })}</li>
            <li>{intl.formatMessage({ id: 'SHED' })}</li>
            <li>{intl.formatMessage({ id: 'GARAGE' })}</li>
            <li>{intl.formatMessage({ id: 'BUILDING' })}</li>
            <li>{intl.formatMessage({ id: 'GROUND' })}</li>
            <li>{intl.formatMessage({ id: 'FARMHOUSE' })}</li>
          </ul>
        </li>
        <li>{intl.formatMessage({ id: 'ADDRESS_FILLED_MESSAGE' })}</li>
        <li>{intl.formatMessage({ id: 'PROPERTY_AREA_REGISTERED_MESSAGE' })}</li>
      </ul>
      <p>{intl.formatMessage({ id: 'INSPECTION_DOWNLOAD_MESSAGE' })}</p>
    </div>
  );
};

export default ErrorPage;

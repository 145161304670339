import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import API from 'src/apiRequest';
import PlanSwitch from 'src/components/common/PlanSwitch';
import Loading from 'src/Loading';

class ClientFinancialSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      total: 0,
      count: 0,
      perPage: 10,
      rows: [],
      order: 'desc',
      sort: 'created_at',
      loading: false,
    };
    this.handleOrderChange = this.handleOrderChange.bind(this);
  }

  componentDidMount() {
    this.fetchData(this.state.page);
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({
      page: 0,
      total: 0,
      count: 0,
      perPage: 10,
      rows: [],
      order: 'desc',
      sort: 'created_at',
      loading: false,
    });

    this.fetchData(this.state.page);
  }

  fetchData(page, column, order) {
    const today = new Date();
    const formattedDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const params = new URLSearchParams();
    let newOrder = this.state.order;
    let newSort = this.state.sort;

    this.setState({
      loading: true,
    });
    if (column !== undefined && order !== undefined) {
      newOrder = order;
      newSort = column;
    }
    params.append('offset', page * this.state.perPage);
    params.append('limit', this.state.perPage);
    params.append('startDate', formattedDate);
    params.append('orderBy', newSort);
    params.append('orderByDir', newOrder);
    API.get('/accounts/consolidated', { params })
      .then(res => {
        const items = res.data;
        this.setState({
          page: items.page - 1,
          total: items.total,
          count: items.quantity,
          rows: items.data ? items.data : [],
          order: newOrder,
          sort: newSort,
          loading: false,
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleChangePage = (event, page) => {
    this.fetchData(page);
  };

  /*
      Function to change the order of the elements from the table based on
      one column
    */
  handleOrderChange(column) {
    if (this.state.sort === column && this.state.order !== 'desc') {
      this.fetchData(this.state.page, column, 'desc');
    } else {
      this.fetchData(this.state.page, column, 'asc');
    }
  }

  render() {
    if (this.state.loading) {
      return <Loading clientPanel />;
    } else {
      return (
        <Fragment>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="Financial.operation" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="Financial.entity" />
                </TableCell>
                <PlanSwitch planToHide="CONTROL">
                  <TableCell>
                    <TableSortLabel
                      active={this.state.sort === 'credits'}
                      direction={this.state.order}
                      order={this.state.order}
                      onClick={() => this.handleOrderChange('credits')}
                    >
                      <FormattedMessage id="Financial.credits" />
                    </TableSortLabel>
                  </TableCell>
                </PlanSwitch>
                <TableCell>
                  <TableSortLabel
                    active={this.state.sort === 'area'}
                    direction={this.state.order}
                    order={this.state.order}
                    onClick={() => this.handleOrderChange('area')}
                  >
                    <FormattedMessage id="Financial.area" />
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.sort === 'created_at'}
                    direction={this.state.order}
                    order={this.state.order}
                    onClick={() => this.handleOrderChange('created_at')}
                  >
                    <FormattedMessage id="Financial.createDate" />
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.sort === 'updated_at'}
                    direction={this.state.order}
                    order={this.state.order}
                    onClick={() => this.handleOrderChange('updated_at')}
                  >
                    <FormattedMessage id="Financial.lastUpdateDate" />
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.rows.map(row => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <FormattedMessage id={['Financial', row.operation].join('.')} />
                  </TableCell>
                  <TableCell>{row.entity}</TableCell>
                  <PlanSwitch planToHide="CONTROL">
                    <TableCell>
                      {(row.credits / 100).toString().replace('.', ',')} {row.credits.unit}
                    </TableCell>
                  </PlanSwitch>
                  <TableCell>{row.area || '-'}</TableCell>
                  <TableCell>{row.created_at}</TableCell>
                  <TableCell>{row.updated_at}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={this.state.total}
            rowsPerPage={this.state.perPage}
            page={this.state.page}
            labelRowsPerPage={null}
            onChangePage={this.handleChangePage}
            rowsPerPageOptions={[this.state.perPage]}
          />
        </Fragment>
      );
    }
  }
}

export default ClientFinancialSummary;

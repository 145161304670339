import React from 'react';

// eslint-disable-next-line react/prop-types
const AutoLink = ({ text }) => {
  const delimiter =
    /((?:https?:\/\/)?(?:(?:[a-z0-9]?(?:[a-z0-9\-]{1,61}[a-z0-9])?\.[^\.|\s])+[a-z\.]*[a-z]+|(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})(?::\d{1,5})*[a-z0-9.,_\/~#&=;%+?\-\\(\\)]*)/gi;

  const renderTextWithLinks = () => {
    // eslint-disable-next-line react/prop-types
    return text.split(delimiter).map((word, index) => {
      const match = word.match(delimiter);
      if (match) {
        const url = match[0];
        return (
          <a key={index} target="_blank" href={url.startsWith('http') ? url : `http://${url}`} rel="noreferrer">
            {url}
          </a>
        );
      }
      return <span key={index}>{word}</span>;
    });
  };

  return <>{renderTextWithLinks()}</>;
};

export default AutoLink;

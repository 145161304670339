import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { BorderColor, Delete, PictureAsPdf as Pdf, Label, Input } from '@material-ui/icons';
import PropTypes from 'prop-types';

import classes from 'src/components/OrderToolbar/ActionBox/ActionBox.module.css';
import ReportOptions from 'src/components/ReportOptions/ReportOptions';
import TagModal from 'src/components/Tags/TagModal/TagModal';
import repairsIcon from 'src/images/icons/repairs.svg';

class ActionBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report: {
        open: false,
        anchor: null,
      },
      tagModal: {
        visible: false,
        anchor: null,
      },
    };
    this.orderTypeConfigurations = JSON.parse(window.sessionStorage.getItem('orderTypeConfigurations'));
  }

  loadOrderTypeConfigurations = () => {
    this.orderTypeConfigurations = JSON.parse(window.sessionStorage.getItem('orderTypeConfigurations'));
  };

  reportOptionsHandler = event => {
    const { currentTarget } = event;
    const newState = { ...this.state };
    newState.report.anchor = currentTarget;
    newState.report.open = true;
    this.setState(newState);
  };

  handleReportListClose = () => {
    const newState = { ...this.state };
    newState.report.anchor = null;
    newState.report.open = false;
    this.setState(newState);
  };

  canAddTags = () => {
    const { selectedItems } = this.props;

    if (selectedItems.size > 0) {
      return true;
    }

    return false;
  };

  openTagModal = evt => {
    const { currentTarget } = evt;
    const state = { ...this.state };
    state.tagModal.visible = true;
    state.tagModal.anchor = currentTarget;
    this.setState(state);
  };

  closeTagModal = () => {
    const state = { ...this.state };
    state.tagModal.visible = false;
    state.tagModal.anchor = null;
    this.setState(state);
  };

  render() {
    const { tagModal, report } = this.state;
    const { actionHandler, selectedItem, selectedItems, history } = this.props;

    const me = JSON.parse(window.sessionStorage.getItem('me'));
    const disabledByModality = me.disabledByModality;

    let parentSelectedItem = {};
    if (selectedItem) parentSelectedItem = selectedItem;
    if (parentSelectedItem.type && parentSelectedItem.type === 'marketplace_docusign') {
      parentSelectedItem.type = 'docusign';
    }
    if (parentSelectedItem.type && parentSelectedItem.type === 'marketplace_fichacerta') {
      parentSelectedItem.type = 'fichacerta';
    }
    const selectedOrder = selectedItem || {};
    let configurations = {};
    if (this.orderTypeConfigurations === null || Object.keys(this.orderTypeConfigurations).length === 0) {
      this.loadOrderTypeConfigurations();
    }
    if (
      selectedOrder &&
      this.orderTypeConfigurations &&
      this.orderTypeConfigurations[selectedOrder.type] &&
      this.orderTypeConfigurations[selectedOrder.type][selectedOrder.status]
    ) {
      configurations = this.orderTypeConfigurations[selectedOrder.type][selectedOrder.status];
    }

    const { flags = {}, id: orderId } = selectedOrder;

    return (
      <div className={classes.ActionBoxWrapper}>
        <Tooltip title={<FormattedMessage id="ACTIONS_MENU.TAG" />}>
          <span>
            <Button
              variant="outlined"
              disabled={!this.canAddTags() || disabledByModality}
              onClick={e => this.openTagModal(e)}
              data-cy="add-tag-button"
            >
              <Label
                style={{
                  fontSize: '1.2vw',
                  minWidth: '25px',
                  minHeight: '20px',
                  marginRight: '3px',
                }}
              />

              <FormattedMessage id="ACTIONS_MENU.TAG" />
            </Button>
          </span>
        </Tooltip>

        <Tooltip title={<FormattedMessage id="ACTIONS_MENU.EDIT" />}>
          <span>
            <Button
              variant="outlined"
              disabled={configurations.canBeEdited !== true}
              onClick={e => actionHandler(e, 'edit')}
              data-cy="edit-order-button"
            >
              <BorderColor
                style={{
                  fontSize: '1.2vw',
                  minWidth: '25px',
                  minHeight: '20px',
                  marginRight: '3px',
                }}
              />

              <FormattedMessage id="ACTIONS_MENU.EDIT" />
            </Button>
          </span>
        </Tooltip>

        <Tooltip title={<FormattedMessage id="ACTIONS_MENU.EXPORT" />}>
          <span>
            <Button
              variant="outlined"
              disabled={configurations.canBeExported !== true || disabledByModality}
              onClick={e => actionHandler(e, 'export')}
              data-cy="export-photos-button"
            >
              <Input
                style={{
                  fontSize: '1.2vw',
                  minWidth: '25px',
                  minHeight: '20px',
                  marginRight: '3px',
                }}
              />

              <FormattedMessage id="ACTIONS_MENU.EXPORT" />
            </Button>
          </span>
        </Tooltip>

        <Tooltip title={<FormattedMessage id="ACTIONS_MENU.GENERATE_PDF" />}>
          <span>
            <Button
              variant="outlined"
              disabled={configurations.canGenerateReport !== true}
              onClick={e => this.reportOptionsHandler(e)}
              data-cy="export-report-button"
            >
              <Pdf
                style={{
                  fontSize: '1.2vw',
                  minWidth: '25px',
                  minHeight: '20px',
                  marginRight: '3px',
                }}
              />

              <FormattedMessage id="ACTIONS_MENU.GENERATE_PDF" />
            </Button>
          </span>
        </Tooltip>

        <Tooltip title={<FormattedMessage id="REPAIR_BUDGET" />}>
          <span>
            <Button
              variant="outlined"
              disabled={
                configurations.canAccessRepairBudget !== true || flags?.has_damages !== true || disabledByModality
              }
              onClick={() => history.push(`/orders/${orderId}/repair-budget`)}
            >
              <img
                src={repairsIcon}
                style={{
                  fontSize: '1.2vw',
                  minWidth: '25px',
                  minHeight: '20px',
                  marginRight: '3px',
                }}
                alt={<FormattedMessage id="REPAIR_BUDGET" />}
              />

              <FormattedMessage id="REPAIR_BUDGET" />
            </Button>
          </span>
        </Tooltip>

        <Tooltip title={<FormattedMessage id="ACTIONS_MENU.DELETE" />}>
          <span>
            <Button
              variant="outlined"
              disabled={configurations.canBeDeleted !== true}
              onClick={e => actionHandler(e, 'delete')}
              data-cy="delete-order-button"
            >
              <Delete
                style={{
                  fontSize: '1.2vw',
                  minWidth: '25px',
                  minHeight: '20px',
                  marginRight: '3px',
                }}
              />

              <FormattedMessage id="ACTIONS_MENU.DELETE" />
            </Button>
          </span>
        </Tooltip>

        <ReportOptions
          show={report.open}
          closeHandler={this.handleReportListClose}
          anchorEl={report.anchor}
          options={configurations.reportsOptions}
          entityId={selectedOrder.id}
          flags={flags}
        />

        <TagModal
          show={tagModal.visible}
          closeHandler={this.closeTagModal}
          anchorEl={tagModal.anchor}
          orders={selectedItems}
        />
      </div>
    );
  }
}

ActionBox.propTypes = {
  history: PropTypes.object.isRequired,
  actionHandler: PropTypes.func.isRequired,
  selectedItems: PropTypes.object.isRequired,
  selectedItem: PropTypes.object,
};

export default ActionBox;

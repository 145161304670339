import React from 'react';
import { FormattedMessage } from 'react-intl';

import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';

const styles = {
  bootstrapInput: {
    borderRadius: 4,
    fontWeight: 'normal',
    border: '1px solid #ced4da',
    fontSize: 'calc(6px + 0.4vw)',
    padding: '8px 12px',
    width: 'calc(100% - 24px)',
    fontFamily: 'Open Sans',

    '&:focus': {
      backgroundColor: 'inherit',
      borderColor: '#80bdff',
      borderRadius: '5px',
    },

    '&.is-disabled': {
      backgroundColor: '#f4f3f4',
      borderColor: '#ced4da',
      cursor: 'not-allowed',
    },
  },
  bootstrapInputError: {
    borderRadius: 4,
    border: '1px solid red',
    fontSize: 'calc(6px + 0.4vw)',
    padding: '8px 12px',
    width: 'calc(100% - 24px)',
    fontFamily: 'Open Sans',

    '&:focus': {
      borderColor: '#80bdff',
    },
  },
  label: {
    fontFamily: 'Open Sans',
    marginTop: '0.3vh',
    fontSize: 'calc(6px + 0.3vw)',
  },
};

function RequestFormFieldSelect(props) {
  const { classes, disabled, formData, name, onChange, rawErrors, required, schema } = props;

  // if (schema.enumNames === undefined) {
  //   return null;
  // }

  let error = false;
  if (rawErrors) {
    if (rawErrors.length > 0) {
      error = true;
    }
  }

  let clients = null;
  if (schema.enumNames) {
    clients = schema.enumNames.map((option, index) => (
      <MenuItem key={index} value={schema.enum[index]}>
        {option}
      </MenuItem>
    ));
  } else {
    clients = [
      <MenuItem key={formData} value={formData}>
        <FormattedMessage id="CURRENT_USER" />
      </MenuItem>,
    ];
  }

  return (
    <div>
      {
        <label style={styles.label} htmlFor={name}>
          <FormattedMessage id={name} />
          {required ? '*' : null}
        </label>
      }
      <TextField
        className={classNames('custom', { 'is-disabled': disabled })}
        value={formData}
        id={name}
        fullWidth
        select
        disabled={disabled}
        rows={4}
        onChange={event => onChange(event.target.value)}
        data-cy={`${String(name).toLowerCase()}`}
        InputProps={{
          disableUnderline: true,
          classes: {
            input: error ? classes.bootstrapInputError : classes.bootstrapInput,
            disabled: 'is-disabled',
          },
        }}
      >
        {clients}
      </TextField>
    </div>
  );
}

export default withStyles(styles)(RequestFormFieldSelect);

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const ArrowRight = props => (
  <SvgIcon viewBox="0 0 20 17" {...props}>
    <path
      d="M12 1.68973L19 8.68973M19 8.68973L12 15.6897M19 8.68973L1 8.68973"
      stroke="#626262"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

ArrowRight.displayName = 'ArrowRight';
ArrowRight.muiName = 'SvgIcon';

export default ArrowRight;

import OrdersTypes from 'src/store/ducks/orders/types';

const initialState = {
  orders: [],
  currentOrder: null,

  ordersError: null,
  currentOrderError: null,

  ordersLoading: false,
  currentOrderloading: false,

  hasMoreOrders: true,
  orderStatusToSearch: 'all',
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case OrdersTypes.FETCH_ORDERS_REQUEST: {
      return {
        ...state,
        ordersLoading: true,
      };
    }

    case OrdersTypes.FETCH_ORDER_BY_ID_REQUEST: {
      return {
        ...state,
        currentOrderloading: true,
      };
    }

    case OrdersTypes.FETCH_ORDERS_SUCCESS: {
      const { orders, append } = action.payload;

      const updatedOrders = append ? [...state.orders, ...orders] : orders;

      return {
        ...state,
        orders: updatedOrders,
        ordersLoading: false,
      };
    }

    case OrdersTypes.FETCH_ORDER_BY_ID_SUCCESS: {
      return {
        ...state,
        currentOrder: action.payload,
        currentOrderloading: false,
      };
    }

    case OrdersTypes.FETCH_ORDERS_ERROR: {
      return {
        ...state,
        ordersError: action.payload,
        ordersLoading: false,
      };
    }

    case OrdersTypes.FETCH_ORDER_BY_ID_ERROR: {
      return {
        ...state,
        currentOrderError: action.payload,
        currentOrderloading: false,
      };
    }

    case OrdersTypes.CLEAR_ORDERS: {
      return {
        ...state,
        orders: [],
        ordersError: null,
        ordersLoading: false,
      };
    }

    case OrdersTypes.CLEAR_CURRENT_ORDER: {
      return {
        ...state,
        currentOrder: null,
        currentOrderError: null,
      };
    }

    case OrdersTypes.HAS_MORE_ORDERS: {
      return {
        ...state,
        hasMoreOrders: action.payload,
      };
    }

    case OrdersTypes.ORDERS_LOADING: {
      return {
        ...state,
        ordersLoading: action.payload,
      };
    }

    case OrdersTypes.ORDER_STATUS_TO_SEARCH: {
      return {
        ...state,
        orderStatusToSearch: action.payload || 'all',
      };
    }

    case OrdersTypes.ORDER_BY_DIR: {
      return {
        ...state,
        orderByDir: action.payload || 'asc',
      };
    }

    default: {
      return state;
    }
  }
};

export default ordersReducer;

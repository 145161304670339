const OrdersTypes = {
  FETCH_ORDERS_REQUEST: 'orders/FETCH_ORDERS_REQUEST',
  FETCH_ORDERS_SUCCESS: 'orders/FETCH_ORDERS_SUCCESS',
  FETCH_ORDERS_ERROR: 'orders/FETCH_ORDERS_ERROR',

  FETCH_ORDER_BY_ID_REQUEST: 'orders/FETCH_ORDER_BY_ID_REQUEST',
  FETCH_ORDER_BY_ID_SUCCESS: 'orders/FETCH_ORDER_BY_ID_SUCCESS',
  FETCH_ORDER_BY_ID_ERROR: 'orders/FETCH_ORDER_BY_ID_ERROR',

  CLEAR_ORDERS: 'orders/CLEAR_ORDERS',

  CLEAR_CURRENT_ORDER: 'orders/CLEAR_CURRENT_ORDER',

  HAS_MORE_ORDERS: 'orders/HAS_MORE_ORDERS',
  ORDERS_LOADING: 'orders/ORDERS_LOADING',
  ORDER_STATUS_TO_SEARCH: 'orders/ORDER_STATUS_TO_SEARCH',
  ORDER_BY_DIR: 'orders/ORDER_BY_DIR',
};

export default OrdersTypes;

import React from 'react';

import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import Modal from 'src/components/common/Modal';
import classes from 'src/components/DeleteAttachment/style.module.scss';
import { ReactComponent as TrashIcon } from 'src/images/icons/trash.svg';

const DeleteAttachmentComponent = ({
  error,
  isModalVisible,
  openModal,
  handleCloseModal,
  handleSubmit,
  validationSchema,
  intl,
}) => (
  <div className={classes.deleteAttachment}>
    <button type="button" onClick={() => openModal()} className={classes.deleteButton}>
      <TrashIcon />
    </button>
    {isModalVisible && (
      <Modal onClose={handleCloseModal}>
        <div className={classes.deleteAttachment}>
          <h3>{intl.formatMessage({ id: 'DELETE_ATTACHMENT_MESSAGE' })}</h3>
          <Formik initialValues={{ reason: '' }} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ errors, isSubmitting }) => (
              <Form>
                <div className={classes.field}>
                  <label htmlFor="reason">{intl.formatMessage({ id: 'REASON' })}</label>
                  <Field name="reason" id="reason" as="textarea" />
                  {(error || errors.reason) && <div className={classes.error}>{error || errors.reason}</div>}
                </div>
                <div className={`${classes.field} ${classes.actions}`}>
                  <button className={`${classes.button} ${classes.isGreen}`} type="submit" disabled={isSubmitting}>
                    {intl.formatMessage({ id: 'DELETE' })}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    )}
  </div>
);

DeleteAttachmentComponent.propTypes = {
  error: PropTypes.string,
  isModalVisible: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

DeleteAttachmentComponent.defaultProps = {
  error: null,
};

export default DeleteAttachmentComponent;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let TypeEntrada = props => (
  <SvgIcon {...props} viewBox="0 0 34.016 34.016">
    <path
      fill="#559AAA"
      d="M25.434,29.985H8.337c-1.089,0-1.973-0.863-1.973-1.927v-3.854
	h5.918v2.569h1.315l7.232-5.139v-1.927l-7.232-5.783h-1.315v2.568H6.365V6.859c0-1.065,0.883-1.927,1.973-1.927h5.26L10.31,6.217
	v5.139h13.151V6.217l-3.287-1.285h5.26c1.089,0,1.972,0.862,1.972,1.927v21.199C27.405,29.122,26.522,29.985,25.434,29.985z
	 M22.145,10.071h-10.52V7.501l3.288-1.285V4.289c0-1.064,0.226-1.284,1.314-1.284h1.315c1.089,0,1.315,0.22,1.315,1.284v1.928
	l3.286,1.285V10.071z M13.598,17.779v-1.286v-0.642l5.957,4.762l-5.957,4.233v-0.643v-0.643v-0.643H3.734v-5.14h8.548H13.598z"
    />
  </SvgIcon>
);
TypeEntrada = pure(TypeEntrada);
TypeEntrada.displayName = 'TypeEntrada';
TypeEntrada.muiName = 'SvgIcon';

export default TypeEntrada;

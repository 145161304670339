import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, NavLink, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import PropTypes from 'prop-types';

import ArrowLeft from 'src/components/common/icons/arrow-left.svg';
import ArrowRight from 'src/components/common/icons/arrow-right.svg';
import disabledImage from 'src/components/common/icons/clients-disabled.svg';
import enabledImage from 'src/components/common/icons/clients-enabled.svg';
// import devicesIcon from 'src/components/common/icons/devices.svg';
import ganttIcon from 'src/components/common/icons/gantt.svg';
import plusCircleImage from 'src/components/common/icons/plus-circle.svg';
import usersIcon from 'src/components/common/icons/users.svg';
import classes from 'src/components/common/SideBar/style.module.scss';
import { orderType as parseOrderType } from 'src/constants';
import getDashboardLink from 'src/helpers/get-dashboard-link';
import getDivergencesPanelLink from 'src/helpers/get-divergences-panel-link';
import CalendarIcon from 'src/svg-icons/calendar-menu';
import DivergencesIcon from 'src/svg-icons/divergences-menu';
import InspectorsIcon from 'src/svg-icons/inspectors';
import ClientIcon from 'src/svg-icons/menu-clientes';
import MenuDashboard from 'src/svg-icons/menu-dashboard';
import SettingsIcon from 'src/svg-icons/menu-settings';
import InspectionIcon from 'src/svg-icons/menu-vistorias';

const SideBar = ({ handleToggleSidebar, sidebarCollapsed }) => {
  const { divergenceCount } = useSelector(({ divergences }) => ({ divergenceCount: divergences.count }));
  const features = JSON.parse(window.sessionStorage.getItem('me'))?.features.map(feature =>
    parseOrderType.get(feature)
  );
  const params = useParams();
  const intl = useIntl();

  const { page } = params;

  const menu = useMemo(
    () => ({
      inspectors: {
        icon: <InspectorsIcon />,
        title: intl.formatMessage({ id: 'INSPECTORS' }),
        link: '/inspectors',
        requiredFeature: 'inspector',
        actions: [
          {
            link: '/inspectors/new',
            icon: plusCircleImage,
            title: intl.formatMessage({ id: 'ADD_INSPECTOR' }),
          },
        ],
        children: [
          {
            link: '/inspectors/active',
            icon: enabledImage,
            title: intl.formatMessage({ id: 'ENABLED' }),
          },
          {
            link: '/inspectors/deleted',
            icon: disabledImage,
            title: intl.formatMessage({ id: 'DISABLED' }),
          },
        ],
      },
      franchisee: {
        icon: <InspectionIcon />,
        title: intl.formatMessage({ id: 'INSPECTIONS' }),
        link: '/orders',
        children: [],
      },
      schedule: {
        icon: <CalendarIcon />,
        title: intl.formatMessage({ id: 'SCHEDULE' }),
        link: '/schedule',
        requiredFeature: 'schedule',
        children: [
          {
            link: '/schedule',
            icon: ganttIcon,
            title: intl.formatMessage({ id: 'OVERVIEW' }),
          },
        ],
      },
      clients: {
        icon: <ClientIcon />,
        title: intl.formatMessage({ id: 'CUSTOMERS' }),
        link: '/clients',
        requiredFeature: 'client',
        actions: [
          {
            link: '/clients/new',
            icon: plusCircleImage,
            title: intl.formatMessage({ id: 'ADD_CUSTOMER' }),
          },
        ],
        children: [
          {
            link: '/clients/enabled',
            icon: enabledImage,
            title: intl.formatMessage({ id: 'ENABLED' }),
          },
          {
            link: '/clients/disabled',
            icon: disabledImage,
            title: intl.formatMessage({ id: 'DISABLED' }),
          },
        ],
      },
      divergences: {
        icon: <DivergencesIcon />,
        title: intl.formatMessage({ id: 'DIVERGENCES' }),
        link: null,
        onClick: getDivergencesPanelLink,
        requiredFeature: 'divergencePanel',
      },
      dashboard: {
        icon: <MenuDashboard />,
        title: intl.formatMessage({ id: 'DASHBOARD' }),
        link: null,
        onClick: getDashboardLink,
      },
      settings: {
        icon: <SettingsIcon />,
        title: intl.formatMessage({ id: 'SETTINGS' }),
        link: '/settings',
        requiredFeature: 'user',
        actions: [
          {
            link: '/settings/users/new',
            icon: plusCircleImage,
            title: intl.formatMessage({ id: 'ADD_USER' }),
          },
        ],
        children: [
          {
            link: '/settings/users',
            icon: usersIcon,
            title: intl.formatMessage({ id: 'USERS' }),
          },
        ],
      },
    }),
    [intl]
  );

  const activeMenu = useMemo(() => menu[page] || null, [menu, page]);

  return (
    <aside className={`${classes.sideMenu} ${sidebarCollapsed ? classes.sidebarCollapsed : ''}`}>
      <button className={classes.toggleSidebar} onClick={() => handleToggleSidebar()}>
        {sidebarCollapsed ? <img src={ArrowRight} alt="" /> : <img src={ArrowLeft} alt="" />}
      </button>
      <h3>
        <span className={classes.icon} title={sidebarCollapsed ? activeMenu?.title : ''}>
          {activeMenu && activeMenu.icon}
        </span>
        {activeMenu?.title || null}
        {activeMenu?.actions && (
          <ul className={classes.actions}>
            {activeMenu.actions.map(action => {
              if (action.requiredFeature && features?.indexOf(action.requiredFeature) < 0) return null;
              return (
                <li className={classes.action} key={action.link} data-tip={action.title}>
                  <Link to={action.link}>
                    <img src={action.icon} alt={action.title} />
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </h3>
      <ul className={classes.menu}>
        {activeMenu &&
          activeMenu.children.map(child => (
            <li key={child.link}>
              <NavLink to={child.link} activeClassName={classes.isActive}>
                <span className={classes.icon}>
                  {typeof child.icon === 'string' ? <img src={child.icon} alt={child.title} /> : child.icon}
                </span>
                {child.title}
              </NavLink>
            </li>
          ))}
      </ul>
      <ul className={`${classes.menu} ${classes.isSecondary}`}>
        {Object.keys(menu).map(key => {
          if (key === page) return null;
          if (menu[key].requiredFeature && features?.indexOf(menu[key].requiredFeature) < 0) return null;

          return (
            <li key={key} title={sidebarCollapsed ? menu[key]?.title : ''}>
              {key === 'divergences' && !!divergenceCount && (
                <span className={classes.divergenceCount}>{divergenceCount}</span>
              )}
              {menu[key].link ? (
                <NavLink to={menu[key].link} activeClassName={classes.isActive}>
                  <span className={classes.icon}>{menu[key].icon}</span>
                  {menu[key].title}
                </NavLink>
              ) : (
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault();

                    if (menu[key]?.onClick && typeof menu[key]?.onClick === 'function') {
                      menu[key].onClick();
                    }
                  }}
                >
                  <span className={classes.icon}>{menu[key].icon}</span>
                  {menu[key].title}
                </a>
              )}
            </li>
          );
        })}
      </ul>
      <ReactTooltip effect="solid" />
    </aside>
  );
};

SideBar.propTypes = {
  handleToggleSidebar: PropTypes.func.isRequired,
  sidebarCollapsed: PropTypes.bool.isRequired,
};

export default SideBar;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { orderType as parseOrderType } from 'src/constants';
import { useUser } from 'src/contexts/UserContext';
import DrawerSubMenu from 'src/leftDrawer/DrawerSubMenu';
import MenuIcon from 'src/leftDrawer/MenuIcon';
import drawerMenuClasses from 'src/leftDrawer/styles.module.scss';
import SearchResultsIcon from 'src/svg-icons/inspector';

const styles = {
  bottom: {
    width: '100%',
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    marginTop: 10,
    fontSize: 16,
    fontWeight: 'bold',
    color: '#556B95',
  },
  li: {
    position: 'relative',
    paddingLeft: 18,
  },
  divergenceCount: {
    position: 'absolute',
    top: '9px',
    left: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    backgroundColor: '#9e3c5b',
    lineHeight: 1,
    fontSize: '12px',
    fontWeight: 400,
    color: '#fff',
    pointerEvents: 'none',
    zIndex: 2,
  },
};

function DrawerMenu({
  menu,
  handleSubMenuChange,
  handleMenuChange,
  handleCollapseSubMenuChange,
  classes,
  isDisplayingSearchResults,
  subMenuToOpenByIndex,
}) {
  const me = useUser();
  const { divergenceCount } = useSelector(state => ({ divergenceCount: state.divergences.count }));

  const features = me?.features.map(feature => parseOrderType.get(feature));

  const active = menu
    .filter(menuOption => menuOption.active)
    .map((menuOption, menuIndex) => (
      <div key={menuIndex}>
        <ListItem className={classes.li}>
          <ListItemIcon>
            <MenuIcon menuKey={menuOption.key} />
          </ListItemIcon>

          <FormattedMessage id={`LeftDrawer.${menuOption.key}`} />

          <SearchResultsIcon
            className={`${drawerMenuClasses.searchResultsIcon} ${
              isDisplayingSearchResults ? drawerMenuClasses.fadeIn : drawerMenuClasses.fadeOut
            }`}
          />
        </ListItem>

        <DrawerSubMenu
          subMenu={menuOption.subMenu}
          handleSubMenuChange={handleSubMenuChange}
          handleCollapseSubMenuChange={handleCollapseSubMenuChange}
          subMenuToOpenByIndex={subMenuToOpenByIndex}
        />
      </div>
    ));

  const inactive = menu
    .filter(menuOption => !menuOption.active)
    .filter(menuOption => !menuOption.requiredFeature || features?.indexOf(menuOption?.requiredFeature) >= 0)
    .map((menuOption, menuIndex) => (
      <ListItem
        className={classes.li}
        key={menuIndex}
        button
        disableRipple
        onClick={e => {
          if (menuOption?.onClick && typeof menuOption?.onClick === 'function') {
            menuOption.onClick();
          } else {
            handleMenuChange(menuIndex, e, menuOption.link, menuOption.routeLink);
          }
        }}
      >
        {menuOption.key === 'divergences' && !!divergenceCount && (
          <span className={classes.divergenceCount}>{divergenceCount}</span>
        )}

        <ListItemIcon>
          <MenuIcon menuKey={menuOption.key} />
        </ListItemIcon>

        <FormattedMessage id={`LeftDrawer.${menuOption.key}`} />
      </ListItem>
    ));

  return (
    <div style={styles.menu}>
      <div>{active}</div>

      <div style={styles.bottom}>{inactive}</div>
    </div>
  );
}

DrawerMenu.propTypes = {
  /**
   * Array with the menu options
   */
  menu: PropTypes.array.isRequired,
  /**
   * Function to handle the change of the menu
   */
  handleMenuChange: PropTypes.func.isRequired,
  /**
   * Function to handle the change of the sub-menu
   */
  handleSubMenuChange: PropTypes.func.isRequired,
  /**
   * Style classes
   */
  classes: PropTypes.object,
  /**
   * Function to handle the url change of the collapse-menu (submenu inside submenu)
   */
  handleCollapseSubMenuChange: PropTypes.func.isRequired,
  /**
   * Is displaying search results list
   */
  isDisplayingSearchResults: PropTypes.bool,
  /**
   * Default submenu index to open (if it is provided)
   */
  subMenuToOpenByIndex: PropTypes.number,
};

DrawerMenu.defaultProps = {
  classes: {},
  isDisplayingSearchResults: false,
  subMenuToOpenByIndex: null,
};

export default withStyles(styles)(DrawerMenu);

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let SectionEnviadas = props => (
  <SvgIcon {...props}>
    <path
      d="M28.308,5.708h-22.6c-1.554,0-2.812,1.271-2.812,2.825l-0.014,16.95c0,1.554,1.271,2.825,2.825,2.825h22.6
	c1.554,0,2.825-1.271,2.825-2.825V8.533C31.133,6.979,29.861,5.708,28.308,5.708z M28.308,25.482h-22.6V11.358l11.3,7.063
	l11.3-7.063V25.482z M17.008,15.595l-11.3-7.062h22.6L17.008,15.595z"
    />
  </SvgIcon>
);
SectionEnviadas = pure(SectionEnviadas);
SectionEnviadas.displayName = 'SectionEnviadas';
SectionEnviadas.muiName = 'SvgIcon';

export default SectionEnviadas;

import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { components as ReactSelectBaseComponents } from 'react-select';
import AsyncSelect from 'react-select/async';

import MenuItem from '@material-ui/core/MenuItem';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import classes from 'src/inspectionAttach/InspectionSearch.module.css';

const Option = ({ children, innerProps, innerRef, isFocused, isSelected }) => (
  <MenuItem
    buttonRef={innerRef}
    selected={isFocused}
    component="div"
    style={{
      fontWeight: isSelected ? 500 : 400,
    }}
    {...innerProps}
  >
    {children}
  </MenuItem>
);

const LoadingMessage = ({ getStyles, innerProps, ...props }) => (
  <div {...innerProps} style={getStyles('loadingMessage', props)}>
    <FormattedMessage id="loading" />
  </div>
);

const NoOptionsMessage = ({ getStyles, innerProps, ...props }) => (
  <div {...innerProps} style={getStyles('loadingMessage', props)}>
    <FormattedMessage id="noOption" />
  </div>
);

const SingleValue = innerProps => {
  return <div style={{ fontSize: '14px', margin: '0px', fontWeight: 'bold' }}>{innerProps?.data?.identification}</div>;
};

const components = {
  Option,
  LoadingMessage,
  NoOptionsMessage,
  SingleValue,
  Input: inputProps => <ReactSelectBaseComponents.Input {...inputProps} data-cy="inspection-search-select" />,
};

const PropertySearch = ({ apiUrl, handleSelect, searchPlaceholder, resetValue }) => {
  const [value, setValue] = useState('');
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMorePage, setHasMorePage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultMenuIsOpen, setDefaultMenuIsOpen] = useState(false);
  const [inputSearchValue, setInputSearchValue] = useState('');
  const [inputKey, setInputKey] = useState(1);

  useEffect(() => {
    if (resetValue) {
      setValue('');
    }
  }, [resetValue]);

  useEffect(() => {
    async function fetchData() {
      const options = await loadOptions('');
      setDefaultOptions(options);
    }
    fetchData();
  }, []);

  const onChange = selectValue => {
    setValue(selectValue);
    handleSelect(selectValue);
    setDefaultMenuIsOpen(false);
  };

  const onInputChange = inputValue => {
    if (inputSearchValue === inputValue) return;
    setInputSearchValue(inputValue);
    if (inputValue.length <= 2) {
      setDefaultOptions([]);
      setDefaultMenuIsOpen(false);
      setInputKey(inputKey + 1);
      return;
    }
    handleFetchSuggestionsDebounced(inputValue);
  };

  const loadOptions = async (inputValue, p = 1) => {
    const { data: suggestions } = await API.get(`${apiUrl}?search=${inputValue}&page=${p}`);

    const mappedSuggestions = suggestions?.map(property => {
      const label = (
        <div style={{ lineHeight: '1', textAlign: 'left' }}>
          <p style={{ fontSize: '14px', margin: '0px', fontWeight: 'bold' }}>{property?.identification}</p>

          <p style={{ fontSize: '12px', margin: '0px' }}>
            {property?.address?.address}, {property?.address?.number}, {property?.address?.neighborhood},{' '}
            {property?.address?.city} - {property?.address?.state}, {property?.address?.zip_code}
          </p>
        </div>
      );
      return {
        value: property?.public_id,
        label,
        ...property,
      };
    });
    return mappedSuggestions;
  };

  const handleFetchSuggestions = async inputValue => {
    setIsLoading(true);
    setDefaultMenuIsOpen(true);
    setHasMorePage(true);
    setPage(1);

    const options = await loadOptions(inputValue);

    setDefaultOptions(options);

    setInputKey(inputKey + 1);
    setIsLoading(false);
  };

  const handleLoadMore = async () => {
    setDefaultMenuIsOpen(true);
    if (hasMorePage) {
      setIsLoading(true);
      setPage(page + 1);
      const options = await loadOptions(inputSearchValue, page + 1);
      if (options.length) {
        setDefaultOptions([...defaultOptions, ...options]);
        setHasMorePage(true);
      } else {
        setHasMorePage(false);
      }
      setIsLoading(false);
    }
  };

  const handleFetchSuggestionsDebounced = debounce(handleFetchSuggestions, 500);

  return (
    <div className={classes.Search}>
      <AsyncSelect
        key={inputKey}
        defaultInputValue={inputSearchValue}
        onInputChange={debounce(onInputChange, 500)}
        value={value}
        placeholder={searchPlaceholder}
        components={components}
        // loadOptions={handleFetchSuggestionsDebounced}
        onMenuScrollToBottom={handleLoadMore}
        defaultOptions={defaultOptions}
        onChange={onChange}
        isLoading={isLoading}
        autoFocus
        defaultMenuIsOpen={defaultMenuIsOpen}
        escapeClearsValue
      />
    </div>
  );
};

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.func,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
};

LoadingMessage.propTypes = {
  getStyles: PropTypes.func,
  innerProps: PropTypes.object,
};

NoOptionsMessage.propTypes = {
  getStyles: PropTypes.func,
  innerProps: PropTypes.object,
};

SingleValue.propTypes = {
  innerProps: PropTypes.object,
};

PropertySearch.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  apiUrl: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string,
  resetValue: PropTypes.number,
};

export default PropertySearch;

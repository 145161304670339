import React from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as InspectionScheduled } from 'src/components/common/icons/activity-log/inspection-scheduled.svg';
import { ReactComponent as OrderCreated } from 'src/components/common/icons/activity-log/order-created.svg';

const icon = {
  CHECKIN: <InspectionScheduled />
};

const Icon = ({ action }) => icon[action] || <OrderCreated />;

Icon.propTypes = {
  action: PropTypes.string.isRequired,
};

export default Icon;

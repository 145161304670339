import React from 'react';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import classes from 'src/inspectionAttach/AttachDetails.module.css';
import AttachDetailsFooter from 'src/inspectionAttach/AttachDetailsFooter';

const AttachDetails = ({ data, handleCancel, handleConfirm }) => (
  <Grid container alignItems="center" className={classes.Grid}>
    <Grid item xs={12}>
      <span>{data.building_id}</span>
    </Grid>
    <Grid item xs={12}>
      <span>{data.client}</span>
    </Grid>
    <Grid item xs={12}>
      <span>{data.code}</span>
    </Grid>
    <Grid item xs={12}>
      <AttachDetailsFooter handleCancel={handleCancel} handleConfirm={handleConfirm} />
    </Grid>
  </Grid>
);

AttachDetails.propTypes = {
  /**
   * Object with the selected data
   */
  data: PropTypes.object.isRequired,
  /**
   * Function to confirm the selection
   */
  handleConfirm: PropTypes.func.isRequired,
  /**
   * Function to cancel the selection
   */
  handleCancel: PropTypes.func.isRequired,
};

export default AttachDetails;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import CardList from 'src/components/common/CardList';
import Spinner from 'src/components/common/Spinner';
import classes from 'src/components/FranchiseeSettingsPage/UserList/style.module.scss';
import UserCard from 'src/components/FranchiseeSettingsPage/UserList/UserCard';
import { setOptions, setQuery } from 'src/store/ducks/filter';
import { showToast } from 'src/store/ducks/toasts';

let source = null;
const ClientList = ({
  cards: cardControl,
  query,
  setOptions,
  setQuery, // eslint-disable-line no-shadow
}) => {
  const isInitialMount = useRef(true);
  const [cards, setCards] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { lastReload } = cardControl;

  const fetchCards = useCallback(
    async page => {
      if (source !== null) {
        source.cancel();
      }

      source = API.CancelToken.source();

      try {
        setIsLoading(true);
        const { data } = await API.get('/users', {
          params: {
            search: query.text ? query.text : null,
            page: page + 1,
          },
          cancelToken: source.token,
        });

        setCards(data.map(item => ({ data: item })));

        setIsLoading(false);
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        }
        dispatch(
          showToast({
            type: 'error',
            title: intl.formatMessage({ id: 'ERROR' }),
            text: intl.formatMessage({ id: 'USER_LOAD_LIST_ERROR' }),
            duration: 3000,
          })
        );
        // setIsLoading(false);
        console.log(err);
      }
    },
    [query.text, dispatch, intl]
  );

  // set possible filter options and load initial cards
  useEffect(() => {
    setOptions({
      basic: ['text'],
    });

    return () => {
      if (source) source.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isInitialMount.current) {
      setQuery({});
    } else {
      fetchCards(0);
    }
  }, [lastReload]); // eslint-disable-line react-hooks/exhaustive-deps

  // listen for changes and filter cards
  useEffect(() => {
    // do not fetch cards on mount
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    // clear right panel when using filter
    if (history.location.pathname.includes('/commercial')) {
      if (Object.entries(query).length > 0 && history.location.pathname !== '/commercial/settings/users') {
        history.push('commercial/settings/users');
      }
    } else {
      if (Object.entries(query).length > 0 && history.location.pathname !== '/settings/users') {
        history.push('/settings/users');
      }
    }

    fetchCards(0);
  }, [fetchCards, history, query]);

  const loadMore = page => {
    fetchCards(page, true);
  };

  if (isLoading) {
    return (
      <div className={classes.spinner}>
        <Spinner />
      </div>
    );
  }

  return <CardList cards={cards} Element={UserCard} loadMore={loadMore} />;
};

ClientList.propTypes = {
  cards: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  setOptions: PropTypes.func.isRequired,
  setQuery: PropTypes.func.isRequired,
};

const mapStateToProps = ({ cards, filter }) => ({
  query: filter.query,
  cards,
});

export default connect(mapStateToProps, { setOptions, setQuery })(ClientList);

import React from 'react';

import PropTypes from 'prop-types';

import classes from 'src/components/common/Form/style.module.scss';

const FormComponent = ({ schema, Form, ...props }) => (
  <div className={classes.form}>
    <Form schema={schema} {...props} />
  </div>
);

FormComponent.propTypes = {
  schema: PropTypes.object.isRequired,
  Form: PropTypes.object.isRequired,
};

export default FormComponent;

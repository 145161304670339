import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

import classes from 'src/components/UnknownError/UnknownError.module.css';

class UnknownError extends Component {
  state = {
    show: false,
  };

  closeHandler = () => {
    this.setState({ show: false });
  };

  render() {
    const classesUpdated = [classes.UnknownError];
    const { show } = this.state;
    const { intl } = this.props;

    if (show) {
      classesUpdated.push(classes.active);
    }

    return (
      <div className={show ? [classes.UnknownError, classes.active].join(' ') : classes.UnknownError}>
        {intl.formatMessage({ id: 'GENERAL_SUPPORT_ERROR_MESSAGE' })}
        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.closeHandler}>
          <CloseIcon />
        </IconButton>
      </div>
    );
  }
}

UnknownError.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(UnknownError);

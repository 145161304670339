// Actions
export const ACTIONS = {
  SET_COUNT: 'divergences/SET_COUNT',
};

// Reducer
const initialState = {
  count: null,
};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.SET_COUNT:
      return {
        ...state,
        count: action.payload,
      };
    default:
      return state;
  }
}

// actions
export const setCount = (count) => ({
  type: ACTIONS.SET_COUNT,
  payload: count
});

import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import update from 'immutability-helper';
import PropTypes from 'prop-types';

import 'bootstrap/dist/css/bootstrap.css';

import API from 'src/apiRequest';
import ApiContext from 'src/contexts/ApiContext';
import Loading from 'src/Loading';
import FormError from 'src/orderForm/FormError';
import NoAvailablePackages from 'src/orderForm/NoAvailablePackages';
import OrderForm from 'src/orderForm/OrderForm/OrderForm';
import OrderFormPageFooter from 'src/orderForm/OrderForm/OrderFormPage/OrderFormPageFooter';
import OrderFormPageHeader from 'src/orderForm/OrderForm/OrderFormPage/OrderFormPageHeader';
import PackageConsumption from 'src/orderForm/PackageConsumption';
import uiSchemaDisabled from 'src/orderForm/uiSchema/uiSchemaDisabled.json';
import uiSchemaImobiliariaDigital from 'src/orderForm/uiSchema/uiSchemaImobiliariaDigital.json';
import uiSchema from 'src/orderForm/uiSchema/uiSchemaNew.json';
import { syncTags } from 'src/store/ducks/tags';
import { showToast } from 'src/store/ducks/toasts';

const styles = {
  container: {
    fontWeight: 'bold',
    alignItems: 'center',
  },
  paper: {
    marginLeft: '1vw',
    marginRight: '1vw',
    paddingBottom: '0.5vw',
    paddingTop: '0.5vw',
    fontSize: 'calc(8px + 0.4vw)',
  },
  form: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fontSize: 'calc(6px + 0.3vw)',
  },
};

class OrderFormPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      selectedPackage: '',
      newOrderStatus: 'SAVE_ORDER', // 'SAVE_ORDER' -> 'PURCHASE_PACKAGE' -> 'CONFIRM_ORDER'
      errors: 0,
      loading: true,
      schema: {},
      uiSchema,
      uiSchemaDisabled,
      formData: {},
      packageConsume: {},
      apiStatus: 'IDLE',
    };
    this.accessToken = null;
    this.tokenType = null;
  }

  async componentDidMount() {
    const { uiSchema: uiSchemaState, uiSchemaDisabled: uiSchemaDisabledState } = this.state;
    const { newOrderType, previousOrderId } = this.props;

    this.accessToken = window.sessionStorage.getItem('accessToken');
    this.tokenType = window.sessionStorage.getItem('tokenType');

    const params = new URLSearchParams();

    params.append('type', newOrderType);

    if (previousOrderId !== null) params.append('previous_id', previousOrderId);

    let newUiSchema = { ...uiSchemaState };

    if (newOrderType === 'marketplace_docusign') {
      newUiSchema['ui:order'] = ['clients', '*'];
      this.setState({ uiSchema: newUiSchema });
    }

    if (newOrderType === 'marketplace_fichacerta') {
      newUiSchema['ui:order'] = ['clients', 'desired_property', 'main_suitor', 'main_suitor_address', 'suitors', '*'];
      this.setState({ uiSchema: newUiSchema });
    }

    try {
      const { data } = await API.get('/orders/new', { params });

      const partiesInvolvedUI = {};
      const partiesInvolvedUIDisabled = {};

      // Add parties involved UISchema
      if (data.schema?.definitions?.parties_involved) {
        Object.keys(data.schema.definitions.parties_involved.properties).forEach(key => {
          if (data.schema.definitions.parties_involved.properties[key].$ref.search('people') === -1) {
            partiesInvolvedUI[key] = { 'ui:field': 'person' };

            partiesInvolvedUIDisabled[key] = {
              'ui:field': 'person',
              'ui:disabled': true,
            };
          } else {
            partiesInvolvedUI[key] = {
              items: {
                'ui:field': 'people',
                'ui:title': data.schema.definitions.parties_involved.properties[key].title,
              },
            };

            partiesInvolvedUIDisabled[key] = {
              items: {
                'ui:field': 'people',
                'ui:title': data.schema.definitions.parties_involved.properties[key].title,
                'ui:disabled': true,
              },
            };
          }
        });
      }

      newUiSchema = update(newUiSchema, { parties_involved: { $merge: partiesInvolvedUI } });

      const newUiSchemaDisabled = update(uiSchemaDisabledState, {
        parties_involved: { $merge: partiesInvolvedUIDisabled },
      });

      let newFormData = {};

      if (data?.data !== undefined) {
        if (Object.keys(data.data).length > 0) {
          const inspection = {
            inspection: {
              id: previousOrderId,
              name: data.data.details.building_id,
            },
          };

          const parties_involved = { ...data.data.parties_involved, inspector: {} };

          newFormData = update(data.data, {
            inspection_type: {
              $set: data.schema.properties.inspection_type.default,
            },
          });
          newFormData = update(newFormData, { previous_inspection: { $set: inspection } });
          newFormData = update(newFormData, { parties_involved: { $set: parties_involved } });
        }
      }

      if (newOrderType === 'visitaDigital' || newOrderType === 'fotosDeCaptacao') {
        newUiSchema = { ...newUiSchema, ...uiSchemaImobiliariaDigital };
      }

      if (newOrderType === 'tourVirtual') {
        newUiSchema = {
          ...newUiSchema,
          'ui:order': [
            'inspection_type',
            'franchisees',
            'clients',
            'details',
            'address',
            'accessInformation',
            'accompaniedInspection',
            '*',
            'recommendation',
          ],
        };
      }

      this.setState({
        schema: data.schema,
        formData: newFormData,
        uiSchema: newUiSchema,
        uiSchemaDisabled: newUiSchemaDisabled,
      });
    } catch (err) {
      console.debug('[DEBUG] componentDidMount error ', err);
    } finally {
      this.setState({ loading: false });
    }
  }

  /**
   * @description Function to handle the submit of the form
   */
  handleSubmit = () => {
    this.setState({ submitted: true });
    this.form.submitButton.click();
  };

  /**
   * @description Function to handle the confirmatio of the order, save the new order
   */
  handleConfirm = () => {
    this.form.submitButton.click();
  };

  /**
   * @description Function to handle the selection of a package
   */
  handleSelectPackage = event => this.setState({ selectedPackage: event.target.value });

  /**
   * @description Function to handle the purchase of a package
   */
  handlePurchase = () => {
    const { formData, selectedPackage } = this.state;
    const { intl } = this.props;

    if (selectedPackage !== '') {
      API.post(`/packages/${selectedPackage}/clients`, { client_id: formData.clients.client })
        .then(() => {
          this.setState({ newOrderStatus: 'SAVE_ORDER' }, () => this.form.submitButton.click());
        })
        .catch(err => {
          console.debug(`[DEBUG] POST /packages/${selectedPackage}/clients error `, err);

          if (err.response && err.response.status && err.response.status === 403) {
            this.props.showToast({
              type: 'error',
              title: intl.formatMessage({ id: 'ERROR' }),
              text: intl.formatMessage({ id: 'ORDER_CREATE_ERROR' }),
              duration: 5000,
            });
          }
        });
    } else {
      this.form.submitButton.click();
    }
  };

  /**
   * @description Function to handle errors of the form
   */
  handleError = errorsNumber => this.setState({ errors: errorsNumber });

  /**
   * @description Function to handle the cancel button onClick
   */
  handleCancel = () => {
    const { newOrderStatus } = this.state;
    const { cancelNewOrder, intl, history } = this.props;

    const confMessage = intl.formatMessage({ id: 'confirmation.cancelNewOrder' });

    switch (newOrderStatus) {
      case 'SAVE_ORDER':
        if (window.confirm(confMessage)) {
          cancelNewOrder();

          history.push('/orders');
        }

        break;
      default:
        this.setState({ newOrderStatus: 'SAVE_ORDER' });

        history.push('/orders');
    }
  };

  handleLoading = () => this.setState(prevState => ({ loading: !prevState.loading }));

  /**
   * @description Function called to submit the form after the form validation
   */
  onSubmit = ({ formData }) => {
    const { newOrderStatus } = this.state;
    const { successfulOrderCreation, updateOrderItem } = this.props;

    const orderAction = ['docusign', 'visitaDigital', 'fotosDeCaptacao', 'fichacerta'].includes(formData.type)
      ? 'FINALIZE'
      : newOrderStatus;

    switch (orderAction) {
      case 'SAVE_ORDER':
        this.setState({ apiStatus: 'WORKING' });

        API.post('/financial/packageconsume', JSON.stringify(formData))
          .then(({ data }) => {
            if (data.hasRequiredCredits) {
              this.setState({
                newOrderStatus: 'CONFIRM_ORDER',
                packageConsume: data,
              });
            } else {
              this.setState({
                newOrderStatus: 'PURCHASE_PACKAGE',
                packageConsume: data,
                formData,
              });
            }
            this.setState({ apiStatus: 'IDLE' });
          })
          .catch(err => {
            console.debug('[DEBUG] POST /financial/packageconsume error ', err);

            this.setState({ apiStatus: 'ERROR' });
          });

        break;

      default:
        this.setState({ apiStatus: 'WORKING' });

        API.post('/orders', JSON.stringify(formData))
          .then(res => {
            const data = res.data.data ? { ...res.data.data } : { ...res.data };

            updateOrderItem(data);
            successfulOrderCreation(data);

            this.props.syncTags();
            this.setState({ apiStatus: 'IDLE' });
          })
          .catch(err => {
            this.setState({ apiStatus: 'ERROR' });

            if (err.response.data) {
              this.setState({ errorMessage: err?.response?.data?.message || 'UNKNOWN_ERROR' });
            } else {
              console.debug('[DEBUG] POST /orders error ', err);
            }
          });
    }
  };

  render() {
    const {
      apiStatus,
      errorMessage,
      errors,
      formData,
      loading,
      newOrderStatus,
      packageConsume,
      selectedPackage,
      schema,
      submitted,
      uiSchema: uiSchemaState,
      uiSchemaDisabled: uiSchemaDisabledState,
    } = this.state;

    const { classes, inspectionIcon, newOrderType, previousOrderFile, updateOrderItem } = this.props;

    let handleSecondButton;
    let textSecondButton;
    let footerContent;

    switch (newOrderStatus) {
      case 'PURCHASE_PACKAGE':
        handleSecondButton = this.handlePurchase;
        textSecondButton = 'BUTTON.PURCHASE'; // <FormattedMessage id="purchase"/>;
        footerContent = (
          <NoAvailablePackages
            data={packageConsume}
            handleSelectPackage={this.handleSelectPackage}
            selectedPackage={selectedPackage}
          />
        );
        break;

      case 'CONFIRM_ORDER':
        handleSecondButton = this.handleConfirm;
        textSecondButton = 'BUTTON.CONFIRM'; // <FormattedMessage id="confirm"/>;
        footerContent = <PackageConsumption data={packageConsume} />;
        break;

      default:
        handleSecondButton = this.handleSubmit;
        textSecondButton = 'BUTTON.SAVE'; // <FormattedMessage id="save"/>;
    }

    if (errors > 0 || errorMessage) {
      footerContent = (
        <FormError>
          {errorMessage ? (
            <FormattedMessage
              id={errorMessage}
              values={{
                a: children => (
                  <a
                    href={`/marketplace/docusign#access_token=${this.accessToken}&token_type=${this.tokenType}&expires_in=`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {children}
                  </a>
                ),
              }}
            />
          ) : (
            <FormattedMessage
              id="orderForm.footerError"
              values={{
                errors,
              }}
            />
          )}
        </FormError>
      );
    }

    let content;

    if (loading) {
      content = <Loading />;
    } else {
      content = (
        <div>
          <Paper style={styles.paper} elevation={0}>
            <Grid container classes={{ container: classes.container }}>
              <OrderFormPageHeader inspectionIcon={inspectionIcon} />

              <Grid container>
                <Grid item xs={12} style={styles.form}>
                  <OrderForm
                    ref={form => {
                      this.form = form;
                    }}
                    submitted={submitted}
                    updateOrderItem={updateOrderItem}
                    handleError={this.handleError}
                    formData={formData}
                    handleLoading={this.handleLoading}
                    schema={schema}
                    uiSchema={newOrderStatus === 'SAVE_ORDER' ? uiSchemaState : uiSchemaDisabledState}
                    onSubmit={this.onSubmit}
                    previousOrderFile={previousOrderFile}
                    orderType={newOrderType}
                  />
                </Grid>
              </Grid>

              <OrderFormPageFooter
                handleFirstButton={this.handleCancel}
                handleSecondButton={handleSecondButton}
                textFirstButton="BUTTON.CANCEL"
                textSecondButton={textSecondButton}
              >
                {footerContent}
              </OrderFormPageFooter>
            </Grid>
          </Paper>
        </div>
      );
    }

    return <ApiContext.Provider value={{ status: apiStatus }}>{content}</ApiContext.Provider>;
  }
}

OrderFormPage.propTypes = {
  cancelNewOrder: PropTypes.func.isRequired,
  classes: PropTypes.object,
  history: PropTypes.object.isRequired,
  inspectionIcon: PropTypes.object,
  intl: PropTypes.object.isRequired,
  newOrderType: PropTypes.string.isRequired,
  previousOrderId: PropTypes.string,
  previousOrderFile: PropTypes.object,
  showToast: PropTypes.func.isRequired,
  successfulOrderCreation: PropTypes.func.isRequired,
  syncTags: PropTypes.func.isRequired,
  updateOrderItem: PropTypes.func.isRequired,
};

OrderFormPage.defaultProps = {
  classes: {},
  inspectionIcon: null,
  previousOrderFile: null,
  previousOrderId: null,
};

const mapDispatchToProps = {
  showToast,
  syncTags,
};

export default withStyles(styles)(connect(null, mapDispatchToProps)(injectIntl(withRouter(OrderFormPage))));

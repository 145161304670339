import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let TypeConferencia = props => (
  <SvgIcon {...props} viewBox="0 0 34.016 34.016">
    <path
      fill="#B15688"
      d="M25.497,29.97H8.266c-1.098,0-1.987-0.863-1.987-1.928v-1.929
	l1.184-0.766c1.13,0.877,2.556,1.408,4.117,1.408c3.659,0,6.63-2.878,6.63-6.428c0-3.55-2.971-6.428-6.63-6.428
	c-2.171,0-4.092,1.018-5.3,2.583V6.83c0-1.065,0.89-1.928,1.987-1.928h5.3l-3.312,1.286v5.142h13.255V6.187l-3.312-1.286h5.301
	c1.098,0,1.987,0.863,1.987,1.928v21.212C27.484,29.106,26.595,29.97,25.497,29.97z M22.184,10.043H11.579V7.472l3.312-1.285V4.258
	c0-1.065,0.228-1.285,1.327-1.285h1.326c1.1,0,1.326,0.22,1.326,1.285v1.929l3.312,1.285V10.043z M6.279,20.328
	c0-2.84,2.373-5.143,5.3-5.143c2.927,0,5.303,2.302,5.303,5.143c0,2.84-2.376,5.143-5.303,5.143c-1.594,0-3.019-0.686-3.99-1.765
	l-4.995,3.248l-1.106-1.602l5.098-3.315C6.39,21.502,6.279,20.929,6.279,20.328z M11.579,23.542c1.829,0,3.312-1.438,3.312-3.214
	c0-1.774-1.483-3.215-3.312-3.215c-1.83,0-3.313,1.44-3.313,3.215C8.266,22.104,9.749,23.542,11.579,23.542z"
    />
  </SvgIcon>
);
TypeConferencia = pure(TypeConferencia);
TypeConferencia.displayName = 'TypeConferencia';
TypeConferencia.muiName = 'SvgIcon';

export default TypeConferencia;

import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/AddressSection.module.css';
import Address from 'src/svg-icons/address';

function AddressSection({ address }) {
  return (
    <Grid item xs={12} data-cy="order-details-address">
      <div className={classes.Header}>
        <Address className={classes.Icon} />
        <FormattedMessage id="address" />
      </div>
      {address.zipcode ? (
        <div className={classes.Information} data-cy="order-details-address-zipcode">
          {address.zipcode}
        </div>
      ) : null}
      <div className={classes.Information} data-cy="order-details-address-street-number-complement">
        {address.street}
        <span>, </span>
        {address.number}
        {!!address.complement &&
          (
            <span>
              <span>, </span>
              {address.complement}
            </span>
          )
        }
      </div>
      <div className={classes.Information} data-cy="order-details-address-neighborhood-city-state">
        {address.neighborhood}
        <span>, </span>
        {address.city}
        <span> - </span>
        {address.state}
      </div>
    </Grid>
  );
}

AddressSection.propTypes = {
  /**
   * Object that contains the address information
   */
  address: PropTypes.object.isRequired,
};

export default AddressSection;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const ReportPhotos = props => (
  <SvgIcon {...props}>
    <path
      fill="#1D487A"
      d="M21,19V5c0-1.1-0.9-2-2-2H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14C20.1,21,21,20.1,21,19z M8.5,13.5l2.5,3
      l3.5-4.5l4.5,6H5L8.5,13.5z"
    />
    <path fill="none" d="M0,0h24v24H0V0z" />
  </SvgIcon>
);

ReportPhotos.displayName = 'ReportPhotos';
ReportPhotos.muiName = 'SvgIcon';

export default ReportPhotos;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import PropTypes from 'prop-types';

import InspectorDetails from 'src/components/InspectorsPage/InspectorDetails';
import InspectorFiltersDetails from 'src/components/InspectorsPage/InspectorFiltersDetails';
import InspectorForm from 'src/components/InspectorsPage/InspectorForm';
import InspectorList from 'src/components/InspectorsPage/InspectorList';
import ExportGeneralStatistics from 'src/components/InspectorsPage/InspectorList/ExportGeneralStatistics';
import InspectorReport from 'src/components/InspectorsPage/InspectorReport';
import Section from 'src/components/Page/Section';
import { syncInspectors } from 'src/store/ducks/inspectors';

// eslint-disable-next-line no-shadow
const InspectorsPage = ({ syncInspectors }) => {
  useEffect(() => {
    syncInspectors();
  }, [syncInspectors]);

  return (
    <>
      <Section cols={6}>
        <Switch>
          <Route exact path="/inspectors">
            <Redirect to="/inspectors/active" />
          </Route>
          <Route path="/inspectors/new" component={InspectorList} />
          <Route path="/inspectors/:status" component={InspectorList} />
        </Switch>
      </Section>
      <Section cols={6}>
        <Switch>
          <Route path="/inspectors/new" render={() => <InspectorForm action="new" />} />
          <Route path="/inspectors/active/:id/edit" render={() => <InspectorForm action="edit" />} />
          <Route path="/inspectors/:status/:id/report" component={InspectorReport} />
          <Route path="/inspectors/active/:id/filters/edit" component={InspectorFiltersDetails} />
          <Route path="/inspectors/:status/:id" component={InspectorDetails} />
          <Route path="/inspectors/:status" component={ExportGeneralStatistics} />
        </Switch>
      </Section>
    </>
  );
};

InspectorsPage.propTypes = {
  syncInspectors: PropTypes.func.isRequired,
};

export default connect(null, { syncInspectors })(InspectorsPage);

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const Details = props => (
  <SvgIcon {...props} viewBox="0 0 34.016 34.016">
    <path
      fill="#393939"
      d="M17.008,2.008c-8.271,0-15,6.729-15,15c0,8.271,6.729,15,15,15c8.271,0,15-6.729,15-15
        C32.008,8.737,25.279,2.008,17.008,2.008z M17.008,30.008c-7.168,0-13-5.832-13-13c0-7.168,
        5.832-13,13-13c7.168,0,13,5.832,13,13C30.008,24.176,24.176,30.008,17.008,30.008z"
    />
    <path
      fill="#393939"
      d="M17.008,10.232c-4.517,0-8.375,2.809-9.938,6.775c1.563,3.967,5.42,6.775,9.938,6.775
        c4.517,0,8.374-2.809,9.938-6.775C25.382,13.042,21.524,10.232,17.008,10.232z M17.008,
        21.524c-2.493,0-4.517-2.023-4.517-4.517s2.024-4.517,4.517-4.517s4.517,2.024,4.517,
        4.517S19.501,21.524,17.008,21.524z"
    />
    <path
      fill="#393939"
      d="M17.008,14.298c-1.5,0-2.71,1.21-2.71,2.71s1.21,2.71,2.71,2.71s2.71-1.21,2.71-2.71
        S18.508,14.298,17.008,14.298z"
    />
  </SvgIcon>
);

Details.displayName = 'Details';
Details.muiName = 'SvgIcon';

export default Details;

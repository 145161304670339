import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const FinishedOrders = props => (
  <SvgIcon {...props} viewBox="0 0 60 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M33.75 26.25H54.375L33.75 5.625V26.25ZM7.5 0H37.5L60 22.5V67.5C60 71.625 56.625 75 52.5 75H7.5C3.3375 75 0 71.625 0 67.5V7.5C0 3.3375 3.3375 0 7.5 0ZM27 61.725L44.8125 43.9125L40.425 38.625L27 52.05L21.0375 46.125L16.6875 50.475L27 61.725Z"
      fill="white"
    />
  </SvgIcon>
);

FinishedOrders.displayName = 'FinishedOrders';
FinishedOrders.muiName = 'SvgIcon';

export default FinishedOrders;

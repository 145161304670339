import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let Previous = props => (
  <SvgIcon {...props}>
    <g>
      <g>
        <path
          fill="#393939"
          d="M17.008,2.008c-8.271,0-15,6.729-15,15c0,8.271,6.729,15,15,15c8.271,0,15-6.729,15-15
    			C32.008,8.737,25.279,2.008,17.008,2.008z M17.008,30.008c-7.168,0-13-5.832-13-13s5.832-13,13-13s13,5.832,13,13
    			S24.176,30.008,17.008,30.008z"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#393939"
        d="M18.289,20.438H9.122v-6.875h9.167v-3.438l8.027,7.27l-8.027,6.48
    		V20.438z"
      />
    </g>
  </SvgIcon>
);
Previous = pure(Previous);
Previous.displayName = 'Previous';
Previous.muiName = 'SvgIcon';

export default Previous;

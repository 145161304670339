import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import apiRequest from 'src/apiRequest';
import InspectorFormComponent from 'src/components/InspectorsPage/InspectorForm/InspectorFormComponent';
import { useUser } from 'src/contexts/UserContext';
import { masksByCountry } from 'src/helpers/mask';
import AddressService from 'src/services/addressService';
import { reloadCards } from 'src/store/ducks/cards';
import { showToast } from 'src/store/ducks/toasts';

const InspectorFormContainer = ({ action }) => {
  const [busy, setBusy] = useState(false);
  const [loading, setLoading] = useState(true);
  const [inspector, setInspector] = useState({});

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);

  const isSupportModeActive = useCallback(() => {
    return document.cookie.includes('SupportMode=true');
  }, []);

  const dispatch = useDispatch();

  const history = useHistory();
  const { id } = useParams();

  const intl = useIntl();

  const me = useUser();
  const addressService = new AddressService(me?.profile?.country);

  const isBrazilianFranchisee = me?.profile?.country === 'BR' || !me?.profile?.country;
  const franchiseeCountry = me?.profile?.country || 'BR';

  const zipcodeMask = masksByCountry[franchiseeCountry].ZIPCODE_MASK;
  const cpfMask = masksByCountry[franchiseeCountry].CPF_MASK;
  const phoneMask = masksByCountry[franchiseeCountry].PHONE_MASK;
  const cellphoneMask = masksByCountry[franchiseeCountry].CELLPHONE_MASK;
  const cellphoneMaskLength = masksByCountry[franchiseeCountry].CELLPHONE_MASK_LENGTH;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: inspector,
    onSubmit: async values => {
      setBusy(true);

      try {
        const payload = { ...values };

        if (payload.avatarToUpload?.name) {
          const fileData = new FormData();
          fileData.set('file', payload.avatarToUpload);

          const { data } = await apiRequest.post('/upload-avatar', fileData);
          payload.avatar = data.filepath;
          delete payload.avatarToUpload;
        }

        if (action === 'edit') {
          await apiRequest.put(`/inspectors/${id}`, payload);
        } else {
          await apiRequest.post('/inspectors', payload);
        }

        let redirectURL;
        let message;

        if (action === 'edit') {
          redirectURL = `/inspectors/active/${id}`;
          message = intl.formatMessage({ id: 'INSPECTOR_UPDATED_SUCCESS' });
        } else {
          redirectURL = '/inspectors';
          message = intl.formatMessage({ id: 'INSPECTOR_CREATED_SUCCESS' });
          dispatch(reloadCards());
        }

        dispatch(
          showToast({
            type: 'success',
            title: intl.formatMessage({ id: 'SUCCESS' }),
            text: message,
            duration: 3000,
          })
        );

        history.replace(redirectURL);
      } catch (err) {
        console.debug('[DEBUG] onSubmit error ', err);

        let errorMessage = intl.formatMessage({ id: 'DATA_SAVE_ERROR' });

        if (err?.response) {
          errorMessage = intl.formatMessage({ id: err.response?.data?.message });
        }

        dispatch(
          showToast({
            type: 'error',
            title: intl.formatMessage({ id: 'ERROR' }),
            text: errorMessage,
            duration: 3000,
          })
        );
      } finally {
        setBusy(false);
      }
    },
  });

  const handleNormalizeCityName = (name = '') => name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const handleFetchNeighborhoods = async city => {
    const fetchedNeighborhoods = await addressService.getNeighborhoodsByCity(city);

    setNeighborhoods(fetchedNeighborhoods);

    return fetchedNeighborhoods;
  };

  const handleFetchCities = async state => {
    const fetchedCities = await addressService.getCitiesByState(state);

    setCities(fetchedCities);

    return fetchedCities;
  };

  const handleFetchAddress = async zipcode => {
    const fetchedAddress = await addressService.getLocationByZipcode(zipcode);

    await handleFetchCities(fetchedAddress.state_id);
    await handleFetchNeighborhoods(fetchedAddress.city_id);

    return fetchedAddress;
  };

  const handleFormChange = async event => {
    const { name } = event.target;
    let { value } = event.target;

    try {
      const handleFormChangeFields = {
        'address.zipcode': async () => {
          if (!addressService.validateZipcode(value)) return;

          const address = await handleFetchAddress(value);

          formik.setFieldValue('address.state', address.state);
          formik.setFieldValue('address.city', address.city);
          formik.setFieldValue('address.neighborhood', address.neighborhood);
          formik.setFieldValue('address.street', address.street);
          formik.setFieldValue('address.state_id', address.state_id);
          formik.setFieldValue('address.city_id', address.city_id);
          formik.setFieldValue('address.neighborhood_id', address.neighborhood_id);
        },
        'address.state_id': async () => {
          if (!value) return;
          if (isBrazilianFranchisee) value = Number(value);

          const fetchedCities = await handleFetchCities(value);
          const selectedStateName = states.find(state => state.id === value);

          formik.setFieldValue('address.state', selectedStateName.name || '');
          formik.setFieldValue('address.city_id', fetchedCities.at(0)?.id);
          formik.setFieldValue('address.city', fetchedCities.at(0)?.name);
        },
        'address.city_id': async () => {
          if (!value) return;
          if (isBrazilianFranchisee) value = Number(value);

          const fetchedNeighborhoods = await handleFetchNeighborhoods(value);
          const selectedCityName = cities.find(city => city.id === value);

          formik.setFieldValue('address.city', selectedCityName.name || '');
          formik.setFieldValue('address.neighborhood_id', fetchedNeighborhoods.at(0)?.id);
          formik.setFieldValue('address.neighborhood', fetchedNeighborhoods.at(0)?.name);
        },
        'address.neighborhood_id': async () => {
          if (!value) return;
          if (isBrazilianFranchisee) value = Number(value);

          const selectedNeighborhoodName = neighborhoods.find(neighborhood => neighborhood.id === value);

          formik.setFieldValue('address.neighborhood', selectedNeighborhoodName.name || '');
        },
      };

      if (name in handleFormChangeFields) await handleFormChangeFields[name]();
    } catch (err) {
      console.debug('[DEBUG] handleFormChange error ', err);
    }
  };

  useEffect(() => {
    const fetchedStates = addressService.getStates();

    async function handleFetchInspector() {
      try {
        const { data: fetchedInspector } = await apiRequest.get(`/inspectors/${id}`);

        if (!fetchedInspector.address) {
          setInspector(fetchedInspector);
          setLoading(false);

          return;
        }

        if (!fetchedInspector.address.state_id) {
          fetchedInspector.address.state_id = '';

          if (fetchedInspector.address.state) {
            const inspectorState = fetchedStates.find(state => state.name === fetchedInspector.address.state);

            fetchedInspector.address.state_id = inspectorState.id || '';

            if (inspectorState.id) await handleFetchCities(inspectorState.id);
          }
        } else {
          await handleFetchCities(fetchedInspector.address.state_id);
        }

        if (!fetchedInspector.address.city_id) {
          fetchedInspector.address.city_id = '';

          if (fetchedInspector.address.city && fetchedInspector.address.state) {
            const inspectorState = fetchedStates.find(state => state.name === fetchedInspector.address.state);

            const fetchedCities = await handleFetchCities(inspectorState.id);

            const inspectorCity = fetchedCities.find(city => {
              return handleNormalizeCityName(city.name) === handleNormalizeCityName(fetchedInspector.address.city);
            });

            fetchedInspector.address.city_id = inspectorCity.id || '';
            fetchedInspector.address.city = inspectorCity.name || '';

            if (inspectorCity.id) await handleFetchNeighborhoods(inspectorCity.id);
          }
        } else {
          await handleFetchNeighborhoods(fetchedInspector.address.city_id);
        }

        if (!fetchedInspector.address.neighborhood_id) fetchedInspector.address.neighborhood_id = '';

        setInspector(fetchedInspector);
        setLoading(false);
      } catch (err) {
        console.debug('[DEBUG] useEffect handleFetchInspector error ', err);
      }
    }

    setStates(fetchedStates);

    if (action === 'edit') {
      handleFetchInspector();
    } else {
      setLoading(false);
    }
  }, [action, id]);

  return (
    <InspectorFormComponent
      formik={formik}
      action={action}
      busy={busy}
      isSupportModeActive={isSupportModeActive}
      franchiseeCountry={franchiseeCountry}
      zipcodeMask={zipcodeMask}
      states={states}
      cities={cities}
      neighborhoods={neighborhoods}
      id={id}
      loading={loading}
      handleFormChange={handleFormChange}
      intl={intl}
      cpfMask={cpfMask}
      phoneMask={phoneMask}
      cellphoneMask={cellphoneMask}
      cellphoneMaskLength={cellphoneMaskLength}
    />
  );
};

InspectorFormContainer.propTypes = {
  action: PropTypes.oneOf(['new', 'edit']).isRequired,
};

export default InspectorFormContainer;

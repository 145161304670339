import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import classes from 'src/components/ClientMenu/ClientMenu.module.css';
import FeatureSwitch from 'src/components/common/FeatureSwitch';
import { RV_GROUP_CLIENT_PANEL_BASE_URL } from 'src/config';
import getDivergencesPanelLink from 'src/helpers/get-divergences-panel-link';
import companyIcon from 'src/images/icons/company.svg';
import profileIcon from 'src/images/icons/profile.svg';
import propertyIcon from 'src/images/icons/property.svg';

const ClientMenu = () => {
  const [disabledByModality, setDisabledByModality] = useState(false);
  const [clientHasDashboard, setClientHasDashboard] = useState(false);
  const { divergenceCount } = useSelector(state => ({ divergenceCount: state.divergences.count }));

  const { pathname } = useLocation();
  const { push } = useHistory();
  const intl = useIntl();

  const tabIndexes = useMemo(() => {
    const defaultTabIndexes = {
      '/orders': 0,
      '/orders/financial-extract': 1,
      '/divergencias': 2,
      '/orders/dashboards': 3,
      '/orders/new': 4,
    };

    if (!clientHasDashboard) {
      delete defaultTabIndexes['/orders/dashboards'];

      defaultTabIndexes['/orders/new'] = 3;
    }

    return defaultTabIndexes;
  }, [clientHasDashboard]);

  useEffect(() => {
    const me = JSON.parse(window.sessionStorage.getItem('me'));

    const hasDashboards = !!me?.dashboards;

    setDisabledByModality(me.disabledByModality);

    setClientHasDashboard(hasDashboards);
  }, []);

  const handleRedirectToApp = url => {
    const accessToken = sessionStorage.getItem('accessToken');
    const tokenType = sessionStorage.getItem('tokenType');

    window.open(
      `${RV_GROUP_CLIENT_PANEL_BASE_URL}/${url}/#access_token=${accessToken}&token_type=${tokenType}&expires_in=&path=${url}`,
      '_blank'
    );
  };

  return (
    <AppBar position="static" color="default" className={classes.ClientMenu}>
      <Tabs
        value={tabIndexes[pathname] >= 0 ? tabIndexes[pathname] : false}
        classes={{ indicator: classes.indicator }}
        fullWidth
        className={classes.Tabs}
      >
        <Tab
          label={intl.formatMessage({ id: 'MY_INSPECTIONS' })}
          className={classes.Tab}
          classes={{ selected: classes.active }}
          disabled={pathname === '/orders'}
          onClick={() => push('/orders')}
          data-cy="inspections-tab"
        />
        <Tab
          label={intl.formatMessage({ id: 'EXTRACT' })}
          className={classes.Tab}
          classes={{ selected: classes.active }}
          disabled={pathname === '/orders/financial-extract' || disabledByModality}
          onClick={() => push('/orders/financial-extract')}
          data-cy="financial-extract-tab"
        />
        <FeatureSwitch feature="DIVERGENCE_PANEL">
          <Tab
            label={
              <div>
                {!!divergenceCount && <span className={classes.divergenceCount}>{divergenceCount}</span>}
                {intl.formatMessage({ id: 'DIVERGENCES' })}
              </div>
            }
            className={classes.Tab}
            classes={{ selected: classes.active }}
            disabled={pathname === '/divergencias' || disabledByModality}
            onClick={getDivergencesPanelLink}
            data-cy="divergences-tab"
          />
        </FeatureSwitch>
        {clientHasDashboard && (
          <Tab
            label={intl.formatMessage({ id: 'DASHBOARD' })}
            className={classes.Tab}
            classes={{ selected: classes.active }}
            disabled={pathname === '/orders/dashboards'}
            onClick={() => push('/orders/dashboards')}
            data-cy="dashboards-tab"
          />
        )}
        <Tab
          label={intl.formatMessage({ id: 'NEW_ORDER' })}
          className={classes.Tab}
          classes={{ selected: classes.active }}
          disabled={pathname === '/orders/new'}
          onClick={() => push('/orders/new')}
          data-cy="new-order-tab"
        />
        <div className={classes.divider}>
          <span></span>
        </div>
        <Tab
          label={
            <div className={classes.label}>
              <img src={profileIcon} alt={intl.formatMessage({ id: 'profile' })} />
              <span>{intl.formatMessage({ id: 'profile' })}</span>
            </div>
          }
          className={classes.Tab}
          onClick={() => handleRedirectToApp('profile')}
          disabled={disabledByModality}
        />
        <Tab
          label={
            <div className={classes.label}>
              <img src={companyIcon} alt={intl.formatMessage({ id: 'companies' })} />
              <span>{intl.formatMessage({ id: 'companies' })}</span>
            </div>
          }
          className={classes.Tab}
          onClick={() => handleRedirectToApp('company')}
          disabled={disabledByModality}
        />
        <Tab
          label={
            <div className={classes.label}>
              <img src={propertyIcon} alt={intl.formatMessage({ id: 'properties' })} />
              <span>{intl.formatMessage({ id: 'properties' })}</span>
            </div>
          }
          className={classes.Tab}
          onClick={() => handleRedirectToApp('properties')}
          disabled={disabledByModality}
        />
      </Tabs>
    </AppBar>
  );
};

export default ClientMenu;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let TypeNone = props => (
  <SvgIcon {...props} viewBox="0 0 34.016 34.016">
    <path
      fill="#E3E3E3"
      d="M27.436,28.058c0,1.063-0.883,1.927-1.975,1.927H8.355
	c-1.09,0-1.974-0.863-1.974-1.927V6.86c0-1.064,0.884-1.927,1.974-1.927h5.264l-3.29,1.285v5.139h13.16V6.218l-3.29-1.285h5.263
	c1.092,0,1.975,0.862,1.975,1.927 M22.173,10.072H11.645V7.502l3.29-1.285V4.29c0-1.064,0.226-1.285,1.316-1.285h1.316
	c1.09,0,1.316,0.221,1.316,1.285v1.927l3.29,1.285V10.072z"
    />
  </SvgIcon>
);
TypeNone = pure(TypeNone);
TypeNone.displayName = 'TypeNone';
TypeNone.muiName = 'SvgIcon';

export default TypeNone;

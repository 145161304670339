export const ITEMS_PER_PAGE = 15;

export const orderType = {
  get: key => {
    const parse = s =>
      s
        .toLowerCase()
        .replace('vistoria_', '')
        .replace('extra_', '')
        .replace(/([-_][a-z])/gi, $1 => $1.toUpperCase().replace(/[-_]/g, ''))
        .replace('docusign', 'marketplace_docusign')
        .replace('fichaCerta', 'marketplace_fichacerta')
        .replace('CcInfra', 'CCInfra')
        .replace('acompanhamentoObraMm', 'acompanhamentoObraMM')
        .replace('fast4you', 'fast4You')
        .replace('auditoriaMm', 'auditoriaMM');

    return key ? parse(key) : null;
  },
};

export const ordersStatuses = {
  WAITING: 'waiting',
  ACCEPTED: 'accepted',
  ASSIGNED: 'assigned',
  IN_PROGRESS: 'in_progress',
  FINALIZED: 'finalized',
  VNR: 'vnr',
  ARCHIVED: 'archived',
  SENT: 'sent',
  REJECTED: 'rejected',
  CANCELED: 'canceled',
};

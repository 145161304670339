import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/PropertyInformationsSection/style.module.scss';
import PropertyInformations from 'src/svg-icons/PropertyInformations';

const PropertyInformationsSection = ({ data }) => (
  <Grid item xs={12}>
    <div className={classes.header}>
      <PropertyInformations className={classes.icon} />
      <FormattedMessage id="propertyInformations" />
    </div>
    <Grid container spacing={8} className={classes.information}>
      <Grid item xs={6}>
        <div className={classes.subHeader}>
          <FormattedMessage id="link" />
        </div>
        <a
          href={data.link}
          className={classes.truncate}
          target="_blank"
          rel="noreferrer noopener"
          data-cy="order-details-property-link"
        >
          {data.link}
        </a>
      </Grid>
      <Grid item xs={6} data-cy="order-details-property-highlights">
        <div className={classes.subHeader}>
          <FormattedMessage id="highlights" />
        </div>
        {data.highlights}
      </Grid>
      <Grid item xs={12} data-cy="order-details-property-other-informations">
        <div className={classes.subHeader}>
          <FormattedMessage id="otherInformations" />
        </div>
        {data.otherInformations}
      </Grid>
    </Grid>
  </Grid>
);

PropertyInformationsSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PropertyInformationsSection;

import React from 'react';
import { useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';

import classes from 'src/components/common/PageHeader/style.module.scss';
import SearchBox from 'src/components/common/SearchBox';
import HeaderMenu from 'src/HeaderMenu';
import LogoRede from 'src/images/logo_rede.svg';
import CircleRede from 'src/svg-icons/circle-rede';

const PageHeader = ({ sidebarCollapsed }) => {
  const { pathname } = useLocation();

  const me = window.sessionStorage.getItem('me') && JSON.parse(window.sessionStorage.getItem('me'));

  const isClientsPage = pathname.includes('/clients');

  return (
    <div className={classes.header}>
      <div className={`${classes.left} ${sidebarCollapsed ? classes.sidebarCollapsed : ''}`}>
        <div className={classes.logo}>
          {sidebarCollapsed ? <CircleRede /> : <img src={LogoRede} alt="Logo Rede Vistorias" />}
        </div>
      </div>
      <div className={classes.right}>
        <div className={classes.searchWrapper}>
          <SearchBox redux isClientsPageHeader={isClientsPage} />
        </div>
        <div className={classes.user}>
          <HeaderMenu features={me.features} userName={me.name} />
        </div>
      </div>
    </div>
  );
};

PageHeader.propTypes = {
  sidebarCollapsed: PropTypes.bool.isRequired,
};

export default PageHeader;

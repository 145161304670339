import React from 'react';

import PropTypes from 'prop-types';

const fieldErrorListTemplate = errors => {
  if (!errors || !errors.length) return null;

  return (
    <ul className="error-list">
      {errors.map((error, index) => (
        <li key={index}>{error}</li>
      ))}
    </ul>
  );
};

const fieldIsBool = (schm, uischm): boolean =>
  (schm && schm.type === 'boolean') ||
  (uischm && ['radio', 'checkbox'].includes(`${uischm['ui:widget']}`));

const FieldTemplate = ({
  id,
  label,
  children,
  displayLabel,
  required,
  rawErrors = [],
  rawDescription,
  schema,
  uiSchema,
}) => (
  <div className={`field${required ? ' is-required' : ''}`}>
    {displayLabel && label ? (
      <label htmlFor={fieldIsBool(schema, uiSchema) ? null : id}>{label}</label>
    ) : null}
    {children}

    {fieldErrorListTemplate(rawErrors)}
    {rawDescription && <div className="helper">{rawDescription}</div>}
  </div>
);

FieldTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  rawDescription: PropTypes.string,
  rawErrors: PropTypes.array,
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

FieldTemplate.defaultProps = {
  label: null,
  rawDescription: null,
  rawErrors: [],
  required: false,
};

export default FieldTemplate;

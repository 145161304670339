import React, { Component } from 'react';
import DropZone from 'react-dropzone';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteFile from '@material-ui/icons/Delete';
import DocumentIcon from '@material-ui/icons/Description';
import PropTypes from 'prop-types';

import classes from 'src/orderForm/fields/DocuSignFile.module.css';
import DocumentsCircle from 'src/svg-icons/documents-circle';

class DocuSignFile extends Component {
  constructor(props) {
    super(props);
    const { documents } = props;
    const allowedMimeTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'image/png',
    ];
    this.state = {
      documents: documents || [],
      allowedMimeTypes,
      isEditing: this.isEditing(),
    };
  }

  onDrop = file => {
    const { documents } = this.state;
    const { onChange } = this.props;
    const reader = new FileReader();
    const fileToDecode = file[0];
    if (fileToDecode) {
      reader.onload = readerEvent => {
        const extension = fileToDecode.name.split('.').pop();
        documents.push({
          url: readerEvent.target.result.split(',').pop(),
          extension,
          name: fileToDecode.name,
        });
        this.setState({ documents });
        onChange(documents);
      };
      reader.readAsDataURL(fileToDecode);
    }
  };

  deleteHandler = (index, documentId) => {
    const { onChange } = this.props;
    const { documents } = this.state;
    if (documents[index] !== undefined && documentId !== undefined && documents[index].id === documentId) {
      documents[index].status = 'deleted';
    } else {
      documents.splice(index, 1);
    }
    this.setState({ documents });
    onChange(documents);
  };

  isEditing = () => {
    const { formData } = this.props;
    return !!formData;
  };

  render() {
    const { allowedMimeTypes, documents, isEditing } = this.state;
    const { formData, name } = this.props;

    if (formData) {
      if (formData.length > documents) {
        this.setState({
          documents: [...formData],
        });
      }
    }
    return (
      <Grid container className={classes.DocuSignFile}>
        <DocumentsCircle viewBox="0 0 34.016 34.016" />
        <Grid item xs={11} className={classes.documentsSpacing}>
          <p className={classes.title}>
            <FormattedMessage id={name} />
          </p>
          {!isEditing ? (
            <>
              <label htmlFor="dropzone">
                <FormattedMessage id="Docusign.file.toSign" />
              </label>
              <DropZone
                id="dropzone"
                className={classes.dropZone}
                data-cy="dropzone"
                accept={allowedMimeTypes.join(', ')}
                onDropAccepted={this.onDrop}
                multiple
              >
                <Button className={classes.addFile} variant="outlined" size="small" disableRipple disableFocusRipple>
                  <FormattedMessage id="Docusign.select" />
                </Button>

                <FormattedMessage id="Docusign.drop" />
              </DropZone>
            </>
          ) : null}
          <List component="ul">
            {documents.map((document, index) => (
              <ListItem alignItems="flex-start" className={classes.fileList} key={document.id} disableGutters>
                <span className={classes.fileTitle}>
                  <ListItemIcon
                    classes={{
                      root: classes.fileSpacing,
                    }}
                  >
                    <DocumentIcon
                      style={{
                        color: '#393939',
                      }}
                      viewBox="4 0 34 27"
                      nativeColor="fill=#fff"
                    />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={document.name}
                    classes={{
                      root: classes.fileText,
                    }}
                  />
                </span>
                <button
                  type="button"
                  className={classes.deleteButton}
                  onClick={() => this.deleteHandler(index, document.id)}
                  disabled={isEditing}
                  data-cy="delete-file"
                >
                  <DeleteFile />
                </button>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    );
  }
}

DocuSignFile.propTypes = {
  onChange: PropTypes.func.isRequired,
  documents: PropTypes.array.isRequired,
  schema: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  formData: PropTypes.object,
};

DocuSignFile.defaultProps = {
  formData: null,
};

export default DocuSignFile;

import React, { useState } from 'react';
import { FormattedMessage,  useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import PropTypes from 'prop-types';

import API from 'src/apiRequest';

import classes from './AddPerformingInspector.module.scss';
import InspectorsFilter from './InspectorsFilter';


const AddPerformingInspector = ({ orderId}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [busy, setBusy] = useState(false);
  const intl = useIntl();
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const selectInspectorHandler = () => {
    setErrorMessage(null);
  };

  const onSubmit = async evt => {
    evt.preventDefault();
    const formData = new FormData(document.getElementById('addPerformingInspectorForm'));
  
    if (formData.get('inspector').length !== 36) {
      setErrorMessage('MESSAGE.INSPECTOR_REQUIRED');
      return;
    }
  
  
    if (formData.get('date').length !== 16) {
      setErrorMessage('MESSAGE.DATE_REQUIRED');
      return;
    }
  
      
    const date = moment(formData.get('date'), moment.DATETIME_LOCAL);
    const maxDate = moment();
  
    if (!date.isValid() || !date.isBefore(maxDate)) {
      setErrorMessage('MESSAGE.INVALID_DATE_AFTER_NOW');
      return;
    }
    
    const formattedDate = date.format('YYYY-MM-DD HH:mm:ss');

    const formValues = {
      public_id: formData.get('inspector'),
      date: formattedDate,
      observation: formData.get('observation')
    };

    try {
      setBusy(true);
      await API.post(`/orders/${orderId}/inspectors`, formValues);
      history.push(`/orders/${orderId}`);
    } catch (err) {
      console.log(err);
      if(err?.response?.data?.error){
        setErrorMessage(err?.response?.data?.error);
      }
    } finally {
      setBusy(false);
    }
  };

  

  return <form id="addPerformingInspectorForm" onSubmit={onSubmit}>
    <div className={classes.AssignInspector}>
      <div className={classes.Title}>
        <h2>{intl.formatMessage({ id: 'ADD_INSPECTOR' })}</h2>
        <h3>
          {intl.formatMessage({
            id: 'HAS_PERFORMED_INSPECTION_MESSAGE',
          })}
        </h3>
      </div>
      <div className={classes.InspectorFilter}>
        <InspectorsFilter handleSelect={selectInspectorHandler} />
      </div>
      <div className={classes.Schedule}>
        <label htmlFor="date">
          <FormattedMessage id="LABEL.DATE" />
        </label>
        <input
          type="datetime-local"
          name="date"
          id="date"
          required
        />
      </div>
      <div className={classes.Observations}>
        <label htmlFor="observation">
          <FormattedMessage id="OBSERVATION" />
        </label>
        <textarea name="observation" id="observation" />
      </div>
      <div>
        <hr />
      </div>
      {!!errorMessage && <div className={classes.ErrorMessage}><FormattedMessage id={errorMessage} /></div>}
      <div className={classes.Actions}>
        <button
          type="button"
          className={classes.Cancel}
          onClick={goBack}
          disabled={busy}
        >
          <FormattedMessage id="BUTTON.CANCEL" />
        </button>
        <button type="submit" className={`${classes.Assign} ${busy ? classes.isBusy : undefined}`} disabled={busy}>
          <FormattedMessage id="ADD" />
        </button>
      </div>
    </div>
  </form>;
};

AddPerformingInspector.propTypes = {
  orderId: PropTypes.object.isRequired
};

export default AddPerformingInspector;

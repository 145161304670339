import React from 'react';
import { injectIntl } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = {
  bootstrapInput: {
    borderRadius: 4,
    fontWeight: 'bold',
    fontSize: 'calc(8px + 0.4vw)',
    width: 'calc(100% - 24px)',
    '&:focus': {
      borderColor: '#80bdff',
    },
    fontFamily: 'Open Sans',
  },
};

function RequestFormFieldDisable(props) {
  return (
    <div>
      <TextField
        fullWidth={true}
        type={'text'}
        disabled
        className="custom"
        value={props.intl.formatMessage({ id: props.formData })}
        data-cy={`${String(props.name).toLowerCase()}`}
        InputProps={{
          disableUnderline: true,
          classes: {
            input: props.classes.bootstrapInput,
          },
        }}
      />
    </div>
  );
}

export default withStyles(styles)(injectIntl(RequestFormFieldDisable));

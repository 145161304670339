import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import PackagePurchaseComponent from 'src/components/ClientsPage/PackagePurchase/PackagePurchaseComponent';
import { reloadCards } from 'src/store/ducks/cards';
import { showToast } from 'src/store/ducks/toasts';

// eslint-disable-next-line no-shadow
const PackagePurchaseContainer = ({ reloadCards, showToast }) => {
  const history = useHistory();
  const { id, status } = useParams();
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [busy, setBusy] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(true);
  const intl = useIntl();

  useEffect(() => {
    const fetchPackages = async () => {
      const { data } = await API.get('/packages');
      setPackages(
        data.data.map(item => ({
          id: item.id,
          name: item.name,
          credits: `${item.standardMeters.value} ${item.standardMeters.unit}`,
        }))
      );
      setLoading(false);
    };

    fetchPackages();
  }, []);

  const onChange = evt => {
    const { value } = evt.target;
    setSelectedPackage(value);
  };

  const onSubmit = async evt => {
    evt.preventDefault();

    setBusy(true);
    try {
      await API.post(`/packages/${selectedPackage}/clients`, {
        client_id: id,
      });

      history.push(`/clients/${status}/${id}`);
      reloadCards();
    } catch (err) {
      console.log(err);

      showToast({
        type: 'error',
        title: intl.formatMessage({ id: 'ERROR' }),
        text: intl.formatMessage({ id: 'PACKAGE_ADD_ERROR' }),
        duration: 5000,
      });


      history.goBack();
    }
    
    setBusy(false);
  };

  return (
    <PackagePurchaseComponent
      id={id}
      intl={intl}
      loading={loading}
      onChange={onChange}
      onSubmit={onSubmit}
      packages={packages}
      status={status}
      busy={busy}
    />
  );
};

PackagePurchaseContainer.propTypes = {
  reloadCards: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

export default connect(null, { reloadCards, showToast })(PackagePurchaseContainer);

import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import api from 'src/apiRequest';
import urlPrefix from 'src/helpers/url-prefix';
import { reloadCards } from 'src/store/ducks/cards';
import { showToast } from 'src/store/ducks/toasts';

import UserDetailsComponent from './UserDetailsComponent';

const FranchiseeDetailsContainer = () => {
  const [features, setFeatures] = useState([]);
  const [user, setUser] = useState(null);
  const [isFeaturesBusy, setIsFeaturesBusy] = useState(true);
  const [isUserBusy, setIsUserBusy] = useState(true);
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
  const { id } = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const showDeleteConfirmation = () => {
    ReactTooltip.hide();
    setIsDeleteConfirmationVisible(true);
  };

  const cancelDelete = () => {
    setIsDeleteConfirmationVisible(false);
  };

  const handleDelete = async () => {
    try {
      await api.delete(`/users/${id}`);
      history.push(urlPrefix('users'));
      dispatch(reloadCards());
      dispatch(
        showToast({
          type: 'success',
          title: intl.formatMessage({ id: 'SUCCESS' }),
          text: intl.formatMessage({ id: 'USER_DELETE_SUCCESS' }),
          duration: 5000,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'USER_DELETE_ERROR' }),
          duration: 5000,
        })
      );
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setIsUserBusy(true);
        setIsFeaturesBusy(true);
        const { data } = await api.get(`/users/${id}`);
        setUser(data);
        setFeatures(data?.features);
      } catch (err) {
        console.log(err);
      } finally {
        setIsUserBusy(false);
        setIsFeaturesBusy(false);
      }
    };

    fetchDetails();
  }, [id]);

  return (
    <UserDetailsComponent
      id={id}
      isFeaturesBusy={isFeaturesBusy}
      isUserBusy={isUserBusy}
      features={features}
      user={user}
      intl={intl}
      isDeleteConfirmationVisible={isDeleteConfirmationVisible}
      cancelDelete={cancelDelete}
      handleDelete={handleDelete}
      showDeleteConfirmation={showDeleteConfirmation}
    />
  );
};

export default FranchiseeDetailsContainer;

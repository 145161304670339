/**
 * @prettier
 **/
import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let StatusWaitingIcon = props => (
  <SvgIcon {...props}>
    <path
      fill="#E1E0E0"
      d="M26,0C11.641,0,0,11.641,0,26s11.641,26,26,26s26-11.641,26-26S40.359,0,26,0z M26,50.001
		C12.746,50.001,2,39.255,2,26C2,12.746,12.746,2,26,2c13.255,0,24.001,10.746,24.001,24C50.001,39.255,39.255,50.001,26,50.001z"
    />
    <polygon
      fill="#E1E0E0"
      points="26.483,25.915 26.483,16.828 24.551,16.828 24.551,28.414 26.483,28.414 26.483,28.203 
		32.265,24.509 31.337,22.815 	"
    />
    <path
      fill="#E1E0E0"
      d="M26,12c-7.732,0-14,6.268-14,14s6.268,14,14,14s14-6.268,14-14S33.732,12,26,12z M26,38
		c-6.627,0-12-5.373-12-12s5.373-12,12-12s12,5.373,12,12S32.627,38,26,38z"
    />
  </SvgIcon>
);

StatusWaitingIcon = pure(StatusWaitingIcon);
StatusWaitingIcon.displaName = 'StatusWaitingIcon';
StatusWaitingIcon.muiName = 'SvgIcon';

export default StatusWaitingIcon;

import { parse, format } from 'date-fns';

export function formatDateString(dateString, dateFormat = 'yyyy-MM-dd') {
  if (!dateString) {
    return {
      error: null,
      formattedDate: '',
    };
  }

  try {
    const parsedDate = parse(dateString, 'dd/MM/yyyy', new Date());

    return {
      error: null,
      formattedDate: format(parsedDate, dateFormat),
    };
  } catch (err) {
    console.debug('formatDateString error ', err);

    return {
      error: new Error('Unable to format the provided date'),
      formattedDate: null,
    };
  }
}

import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useInspectorTimelineIntervals = () => {
  const scheduleDate = useSelector(({ schedule }) => schedule.date);

  return useMemo(() => {
    const formattedSchedulesDate = scheduleDate.clone().add(1, 'days').format('YYYY-MM-DD');
    const halfAnHourIntervals = [];

    for (let hour = 5; hour < 20; hour++) {
      const hourDate = new Date('1970-01-02');
      const halfAnHourDate = new Date('1970-01-02');

      const scheduleHourDate = new Date(formattedSchedulesDate);
      const scheduleHalfAnHourDate = new Date(formattedSchedulesDate);

      hourDate.setHours(hour, 0, 0, 0);
      scheduleHourDate.setHours(hour, 0, 0, 0);

      halfAnHourIntervals.push({
        halfAnHourInterval: hourDate,
        halfAnHourIntervalWithScheduleDate: scheduleHourDate,
      });

      halfAnHourDate.setHours(hour, 30, 0, 0);
      scheduleHalfAnHourDate.setHours(hour, 30, 0, 0);

      halfAnHourIntervals.push({
        halfAnHourInterval: halfAnHourDate,
        halfAnHourIntervalWithScheduleDate: scheduleHalfAnHourDate,
      });
    }

    return halfAnHourIntervals;
  }, [scheduleDate]);
};

export default useInspectorTimelineIntervals;

import React from 'react';
import MaskedInput from 'react-text-mask';

import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import ColorButton from 'src/components/common/elements/ColorButton';
import classes from 'src/components/UpdateAllowedAtDate/style.module.scss';
import { DATETIME_MASK } from 'src/helpers/mask';
import Details from 'src/svg-icons/details';

const UpdateAllowedAtDateComponent = ({ error, validationSchema, handleSubmit, intl }) => (
  <div className={classes.container}>
    <div className={classes.Header}>
      <Details className={classes.Icon} />
      {intl.formatMessage({ id: 'DETAILS' })}
    </div>
    <Formik initialValues={{ allowed_at_date: '' }} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {({ errors, isSubmitting, setFieldValue }) => (
        <Form>
          <div className={classes.field}>
            <label htmlFor="allowed_at_date">{intl.formatMessage({ id: 'allowed_at_date' })}</label>
            <MaskedInput
              type="text"
              name="allowed_at_date"
              id="allowed_at_date"
              placeholder={intl.formatMessage({
                id: 'LABEL.DATETIME',
              })}
              guide={false}
              mask={DATETIME_MASK}
              onChange={e => {
                setFieldValue('allowed_at_date', e.target.value);
              }}
            />
            {(error || errors.allowed_at_date) && (
              <div className={classes.error}>{error || errors.allowed_at_date}</div>
            )}
          </div>
          <div className={`${classes.field} ${classes.actions}`}>
            <ColorButton type="submit" color="green" isBusy={isSubmitting}>
              {intl.formatMessage({ id: 'SEND' })}
            </ColorButton>
          </div>
        </Form>
      )}
    </Formik>
  </div>
);

UpdateAllowedAtDateComponent.propTypes = {
  error: PropTypes.string,
  validationSchema: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

UpdateAllowedAtDateComponent.defaultProps = {
  error: null,
};

export default UpdateAllowedAtDateComponent;

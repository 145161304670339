import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Paper, Popper, Grow, ClickAwayListener } from '@material-ui/core';
import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import TagField from 'src/components/Tags/TagField';
import classes from 'src/components/Tags/TagModal/style.module.scss';
import { syncTags } from 'src/store/ducks/tags';

const TagModal = ({
  anchorEl,
  closeHandler,
  orders,
  show,
  syncTags, // eslint-disable-line no-shadow
}) => {
  const [tags, setTags] = useState(0);
  const intl = useIntl();

  const handleChange = value => {
    setTags(value);
  };

  const handleSubmit = async evt => {
    evt.preventDefault();
    const payload = {
      tags: [...tags.map(item => item.value)],
      orders: [...orders],
    };
    try {
      await API.post('/orders/tags', payload);
      syncTags();
      closeHandler();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Popper open={show} className={classes.position} anchorEl={anchorEl} placement="bottom-start" transition>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={closeHandler}>
              <div className={classes.tagModal}>
                <form onSubmit={handleSubmit}>
                  <h3>{intl.formatMessage({ id: 'ADD_BOOKMARKS' })}</h3>
                  <TagField handleChange={handleChange} autoFocus />
                  <button type="submit">{intl.formatMessage({ id: 'ADD' })}</button>
                </form>
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

TagModal.propTypes = {
  anchorEl: PropTypes.object,
  closeHandler: PropTypes.func.isRequired,
  orders: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  syncTags: PropTypes.func.isRequired,
};

export default connect(null, { syncTags })(TagModal);

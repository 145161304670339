import React from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import MaskedInput from 'react-text-mask';

import PropTypes from 'prop-types';

import classes from 'src/components/CommercialPage/ClientRegistrationForm/style.module.scss';
import Card from 'src/components/common/Card';
import Radio from 'src/components/common/elements/Radio';
import clientImage from 'src/components/common/icons/client-circle.svg';
import detailsImage from 'src/components/common/icons/client-details.svg';
import contactImage from 'src/components/common/icons/contact-circle.svg';
import documentImage from 'src/components/common/icons/document-circle.svg';
import Spinner from 'src/components/common/Spinner';
import { USERNAME_MASK } from 'src/helpers/mask';
import AddressIcon from 'src/svg-icons/address';
import ClientIcon from 'src/svg-icons/client';

const ClientRegistrationFormComponent = ({
  busy,
  client,
  franchisees,
  loading,
  intl,
  showBillingAddress,
  origins,
  classifications,
  zipcodeMask,
  states,
  cities,
  billingCities,
  neighborhoods,
  billingNeighborhoods,
  type,
  onChange,
  onChangeFranchisee,
  onSubmit,
  cnpjMask,
  cpfOnlyMask,
  phoneMask,
  cellphoneMask,
  cellphoneMaskLength,
}) =>
  loading ? (
    <div className={classes.clientRegistrationForm}>
      <div className={classes.spinner}>
        <Spinner />
      </div>
    </div>
  ) : (
    <div className={classes.clientRegistrationForm}>
      <Card className="fade-in">
        <h2 className={classes.cardHead}>
          <div className={classes.back}>
            <Link to="/commercial/clients">
              <div className={classes.arrowLeft} />
            </Link>
          </div>

          <div className={classes.icon}>
            <img src={detailsImage} alt={intl.formatMessage({ id: 'DETAILS' })} />
          </div>

          {intl.formatMessage({ id: 'DETAILS' })}
        </h2>

        <form onSubmit={onSubmit}>
          <h3 className={classes.subtitle}>
            <div className={classes.icon}>
              <img src={clientImage} alt={intl.formatMessage({ id: 'CUSTOMER' })} />
            </div>

            {intl.formatMessage({ id: 'CUSTOMER' })}
          </h3>

          <div className={`${classes.field} ${classes.hasRadio}`}>
            <div className={classes.radio}>
              <Radio
                name="client_type"
                className={classes.radioInput}
                id="clientTypePJ"
                data-cy="clientTypePJ"
                value="pj"
                onChange={onChange}
                checked={type === 'pj'}
              />

              <label htmlFor="clientTypePJ">{intl.formatMessage({ id: 'LEGAL_PERSON' })}</label>
            </div>

            <div className={classes.radio}>
              <Radio
                name="client_type"
                className={classes.radioInput}
                id="clientTypePF"
                data-cy="clientTypePF"
                value="pf"
                onChange={onChange}
                checked={type === 'pf'}
              />

              <label htmlFor="clientTypePF">{intl.formatMessage({ id: 'NATURAL_PERSON' })}</label>
            </div>
          </div>

          <div className={classes.field}>
            <label htmlFor="name">
              {type === 'pj' ? intl.formatMessage({ id: 'FANTASY_NAME' }) : intl.formatMessage({ id: 'NAME' })}*
            </label>

            <input type="text" name="name" id="name" required value={client?.name || ''} onChange={onChange} />
          </div>

          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="classification">{intl.formatMessage({ id: 'CLASSIFICATION' })}*</label>

              <select
                name="classification"
                id="classification"
                data-cy="classification"
                value={client?.classification || ''}
                required
                onChange={onChange}
              >
                <option value="" disabled />

                {classifications.map(classification => (
                  <option value={classification} key={classification}>
                    {intl.formatMessage({ id: `CLASSIFICATION.${classification}` })}
                  </option>
                ))}
              </select>
            </div>

            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="origin">{intl.formatMessage({ id: 'ORIGIN' })}*</label>

              <select
                name="origin"
                id="origin"
                data-cy="origin"
                value={client?.origin || ''}
                required
                onChange={onChange}
              >
                <option value="" disabled />

                {origins.map(origin => (
                  <option value={origin} key={origin}>
                    {intl.formatMessage({ id: `ORIGIN.${origin}` })}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className={`${classes.field} ${classes.isFlex}`}>
            {type === 'pj' ? (
              <>
                <div className={`${classes.field} ${classes.is6}`}>
                  <label htmlFor="cnpj">{intl.formatMessage({ id: 'CNPJ' })}*</label>

                  <MaskedInput
                    type="text"
                    name="document"
                    id="cnpj"
                    data-cy="cnpj"
                    required
                    value={client?.document || ''}
                    onChange={onChange}
                    guide={false}
                    mask={cnpjMask}
                  />
                </div>
                <div className={`${classes.field} ${classes.is6}`}>
                  <label htmlFor="billing-name">{intl.formatMessage({ id: 'COMPANY_NAME' })}*</label>

                  <input
                    type="text"
                    name="company_name"
                    id="billing-name"
                    data-cy="billing-name"
                    required
                    value={client?.company_name || ''}
                    onChange={onChange}
                  />
                </div>
              </>
            ) : (
              <div className={`${classes.field} ${classes.is6}`}>
                <label htmlFor="cpf">{intl.formatMessage({ id: 'CPF' })}*</label>

                <MaskedInput
                  type="text"
                  name="document"
                  id="cpf"
                  data-cy="cpf"
                  required
                  value={client?.document || ''}
                  onChange={onChange}
                  guide={false}
                  mask={cpfOnlyMask}
                />
              </div>
            )}
          </div>

          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="username">{intl.formatMessage({ id: 'USER' })}*</label>

              <MaskedInput
                type="text"
                name="username"
                id="username"
                data-cy="username"
                required
                value={client?.username || ''}
                onChange={onChange}
                guide={false}
                mask={USERNAME_MASK}
              />
            </div>

            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="email">{intl.formatMessage({ id: 'EMAIL' })}*</label>

              <input
                type="text"
                name="contact[email]"
                id="email"
                data-cy="email"
                required
                value={client?.contact?.email || ''}
                onChange={onChange}
              />
            </div>
          </div>

          {type === 'pj' && (
            <>
              <h3 className={classes.subtitle}>
                <div className={classes.icon}>
                  <ClientIcon />
                </div>

                {intl.formatMessage({ id: 'RESPONSIBLE' })}
              </h3>

              <div className={classes.field}>
                <label htmlFor="responsibleName">{intl.formatMessage({ id: 'NAME' })}*</label>

                <input
                  type="text"
                  name="responsible[name]"
                  id="responsibleName"
                  data-cy="responsibleName"
                  value={client?.responsible?.name || ''}
                  onChange={onChange}
                />
              </div>

              <div className={`${classes.field} ${classes.isFlex}`}>
                <div className={`${classes.field} ${classes.is4}`}>
                  <label htmlFor="responsibleDocument">{intl.formatMessage({ id: 'CPF' })}*</label>

                  <MaskedInput
                    type="text"
                    name="responsible[document]"
                    id="responsibleDocument"
                    data-cy="responsibleDocument"
                    required
                    value={client?.responsible?.document || ''}
                    onChange={onChange}
                    guide={false}
                    mask={cpfOnlyMask}
                  />
                </div>

                <div className={`${classes.field} ${classes.is4}`}>
                  <label htmlFor="responsiblePhone">{intl.formatMessage({ id: 'PHONE' })}</label>

                  <MaskedInput
                    type="text"
                    name="responsible[phone]"
                    id="responsiblePhone"
                    data-cy="responsiblePhone"
                    value={client?.responsible?.phone || ''}
                    onChange={onChange}
                    guide={false}
                    mask={value => (value.length >= cellphoneMaskLength ? cellphoneMask : phoneMask)}
                  />
                </div>

                <div className={`${classes.field} ${classes.is4}`}>
                  <label htmlFor="responsibleCellphone">{intl.formatMessage({ id: 'CELLPHONE' })}</label>

                  <MaskedInput
                    type="text"
                    name="responsible[cellphone]"
                    id="responsibleCellphone"
                    data-cy="responsibleCellphone"
                    value={client?.responsible?.cellphone || ''}
                    onChange={onChange}
                    guide={false}
                    mask={value => (value.length >= cellphoneMaskLength ? cellphoneMask : phoneMask)}
                  />
                </div>
              </div>

              <div className={classes.field}>
                <label htmlFor="ResponsibleEmail">{intl.formatMessage({ id: 'EMAIL' })}</label>

                <input
                  type="text"
                  name="responsible[email]"
                  id="ResponsibleEmail"
                  data-cy="ResponsibleEmail"
                  value={client?.responsible?.email || ''}
                  onChange={onChange}
                />
              </div>
            </>
          )}

          <h3 className={classes.subtitle}>
            <div className={classes.icon}>
              <AddressIcon />
            </div>

            {intl.formatMessage({ id: 'CUSTOMER_ADDRESS' })}
          </h3>

          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="zipcode">{intl.formatMessage({ id: 'CEP' })}</label>

              <MaskedInput
                type="text"
                name="address[zipcode]"
                id="zipcode"
                data-cy="zipcode"
                value={client?.address?.zipcode || ''}
                onChange={onChange}
                guide={false}
                mask={zipcodeMask}
              />
            </div>
          </div>

          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is2}`}>
              <label htmlFor="state">{intl.formatMessage({ id: 'STATE' })}</label>

              <select
                name="address[state_id]"
                id="state"
                data-cy="state"
                value={client?.address?.state_id || ''}
                onChange={onChange}
              >
                <option value="" disabled />

                {states.map(state => (
                  <option value={state.id} key={state.id}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>

            <div className={`${classes.field} ${classes.is5}`}>
              <label htmlFor="city">{intl.formatMessage({ id: 'CITY' })}</label>

              <select
                name="address[city_id]"
                id="city"
                data-cy="city"
                value={client?.address?.city_id || ''}
                onChange={onChange}
                disabled={!cities.length}
              >
                <option value="" disabled />

                {cities.map(city => (
                  <option value={city.id} key={city.id}>
                    {city.name}
                  </option>
                ))}
              </select>
            </div>

            <div className={`${classes.field} ${classes.is5}`}>
              <label htmlFor="neighborhood">{intl.formatMessage({ id: 'NEIGHBORHOOD' })}</label>

              <select
                name="address[neighborhood_id]"
                id="neighborhood"
                data-cy="neighborhood"
                value={client.address?.neighborhood_id || ''}
                onChange={onChange}
                disabled={!neighborhoods.length}
              >
                <option value="" disabled />

                {neighborhoods.map(neighborhood => (
                  <option value={neighborhood.id} key={neighborhood.id}>
                    {neighborhood.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="street">{intl.formatMessage({ id: 'STREET' })}</label>

              <input
                type="text"
                name="address[street]"
                id="street"
                data-cy="street"
                value={client?.address?.street || ''}
                onChange={onChange}
              />
            </div>

            <div className={`${classes.field} ${classes.is3}`}>
              <label htmlFor="number">{intl.formatMessage({ id: 'NUMBER' })}</label>

              <input
                type="text"
                name="address[number]"
                id="number"
                data-cy="number"
                value={client?.address?.number || ''}
                onChange={onChange}
              />
            </div>

            <div className={`${classes.field} ${classes.is3}`}>
              <label htmlFor="complement">{intl.formatMessage({ id: 'COMPLEMENT' })}</label>

              <input
                type="text"
                name="address[complement]"
                id="complement"
                data-cy="complement"
                value={client?.address?.complement || ''}
                onChange={onChange}
              />
            </div>
          </div>

          <h3 className={classes.subtitle}>
            <div className={classes.icon}>
              <AddressIcon />
            </div>

            {intl.formatMessage({ id: 'BILLING_ADDRESS' })}
          </h3>

          <div className={`${classes.field} ${classes.hasRadio}`}>
            <div className={classes.radio}>
              <Radio
                name="showBillingAddress"
                className={classes.radioInput}
                id="showBillingAddressNo"
                data-cy="showBillingAddressNo"
                value="false"
                onChange={onChange}
                checked={showBillingAddress === false}
              />

              <label htmlFor="showBillingAddressNo">{intl.formatMessage({ id: 'SAME_ADDRESS' })}</label>
            </div>

            <div className={classes.radio}>
              <Radio
                name="showBillingAddress"
                className={classes.radioInput}
                id="showBillingAddressYes"
                data-cy="showBillingAddressYes"
                value="true"
                onChange={onChange}
                checked={showBillingAddress === true}
              />

              <label htmlFor="showBillingAddressYes">{intl.formatMessage({ id: 'DIFFERENT_ADDRESS' })}</label>
            </div>
          </div>

          {showBillingAddress && (
            <>
              <div className={`${classes.field} ${classes.isFlex}`}>
                <div className={`${classes.field} ${classes.is6}`}>
                  <label htmlFor="billing_address_zipcode">{intl.formatMessage({ id: 'CEP' })}</label>

                  <MaskedInput
                    type="text"
                    name="billing_address[zipcode]"
                    id="billing_address_zipcode"
                    data-cy="billing_address_zipcode"
                    value={client?.billing_address?.zipcode || ''}
                    onChange={onChange}
                    guide={false}
                    mask={zipcodeMask}
                  />
                </div>
              </div>

              <div className={`${classes.field} ${classes.isFlex}`}>
                <div className={`${classes.field} ${classes.is2}`}>
                  <label htmlFor="billing_address_state_id">{intl.formatMessage({ id: 'STATE' })}</label>

                  <select
                    name="billing_address[state_id]"
                    id="billing_address_state_id"
                    data-cy="billing_address_state_id"
                    value={client?.billing_address?.state_id || ''}
                    onChange={onChange}
                  >
                    <option value="" disabled />

                    {states.map(state => (
                      <option value={state.id} key={state.id}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className={`${classes.field} ${classes.is5}`}>
                  <label htmlFor="billing_address_city_id">{intl.formatMessage({ id: 'CITY' })}</label>

                  <select
                    name="billing_address[city_id]"
                    id="billing_address_city_id"
                    data-cy="billing_address_city_id"
                    value={client?.billing_address?.city_id || ''}
                    onChange={onChange}
                    disabled={!billingCities.length}
                  >
                    <option value="" disabled />

                    {billingCities.map(billingCity => (
                      <option value={billingCity.id} key={billingCity.id}>
                        {billingCity.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className={`${classes.field} ${classes.is5}`}>
                  <label htmlFor="billing_address_neighborhood">{intl.formatMessage({ id: 'NEIGHBORHOOD' })}</label>

                  <select
                    name="billing_address[neighborhood_id]"
                    id="billing_address_neighborhood_id"
                    data-cy="billing_address_neighborhood_id"
                    value={client.billing_address?.neighborhood_id || ''}
                    onChange={onChange}
                    disabled={!billingNeighborhoods.length}
                  >
                    <option value="" disabled />

                    {billingNeighborhoods.map(billingNeighborhood => (
                      <option value={billingNeighborhood.id} key={billingNeighborhood.id}>
                        {billingNeighborhood.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className={`${classes.field} ${classes.isFlex}`}>
                <div className={`${classes.field} ${classes.is6}`}>
                  <label htmlFor="billing_address_street">{intl.formatMessage({ id: 'STREET' })}</label>

                  <input
                    type="text"
                    name="billing_address[street]"
                    id="billing_address_street"
                    data-cy="billing_address_street"
                    value={client?.billing_address?.street || ''}
                    onChange={onChange}
                  />
                </div>

                <div className={`${classes.field} ${classes.is3}`}>
                  <label htmlFor="billing_address_number">{intl.formatMessage({ id: 'NUMBER' })}</label>

                  <input
                    type="text"
                    name="billing_address[number]"
                    id="billing_address_number"
                    data-cy="billing_address_number"
                    value={client?.billing_address?.number || ''}
                    onChange={onChange}
                  />
                </div>

                <div className={`${classes.field} ${classes.is3}`}>
                  <label htmlFor="billing_address_complement">{intl.formatMessage({ id: 'COMPLEMENT' })}</label>

                  <input
                    type="text"
                    name="billing_address[complement]"
                    id="billing_address_complement"
                    data-cy="billing_address_complement"
                    value={client?.billing_address?.complement || ''}
                    onChange={onChange}
                  />
                </div>
              </div>
            </>
          )}

          <h3 className={classes.subtitle}>
            <div className={classes.icon}>
              <img src={contactImage} alt={intl.formatMessage({ id: 'CONTACT' })} />
            </div>

            {intl.formatMessage({ id: 'CONTACT' })}
          </h3>

          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is12}`}>
              <label htmlFor="assignmentEmailRule">
                {intl.formatMessage({ id: 'EMAIL_FOR_ORDER_ASSIGNMENT_RULE' })}
              </label>

              <select
                id="assignmentEmailRule"
                data-cy="assignmentEmailRule"
                type="text"
                name="contact[assignment_email_rule]"
                value={client?.contact?.assignment_email_rule}
                onChange={onChange}
              >
                <option value="CREATOR">{intl.formatMessage({ id: 'ORDER_ASSIGNMENT_RULE_CREATOR' })}</option>
                <option value="CUSTOM">{intl.formatMessage({ id: 'ORDER_ASSIGNMENT_RULE_CUSTOM' })}</option>
                <option value="RESPONSIBLE">{intl.formatMessage({ id: 'ORDER_ASSIGNMENT_RULE_RESPONSIBLE' })}</option>
                <option value="NONE">{intl.formatMessage({ id: 'ORDER_ASSIGNMENT_RULE_NONE' })}</option>
              </select>
            </div>
          </div>

          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is12}`}>
              <label htmlFor="assignmentEmail">{intl.formatMessage({ id: 'EMAIL_FOR_ORDER_ASSIGNMENT' })}</label>

              <input
                id="assignmentEmail"
                data-cy="assignmentEmail"
                type="text"
                name="contact[assignment_email]"
                disabled={client?.contact?.assignment_email_rule !== 'CUSTOM'}
                value={client?.contact?.assignment_email || ''}
                onChange={onChange}
              />
            </div>
          </div>

          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="phone">{intl.formatMessage({ id: 'PHONE' })}</label>

              <MaskedInput
                type="text"
                name="contact[phone]"
                id="phone"
                data-cy="phone"
                value={client?.contact?.phone || ''}
                onChange={onChange}
                guide={false}
                mask={value => (value.length >= cellphoneMaskLength ? cellphoneMask : phoneMask)}
              />
            </div>

            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="cellphone">{intl.formatMessage({ id: 'CELLPHONE' })}</label>

              <MaskedInput
                type="text"
                name="contact[cellphone]"
                id="cellphone"
                data-cy="cellphone"
                value={client?.contact?.cellphone || ''}
                onChange={onChange}
                guide={false}
                mask={value => (value.length >= cellphoneMaskLength ? cellphoneMask : phoneMask)}
              />
            </div>
          </div>

          <h3 className={classes.subtitle}>
            <div className={classes.icon}>
              <img src={documentImage} alt={intl.formatMessage({ id: 'ORDER_PAGE' })} />
            </div>

            {intl.formatMessage({ id: 'ORDER_PAGE' })}
          </h3>

          <div className={classes.field}>
            <label htmlFor="expiration_time">
              {intl.formatMessage({ id: 'DEADLINE_FOR_RECORDING_DIVERGENCES_IN_DAYS' })}
            </label>

            <input
              type="number"
              name="hotsite_configuration[expiration_time]"
              id="expiration_time"
              data-cy="expiration_time"
              min="0"
              value={client?.hotsite_configuration?.expiration_time || ''}
              onChange={onChange}
            />
          </div>

          <div className={classes.field}>
            <label htmlFor="cc_email">{intl.formatMessage({ id: 'ADMINISTRATOR_EMAIL' })}</label>

            <input
              type="email"
              name="hotsite_configuration[cc_email]"
              id="cc_email"
              data-cy="cc_email"
              value={client?.hotsite_configuration?.cc_email || ''}
              onChange={onChange}
            />
          </div>

          <h3 className={classes.subtitle}>
            <div className={classes.icon}>
              <img src={documentImage} alt={intl.formatMessage({ id: 'FRANCHISES' })} />
            </div>

            {intl.formatMessage({ id: 'FRANCHISES' })}
          </h3>

          <div className={classes.field}>
            <Select
              id={classes.commercialFranchiseesSelect}
              name="franchisee"
              data-cy="franchisee"
              placeholder={`${intl.formatMessage({ id: 'SELECT_ONE_FRANCHISE_MESSAGE' })}*`}
              classNamePrefix="select"
              isClearable
              required
              options={franchisees}
              noOptionsMessage={() => intl.formatMessage({ id: 'NO_FRANCHISES_AVAILABLE_MESSAGE' })}
              isDisabled={!franchisees.length}
              onChange={onChangeFranchisee}
            />
          </div>

          <div className={classes.cardFooter}>
            <Link to="/commercial/clients" className={classes.button} disabled={busy}>
              {intl.formatMessage({ id: 'CANCEL' })}
            </Link>

            <button type="submit" className={`${classes.button} ${classes.isGreen}`} disabled={busy}>
              {intl.formatMessage({ id: 'SAVE' })}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );

ClientRegistrationFormComponent.propTypes = {
  busy: PropTypes.bool.isRequired,
  client: PropTypes.object,
  franchisees: PropTypes.array,
  intl: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  showBillingAddress: PropTypes.bool,
  origins: PropTypes.array.isRequired,
  classifications: PropTypes.array.isRequired,
  zipcodeMask: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  billingCities: PropTypes.array.isRequired,
  neighborhoods: PropTypes.array.isRequired,
  billingNeighborhoods: PropTypes.array.isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onChangeFranchisee: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  cnpjMask: PropTypes.array.isRequired,
  cpfOnlyMask: PropTypes.array.isRequired,
  phoneMask: PropTypes.array.isRequired,
  cellphoneMask: PropTypes.array.isRequired,
  cellphoneMaskLength: PropTypes.number.isRequired,
};

ClientRegistrationFormComponent.defaultProps = {
  client: null,
  franchisees: [],
  showBillingAddress: false,
  type: null,
};

export default ClientRegistrationFormComponent;

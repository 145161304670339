/* eslint-disable indent */
import React from 'react';
import { useIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import PropTypes from 'prop-types';

import classes from 'src/components/ExtraActions/style.module.scss';
import { orderType as parseOrderType } from 'src/constants';
import TypeGuiada from 'src/svg-icons/type-guiada';

const ExtraActions = ({ changePage, orderData, orderType }) => {
  const intl = useIntl();

  const features = JSON.parse(window.sessionStorage.getItem('me')).features.map(feature => parseOrderType.get(feature));
  const orderTypeConfigurations = JSON.parse(window.sessionStorage.getItem('orderTypeConfigurations'));

  const shouldShowButton = feature =>
    orderTypeConfigurations &&
    orderTypeConfigurations[orderType] &&
    orderTypeConfigurations[orderType][orderData.status] &&
    orderTypeConfigurations[orderType][orderData.status].extraActions &&
    orderTypeConfigurations[orderType][orderData.status].extraActions.indexOf(feature) > -1 &&
    features.indexOf(feature) > -1;

  return (
    <div className={classes.extraActions}>
      <h1>{intl.formatMessage({ id: 'EXTRA_ACTIONS' })}</h1>
      <p>{intl.formatMessage({ id: 'REQUEST_EXTRA_ACTION_MESSAGE' })}</p>
      <ul className={classes.itemList}>
        {shouldShowButton('checklist') && (
          <li>
            <button type="button" onClick={evt => changePage(evt, 'checklist-form')}>
              <figure>
                <TypeGuiada />
              </figure>
              <div className={classes.itemData}>
                <h2>{intl.formatMessage({ id: 'GUIDED_INSPECTION' })}</h2>
              </div>
            </button>
          </li>
        )}
        {shouldShowButton('repairBudget') && (
          <li
            data-tip={
              orderData.damages_count < 1
                ? intl.formatMessage({
                    id: 'NON_CONFORMITIES_REPORTED_MESSAGE',
                  })
                : null
            }
          >
            <button
              type="button"
              onClick={evt => changePage(evt, 'repair-budget-form')}
              disabled={orderData.damages_count < 1}
            >
              <figure>
                <TypeGuiada />
              </figure>
              <div className={classes.itemData}>
                <h2>{intl.formatMessage({ id: 'REPAIR_BUDGET' })}</h2>
              </div>
            </button>
          </li>
        )}
      </ul>
      <ReactTooltip effect="solid" />
    </div>
  );
};

ExtraActions.propTypes = {
  changePage: PropTypes.func.isRequired,
  orderData: PropTypes.object,
  orderType: PropTypes.string.isRequired,
};

ExtraActions.defaultProps = {
  orderData: null,
};

export default ExtraActions;

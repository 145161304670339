import axios from 'axios';

import { REDIRECT_URL, AUTH_BASE_URL, API_BASE_URL } from 'src/config';

if (window.location.hash) {
  const hash = window.location.hash.substring(1);
  if (hash !== '_=_') {
    const parameters = hash.split('&');
    if (parameters.length === 3) {
      if (parameters[0].includes('access_token') && parameters[1].includes('token_type')) {
        const token = parameters[0].substring(parameters[0].indexOf('=') + 1, parameters[0].length);
        const type = parameters[1].substring(parameters[1].indexOf('=') + 1, parameters[1].length);
        window.sessionStorage.setItem('accessToken', token);
        window.sessionStorage.setItem('tokenType', type);
        document.location.hash = '_=_';
      }
    }
  }
}

const accessToken = window.sessionStorage.getItem('accessToken');
const tokenType = window.sessionStorage.getItem('tokenType');
const instance = axios.create({
  baseURL: API_BASE_URL,
  headers: { Authorization: `${tokenType} ${accessToken}` },
});
instance.CancelToken = axios.CancelToken;

instance.interceptors.response.use(
  response => response,
  error => {
    const redirectUrl = window?.location?.href || REDIRECT_URL;
    // Do something with response error
    if (error.response && error.response.status === 401) {
      window.location.replace(
        `${AUTH_BASE_URL}/login?response_type=token` +
          `&redirect_uri=${redirectUrl.replace('#_=_', '')}` +
          '&client_id=redevistorias' +
          '&scope=users%20order:*%20client:*%20device:*%20financial:*%20package:*%20entity:*%20integration:*'
      );
    }
    return Promise.reject(error);
  }
);

export const getAccessToken = () => {
  return window.sessionStorage.getItem('accessToken');
};

export default instance;

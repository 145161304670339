import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let TypeSaida = props => (
  <SvgIcon {...props} viewBox="0 0 34.016 34.016">
    <path
      fill="#E59F35"
      d="M24.84,13.938h-1.323v2.574h-8.602h-0.662h-0.662v6.438v1.285
	h1.324h8.602v2.574h1.323l2.647-1.609v2.896c0,1.067-0.889,1.932-1.985,1.932H8.296c-1.096,0-1.985-0.864-1.985-1.932V6.858
	c0-1.066,0.889-1.931,1.985-1.931h5.295l-3.309,1.287v5.149h13.235V6.215l-3.31-1.287h5.295c1.097,0,1.985,0.864,1.985,1.931v9.011
	L24.84,13.938z M22.192,10.077H11.605V7.502l3.31-1.287V4.284c0-1.066,0.227-1.288,1.323-1.288h1.324
	c1.097,0,1.324,0.221,1.324,1.288v1.931l3.307,1.287V10.077z M24.84,24.235V22.95h-1.323h-8.602v-5.151h8.602h1.323v-1.286v-0.644
	l6.178,4.806l-6.178,4.204V24.235z"
    />
  </SvgIcon>
);
TypeSaida = pure(TypeSaida);
TypeSaida.displayName = 'TypeSaida';
TypeSaida.muiName = 'SvgIcon';

export default TypeSaida;

import { createIntl } from 'react-intl';

import messages from 'src/translations/pt_br.json';

const intl = createIntl({ locale: 'pt', messages });

const getReportNotificationTexts = ({ data, type }) => {
  const alertType = type === 'report' ? data?.format : type;

  switch (alertType) {
    case 'simple_damages':
    case 'simple_inspection':
    case 'simple_report':
    case 'simple_measurement':
      return {
        title: intl.formatMessage({ id: 'reportTitle' }),
        text: intl.formatMessage({ id: 'simpleReport' }, { buildingId: data?.building_id }),
        downloadLabel: 'Relatório de vistoria sem fotos',
      };

    case 'full_damages':
    case 'full_inspection':
    case 'full_report':
    case 'full_measurement':
    case 'full_repair_budget':
    case 'full_5asec':
    case 'full_questionnaire':
      return {
        title: intl.formatMessage({ id: 'reportTitle' }),
        text: intl.formatMessage({ id: 'fullReport' }, { buildingId: data?.building_id }),
      };

    case 'zip_archive':
      return {
        title: intl.formatMessage({ id: 'archiveTitle' }),
        text: intl.formatMessage({ id: 'zipArchive' }, { buildingId: data?.building_id }),
      };

    case 'inspections_summary':
      return {
        title: intl.formatMessage({ id: 'inspectionsTitle' }),
        text: intl.formatMessage({ id: 'inspectionsSummary' }),
      };

    case 'simple_signature':
    case 'full_signature':
      return {
        title: intl.formatMessage({ id: 'reportTitle' }),
        text: intl.formatMessage({ id: 'signatureReport' }, { buildingId: data?.building_id }),
      };

    case 'simple_divergence':
      return {
        title: intl.formatMessage({ id: 'reportTitle' }),
        text: intl.formatMessage({ id: 'divergenceSimpleReport' }, { buildingId: data?.building_id }),
      };

    case 'full_divergence':
      return {
        title: intl.formatMessage({ id: 'reportTitle' }),
        text: intl.formatMessage({ id: 'divergenceFullReport' }, { buildingId: data?.building_id }),
      };

    case 'simple_predial':
      return {
        title: intl.formatMessage({ id: 'reportTitle' }),
        text: intl.formatMessage({ id: 'predialSimpleReport' }, { buildingId: data?.building_id }),
      };

    case 'full_predial':
      return {
        title: intl.formatMessage({ id: 'reportTitle' }),
        text: intl.formatMessage({ id: 'predialFullReport' }, { buildingId: data?.building_id }),
      };

    case 'fc_report':
      return {
        title: intl.formatMessage({ id: 'reportTitle' }),
        text: intl.formatMessage({ id: 'fcFullReport' }),
      };

    default:
      return {
        title: '',
        text: '',
      };
  }
};

export default getReportNotificationTexts;

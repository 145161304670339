import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let TypeAuditoriaLoja = props => (
  <SvgIcon {...props} fill="none" viewBox="0 0 34.016 34.016">
    <path
      d="M13.1854 3.7591V1.81716C13.1854 0.746265 12.9516 0.52002 11.8354 0.52002H10.7872C9.67101 0.52002 9.43722 0.742495 9.43722 1.81716V3.7591L6.06616 5.0487V7.63922H16.5564V5.0487L13.1854 3.7591Z"
      fill="#008870"
    />
    <path
      d="M21.428 4.31708V9.94683H13.5094V26.1611H21.3375C21.0886 26.9454 20.3496 27.5073 19.4898 27.4997H2.76274C1.70316 27.5148 0.835884 26.6664 0.820801 25.603C0.820801 25.5993 0.820801 25.5993 0.820801 25.5993V4.66776C0.835884 3.60441 1.70316 2.75222 2.76274 2.7673H7.8759L4.56517 4.04559V9.13612H17.6799V3.67982L14.5841 2.39399H19.5539C20.5758 2.37891 21.4129 3.23864 21.428 4.31708Z"
      fill="#008870"
    />
    <path
      d="M14.2639 10.7012V25.4071H25.1991V10.7012H14.2639ZM18.7888 12.9636H24.0679V14.0949H18.7888V12.9636ZM15.0181 12.5866H16.9034V14.4719H15.0181V12.5866ZM15.0181 16.7344H16.9034V18.6198H15.0181V16.7344ZM16.9034 22.7676H15.0181V20.8822H16.9034V22.7676ZM18.7888 17.1115H24.0679V18.2427H18.7888V17.1115ZM24.0679 22.3905H18.7888V21.2593H24.0679V22.3905Z"
      fill="#008870"
    />
  </SvgIcon>
);

TypeAuditoriaLoja = pure(TypeAuditoriaLoja);
TypeAuditoriaLoja.displayName = 'TypeAuditoriaLoja';
TypeAuditoriaLoja.muiName = 'SvgIcon';

export default TypeAuditoriaLoja;

// Method to generate the hash
export const generateToken = franchise => {

  const tokenPayload = {
    franchisee_id: franchise.id,
    name: franchise.name,
    email: franchise.email,
    phone: franchise.phone,
  };

  // Encode the payload to Base64
  const encodedPayload = btoa(JSON.stringify(tokenPayload));

  return encodedPayload;
};

export const decodeToken = token => {
  try {
    // Decode the payload from Base64
    const decodedPayload = JSON.parse(atob(token));
    return decodedPayload;
  } catch (error) {
    return null;
  }
};

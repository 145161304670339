import React from 'react';

const attachmentField = props => {
  return (
    <div>
      <input type="text" name="extension" value={props.formData.extension} data-cy="extension" />
      <input type="text" name="original_name" value={props.formData.original_name} data-cy="original_name" />
      <input type="text" name="remote_name" value={props.formData.remote_name} data-cy="remote_name" />
    </div>
  );
};

export default attachmentField;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const TypeMedicaoObraTrinus = props => (
  <SvgIcon {...props} viewBox="0 0 34 34">
    <path
      d="M30.639 60.262c-1.75.318-2.1-1.119-2.644-2.197-6.759-13.417-13.49-26.846-20.232-40.271C5.389 13.066 3.012 8.344.654 3.608.127 2.546-.472 1.448.582.333 2.159.293 3.2 1.351 4.186 2.296c5.118 4.913 10.477 9.56 15.715 14.339 4.988 4.55 9.905 9.185 15.042 13.578 1.264 1.078 2.55 2.188 3.175 3.83-.85 5.56-2.708 10.87-4.012 16.318-.527 2.204-1.122 4.397-1.693 6.594-.33 1.247-.833 2.393-1.774 3.307Z"
      fill="#2B4899"
    />
    <path
      d="M37.442 33.87C25.529 23.068 13.617 12.266 1.712 1.46 1.318 1.102.956.71.582.333 1.338-.383 2.135-.45 3.14-.1 21.361 6.21 39.594 12.495 57.83 18.763c.87.298 1.562.724 1.992 1.537-.322 1.726-1.742 2.51-3.041 3.255-5.75 3.295-11.341 6.86-17.127 10.094-.728.41-1.408.812-2.212.22Z"
      fill="#60C3DC"
    />
    <path
      d="M37.442 33.87c6.944-4.116 13.891-8.231 20.831-12.355.564-.334 1.223-.571 1.545-1.215.54 1.13-.237 1.863-.813 2.651-8.653 11.832-17.299 23.676-25.993 35.484-.58.785-1.022 1.96-2.378 1.83l6.808-26.395Z"
      fill="#3768B0"
    />
  </SvgIcon>
);

TypeMedicaoObraTrinus.displayName = 'TypeMedicaoObraTrinus';
TypeMedicaoObraTrinus.muiName = 'SvgIcon';

export default TypeMedicaoObraTrinus;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = {
  bootstrapInput: {
    borderRadius: 4,
    fontWeight: 'normal',
    border: '1px solid #ced4da',
    fontSize: 'calc(6px + 0.4vw)',
    padding: '8px 12px',
    width: 'calc(100% - 24px)',
    '&:focus': {
      borderColor: '#80bdff',
    },
    fontFamily: 'Open Sans',
    backgroundColor: '#F4F3F4',
  },
  bootstrapInputError: {
    borderRadius: 4,
    border: '1px solid red',
    fontSize: 'calc(6px + 0.4vw)',
    padding: '8px 12px',
    width: 'calc(100% - 24px)',
    '&:focus': {
      borderColor: '#80bdff',
    },
    fontFamily: 'Open Sans',
  },
  label: {
    fontFamily: 'Open Sans',
    marginTop: '0.3vh',
    fontSize: 'calc(6px + 0.3vw)',
  },
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      ref={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value ? parseFloat(values.value) : values.value,
          },
        });
      }}
      thousandSeparator={'.'}
      decimalSeparator={','}
      decimalScale={2}
      fixedDecimalScale
      suffix={' m²'}
      isNumericString
    />
  );
}

function RequestFormWidgetPriceArea(props) {
  let error = false;
  if (props.rawErrors) {
    if (props.rawErrors.length > 0) {
      error = true;
    }
  }
  return (
    <div>
      {
        <div style={styles.label}>
          <FormattedMessage id={props.label} />
          {props.required ? '*' : null}
        </div>
      }
      <TextField
        disabled
        fullWidth={true}
        className="custom"
        value={props.value}
        data-cy={String(props.label).toLowerCase()}
        onChange={event => props.onChange(event.target.value)}
        InputProps={{
          disableUnderline: true,
          classes: {
            input: error ? props.classes.bootstrapInputError : props.classes.bootstrapInput,
          },
          inputComponent: NumberFormatCustom,
        }}
      />
    </div>
  );
}

export default withStyles(styles)(RequestFormWidgetPriceArea);

import React from 'react';

import PropTypes from 'prop-types';

import classes from 'src/components/ClientLinkLayout/style.module.scss';
import facebook from 'src/components/common/icons/facebook.svg';
import instagram from 'src/components/common/icons/instagram.svg';
import linkClientBackground from 'src/components/common/icons/linkClientBackground.svg';
import linkedin from 'src/components/common/icons/linkedin.svg';
import youtube from 'src/components/common/icons/youtube.svg';
import linkClientImage from 'src/png-icons/linkClientImage.png';

const ClientLinkLayoutComponent = ({ children, franchise }) => {
  return (
    <div className={classes.ClientLinkLayout}>
      <div className={classes.header}>
        <div className={classes.container}>
          <div className={classes.logo}>
            <img src="/assets/img/logo_rede3.svg" alt="Logo Rede Vistorias" />
          </div>
          <div className={classes.headerRigth}>
            <h6>{franchise?.name}</h6>
            <p>{franchise?.email}</p>
            <p>{franchise?.phone}</p>
          </div>
        </div>
      </div>
      <div className={classes.Content} style={{ backgroundImage: `url(${linkClientBackground})` }}>
        <div className={classes.ContentLeft}>
          <div>
            <h3>REALIZE SEU CADASTRO</h3>
            <p>Preencha o formulário ao lado e acesse o Painel de Vistorias!</p>
            <img className={classes.Image} src={linkClientImage} alt=""></img>
          </div>
        </div>
        <div className={classes.ContentRight}>{children}</div>
      </div>
      <div className={classes.Footer}>
        <div className={classes.FooterLeft}>
          <img src="/assets/img/logo_rede2.svg" alt="Logo Rede Vistorias" />
          <p>
            Matriz: Rodovia Admar Gonzaga, n° 440, Edifício América Officenter, 6° andar, Sala 22 Itacorubi,
            Florianópolis/SC – CEP: 88034-000
          </p>
        </div>
        <div className={classes.FooterRight}>
          <p>Acompanhe todas as novidades na sua rede social preferida</p>
          <ul className={classes.social}>
            <li>
              <a href="https://www.youtube.com/c/SomosGrupoRV" target="_blank" rel="noreferrer">
                <img src={youtube} alt="youtube" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/somosgruporv/" target="_blank" rel="noreferrer">
                <img src={instagram} alt="instagram" />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/somosgruporv" target="_blank" rel="noreferrer">
                <img src={facebook} alt="facebook" />
              </a>
            </li>
            <li>
              <a href="https://br.linkedin.com/company/somosgruporv" target="_blank" rel="noreferrer">
                <img src={linkedin} alt="linkedin" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={classes.Footerbar}>
        <div>© 2022 - Todos os direitos reservados</div>
        <div>CNPJ: 25.005.259/0001-56</div>
        <div>Desenvolvido por GRUPORV</div>
      </div>
    </div>
  );
};

ClientLinkLayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
  franchise: PropTypes.object,
};

ClientLinkLayoutComponent.defaultProps = {
  franchise: null,
};

export default ClientLinkLayoutComponent;

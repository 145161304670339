import React from 'react';

import PropTypes from 'prop-types';

import classes from 'src/orderForm/fields/RealEstateAdressesSelectField/RealEstateAddressOption/style.module.scss';

const RealEstateAddressOption = ({ data, innerProps, innerRef }) => (
  <div data-cy="real-estate-address-option" className={classes.realEstateAddressOption} ref={innerRef} {...innerProps}>
    <h4>{data?.label}</h4>

    <p>{data?.realEstateAddressText}</p>
  </div>
);

RealEstateAddressOption.propTypes = {
  data: PropTypes.object,
  innerProps: PropTypes.object.isRequired,
  innerRef: PropTypes.func,
};

RealEstateAddressOption.defaultProps = {
  data: null,
  innerRef: null,
};

export default RealEstateAddressOption;

import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import checkImage from 'src/images/icons/checkSucces.svg';
import classes from 'src/orderForm/ClientOrderForm/ClientOrderForm.module.css';

const ClientFormSuccess = () => {
  const { push } = useHistory();

  return (
    <Grid container className={classes.content}>
      <div className={classes.success}>
        <p className={classes.titleSuccess}>
          <img src={checkImage} alt="Sucesso" />
          PEDIDO ENVIADO COM SUCESSO!
        </p>
        <p>Em breve um vendedor entrará em contato para prosseguir com o seu pedido.</p>
        <p>Em caso de dúvidas, entre em contato com seu consultor.</p>
        <div className={classes.divisor}></div>
        <Button variant="outlined" size="small" className={classes.Button} onClick={() => push('/orders')}>
          Ir para Minhas Vistorias
        </Button>
      </div>
    </Grid>
  );
};

export default ClientFormSuccess;

import React, { createContext, useCallback, useContext, useState } from 'react';

import PropTypes from 'prop-types';

import ConfirmAlert from 'src/components/common/ConfirmAlert';

export const ConfirmAlertContext = createContext(null);

export const ConfirmAlertProvider = ({ children }) => {
  const [confirmationData, setConfirmationData] = useState({
    isOpen: false,
    message: '',
    onConfirm: () => {},
    onCancel: () => {},
  });

  const openConfirmAlert = useCallback(({ message, onConfirm, onCancel }) => {
    setConfirmationData(state => ({
      ...state,
      isOpen: true,
      message,
      onConfirm: onConfirm !== undefined ? onConfirm : () => {},
      onCancel: onCancel !== undefined ? onCancel : () => {},
    }));
  }, []);

  const handleConfirmClick = useCallback(() => {
    setConfirmationData(state => ({ ...state, isOpen: false }));

    confirmationData.onConfirm();
  }, [confirmationData]);

  const handleCancelClick = useCallback(() => {
    setConfirmationData(state => ({ ...state, isOpen: false }));

    confirmationData.onCancel();
  }, [confirmationData]);

  return (
    <ConfirmAlertContext.Provider
      value={{
        openConfirmAlert,
        isOpen: confirmationData.isOpen,
        message: confirmationData.message,
        onOk: handleConfirmClick,
        onCancel: handleCancelClick,
      }}
    >
      {children}
      <ConfirmAlert />
    </ConfirmAlertContext.Provider>
  );
};

ConfirmAlertProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useConfirmAlert() {
  const context = useContext(ConfirmAlertContext);

  if (!context) {
    throw new Error('useConfirmAlert must be used within an ConfirmAlertProvider component');
  }

  return context;
}

export const ConfirmAlertConsumer = ConfirmAlertContext.Consumer;

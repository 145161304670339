import React from 'react';
import { FormattedMessage } from 'react-intl';

import PropTypes from 'prop-types';

import classes from 'src/components/AdvancedOptions/style.module.scss';
import FeatureSwitch from 'src/components/common/FeatureSwitch';
import { ReactComponent as EllipsisIcon } from 'src/components/common/icons/ellipsis-h.svg';

const AdvancedOptionsComponent = ({
  menuRef,
  advancedActions,
  isMenuVisible,
  toggleMenu,
  selectOption,
  CurrentAction,
  components,
}) => (
  <div className={classes.advancedOptions} ref={menuRef}>
    {advancedActions.length > 0 ? (
      <FeatureSwitch feature={Object.keys(components).map(key => components[key]?.feature)}>
        <button type="button" className={classes.ellipsisButton} onClick={toggleMenu}>
          <EllipsisIcon />
        </button>
      </FeatureSwitch>
    ) : null}
    {isMenuVisible ? (
      <ul className={classes.menu}>
        {advancedActions.map(action => (
          <FeatureSwitch feature={components[action]?.feature} key={action}>
            <li>
              <button type="button" onClick={() => selectOption(action)}>
                <FormattedMessage id={action} />
              </button>
            </li>
          </FeatureSwitch>
        ))}
      </ul>
    ) : null}
    <CurrentAction />
  </div>
);

AdvancedOptionsComponent.propTypes = {
  menuRef: PropTypes.object.isRequired,
  advancedActions: PropTypes.array.isRequired,
  isMenuVisible: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  selectOption: PropTypes.func.isRequired,
  CurrentAction: PropTypes.func.isRequired,
  components: PropTypes.object.isRequired,
};

export default AdvancedOptionsComponent;

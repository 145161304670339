import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import MaskedInput from 'react-text-mask';

import PropTypes from 'prop-types';

import Card from 'src/components/common/Card';
import ColorButton from 'src/components/common/elements/ColorButton';
import detailsImage from 'src/components/common/icons/client-details.svg';
import formationImage from 'src/components/common/icons/client-formation.svg';
import contactImage from 'src/components/common/icons/contact-circle.svg';
import moreInformationImage from 'src/components/common/icons/user-circle-add.svg';
import ImageCrop from 'src/components/common/ImageCrop';
import Spinner from 'src/components/common/Spinner';
import classes from 'src/components/InspectorsPage/InspectorForm/style.module.scss';
import { BASE_URL } from 'src/config';
import { DATE_MASK, USERNAME_MASK } from 'src/helpers/mask';
import AddressIcon from 'src/svg-icons/address';
import InspectorIcon from 'src/svg-icons/client';
import RemoveIcon from 'src/svg-icons/remove';

const InspectorFormComponent = ({
  formik,
  action,
  busy,
  isSupportModeActive,
  franchiseeCountry,
  zipcodeMask,
  states,
  cities,
  neighborhoods,
  loading,
  id,
  handleFormChange,
  intl,
  cpfMask,
  phoneMask,
  cellphoneMask,
  cellphoneMaskLength,
}) => {
  const [urlPrevImage, setUrlPrevImage] = useState(null);

  if (loading) {
    return (
      <div className={classes.clientForm}>
        <div className={classes.spinner}>
          <Spinner />
        </div>
      </div>
    );
  }

  const animatedComponents = makeAnimated();

  const softwareOptions = [
    { value: 'CAD', label: 'CAD' },
    { value: 'MS Project', label: 'MS Project' },
    { value: 'Prevision', label: 'Prevision' },
    { value: 'Bim', label: 'Bim' },
    { value: 'Revit', label: 'Revit' },
    { value: 'Não possuo experiência', label: 'Não possuo experiência' },
  ];

  const vehicleTypeOptions = [
    { value: 'Carro', label: 'Carro' },
    { value: 'Moto', label: 'Moto' },
  ];

  const handleSoftwareExperienceChange = value => {
    if (!value) formik.setFieldValue('extra_info.software_experience', []);
    else
      formik.setFieldValue(
        'extra_info.software_experience',
        value.map(item => item.value)
      );
  };

  const handleVehicleTypeChange = value => {
    if (!value) formik.setFieldValue('extra_info.vehicle_type', []);
    else
      formik.setFieldValue(
        'extra_info.vehicle_type',
        value.map(item => item.value)
      );
  };

  const removeProfileImage = () => {
    setUrlPrevImage(null);
    formik.setFieldValue('avatarToUpload', null);
    formik.setFieldValue('avatar', '');
  };

  const onConfirm = async image => {
    const objectURL = URL.createObjectURL(image);
    setUrlPrevImage(objectURL);
    formik.setFieldValue('avatarToUpload', image);
  };

  return (
    <div className={classes.clientForm}>
      <Card className="fade-in">
        <h2 className={classes.cardHead}>
          <div className={classes.back}>
            <Link to={action === 'edit' ? `/inspectors/active/${id}` : '/inspectors'}>
              <div className={classes.arrowLeft} />
            </Link>
          </div>

          <div className={classes.icon}>
            <img src={detailsImage} alt={intl.formatMessage({ id: 'DETAILS' })} />
          </div>

          {action === 'edit'
            ? intl.formatMessage({ id: 'UPDATE_INSPECTOR' })
            : intl.formatMessage({ id: 'CREATE_INSPECTOR' })}
        </h2>

        <form onSubmit={formik.handleSubmit} onChange={handleFormChange}>
          <h3 className={classes.subtitle}>
            <div className={classes.icon}>
              <InspectorIcon />
            </div>

            {intl.formatMessage({ id: 'PERSONAL_DATA' })}
          </h3>

          <div className={classes.field}>
            <label htmlFor="name">{intl.formatMessage({ id: 'NAME' })}*</label>

            <input
              type="text"
              name="name"
              id="name"
              data-cy="name"
              required
              value={formik.values?.name || ''}
              onChange={formik.handleChange}
              disabled={action === 'edit' && !isSupportModeActive()}
            />
          </div>

          <div className={`${classes.field} ${classes.isFlex}`}>
            {franchiseeCountry === 'BR' && (
              <div className={`${classes.field} ${classes.is6}`}>
                <label htmlFor="rg">{intl.formatMessage({ id: 'RG' })}*</label>

                <input
                  type="text"
                  name="rg"
                  id="rg"
                  data-cy="rg"
                  required
                  value={formik.values?.rg || ''}
                  onChange={formik.handleChange}
                />
              </div>
            )}

            <div className={`${classes.field} ${franchiseeCountry === 'BR' ? classes.is6 : classes.is12}`}>
              <label htmlFor="cpf">{intl.formatMessage({ id: 'CPF' })}*</label>

              <MaskedInput
                type="text"
                name="document"
                id="document"
                data-cy="document"
                required
                value={formik.values?.document || ''}
                onChange={formik.handleChange}
                guide={false}
                mask={cpfMask}
              />
            </div>
          </div>

          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="birth_date">{intl.formatMessage({ id: 'BIRTH_DATE' })}*</label>

              <MaskedInput
                type="text"
                name="birth_date"
                id="birth_date"
                data-cy="birth_date"
                value={formik.values?.birth_date || ''}
                onChange={formik.handleChange}
                guide={false}
                mask={DATE_MASK}
                required
              />
            </div>

            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="genre">{intl.formatMessage({ id: 'GENRE' })}*</label>

              <select
                name="genre"
                id="genre"
                data-cy="genre"
                value={formik.values?.genre || ''}
                onChange={formik.handleChange}
                required
              >
                <option value=""></option>
                <option value="Masculino">Masculino</option>
                <option value="Feminino">Feminino</option>
                <option value="Outro">Outro</option>
              </select>
            </div>
          </div>

          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="marital_status">{intl.formatMessage({ id: 'MARITAL_STATUS' })}</label>

              <select
                name="marital_status"
                id="marital_status"
                data-cy="marital_status"
                value={formik.values?.marital_status || ''}
                onChange={formik.handleChange}
              >
                <option value=""></option>
                <option value="Solteiro(a)">Solteiro(a)</option>
                <option value="Casado(a)">Casado(a)</option>
                <option value="União estável">União estável</option>
                <option value="Separado(a)">Separado(a)</option>
                <option value="Divorciado(a)">Divorciado(a)</option>
                <option value="Viúvo(a)">Viúvo(a)</option>
              </select>
            </div>

            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="children">{intl.formatMessage({ id: 'NUMBER_OF_CHILDREN' })}</label>

              <input
                type="number"
                name="children"
                id="children"
                data-cy="children"
                value={formik.values?.children || ''}
                onChange={formik.handleChange}
              />
            </div>
          </div>

          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="username">{intl.formatMessage({ id: 'USER' })}*</label>

              <MaskedInput
                type="text"
                name="username"
                id="username"
                data-cy="username"
                required
                value={formik.values?.username || ''}
                disabled={action === 'edit'}
                onChange={formik.handleChange}
                guide={false}
                mask={action === 'edit' ? false : USERNAME_MASK}
              />
            </div>

            <div className={`${classes.field} ${classes.is6}`}>
              <label>{intl.formatMessage({ id: 'PROFILE_IMAGE' })}</label>

              <div className={classes.profileImage}>
                {urlPrevImage ? (
                  <img
                    className="mr-2"
                    width={50}
                    height={50}
                    src={urlPrevImage}
                    alt={intl.formatMessage({ id: 'PROFILE_IMAGE' })}
                  />
                ) : formik.values?.avatar ? (
                  <img
                    className="mr-2"
                    width={50}
                    height={50}
                    src={BASE_URL + '/api/images/' + formik.values?.avatar}
                    alt={intl.formatMessage({ id: 'PROFILE_IMAGE' })}
                  />
                ) : (
                  <span className="mr-2">{intl.formatMessage({ id: 'ADD_PROFILE_IMAGE' })}</span>
                )}

                <ImageCrop onConfirm={onConfirm}></ImageCrop>

                {/* {urlPrevImage && ( */}
                <div className={classes.icon} onClick={removeProfileImage}>
                  <RemoveIcon style={{ fontSize: '18px' }} />
                </div>
                {/* )} */}
              </div>
            </div>
          </div>

          <h3 className={classes.subtitle}>
            <div className={classes.icon}>
              <img src={contactImage} alt={intl.formatMessage({ id: 'CONTACT' })} />
            </div>

            {intl.formatMessage({ id: 'CONTACT' })}
          </h3>

          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="email">{intl.formatMessage({ id: 'EMAIL' })}*</label>

              <input
                type="text"
                name="contact.email"
                id="contact.email"
                data-cy="contact.email"
                required
                value={formik.values?.contact?.email || ''}
                onChange={formik.handleChange}
              />
            </div>
          </div>

          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="phone">{intl.formatMessage({ id: 'PHONE' })}</label>

              <MaskedInput
                type="text"
                name="contact.phone"
                id="contact.phone"
                data-cy="contact.phone"
                value={formik.values?.contact?.phone || ''}
                onChange={formik.handleChange}
                guide={false}
                mask={value => (value.length >= cellphoneMaskLength ? cellphoneMask : phoneMask)}
              />
            </div>

            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="cellphone">{intl.formatMessage({ id: 'CELLPHONE' })}</label>

              <MaskedInput
                type="text"
                name="contact.cellphone"
                id="contact.cellphone"
                data-cy="contact.cellphone"
                value={formik.values?.contact?.cellphone || ''}
                onChange={formik.handleChange}
                guide={false}
                mask={value => (value.length >= cellphoneMaskLength ? cellphoneMask : phoneMask)}
              />
            </div>
          </div>

          <h3 className={classes.subtitle}>
            <div className={classes.icon}>
              <AddressIcon />
            </div>

            {intl.formatMessage({ id: 'HOME_ADDRESS' })}
          </h3>

          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="zipcode">{intl.formatMessage({ id: 'CEP' })}*</label>

              <MaskedInput
                type="text"
                name="address.zipcode"
                id="address.zipcode"
                data-cy="address.zipcode"
                value={formik.values?.address?.zipcode || ''}
                onChange={formik.handleChange}
                guide={false}
                mask={zipcodeMask}
                required
              />
            </div>
          </div>

          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is2}`}>
              <label htmlFor="state_id">{intl.formatMessage({ id: 'STATE' })}*</label>

              <select
                name="address.state_id"
                id="address.state_id"
                data-cy="address.state_id"
                value={formik.values?.address?.state_id || ''}
                onChange={formik.handleChange}
                required
              >
                {states.map(state => (
                  <option value={state?.id} key={state?.id}>
                    {state?.name}
                  </option>
                ))}
              </select>

              <input
                type="hidden"
                name="address.state"
                id="address.state"
                data-cy="address.state"
                value={formik.values?.address?.state || ''}
                onChange={formik.handleChange}
              />
            </div>

            <div className={`${classes.field} ${classes.is5}`}>
              <label htmlFor="city_id">{intl.formatMessage({ id: 'CITY' })}*</label>

              <select
                name="address.city_id"
                id="address.city_id"
                data-cy="address.city_id"
                value={formik.values?.address?.city_id || ''}
                onChange={formik.handleChange}
                required
              >
                {cities.map(city => (
                  <option value={city?.id} key={city?.id}>
                    {city?.name}
                  </option>
                ))}
              </select>

              <input
                type="hidden"
                name="address.city"
                id="address.city"
                data-cy="address.city"
                value={formik.values?.address?.city || ''}
                onChange={formik.handleChange}
                required
              />
            </div>

            <div className={`${classes.field} ${classes.is5}`}>
              <label htmlFor="neighborhood_id">{intl.formatMessage({ id: 'NEIGHBORHOOD' })}*</label>

              <select
                name="address.neighborhood_id"
                id="address.neighborhood_id"
                data-cy="address.neighborhood_id"
                value={formik.values.address?.neighborhood_id || ''}
                onChange={formik.handleChange}
                required
              >
                {neighborhoods.map(neighborhood => (
                  <option value={neighborhood.id} key={neighborhood.id}>
                    {neighborhood.name}
                  </option>
                ))}
              </select>

              <input
                type="hidden"
                name="address.neighborhood"
                id="address.neighborhood"
                data-cy="address.neighborhood"
                value={formik.values?.address?.neighborhood || ''}
                onChange={formik.handleChange}
                required
              />
            </div>
          </div>

          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="street">{intl.formatMessage({ id: 'STREET' })}*</label>

              <input
                type="text"
                name="address.street"
                id="address.street"
                data-cy="address.street"
                value={formik.values?.address?.street || ''}
                onChange={formik.handleChange}
                required
              />
            </div>

            <div className={`${classes.field} ${classes.is3}`}>
              <label htmlFor="number">{intl.formatMessage({ id: 'NUMBER' })}*</label>

              <input
                type="text"
                name="address.number"
                id="address.number"
                data-cy="address.number"
                value={formik.values?.address?.number || ''}
                onChange={formik.handleChange}
                required
              />
            </div>

            <div className={`${classes.field} ${classes.is3}`}>
              <label htmlFor="complement">{intl.formatMessage({ id: 'COMPLEMENT' })}</label>

              <input
                type="text"
                name="address.complement"
                id="address.complement"
                data-cy="address.complement"
                value={formik.values?.address?.complement || ''}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <h3 className={classes.subtitle}>
            <div className={classes.icon}>
              <img src={formationImage} alt={intl.formatMessage({ id: 'FORMATION' })} />
            </div>

            {intl.formatMessage({ id: 'FORMATION' })}
          </h3>

          <div className={classes.field}>
            <label htmlFor="academic_background.education">{intl.formatMessage({ id: 'EDUCATION' })}*</label>

            <select
              name="academic_background.education"
              id="academic_background.education"
              data-cy="academic_background.education"
              value={formik.values?.academic_background?.education || ''}
              onChange={formik.handleChange}
              required
            >
              <option value=""></option>
              <option value="Ensino fundamental">Ensino fundamental</option>
              <option value="Ensino médio">Ensino médio</option>
              <option value="Técnico">Técnico</option>
              <option value="Ensino superior incompleto">Ensino superior incompleto</option>
              <option value="Ensino superior completo">Ensino superior completo</option>
              <option value="Pós-graduação">Pós-graduação</option>
              <option value="Mestrado">Mestrado</option>
              <option value="Doutorado">Doutorado</option>
            </select>
          </div>

          <div className={classes.field}>
            <label htmlFor="academic_background.course">{intl.formatMessage({ id: 'COURSE' })}</label>

            <input
              type="text"
              name="academic_background.course"
              id="academic_background.course"
              data-cy="academic_background.course"
              value={formik.values?.academic_background?.course || ''}
              onChange={formik.handleChange}
            />
          </div>

          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="academic_background.has_technical_expertise">
                {intl.formatMessage({ id: 'HAS_TECHNICAL_SPECIALIZATION' })}*
              </label>

              <select
                name="academic_background.has_technical_expertise"
                id="academic_background.has_technical_expertise"
                data-cy="academic_background.has_technical_expertise"
                value={formik.values?.academic_background?.has_technical_expertise || ''}
                onChange={formik.handleChange}
                required
              >
                <option value=""></option>
                <option value="Sim">Sim</option>
                <option value="Não">Não</option>
              </select>
            </div>

            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="academic_background.technical_expertise">
                {intl.formatMessage({ id: 'instructions' })}
              </label>

              <input
                type="text"
                name="academic_background.technical_expertise"
                id="academic_background.technical_expertise"
                data-cy="academic_background.technical_expertise"
                value={formik.values?.academic_background?.technical_expertise || ''}
                onChange={formik.handleChange}
                required={formik.values?.academic_background?.has_technical_expertise === 'Sim'}
              />
            </div>
          </div>

          <h3 className={classes.subtitle}>
            <div className={classes.icon}>
              <img src={moreInformationImage} alt={intl.formatMessage({ id: 'MORE_INFORMATION' })} />
            </div>

            {intl.formatMessage({ id: 'MORE_INFORMATION' })}
          </h3>

          <div className={classes.field}>
            <label htmlFor="extra_info.experience">{intl.formatMessage({ id: 'HAS_EXPERIENCE' })}</label>

            <input
              type="text"
              name="extra_info.experience"
              id="extra_info.experience"
              data-cy="extra_info.experience"
              value={formik.values?.extra_info?.experience || ''}
              onChange={formik.handleChange}
            />
          </div>

          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="extra_info.has_vehicle">{intl.formatMessage({ id: 'HAS_VEHICLE' })}*</label>

              <select
                name="extra_info.has_vehicle"
                id="extra_info.has_vehicle"
                data-cy="extra_info.has_vehicle"
                value={formik.values?.extra_info?.has_vehicle || ''}
                onChange={formik.handleChange}
                required
              >
                <option value=""></option>
                <option value="Sim">Sim</option>
                <option value="Não">Não</option>
              </select>
            </div>

            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="extra_info.vehicle_type">{intl.formatMessage({ id: 'VEHICLE_TYPE' })}</label>

              <Select
                className={classes.select}
                classNamePrefix="select"
                components={{
                  ...animatedComponents,
                  Input: inputProps => <components.Input {...inputProps} data-cy="extra_info.vehicle_type" />,
                }}
                options={vehicleTypeOptions}
                isMulti
                placeholder={intl.formatMessage({ id: 'SELECT_A_BOOKMARK' })}
                noOptionsMessage={() => intl.formatMessage({ id: 'NO_BOOKMARK_FOUND_MESSAGE' })}
                onChange={handleVehicleTypeChange}
                defaultValue={
                  formik.values?.extra_info?.vehicle_type
                    ? formik.values?.extra_info?.vehicle_type.map(item => ({
                        value: item,
                        label: item,
                      }))
                    : []
                }
              />
            </div>
          </div>

          <div className={`${classes.field} ${classes.isFlex}`}>
            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="react-select-2-input">{intl.formatMessage({ id: 'HAS_SOFTWARE_EXPERIENCE' })}</label>

              <Select
                className={classes.select}
                classNamePrefix="select"
                components={{
                  ...animatedComponents,
                  Input: inputProps => <components.Input {...inputProps} data-cy="extra_info.software_experience" />,
                }}
                options={softwareOptions}
                isMulti
                placeholder={intl.formatMessage({ id: 'SELECT_A_BOOKMARK' })}
                noOptionsMessage={() => intl.formatMessage({ id: 'NO_BOOKMARK_FOUND_MESSAGE' })}
                onChange={handleSoftwareExperienceChange}
                defaultValue={
                  formik.values?.extra_info?.software_experience
                    ? formik.values?.extra_info?.software_experience.map(item => ({
                        value: item,
                        label: item,
                      }))
                    : []
                }
              />
            </div>

            <div className={`${classes.field} ${classes.is6}`}>
              <label htmlFor="extra_info.last_remuneration">{intl.formatMessage({ id: 'LAST_REMUNERATION' })}</label>

              <input
                type="text"
                name="extra_info.last_remuneration"
                id="extra_info.last_remuneration"
                data-cy="extra_info.last_remuneration"
                value={formik.values?.extra_info?.last_remuneration || ''}
                onChange={formik.handleChange}
              />
            </div>
          </div>

          <div className={classes.cardFooter}>
            <Link
              to={action === 'edit' ? `/inspectors/active/${id}` : '/inspectors'}
              className={classes.button}
              disabled={busy}
            >
              {intl.formatMessage({ id: 'CANCEL' })}
            </Link>

            <ColorButton type="submit" color="green" isBusy={busy}>
              {intl.formatMessage({ id: 'SAVE' })}
            </ColorButton>
          </div>
        </form>
      </Card>
    </div>
  );
};

InspectorFormComponent.propTypes = {
  formik: PropTypes.object.isRequired,
  action: PropTypes.string.isRequired,
  busy: PropTypes.bool.isRequired,
  isSupportModeActive: PropTypes.func.isRequired,
  franchiseeCountry: PropTypes.string.isRequired,
  zipcodeMask: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  neighborhoods: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  intl: PropTypes.object.isRequired,
  cpfMask: PropTypes.array.isRequired,
  phoneMask: PropTypes.array.isRequired,
  cellphoneMask: PropTypes.array.isRequired,
  cellphoneMaskLength: PropTypes.number.isRequired,
};

InspectorFormComponent.defaultProps = {
  id: null,
};

export default InspectorFormComponent;

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import KeysAddressSection from 'src/orderDetails/KeysAddressSection';
import classes from 'src/orderDetails/PropertyAccessSection/style.module.scss';
import PropertyAccess from 'src/svg-icons/PropertyAccess';

const PropertyAccessSection = ({ data }) => (
  <Grid item xs={12}>
    <div className={classes.header}>
      <PropertyAccess className={classes.icon} />
      <FormattedMessage id="propertyAccess" />
    </div>
    <Grid container spacing={8} className={classes.information}>
      {!!data.type && (
        <Grid item xs={12} data-cy="order-details-access-info-type">
          <div className={classes.subHeader}>
            <FormattedMessage id="keysLocationRadio" />
          </div>
          <span>{data.type}</span>

          {(data.type === 'Outro' || data.type === 'Na imobiliária') && !!data.address && (
            <KeysAddressSection address={data.address} />
          )}
        </Grid>
      )}
      {!!data.keysDevolutionType && (
        <Grid item xs={12} data-cy="order-details-access-info-type">
          <div className={classes.subHeader}>
            <FormattedMessage id="keysDevolutionType" />
          </div>
          <span>
            <FormattedMessage id={data.keysDevolutionType} />
          </span>

          {(data.keysDevolutionType === 'Outro' || data.keysDevolutionType === 'Na imobiliária') && !!data.keysDevolutionAddress && (
            <KeysAddressSection address={data.keysDevolutionAddress} />
          )}
        </Grid>
      )}
      {!!data.keysLocation && (
        <Grid item xs={12} data-cy="order-details-keys-location">
          <div className={classes.subHeader}>
            <FormattedMessage id="keysLocation" />
          </div>
          <span>{data.keysLocation}</span>
        </Grid>
      )}
      <Grid item xs={12} data-cy="order-details-property-access-details">
        <div className={classes.subHeader}>
          <FormattedMessage id="accessDetails" />
        </div>
        {data.accessDetails}
      </Grid>
    </Grid>
  </Grid>
);

PropertyAccessSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PropertyAccessSection;

import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import PropTypes from 'prop-types';
import * as yup from 'yup';

import API from 'src/apiRequest';
import DeleteAttachmentComponent from 'src/components/DeleteAttachment/DeleteAttachmentComponent';

const validationSchema = yup.object().shape({
  reason: yup.string().required('Campo obrigatório'),
});

const DeleteAttachmentContainer = ({ orderId, remoteName, onDelete }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState(null);
  const intl = useIntl();

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const [folder, file] = remoteName.split('/'); // eslint-disable-line
      await API.delete(`orders/${orderId}/attachments/${file}`, {
        data: {
          reason: values.reason,
        },
      });
      setSubmitting(false);
      closeModal();
      onDelete(remoteName);
    } catch (err) {
      setSubmitting(false);
      console.log(err?.response || err);
      setError(
        err?.response?.status === 500 || !err?.response?.data?.message
          ? intl.formatMessage({ id: 'DELETE_ATTACHMENT_ERROR' })
          : err.response.data.message
      );
    }
  };

  return (
    <DeleteAttachmentComponent
      error={error}
      isModalVisible={isModalVisible}
      openModal={openModal}
      handleCloseModal={closeModal}
      handleSubmit={handleSubmit}
      validationSchema={validationSchema}
      intl={intl}
    />
  );
};

DeleteAttachmentContainer.propTypes = {
  orderId: PropTypes.string.isRequired,
  remoteName: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteAttachmentContainer;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let SectionIndependentes = props => (
  <SvgIcon {...props}>
    <path d="M20.049,6.609l-0.642-3.2H5.009v27.197h3.2V19.407h8.959l0.64,3.2h11.199V6.609H20.049z" />
  </SvgIcon>
);
SectionIndependentes = pure(SectionIndependentes);
SectionIndependentes.displayName = 'SectionIndependentes';
SectionIndependentes.muiName = 'SvgIcon';

export default SectionIndependentes;

import React from 'react';

import PropTypes from 'prop-types';

const TextWidget = ({
  id,
  type,
  value,
  options,
  schema,
  autofocus,
  disabled,
  readonly,
  required,
  onBlur,
  onChange,
  onFocus,
}) => {
  let inputType = type || schema.type;

  if (
    ![
      'text',
      'email',
      'tel',
      'password',
      'number',
      'search',
      'color',
      'date',
      'time',
      'datetime-local',
    ].includes(inputType)
  ) {
    inputType = 'text';
  }
  const handleChange = ({ target }) =>
    onChange(target.value === '' ? options.emptyValue : target.value);
  const handleBlur = ({ target }) => onBlur(id, target.value);
  const handleFocus = ({ target }) => onFocus(id, target.value);

  return (
    <input
      type={inputType}
      id={id}
      required={required}
      disabled={disabled || !!schema.default || id === 'root_apiKey'}
      readOnly={readonly}
      autoFocus={autofocus}
      value={value || value === 0 ? value : ''}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  );
};

TextWidget.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  required: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  readonly: PropTypes.bool.isRequired,
  autofocus: PropTypes.bool.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
};

TextWidget.defaultProps = {
  type: null,
  value: null,
};

export default TextWidget;

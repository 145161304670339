import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let MenuRemovidos = props => (
  <SvgIcon {...props}>
    <path
      fill="#FFFFFF"
      d="M17.008,4.842c-6.72,0-12.167,5.448-12.167,12.167
			c0,6.72,5.446,12.166,12.167,12.166c6.721,0,12.166-5.446,12.166-12.166C29.174,10.289,23.729,4.842,17.008,4.842z M22.484,19.441
			h-10.95c-1.008,0-1.825-0.817-1.825-1.824c0-1.007,0.817-1.825,1.825-1.825h10.95c1.007,0,1.824,0.817,1.824,1.825
			C24.308,18.623,23.49,19.441,22.484,19.441z"
    />
  </SvgIcon>
);
MenuRemovidos = pure(MenuRemovidos);
MenuRemovidos.displayName = 'MenuRemovidos';
MenuRemovidos.muiName = 'SvgIcon';

export default MenuRemovidos;

import React from 'react';
import {} from '@material-ui/icons';

import PropTypes from 'prop-types';

import Spinner from 'src/components/common/Spinner';
import CheckImage from 'src/images/icons/check-circle.svg';
import ExclamationImage from 'src/images/icons/exclamation-circle.svg';

const Icon = ({ type }) => {
  switch (type) {
    case 'loading':
      return <Spinner />;
    case 'error':
      return <img src={ExclamationImage} alt="Erro" />;
    default:
      return <img src={CheckImage} alt="Sucesso" />;
  }
};

Icon.propTypes = {
  type: PropTypes.string,
};

Icon.defaultProps = {
  type: null,
};

export default Icon;

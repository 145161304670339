import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { addDays, format, isEqual, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import SchedulePageComponent from 'src/components/SchedulePage/SchedulePageComponent';
import { useAsyncService } from 'src/contexts/AsyncServiceContext';
import { setOptions, setSelectableOptions } from 'src/store/ducks/filter';

import {
  setUnsaved,
  setFilteredInspectors,
  setFilteredPendingOrders,
  setInspectorsAvailabilities,
  addOrUpdateOrder,
  deleteOrder,
  prependPendingOrder,
  addOrderBeingEdited,
  deleteOrderBeingEdited,
  deleteFilteredPendingOrder,
  appendPendingOrders,
} from '../../store/ducks/schedule';
import { showToast } from '../../store/ducks/toasts';

let disabledTimeout;
let emitAssignOrderTimeOut;

const SchedulePageContainer = ({ sidebarCollapsed }) => {
  const [isBusy, setIsBusy] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isDisconnected, setIsDisconnected] = useState(false);

  const dispatch = useDispatch();
  const { query, date, selectedOrder, ordersThatAreBeingEdited, unsaved } = useSelector(({ filter, schedule }) => ({
    query: filter.query,
    date: schedule.date,
    selectedOrder: schedule.selectedOrder,
    state: schedule.state,
    ordersThatAreBeingEdited: schedule.ordersThatAreBeingEdited,
    unsaved: schedule.unsaved,
  }));

  const intl = useIntl();
  const { block: blockRouteChange } = useHistory();
  const { emit, on, off } = useAsyncService();
  const me = JSON.parse(window.sessionStorage.getItem('me'));

  const orderAlreadyBeingEditedByAnotherUser = useMemo(() => {
    const order = ordersThatAreBeingEdited.find(orderThatAreBeingEdited => {
      return (
        orderThatAreBeingEdited?.order?.order?.public_id === selectedOrder?.orderId &&
        orderThatAreBeingEdited?.sender?.id !== me?.uniqueId
      );
    });

    return {
      isEditing: !!order,
      who: order?.sender?.who,
    };
  }, [me, ordersThatAreBeingEdited, selectedOrder]);

  const handleSubmit = async () => {
    try {
      setIsBusy(true);
      await API.post('scheduler/schedules', {
        date: date.format('YYYY-MM-DD'),
        type: 'live',
      });
      dispatch(setUnsaved(false));
      dispatch(
        showToast({
          type: 'success',
          title: intl.formatMessage({ id: 'SUCCESS' }),
          text: intl.formatMessage({
            id: 'SAVE_CHANGES_AND_INSPECTOR_COMMUNICATED_SUCCESS',
          }),
        })
      );
    } catch (err) {
      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'UNABLE_SAVE_CHANGES_ERROR' }),
        })
      );
    } finally {
      setIsBusy(false);
    }
  };

  useEffect(() => {
    const blockRouteChangeOnUnsavedChanges = blockRouteChange(() => {
      if (unsaved) {
        const exit = window.confirm(intl.formatMessage({ id: 'THERE_ARE_CHANGES_NOT_SENT_TO_DEVICES_MESSAGE' }));

        if (exit) {
          const formattedMe = {
            id: me?.uniqueId,
            who: me?.name,
            when: format(new Date(), 'yyyy-MM-dd HH:mm'),
          };

          ordersThatAreBeingEdited
            .filter(orderThatAreBeingEdited => orderThatAreBeingEdited?.sender?.id === me?.uniqueId)
            .forEach(orderThatAreBeingEdited => {
              emit({
                data: {
                  channel: `panel_${me.panelId}`,
                  event: 'SCHEDULE.ORDER_EDITING_HAS_BEEN_FINISHED',
                  data: {
                    order: {
                      order: {
                        public_id: orderThatAreBeingEdited?.order?.order?.public_id,
                      },
                    },
                    sender: formattedMe,
                  },
                },
              });
            });
        }

        return exit;
      }

      return true;
    });

    return () => blockRouteChangeOnUnsavedChanges();
  }, [blockRouteChange, emit, intl, me, ordersThatAreBeingEdited, unsaved]);

  useEffect(() => {
    function unsavedChangesMessage() {
      return intl.formatMessage({ id: 'THERE_ARE_CHANGES_NOT_SENT_TO_DEVICES_MESSAGE' });
    }

    window.onbeforeunload = unsavedChangesMessage;
  }, [intl]);

  useEffect(() => {
    async function fetchAvailableFiltersAndSetScheduleOption() {
      try {
        const availableFilters = await API.get('/filters/schedule', {
          params: {
            rule: 'pending_inspections',
          },
        });

        dispatch(
          setSelectableOptions({
            orderTypes: availableFilters.data?.orderTypes || [],
            addresses: availableFilters.data?.addresses || [],
          })
        );

        dispatch(setOptions({ basic: ['text'], advanced: ['schedule'] }));
      } catch (err) {
        dispatch(
          showToast({
            type: 'error',
            title: intl.formatMessage({ id: 'ERROR' }),
            text: intl.formatMessage({ id: 'FILTER_LOAD_INFORMATION_ERROR' }),
            duration: 5000,
          })
        );
      }
    }

    fetchAvailableFiltersAndSetScheduleOption();
  }, [dispatch, on, intl]);

  useEffect(() => {
    dispatch(
      setFilteredInspectors({
        addresses: query.addresses,
        orderTypes: query.orderTypes,
      })
    );

    dispatch(setInspectorsAvailabilities());

    dispatch(
      setFilteredPendingOrders({
        addresses: query.addresses,
        orderTypes: query.orderTypes,
        text: query.text,
      })
    );
  }, [dispatch, query.addresses, query.orderTypes, query.text]);

  useEffect(() => {
    off('connect');
    on('connect', () => {
      setIsDisconnected(false);
    });
    off('disconnect');
    on('disconnect', () => {
      setIsDisconnected(true);
    });
    off('SCHEDULE.SEND_TO_TABLETS');
    on('SCHEDULE.SEND_TO_TABLETS', () => {
      setIsButtonDisabled(true);
      disabledTimeout = setTimeout(() => setIsButtonDisabled(false), 10000);
    });
    off('SCHEDULE.SEND_TO_TABLETS_FINISHED');
    on('SCHEDULE.SEND_TO_TABLETS_FINISHED', () => {
      setIsButtonDisabled(false);
      dispatch(setUnsaved(false));
      clearTimeout(disabledTimeout);
    });
    off('SCHEDULE.ORDER_IS_BEING_UPDATED');
    on('SCHEDULE.ORDER_IS_BEING_UPDATED', ({ order, sender }) => {
      dispatch(addOrderBeingEdited({ order, sender }));
      emitAssignOrderTimeOut = setTimeout(
        () => dispatch(deleteOrderBeingEdited({ public_id: order?.order?.public_id })),
        10000
      );
    });
    off('SCHEDULE.ORDER_ASSIGNED');
    on('SCHEDULE.ORDER_ASSIGNED', ({ order, schedule, sender }) => {
      const parsedCalendarDate = date.format('yyyy-MM-DD');
      const parsedOrderScheduleDate = format(addDays(new Date(schedule?.when?.date), 1), 'yyyy-MM-dd');

      const isSameDate = isEqual(parseISO(parsedCalendarDate), parseISO(parsedOrderScheduleDate));

      if (sender?.id !== me.uniqueId && isSameDate) {
        dispatch(addOrUpdateOrder({ order, schedule }));
        dispatch(setUnsaved(true));
        dispatch(deleteFilteredPendingOrder(order?.public_id));
      }
    });
    off('SCHEDULE.ORDER_EDITING_HAS_BEEN_FINISHED');
    on('SCHEDULE.ORDER_EDITING_HAS_BEEN_FINISHED', ({ order }) => {
      dispatch(deleteOrderBeingEdited({ public_id: order?.order?.public_id }));
      clearTimeout(emitAssignOrderTimeOut);
    });
    off('SCHEDULE.ORDER_UNASSIGNED');
    on('SCHEDULE.ORDER_UNASSIGNED', ({ order }) => {
      dispatch(setUnsaved(true));
      dispatch(deleteOrder(order?.id || order?.public_id));
      dispatch(
        prependPendingOrder({
          client: order?.client,
          code: order?.code,
          identifier: order?.identifier,
          id: order?.id || order?.public_id,
          type: order?.type,
          credits: order?.credits,
          address_id: order?.address_id,
          order_type_id: order?.order_type_id,
          building_type: order?.building_type,
          allowed_at_date: order?.allowed_at_date,
          accompanied_inspection: order?.accompanied_inspection,
          furnished: order?.furnished,
          modality: order?.modality,
          urgency: order?.urgency,
          created_at: order?.created_at,
          access_information: order?.access_information,
          search_tokens: order?.search_tokens,
          details: order?.details,
          status: order?.status,
          city: order?.city,
          neighborhood: order?.neighborhood,
          street: order?.street,
          number: order?.number,
          complement: order?.complement,
          state: order?.state,
          zipcode: order?.zipcode,
        })
      );
    });
    off('SCHEDULE.NEW_PENDING_ORDER');
    on('SCHEDULE.NEW_PENDING_ORDER', ({ order }) => {
      dispatch(appendPendingOrders([order]));
      dispatch(
        showToast({
          type: 'success',
          title: intl.formatMessage({ id: 'UPDATE' }),
          text: intl.formatMessage({ id: 'NEW_PENDING_ORDER_ADDED_MESSAGE' }),
          duration: 3000,
        })
      );
    });

    return () => {
      if (emitAssignOrderTimeOut) clearTimeout(emitAssignOrderTimeOut);
      if (disabledTimeout) clearTimeout(disabledTimeout);
    };
  }, [dispatch, on, off, date, me.uniqueId, intl]);

  return (
    <SchedulePageComponent
      isBusy={isBusy}
      isButtonDisabled={isButtonDisabled}
      isDisconnected={isDisconnected}
      selectedOrder={selectedOrder}
      handleSubmit={handleSubmit}
      intl={intl}
      orderAlreadyBeingEditedByAnotherUser={orderAlreadyBeingEditedByAnotherUser}
      isUnsaved={unsaved}
      sidebarCollapsed={sidebarCollapsed}
    />
  );
};
SchedulePageContainer.propTypes = {
  sidebarCollapsed: PropTypes.bool.isRequired,
};
export default SchedulePageContainer;

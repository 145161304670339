import React from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import moment from 'moment';
import PropTypes from 'prop-types';

import classes from 'src/components/ClientsPage/ClientDetails/style.module.scss';
import Card from 'src/components/common/Card';
import FeatureSwitch from 'src/components/common/FeatureSwitch';
import addPackageImage from 'src/components/common/icons/add-package.svg';
import addUserImage from 'src/components/common/icons/add-user.svg';
import arrowDownImage from 'src/components/common/icons/arrow-down-circle.svg';
import arrowUpImage from 'src/components/common/icons/arrow-up-circle.svg';
import detailsIcon from 'src/components/common/icons/client-details.svg';
import disableImage from 'src/components/common/icons/disable.svg';
import editImage from 'src/components/common/icons/edit.svg';
import enableImage from 'src/components/common/icons/enable.svg';
import recordsIcon from 'src/components/common/icons/records.svg';
import userCircleImage from 'src/components/common/icons/user-circle.svg';
import userPermissionImage from 'src/components/common/icons/user-permission.svg';
import Spinner from 'src/components/common/Spinner';
import AddressIcon from 'src/svg-icons/address';

const ClientDetailsComponent = ({ client, disableClient, enableClient, id, intl, numberFormat, status }) => {
  const me = JSON.parse(sessionStorage.getItem('me'));
  const disableAddPackage = me.type === 'OWNED';
  return client ? (
    <div className={classes.clientDetails}>
      <Card className="fade-in">
        <div className={classes.toolbar}>
          <div className={classes.actions}>
            <Link to={`/clients/${status}/${id}/edit`} data-tip={intl.formatMessage({ id: 'EDIT' })}>
              <img src={editImage} alt={intl.formatMessage({ id: 'EDIT' })} />
            </Link>
            <FeatureSwitch feature="USER">
              <Link to={`/clients/${status}/${id}/users/new`} data-tip={intl.formatMessage({ id: 'ADD_USER' })}>
                <img src={addUserImage} alt={intl.formatMessage({ id: 'ADD_USER' })} />
              </Link>
            </FeatureSwitch>
            {disableAddPackage ? (
              <Link className={classes.disabled} data-tip={intl.formatMessage({ id: 'ADD_PACKAGE_DISABLED' })}>
                <img src={addPackageImage} alt={intl.formatMessage({ id: 'ADD_PACKAGE' })} />
              </Link>
            ) : (
              <Link to={`/clients/${status}/${id}/packages`} data-tip={intl.formatMessage({ id: 'ADD_PACKAGE' })}>
                <img src={addPackageImage} alt={intl.formatMessage({ id: 'ADD_PACKAGE' })} />
              </Link>
            )}
            {/* <Link to={`/clients/${status}/${id}/packages`} data-tip={intl.formatMessage({ id: 'ADD_PACKAGE' })}>
              <img src={addPackageImage} alt={intl.formatMessage({ id: 'ADD_PACKAGE' })} />
            </Link> */}
            {status === 'enabled' && client?.details?.status === 'ENABLED' && (
              <button type="button" data-tip={intl.formatMessage({ id: 'DEACTIVATE' })} onClick={disableClient}>
                <img src={disableImage} alt={intl.formatMessage({ id: 'DEACTIVATE' })} />
              </button>
            )}
            {status === 'disabled' && client?.details?.status === 'DISABLED' && (
              <button type="button" data-tip={intl.formatMessage({ id: 'ACTIVATE' })} onClick={enableClient}>
                <img src={enableImage} alt={intl.formatMessage({ id: 'ACTIVATE' })} />
              </button>
            )}
          </div>
        </div>
      </Card>
      <Card className="fade-in">
        <h2 className={classes.cardHead}>
          <div className={classes.icon}>
            <img src={detailsIcon} alt={intl.formatMessage({ id: 'DETAILS' })} />
          </div>
          {intl.formatMessage({ id: 'DETAILS' })}
        </h2>
        <div className={classes.address}>
          <div className={classes.icon}>
            <AddressIcon />
          </div>
          <div className={classes.text}>
            {!client.details.address.street &&
              !client.details.address.number &&
              !client.details.address.complement &&
              !client.details.address.neighborhood &&
              !client.details.address.city &&
              !client.details.address.state &&
              intl.formatMessage({ id: 'NO_REGISTERED_ADDRESS_MESSAGE' })}
            {client.details.address.street || null}
            {client.details.address.number && `, ${client.details.address.number}`}
            {client.details.address.complement && `, ${client.details.address.complement}`}
            <br />
            {client.details.address.neighborhood || null}
            {client.details.address.city && `, ${client.details.address.city}`}
            {client.details.address.state && ` - ${client.details.address.state}`}
          </div>
        </div>
        <div className={classes.cardFooter}>
          <Link className={classes.outline} to={`/clients/${status}/${id}/details`}>
            {intl.formatMessage({ id: 'SEE_MORE' })}
          </Link>
        </div>
      </Card>
      <FeatureSwitch feature="USER">
        <Card className="fade-in">
          <h2 className={classes.cardHead}>
            <div className={classes.icon}>
              <img src={userPermissionImage} alt={intl.formatMessage({ id: 'USERS' })} />
            </div>
            {intl.formatMessage({ id: 'USERS' })}
          </h2>
          <div className={classes.list}>
            {client.users.map(item => (
              <div className={classes.item} key={item.id}>
                <div className={classes.icon}>
                  <img src={userCircleImage} alt={intl.formatMessage({ id: 'USER' })} />
                </div>
                <div className={classes.text}>
                  <div className={classes.highlight}>{item.username}</div>
                  {item.email ? (
                    <>
                      <div className={classes.separator}>•</div>
                      <div className={classes.normal} title={item.email}>
                        {item.email}
                      </div>
                    </>
                  ) : null}
                </div>
                <div className={classes.date}>{`${moment(item.created_at).format('DD/MM/YYYY')}`}</div>
              </div>
            ))}
            {client.users.length === 0 && (
              <p className={classes.empty}>{intl.formatMessage({ id: 'NO_USERS_CREATED_MESSAGE' })}</p>
            )}
          </div>
          <div className={classes.cardFooter}>
            <Link className={classes.outline} to={`/clients/${status}/${id}/users`}>
              {intl.formatMessage({ id: 'SEE_MORE' })}
            </Link>
          </div>
        </Card>
      </FeatureSwitch>
      <Card className="fade-in">
        <h2 className={classes.cardHead}>
          <div className={classes.icon}>
            <img src={recordsIcon} alt={intl.formatMessage({ id: 'EXTRACT' })} />
          </div>
          {intl.formatMessage({ id: 'EXTRACT' })}
        </h2>
        <div className={classes.list}>
          {client.summary.map(item => (
            <div className={classes.item} key={item.id}>
              <div className={classes.icon}>
                {item.credits >= 0 ? (
                  <img src={arrowUpImage} alt={intl.formatMessage({ id: 'POSITIVE' })} />
                ) : (
                  <img src={arrowDownImage} alt={intl.formatMessage({ id: 'NEGATIVE' })} />
                )}
              </div>
              <div className={classes.text}>
                <div className={classes.highlight}>
                  {`${numberFormat.format((item.credits / 100).toFixed(1))}`}
                </div>
                <div className={classes.separator}>•</div>
                <div className={classes.normal}>{item.identifier}</div>
              </div>
              <div className={classes.date}>
                {`${moment(item.created_at).format('DD/MM/YYYY')} • ${moment(item.created_at).format('HH[h]mm')}`}
              </div>
            </div>
          ))}
          {client.summary.length === 0 && (
            <p className={classes.empty}>{intl.formatMessage({ id: 'NO_ITEMS_SO_FAR_MESSAGE' })}</p>
          )}
        </div>
        <div className={classes.cardFooter}>
          <Link className={classes.outline} to={`/clients/${status}/${id}/summary`}>
            {intl.formatMessage({ id: 'SEE_MORE' })}
          </Link>
        </div>
      </Card>
      <ReactTooltip effect="solid" />
    </div>
  ) : (
    <div className={classes.spinner}>
      <Spinner />
    </div>
  );
};

ClientDetailsComponent.propTypes = {
  client: PropTypes.object,
  disableClient: PropTypes.func.isRequired,
  enableClient: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  numberFormat: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
};

ClientDetailsComponent.defaultProps = {
  client: null,
};

export default ClientDetailsComponent;

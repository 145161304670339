import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import AccessDeniedComponent from 'src/components/AccessDenied/AccessDeniedComponent';

const AccessDeniedContainer = () => {
  const [message, setMessage] = useState('');
  const intl = useIntl();

  useEffect(() => {
    const me = JSON.parse(window.sessionStorage.getItem('me'));

    if (me.role === 'ROLE_INSPECTOR') {
      setMessage(intl.formatMessage({ id: 'PANEL_ACCESS_BLOCK_MESSAGE' }));
    }

    if (me.status === 'disabled') {
      setMessage(intl.formatMessage({ id: 'ACCOUNT_BLOCKED_MESSAGE' }));
    }
  }, [intl]);

  return <AccessDeniedComponent message={message} />;
};

export default AccessDeniedContainer;

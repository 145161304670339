import React from 'react';

import PropTypes from 'prop-types';

import classes from 'src/components/AccessDenied/style.module.scss';

const AccessDeniedComponent = ({ message }) => (
  <div className={classes.accessDenied}>
    <div className={classes.logo}>
      <img src="/assets/img/logo-rv.png" alt="Logo Rede Vistorias" />
    </div>
    <p className={classes.message}>{message}</p>
  </div>
);

AccessDeniedComponent.propTypes = {
  message: PropTypes.string.isRequired,
};

export default AccessDeniedComponent;

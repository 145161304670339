import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const StepPartiesInvolved = ({ on, ...props }) => {
  if (on) {
    return (
      <SvgIcon {...props}>
        <g>
          <path
            fill="#8980B2"
            d="M43,0C19.252,0,0,19.252,0,43s19.252,43,43,43s43-19.252,43-43S66.748,0,43,0z M48.059,61.314V67h-9.657
              v-5.755c-5.618-1.47-9.108-5.356-9.39-10.524l-0.058-1.055h7.583l0.084,0.907c0.275,2.958,2.499,4.458,6.609,4.458
              c5.467,0,5.892-2.734,5.892-3.572c0-1.146,0-3.53-6.89-5.186c-5.479-1.318-12.763-3.947-12.763-11.528
              c0-4.771,3.4-8.551,8.933-10.013V19h9.657v5.834c4.874,1.49,7.988,5.434,8.191,10.458l0.042,1.041h-7.635l-0.046-0.952
              c-0.147-3.054-1.808-4.416-5.382-4.416c-3.579,0-5.891,1.463-5.891,3.728c0,1.321,0.534,2.87,6.908,4.527
              c6.302,1.642,12.743,4.376,12.743,12.211C56.99,56.414,53.748,59.982,48.059,61.314z"
          />
          <path
            fill="#8980B2"
            d="M43.743,41.156c-5.044-1.312-8.404-2.913-8.404-6.463c0-3.479,3.097-5.728,7.891-5.728
              c5.213,0,6.886,2.732,7.286,5.367h3.644c-0.542-3.893-3.223-6.759-7.338-7.765l-0.763-0.186V21h-5.657v5.327l-0.788,0.17
              c-3.938,0.854-8.145,3.469-8.145,8.248c0,4.76,3.463,7.715,11.23,9.583c5.825,1.4,8.422,3.599,8.422,7.131
              c0,0.931-0.381,5.572-7.892,5.572c-6.134,0-7.962-3.039-8.462-5.365H31.12c0.888,5.575,6.162,7.312,8.491,7.812l0.791,0.169V65
              h5.657v-5.324l0.813-0.154c3.032-0.575,8.118-2.39,8.118-8.09C54.99,44.977,49.585,42.678,43.743,41.156z"
          />
        </g>
      </SvgIcon>
    );
  }
  return (
    <SvgIcon {...props}>
      <g>
        <path
          fill="#D7D7D7"
          d="M43,3c22.057,0,40,17.944,40,40c0,22.057-17.943,40-40,40C20.944,83,3,65.057,3,43C3,20.944,20.944,3,43,3
            M43,0C19.252,0,0,19.252,0,43s19.252,43,43,43s43-19.252,43-43S66.748,0,43,0L43,0z"
        />
      </g>
      <g>
        <path
          fill="#D7D7D7"
          d="M48.059,67h-9.657v-5.755c-5.618-1.47-9.108-5.356-9.39-10.524l-0.058-1.055h7.583l0.084,0.907
            c0.275,2.958,2.499,4.458,6.609,4.458c5.467,0,5.892-2.734,5.892-3.572c0-1.146,0-3.53-6.89-5.186
            c-5.479-1.318-12.763-3.947-12.763-11.528c0-4.771,3.4-8.551,8.933-10.013V19h9.657v5.834c4.874,1.49,7.988,5.434,8.191,10.458
            l0.042,1.041h-7.635l-0.046-0.952c-0.147-3.054-1.808-4.416-5.382-4.416c-3.579,0-5.891,1.463-5.891,3.728
            c0,1.321,0.534,2.87,6.908,4.527c6.302,1.642,12.743,4.376,12.743,12.211c0,4.982-3.242,8.551-8.932,9.883V67z M40.401,65h5.657
            v-5.324l0.813-0.154c3.032-0.575,8.118-2.39,8.118-8.09c0-6.455-5.405-8.753-11.247-10.275c-5.044-1.312-8.404-2.913-8.404-6.463
            c0-3.479,3.097-5.728,7.891-5.728c5.213,0,6.886,2.732,7.286,5.367h3.644c-0.542-3.893-3.223-6.759-7.338-7.765l-0.763-0.186V21
            h-5.657v5.327l-0.788,0.17c-3.938,0.854-8.145,3.469-8.145,8.248c0,4.76,3.463,7.715,11.23,9.583
            c5.825,1.4,8.422,3.599,8.422,7.131c0,0.931-0.381,5.572-7.892,5.572c-6.134,0-7.962-3.039-8.462-5.365H31.12
            c0.888,5.575,6.162,7.312,8.491,7.812l0.791,0.169V65z"
        />
      </g>
    </SvgIcon>
  );
};

StepPartiesInvolved.displayName = 'StepPartiesInvolved';
StepPartiesInvolved.muiName = 'SvgIcon';

export default StepPartiesInvolved;

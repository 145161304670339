import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = {
  input: {
    minHeight: '37px',
    height: '37px',
  },
  isDisabled: {
    backgroundColor: '#f4f3f4',
    borderColor: '#ced4da',
    cursor: 'not-allowed',
  },
  label: {
    fontFamily: 'Open Sans',
    marginTop: '0.3vh',
    fontSize: 'calc(6px + 0.3vw)',
  },
};

class AutoCompleteOrCreate extends Component {
  constructor(props) {
    super(props);
    const { formData } = props;
    this.state = {
      defaultValue: this.getDefaultValue(formData),
      options: this.getOptions(),
      value: this.getDefaultValue(formData),
    };
  }

  onChange = option => {
    const { onChange } = this.props;
    const value = option ? option.value : undefined;
    this.setState({ value: option });
    onChange(value);
  };

  getOptions = () => {
    const { intl, schema, uiSchema } = this.props;
    const { 'ui:options': uiOptions } = uiSchema;
    const labels = uiOptions?.enumNames || null;
    const isTranslatable = uiOptions?.translatable || null;
    const values = uiOptions?.enum || uiOptions?.values || null;
    const items = [];

    if (schema.enum) {
      schema.enum.forEach((item, index) => {
        let label = schema.enumNames ? schema.enumNames[index] : item;
        if (isTranslatable) label = intl.formatMessage({ id: label });

        items.push({
          value: item,
          label,
        });
      });

      return items;
    }

    if (values) {
      values.forEach((item, index) => {
        let label = labels ? labels[index] : item;
        if (isTranslatable) label = intl.formatMessage({ id: label });
        items.push({ value: item, label });
      });

      return items;
    }

    return [];
  };

  getDefaultValue(value) {
    if (!value) return null;

    const { intl, schema, uiSchema } = this.props;
    const { translatable: isTranslatable, values: optionsValues } = uiSchema['ui:options'];
    const { enum: enumValues, enumNames: enumLabels } = schema;

    const values = enumValues || optionsValues;
    const labels = enumLabels || values;
    const index = values ? values.indexOf(value) : null;

    if (values && values.includes(value)) {
      let label = labels[index];

      if (isTranslatable) {
        label = intl.formatMessage({ id: label });
      }
      return { value, label };
    }

    return null;
  }

  selectElement = (newValue, creatable) => {
    const { options, value } = this.state;
    const { disabled, name, uiSchema, intl } = this.props;

    let controlStyles = styles.input;
    if (disabled) controlStyles = { ...styles.input, ...styles.isDisabled };

    if (!creatable) {
      return (
        <Select
          options={options}
          formatCreateLabel={label => label}
          id={name}
          value={value}
          onChange={this.onChange}
          isDisabled={disabled}
          className="custom"
          styles={{
            control: base => ({
              ...base,
              ...controlStyles,
            }),
          }}
          components={{
            Input: inputProps => <components.Input {...inputProps} data-cy={`${String(name).toLowerCase()}`} />,
          }}
          isClearable={uiSchema?.['ui:options']?.clearable}
          placeholder={null}
          noOptionsMessage={() => intl.formatMessage({ id: 'NO_OPTIONS_AVAILABLE_MESSAGE' })}
        />
      );
    }

    return (
      <CreatableSelect
        options={options}
        formatCreateLabel={label => label}
        id={name}
        value={value}
        onChange={this.onChange}
        isDisabled={disabled}
        styles={{ control: base => ({ ...base, backgroundColor: 'white' }) }}
        components={{
          Input: inputProps => <components.Input {...inputProps} data-cy={`${String(name).toLowerCase()}`} />,
        }}
        isClearable={uiSchema?.['ui:options']?.clearable}
        placeholder={null}
        noOptionsMessage={() => intl.formatMessage({ id: 'NO_OPTIONS_AVAILABLE_MESSAGE' })}
      />
    );
  };

  render() {
    const { options, value } = this.state;
    const { formData, name, required, uiSchema } = this.props;

    if ((!value && formData) || (formData && value && formData !== value.value)) {
      const label = options.filter(option => option.value === formData);

      if (uiSchema['ui:options'].creatable || label.length > 0) {
        this.setState({
          value: {
            label: label.length > 0 ? label[0].label : formData,
            value: formData,
          },
        });
      }
    }

    return (
      <div>
        <label style={styles.label} htmlFor={name}>
          <FormattedMessage id={name} />
          {required ? '*' : null}
        </label>
        {this.selectElement(formData, uiSchema['ui:options'] ? uiSchema['ui:options'].creatable : false)}
      </div>
    );
  }
}

AutoCompleteOrCreate.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.any,
  intl: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object.isRequired,
};

AutoCompleteOrCreate.defaultProps = {
  disabled: false,
  formData: null,
  required: false,
};

export default withStyles(styles)(injectIntl(AutoCompleteOrCreate));

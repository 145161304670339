import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/PotentialScheduleSection/style.module.scss';
import PotentialSchedule from 'src/svg-icons/PotentialSchedule';

const PotentialScheduleSection = ({ data }) => (
  <Grid item xs={12}>
    <div className={classes.header}>
      <PotentialSchedule className={classes.icon} />
      <FormattedMessage id="potentialSchedule" />
    </div>
    <Grid container spacing={8} className={classes.information}>
      <Grid item xs={6} data-cy="order-details-potential-schedule-date1">
        <div className={classes.subHeader}>
          <FormattedMessage id="date1" />
        </div>
        {data.date1}
      </Grid>
      <Grid item xs={6} data-cy="order-details-potential-schedule-time1">
        <div className={classes.subHeader}>
          <FormattedMessage id="time1" />
        </div>
        {data.time1}
      </Grid>
      <Grid item xs={6} data-cy="order-details-potential-schedule-date2">
        <div className={classes.subHeader}>
          <FormattedMessage id="date2" />
        </div>
        {data.date2}
      </Grid>
      <Grid item xs={6} data-cy="order-details-potential-schedule-time2">
        <div className={classes.subHeader}>
          <FormattedMessage id="time2" />
        </div>
        {data.time2}
      </Grid>
    </Grid>
  </Grid>
);

PotentialScheduleSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PotentialScheduleSection;

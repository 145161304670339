import React from 'react';
import Dropzone from 'react-dropzone';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import classes from 'src/inspectionAttach/InspectionFile.module.css';

class InspectionFile extends React.Component {
  constructor(props) {
    super(props);
    this.onDropAccepted = this.onDropAccepted.bind(this);
  }

  /*
    Function call when the file dropped is accepted
  */
  onDropAccepted(files) {
    const { orderAttachFile } = this.props;
    const file = files[0];
    const fileExtension = file.name.split('.').pop();

    API.get('/upload')
      .then(res => {
        fetch(res.data.url, {
          method: 'PUT',
          contentType: 'binary/octet-stream',
          processData: false,
          body: file,
        })
          .then(response => {
            if (response.ok) {
              orderAttachFile(fileExtension, file.name, res.data.remote_name);
            }
          })
          .catch(ex => {
            console.log('parsing failed', ex);
          });
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const { previousType } = this.props;

    return (
      <Dropzone
        className={classes.Dropzone}
        accept="application/pdf"
        multiple={false}
        onDropAccepted={this.onDropAccepted}
        data-cy="inspection-attach-input"
      >
        <Grid container>
          <Grid item xs={12} className="InspectionFile-padding">
            <Grid container spacing={16} alignItems="center">
              <Grid item xs={12}>
                <span className={classes.title}>
                  <FormattedMessage id="inspectionAttach.file" values={{ type: previousType.toLowerCase() }} />
                </span>
              </Grid>
              <Grid item xs={6} className={classes.FileButton}>
                <Button variant="outlined" size="small" disableRipple disableFocusRipple>
                  <FormattedMessage id="select" />
                </Button>
              </Grid>
              <Grid item xs={6} className={classes.UploadInstruction}>
                <span className="InspectionFile-text-light">
                  <FormattedMessage id="inspectionAttach.drop" />
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dropzone>
    );
  }
}

InspectionFile.propTypes = {
  /**
   * Function to pass the selected inspection
   */
  orderAttachFile: PropTypes.func.isRequired,
  previousType: PropTypes.string.isRequired,
};

export default InspectionFile;

import React from 'react';
import { injectIntl } from 'react-intl';

import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/FooterMessage.module.css';

const FooterMessage = ({ newStatus, intl, onChange, value }) => (
  <div>
    <TextField
      fullWidth
      type="text"
      multiline
      placeholder={
        newStatus === 'REJECT'
          ? intl.formatMessage({ id: 'menssageForReject' })
          : intl.formatMessage({ id: 'menssageForAccept' })
      }
      rows={5}
      className={classes.multiline}
      value={value}
      onChange={event => onChange(event.target.value)}
      InputProps={{
        disableUnderline: true,
        classes: {
          input: classes.bootstrapInput,
        },
      }}
    />
  </div>
);

FooterMessage.propTypes = {
  newStatus: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default injectIntl(FooterMessage);

import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import ApiContext from 'src/contexts/ApiContext';

const styles = {
  acceptButton: {
    backgroundColor: '#909B11',
    color: '#ffffff',
    fontFamily: 'Open Sans',
    fontSize: 'calc(6px + 0.4vw)',
    textTransform: 'none',
    marginTop: '1vh',
    marginBottom: '1vh',
    width: '9vw',
    '&:hover': {
      backgroundColor: '#202F4A',
      borderColor: '#0062cc',
    },
    '&:disabled': {
      opacity: '0.4',
    },
  },
  cancelButton: {
    backgroundColor: '#A50C00',
    color: '#ffffff',
    fontFamily: 'Open Sans',
    fontSize: 'calc(6px + 0.4vw)',
    textTransform: 'none',
    marginRight: '1vw',
    marginTop: '1vh',
    marginBottom: '1vh',
    width: '9vw',
    '&:hover': {
      backgroundColor: '#202F4A',
      borderColor: '#0062cc',
    },
    '&:disabled': {
      opacity: '0.4',
    },
  },
  loading: {
    position: 'absolute',
    width: '20px',
    height: '20px',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -10,
  },
};

function AttachDetailsFooter(props) {
  return (
    <ApiContext.Consumer>
      {apiContext => (
        <div>
          <span style={{ position: 'relative' }}>
            <Button
              variant="outlined"
              classes={{ root: props.classes.cancelButton }}
              size="small"
              onClick={props.handleCancel}
              disabled={apiContext.status === 'WORKING'}
            >
              <FormattedMessage id="cancel" />
            </Button>
          </span>
          <span style={{ position: 'relative' }}>
            <Button
              variant="outlined"
              classes={{ root: props.classes.acceptButton }}
              size="small"
              onClick={props.handleConfirm}
              disabled={apiContext.status === 'WORKING'}
            >
              <FormattedMessage id="confirm" />
            </Button>
            {apiContext.status === 'WORKING' && (
              <CircularProgress size={20} classes={{ root: props.classes.loading }} />
            )}
          </span>
        </div>
      )}
    </ApiContext.Consumer>
  );
}

AttachDetailsFooter.propTypes = {
  /**
   * Function to confirm the selection
   */
  handleConfirm: PropTypes.func.isRequired,
  /**
   * Function to cancel the selection
   */
  handleCancel: PropTypes.func.isRequired,
};

export default withStyles(styles)(AttachDetailsFooter);

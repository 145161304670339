import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const PotentialSchedule = props => (
  <SvgIcon viewBox="0 0 34.016 34.016" {...props}>
    <g>
      <path
        fill="#393939"
        d="M17.008,2.008c-8.271,0-15,6.729-15,15c0,8.271,6.729,15,15,15c8.271,0,15-6.729,15-15
        C32.008,8.737,25.279,2.008,17.008,2.008z M17.008,30.008c-7.168,0-13-5.832-13-13s5.832-13,13-13s13,5.832,13,13
        S24.176,30.008,17.008,30.008z"
      />
    </g>
    <g>
      <path
        fill="#393939"
        d="M22.008,10.941h-1V8.942h-2v1.999h-4V8.942h-2v1.999h-1
        c-0.552,0-2,1.447-2,2v9c0,0.552,1.448,2,2,2h10c0.553,0,2-1.448,2-2v-9C24.008,12.388,22.561,10.941,22.008,10.941z
         M12.008,21.941v-7h10v7H12.008z"
      />
      <rect x="13.008" y="15.941" fill="#393939" width="2" height="2.001" />
      <rect x="16.008" y="15.941" fill="#393939" width="2" height="2.001" />
      <rect x="19.008" y="15.941" fill="#393939" width="2" height="2.001" />
      <rect x="13.008" y="18.942" fill="#393939" width="2" height="1.999" />
      <rect x="16.008" y="18.942" fill="#393939" width="2" height="1.999" />
    </g>
  </SvgIcon>
);

PotentialSchedule.displayName = 'PotentialSchedule';
PotentialSchedule.muiName = 'SvgIcon';

export default PotentialSchedule;

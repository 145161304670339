import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import ClientDetailsComponent from 'src/components/ClientsPage/ClientDetails/ClientDetailsComponent';
import { reloadCards } from 'src/store/ducks/cards';
import { showToast } from 'src/store/ducks/toasts';

// eslint-disable-next-line no-shadow
const ClientDetailsContainer = ({ reloadCards, showToast }) => {
  const numberFormat = new Intl.NumberFormat('pt-BR', {
    maximumSignificantDigits: 5,
  });
  const history = useHistory();
  const { id, status } = useParams();
  const [client, setClient] = useState(null);
  const intl = useIntl();

  useEffect(() => {
    setClient(null);

    async function fetchClient() {
      const { data } = await API.get(`/view/clients/${id}`);
      const clientStatus = data?.details?.status;

      setClient(data);

      if (status === 'enabled' && clientStatus === 'DISABLED') {
        history.push(`/clients/disabled/${id}`);
      } else if (status === 'disabled' && clientStatus === 'ENABLED') {
        history.push(`/clients/enabled/${id}`);
      }
    }

    try {
      fetchClient();
    } catch (err) {
      console.log(err);
    }
  }, [history, id, status]);

  const disableClient = async () => {
    try {
      await API.put(`/clients/${id}`, { is_disabled: true });
      history.push(`/clients/${status}`);

      reloadCards();
    } catch (err) {
      console.log(err);

      showToast({
        type: 'error',
        title: intl.formatMessage({ id: 'ERROR' }),
        text: intl.formatMessage({ id: 'CUSTOMER_DEACTIVATION_ERROR' }),
        duration: 5000,
      });
    }
  };

  const enableClient = async () => {
    try {
      await API.put(`/clients/${id}`, { is_disabled: false });
      history.push(`/clients/${status}`);

      reloadCards();
    } catch (err) {
      console.log(err);

      showToast({
        type: 'error',
        title: intl.formatMessage({ id: 'ERROR' }),
        text: intl.formatMessage({ id: 'CUSTOMER_ACTIVATION_ERROR' }),
        duration: 5000,
      });
    }
  };

  return (
    <ClientDetailsComponent
      client={client}
      disableClient={disableClient}
      enableClient={enableClient}
      id={id}
      intl={intl}
      numberFormat={numberFormat}
      status={status}
    />
  );
};

ClientDetailsContainer.propTypes = {
  reloadCards: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

export default connect(null, { reloadCards, showToast })(ClientDetailsContainer);

import React, { useRef } from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { AddCircle } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import API from 'src/apiRequest';
import { Spinner } from 'src/components/common';
import Modal from 'src/components/common/Modal';
import classes from 'src/orderForm/fields/AttachmentFiles.module.scss';
import { changeApiStatus } from 'src/store/ducks/clientOrderForm';
import { showToast } from 'src/store/ducks/toasts';
import AttachmentCircle from 'src/svg-icons/attachments';

const AttachmentFiles = ({ formData, onChange, name, schema }) => {
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
  const [originalNameToDelete, setOriginalNameToDelete] = useState('');

  const [files, setFiles] = useState(() => {
    if (Array.isArray(formData) && formData?.length) return formData;

    return [];
  });

  const fileInputRef = useRef(null);

  const dispatch = useDispatch();
  const intl = useIntl();

  const handleUploadAttachment = async event => {
    setIsUploadingFile(true);
    dispatch(changeApiStatus('WORKING'));

    try {
      const file = event.target.files[0];

      fileInputRef.current.value = null;

      if (!file) throw new Error('Error to get file from input');

      if (file.type !== 'application/pdf') {
        dispatch(
          showToast({
            type: 'error',
            title: intl.formatMessage({ id: 'ERROR' }),
            text: intl.formatMessage({ id: 'ATTACH_ONLY_PDF_FILES' }),
            duration: 5000,
          })
        );

        throw new Error('Invalid file type');
      }

      const uploadResponse = await API.get('/upload');
      const uploadUrlResponse = await fetch(uploadResponse?.data?.url, {
        method: 'PUT',
        contentType: 'binary/octet-stream',
        processData: false,
        body: file,
      });

      if (!uploadUrlResponse?.ok) throw new Error('Error to upload file');

      const originalName = file?.name.split('.pdf')[0];
      const fileNameUniqueId = uuidv4();

      const newFile = {
        original_name: `${originalName}-${fileNameUniqueId}.pdf`,
        remote_name: uploadResponse?.data?.remote_name,
        extension: '.pdf',
      };

      setFiles(state => {
        const withNewFile = [...state, newFile];

        onChange(withNewFile);

        return withNewFile;
      });

      setIsUploadingFile(false);
      dispatch(changeApiStatus('IDLE'));
    } catch {
      setIsUploadingFile(false);
      dispatch(changeApiStatus('IDLE'));
    }
  };

  const handleDeleteAttachment = () => {
    if (!originalNameToDelete) return;

    setFiles(state => {
      const withoutFileByOriginalName = state.filter(file => file?.original_name !== originalNameToDelete);

      onChange(withoutFileByOriginalName);

      return withoutFileByOriginalName;
    });
  };

  const handleDeleteConfirmationModalOpen = originalName => {
    setIsDeleteConfirmationModalOpen(true);
    setOriginalNameToDelete(originalName);
  };

  const handleDeleteConfirmationModalClose = () => {
    setIsDeleteConfirmationModalOpen(false);
    setOriginalNameToDelete('');
  };

  const handleDeleteConfirmationModalConfirmation = () => {
    try {
      handleDeleteAttachment();
      handleDeleteConfirmationModalClose();
    } catch {
      handleDeleteConfirmationModalClose();
    }
  };

  return (
    <>
      {isDeleteConfirmationModalOpen && (
        <Modal onClose={handleDeleteConfirmationModalClose}>
          <div className={classes.deleteAttachmentModal}>
            <header>
              <AttachmentCircle viewBox="0 0 34.016 34.016" />

              <p>
                <FormattedMessage id="WANT_TO_DELETE_ATTACHED_FILE" />
              </p>
            </header>

            <hr />

            <footer>
              <Button
                className={`${classes.button} ${classes.close}`}
                data-cy="close-delete-attachment-modal"
                variant="outlined"
                size="small"
                onClick={handleDeleteConfirmationModalClose}
              >
                <FormattedMessage id="CANCEL" />
              </Button>

              <Button
                className={`${classes.button} ${classes.confirm}`}
                data-cy="confirm-delete-attachment-modal"
                variant="outlined"
                size="small"
                onClick={handleDeleteConfirmationModalConfirmation}
              >
                <FormattedMessage id="CONFIRM" />
              </Button>
            </footer>
          </div>
        </Modal>
      )}

      <Grid container className={classes.attachmentFiles}>
        <AttachmentCircle viewBox="0 0 34.016 34.016" />

        <Grid item xs={11} className={classes.documentsSpacing}>
          <p className={classes.title}>
            <FormattedMessage id={name || schema?.title} />
          </p>

          <p>
            <FormattedMessage id="ATTACH_ADDITIONAL_FILES_FROM_INSPECTION" />
          </p>

          {!!files?.length && (
            <ul className={classes.fileList}>
              {files.map(file => (
                <li key={file?.original_name}>
                  <span>{file?.original_name}</span>

                  <DeleteIcon
                    className={classes.deleteButton}
                    onClick={() => handleDeleteConfirmationModalOpen(file?.original_name)}
                  />
                </li>
              ))}
            </ul>
          )}

          {isUploadingFile && (
            <div className={classes.isUploadingFile}>
              <Spinner />
            </div>
          )}

          <div>
            <input
              id="attachment-file-upload"
              ref={fileInputRef}
              type="file"
              accept="application/pdf"
              onChange={handleUploadAttachment}
            />

            <label htmlFor="attachment-file-upload" className={classes.uploadButton}>
              <AddCircle className={classes.addCircle} />

              <FormattedMessage id="ADD_ATTACHMENT" />
            </label>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

AttachmentFiles.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  schema: PropTypes.object.isRequired,
};

AttachmentFiles.defaultProps = {
  formData: null,
  name: null,
};

export default AttachmentFiles;

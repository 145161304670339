import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const VisitaDigital = props => (
  <SvgIcon viewBox="297.638 155.903 850.394 850.394" {...props}>
    <path
      fill="#393939"
      d="M722.833,457.731c-133.368,0-247.266,82.95-293.41,200.055c46.145,117.087,160.042,200.025,293.41,200.025
      c133.371,0,247.255-82.938,293.414-200.025C970.089,540.68,856.204,457.731,722.833,457.731z M722.833,791.126
      c-73.613,0-133.368-59.729-133.368-133.341c0-73.628,59.755-133.371,133.368-133.371c73.617,0,133.371,59.743,133.371,133.371
      C856.204,731.398,796.45,791.126,722.833,791.126z"
    />
    <path
      fill="#393939"
      d="M722.833,577.762c-44.282,0-80.021,35.741-80.021,80.023s35.739,80.021,80.021,80.021
      c44.285,0,80.023-35.738,80.023-80.021S767.118,577.762,722.833,577.762z"
    />
    <path
      fill="#393939"
      d="M603.543,383.778l37.992,37.993c19.563-23.249,48.516-38.33,81.298-38.33c32.783,0,61.738,15.082,81.3,38.33
      l38.004-37.993c-29.33-32.859-71.867-53.685-119.304-53.685C675.398,330.093,632.862,350.918,603.543,383.778z"
    />
    <path
      fill="#393939"
      d="M722.833,276.745c62.246,0,118.054,26.778,157.076,69.263l37.666-37.68
      c-48.711-52.136-117.844-84.93-194.742-84.93c-76.908,0-146.041,32.794-194.738,84.93l37.678,37.68
      C604.768,303.524,660.589,276.745,722.833,276.745z"
    />
  </SvgIcon>
);

VisitaDigital.displayName = 'VisitaDigital';
VisitaDigital.muiName = 'SvgIcon';

export default VisitaDigital;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { format } from 'date-fns';

import API from 'src/apiRequest';
import PendingOrdersComponent from 'src/components/SchedulePage/Timeline/PendingOrders/PendingOrdersComponent';
import { useAsyncService } from 'src/contexts/AsyncServiceContext';
import { useConfirmAlert } from 'src/contexts/ConfirmAlertContext';
import {
  prependPendingOrder,
  deleteOrder,
  setUnsaved,
  setInitialFilteredPendingOrders,
  setPendingOrders,
  clearFilteredInspectorsNotifications,
} from 'src/store/ducks/schedule';
import { showToast } from 'src/store/ducks/toasts';

const PendingOrdersContainer = () => {
  const dispatch = useDispatch();
  const { orders, pendingOrders, state } = useSelector(({ schedule, filter }) => ({
    date: schedule.date,
    orders: schedule.orders,
    pendingOrders: schedule.filteredPendingOrders,
    state: schedule.state,
    query: filter.query,
  }));

  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(false);
  const orderRef = useRef();
  const intl = useIntl();
  const { on, off } = useAsyncService();

  const { openConfirmAlert } = useConfirmAlert();

  const unassign = data => {
    openConfirmAlert({
      message: intl.formatMessage({ id: 'WANT_UNASSIGN_ORDER_MESSAGE' }),
      onConfirm: async () => {
        const me = JSON.parse(window.sessionStorage.getItem('me'));

        dispatch(deleteOrder(data?.order?.id));
        dispatch(
          prependPendingOrder({
            client: data?.order?.client,
            code: data?.order?.code,
            identifier: data?.order?.identifier,
            id: data?.order?.id,
            type: data?.order?.type,
            credits: data?.order?.credits,
            address_id: data?.order?.address_id,
            order_type_id: data?.order?.order_type_id,
            building_type: data?.order?.building_type,
            allowed_at_date: data?.order?.allowed_at_date,
            accompanied_inspection: data?.order?.accompanied_inspection,
            search_tokens: data?.order?.search_tokens,
            furnished: data?.order?.furnished,
            modality: data?.order?.modality,
            urgency: data?.order?.urgency,
            created_at: data?.order?.created_at,
            access_information: data?.order?.access_information,
            details: data?.order?.details,
            status: data?.order?.status,
            city: data?.order?.city,
            neighborhood: data?.order?.neighborhood,
            street: data?.order?.street,
            number: data?.order?.number,
            complement: data?.order?.complement,
            state: data?.order?.state,
            zipcode: data?.order?.zipcode,
          })
        );
        dispatch(setUnsaved(true));
        dispatch(
          showToast({
            type: 'success',
            title: intl.formatMessage({ id: 'SUCCESS' }),
            text: intl.formatMessage({
              id: 'INSPECTOR_ORDER_UNASSIGNED_SUCCESS',
            }),
            duration: 3000,
          })
        );

        try {
          await API.post('/scheduler/sync', {
            channel: `panel_${me.panelId}`,
            event: 'SCHEDULE.ORDER_UNASSIGNED',
            data: {
              order: data?.order,
              schedule: data?.schedule,
              sender: {
                id: me?.uniqueId,
                who: me?.name,
                when: format(new Date(), 'yyyy-MM-dd HH:mm'),
              },
            },
          });
        } catch {
          dispatch(
            showToast({
              type: 'error',
              title: intl.formatMessage({ id: 'ERROR' }),
              text: intl.formatMessage({ id: 'UNABLE_SAVE_CALENDAR_DATA_ERROR_MESSAGE' }),
              duration: 3000,
            })
          );
        }
      },
    });
  };

  const handleDragOver = evt => {
    evt.preventDefault();
    return false;
  };

  const handleDrop = evt => {
    const data = JSON.parse(evt.dataTransfer.getData('application/json'));
    const isInTimeline = orders.filter(order => order.order.public_id === data.order.id).length > 0;

    dispatch(clearFilteredInspectorsNotifications());

    if (!isInTimeline) return;
    unassign(data);
  };

  const fetchOrders = useCallback(async () => {
    try {
      setBusy(true);
      const { data } = await API.get('/scheduler/assignable-orders');
      dispatch(setInitialFilteredPendingOrders(data));
      dispatch(setPendingOrders(data));
    } catch (err) {
      setError(true);
      console.log(err);
    } finally {
      setBusy(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  useEffect(() => {
    on('connect', fetchOrders);
    return () => off('connect', fetchOrders);
  }, [on, off, fetchOrders]);

  return (
    <PendingOrdersComponent
      pendingOrders={pendingOrders}
      state={state}
      busy={busy}
      error={error}
      orderRef={orderRef}
      handleDrop={handleDrop}
      handleDragOver={handleDragOver}
      intl={intl}
    />
  );
};

export default PendingOrdersContainer;

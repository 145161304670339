import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import axios from 'axios';
import PropTypes from 'prop-types';

import FileUploadComponent from 'src/orderForm/fields/FileUpload/FileUploadComponent';

const FileUploadContainer = ({ required, schema, formData, idSchema, uiSchema, onChange }) => {
  const [file, setFile] = useState(null);
  const [remoteUri, setRemoteUri] = useState(null);
  const [isBusy, setIsBusy] = useState(false);
  const intl = useIntl();

  const uploadFile = async image => {
    try {
      setIsBusy(true);
      const filename = [...Array(48)].map(() => Math.random().toString(36)[2]).join(''); // generate random string of size 48
      const extension = image.name.indexOf('.' > 0) ? `.${image.name.split('.').pop()}` : '';
      const imageUri = `https://publico.vistori.as/location_references/${filename}${extension}`;
      const uploadUri = `https://s3.amazonaws.com/publico.vistori.as/location_references/${filename}${extension}`;

      await axios.put(uploadUri, image, {
        headers: {
          'Content-Type': image.type,
          'x-amz-acl': 'public-read-write',
        },
      });
      setRemoteUri(imageUri);
      onChange(imageUri);
    } catch (err) {
      console.log(err);
    } finally {
      setIsBusy(false);
    }
  };

  const handleChange = evt => {
    const { files } = evt.target;
    const chosenFile = files.item(0);
    setFile(chosenFile);
    uploadFile(chosenFile);
  };

  return (
    <FileUploadComponent
      name={intl.formatMessage({ id: schema.title })}
      id={idSchema.$id}
      required={required}
      formData={formData}
      options={uiSchema.options}
      file={file}
      remoteUri={remoteUri}
      isBusy={isBusy}
      handleChange={handleChange}
    />
  );
};

FileUploadContainer.propTypes = {
  idSchema: PropTypes.object.isRequired,
  required: PropTypes.bool.isRequired,
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.string,
};

FileUploadContainer.defaultProps = {
  formData: null,
};

export default FileUploadContainer;

// Actions
export const ACTIONS = {
  RELOAD: 'cards/RELOAD',
};

// Reducer
const initialState = {
  lastReload: new Date(),
};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.RELOAD:
      return {
        ...state,
        lastReload: new Date(),
      };
    default:
      return state;
  }
}

// actions
export const reloadCards = () => ({
  type: ACTIONS.RELOAD,
});

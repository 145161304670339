import { createContext, useContext } from 'react';

const UserContext = createContext();

export function useUser() {
  const context = useContext(UserContext);

  return context;
}

export default UserContext;

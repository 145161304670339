import React from 'react';
import { FormattedMessage } from 'react-intl';

import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = {
  bootstrapInput: {
    borderRadius: 4,
    fontWeight: 'normal',
    border: '1px solid #ced4da',
    fontSize: 'calc(6px + 0.4vw)',
    padding: '8px 12px',
    width: 'calc(100% - 24px)',
    '&:focus': {
      backgroundColor: 'inherit',
      borderColor: '#80bdff',
      borderRadius: '5px',
    },
    fontFamily: 'Open Sans',
  },
  bootstrapInputError: {
    borderRadius: 4,
    border: '1px solid red',
    fontSize: 'calc(6px + 0.4vw)',
    padding: '8px 12px',
    width: 'calc(100% - 24px)',
    '&:focus': {
      borderColor: '#80bdff',
    },
    fontFamily: 'Open Sans',
  },
  label: {
    fontFamily: 'Open Sans',
    marginTop: '0.3vh',
    fontSize: 'calc(6px + 0.3vw)',
  },
};

function RequestFormFieldSelect(props) {
  const { classes, disabled, formData, id, name, onChange, rawErrors, required, schema, uiSchema } = props;
  const notTranslatable = uiSchema['ui:options'] ? !uiSchema['ui:options'].translatable : false;

  let error = false;
  if (rawErrors) {
    if (rawErrors.length > 0) {
      error = true;
    }
  }

  return (
    <div>
      {
        <label style={styles.label} htmlFor={id}>
          <FormattedMessage id={name} />
          {required ? '*' : null}
        </label>
      }
      <TextField
        className="custom"
        value={formData}
        id={id}
        disabled={disabled}
        fullWidth
        select
        rows={4}
        onChange={event => onChange(event.target.value)}
        data-cy={`${String(schema?.title).toLowerCase()}-${name}`}
        InputProps={{
          disableUnderline: true,
          classes: {
            input: error ? classes.bootstrapInputError : classes.bootstrapInput,
          },
        }}
      >
        {schema.enum ? (
          schema.enum.map(option => (
            <MenuItem key={option} value={option}>
              {notTranslatable ? option : <FormattedMessage id={option} />}
            </MenuItem>
          ))
        ) : (
          <MenuItem key={null} value={null}>
            {uiSchema['ui:options'].emptyMessage}
          </MenuItem>
        )}
      </TextField>
    </div>
  );
}

export default withStyles(styles)(RequestFormFieldSelect);

import React from 'react';

import PropTypes from 'prop-types';

import Spinner from 'src/components/common/Spinner';
import OrderCard from 'src/components/SchedulePage/OrderCard';
import SortSwitcher from 'src/components/SchedulePage/Timeline/PendingOrders/SortSwitcher';
import classes from 'src/components/SchedulePage/Timeline/PendingOrders/style.module.scss';

const PendingOrdersComponent = ({ pendingOrders, busy, error, orderRef, handleDragOver, handleDrop, intl }) => (
  <div className={classes.pendingOrders}>
    <SortSwitcher />

    {busy ? (
      <div className={classes.spinner}>
        <Spinner />
      </div>
    ) : error ? (
      <p>{intl.formatMessage({ id: 'ORDERS_LOAD_ERROR_MESSAGE' })}</p>
    ) : (
      <ul onDragOver={handleDragOver} onDrop={evt => handleDrop(evt)}>
        {pendingOrders.length ? (
          pendingOrders.map(order => (
            <li key={order.id} className="fade-in">
              <OrderCard order={{ order }} ref={orderRef} />
            </li>
          ))
        ) : (
          <p>{intl.formatMessage({ id: 'NO_PENDING_ORDERS_MESSAGE' })}</p>
        )}
      </ul>
    )}
  </div>
);

PendingOrdersComponent.propTypes = {
  pendingOrders: PropTypes.array,
  busy: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  orderRef: PropTypes.object.isRequired,
  handleDragOver: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

PendingOrdersComponent.defaultProps = {
  pendingOrders: [],
};

export default PendingOrdersComponent;

/**
 * @prettier
 **/
import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import pure from 'recompose/pure';

let HistoryIcon = props => (
  <SvgIcon {...props}>
    <path
      fill="#393939"
      d="M17,2C8.7,2,2,8.7,2,17s6.7,15,15,15s15-6.7,15-15S25.3,2,17,2z M17,30C9.8,30,4,24.2,4,17S9.8,4,17,4 s13,5.8,13,13S24.2,30,17,30z"
    />
    <g>
      <path
        fill="#393939"
        d="M17,7.5c-5.2,0-9.5,4.2-9.5,9.5c0,5.2,4.2,9.5,9.5,9.5s9.5-4.2,9.5-9.5C26.5,11.8,22.3,7.5,17,7.5z M17,24.6 c-4.2,0-7.6-3.4-7.6-7.6s3.4-7.6,7.6-7.6s7.6,3.4,7.6,7.6S21.2,24.6,17,24.6z"
      />
      <polygon
        fill="#393939"
        points="18,11.6 16,11.6 16,16 16,18 18,18 20.5,18 20.5,16 18,16"
      />
    </g>
  </SvgIcon>
);

HistoryIcon = pure(HistoryIcon);
HistoryIcon.displaName = 'HistoryIcon';
HistoryIcon.muiName = 'SvgIcon';

export default HistoryIcon;

import { useEffect } from 'react';

import { useFormikContext } from 'formik';

const AutoSavingForm = () => {
  const formik = useFormikContext();

  useEffect(() => {
    formik.submitForm();
  }, [formik.values.neighborhood]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default AutoSavingForm;

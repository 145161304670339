import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as OrderHistory } from 'src/components/common/icons/order-history.svg';

import Icon from './Icon';
import classes from './style.module.scss';

const ActivityLogComponent = ({ intl, log }) => (
  <div className={classes.activityLog}>
    <div className={classes.header}>
      <OrderHistory className={classes.icon} />
      Histórico do pedido
    </div>
    <div className={classes.timeline}>
      <div className={classes.title}>
        <div style={{ paddingLeft: '8px' }}>Ação</div>
        <div>Usuário</div>
        <div style={{ width: '150px' }}>Data e Hora</div>
      </div>
      {log.map(record => (
        <Fragment key={record.id}>
          <div className={classes.line} style={{ paddingBottom: !record.notes ? '4em' : '0.5em' }}>
            <div style={{ width: '40%' }} className={classes.action}>
              <div className={classes.actionIcon}>
                <Icon action={record.type} />
              </div>
              <div className={classes.actionName}>{intl.formatMessage({ id: record.type })}</div>
            </div>
            <div style={{ width: '40%' }}>{record.user_identification}</div>
            <div style={{ width: '20%' }} title={record.humanDate}>
              {record.date}
            </div>
          </div>
          {record.notes && (
            <div className={classes.lineObservation}>
              <div className={classes.action}>
                <div className={classes.observationIcon}></div>
                <div className={classes.actionName}>{record.notes}</div>
              </div>
            </div>
          )}
        </Fragment>
      ))}
    </div>
  </div>
);

ActivityLogComponent.propTypes = {
  intl: PropTypes.object.isRequired,
  log: PropTypes.array.isRequired,
};

export default ActivityLogComponent;

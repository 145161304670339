const parseInputName = (name, value) => {
  const tokens = name.split('[').map(s => s.replace(']', ''));

  const parent = tokens.shift();

  if (tokens.length === 0) return { [parent]: value };

  let parsedName = {};
  tokens.reverse().forEach(key => {
    if (Object.keys(parsedName).length === 0) {
      parsedName[key] = value; // inner-most key-value
    } else {
      const temp = {};
      temp[key] = parsedName;
      parsedName = temp;
    }
  });

  return { [parent]: parsedName };
};

export default parseInputName;

import React from 'react';

import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import EmptyIcon from 'src/svg-icons/empty';

const styles = {
  paper: {
    marginLeft: '1vw',
    marginRight: '1vw',
    backgroundColor: '#EAEAEA',
    border: '#DEDEDE',
    borderStyle: 'solid',
    borderWidth: '2px',
    minHeight: 'calc(100% - 4px)',
  },
  center: {
    textAlign: 'center',
    height: '100%',
  },
  header: {
    fontSize: 'calc(16px + 0.4vw)',
    marginTop: '6vh',
  },
  subHeader: {
    fontSize: 'calc(14px + 0.4vw)',
  },
  imageDiv: {
    marginTop: '10px',
    marginBottom: '5vh',
  },
};

class Empty extends React.Component {
  render() {
    return (
      <div style={styles.center}>
        <Paper style={styles.paper} elevation={0}>
          <div style={styles.header}>
            <span>{this.props.header}</span>
          </div>
          <div style={styles.subHeader}>
            <span>{this.props.subHeader}</span>
          </div>
          <div style={styles.imageDiv}>
            <EmptyIcon
              viewBox="0 0 104 104"
              style={{
                fontSize: 'calc(48px + 0.8vw)',
                verticalAlign: 'middle',
              }}
            />
          </div>
        </Paper>
      </div>
    );
  }
}

Empty.propTypes = {
  /**
   * FormattedMessage of the header
   */
  header: PropTypes.object.isRequired,
  /**
   * FormattedMessage of the subHeader
   */
  subHeader: PropTypes.object.isRequired,
};

export default withStyles(styles)(Empty);

import React from 'react';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = {
  bootstrapInput: {
    borderRadius: 4,
    fontWeight: 'normal',
    border: '1px solid #ced4da',
    fontSize: 'calc(6px + 0.4vw)',
    padding: '8px 12px',
    width: 'calc(100% - 24px)',
    '&:focus': {
      borderColor: '#80bdff',
    },
    fontFamily: 'Open Sans',
  },
  bootstrapInputError: {
    borderRadius: 4,
    border: '1px solid red',
    fontSize: 'calc(6px + 0.4vw)',
    padding: '8px 12px',
    width: 'calc(100% - 24px)',
    '&:focus': {
      borderColor: '#80bdff',
    },
    fontFamily: 'Open Sans',
  },
  label: {
    fontFamily: 'Open Sans',
    marginTop: '0.3vh',
    fontSize: 'calc(6px + 0.3vw)',
  },
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      ref={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value ? parseFloat(values.value) : values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale
      suffix=" m²"
      isNumericString
    />
  );
}

function RequestFormWidgetArea(props) {
  const { classes, disabled, id, label, onChange, rawErrors, required, value } = props;
  let error = false;

  if (rawErrors) {
    if (rawErrors.length > 0) {
      error = true;
    }
  }

  return (
    <div>
      {
        <label style={styles.label} htmlFor={id}>
          <FormattedMessage id={label} />
          {required ? '*' : null}
        </label>
      }
      <TextField
        disabled={disabled}
        fullWidth
        className="custom"
        value={value}
        id={id}
        data-cy={id}
        onChange={event => onChange(event.target.value)}
        InputProps={{
          disableUnderline: true,
          classes: {
            input: error ? classes.bootstrapInputError : classes.bootstrapInput,
          },
          inputComponent: NumberFormatCustom,
        }}
      />
    </div>
  );
}

export default withStyles(styles)(RequestFormWidgetArea);

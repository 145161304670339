import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import * as yup from 'yup';

import API from 'src/apiRequest';
import AddRecommendationCodeComponent from 'src/components/AddRecommendationCode/AddRecommendationCodeComponent';
import { showToast } from 'src/store/ducks/toasts';

const AddRecommendationCodeContainer = ({ orderId, closeModal, refreshHandler }) => {
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const intl = useIntl();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        code: yup.string().required(intl.formatMessage({ id: 'REQUIRED_FIELD' })),
      }),
    [intl]
  );

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await API.post(`orders/${orderId}/recommendations`, {
        code: values.code,
      });
      refreshHandler();
      dispatch(
        showToast({
          type: 'success',
          title: intl.formatMessage({ id: 'SUCCESS' }),
          text: intl.formatMessage({
            id: 'ADD_REFERRAL_CODE_TO_ORDER_SUCCESS',
          }),
          duration: 5000,
        })
      );
      closeModal();
    } catch (err) {
      console.log(err?.response || err);
      setError(
        err?.response?.status === 500 || !err?.response?.data?.message
          ? intl.formatMessage({ id: 'UNKNOWN_ERROR' })
          : intl.formatMessage({ id: err.response.data.message })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <AddRecommendationCodeComponent
      error={error}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      intl={intl}
    />
  );
};

AddRecommendationCodeContainer.propTypes = {
  orderId: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  refreshHandler: PropTypes.func.isRequired,
};

export default AddRecommendationCodeContainer;

import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import RepairBudgetFormComponent from 'src/components/ExtraActions/RepairBudgetForm/RepairBudgetFormComponent';

const RepairBudgetFormContainer = ({ changePage, clientPanel, orderId }) => {
  const [busy, setBusy] = useState(false);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [roomsWithDamages, setRoomsWithDamages] = useState({});
  const [currentRoom, setCurrentRoom] = useState(null);
  const [options, setOptions] = useState([]);
  const [currentTag, setCurrentTag] = useState('');
  const [filterTag, setFilterTag] = useState();

  const params = useParams();
  const intl = useIntl();

  orderId = orderId || params?.orderId;

  const optionsTag = [
    {
      value: '',
      label: intl.formatMessage({ id: 'AllItems' }),
    },
    {
      value: 'TenantPending',
      label: intl.formatMessage({ id: 'TenantPending' }),
    },
    {
      value: 'OwnerPending',
      label: intl.formatMessage({ id: 'OwnerPending' }),
    },
  ];

  useEffect(() => {
    const fillOptions = remoteData => {
      const newOptions = [];
      if (remoteData.damages) {
        newOptions.push({
          value: -1,
          label: intl.formatMessage({ id: 'GENERAL' }),
        });
      }

      remoteData.rooms.forEach((room, index) => {
        if (room.damages) {
          setRoomsWithDamages(oldData => ({ ...oldData, [index]: room }));

          if (newOptions.filter(item => item.value === index).length === 0) {
            newOptions.push({
              value: index,
              label: `${+index + 1}. ${room.name}`,
            });
          }
        }

        room.items.forEach(item => {
          if (item.damages) {
            setRoomsWithDamages(oldData => ({ ...oldData, [index]: room }));

            if (newOptions.filter(currentItem => currentItem.value === index).length === 0) {
              newOptions.push({
                value: index,
                label: `${+index + 1}. ${room.name}`,
              });
            }
          }
        });
      });

      setOptions(newOptions);
    };

    const fetchData = async () => {
      const { data: remoteData } = await API.get(`orders/${orderId}/repair-budget`);
      setData(remoteData);
      fillOptions(remoteData);
      setIsLoading(false);
    };

    fetchData();
  }, [intl, orderId]);

  const handleInputChange = evt => {
    const { name: fullName, value } = evt.target;
    const [name, roomIndex, itemIndex, damageIndex] = fullName.split('-');

    if (itemIndex !== 'G') {
      setRoomsWithDamages(oldData => {
        const newData = { ...oldData };
        if (itemIndex !== 'R') {
          newData[roomIndex].items[itemIndex].damages[damageIndex].repairBudget = {
            ...newData[roomIndex].items[itemIndex].damages[damageIndex].repairBudget,
            [name]: value,
          };
        } else {
          newData[roomIndex].damages[damageIndex].repairBudget = {
            ...newData[roomIndex].damages[damageIndex].repairBudget,
            [name]: value,
          };
        }
        return newData;
      });
    } else {
      setData(oldData => {
        const newData = { ...oldData };
        newData.damages[damageIndex].repairBudget = {
          ...newData.damages[damageIndex].repairBudget,
          [name]: value,
        };
        return newData;
      });
    }
  };

  const handleRoomChange = option => {
    setCurrentRoom(option);
  };

  const handleTagChange = option => {
    setCurrentTag(option.value);
    if (!option?.value) {
      setFilterTag([]);
    } else {
      if (option?.value === 'TenantPending') {
        setFilterTag(['Pendência do Inquilino']);
      } else if (option?.value === 'OwnerPending') {
        setFilterTag(['Pendência do Proprietário', 'Igual entrada + Pendência do Proprietário']);
      }
    }
  };

  const cancel = () => {
    changePage({ orderId }, 'order-details');
  };

  const onSubmit = async () => {
    const rooms = {
      ...data.rooms,
      ...roomsWithDamages,
    };

    const mergedData = {
      ...data,
      rooms: Object.keys(rooms).map(key => rooms[key]),
    };
    try {
      setBusy(true);
      await API.post(`/orders/${orderId}/repair-budget`, mergedData);
      changePage({ orderId }, 'order-details');
    } catch (err) {
      console.log(err);
    } finally {
      setBusy(false);
    }
  };

  return (
    <RepairBudgetFormComponent
      busy={busy}
      cancel={cancel}
      clientPanel={clientPanel}
      currentRoom={currentRoom}
      currentTag={currentTag}
      filterTag={filterTag}
      data={data}
      handleInputChange={handleInputChange}
      handleRoomChange={handleRoomChange}
      handleTagChange={handleTagChange}
      intl={intl}
      isLoading={isLoading}
      onSubmit={onSubmit}
      options={options}
      optionsTag={optionsTag}
      roomsWithDamages={roomsWithDamages}
    />
  );
};

RepairBudgetFormContainer.propTypes = {
  changePage: PropTypes.func.isRequired,
  clientPanel: PropTypes.bool.isRequired,
  orderId: PropTypes.string,
};

export default RepairBudgetFormContainer;

import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const PotentialSchedule = props => (
  <SvgIcon viewBox="0 0 34.016 34.016" {...props}>
    <g>
      <path
        fill="#393939"
        d="M17.008,2.008c-8.271,0-15,6.729-15,15c0,8.271,6.729,15,15,15c8.271,0,15-6.729,15-15
        C32.008,8.737,25.279,2.008,17.008,2.008z M17.008,30.008c-7.168,0-13-5.832-13-13c0-7.168
        ,5.832-13,13-13c7.168,0,13,5.832,13,13C30.008,24.176,24.176,30.008,17.008,30.008z"
      />
      <circle fill="#393939" cx="17.008" cy="13.187" r="3.742" />
      <path
        fill="#393939"
        d="M17.008,18.8c-2.498,0-7.484,1.254-7.484,3.742v1.871h14.968v-1.871C24.492,20.054,19.506,18.8,17.008,18.8z"
      />
    </g>
  </SvgIcon>
);

PotentialSchedule.displayName = 'PotentialSchedule';
PotentialSchedule.muiName = 'SvgIcon';

export default PotentialSchedule;

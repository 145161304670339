const urlPrefix = (suffix) => {
  const { pathname } = window.location;

  const prefix = [];
  pathname.split('/').some(item => (item !== suffix && prefix.push(item), item === suffix));
  const baseUrl = prefix.join('/');

  return `${baseUrl}/${suffix}`;
};

export default urlPrefix;

import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import ClosableFilterOption from 'src/components/common/SearchBox/ClosableFilterOption';
import classes from 'src/components/common/SearchBox/style.module.scss';

const ActiveFiltersAndFreeTermSearch = ({
  placeholder,
  isScheduleBoxFilter,
  isSearchBoxClickable,
  hasFreeTermSearch,
  searchString,
  chosenType,
  chosenOrderStatus,
  query,
  searchWithDelayHandler,
  handleCloseScheduleFilterOption,
  toggleAdvancedSearch,
  options,
  intl,
  moment,
}) => {
  const filters = [];

  if (!isScheduleBoxFilter) {
    if (query.startDate && query.endDate) {
      filters.push(
        <div className={classes.dateIntervalFilter} key="date-1">
          <span>
            {moment(query.startDate).format('DD/MM/YYYY')} {intl.formatMessage({ id: 'TO' })}{' '}
            {moment(query.endDate).format('DD/MM/YYYY')}
          </span>
        </div>
      );
    }

    if (query.startDate && !query.endDate) {
      filters.push(
        <div className={classes.startDateFilter} key="date-2">
          <span>
            {intl.formatMessage({ id: 'FROM' })} {moment(query.startDate).format('DD/MM/YYYY')}
          </span>
        </div>
      );
    }

    if (!query.startDate && query.endDate) {
      filters.push(
        <div className={classes.endDateFilter} key="date-3">
          <span>
            {intl.formatMessage({ id: 'UNTIL' })} {moment(query.endDate).format('DD/MM/YYYY')}
          </span>
        </div>
      );
    }

    if (query.inspectors && query.inspectors.length > 0) {
      if (query.inspectors.length <= 2) {
        filters.push(
          query.inspectors.map(inspector => (
            <div className={classes.filter} key={inspector}>
              {inspector}
            </div>
          ))
        );
      } else {
        filters.push(
          <Fragment key="multiple-inspectors">
            <div className={classes.filter}>{query.inspectors[0]}</div>
            <div className={classes.filter}>{query.inspectors[1]}</div>
            <div className={classes.filter}>
              {intl.formatMessage({ id: 'AND_MORE' })}
              {query.inspectors.length - 2}
              ...
            </div>
          </Fragment>
        );
      }
    }

    if (chosenType) {
      filters.push(
        <div className={classes.filter} key={`type-${chosenType.value}`}>
          <span>{chosenType.label}</span>
        </div>
      );
    }

    if (chosenOrderStatus && options.advanced && options.advanced.includes('activeTabOrderStatus')) {
      filters.push(
        <div className={classes.filter} key={`type-${chosenOrderStatus.value}`}>
          <span>{chosenOrderStatus.label}</span>
        </div>
      );
    }
  }

  if (query.addresses && query.addresses.length > 0) {
    if (query.addresses.length <= 2) {
      filters.push(
        query.addresses.map(address => (
          <ClosableFilterOption
            key={address?.id}
            id={address?.id}
            type="addresses"
            handleClose={handleCloseScheduleFilterOption}
          >
            <span>{address?.description}</span>
          </ClosableFilterOption>
        ))
      );
    } else {
      filters.push(
        <Fragment key="multiple-addresses">
          <ClosableFilterOption
            id={query.addresses[0]?.id}
            type="addresses"
            handleClose={handleCloseScheduleFilterOption}
          >
            <span>{query.addresses[0]?.description}</span>
          </ClosableFilterOption>
          <ClosableFilterOption
            id={query.addresses[1]?.id}
            type="addresses"
            handleClose={handleCloseScheduleFilterOption}
          >
            <span>{query.addresses[1]?.description}</span>
          </ClosableFilterOption>
          <div id={classes.addresses} className={classes.filter}>
            <span>
              {`${intl.formatMessage({ id: 'AND_MORE' })} ${query.addresses.length - 2}`}{' '}
              {query.addresses.length - 2 > 1
                ? intl.formatMessage({ id: 'NEIGHBORHOODS' })
                : `${intl.formatMessage({ id: 'NEIGHBORHOOD' })}...`}
            </span>
          </div>
        </Fragment>
      );
    }
  }

  if (query.orderTypes && query.orderTypes.length > 0) {
    if (query.orderTypes.length <= 2) {
      filters.push(
        query.orderTypes.map(orderTypeItem => (
          <ClosableFilterOption
            key={orderTypeItem?.id}
            id={orderTypeItem?.id}
            type="order-types"
            handleClose={handleCloseScheduleFilterOption}
          >
            <span>{orderTypeItem?.description}</span>
          </ClosableFilterOption>
        ))
      );
    } else {
      filters.push(
        <Fragment key="multiple-orderTypes">
          <ClosableFilterOption
            id={query.orderTypes[0]?.id}
            type="order-types"
            handleClose={handleCloseScheduleFilterOption}
          >
            <span>{query.orderTypes[0]?.description}</span>
          </ClosableFilterOption>
          <ClosableFilterOption
            id={query.orderTypes[1]?.id}
            type="order-types"
            handleClose={handleCloseScheduleFilterOption}
          >
            <span>{query.orderTypes[1]?.description}</span>
          </ClosableFilterOption>
          <div id={classes['order-types']} className={classes.filter}>
            <span>
              {intl.formatMessage({ id: 'AND_MORE' })} {query.orderTypes.length - 2}{' '}
              {query.orderTypes.length - 2 > 1
                ? intl.formatMessage({ id: 'TYPES' })
                : `${intl.formatMessage({ id: 'TYPE' })}...`}
            </span>
          </div>
        </Fragment>
      );
    }
  }

  return (
    <div
      className={classes.activeFilters}
      {...(isSearchBoxClickable && {
        onClick: toggleAdvancedSearch,
        style: { cursor: 'pointer' },
      })}
    >
      {filters.length > 0 && filters}
      {hasFreeTermSearch ? (
        <input
          type="text"
          className={classes.freeSearchTermInput}
          value={searchString || ''}
          placeholder={placeholder || intl.formatMessage({ id: 'SEARCH' })}
          onChange={searchWithDelayHandler}
          data-cy="free-term-search-input"
        />
      ) : (
        !filters.length && <span>{intl.formatMessage({ id: 'SEARCH' })}</span>
      )}
    </div>
  );
};

ActiveFiltersAndFreeTermSearch.propTypes = {
  placeholder: PropTypes.string,
  isScheduleBoxFilter: PropTypes.bool.isRequired,
  isSearchBoxClickable: PropTypes.bool.isRequired,
  hasFreeTermSearch: PropTypes.bool.isRequired,
  searchString: PropTypes.string.isRequired,
  chosenType: PropTypes.object,
  chosenOrderStatus: PropTypes.object,
  query: PropTypes.object,
  searchWithDelayHandler: PropTypes.func.isRequired,
  handleCloseScheduleFilterOption: PropTypes.func.isRequired,
  toggleAdvancedSearch: PropTypes.func.isRequired,
  options: PropTypes.object,
  intl: PropTypes.object.isRequired,
  moment: PropTypes.any.isRequired,
};

ActiveFiltersAndFreeTermSearch.defaultProps = {
  placeholder: null,
  chosenType: null,
  chosenOrderStatus: null,
  query: {},
  options: {},
};

export default ActiveFiltersAndFreeTermSearch;

import React from 'react';

import PropTypes from 'prop-types';

import classes from 'src/orderDetails/KeysAddressSection.module.scss';

function KeysAddressSection({ address }) {
  return (
    <div className={classes.Container}>
      {address.zipcode ? (
        <div data-cy="order-details-keys-address-zipcode">
          {address.zipcode}
        </div>
      ) : null}
      <div data-cy="order-details-keys-address-street-number-complement">
        {address.street}
        <span>, </span>
        {address.number}
        {address.complement !== undefined ? (
          <span>
            <span>, </span>
            {address.complement}
          </span>
        ) : null}
      </div>
      <div data-cy="order-details-keys-address-neighborhood-city-state">
        {address.neighborhood}
        <span>, </span>
        {address.city}
        <span> - </span>
        {address.state}
      </div>
    </div>
  );
}

KeysAddressSection.propTypes = {
  /**
   * Object that contains the address information
   */
  address: PropTypes.object.isRequired,
};

export default KeysAddressSection;

import React, { useCallback } from 'react';
import { components } from 'react-select';

import PropTypes from 'prop-types';

import deviceImage from 'src/components/SchedulePage/OrderModal/OrderForm/InspectorOption/icons/device-gray.svg';
import inspectionImage from 'src/components/SchedulePage/OrderModal/OrderForm/InspectorOption/icons/inspection-gray.svg';
import locationImage from 'src/components/SchedulePage/OrderModal/OrderForm/InspectorOption/icons/location-gray.svg';
import classes from 'src/components/SchedulePage/OrderModal/OrderForm/InspectorOption/style.module.scss';

const InspectorOption = ({ children, data, ...props }) => {
  const getInspectorIcon = useCallback(() => {
    if (data?.status === 'DEVICE_NOT_CONNECTED') {
      return <img src={deviceImage} alt="dispositivo" />;
    }

    if (data?.pendingOrderBasedMessage) {
      if (data?.pendingOrderBasedMessage === 'ORDER_TYPE') {
        return <img src={inspectionImage} alt="Tipo de vistoria" />;
      }

      if (data?.pendingOrderBasedMessage === 'ADDRESS') {
        return <img src={locationImage} alt="Bairro" />;
      }
    }

    return null;
  }, [data]);

  return (
    <components.Option className={classes.inspectorOption} {...props}>
      {getInspectorIcon()}
      {children}
    </components.Option>
  );
};

InspectorOption.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
};

export default InspectorOption;

import moment from 'moment';

const allowedDateFormats = [
  'DD-MM-YYYY HH:mm:ss',
  'DD-MM-YYYY HH:mm',
  'DD-MM-YYYY',
  'DD/MM/YYYY HH:mm:ss',
  'DD/MM/YYYY HH:mm',
  'DD/MM/YYYY',
  'D-M-YYYY HH:mm:ss',
  'D-M-YYYY HH:mm',
  'D-M-YYYY',
  'D/M/YYYY HH:mm:ss',
  'D/M/YYYY HH:mm',
  'D/M/YYYY',
];

function validateAllowedAtDateFormat(allowedAtDate) {
  try {
    for (const dateFormat of allowedDateFormats) {
      const validatedDate = moment(allowedAtDate, dateFormat);

      if (validatedDate.isValid()) return validatedDate;
    }

    return false;
  } catch {
    return false;
  }
}

export default validateAllowedAtDateFormat;

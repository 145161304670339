import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import classes from 'src/orderDetails/KeysSection.module.css';
import Keys from 'src/svg-icons/Keys';

function KeysSection(props) {
  if (Object.keys(props.keys).length > 0) {
    return (
      <Grid item xs={12}>
        <div className={classes.Header}>
          <Keys viewBox="0 0 34.016 34.016" className={classes.Icon} />
          <FormattedMessage id="keys" />
        </div>
        <Grid container spacing={16} className={classes.Information}>
          {props.keys['simple'] !== undefined ? (
            <Grid item xs={6}>
              <div className={classes.SubHeader}>
                <FormattedMessage id="simple" />
              </div>
              <div data-cy="order-details-keys-simple">{props.keys['simple']}</div>
            </Grid>
          ) : null}
          {props.keys['foursided'] !== undefined ? (
            <Grid item xs={6}>
              <div className={classes.SubHeader}>
                <FormattedMessage id="foursided" />
              </div>
              <div data-cy="order-details-keys-foursided">{props.keys['foursided']}</div>
            </Grid>
          ) : null}
          {props.keys['others'] !== undefined ? (
            <Grid item xs={12}>
              <div className={classes.SubHeader}>
                <FormattedMessage id="others" />
              </div>
              <div data-cy="order-details-keys-others">{props.keys['others']}</div>
            </Grid>
          ) : null}
          {props.keys['notes'] !== undefined ? (
            <Grid item xs={12}>
              <div className={classes.SubHeader}>
                <FormattedMessage id="notes" />
              </div>
              <div data-cy="order-details-keys-notes">{props.keys['notes']}</div>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
}

KeysSection.propTypes = {
  /**
   * Object that contains the keys information
   */
  keys: PropTypes.object.isRequired,
};

export default KeysSection;

import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import PropTypes from 'prop-types';

import exclamationSolidImage from 'src/components/common/icons/exclamation-solid.svg';
import pointCleaningImage from 'src/components/common/icons/point-cleaning.svg';
import pointKeysImage from 'src/components/common/icons/point-keys.svg';
import pointMaintenanceImage from 'src/components/common/icons/point-maintenance.svg';
import pointUnoccupiedImage from 'src/components/common/icons/point-unoccupied.svg';
import classes from 'src/orderForm/UnproductiveWarning/style.module.scss';

const UnproductiveWarning = ({ onChange, submitted }) => {
  const [checked, setChecked] = useState(false);
  const intl = useIntl();

  const handleChange = evt => {
    const { checked: isChecked } = evt.target;
    setChecked(isChecked);
    onChange(isChecked);
  };

  return (
    <div className={classes.unproductiveWarning}>
      <h2>
        <div className={classes.icon}>
          <img src={exclamationSolidImage} alt={intl.formatMessage({ id: 'GET_CERTIFIED' })} />
        </div>
        {intl.formatMessage({ id: 'MAKE_SURE_THAT' })}
      </h2>
      <ul>
        <li>
          <div className={classes.icon}>
            <img src={pointKeysImage} alt={intl.formatMessage({ id: 'KEYS' })} />
          </div>
          <div className={classes.text}>{intl.formatMessage({ id: 'ALL_KEYS_AVAILABLE_MESSAGE' })}</div>
        </li>
        <li>
          <div className={classes.icon}>
            <img src={pointMaintenanceImage} alt={intl.formatMessage({ id: 'REPAIR' })} />
          </div>
          <div className={classes.text}>{intl.formatMessage({ id: 'PROPERTY_MAINTENANCE_CARRIED_MESSAGE' })}</div>
        </li>
        <li>
          <div className={classes.icon}>
            <img src={pointUnoccupiedImage} alt={intl.formatMessage({ id: 'NOT_BUSY' })} />
          </div>
          <div className={classes.text}>
            {intl.formatMessage({
              id: 'PROPERTY_COMPLETELY_UNOCCUPIED_MESSAGE',
            })}
          </div>
        </li>
        <li>
          <div className={classes.icon}>
            <img src={pointCleaningImage} alt={intl.formatMessage({ id: 'CLEANING' })} />
          </div>
          <div className={classes.text}>
            {intl.formatMessage({
              id: 'PROPERTY_CLEANING_HAS_BEEN_CARRIED_MESSAGE',
            })}
          </div>
        </li>
      </ul>
      <div className={classes.field}>
        <label>
          <input
            type="checkbox"
            name="unproductive-checkbox"
            id="unproductive-checkbox"
            data-cy="unproductive-checkbox"
            value="true"
            checked={checked}
            onChange={handleChange}
          />
          {intl.formatMessage({ id: 'NON_PRODUTIVE_INSPECTION_AWARE_MESSAGE' })}
        </label>
        {submitted && !checked ? (
          <ul className="error-detail bs-callout bs-callout-info">
            <li className="text-danger">{intl.formatMessage({ id: 'ACCEPT_TERMS_MESSAGE' })}</li>
          </ul>
        ) : null}
      </div>
    </div>
  );
};

UnproductiveWarning.propTypes = {
  onChange: PropTypes.func.isRequired,
  submitted: PropTypes.bool.isRequired,
};

export default UnproductiveWarning;

import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import ColorButton from 'src/components/common/elements/ColorButton';
import { ReactComponent as CommentsIcon } from 'src/components/common/icons/comments.svg';
import { ReactComponent as EllipsisIcon } from 'src/components/common/icons/ellipsis-h.svg';
import { ReactComponent as OrderHistory } from 'src/components/common/icons/order-history.svg';
import Modal from 'src/components/common/Modal';
import { showToast } from 'src/store/ducks/toasts';

import classes from './style.module.scss';

const OptionsComponent = ({ inspector, orderId, refreshHandler }) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const menuRef = useRef();
  const dispatch = useDispatch();
  const intl = useIntl();

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const openModal = type => {
    setModalContent(type);
    setIsMenuVisible(false);
    setShowModal(true);
  };

  const closeModal = () => {
    setModalContent('');
    setShowModal(false);
  };

  const saveComment = async () => {
    const formData = new FormData(document.getElementById('commentForm'));
    const formValues = {
      observation: formData.get('observation'),
    };
    try {
      await API.put(
        `orders/${orderId}/inspectors/${inspector.public_id}/observation?action=${inspector.action}`,
        formValues
      );
      dispatch(
        showToast({
          type: 'success',
          title: intl.formatMessage({ id: 'SUCCESS' }),
          text: intl.formatMessage({ id: 'INSPECTOR_UPDATED_SUCCESS' }),
          duration: 5000,
        })
      );
      closeModal();
      refreshHandler();
    } catch (err) {
      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'UNKNOWN_ERROR' }),
          duration: 5000,
        })
      );
      console.log(err?.response || err);
    }
  };

  const confirmExcludeIspector = async () => {
    try {
      await API.delete(`orders/${orderId}/inspectors/${inspector.public_id}?action=${inspector.action}`);
      dispatch(
        showToast({
          type: 'success',
          title: intl.formatMessage({ id: 'SUCCESS' }),
          text: intl.formatMessage({ id: 'INSPECTOR_DELETE_SUCCESS' }),
          duration: 5000,
        })
      );
      closeModal();
      refreshHandler();
    } catch (err) {
      dispatch(
        showToast({
          type: 'error',
          title: intl.formatMessage({ id: 'ERROR' }),
          text: intl.formatMessage({ id: 'INSPECTOR_DELETE_ERROR' }),
          duration: 5000,
        })
      );
      console.log(err?.response || err);
    }
  };

  // click away events
  useEffect(() => {
    const closeOnClickAway = evt => {
      if (menuRef?.current?.contains(evt.target)) return;
      setIsMenuVisible(false);
    };

    const closeOnEsc = evt => {
      if (evt.key !== 'Escape') return;
      setIsMenuVisible(false);
    };

    document.addEventListener('mouseup', closeOnClickAway);
    document.addEventListener('keyup', closeOnEsc);

    return () => {
      document.removeEventListener('mouseup', closeOnClickAway);
      document.removeEventListener('keyup', closeOnEsc);
    };
  }, [menuRef]);

  return (
    <div className={classes.advancedOptions} ref={menuRef}>
      <button type="button" className={classes.ellipsisButton} onClick={toggleMenu}>
        <EllipsisIcon />
      </button>

      {isMenuVisible ? (
        <ul className={classes.menu}>
          <li>
            <button type="button" onClick={() => openModal('editComment')}>
              {!inspector.observation ? 'Adicionar comentário' : 'Editar comentário'}
            </button>
          </li>
          <li>
            <button type="button" onClick={() => openModal('excludeIspector')}>
              Excluir vistoriador executante
            </button>
          </li>
        </ul>
      ) : null}
      {showModal && (
        <Modal onClose={closeModal}>
          {modalContent === 'editComment' && (
            <div>
              <div>
                <div className={classes.headerModal}>
                  <CommentsIcon className={classes.icon} />
                  Comentário
                </div>
                <hr />
                <form id="commentForm">
                  <div className={classes.observation}>
                    <textarea
                      defaultValue={inspector.observation || ''}
                      name="observation"
                      placeholder="Escreva aqui uma anotação..."
                    />
                  </div>
                </form>

                <div className="text-right">
                  <ColorButton color="red" onClick={closeModal}>
                    <FormattedMessage id="BUTTON.CANCEL" />
                  </ColorButton>
                  <ColorButton color="green" onClick={() => saveComment()}>
                    <FormattedMessage id="BUTTON.CONFIRM" />
                  </ColorButton>
                </div>
              </div>
            </div>
          )}
          {modalContent === 'excludeIspector' && (
            <div>
              <div className={classes.headerModal}>
                <OrderHistory className={classes.icon} />
                Deseja excluir vistoriador executante?
              </div>
              <hr />
              <div className="text-right">
                <ColorButton color="red" onClick={closeModal}>
                  <FormattedMessage id="BUTTON.CANCEL" />
                </ColorButton>
                <ColorButton color="green" onClick={() => confirmExcludeIspector()}>
                  <FormattedMessage id="BUTTON.CONFIRM" />
                </ColorButton>
              </div>
            </div>
          )}
        </Modal>
      )}
    </div>
  );
};

OptionsComponent.propTypes = {
  inspector: PropTypes.object.isRequired,
  orderId: PropTypes.string.isRequired,
  refreshHandler: PropTypes.func.isRequired,
};

export default OptionsComponent;
